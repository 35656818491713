/**
 * Created by lkarmelo on 18.11.2019.
 */

import React, { useMemo } from 'react';
import classNames from 'classnames';

import TreeSelect, {
    IOptionProps,
    ISelectOption,
} from 'app/modules/components/TreeSelect';

import * as styles from './Tags.scss';

export const Option: React.FC<IOptionProps> = ({
    active,
    option,
    onSelect,
    onDeselect,
}) => {
    const toggle = () => {
        active ? onDeselect(option) : onSelect(option);
    };

    return (
        <button
            title={option.label}
            className={classNames('btn', styles.tagsFilterTag, {
                [styles.tagsFilterTagActive]: active,
            })}
            onClick={toggle}
        >
            {option.label}
        </button>
    );
};

interface IProps {
    options: ISelectOption[];
    active: string[];
    title: string;
    onSelect(option: ISelectOption): void;
    onDeselect(option: ISelectOption): void;
}

const listComponentProps = {
    OptionComponent: Option,
    sortOptions(
        { sortIndex: sortIndexA }: ISelectOption,
        { sortIndex: sortIndexB }: ISelectOption
    ) {
        if (sortIndexA < sortIndexB) {
            return -1;
        }
        if (sortIndexA > sortIndexB) {
            return 1;
        }
        return 0;
    },
};

const SearchBar = () => {
    return null;
};

const Tags: React.FC<IProps> = (props) => {
    const { options, active, onSelect, onDeselect, title } = props;

    const headerComponentProps = useMemo(() => ({ title }), [title]);

    return (
        <TreeSelect
            className={styles.tagsFilter}
            options={options}
            active={active}
            onSelect={onSelect}
            onDeselect={onDeselect}
            listComponentProps={listComponentProps}
            headerComponentProps={headerComponentProps}
            SearchBarComponent={SearchBar}
            collapsible={options?.length > 4}
            isTags
        />
    );
};

export default Tags;
