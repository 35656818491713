/**
 * Created by Lkarmelo on 27.10.2017.
 * -----
 * Last Modified: 07.02.2018 16:29:13
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import React, { PureComponent } from 'react';

import Footer from 'app/components/Footer';
import setBodyClassOnMount from 'app/components/high-order/setBodyClassOnMount';
import { Header } from 'app/components/header/Header';

@setBodyClassOnMount('default-layout')
export default class DefaultLayout extends PureComponent {
    render(): JSX.Element {
        return (
            <div className="layout-wrapper">
                <Header showUserMenu />
                {this.props.children}
                <Footer />
            </div>
        );
    }
}
