/**
 * Created by lkarmelo on 03.03.2020.
 */

import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import * as Store from 'app/redux/store/StoreNamespace';
import { contextDispatch } from 'app/redux/context/connectWithContext';
import { setSorting as setSortingAction } from 'app/redux/actions/sorting';

import IProps, {
    ISortingPanelOwnProps,
    ISortingPanelActionProps,
    ISortingPanelStateProps,
    ISortingPanelMergeableActionProps,
} from './interfaces/ISortingPanelProps';
import * as styles from './SortingPanel.scss';

const SortingPanel: React.FC<IProps> = (props) => {
    const { options, sort, setSorting, defaultSort } = props;

    const activeSortingKey = sort?.key ?? defaultSort.key;
    const activeSortingOrder = sort?.order ?? defaultSort.order;

    return (
        <div className={styles.sortingPanel}>
            {options?.map((opt) => {
                const isActive = opt.key === activeSortingKey;
                const { startingSortingOrder } = opt;

                return (
                    <div
                        key={opt.key}
                        className={styles.sortingPanelSortBtnWrapper}
                    >
                        <button
                            className={classNames(
                                'btn',
                                styles.sortingPanelSortBtn,
                                { [styles.sortingPanelSortBtnActive]: isActive }
                            )}
                            onClick={() => {
                                if (opt.orderless && isActive) {
                                    return;
                                }
                                const nextSortOrder = isActive
                                    ? activeSortingOrder === 'asc'
                                        ? 'desc'
                                        : 'asc'
                                    : startingSortingOrder ?? 'desc';
                                setSorting(opt.key, nextSortOrder);
                            }}
                        >
                            {isActive && !opt.orderless && (
                                <span
                                    className={classNames(
                                        'nkc-icon',
                                        'nkc-icon-arrow-down',
                                        styles.sortingPanelSortBtnOrder,
                                        {
                                            [styles.sortingPanelSortBtnOrderAsc]:
                                                activeSortingOrder === 'asc',
                                        }
                                    )}
                                />
                            )}
                            {opt.label}
                        </button>
                    </div>
                );
            })}
        </div>
    );
};

export const createSortingPanel = (
    context?: string,
    dispatchContext?: string
) => {
    const mapStateToProps = (state: Store.IState): ISortingPanelStateProps => {
        const searchState: Store.ISearch = state[context ?? 'search'];

        return {
            sort: searchState.sorting,
        };
    };

    const mapDispatchToProps = (defaultDispatch) => {
        const dispatch = dispatchContext
            ? contextDispatch(defaultDispatch, dispatchContext)
            : defaultDispatch;

        return (): ISortingPanelActionProps &
            ISortingPanelMergeableActionProps => ({
            setSorting(key: string, order: Store.SortingOrder) {
                dispatch(setSortingAction(key, order));
            },
        });
    };

    const mergeProps = (
        stateProps: ISortingPanelStateProps,
        dispatchProps: ISortingPanelActionProps,
        ownProps: ISortingPanelOwnProps
    ): IProps => ({
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
    });

    return connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(SortingPanel);
};
