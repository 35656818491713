import React, { ReactNode, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import Truncate from 'react-truncate';
import { useUpdateEffect } from 'react-use';
import { useTruncate } from '@nkc-frontend/nkc-react-hooks';

import DocumentTags from 'app/components/document/DocumentTags';
import { useHighlighted } from 'app/hooks/useHighlighted';
import { useNormalizedOrgs } from 'app/hooks/useNormalizedOrgs';
import { prependHttp } from 'app/utils/prependHttp';
import getDocumentNameByType from 'app/utils/document/getDocumentNameByType';
import { getPersonsLoginsByProfiles } from 'app/utils/document/login';
import {
    DATE_DOTS_FORMAT,
    documentFileMetaTitle,
    documentFilePathTitle,
    DOCUMENT_EMPTY_TEXT,
    CURRENT_HTTP_URL,
} from 'app/utils/constants';
import {
    getBiggestPreviewLink,
    getPreviewLinksMap,
} from 'app/utils/document/previewLinksUtils';
import { getAuthorBlockTitle } from 'app/utils/document/getAuthorBlockTitle';
import { stringToTag } from 'app/utils/document/tagsUtils';
import { getDocumentDate } from 'app/utils/document/getDocumentDate';
import clientRoutes from 'app/routing/clientRoutes';
import * as Store from 'app/redux/store/StoreNamespace';
import {
    getClearedDangerousHtml,
    getFormattedByBrText,
} from 'app/utils/clearifyDangerousHtml';

import CollapseDocumentAttributes, {
    IDocumentAttributes,
} from './CollapseDocumentAttributes';

import ActiveIndicator from '../ActiveIndicator/ActiveIndicator';
import * as styles from '../Card/Card.scss';

interface IProps {
    card: Store.IObjectCard;
}

const renderOptionalAttrValue = (
    val: any
): JSX.Element | JSX.Element[] | null => {
    if (typeof val === 'string' || typeof val === 'number') {
        return <div dangerouslySetInnerHTML={{ __html: val.toString() }} />;
    }
    if (typeof val === 'boolean') {
        return <div>{val ? 'Да' : 'Нет'}</div>;
    }
    if (Array.isArray(val)) {
        return val.map((v, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i}>{renderOptionalAttrValue(v)}</div>
        ));
    }
    if (val?.$date !== undefined) {
        return <div>{moment(val.$date).format(DATE_DOTS_FORMAT)}</div>;
    }
    return null;
};

const getDocumentMeta = <T extends object>(
    values: T,
    keyTitleMap: Record<keyof T, string>,
    renderValue?: (key: keyof T, value: T[keyof T]) => ReactNode
) => {
    return (
        values &&
        Object.entries(values)
            .filter(([, value]) => value !== null && value !== undefined)
            .map(([key, value]) => ({
                code: key,
                title: keyTitleMap[key] || key,
                renderedValue: renderValue
                    ? renderValue(key as keyof T, value)
                    : value,
            }))
    );
};

const renderFileMetaValue = (
    key: keyof Store.IDocumentFileMeta,
    val: Store.IDocumentFileMeta[keyof Store.IDocumentFileMeta]
): ReactNode => {
    if (key === 'filesize') {
        return `${val} байт`;
    }
    if (
        key === 'created' ||
        key === 'lastModified' ||
        key === 'lastAccessed' ||
        key === 'indexingDate'
    ) {
        return moment(val).format(DATE_DOTS_FORMAT);
    }

    return val;
};

const renderFilePathValue = (relativeUrl: string) => (
    key: keyof Store.IDocumentFilePath,
    val: Store.IDocumentFilePath[keyof Store.IDocumentFilePath]
): ReactNode => {
    if (key === 'real' && !!relativeUrl) {
        let absoluteUrl = `${CURRENT_HTTP_URL}${relativeUrl}`
        return <a href={absoluteUrl} download={val}>{val}</a>;
    }

    return val;
};

const Description: React.FC<IProps> = ({ card }) => {
    const [
        contextTruncateContainerRef,
        isToggleContextTruncateBtnShown,
        _,
        isContextManuallyOpened,
        setIsContextManuallyOpened,
        showHideTruncContextBtnIfOverflown,
    ] = useTruncate<HTMLDivElement>(styles.cardContextOpened, 'height');

    const {
        keywords,
        categories,
        optionalAttrs,
        profiles: profileLink,
        annotation,
        tags,
        previewLinks,
        highlights,
        meta,
    } = card || ({} as Store.IObjectCard);

    const {
        objectType,
        title,
        orgs,
        persons,
        displayObjectType,
        displayStorageSource,
        activeStatus,
        file,
        path,
        storageLink
    } = meta || ({} as Store.IDocumentDescription);

    const formattedDate = useMemo(() => getDocumentDate(meta), [meta]);

    const personWithLink = useMemo(
        () => getPersonsLoginsByProfiles(persons, profileLink),
        [persons, profileLink]
    );
    const normalizedOrgs = useNormalizedOrgs(orgs);

    const [__, highlightedOrgs, highlightedPersons, highlightedOptionalAttrs] =
        useHighlighted({
            highlights,
            orgs: normalizedOrgs,
            personWithLink,
            optionalAttrs,
        });

    const optionalAttrsWithTitle = useMemo<Store.IDocumentAttr[]>(() => {
        return highlightedOptionalAttrs?.filter(
            (attr: Store.IDocumentAttr) => !!attr.title
        );
    }, [highlightedOptionalAttrs]);

    const [previewLinkBig, previewLinkDefault] = useMemo(() => {
        const previewLinksMap = getPreviewLinksMap(previewLinks);
        const pLinkBig = getBiggestPreviewLink(previewLinksMap);
        const prLinkDefault =
            previewLinksMap.get(Store.PreviewLinkSize.default) || pLinkBig;
        return [pLinkBig, prLinkDefault];
    }, [previewLinks]);

    const fileMeta = useMemo<IDocumentAttributes[]>(
        () => getDocumentMeta(file, documentFileMetaTitle, renderFileMetaValue),
        [file]
    );

    const pathMeta = useMemo<IDocumentAttributes[]>(
        () => getDocumentMeta(path, documentFilePathTitle, renderFilePathValue(storageLink)),
        [path]
    );

    const contextList: any[] =
        Array.isArray(highlights?.content) && highlights.content.length > 0
            ? highlights.content
            : annotation;

    useUpdateEffect(showHideTruncContextBtnIfOverflown, [contextList]);

    const renderTag = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        (tag: Store.ICategory, _, className: string) => {
            const formattedTag = stringToTag(tag.name);
            return (
                <Link
                    to={clientRoutes.search.getUrl(
                        {},
                        {
                            query: formattedTag,
                        }
                    )}
                    key={tag.id}
                    className={className}
                >
                    {formattedTag}
                </Link>
            );
        },
        []
    );

    const getContent = (line: any): string => {
        if (typeof line === 'string') {
            return line;
        }

        if (typeof line === 'object') {
            return line.sentence;
        }

        return null;
    };

    return (
        <div>
            {keywords && keywords.length > 0 && (
                <div className={styles.cardSection}>
                    <div className={styles.cardSectionTitle}>
                        Ключевые слова
                    </div>
                    <div className={styles.cardKeywords}>
                        <Truncate lines={3} ellipsis={<span>...</span>}>
                            {card.keywords.map((kw) => kw.keyword).join(', ')}
                        </Truncate>
                    </div>
                </div>
            )}
            <div className={styles.cardSection}>
                <div className={styles.cardSectionTitle}>Контекст</div>
                <div
                    ref={contextTruncateContainerRef}
                    className={classNames(styles.cardContext, {
                        [styles.cardContextOpened]: isContextManuallyOpened,
                    })}
                >
                    {Array.isArray(contextList) && contextList.length > 0
                        ? contextList.map((line, index) => {
                              const key = `context-${index}`;

                              return (
                                  <div key={key}>
                                      {getFormattedByBrText(getContent(line))}
                                  </div>
                              );
                          })
                        : DOCUMENT_EMPTY_TEXT}
                </div>
                <div className={styles.cardReadMore}>
                    {isToggleContextTruncateBtnShown &&
                        !isContextManuallyOpened && (
                            <button
                                onClick={() => setIsContextManuallyOpened(true)}
                                className="btn"
                            >
                                Ещё
                            </button>
                        )}
                </div>
                <div className={styles.cardTruncateBtn}>
                    {isToggleContextTruncateBtnShown &&
                        isContextManuallyOpened && (
                            <button
                                onClick={() =>
                                    setIsContextManuallyOpened(false)
                                }
                                className="btn"
                            >
                                Свернуть
                            </button>
                        )}
                </div>
            </div>
            <div className={styles.cardMainAttrs}>
                {formattedDate && (
                    <div className={styles.cardSection}>
                        <div className={styles.cardSectionTitle}>
                            Дата документа
                        </div>
                        <div className={styles.cardDate}>
                            <span className={styles.cardDateIcon} />
                            <div>{formattedDate}</div>
                        </div>
                    </div>
                )}
                {objectType && (
                    <div className={styles.cardSection}>
                        <div className={styles.cardSectionTitle}>
                            Тип документа
                        </div>
                        <div className={styles.cardType}>
                            <span
                                className={`${styles.cardTypeIcon} document-type document-type-${objectType}`}
                            />
                            <div>
                                {getDocumentNameByType(
                                    objectType,
                                    displayObjectType
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {displayStorageSource && (
                    <div className={styles.cardSection}>
                        <div className={styles.cardSectionTitle}>Источник</div>
                        <div>{displayStorageSource}</div>
                    </div>
                )}
                {activeStatus && (
                    <div className={styles.cardSection}>
                        <div className={styles.cardSectionTitle}>Статус</div>
                        <ActiveIndicator activeStatus={activeStatus} />
                    </div>
                )}
                {highlightedPersons && highlightedPersons.ids.length > 0 && (
                    <div className={styles.cardSection}>
                        <div className={styles.cardSectionTitle}>
                            {getAuthorBlockTitle(meta)}
                        </div>
                        <div>
                            {highlightedPersons &&
                                highlightedPersons.ids.map((personId) => {
                                    const person =
                                        highlightedPersons.persons[personId];
                                    if (!person) {
                                        return null;
                                    }
                                    return (
                                        <div
                                            key={person.id}
                                            className={styles.cardAuthor}
                                        >
                                            {person.link ? (
                                                <Link
                                                    target="_blank"
                                                    to={clientRoutes.account.subRoutes.profile.getUrl(
                                                        null,
                                                        {
                                                            user: person.link,
                                                            source: person.source,
                                                        }
                                                    )}
                                                >
                                                    {getClearedDangerousHtml(
                                                        person.fullName
                                                    )}
                                                </Link>
                                            ) : (
                                                <div>
                                                    {getClearedDangerousHtml(
                                                        person.fullName
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                )}
                {highlightedOrgs && highlightedOrgs.ids.length > 0 && (
                    <div className={styles.cardSection}>
                        <div className={styles.cardSectionTitle}>
                            Организация
                        </div>
                        <div>
                            {highlightedOrgs.ids.map((orgId) => {
                                const org = highlightedOrgs.orgs[orgId];
                                if (!org) {
                                    return null;
                                }
                                return (
                                    <div
                                        key={org.id}
                                        className={`${styles.cardOrg}`}
                                    >
                                        {getClearedDangerousHtml(org.title)}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
            {categories && categories.length > 0 && (
                <div className={styles.cardSection}>
                    <div className={styles.cardSectionTitle}>Категория</div>
                    <div>
                        {categories.map((cat) => (
                            <div key={cat.id}>
                                <Link
                                    to={clientRoutes.catalogue.getUrl({
                                        id: cat.id,
                                    })}
                                    className={styles.cardCategory}
                                    target="_blank"
                                >
                                    {cat.name.replace(/_/g, ' ')}
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {tags && tags.length > 0 && (
                <div className={styles.cardSection}>
                    <div className={styles.cardSectionTitle}>Теги</div>
                    <DocumentTags tags={tags} renderTag={renderTag} />
                </div>
            )}
            {previewLinkBig && (
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={prependHttp(previewLinkDefault)}
                    className={styles.cardPreview}
                >
                    <img src={previewLinkBig} alt={title} />
                </a>
            )}
            {optionalAttrsWithTitle && optionalAttrsWithTitle.length > 0 && (
                <div className={styles.cardSection}>
                    <CollapseDocumentAttributes
                        title="Дополнительные данные документа"
                        attributes={optionalAttrsWithTitle.map((attr) => ({
                            ...attr,
                            renderedValue: renderOptionalAttrValue(attr.value),
                        }))}
                    />
                </div>
            )}
            {Array.isArray(fileMeta) && fileMeta.length > 0 && (
                <div className={styles.cardSection}>
                    <CollapseDocumentAttributes
                        title="Метаданные файла"
                        attributes={fileMeta}
                    />
                </div>
            )}
            {Array.isArray(pathMeta) && pathMeta.length > 0 && (
                <div className={styles.cardSection}>
                    <CollapseDocumentAttributes
                        title="Путь"
                        attributes={pathMeta}
                    />
                </div>
            )}
        </div>
    );
};

export default Description;
