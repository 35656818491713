import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import IProps, {
    IProfileActionProps,
    IProfileStateProps,
} from './interfaces/IProfileProps';
import styles from './Profile.scss';

import withSetDocumentTitle, {
    IDocumentTittleSetterProps,
} from '../../high-order/setDocumentTitle';
import PersonAvatar from '../../search/PersonAvatar/PersonAvatarConnected';
import { PersonalInfo, WorkInfo } from '../ProfilePanels';
import * as Store from '../../../redux/store/StoreNamespace';
import { FunctionalityName, getFunctionalityAllowed } from '../../../roles';
import { fetchProfileAvatar } from '../../../redux/actions/profile';
import scrollToTopOnMount from '../../high-order/scrollToTopOnMount';

const Profile: React.FC<IProps & IDocumentTittleSetterProps> = (props) => {
    const {
        currentUserId,
        isPersonalInfoEditable,
        isWorkInfoEditable,
        currentUserFirstName,
        currentUserLastName,
        currentUserMiddleName,
        canChangePassword,
        avatar,
    } = props;

    const { setDocumentTitle, onLoad } = props;

    useEffect(() => {
        onLoad(currentUserId);
        if (!currentUserFirstName && !currentUserLastName) {
            return;
        }
        const documentTitle = `${currentUserLastName || ''} ${
            currentUserFirstName || ''
        } ${currentUserMiddleName || ''}`;
        setDocumentTitle(documentTitle);
    }, [
        currentUserFirstName,
        currentUserLastName,
        currentUserMiddleName,
        currentUserId,
    ]);

    return (
        <section className="profile">
            <div className="profile__content">
                <PersonAvatar
                    avatar={avatar && avatar.avatar}
                    className={styles.profileAvatar}
                />
                <PersonalInfo
                    isEditable={isPersonalInfoEditable}
                    hideChangePassword={!canChangePassword}
                />
                <WorkInfo isEditable={isWorkInfoEditable} />
            </div>
        </section>
    );
};

const mapStateToProps = (state: Store.IState): IProfileStateProps => {
    const isOwnProfile = state.user.id === state.currentUser.id;

    // @ts-ignore
    return {
        currentUserId: state.currentUser.userId,
        isPersonalInfoEditable: getFunctionalityAllowed(
            state.user,
            isOwnProfile
                ? FunctionalityName.OwnPersonalInfoEdit
                : FunctionalityName.ForeignPersonalInfoEdit
        ),
        isWorkInfoEditable: getFunctionalityAllowed(
            state.user,
            isOwnProfile
                ? FunctionalityName.OwnWorkInfoEdit
                : FunctionalityName.ForeignWorkInfoEdit
        ),
        canChangePassword: getFunctionalityAllowed(
            state.user,
            isOwnProfile
                ? FunctionalityName.OwnPasswordChange
                : FunctionalityName.ForeignPasswordChange
        ),
        currentUserFirstName: state.currentUser.firstName,
        currentUserMiddleName: state.currentUser.middleName,
        currentUserLastName: state.currentUser.lastName,
        avatar: state.user.avatar,
    };
};

const mapDispatchToProps = (dispatch): IProfileActionProps => ({
    onLoad(currentUserId: string): void {
        dispatch(
            fetchProfileAvatar({ userId: currentUserId, resolution: '300' })
        );
    },
});

export default scrollToTopOnMount(
    withSetDocumentTitle(connect(mapStateToProps, mapDispatchToProps)(Profile))
);
