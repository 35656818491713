import React, { PureComponent } from 'react';
import moment from 'moment';
import autoBind from 'autobind-decorator';

import { ChangePassword } from 'app/components/Modals';
import { DATE_DAY_AND_MONTH_FORMAT } from 'app/utils/constants';

import IProps from './interfaces/IPersonalInfoProps';
import IState from './interfaces/IPersonalInfoState';

import './PersonalInfo.scss';

export default class PersonalInfo extends PureComponent<IProps, IState> {
    state = {
        isPassChangeShown: false,
    };

    @autoBind
    onChangePasswordClick(): void {
        this.setState({ isPassChangeShown: true });
    }

    @autoBind
    onChangePasswordCancel(): void {
        this.setState({ isPassChangeShown: false });
    }

    render(): JSX.Element {
        const { email, birthDate, login } = this.props;
        return (
            <article className="personal-info">
                <ChangePassword
                    isVisible={this.state.isPassChangeShown}
                    onCancel={this.onChangePasswordCancel}
                />
                {birthDate && (
                    <div className="personal-info__field clearfix">
                        <div className="personal-info__title">
                            Дата рождения
                        </div>
                        <div className="personal-info__value">
                            {birthDate
                                ? moment(birthDate).format(
                                      DATE_DAY_AND_MONTH_FORMAT
                                  )
                                : ''}
                        </div>
                    </div>
                )}
                {Array.isArray(email) && email.length > 0 && (
                    <div className="personal-info__field clearfix">
                        <div className="personal-info__title">E-mail</div>
                        <div className="personal-info__value">
                            {email.map((em) => (
                                <div
                                    key={em}
                                    className="personal-info__sub-value"
                                >
                                    <a href={`mailto:${em}`}>{em}</a>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {login && (
                    <div className="personal-info__field clearfix">
                        <div className="personal-info__title">Логин</div>
                        <div className="personal-info__value">{login}</div>
                    </div>
                )}
            </article>
        );
    }
}
