/**
 * Created by Lkarmelo on 24.05.2018.
 */

import memoizeOne from 'memoize-one';

import * as Store from 'app/redux/store/StoreNamespace';
import { IOption } from 'app/components/common/controls/Option';

export const simpleCatalogItemsToOptions = memoizeOne(
    (
        items: Store.ISimpleCatalogItem[],
        keyAsValue: keyof Store.ISimpleCatalogItem = 'id'
    ): IOption[] =>
        items
            ? items.map((item) => ({
                  label: item.title,
                  value: item[<string>keyAsValue],
              }))
            : []
);
