/**
 * Created by Lkarmelo on 30.10.2017.
 */

import { MiddlewareAPI } from 'redux';
import { Observable } from 'rxjs/Observable';

import { pubSubImpl } from 'app/react-context-pubsub';

import * as Store from '../store/StoreNamespace';
import {
    filtersToDefaults,
    FiltersToDefaultsPayload,
} from '../actions/search/defaultFilters';
import { setFilters } from '../actions/search/filters';
import { ContextAction, withContext } from '../context/connectWithContext';
import { removeTags } from '../actions/search/tagsFromQuery';
import { withIgnoreSearchTriggerMeta } from '../utils/withIgnoreSearchTriggerMeta';

// некоторые изменения интерфейса костыльно сделаны эвентами, потому что так намного проще
export const CLEAR_FILTERS_EVENT = 'CLEAR_FILTERS_EVENT';

export const filtersToDefaultsEpic = (
    action$,
    store: MiddlewareAPI<Store.IState>
) =>
    action$
        .ofType(filtersToDefaults.toString())
        .do(() => {
            pubSubImpl.emitEvent(CLEAR_FILTERS_EVENT);
        })
        .mergeMap((action: ContextAction<FiltersToDefaultsPayload>) => {
            const shouldRemoveTags =
                !action.payload || action.payload.removeTags;
            return Observable.of(
                // на странице поиска запустится поиск только на removeTags, это надо, чтобы не было 2 запросов.
                // TODO: в contextLoadSearchResults эпики с фильтрами не используют .ignoreSearchActionsByMeta(), поэтому поиск всё равно
                // запустится, это поведение надо поправить и сделать одинаковым для страницы поиска и остальных страниц
                withIgnoreSearchTriggerMeta(
                    withContext(
                        setFilters(store.getState().defaultFilters),
                        action.context
                    )
                ),
                shouldRemoveTags
                    ? withContext(removeTags(), action.context)
                    : undefined
            ).filter(Boolean);
        });
