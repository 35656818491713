import { SearchDocumentName } from 'app/redux/actions/interfaces/DocumentEnum';
import { DEFAULT_PAGING_ITEMS_LIMIT } from 'app/utils/constants';

import * as Store from './StoreNamespace';

import {
    MAIN_TOUR_STEPS_VERSION,
    MAIN_TOUR_STEPS,
} from '../../components/common/SiteTour/MainTourSteps';
import clientRoute from "../../routing/clientRoutes";
/**
 * Created by Lkarmelo on 11.09.2017.
 */

const initialState: Store.IState = {
    filtersMeta: {},
    favoritesFiltersMeta: {},
    defaultFilters: {},
    mainSearchPageActiveTab: null,
    mainSearchPageActiveTabForResults: null,
    searchTabs: {},
    loading: {
        pendingRequests: {},
        loadingErrors: {},
        pendingRequestsCount: 0,
    },
    loadingBar: {
        default: 0,
    },
    authorization: {
        /*
            null означает, что неизвестно, залогинен ли пользователь или нет,
            потому что единственный способ это узнать - подождать ответ на 1-й запрос,
            которому нужна авторизация
         */
        isAuthorized: null,
        authorizationFailed: false,
        loginUrl: clientRoute.login.getUrl(),
    },

    fileStore: {
        files: {},
        keys: [],
    },
    passwordRecovery: {},
    navMenuDropdownVisibility: {
        isOpen: false,
    },

    search: {
        personStrict: null,
        searchQuery: '',
        ignoreMistakes: false,
        // null используется вместо undefined, потому что redux не позволяет иметь undefined как начальное значение
        lastExecutedSearchQuery: null,
        executingSearchQuery: null,
        lastExecutedSearchAlias: null,
        executingSearchAlias: null,
        resolvingSearchAlias: null,
        lastExecutedIgnoreMistakes: false,
        isQueryExpansion: false,
        filters: {},
        lastExecutedFilters: {},
        paging: {
            skip: 0,
            limit: DEFAULT_PAGING_ITEMS_LIMIT,
        },
        hints: {
            queries: [],
            persons: [],
        },
        initialFilterStats: {},
        queryTags: null,
        concepts: {
            concepts: {},
            extractedConcepts: [],
            selectState: {},
            initialSelectState: {},
        },
    },
    [SearchDocumentName.favorites]: {
        isQueryExpansion: false,
        paging: {
            skip: 0,
            limit: DEFAULT_PAGING_ITEMS_LIMIT,
        },
        sorting: {
            key: Store.sortingOptions.BookmarkedDateSortingOption.key,
            order: 'desc',
        },
    },
    [SearchDocumentName.readLater]: {
        isQueryExpansion: false,
        paging: {
            skip: 0,
            limit: DEFAULT_PAGING_ITEMS_LIMIT,
        },
    },
    [SearchDocumentName.related]: {
        isQueryExpansion: false,
        paging: {
            skip: 0,
            limit: DEFAULT_PAGING_ITEMS_LIMIT,
        },
    },
    [SearchDocumentName.uploadDocument]: {
        isQueryExpansion: false,
        paging: {
            skip: 0,
            limit: DEFAULT_PAGING_ITEMS_LIMIT,
        },
    },
    [SearchDocumentName.catalogueDocuments]: {
        isQueryExpansion: false,
        paging: {
            skip: 0,
            limit: DEFAULT_PAGING_ITEMS_LIMIT,
        },
        sorting: {
            key: Store.sortingOptions.DateSortingOption.key,
            order: 'desc',
        },
    },
    [SearchDocumentName.catalogueDocumentsNew]: {
        isQueryExpansion: false,
        paging: {
            skip: 0,
            limit: DEFAULT_PAGING_ITEMS_LIMIT,
        },
    },
    [SearchDocumentName.documentManagementOwn]: {
        isQueryExpansion: false,
        paging: {
            skip: 0,
            limit: DEFAULT_PAGING_ITEMS_LIMIT,
        },
    },
    [SearchDocumentName.documentManagementAll]: {
        isQueryExpansion: false,
        paging: {
            skip: 0,
            limit: DEFAULT_PAGING_ITEMS_LIMIT,
        },
    },
    [SearchDocumentName.education]: {
        isQueryExpansion: false,
        paging: {
            skip: 0,
            limit: DEFAULT_PAGING_ITEMS_LIMIT,
        },
        statusFilter: Store.EducationMaterialStatusValue.All,
        numberOfMaterials: {},
    },
    objectCard: {
        linksPaging: {
            skip: 0,
            limit: DEFAULT_PAGING_ITEMS_LIMIT,
        },
        groupedPaging: {
            skip: 0,
            limit: DEFAULT_PAGING_ITEMS_LIMIT,
        },
        groupedSorting: {
            'organizations.title.keyword': 'asc',
        },
        linksSorting: {
            'optionalAttrs.dateStart': 'desc',
        },
    },
    [SearchDocumentName.objectCardSimilar]: {
        isQueryExpansion: false,
        paging: {
            skip: 0,
            limit: DEFAULT_PAGING_ITEMS_LIMIT,
        },
    },
    guideResults: {
        stats: [],
        version: '',
        view: true,
        showTour: false,
        steps: new Map([[MAIN_TOUR_STEPS_VERSION, MAIN_TOUR_STEPS]]),
    },
    outsideRedirectInfo: {
        link: null,
        checkLinks: [],
    },
    [Store.NotificationContext]: {
        newCount: 0,
        notificationsIntervalId: -1,
    },
    conceptDescriptions: {},
    modals: {},
    doodle: {},
};

export default initialState;
