import React, { PureComponent } from 'react';
import Icon from 'antd/es/icon';

import { IOption } from 'app/components/common/controls/Option';
import dropdownContainer from 'app/components/common/utils/DropdownContainer/DropdownContainer';

import IProps from './interfaces/ITreeSelectListProps';
import IState from './interfaces/ITreeSelectListState';
import TreeOptionNode from './TreeOptionNode';

class TreeSelectList extends PureComponent<IProps, IState> {
    selectedOption(): IOption[] {
        const { active, options } = this.props;

        return (
            (active &&
                options.filter((opt) => active.indexOf(opt.value) !== -1)) ||
            []
        );
    }

    render(): JSX.Element {
        const { className, active, isClear, onDeselectOption } = this.props;

        const someSelected = Array.isArray(active) && active.length > 0;
        const selectedOptions = this.selectedOption();

        return (
            <div>
                <div className={`${className}__control`}>
                    <div>
                        {selectedOptions.length > 0 ? (
                            selectedOptions.map((opt) => (
                                <span
                                    key={opt.value}
                                    className={`${className}__option-tag`}
                                >
                                    <Icon
                                        className={`${className}__option-tag__remove-btn`}
                                        type="close"
                                        onClick={(e) => {
                                            e.preventDefault();

                                            onDeselectOption(
                                                opt.value,
                                                opt.label,
                                                opt.meta
                                            );
                                        }}
                                    />
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: opt.label,
                                        }}
                                    />
                                </span>
                            ))
                        ) : (
                            <span className={`${className}__option-none`}>
                                Не выбрано
                            </span>
                        )}
                    </div>
                    <div>
                        <button
                            className={`${className}__toggle-all-btn btn`}
                            onClick={() => {
                                const { onClear, onDeselectAllOptions } =
                                    this.props;

                                onDeselectAllOptions();

                                // reset search string
                                onClear && onClear();
                            }}
                        >
                            <span>
                                {((!isClear || someSelected) &&
                                    'очистить все') ||
                                    ''}
                            </span>
                        </button>
                    </div>
                </div>
                <TreeOptionNode {...this.props} />
            </div>
        );
    }
}

export default dropdownContainer(TreeSelectList);
