import React, {
    useEffect,
    useMemo,
    useRef,
    useCallback,
    useState,
} from 'react';
import classNames from 'classnames';
import { useMemoThrottled } from '@nkc-frontend/nkc-react-hooks';
import { usePrevious } from 'react-use';

import { useDidUpdate } from 'app/hooks/useDidUpdate';
import { useDidMount } from 'app/hooks/useDidMount';
import { debounce } from 'app/utils/debounce';

import { IntermediateFunc } from './TreeSelectWithSelectAllBtn.props';
import {
    collapsedOptionsCount,
    LIST_ACTION_DELAY,
    sortOptionsWithSubOptions,
} from './utils';

import OptionGroup from '../OptionGroup/OptionGroup';
import Option from '../Option/Option';
import SearchBar from '../SearchBar/SearchBar';
import Header from '../Header/Header';
import CollapseToggle from '../CollapseToggle/CollapseToggle';
import SelectAllToggle from '../SelectAllToggle/SelectAllToggle';
import { useDescendantsAscendantsMaps } from '../List/useDescendantsAscendatsMaps';
import { RegularOptionsRenderer } from '../List/RegularOptionsRenderer';

export interface ISelectOption {
    value: string;
    label: string;
    options?: ISelectOption[];
    count?: number;
    fixed?: boolean;
    [key: string]: any;
}

type AnyProps = { [propName: string]: any };

export interface IProps<
    ListProps extends AnyProps = AnyProps,
    HeaderProps extends AnyProps = AnyProps,
    SearchBarProps extends AnyProps = AnyProps,
    CollapseToggleProps extends AnyProps = AnyProps
> {
    options: ISelectOption[];
    active: string[];
    intermediate?:
        | string[]
        | ((active: Record<string, boolean>) => string[])
        | IntermediateFunc;
    className?: string;
    collapsible?: boolean;
    collapsed?: boolean;
    initiallyCollapsed?: boolean;
    searchQuery?: string;
    optionFilteringThrottleWait?: number;
    hierarchical?: boolean;
    isTags?: boolean;
    /**
     * высота элемента списка при включённом виртуальном рендеринге, см. документацию react-vtree и react-window
     */

    headerComponentProps?: Partial<HeaderProps>;
    searchBarComponentProps?: Partial<SearchBarProps>;
    listComponentProps?: Partial<ListProps>;
    collapseToggleComponentProps?: Partial<CollapseToggleProps>;
    HeaderComponent?: React.ComponentType<HeaderProps>;
    SearchBarComponent?: React.ComponentType<SearchBarProps>;
    CollapseToggleComponent?: React.ComponentType<CollapseToggleProps>;

    onSelect?: ListProps['onSelect'];
    onDeselect?: ListProps['onDeselect'];
    getFilteredOptions?(
        searchQuery: string,
        options: ISelectOption[]
    ): ISelectOption[];
}

const defaultGetFilteredOptions = (
    searchQuery: string,
    options: ISelectOption[]
) => {
    if (!searchQuery) {
        return options;
    }

    const searchQueryLowerCase = searchQuery.toLowerCase();

    const optionFilterCb = (result: ISelectOption[], option: ISelectOption) => {
        const { label, options: subOptions } = option;

        const searchQueryInLabel = label
            .toLowerCase()
            .includes(searchQueryLowerCase);

        if (Array.isArray(subOptions)) {
            const filteredSubOptions = subOptions.reduce(optionFilterCb, []);

            if (searchQueryInLabel) {
                const optionToPush = {
                    ...option,
                    options:
                        filteredSubOptions.length > 0
                            ? filteredSubOptions
                            : null,
                };
                result.push(optionToPush);
            } else if (filteredSubOptions.length > 0) {
                result.push(...filteredSubOptions);
            }

            return result;
        }

        searchQueryInLabel && result.push(option);
        return result;
    };

    return options.reduce(optionFilterCb, []);
};

const TreeSelectWithSelectAllBtn = React.forwardRef<HTMLDivElement, IProps>(
    (props, forwardedRef) => {
        const {
            options,
            active,
            HeaderComponent,
            headerComponentProps,
            listComponentProps: { compareOptions, noFoundPlaceholder },
            getFilteredOptions,
            onSelect,
            onDeselect,
            collapsible,
            initiallyCollapsed,
            collapseToggleComponentProps: { onAfterToggleChange },
            className,
            searchQuery: searchQueryProp,
            intermediate,
            optionFilteringThrottleWait,
            hierarchical,
            collapsed: collapsedProp,
            isTags,
        } = props;

        const prevSearchQueryProp = usePrevious(searchQueryProp);
        const prevCollapsedProp = usePrevious(collapsedProp);

        const [isCollapsed, setIsCollapsed] = useState(initiallyCollapsed);
        const [searchQueryState, setSearchQueryState] = useState('');
        const [isAllSelected, setIsAllSelected] = useState(false);

        useDidMount(() => {
            if (
                typeof searchQueryProp === 'string' &&
                prevSearchQueryProp !== searchQueryProp &&
                searchQueryProp !== searchQueryState
            ) {
                setSearchQueryState(searchQueryProp);
            }
            if (
                typeof collapsedProp === 'boolean' &&
                prevCollapsedProp !== collapsedProp &&
                collapsedProp !== isCollapsed
            ) {
                setIsCollapsed(collapsedProp);
            }

            setIsAllSelected(active.length === options.length);
        });

        const filterOptions = useMemoThrottled(
            () =>
                (opts: ISelectOption[]): ISelectOption[] =>
                    getFilteredOptions(searchQueryState, opts),
            [getFilteredOptions],
            [searchQueryState],
            optionFilteringThrottleWait,
            false,
            true
        );

        const toggleCollapse = useCallback(
            (nextCollapseState: boolean) => {
                setIsCollapsed(nextCollapseState);
                nextCollapseState && setSearchQueryState('');
            },
            [setIsCollapsed]
        );

        const isSelectAllBtnVisible = () =>
            !hierarchical && !isTags && !isCollapsed;

        const [sortedOptions, setSortedOptions] = useState<ISelectOption[]>([]);
        const [filteredOptions, setFilteredOptions] = useState<ISelectOption[]>(
            []
        );
        const [optionsToRender, setOptionsToRender] = useState<ISelectOption[]>(
            []
        );
        const isDisabledAction = useRef(false);
        const wrapperRef = useRef<HTMLDivElement>();

        const activeMap = useMemo<Record<string, boolean>>(
            () =>
                active.reduce((map, activeElement) => {
                    map[activeElement] = true;
                    return map;
                }, {}),
            [active]
        );

        const { ascendantsMap, descendantsMap } =
            useDescendantsAscendantsMaps(options);

        const intermediateValues = useMemo(() => {
            if (typeof intermediate === 'function') {
                return intermediate(activeMap, ascendantsMap, descendantsMap);
            }
            return intermediate;
        }, [intermediate, activeMap, ascendantsMap, descendantsMap]);

        const intermediateMap = useMemo<Record<string, boolean>>(
            () =>
                intermediateValues.reduce((map, intermediateElement) => {
                    map[intermediateElement] = true;
                    return map;
                }, {}),
            [intermediateValues]
        );

        const debouncedSetSortedOptions = debounce(
            () =>
                setSortedOptions(
                    typeof compareOptions === 'function'
                        ? sortOptionsWithSubOptions(
                              options,
                              compareOptions,
                              activeMap
                          )
                        : options
                ),
            LIST_ACTION_DELAY
        );

        useEffect(() => {
            isDisabledAction.current
                ? debouncedSetSortedOptions()
                : setSortedOptions(
                      typeof compareOptions === 'function'
                          ? sortOptionsWithSubOptions(
                                options,
                                compareOptions,
                                activeMap
                            )
                          : options
                  );
        }, [options, compareOptions, activeMap]);

        useEffect(() => {
            setFilteredOptions(
                typeof filterOptions === 'function'
                    ? filterOptions(sortedOptions)
                    : sortedOptions
            );
        }, [sortedOptions, filterOptions]);

        useEffect(() => {
            setOptionsToRender(
                collapsible && isCollapsed
                    ? filteredOptions.slice(0, collapsedOptionsCount)
                    : filteredOptions
            );
        }, [filteredOptions, isCollapsed, collapsedOptionsCount]);

        const _onSelect = useCallback(
            (opt) => {
                isDisabledAction.current = false;
                onSelect(opt, descendantsMap, ascendantsMap);
            },
            [onSelect, descendantsMap, ascendantsMap]
        );

        const _onDeselect = useCallback(
            (opt) => {
                isDisabledAction.current = true;
                onDeselect(opt, descendantsMap, ascendantsMap);
            },
            [onDeselect, descendantsMap, ascendantsMap]
        );

        const getSortedOptionChildren = useCallback(
            (optionsToSort: ISelectOption[]): ISelectOption[] => {
                if (typeof compareOptions === 'function') {
                    return optionsToSort;
                }
                return optionsToSort;
            },
            [activeMap, compareOptions]
        );

        useDidUpdate(() => {
            Object.keys(activeMap).length === optionsToRender.length &&
                !isAllSelected &&
                setIsAllSelected(true);
            Object.keys(activeMap).length !== optionsToRender.length &&
                isAllSelected &&
                setIsAllSelected(false);
        }, [activeMap, optionsToRender]);

        const handleToggleSelectedBtn = (nextSelectAllState: boolean) => {
            setIsAllSelected(nextSelectAllState);
            if (nextSelectAllState) optionsToRender.map(_onSelect);

            if (!nextSelectAllState)
                Object.keys(activeMap).length !== optionsToRender.length - 1 &&
                    optionsToRender.map(_onDeselect);
        };

        return (
            <div
                ref={forwardedRef}
                className={classNames(
                    'nkcm-select',
                    'nkcm-select-with-select-all-btn',
                    className,
                    {
                        'nkcm-select--collapsible': collapsible,
                        'nkcm-select--collapsed': isCollapsed,
                    }
                )}
            >
                <HeaderComponent
                    searchQuery={searchQueryState}
                    collapsed={collapsible && isCollapsed}
                    {...headerComponentProps}
                />
                <SearchBar
                    searchQuery={searchQueryState}
                    setSearchQuery={setSearchQueryState}
                    collapsed={isCollapsed}
                />
                <SelectAllToggle
                    isVisible={isSelectAllBtnVisible()}
                    selectAll={isAllSelected}
                    toggleSelectAll={handleToggleSelectedBtn}
                />
                <div
                    className={classNames('nkcm-select-list')}
                    ref={wrapperRef}
                >
                    <RegularOptionsRenderer
                        activeMap={activeMap}
                        descendantsMap={descendantsMap}
                        intermediateMap={intermediateMap}
                        options={optionsToRender}
                        onSelect={_onSelect}
                        onDeselect={_onDeselect}
                        getSortedOptionChildren={getSortedOptionChildren}
                        OptionGroupComponent={OptionGroup}
                        OptionComponent={Option}
                        withDelay
                    />
                    {optionsToRender.length === 0 &&
                        !!searchQueryState &&
                        !!noFoundPlaceholder && (
                            <div className="nkcm-select-list__no-found-placeholder">
                                {noFoundPlaceholder}
                            </div>
                        )}
                </div>
                <CollapseToggle
                    collapsible={collapsible}
                    collapsed={isCollapsed}
                    toggleCollapse={toggleCollapse}
                    onAfterToggleChange={onAfterToggleChange}
                />
            </div>
        );
    }
);

TreeSelectWithSelectAllBtn.defaultProps = {
    initiallyCollapsed: true,
    optionFilteringThrottleWait: 0,
    getFilteredOptions: defaultGetFilteredOptions,
    HeaderComponent: Header,
    onDeselect: () => {},
    active: [],
    intermediate: [],
};

export default TreeSelectWithSelectAllBtn;
