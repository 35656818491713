import * as React from 'react';

import connectWithContext, {
    IInjectedProps,
} from 'app/redux/context/connectWithContext';
import * as Store from 'app/redux/store/StoreNamespace';
import { IDocumentPanelStateProps } from 'app/components/document/DocumentPanel/interfaces/IDocumentPanelProps';
import {
    documentState,
    SearchDocumentName,
} from 'app/redux/actions/interfaces/DocumentEnum';

import mapActionToProps from '../../../document/DocumentPanelActionDefault';
import filterPanelFunc from '../../../document/FiltersPanel/DocumentFilterPanel';
import DocumentPanel from '../../../document/DocumentPanel/DocumentPanelConnected';
import { createSortingPanel } from '../../../common/SortingPanel/SortingPanel';

const subscribeState = documentState(SearchDocumentName.subscribes);

const DocumentFilterPanel = filterPanelFunc({
    rootContext: `${SearchDocumentName.subscribes}.filters`,
    context: subscribeState,
    searchDocumentName: SearchDocumentName.subscribes,
});

const SortingPanel = createSortingPanel(
    SearchDocumentName.subscribes,
    `${SearchDocumentName.subscribes}.sorting`
);

const sortingPanelOptions = [
    Store.sortingOptions.BookmarkedDateSortingOption,
    Store.sortingOptions.DateSortingOption,
];
const sortingPanelDefaultSort: Store.ISorting = {
    key: Store.sortingOptions.BookmarkedDateSortingOption.key,
    order: 'desc',
};

const mapStateToFactoryProps = () => {
    const constProps = {
        renderFilterPanel: (): JSX.Element => {
            return <DocumentFilterPanel />;
        },
        checkRemoved: (doc) => !doc.subscribed,
        renderSortingPanel: () => (
            <SortingPanel
                options={sortingPanelOptions}
                defaultSort={sortingPanelDefaultSort}
            />
        ),
    };

    return (
        state: Store.IState
    ): Store.IDocumentResults & IInjectedProps & IDocumentPanelStateProps => {
        const actionState: Store.ISearch = subscribeState(state);

        return {
            rootContext: SearchDocumentName.subscribes,
            currentUserId: state.currentUser.id,
            isLoading: state.loading.pendingRequests.search,
            limit: actionState.paging.limit,
            skip: actionState.paging.skip,
            ...actionState.results,
            ...constProps,
        };
    };
};

export default connectWithContext(
    mapStateToFactoryProps,
    mapActionToProps
)(DocumentPanel);
