/**
 * Created by Lkarmelo on 29.12.2017.
 */

import React, { PureComponent } from 'react';
import d3 from 'd3/dist/d3';
import _tip from 'd3-tip';
import autoBind from 'autobind-decorator';

import clientRoutes from 'app/routing/clientRoutes';
import history from 'app/history';
import * as Store from 'app/redux/store/StoreNamespace';

import IProps from './interfaces/IChartProps';

const d3Tip = _tip.bind(d3);

export default class Chart extends PureComponent<IProps> {
    static defaultProps = {
        categories: [],
    };

    chartContainerRef: React.RefObject<HTMLDivElement> = React.createRef();

    tip = null;

    render(): JSX.Element {
        return <div id="categories-chart" ref={this.chartContainerRef} />;
    }

    componentDidMount() {
        this.drawChart(this.props);
    }

    componentDidUpdate() {
        const chartContainer = this.chartContainerRef.current;
        while (chartContainer.firstChild) {
            chartContainer.removeChild(chartContainer.firstChild);
        }
        d3.selectAll('.categories__tooltip-container').remove();
        this.drawChart(this.props);
    }

    componentWillUnmount() {
        d3.selectAll('.categories__tooltip-container').remove();
    }

    drawChart(props: IProps) {
        const totalDocsCount = props.categories.reduce(
            (sum, cat) => sum + cat.docsCount,
            0
        );

        const containerWidth = this.chartContainerRef.current.offsetWidth;
        const containerHeight = this.chartContainerRef.current.offsetHeight;
        const innerArcOuterRadius =
            Math.min(containerWidth, containerHeight) / 2;

        const arc = d3
            .arc()
            .outerRadius(innerArcOuterRadius)
            .innerRadius(35)
            .padAngle(0.03);

        const outerArc = d3
            .arc()
            .outerRadius(innerArcOuterRadius - 5)
            .innerRadius(innerArcOuterRadius);

        const pie = d3
            .pie<Store.IKnowledgeCategory>()
            .value((d) => d.docsCount);

        const svg = d3.select('#categories-chart').append('svg');

        const innerArcGroup = svg
            .attr('class', 'categories__chart-svg')
            .attr('width', '100%')
            .attr('height', '100%')
            .attr(
                'viewBox',
                `0 0 ${innerArcOuterRadius * 2} ${innerArcOuterRadius * 2}`
            )
            .attr('preserveAspectRatio', 'xMinYMin')
            .append('g')
            .attr(
                'transform',
                `translate(${innerArcOuterRadius}, ${innerArcOuterRadius})`
            );

        const outerArcGroup = svg
            .append('g')
            .attr(
                'transform',
                `translate(${innerArcOuterRadius}, ${innerArcOuterRadius})`
            );

        const g = innerArcGroup
            .selectAll('.arc')
            .data(pie(props.categories))
            .enter()
            .append('g')
            .attr('class', 'arc');

        const gO = outerArcGroup
            .selectAll('.arc-outer')
            .data(pie(props.categories))
            .enter()
            .append('g')
            .attr('class', 'arc-outer');

        const tip = d3Tip()
            .attr('class', 'categories__tooltip categories__tooltip-container')
            .offset([40, 0])
            .html((d) => {
                const { data } = d;
                tip.attr('data-categoryId', d.data.item.id);
                const relatedDocsLinkWithCategory =
                    clientRoutes.account.subRoutes.related.getUrl(null, {
                        'category-id': data.item.code,
                    });
                const { search } = window.location;

                return `
                        <a
                            href="${clientRoutes.catalogue.getUrl({
                                id: data.item.code,
                            })}"
                            class="categories__tooltip categories__tooltip-title"
                        >
                            ${data.item.title}
                        </a>
                        <div class="categories__tooltip categories__tooltip-text">
                            Количество связанных документов
                            <a
                                class="categories__tooltip categories__tooltip-value"
                                href="${relatedDocsLinkWithCategory}${
                    search ? `&${search.slice(1)}` : ''
                }"
                            >
                                ${data.docsCount}
                            </a>
                            <span class="categories__tooltip categories__tooltip-percent">
                                ${(
                                    (data.docsCount / totalDocsCount) *
                                    100
                                ).toFixed(2)}%
                            </span>
                        </div>
                    `;
            });

        this.tip = tip;

        innerArcGroup.call(tip);

        d3.select('.categories__tooltip-container').on(
            'mouseleave',
            function () {
                if (
                    d3.event.relatedTarget &&
                    d3.event.relatedTarget.nodeName !== 'path'
                ) {
                    tip.hide();
                }
                if (
                    d3.event.relatedTarget &&
                    d3.event.relatedTarget.dataset.categoryId !==
                        (this as HTMLElement).dataset.categoryid
                ) {
                    innerArcGroup
                        .select(
                            `.categories__chart-percentage[data-categoryId="${
                                (this as HTMLElement).dataset.categoryid
                            }"]`
                        )
                        .style('display', 'none');
                }
            }
        );

        innerArcGroup.on('mouseout', () => {
            if (
                d3.event.relatedTarget &&
                !d3.event.relatedTarget.classList.contains(
                    'categories__tooltip'
                ) &&
                !d3.event.relatedTarget.classList.contains(
                    'categories__chart-percentage'
                ) &&
                !d3.event.relatedTarget.classList.contains(
                    'categories__chart-slice'
                )
            ) {
                setTimeout(() => {
                    const hoveredElements = document.querySelectorAll(':hover');
                    let isToolTipHovered = false;
                    for (let i = 0; i < hoveredElements.length; i++) {
                        if (
                            hoveredElements[i].classList.contains(
                                'categories__tooltip'
                            )
                        ) {
                            isToolTipHovered = true;
                            break;
                        }
                    }
                    if (!isToolTipHovered) {
                        tip.hide();
                    }
                }, 400);
            }
        });

        g.append('path')
            .attr('d', arc as any)
            .attr('class', 'categories__chart-slice')
            .attr('data-categoryId', (d) => (d as any).data.item.id)
            .style('fill', (d) => props.colorsMap[(d as any).data.item.id])
            .style('opacity', 0.25)
            .on('mouseover', (d) => {
                innerArcGroup
                    .selectAll(`.categories__chart-percentage`)
                    .style('display', 'none');
                innerArcGroup
                    .select(
                        `.categories__chart-percentage[data-categoryId="${
                            (d as any).data.item.id
                        }"]`
                    )
                    .style('display', 'block');
            })
            .on('mouseout', (d) => {
                if (
                    d3.event.relatedTarget &&
                    !d3.event.relatedTarget.classList.contains(
                        'categories__chart-percentage'
                    ) &&
                    !d3.event.relatedTarget.classList.contains(
                        'categories__tooltip'
                    )
                ) {
                    innerArcGroup
                        .select(
                            `.categories__chart-percentage[data-categoryId="${
                                (d as any).data.item.id
                            }"]`
                        )
                        .style('display', 'none');
                }
            })
            .on('click', this.getOnShowTip());

        gO.append('path')
            .attr('d', outerArc as any)
            .style('fill', (d) => props.colorsMap[(d as any).data.item.id]);

        g.append('text')
            .attr('transform', (d) => `translate(${arc.centroid(d as any)})`)
            .attr('class', 'categories__chart-percentage')
            .attr('data-categoryId', (d) => (d as any).data.item.id)
            .style('display', 'none')
            .style('text-anchor', 'middle')
            .style('fill', (d) => props.colorsMap[(d as any).data.item.id])
            .text(
                (d) =>
                    `${(
                        ((d as any).data.docsCount / totalDocsCount) *
                        100
                    ).toFixed(2)}%`
            )
            .on('click', this.getOnShowTip())
            .on('mouseout', function (d: any) {
                if (
                    d3.event.relatedTarget &&
                    !d3.event.relatedTarget.dataset.categoryId !==
                        d.data.item.id &&
                    !d3.event.relatedTarget.classList.contains(
                        'categories__tooltip'
                    )
                ) {
                    (this as any).style.display = 'none';
                }
            });
    }

    showTip(id: string): void {
        d3.select(`.categories__chart-slice[data-categoryId="${id}"]`).dispatch(
            'click'
        );
    }

    hideTip(): void {
        this.tip.hide();
    }

    getTipElement(): HTMLElement {
        return document.querySelector('.categories__tooltip');
    }

    showSlicePercentage(id: string): void {
        d3.select(`.categories__chart-slice[data-categoryId="${id}"]`).dispatch(
            'mouseover'
        );
    }

    @autoBind
    getOnShowTip(): () => void {
        const _this = this;
        return function () {
            _this.tip.show.apply(this, arguments);
            const links = document.querySelectorAll(
                '.categories__tooltip.categories__tooltip-container a'
            );
            Array.prototype.forEach.call(links, (link: HTMLAnchorElement) => {
                // При каждом рендере d3-tip убирает из dom'а элемент и заменяет новым, так что removeEventListener не обязателен.
                link.addEventListener(
                    'click',
                    (e: HTMLElementEventMap['click']) => {
                        e.preventDefault();
                        const href = (
                            e.target as HTMLAnchorElement
                        ).getAttribute('href');
                        history.push(href);
                    }
                );
            });
        };
    }
}
