/**
 * Created by Lkarmelo on 04.12.2017.
 */

import * as React from 'react';
import { connect } from 'react-redux';
import autoBind from 'autobind-decorator';

import AbstractEditablePanelContainer from 'app/components/common/forms/AbstractEditablePanelContainer';
import EditablePanel from 'app/components/common/forms/EditablePanel';
import { userKnowledgeCategoriesSortedSelector } from 'app/redux/selectors/userKnowledgeCategoriesSorted';
import * as Store from 'app/redux/store/StoreNamespace';

import IProps, {
    ICategoriesOwnProps as IOwnProps,
    ICategoriesStateProps as IStateProps,
} from './interfaces/ICategoriesProps';

import PresentationalCategories from '../presentational/Categories';

@autoBind
class Categories extends AbstractEditablePanelContainer<IProps> {
    static defaultProps = {
        categories: [],
    };

    render(): JSX.Element {
        return (
            <EditablePanel
                isEditable={this.state.isEditable}
                title="КАТЕГОРИИ ЗНАНИЙ"
                onStartEdit={this.startEdit}
                onCancelEdit={this.cancelEdit}
                onSave={this.save}
                hideEditBtn
            >
                <PresentationalCategories
                    onTruncateChange={this.props.onTruncateChange}
                    categories={this.props.categories}
                />
            </EditablePanel>
        );
    }

    componentDidUpdate(): void {
        if (this.props.onUpdate) this.props.onUpdate(this.state.isEditable);
    }
}

const mapStateToProps = (
    state: Store.IState,
    props: IOwnProps
): IStateProps & IOwnProps => ({
    categories: userKnowledgeCategoriesSortedSelector(state),
    onUpdate: props.onUpdate,
});

export default connect(mapStateToProps)(Categories);
