/**
 * Created by Lkarmelo on 12.02.2018.
 */

import { handleActions } from 'redux-actions';

import { ADD_SKILL, SET_SKILLS } from '../actions/skills';
import * as Store from '../store/StoreNamespace';

export default handleActions<
    Store.ISimpleCatalogItem[],
    Store.ISimpleCatalogItem[] | Store.ISimpleCatalogItem
>(
    {
        [SET_SKILLS](
            state: Store.ISimpleCatalogItem[],
            { payload }: { payload: Store.ISimpleCatalogItem[]; type: string }
        ) {
            return payload;
        },
        [ADD_SKILL](
            state: Store.ISimpleCatalogItem[],
            { payload }: { payload: Store.ISimpleCatalogItem; type: string }
        ) {
            const nextState: Store.ISimpleCatalogItem[] = state.slice();
            nextState.push(payload);
            return nextState;
        },
    },
    [] // - default value for skills
);
