/**
 * Created by lkarmelo on 29.11.2019.
 */

import { ISelectOption } from '../modules/components/TreeSelect';
import * as Store from '../redux/store/StoreNamespace';

export const knowledgeCategoriesToSelectOption = (
    categories: Store.INormalizedHierarchicalCategories
) => {
    const reduceCategories = (categoriesIds: string[]) => {
        return categoriesIds.reduce(
            (options: ISelectOption[], categoryId: string) => {
                const category = categories.categories[categoryId];
                const option: ISelectOption = {
                    label: category.item.title,
                    value: category.item.id,
                };
                if (category.subItems && category.subItems.length > 0) {
                    option.options = reduceCategories(category.subItems);
                }
                options.push(option);
                return options;
            },
            []
        );
    };
    return categories.topCategories
        ? reduceCategories(categories.topCategories)
        : [];
};
