/**
 * Created by Lkarmelo on 19.06.2018.
 */

import { createAction } from 'redux-actions';

import * as Store from 'app/redux/store/StoreNamespace';

export const fetchOrgStructure = createAction('FETCH_ORGSTRUCTURE');

export const setOrgStructure =
    createAction<Store.INormalizedOrgStructure>('SET_ORGSTRUCTURE');
