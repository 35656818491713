import * as React from 'react';
import * as info from 'preval-build-info';

import styles from './BuildInfo.scss';

export const BuildInfo = () => {
    if (!info) {
        return (
            <div className={styles.buildInfo}>
                Информация по сборке не найдена
            </div>
        );
    }

    return (
        <div className={styles.buildInfo}>
            <div className={styles.buildInfoTitle}>Информация о сборке</div>
            {Object.entries(info).map((item) => (
                <div key={item[0]} className={styles.buildInfoRow}>
                    <div className={styles.buildInfoColumnTitle}>{item[0]}</div>
                    <div className={styles.buildInfoColumnDescription}>
                        {item[1]}
                    </div>
                </div>
            ))}
        </div>
    );
};
