import { Link } from 'react-router-dom';
import React from 'react';

import {
    FeatureRenderer,
    UIFeaturesName,
} from 'app/components/common/utils/FeatureRenderer';
import clientRoutes from 'app/routing/clientRoutes';
import { FunctionalityName, getFunctionalityAllowed } from 'app/roles';
import { useTypedSelector } from 'app/hooks/useTypedSelector';
import * as styles from '../NavigationMenu.scss';
const searchImgPath = require('images/docSearching.svg');
const adminImgPath = require('images/admin.svg');
const catalogueImgPath = require('images/catalog.svg');

const Content = ({ onClose }: { onClose: () => void }) => {
    const { user } = useTypedSelector((store) => store);

    const shouldRenderAdminPageLink = getFunctionalityAllowed(
        user,
        FunctionalityName.AdminPageLinkView
    );



    return (
        <nav>
            <ul>
                {/*<FeatureRenderer featureName={UIFeaturesName.SearchLink}>
                    <li
                        className={classNames(
                            styles.navMenuLinkWrapper,
                            styles.navMenuTour
                        )}
                    >
                        <img src={tourImagePath} alt="Обзор системы" />
                        <Link
                            onClick={onTourClick}
                            to={clientRoutes.search.getUrl()}
                            className={styles.navMenuLink}
                        >
                            Обзор системы
                        </Link>
                    </li>
                </FeatureRenderer>*/}
                <FeatureRenderer featureName={UIFeaturesName.SearchLink}>
                    <li className={styles.navMenuLinkWrapper}>
                        <img src={searchImgPath} alt="Поиск" />
                        <Link
                            onClick={onClose}
                            to={clientRoutes.search.getUrl()}
                            className={styles.navMenuLink}
                        >
                            Поиск
                        </Link>
                    </li>
                </FeatureRenderer>
                <FeatureRenderer featureName={UIFeaturesName.CatalogueLink}>
                    <li className={styles.navMenuLinkWrapper}>
                        <img src={catalogueImgPath} alt="Каталог" />
                        <Link
                            onClick={onClose}
                            to={clientRoutes.catalogue.getUrl({})}
                            className={styles.navMenuLink}
                        >
                            Каталог
                        </Link>
                    </li>
                </FeatureRenderer>
                {/*<FeatureRenderer
                    featureName={UIFeaturesName.DocumentManagementLink}
                >
                    <li className={styles.navMenuLinkWrapper}>
                        <img
                            src={documentManagementImgPath}
                            alt="Обучающие материалы"
                        />
                        <Link
                            onClick={onClose}
                            to={clientRoutes.documentManagement.subRoutes.own.getUrl()}
                            className={styles.navMenuLink}
                        >
                            Обучающие материалы
                        </Link>
                    </li>
                </FeatureRenderer>*/}
                {shouldRenderAdminPageLink && (
                    <>
                        {/*<FeatureRenderer featureName={UIFeaturesName.AdminLink}>
                            <li className={styles.navMenuLinkWrapper}>
                                <img src={linkImgPath} alt="Сервисы" />
                                <a
                                    onClick={onClose}
                                    href={clientRoutes.service.getUrl()}
                                    className={styles.navMenuLink}
                                >
                                    Сервисы
                                </a>
                            </li>
                        </FeatureRenderer>*/}
                        <FeatureRenderer featureName={UIFeaturesName.AdminLink}>
                            <li className={styles.navMenuLinkWrapper}>
                                <img
                                    src={adminImgPath}
                                    alt="Администрирование"
                                />
                                <a
                                    onClick={onClose}
                                    href={clientRoutes.admin.getUrl()}
                                    className={styles.navMenuLink}
                                >
                                    Администрирование
                                </a>
                            </li>
                        </FeatureRenderer>
                    </>
                )}
               {/* <FeatureRenderer featureName={UIFeaturesName.DVALink}>
                    {DVA_LINKS.map(({ url, label }) => (
                        <li key={label} className={styles.navMenuLinkWrapper}>
                            <img src={linkImgPath} alt={label} />
                            <a
                                onClick={onClose}
                                href={url}
                                target="_blank"
                                className={styles.navMenuLink}
                                rel="noreferrer"
                            >
                                {label}
                            </a>
                        </li>
                    ))}
                </FeatureRenderer>*/}
            </ul>
        </nav>
    );
};

export default Content;
