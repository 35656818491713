/**
 * Created by Lkarmelo on 26.12.2017.
 */

import React, { PureComponent } from 'react';
import classNames from 'classnames';
import autoBind from 'autobind-decorator';

import IProps from './interfaces/IMultipleInputFieldsProps';

import InputField from '../InputField';

import './MultipleInputFields.scss';

@autoBind
export default class MultipleInputFields extends PureComponent<IProps> {
    static defaultProps = {
        maxSubFields: Number.MAX_VALUE,
        subFieldsMetas: [],
        ids: [],
        errorMessages: [],
    };

    render(): JSX.Element {
        const {
            values,
            titles,
            subFieldsMetas,
            meta,
            isEditable,
            isDeletable,
            ids,
            onRemoveField,
            addBtnText,
            errorMessages,
            cssClass,
            classPrefix,
            maxSubFields,
            isRequired,
        } = this.props;
        return (
            <div className="multiple-input-fields">
                {values.map((val, index) => {
                    const isFieldEditable = isEditable
                        ? isEditable(
                              val,
                              subFieldsMetas[index],
                              meta,
                              ids[index]
                          )
                        : true;
                    const isFieldDeletable = isDeletable
                        ? isDeletable(
                              val,
                              subFieldsMetas[index],
                              meta,
                              ids[index]
                          )
                        : true;
                    subFieldsMetas[index] &&
                        (subFieldsMetas[index].index = index);
                    return (
                        <div
                            key={val}
                            className={classNames(
                                'multiple-input-fields__field-wrapper',
                                {
                                    'multiple-input-fields__field-wrapper--deletable':
                                        isFieldDeletable,
                                }
                            )}
                        >
                            <InputField
                                isRequired={isRequired}
                                value={val}
                                title={titles[index]}
                                meta={subFieldsMetas[index]}
                                errorMessages={errorMessages[index]}
                                onChange={this.changeField}
                                isDisabled={!isFieldEditable}
                                cssClass={cssClass}
                                classPrefix={classPrefix}
                                onBlur={this.onInputBlur}
                                onFocus={this.onInputFocus}
                            />
                            {isFieldDeletable && (
                                <button
                                    className="multiple-input-fields__field-remove btn"
                                    onClick={() =>
                                        onRemoveField(
                                            val,
                                            subFieldsMetas[index],
                                            meta
                                        )
                                    }
                                />
                            )}
                        </div>
                    );
                })}
                {values.length < maxSubFields && (
                    <button
                        className="multiple-input-fields__add btn"
                        onClick={this.addField}
                    >
                        <span />
                        {addBtnText}
                    </button>
                )}
            </div>
        );
    }

    changeField(value: string, subFieldMeta?: any, id?: string) {
        const { onChange, meta } = this.props;
        onChange(value, subFieldMeta, meta, id);
    }

    addField() {
        this.props.onAddField(this.props.meta);
    }

    onInputFocus(
        e: React.FocusEvent<HTMLInputElement>,
        subFieldMeta: any
    ): void {
        this.props.onFocus && this.props.onFocus(subFieldMeta);
    }

    onInputBlur(
        e: React.FocusEvent<HTMLInputElement>,
        subFieldMeta: any
    ): void {
        this.props.onBlur && this.props.onBlur(subFieldMeta);
    }
}
