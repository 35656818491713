import { TOP_LEVEL_CATALOGUE_CATEGORY_LABEL } from 'app/utils/constants';

import * as Store from '../../../redux/store/StoreNamespace';

export const EMPTY_OPTION = {
    label: TOP_LEVEL_CATALOGUE_CATEGORY_LABEL,
    value: null,
};

export const isRemovingCategoryDescendant = (
    currentCategoryId: string,
    categoryToCheckId: string,
    categories: Store.INormalizedHierarchicalCategories
) => {
    if (currentCategoryId === categoryToCheckId) {
        return true;
    }
    const category =
        categories &&
        categories.categories &&
        categoryToCheckId &&
        categories.categories[currentCategoryId];
    return (
        category.parent &&
        isRemovingCategoryDescendant(
            category.parent,
            categoryToCheckId,
            categories
        )
    );
};
