import { createSelector, Selector } from 'reselect';

import * as Store from 'app/redux/store/StoreNamespace';
import { ISearchConcepts, SearchTarget } from 'app/redux/store/StoreNamespace';
import Api from 'app/api/Api';
import { SearchDocumentName } from 'app/redux/actions/interfaces/DocumentEnum';
import { replaceDashesWithDots } from 'app/utils/filters/filterNamesEscape';

import { getFilterValue } from './getFilterValue';

import { selectedExpansionTagsSelector } from '../selectedExpansionTags';
import { filtersValuesSelector, IFiltersValues } from '../filters';

const ignoreFilters: string[] = [
    Store.StaticFilterName.Search,
    Store.StaticFilterName.Thesaurus,
];

export interface ISearchResultSelectorProps {
    state: Store.IState;
    actionState: Store.ISearch;
    searchDocumentName: SearchDocumentName;
}

const filtersMetaSelector: Selector<
    ISearchResultSelectorProps,
    Store.IFiltersMeta
> = ({ state }) => state.filtersMeta;

const filtersSelector: Selector<ISearchResultSelectorProps, IFiltersValues> = ({
    actionState,
}) => filtersValuesSelector(actionState);

const pagingSelector: Selector<ISearchResultSelectorProps, Store.IPaging> = ({
    actionState,
}) => actionState.paging;

const selectedExpansionTags = ({
    state,
}: ISearchResultSelectorProps): Store.IQueryNode | Store.IQueryTerm =>
    selectedExpansionTagsSelector(state);

const ignoreMistakesSelector = ({ actionState }: ISearchResultSelectorProps) =>
    actionState.ignoreMistakes;

const personalizationSelector = ({ actionState }: ISearchResultSelectorProps) =>
    actionState.personalization;

export const sortingSelector = ({
    actionState,
}: ISearchResultSelectorProps): Record<string, Api.SearchSorting> => {
    const { sorting } = actionState;

    // сортировка по релевантности === отсутствию сортировки
    if (
        !sorting ||
        !sorting.key ||
        sorting.key === Store.sortingOptions.RelevancySortingOption.key
    ) {
        return {};
    }

    const { fields } = Store.sortingOptions[sorting.key];
    return fields.reduce<Record<string, Api.SearchSorting>>(
        (apiSorting, field) => {
            apiSorting[field] =
                sorting.order.toUpperCase() as Api.SearchSorting;

            return apiSorting;
        },
        {}
    );
};

const activeTabSelector = ({ state }: ISearchResultSelectorProps) =>
    state.mainSearchPageActiveTab;
const tabsSelector = ({ state }: ISearchResultSelectorProps) =>
    state.searchTabs;

const conceptsSelector = ({ actionState }: ISearchResultSelectorProps) =>
    actionState.concepts;

const personStrictSelector = ({ actionState }: ISearchResultSelectorProps) =>
    actionState.personStrict;

export default createSelector(
    [
        filtersMetaSelector,
        filtersSelector,
        selectedExpansionTags,
        pagingSelector,
        ignoreMistakesSelector,
        personalizationSelector,
        sortingSelector,
        activeTabSelector,
        tabsSelector,
        conceptsSelector,
        personStrictSelector,
    ],
    (
        filtersMeta: Store.IFiltersMeta,
        filters: IFiltersValues,
        selectedExpansion: Store.IQueryNode | Store.IQueryTerm,
        searchPaging: Store.IPaging,
        ignoreMistakes: boolean,
        personalization: boolean,
        sortBy: Record<string, Api.SearchSorting>,
        activeTab: string | null,
        tabs: Store.ISearchTabsMeta,
        concepts: ISearchConcepts,
        personsStrict: boolean
    ) => {
        const activeTabObj = tabs.tabs[activeTab];

        const result: Omit<Api.IGlobalSearchRequestBody, 'query'> = {
            doc: {
                skip: searchPaging.skip,
                limit: searchPaging.limit,
                ignoreMistakes,
                userFilters: [],
                queryParams: [],
                expansion: selectedExpansion,
                personalization,
                sortBy,
                strict:
                    activeTabObj?.searchTarget === SearchTarget.employee
                        ? personsStrict
                        : null,
            },
            searchTab: activeTab,
        };

        const userFilters = [];

        Object.keys(filters).forEach((code) => {
            if (ignoreFilters.includes(code)) {
                return;
            }

            const filterMeta = filtersMeta[code];

            const payload: Api.ISearchRequestFilter = {
                type: 'BaseFilter',
                code: replaceDashesWithDots(code),
                value: getFilterValue(filters[code], filterMeta),
            };

            if (payload.value) {
                !!filterMeta && filterMeta.isParam === true
                    ? result.doc.queryParams.push(payload)
                    : userFilters.push(payload);
            }
        });

        result.doc.userFilters.push(...userFilters);

        const conceptSelectionEntries = Object.entries(concepts.selectState);
        if (conceptSelectionEntries.length > 0) {
            result.doc.concepts = conceptSelectionEntries.reduce(
                (res, [uri, selected]) => {
                    res.push({ uri, selected });
                    return res;
                },
                []
            );
        }

        return result;
    }
);
