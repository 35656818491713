import { handleActions, Action } from 'redux-actions';

import * as Store from '../store/StoreNamespace';
import { setNavMenuDropdownVisibility } from '../actions/navMenuDropdownVisibility';
import initialState from '../store/initialState';

const navMenuDropdownVisibility = handleActions<
    Store.INavMenuDropdownVisibility,
    any
>(
    {
        [setNavMenuDropdownVisibility.toString()](
            state: Store.INavMenuDropdownVisibility,
            { payload }: Action<boolean>
        ) {
            const nextState: Store.INavMenuDropdownVisibility = {
                isOpen: payload,
            };
            return nextState;
        },
    },
    initialState.navMenuDropdownVisibility
);

export default navMenuDropdownVisibility;
