/**
 * Created by lkarmelo on 28.10.2019.
 */

import React, { useState } from 'react';
import classNames from 'classnames';

import { debounce } from 'app/utils/debounce';

import CheckBox from '../../Checkbox';
import { ISelectOption } from '../Select/TreeSelect';
import { LIST_ACTION_DELAY } from '../List/List';

export interface IProps {
    active: boolean;
    option: ISelectOption;
    intermediate?: boolean;
    className?: string;
    withDelay?: boolean;
    onSelect(option: ISelectOption): void;
    onDeselect(option: ISelectOption): void;
}

const Option: React.FC<IProps> = (props) => {
    const {
        active,
        onSelect,
        onDeselect,
        option,
        intermediate,
        className,
        withDelay,
    } = props;

    const [inProgress, setInProgress] = useState(false);

    const action = () => (active ? onDeselect(option) : onSelect(option));
    const debouncedSetInProgress = debounce(
        () => setInProgress(false),
        LIST_ACTION_DELAY
    );

    const toggle = () => {
        withDelay && active
            ? (() => {
                  setInProgress(true);
                  action();
                  debouncedSetInProgress();
              })()
            : action();
    };

    return (
        <div className={classNames('nkcm-select-option', className)}>
            <div className="nkcm-select-option__checkbox-label">
                <CheckBox
                    checked={active}
                    onChange={toggle}
                    intermediate={intermediate}
                    fixed={option.fixed}
                    inProgress={inProgress}
                />
                <div className="nkcm-select-option__label" title={option.label}>
                    {option.label}
                </div>
            </div>
            <div className="nkcm-select-option__count">{option.count}</div>
        </div>
    );
};

export default React.memo(Option);
