/**
 * Created by Lkarmelo on 23.08.2017.
 */

import { loadingBarReducer } from 'react-redux-loading-bar';

import {
    searchTabsMetaReducer,
    activeTabReducer,
    activeTabForResultsReducer,
} from 'app/redux/reducers/search/searchTabs';

import globalSearch from './search/search';
import documentSearch from './search/documentSearch';
import loading from './loading';
import filtersMeta from './search/filtersMeta';
import favoritesFiltersMeta from './search/favoritesFiltersMeta';
import objectCard from './objectCard';
import defaultFilters from './search/defaultFilters';
import authorization, { passwordRecovery } from './authorization';
import user from './user';
import currentUser from './currentUser';
import forms from './forms';
import currentUserWorkInfo from './currentUserWorkInfo';
import currentUserCompetencies from './currentUserCompetencies';
import currentUserKnowledgeCategories from './currentUserKnowledgeCategories';
import currentUserSkills from './currentUserSkills';
import competencies from './competencies';
import organizationsInfo from './organizationsInfo';
import skills from './skills';
import catalogueCategories from './catalogueCategories';
import educationMaterialTypes from './educationMaterialTypes';
import education from './education';
import notifications from './notifications';
import orgStructure from './orgstructure';
import recommendations from './recommendations';
import fileStore from './files';
import outsideRedirectInfo from './outsideRedirectInfo';
import modals from './modals';
import guideResults from './tour';
import conceptDescriptions from './conceptDescriptions';
import navMenuDropdownVisibility from './navMenuDropdownVisibility';
import doodle from './doodle';

import * as Store from '../store/StoreNamespace';
import { contextCombineReducers } from '../context/reducer';
import { SearchDocumentName } from '../actions/interfaces/DocumentEnum';

export default contextCombineReducers(
    {
        [SearchDocumentName.related]: documentSearch,
        [SearchDocumentName.favorites]: documentSearch,
        [SearchDocumentName.subscribes]: documentSearch,
        [SearchDocumentName.readLater]: documentSearch,
        [SearchDocumentName.catalogueDocuments]: documentSearch,
        [SearchDocumentName.catalogueDocumentsNew]: documentSearch,
        [SearchDocumentName.documentManagementAll]: documentSearch,
        [SearchDocumentName.documentManagementOwn]: documentSearch,
        [SearchDocumentName.education]: education,
        [SearchDocumentName.uploadDocument]: documentSearch,
        [SearchDocumentName.objectCardSimilar]: documentSearch,
        [Store.RecommendationsContext]: recommendations,
    },
    {
        mainSearchPageActiveTab: activeTabReducer,
        mainSearchPageActiveTabForResults: activeTabForResultsReducer,
        searchTabs: searchTabsMetaReducer,
        search: globalSearch,
        fileStore,
        loading,
        loadingBar: loadingBarReducer,
        filtersMeta,
        favoritesFiltersMeta,
        objectCard,
        defaultFilters,
        authorization,
        passwordRecovery,
        user,
        currentUser,
        forms,
        currentUserWorkInfo,
        currentUserCompetencies,
        currentUserKnowledgeCategories,
        currentUserSkills,
        competencies,
        organizationsInfo,
        skills,
        catalogueCategories,
        educationMaterialTypes,
        orgStructure,
        outsideRedirectInfo,
        [Store.NotificationContext]: notifications,
        modals,
        conceptDescriptions,
        guideResults,
        navMenuDropdownVisibility,
        doodle,
    }
);
