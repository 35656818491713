import React, { useCallback, PropsWithChildren } from 'react';
import classNames from 'classnames';

import CarouselPaging from './CarouselPaging/CarouselPaging';
import IProps from './interfaces/ICarouselWidgetProps';
import styles from './CarouselWidget.scss';

const CarouselWidget = <T extends any>({
    className,
    slides,
    currentSlideIndex,
    setCurrentSlideIndex,
    renderSlidesCount,
    showPaging,
}: PropsWithChildren<IProps<T>>): JSX.Element => {
    const slidesCount = slides.length;

    const toNextSlide = useCallback(() => {
        setCurrentSlideIndex((prevIndex) =>
            prevIndex + 1 > slidesCount - 1 ? 0 : prevIndex + 1
        );
    }, [slidesCount]);

    const toPrevSlide = useCallback(() => {
        setCurrentSlideIndex((prevIndex) =>
            prevIndex - 1 < 0 ? slidesCount - 1 : prevIndex - 1
        );
    }, [slidesCount]);

    const currentSlide = slides[currentSlideIndex];

    return (
        <div className={classNames(styles.carouselWidget, className)}>
            {currentSlide.renderSlide()}
            <div className={styles.carouselWidgetFooter}>
                <div className={styles.carouselWidgetSlidesCount}>
                    {renderSlidesCount?.(
                        currentSlideIndex,
                        slidesCount,
                        currentSlide.value
                    ) ?? (
                        <div className={styles.carouselWidgetDefaultCount}>
                            {currentSlideIndex + 1} из {slidesCount}
                        </div>
                    )}
                </div>
                {showPaging && slidesCount > 1 && (
                    <CarouselPaging
                        toNextSlide={toNextSlide}
                        toPrevSlide={toPrevSlide}
                    />
                )}
            </div>
        </div>
    );
};

CarouselWidget.defaultProps = {
    showPaging: true,
};

export default CarouselWidget;
