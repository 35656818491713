import memoizeOne from 'memoize-one';

import * as Store from 'app/redux/store/StoreNamespace';

import { IFilterHints } from '../interfaces/ISearchPanelProps';

// группируем фильтры-подсказки по коду фильтра
export const getQueryHints = memoizeOne((queryHints: Store.IQueryHint[]): {queryHints: Store.IQueryHint[]; filterHints: IFilterHints} => {
    if (!queryHints || queryHints.length === 0) {
        return {queryHints: [], filterHints: {}};
    }

    return queryHints.reduce(
        (res, query) => {
            if (!query.filter) {
                res.queryHints.push(query);
            } else {
                const code = query.filter.code;
                res.filterHints[code] ? (res.filterHints[code].push(query)) : (res.filterHints[code] = [query]);
            }

            return res;
        },
        {
            queryHints: [],
            filterHints: {},
        }
    );
});
