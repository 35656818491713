/**
 * Created by Lkarmelo on 18.01.2018.
 */

import * as React from 'react';

import './LoadingScreen.scss';

const LoadingScreen = () => {
    return (
        <div className="loading-screen">
            <div>
                <div className="loading-screen__content">
                    <svg
                        width="50"
                        height="50"
                        viewBox="0 0 30 30"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                        <g transform="translate(-24717 -7036)">
                            {/* tslint:disable-next-line */}
                            <path
                                transform="matrix(0.133333 0 0 -0.133333 24717 7066)"
                                d="M 207.306 26.7056C 201.529 26.7056 196.64 23.1011 194.709 18.0603L 9.5285 18.0603L 9.5285 202.257L 70.3033 142.157L 141.763 142.157L 202.542 202.257L 202.542 84.8215L 84.8722 84.8215C 82.9411 89.8624 78.0526 93.4614 72.2772 93.4614C 64.8195 93.4614 58.7772 87.4828 58.7772 80.1113C 58.7772 72.7398 64.8195 66.7612 72.2772 66.7612C 78.0526 66.7612 82.9433 70.3603 84.8722 75.4011L 212.066 75.4011L 212.066 225L 137.821 151.577L 74.2495 151.577L 0 225L 0 8.63988L 194.709 8.63988C 196.643 3.60447 201.533 3.17593e-05 207.306 3.17593e-05C 214.762 3.17593e-05 220.806 5.97851 220.806 13.3555C 220.806 20.727 214.762 26.7056 207.306 26.7056Z"
                            />
                        </g>
                    </svg>
                    <p>Загрузка...</p>
                </div>
            </div>
        </div>
    );
};

export default LoadingScreen;
