import { MiddlewareAPI } from 'redux';
import { Observable } from 'rxjs/Observable';
import { concat } from 'rxjs/observable/concat';
import { Action } from 'redux-actions';

import Api from 'app/api/Api';
import { ApiFeaturesName } from 'app/features/ApiFeatresName';

import * as Store from '../store/StoreNamespace';
import reduxStore from '../store/store';
import {
    fetchNotificationListAndCount,
    setNotificationListAndCount,
    startNotificationsPolling,
    requestNotificationsRead,
    markNotificationsRead,
    fetchNotificationCount,
    setNotificationsCount,
    fetchLatestNotificationsAndCount,
    setLatestNotificationsAndCount,
    setNotificationsIntervalId,
} from '../actions/notification';
import { SET_AUTHORIZED } from '../actions/authorization';
import { NOTIFICATIONS_LOAD_INTERVAL } from '../../utils/constants';

export const loadLatestNotifications = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(fetchLatestNotificationsAndCount.toString())
        .mergeMap(({ payload }: Action<number | undefined>) =>
            apiCall
                .userNotification(payload)
                .catch(() => Observable.empty())
                .map(({ response }: { response: Api.INotificationsResponse }) =>
                    setLatestNotificationsAndCount(response)
                )
        );

export const loadNotificationList = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(fetchNotificationListAndCount.toString())
        .mergeMap(({ payload }: Action<number | undefined>) =>
            apiCall
                .userNotification(payload)
                .catch(() => Observable.empty())
                .map(({ response }: { response: Api.INotificationsResponse }) =>
                    setNotificationListAndCount(response)
                )
        );

export const loadNewNotificationsCount = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$.ofType(fetchNotificationCount.toString()).mergeMap(() =>
        apiCall
            .userNotificationCount()
            .catch(() => Observable.empty())
            .map(({ response }: { response: number }) =>
                setNotificationsCount(response)
            )
    );

// В версии rxjs ниже 6 и выше 5.0.x интервал постоянно наращивает call stack, поэтому приходится использовать нативный setInterval,
// чтобы не понижать версию
// TODO: заменить обратно, когда будем апгрейдиться до 6+ версии
let intervalId: number;

export const startNotificationCountDispatcher = (
    action$,
    store: MiddlewareAPI<Store.IState>
) =>
    action$
        .ofType(startNotificationsPolling.toString())
        .filter(() => APP_FEATURES.api[ApiFeaturesName.Notifications])
        .switchMap((a) => {
            const { isAuthorized } = store.getState().authorization;
            if (isAuthorized) {
                return Observable.of(a);
            }
            return action$
                .ofType(SET_AUTHORIZED)
                .filter((action) => {
                    return action.payload;
                })
                .take(1);
        })
        .do(() => {
            (reduxStore.getState() as Store.IState).authorization
                .isAuthorized && reduxStore.dispatch(fetchNotificationCount());
            intervalId = window.setInterval(() => {
                (reduxStore.getState() as Store.IState).authorization
                    .isAuthorized &&
                    reduxStore.dispatch(fetchNotificationCount());
            }, NOTIFICATIONS_LOAD_INTERVAL);
            reduxStore.dispatch(setNotificationsIntervalId(intervalId));
        })
        .mergeMap(() => Observable.empty());

export const sendNotificationStatus = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(requestNotificationsRead.toString())
        .filter(
            (action: Action<string[]>) =>
                store.getState().authorization.isAuthorized &&
                action.payload.length > 0
        )
        .mergeMap((action: Action<string[]>) =>
            concat(
                apiCall
                    .markNotificationReaded(action.payload)
                    .filter((response) => !!response)
                    .mergeMap(({ response }: { response: string[] }) =>
                        Observable.of(markNotificationsRead(response))
                    )
                    .catch((e) => {
                        console.error(e);
                        return Observable.empty();
                    })
            )
        );
