/**
 * Created by lkarmelo on 05.09.2018.
 */

import * as Store from 'app/redux/store/StoreNamespace';

import { FunctionalityName } from './FunctionalityName';

const FunctionalityToAllowedRoles = new Map<
    FunctionalityName,
    Store.PermissionRole[]
>([
    [
        FunctionalityName.CatalogueEditCategory,
        [Store.PermissionRole.AccessOperator],
    ],
    [
        FunctionalityName.CatalogueAddCategory,
        [Store.PermissionRole.AccessOperator],
    ],
    [
        FunctionalityName.CatalogueAcceptOrChangeDocuments,
        [Store.PermissionRole.AccessOperator],
    ],

    [FunctionalityName.AdminPageLinkView, [Store.PermissionRole.AccessAdmin]],

    [FunctionalityName.ForeignPasswordChange, []],

    [
        FunctionalityName.ForeignPersonalInfoEdit,
        [Store.PermissionRole.AccessAdmin, Store.PermissionRole.AccessManager],
    ],
    [
        FunctionalityName.ForeignWorkInfoEdit,
        [Store.PermissionRole.AccessAdmin, Store.PermissionRole.AccessManager],
    ],
    [
        FunctionalityName.ForeignCompetenciesEdit,
        [Store.PermissionRole.AccessAdmin, Store.PermissionRole.AccessManager],
    ],
    [
        FunctionalityName.ForeignSkillsEdit,
        [Store.PermissionRole.AccessAdmin, Store.PermissionRole.AccessManager],
    ],
    [
        FunctionalityName.ForeignCompetenciesView,
        [Store.PermissionRole.AccessAdmin, Store.PermissionRole.AccessManager],
    ],

    [
        FunctionalityName.ForeignFavoriteView,
        [
            /* Store.PermissionRole.AccessAdmin */
        ],
    ],
    [
        FunctionalityName.ForeignSubscribeView,
        [
            /* Store.PermissionRole.AccessAdmin */
        ],
    ],
    [
        FunctionalityName.ForeignEducationView,
        [
            /* Store.PermissionRole.AccessAdmin, Store.PermissionRole.AccessManager */
        ],
    ],
    [
        FunctionalityName.ForeignNotificationsView,
        [
            /* Store.PermissionRole.AccessAdmin */
        ],
    ],
    [
        FunctionalityName.ForeignFilesView,
        [
            /* Store.PermissionRole.AccessAdmin */
        ],
    ],
]);

export function getFunctionalityAllowed(
    user: Store.ISystemUser,
    functionality: FunctionalityName
): boolean {
    // считаем, что функция доступна для всех, если её нет в FunctionalityToAllowedRoles
    if (!FunctionalityToAllowedRoles.has(functionality)) {
        return true;
    }
    const allowedForRoles = FunctionalityToAllowedRoles.get(functionality);
    return (
        !!user &&
        !!user.permissions &&
        allowedForRoles.findIndex((role) => !!user.permissions[role]) >= 0
    );
}
