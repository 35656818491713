/**
 * Created by Lkarmelo on 04.09.2017.
 */

import { RoutingFeaturesName } from 'app/features/RoutingFeaturesName';

import ClientRoute from './Route/ClientRoute';
import FeatureDependantClientRoute from './Route/FeatureDependantClientRoute';

export type ProfileQueryParams = 'user' | 'id' | 'source';

export type EditEducationMaterialMatchParams = 'id';
export type EditEducationMaterialQueryParams = 'backUrl';

export type DocumentRedirectParams = 'id';

const routes = {
    search: new ClientRoute('/'),
    objectCard: new ClientRoute<{}, 'id'>('/objectCard/:id'),
    catalogue: new FeatureDependantClientRoute<{}, 'id'>(
        '/catalogue/:id?',
        null,
        RoutingFeaturesName.Catalogue
    ),
    analysis: new ClientRoute('/analysis'),
    admin: new ClientRoute('/admin/catalogs'),
    documentation: new ClientRoute('/docs'),
    service: new ClientRoute('/service'),
    login: new ClientRoute('/login'),
    resetPassword: new ClientRoute('/resetPassword'),
    recoveryPassword: new ClientRoute('/recoveryPassword'),
    main: new ClientRoute('/'),
    buildInfo: new ClientRoute('/build-info'),
    account: new FeatureDependantClientRoute<{
        profile: ClientRoute<{}, '', ProfileQueryParams>;
        history: ClientRoute;
        favorite: ClientRoute;
        pending: ClientRoute;
        related: ClientRoute;
        education: ClientRoute;
        myDocument: ClientRoute;
        subscriptions: ClientRoute;
        files: ClientRoute;
        subscribe: ClientRoute;
    }>(
        '/account',
        {
            profile: new FeatureDependantClientRoute(
                '/profile',
                null,
                RoutingFeaturesName.Profile
            ),
            history: '/history',
            favorite: new FeatureDependantClientRoute(
                '/favorite',
                null,
                RoutingFeaturesName.Favorite
            ),
            pending: new FeatureDependantClientRoute(
                '/pending',
                null,
                RoutingFeaturesName.ReadLater
            ),
            related: new FeatureDependantClientRoute(
                '/related',
                null,
                RoutingFeaturesName.Related
            ),
            education: new FeatureDependantClientRoute(
                '/education',
                null,
                RoutingFeaturesName.Education
            ),
            myDocument: new FeatureDependantClientRoute(
                '/my-document',
                null,
                RoutingFeaturesName.UploadDocument
            ),
            files: new FeatureDependantClientRoute(
                '/files',
                null,
                RoutingFeaturesName.UserFiles
            ),
            subscribe: new FeatureDependantClientRoute(
                '/subscribe',
                null,
                RoutingFeaturesName.Subscribe
            ),
            subscriptions: '/subscriptions',
        },
        RoutingFeaturesName.Account
    ),
    documentManagement: new FeatureDependantClientRoute<{
        all: ClientRoute;
        own: ClientRoute;
        add: ClientRoute;
        edit: ClientRoute<
            {},
            EditEducationMaterialMatchParams,
            EditEducationMaterialQueryParams
        >;
        recommendations: ClientRoute;
    }>(
        '/doc-management',
        {
            own: '/own',
            add: '/add',
            edit: '/edit/:id',
            recommendations: '/recommendations',
            all: '',
        },
        RoutingFeaturesName.DocumentManagement
    ),
    documentRedirect: new ClientRoute<{}, DocumentRedirectParams>(
        '/document-redirect/:id'
    ),
    notifications: new ClientRoute('/notifications', null),
    redirect: new ClientRoute<{}, '', 'url'>('/redirect'),
};

export default routes;
