import * as React from 'react';

export const MobileNewsIcon: React.FC = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 5.73913C0 2.5695 2.5695 0 5.73913 0H18.2609C21.4305 0 24 2.5695 24 5.73913V18.2609C24 21.4305 21.4305 24 18.2609 24H5.73913C2.5695 24 0 21.4305 0 18.2609V5.73913Z"
                fill="#001F4A"
            />
            <path
                d="M0 5.73913C0 2.5695 2.5695 0 5.73913 0H18.2609C21.4305 0 24 2.5695 24 5.73913V18.2609C24 21.4305 21.4305 24 18.2609 24H5.73913C2.5695 24 0 21.4305 0 18.2609V5.73913Z"
                fill="#0092FE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.43478 7.82609C4.43478 6.0972 5.84132 4.69565 7.57638 4.69565H16.1151C17.8501 4.69565 19.2567 6.0972 19.2567 7.82609V16.1739C19.2567 17.1049 20.0141 17.8595 20.9483 17.8595V19.3043H7.57638C5.84132 19.3043 4.43478 17.9028 4.43478 16.1739V7.82609ZM18.3006 17.8595C17.988 17.373 17.8067 16.7945 17.8067 16.1739V7.82609C17.8067 6.89515 17.0493 6.14047 16.1151 6.14047H7.57638C6.64212 6.14047 5.88475 6.89515 5.88475 7.82609V16.1739C5.88475 17.1049 6.64212 17.8595 7.57638 17.8595H18.3006Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.88073 9.1416H12.8279V10.5864H10.3307V13.0747H8.88073V9.1416Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2321 15.2982L11.285 15.2982L11.285 13.8534L13.7821 13.8534L13.7821 11.3651L15.2321 11.3651L15.2321 15.2982Z"
                fill="white"
            />
        </svg>
    );
};
