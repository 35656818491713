/**
 * Created by Lkarmelo on 23.08.2017.
 */

import { Action, handleActions, combineActions } from 'redux-actions';

import * as Store from '../../store/StoreNamespace';
import {
    setFiltersMeta,
    replaceFiltersMetaValues,
    IUpdateFiltersMetaPayload,
} from '../../actions/search/filtersMeta';
import {
    addMultiSelectFilterValue,
    IChangeFilterValuePayload,
    removeMultiSelectFilterValue,
    setFilterValue,
    toggleMultiSelectFilterValue,
} from '../../actions/search/filters';

export default handleActions<Store.IFiltersMeta, any>(
    {
        [setFiltersMeta.toString()](
            state: Store.IFiltersMeta,
            { payload }: Action<Store.IFiltersMeta>
        ): Store.IFiltersMeta {
            return payload;
        },
        [replaceFiltersMetaValues.toString()](
            state: Store.IFiltersMeta,
            { payload }: Action<IUpdateFiltersMetaPayload>
        ): Store.IFiltersMeta {
            const payloadEntries = Object.entries(payload);

            if (payloadEntries.length === 0) {
                return state;
            }
            const nextState = { ...state };

            payloadEntries.forEach(([filterName, payloadValuesMeta]) => {
                const filterMeta = nextState[filterName];
                if (
                    !filterMeta ||
                    !Array.isArray(filterMeta.values) ||
                    !Array.isArray(payloadValuesMeta)
                ) {
                    return;
                }

                nextState[filterName] = {
                    ...filterMeta,
                    values: payloadValuesMeta,
                };
            });

            return nextState;
        },
        [combineActions(
            addMultiSelectFilterValue,
            removeMultiSelectFilterValue,
            setFilterValue,
            toggleMultiSelectFilterValue
        ).toString()](
            state: Store.IFiltersMeta,
            { payload }: Action<IChangeFilterValuePayload>
        ): Store.IFiltersMeta {
            const { filterName, value, label, updateFilterMetaIfValueUnknown } =
                payload;
            const filterMeta: Store.IFilterMeta = state[filterName];

            if (
                !updateFilterMetaIfValueUnknown ||
                !filterMeta ||
                filterMeta.values.find((val) => val.value === value)
            ) {
                return state;
            }
            const nextMetaValues = filterMeta.values.slice();

            nextMetaValues.push({
                value: value.toString(),
                label: label || value.toString(),
            });

            return {
                ...state,
                [filterName]: {
                    ...filterMeta,
                    values: nextMetaValues,
                },
            };
        },
    },
    {} // - default value for filtersMeta
);
