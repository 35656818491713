/**
 * Created by Lkarmelo on 23.08.2017.
 */

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { MiddlewareAPI } from 'redux';

import {
    loadingShowActionCreators,
    loadingHideActionCreators,
} from '../actions/loading';
import * as Store from '../store/StoreNamespace';

export const startLoading = (action$, store: MiddlewareAPI<Store.IState>) =>
    action$
        .ofType(...loadingShowActionCreators.map((a) => a.toString()))
        .debounceTime(100)
        .filter(
            () =>
                store.getState().loading.pendingRequestsCount > 0 &&
                !store.getState().loadingBar.default
        )
        .mapTo(showLoading());

export const stopLoading = (action$, store: MiddlewareAPI<Store.IState>) =>
    action$
        .ofType(...loadingHideActionCreators.map((a) => a.toString()))
        .debounceTime(300)
        .filter(
            () =>
                !store.getState().loading.pendingRequestsCount &&
                store.getState().loadingBar.default === 1
        )
        .mapTo(hideLoading());
