import { MiddlewareAPI } from 'redux';
import { Observable } from 'rxjs/Observable';
import { Action } from 'redux-actions';

import * as Store from 'app/redux/store/StoreNamespace';
import { getTitleFromLabels } from 'app/utils/concepts';

import { createRetryOnIEAuthProblem } from './utils/createRetryOnIEAuthProblem';

import {
    fetchResourceView,
    IFetchResourceViewPayload,
    setResourceView,
} from '../actions/conceptDescriptions';

export const loadResourceViewEpic = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall, context }
) =>
    action$
        .ofType(fetchResourceView.toString())
        .switchMap(({ payload }: Action<IFetchResourceViewPayload>) =>
            apiCall
                .resourceView(payload.conceptUri, payload.schemeUri)
                .retryWhen(createRetryOnIEAuthProblem())
                .mergeMap(({ response }) => {
                    const resourceView = response[payload.conceptUri];

                    if (!resourceView) {
                        return Observable.empty();
                    }

                    return Observable.of(
                        setResourceView({
                            conceptUri: payload.conceptUri,
                            altLabels:
                                resourceView[Store.SKOSLabelClasses.AltLabel] ??
                                [],
                            prefLabels:
                                resourceView[
                                    Store.SKOSLabelClasses.PrefLabel
                                ] ?? [],
                            definition:
                                resourceView[
                                    Store.SKOSNoteClasses.Definition
                                ] ?? [],
                            related:
                                resourceView[
                                    Store.SKOSRelationshipClasses.Related
                                ]?.map((relConcept) => ({
                                    uri: relConcept.value,
                                    label: getTitleFromLabels(
                                        relConcept.attr.prefLabel
                                    ),
                                })) ?? [],
                        })
                    );
                })
                .catch((e) => {
                    console.error(e);
                    return Observable.empty();
                })
        );
