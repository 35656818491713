import { Dispatch } from 'redux';
import { Action } from 'redux-actions';
import {
    DispatchProp,
    MapDispatchToPropsFunction,
    InferableComponentEnhancerWithProps,
    connect,
    MapStateToPropsParam,
} from 'react-redux';
import memoizeOne from 'memoize-one';

interface IContextAction<Payload> extends Action<Payload> {
    originalContext?: string[];
    context: string[];
}

export interface IInjectedProps {
    rootContext: string;
}

export type ContextAction<Payload> = IContextAction<Payload>;
type ActionType<P> = ContextAction<P> | Action<P>;
type DispatchType<P> = Dispatch<ActionType<P>>;

export interface IMapContextToProps<TStateProps, TOwnProps, Context, State> {
    (context: Context, state: State, ownProps: TOwnProps): TStateProps;
}

export interface IConnectWithContext {
    <
        TStateProps extends {},
        TOwnDispatchProps = {},
        TOwnProps = {},
        TDispatchProps extends DispatchProp<any> = DispatchProp<any>,
        State extends {} = {},
        Context extends {} = {}
    >(
        mapStateToProps: MapStateToPropsParam<
            TStateProps & IInjectedProps,
            TOwnProps,
            State
        >,
        mapDispatchToProps: MapDispatchToPropsFunction<
            TOwnDispatchProps,
            TOwnProps
        >
    ): InferableComponentEnhancerWithProps<
        TStateProps & TOwnDispatchProps,
        TOwnProps
    >;
}

function appendContext<P extends {}>(
    action: ActionType<P>,
    currentContext: string | string[] | any,
    ...paths: string[]
): ContextAction<P> {
    const context = (action as IContextAction<P>).context || [];

    (Array.isArray(currentContext)
        ? currentContext
        : currentContext.toString().split('.')
    ).forEach((item) => context.push(item));

    // push additional context
    !!paths && Array.prototype.push.apply(context, paths);

    return { ...action, context };
}

export function withContext<T>(
    action: Action<T>,
    context: string | string[] | any,
    ...paths: string[]
): ContextAction<T> | Action<T> {
    return !context || (Array.isArray(context) && context.length === 0)
        ? action
        : appendContext(action, context, ...paths);
}

export function contextDispatch<P extends {}, A extends ActionType<P>>(
    dispatch: DispatchType<P>,
    currentContext: string
) {
    return (action: A) =>
        dispatch(<ActionType<P>>appendContext(action, currentContext));
}
// TODO: если одновременно будет несколько компонентов использовать connectWithContext, то может не помочь простая мемоизация
// но пока что у нас всегда только 1 компонент используется
const memoizedGetContextDispatch = memoizeOne(contextDispatch);

const getMapDispatchToProps = memoizeOne(
    (mapDispatchToPropsFunc, dispatch, ownProps?) =>
        mapDispatchToPropsFunc(dispatch, ownProps)
);

const connectWithContext: IConnectWithContext = <
    TStateProps extends {},
    TOwnDispatchProps = {},
    TOwnProps = {},
    TDispatchProps extends DispatchProp<any> = DispatchProp<any>,
    State = {},
    Context = {}
>(
    mapStateToProps: MapStateToPropsParam<
        TStateProps & IInjectedProps,
        TOwnProps,
        State
    >,
    mapDispatchToProps: MapDispatchToPropsFunction<TOwnDispatchProps, TOwnProps>
) => {
    return connect(
        mapStateToProps,
        undefined,
        (
            stateProps: TStateProps & IInjectedProps,
            dispatchProps: TDispatchProps,
            ownProps: TOwnProps
        ) => {
            const { rootContext, ...restProps } = stateProps as any;
            const dispatch: Dispatch<any> = <Dispatch<any>>(
                memoizedGetContextDispatch(dispatchProps.dispatch, rootContext)
            );
            const contextMapDispatchFunc =
                mapDispatchToProps.length === 1
                    ? getMapDispatchToProps(mapDispatchToProps, dispatch)
                    : getMapDispatchToProps(
                          mapDispatchToProps,
                          dispatch,
                          ownProps
                      );

            return Object.assign(restProps, contextMapDispatchFunc, ownProps);
        }
    );
};

export default connectWithContext;
