/**
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 06.02.2018
 * -----
 * Last Modified: 12.03.2018 11:52:22
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import { Dispatch, compose } from 'redux';
import { Action } from 'redux-actions';

import connectWithContext, {
    IInjectedProps,
} from 'app/redux/context/connectWithContext';
import * as Store from 'app/redux/store/StoreNamespace';
import { filtersValuesSelector } from 'app/redux/selectors/filters';
import { filtersMetaForSearchDocument } from 'app/redux/selectors/filtersMetaForSearchDocument';
import { filtersToDefaults } from 'app/redux/actions/search/defaultFilters';
import {
    addMultiSelectFilterValue,
    removeMultiSelectFilterValue,
    setFilterValue,
    setDateRangeFrom,
    setDateRangeTo,
} from 'app/redux/actions/search/filters';
import { ISelectOption } from 'app/modules/components/TreeSelect/Select/TreeSelect';
import FiltersSidePanel, {
    IFiltersSidePanelActionProps,
    IFiltersSidePanelStateProps,
} from 'app/components/search/FiltersSidePanel';
import {
    createFilterOrderer,
    createFilterValuesNormalizer,
} from 'app/utils/filters/createNormalizedFiltersSelector';
import { createFiltersToOptions } from 'app/utils/filters/filtersToOptions';
import { getFiltersMetasWithCount } from 'app/utils/filters/getFiltersMetaWithCount';

import IFilterPanelOwnProps from './interface/IFilterPanelOwnProps';

// const getFiltersMetaWithSearchPlaceHolder = createSelector(
//     ({searchPlaceholder}: {searchPlaceholder: string, filtersMeta: Store.IFiltersMeta}): string => searchPlaceholder,
//     ({filtersMeta}: {searchPlaceholder: string, filtersMeta: Store.IFiltersMeta}): Store.IFiltersMeta => filtersMeta,
//     (placeHolder: string, meta: Store.IFiltersMeta): Store.IFiltersMeta => {
//
//         if (!meta[Store.StaticFilterName.Search]) {
//             return meta;
//         }
//
//         const nextMeta = {...meta};
//         nextMeta[Store.StaticFilterName.Search] = {...nextMeta[Store.StaticFilterName.Search]};
//         nextMeta[Store.StaticFilterName.Search].title = placeHolder;
//         return nextMeta;
//     }
// );

const getFiltersOptions = createFiltersToOptions();

const getNormalizedOrderedFiltersMeta = compose(
    createFilterValuesNormalizer<Store.IFilterMeta>(),
    createFilterOrderer()
);

const mapStateToProps = ({
    context,
    searchDocumentName,
    rootContext,
}: IFilterPanelOwnProps) => {
    return (
        state: Store.IState
    ): IFiltersSidePanelStateProps & IInjectedProps => {
        const page = context(state);

        const filtersMeta: Store.IFiltersMeta = filtersMetaForSearchDocument({
            state,
            searchDocumentName,
        });

        const metaWithCount = getFiltersMetasWithCount(
            filtersMeta,
            page.results && page.results.filterStats
        );

        const meta = getNormalizedOrderedFiltersMeta(metaWithCount);
        const opts = getFiltersOptions(meta);

        return {
            rootContext,
            orderedNormalizedFiltersMeta: meta,
            filtersOptions: opts,
            activeFilterValues: filtersValuesSelector(page),
            filtersMeta: state.filtersMeta,
            defaultFilters: state.defaultFilters,
        };
    };
};

const mapDispatchToProps = (
    dispatch: Dispatch<Action<any>>
): IFiltersSidePanelActionProps => ({
    // select filter
    onSelect(filterName: string, value: string | string[]): void {
        dispatch(setFilterValue(filterName, value));
    },
    onSelectMultiSelectFilter({ filterName, value }: ISelectOption): void {
        dispatch(addMultiSelectFilterValue(filterName, value));
    },
    onDeselectMultiSelectFilter({ filterName, value }: ISelectOption): void {
        dispatch(removeMultiSelectFilterValue(filterName, value));
    },

    // range filter
    onStartRangeChange(filterName: string, value: any): void {
        dispatch(setDateRangeFrom(filterName, value));
    },

    onEndRangeChange(filterName: string, value: any): void {
        dispatch(setDateRangeTo(filterName, value));
    },

    onFiltersToDefaultsClick(): void {
        dispatch(filtersToDefaults());
    },
});

const FilterPanelFunc = (props: IFilterPanelOwnProps) =>
    connectWithContext(
        mapStateToProps(props),
        mapDispatchToProps
    )(FiltersSidePanel);

export default FilterPanelFunc;
