import { handleActions, Action } from 'redux-actions';

import Api from 'app/api/Api';
import { favoritesFiltersMetaReject } from 'app/redux/actions/loading';
import { setFavoritesFiltersMeta } from 'app/redux/actions/search/filtersMeta';
import { copyFiltersMeta } from 'app/redux/epics/loadFiltersMeta';
import initialState from 'app/redux/store/initialState';

import * as Store from '../../store/StoreNamespace';

export default handleActions(
    {
        [setFavoritesFiltersMeta.toString()](
            state: Store.IFiltersMeta,
            { payload }: Action<Api.IFilterDescription[]>
        ): Store.IFiltersMeta {
            if (!Array.isArray(payload)) {
                return state;
            }
            return Object.assign({}, ...payload.map(copyFiltersMeta));
        },
        [favoritesFiltersMetaReject.toString()]() {
            return {};
        },
    },
    initialState.favoritesFiltersMeta
);
