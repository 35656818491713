/**
 * Created by Lkarmelo on 11.12.2017.
 */

import { MiddlewareAPI } from 'redux';
import moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { concat } from 'rxjs/observable/concat';
import { Action } from 'redux-actions';

import { FormName } from 'app/api/FormName';
import Api from 'app/api/Api';
import { FULL_DATE_TIME_FORMAT } from 'app/utils/constants';
import IPersonalInfoFormData from 'app/components/account/ProfilePanels/PersonalInfo/container/interfaces/IFormData';
import IAddDocumentFormData from 'app/components/document-management/AddDocument/interfaces/IAddDocumentFormData';
import IEditDocumentFormData from 'app/components/document-management/EditDocument/interfaces/IEditDocumentFormData';
import IDeleteDocumentFormData from 'app/components/Modals/DeleteDocument/interfaces/IFormData';
import {
    ICreateRecommendationFormData,
    IEditRecommendationFormData,
} from 'app/components/Modals/legacy/DocumentRecommendation/interfaces/IDocumentRecommendationFormData';
import IDeleteRecommendationFormData from 'app/components/Modals/DeleteRecommendation/interfaces/IFormData';
import { EditCategoryFormBody } from 'app/components/catalogue/AddEditCategory';

import { createRetryOnIEAuthProblem } from './utils/createRetryOnIEAuthProblem';

import * as Store from '../store/StoreNamespace';
import { formReject, formRequest, formResolve } from '../actions/loading';
import {
    ISendFormPayload,
    sendForm as sendFormAction,
    setFormResponse,
    setFormStatus,
} from '../actions/forms';

const getRequestArgsByFormName = (
    formName: FormName,
    state: Store.IState,
    reduxFormData: any
): Array<any> => {
    switch (formName) {
        case FormName.WorkInfo:
            return [
                state.currentUser.id,
                state.currentUserWorkInfo[0] && state.currentUserWorkInfo[0].id,
            ];
        case FormName.PersonalInfo:
            return [state.currentUser.id];
        case FormName.UserCompetencies:
            return [state.currentUser.id];
        case FormName.Skills:
            return [state.currentUser.id];
        case FormName.RecommendDocument:
            return [
                (<ICreateRecommendationFormData>reduxFormData)
                    .isForCertainEmployees,
            ];
        case FormName.EditRecommendation: {
            const data = <IEditRecommendationFormData>reduxFormData;
            return [data.recommendationId, data.profileId];
        }
        case FormName.DeleteRecommendation: {
            const data = <IDeleteRecommendationFormData>reduxFormData;
            return [data.recommendationId, data.profileId];
        }
        case FormName.DeleteDocument:
        case FormName.EditDocument:
            return [
                (<IEditDocumentFormData | IDeleteDocumentFormData>reduxFormData)
                    .documentId,
            ];
        case FormName.CatalogueEditCategory: {
            const { editingCategoryId } = <EditCategoryFormBody>reduxFormData;
            return [editingCategoryId];
        }
        default:
            return [];
    }
};

const getRequestBodyByFormName = (
    formName: FormName,
    state: Store.IState,
    reduxFormData: any
): any => {
    switch (formName) {
        case FormName.PersonalInfo: {
            const formData = <IPersonalInfoFormData>reduxFormData;

            return <Api.FormBody[FormName.PersonalInfo]>{
                ...formData,
                birthDate: formData.birthDate
                    ? moment(formData.birthDate).format(FULL_DATE_TIME_FORMAT)
                    : undefined,
            };
        }
        case FormName.EditDocument:
        case FormName.AddNewDocument: {
            const formData = <IAddDocumentFormData>reduxFormData;

            return <Api.FormBody[FormName.AddNewDocument]>{
                title: formData.title,
                objectSubType: formData.type,
                storageUrl: formData.source,
                summary: formData.description,
                year: Number(moment(formData.date).format('YYYY')),
                orgs: formData.org
                    ? [{ id: formData.org, title: formData.org }]
                    : undefined,
                persons: formData.author
                    ? [{ title: formData.author, id: formData.author }]
                    : undefined,
                categories: formData.category,
                competenciesCodeToScore: formData.competencies.map((c) => ({
                    competenceCode: c.competenceItem.code,
                    grade: c.grade,
                })),
            };
        }
        case FormName.RecommendDocument: {
            const formData = <ICreateRecommendationFormData>reduxFormData;
            const planDate = formData.required
                ? moment(formData.finishDate).format(FULL_DATE_TIME_FORMAT)
                : undefined;

            if (formData.isForCertainEmployees) {
                return <Api.FormBody['recommendByProfiles']>{
                    documentId: formData.documentId,
                    receiversData: {
                        profilePlanDate: formData.employees.map(
                            (profileId) => ({ profileId, planDate })
                        ),
                    },
                };
            }
            return <Api.FormBody['recommendBySubdivisions']>{
                documentId: formData.documentId,
                receiversData: {
                    subdivisionIds: formData.subdivisions,
                    postIds: formData.posts,
                    planDate,
                },
            };
        }
        case FormName.EditRecommendation: {
            const formData = <IEditRecommendationFormData>reduxFormData;
            return <Api.FormBody[FormName.EditRecommendation]>{
                profilePlanDate: [
                    {
                        profileId: formData.profileId,
                        planDate: formData.required
                            ? moment(formData.finishDate).format(
                                  FULL_DATE_TIME_FORMAT
                              )
                            : undefined,
                    },
                ],
            };
        }
        case FormName.CatalogueEditCategory: {
            const { editingCategoryId, ...values } = <EditCategoryFormBody>(
                reduxFormData
            );
            return values;
        }
        default:
            return reduxFormData;
    }
};

const filterSendForm = (formName: FormName, state: Store.IState): boolean => {
    switch (formName) {
        case FormName.AddNewDocument: {
            return !state.loading.pendingRequests[FormName.AddNewDocument];
        }
        default:
            return true;
    }
};

export const sendForm = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }
) =>
    action$
        .ofType(sendFormAction.toString())
        .filter(({ payload }: Action<ISendFormPayload>) =>
            filterSendForm(payload.formName, store.getState())
        )
        .mergeMap((action: Action<ISendFormPayload>) => {
            const { formName } = action.payload;
            const state = store.getState();
            return concat(
                apiCall.forms[formName](
                    ...getRequestArgsByFormName(
                        formName,
                        state,
                        action.payload.data
                            ? action.payload.data
                            : state.forms[formName].data
                    ),
                    getRequestBodyByFormName(
                        formName,
                        state,
                        action.payload.data
                            ? action.payload.data
                            : state.forms[formName].data
                    )
                )
                    .retryWhen(createRetryOnIEAuthProblem())
                    .mergeMap((response) =>
                        Observable.from([
                            setFormResponse(formName, response.response),
                            formResolve(formName),
                        ])
                    )
                    .catch((e) => {
                        console.error(e);
                        return Observable.from([
                            setFormStatus(
                                formName,
                                Store.FormStatus.SendingFail
                            ),
                            formReject(e, formName),
                        ]);
                    }),
                Observable.of(
                    setFormStatus(formName, Store.FormStatus.SendingSuccess)
                )
            )
                .takeUntil(
                    action$
                        .ofType(formReject.toString())
                        .filter((act) => act.meta.requestName === formName)
                )
                .startWith(formRequest(formName));
        });
