import React, { useCallback } from 'react';
import { useLatest } from 'react-use';

import { useDescendantsOptimization } from './useDescendantsOptimization';
import { GetPropsFunc, ListSelectDeselectFunc } from './List';

import { ISelectOption } from '..';

interface IRegularOptionsRendererProps {
    activeMap: Record<string, boolean>;
    descendantsMap: Record<string, ISelectOption[]>;
    intermediateMap: Record<string, boolean>;
    options: ISelectOption[];

    onSelect: ListSelectDeselectFunc;
    onDeselect: ListSelectDeselectFunc;
    withDelay?: boolean;
    initiallyOpenedNestedLevel?: number;
    OptionGroupComponent?: React.ComponentType<any>;
    OptionComponent?: React.ComponentType<any>;
    optionComponentProps?: Record<string, any> | GetPropsFunc;
    optionGroupComponentProps?: Record<string, any> | GetPropsFunc;

    getSortedOptionChildren?(
        options: ISelectOption[],
        parentOption?: ISelectOption
    ): ISelectOption[];
}

export const RegularOptionsRenderer: React.FC<IRegularOptionsRendererProps> = (
    props
) => {
    const {
        activeMap,
        OptionGroupComponent,
        OptionComponent,
        optionComponentProps,
        optionGroupComponentProps,
        onSelect,
        onDeselect,
        descendantsMap,
        intermediateMap,
        initiallyOpenedNestedLevel,
        options,
        getSortedOptionChildren,
        withDelay,
    } = props;

    const activeMapRef = useLatest(activeMap);
    const intermediateMapRef = useLatest(intermediateMap);
    const getSortedOptionChildrenRef = useLatest(getSortedOptionChildren);

    const isAnyDescendantChanged = useDescendantsOptimization(
        activeMap,
        descendantsMap
    );

    const renderOptions = useCallback(
        ({
            options: subOptions,
            currentNestedLevel = 0,
            option: parentOption,
        }) => {
            const sortedSubOptions =
                getSortedOptionChildrenRef.current?.(
                    subOptions,
                    parentOption
                ) ?? subOptions;

            return sortedSubOptions.map((option) => {
                const nestedLevel = currentNestedLevel + 1;
                const hasSubOptions =
                    Array.isArray(option.options) && option.options.length > 0;

                const Component = hasSubOptions
                    ? OptionGroupComponent
                    : OptionComponent;

                let additionalProps;

                if (hasSubOptions) {
                    additionalProps =
                        typeof optionGroupComponentProps === 'function'
                            ? optionGroupComponentProps(option)
                            : optionGroupComponentProps;
                } else {
                    additionalProps =
                        typeof optionComponentProps === 'function'
                            ? optionComponentProps(option)
                            : optionComponentProps;
                }
                // ищем среди списка потомков опцию, у которой изменилось значение. если изменилось, значит, нужно ререндерить
                // поддерево, начиная от текущего компонента
                const anyDescendantChanged = isAnyDescendantChanged(option);

                return (
                    <Component
                        key={option.value}
                        active={!!activeMapRef.current[option.value]}
                        onSelect={onSelect}
                        onDeselect={onDeselect}
                        option={option}
                        initiallyOpened={
                            nestedLevel <= initiallyOpenedNestedLevel
                        }
                        intermediate={
                            !!intermediateMapRef.current?.[option.value]
                        }
                        withDelay={withDelay}
                        currentNestedLevel={nestedLevel}
                        options={option.options}
                        // этот проп нужен просто для того, чтобы компонент ререндернулся, когда у него меняется потомок
                        forceUpdate={anyDescendantChanged ? {} : undefined}
                        {...additionalProps}
                    >
                        {hasSubOptions ? renderOptions : null}
                    </Component>
                );
            });
        },
        [
            descendantsMap,
            OptionGroupComponent,
            OptionComponent,
            optionGroupComponentProps,
            optionComponentProps,
            onDeselect,
            onSelect,
            initiallyOpenedNestedLevel,
        ]
    );

    return <>{renderOptions({ options })}</>;
};
