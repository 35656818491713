import React, { Component } from 'react';
import classNames from 'classnames';
import autoBind from 'autobind-decorator';
import { pluralize } from 'numeralize-ru';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Modal from 'antd/es/modal/Modal';

import Form from 'app/components/common/forms/Form';
import { getUserNameByUserObject } from 'app/utils/getUserNameByUserObject';
import { getParametersForLinkToProfileFromIdAndLogins } from 'app/utils/document/login';
import clientRoutes from 'app/routing/clientRoutes';
import * as Store from 'app/redux/store/StoreNamespace';
import {
    DATE_DOTS_FORMAT,
    DATE_INPUT_DEFAULT_MAX_VALUE,
    DATE_INPUT_FORMAT_HINT,
} from 'app/utils/constants';

import IProps from './interfaces/IDocumentRecommendationProps';
import IState from './interfaces/IDocumentRecommendationState';
import * as styles from './DocumentRecommendation.scss';

@autoBind
export default class DocumentRecommendation extends Component<IProps, IState> {
    static defaultProps: Partial<IProps> = {
        posts: [],
        allEmployees: [],
        allPosts: [],
        allSubdivisions: [],
    };

    state: IState = {
        isForCertainEmployees: true,
        isRecommendationSuccessful: false,
        isFormValidationFailedOnSave: false,
    };

    formRef: React.RefObject<typeof Form.prototype> = React.createRef();

    minDate: number;

    static getUserWordDeclension(count: number): string {
        return pluralize(
            count,
            'пользователю',
            'пользователям',
            'пользователям'
        );
    }

    static renderUserLitsForSuccessMessage(
        users: Store.IUser[]
    ): JSX.Element[] {
        return users.map((user, index) => {
            const profileLinkParams =
                getParametersForLinkToProfileFromIdAndLogins(
                    user.id,
                    user.userLogins
                );

            return (
                <React.Fragment key={user.id}>
                    <Link
                        target="_blank"
                        to={clientRoutes.account.subRoutes.profile.getUrl(
                            null,
                            profileLinkParams
                        )}
                    >
                        {getUserNameByUserObject(user)}
                    </Link>
                    {index < users.length - 1 && <span>, </span>}
                </React.Fragment>
            );
        });
    }

    render(): JSX.Element {
        const {
            isVisible,
            status,
            isRequired,
            onChangeRequired,
            learnBefore,
            onLearnBeforeDateChange,
            employees,
            subdivisions,
            posts,
            allEmployees,
            allPosts,
            allSubdivisions,
            onValidationFail,
            onValidationSuccess,
            isEditing,
            editingForEmployeeName,
            editingForDocumentTitle,
            numberOfUsersDeclined,
            numberOfUsersSent,
            usersAlreadyLearnt,
            usersAlreadyRecommended,
        } = this.props;
        const { isForCertainEmployees, isRecommendationSuccessful } =
            this.state;
        return (
            <Modal
                visible={isVisible}
                maskClosable={false}
                footer={null}
                onCancel={this.cancel}
                afterClose={this.afterModalClose}
                className={classNames('modal', styles.docRecommendation, {
                    [styles.docRecommendationSingle]: isForCertainEmployees,
                    [styles.docRecommendationSuccess]:
                        isRecommendationSuccessful,
                    [styles.docRecommendationRecommendationRequired]:
                        isRequired,
                    [styles.docRecommendationEditing]: isEditing,
                })}
            >
                <header className={styles.docRecommendationTitle}>
                    {!isEditing ? (
                        <h2>Рекомендовать документ</h2>
                    ) : (
                        <h2>Редактирование рекомендации</h2>
                    )}
                </header>
                <div className={styles.docRecommendationSuccessMessage}>
                    {isEditing ? (
                        'Рекомендация успешно отредактирована'
                    ) : (
                        <div>
                            {!!numberOfUsersSent && (
                                <div
                                    className={
                                        styles.docRecommendationSuccessMessageUsersCount
                                    }
                                >
                                    Рекомендация успешно отправлена{' '}
                                    {numberOfUsersSent} &nbsp;
                                    {DocumentRecommendation.getUserWordDeclension(
                                        numberOfUsersSent
                                    )}
                                </div>
                            )}
                            {!!numberOfUsersDeclined && (
                                <>
                                    <div
                                        className={
                                            styles.docRecommendationSuccessMessageUsersCount
                                        }
                                    >
                                        Рекомендация не была отправлена{' '}
                                        {numberOfUsersDeclined} &nbsp;
                                        {DocumentRecommendation.getUserWordDeclension(
                                            numberOfUsersDeclined
                                        )}
                                    </div>
                                    {usersAlreadyLearnt &&
                                        usersAlreadyLearnt.length > 0 && (
                                            <div
                                                className={
                                                    styles.docRecommendationSuccessMessageUsersList
                                                }
                                            >
                                                <div>
                                                    Рекомендуемый документ уже
                                                    изучили пользователи:
                                                </div>
                                                <div>
                                                    {DocumentRecommendation.renderUserLitsForSuccessMessage(
                                                        usersAlreadyLearnt
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    {usersAlreadyRecommended &&
                                        usersAlreadyRecommended.length > 0 && (
                                            <div
                                                className={
                                                    styles.docRecommendationSuccessMessageUsersList
                                                }
                                            >
                                                <div>
                                                    Вы уже рекомендовали данный
                                                    документ пользователям:
                                                </div>
                                                <div>
                                                    {DocumentRecommendation.renderUserLitsForSuccessMessage(
                                                        usersAlreadyRecommended
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                </>
                            )}
                            <div
                                className={
                                    styles.docRecommendationSuccessMessageHelp
                                }
                            >
                                Внести изменения в ранее отправленные
                                рекомендации вы можете в разделе
                                <br />
                                <Link
                                    to={clientRoutes.documentManagement.subRoutes.recommendations.getUrl()}
                                >
                                    Обучающие материалы - Исходящие рекомендации
                                </Link>
                            </div>
                            <div
                                className={
                                    styles.docRecommendationSuccessMessageControls
                                }
                            >
                                <button
                                    className="btn btn-ok"
                                    onClick={this.cancel}
                                >
                                    Ок
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.docRecommendationBody}>
                    {!isEditing && (
                        <ul
                            className={`clearfix ${styles.docRecommendationSwitcher}`}
                        >
                            <li>
                                <button
                                    onClick={() =>
                                        this.setState({
                                            isForCertainEmployees: true,
                                            isFormValidationFailedOnSave: false,
                                        })
                                    }
                                    className={classNames(
                                        'btn',
                                        styles.docRecommendationSwitcherItem,
                                        {
                                            [styles.docRecommendationSwitcherItemActive]:
                                                isForCertainEmployees,
                                        }
                                    )}
                                >
                                    Сотруднику
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() =>
                                        this.setState({
                                            isForCertainEmployees: false,
                                            isFormValidationFailedOnSave: false,
                                        })
                                    }
                                    className={classNames(
                                        'btn',
                                        styles.docRecommendationSwitcherItem,
                                        {
                                            [styles.docRecommendationSwitcherItemActive]:
                                                !isForCertainEmployees,
                                        }
                                    )}
                                >
                                    Группе сотрудников
                                </button>
                            </li>
                        </ul>
                    )}
                    {isEditing && (
                        <div
                            className={
                                styles.docRecommendationDocumentTitleField
                            }
                        >
                            <div className="input-field">
                                <label className="input-field__title">
                                    Документа
                                </label>
                                <input
                                    disabled
                                    className="input-field__input"
                                    type="text"
                                    title={editingForDocumentTitle}
                                    value={editingForDocumentTitle}
                                />
                            </div>
                        </div>
                    )}
                    <Form
                        ref={this.formRef}
                        status={status}
                        activateValidationForAllFields={
                            this.state.isFormValidationFailedOnSave
                        }
                        validation={{
                            subdivisions: [
                                (value: string[]) => ({
                                    isValid:
                                        !isEditing && !isForCertainEmployees
                                            ? value && value.length > 0
                                            : true,
                                }),
                            ],
                            posts: [
                                (value: string[]) => ({
                                    isValid:
                                        !isEditing && !isForCertainEmployees
                                            ? value && value.length > 0
                                            : true,
                                }),
                            ],
                            employees: [
                                (value: string[]) => ({
                                    isValid:
                                        !isEditing && isForCertainEmployees
                                            ? value && value.length > 0
                                            : true,
                                }),
                            ],
                            date: [
                                (val: string | number) => ({
                                    isValid: isRequired
                                        ? typeof val !== 'string'
                                        : true,
                                    message: `Дата должна быть указана в формате ${DATE_INPUT_FORMAT_HINT}`,
                                }),
                                (val: string | number) => {
                                    const isValNotNumber =
                                        typeof val === 'string';
                                    return {
                                        isValid: isRequired
                                            ? isValNotNumber ||
                                              (val <=
                                                  DATE_INPUT_DEFAULT_MAX_VALUE &&
                                                  val >= this.minDate)
                                            : true,
                                        message:
                                            `Дата должна быть указана от ` +
                                            `${moment(this.minDate).format(
                                                DATE_DOTS_FORMAT
                                            )} ` +
                                            `до ${moment(
                                                DATE_INPUT_DEFAULT_MAX_VALUE
                                            ).format(DATE_DOTS_FORMAT)}`,
                                    };
                                },
                            ],
                        }}
                        onValidationFail={onValidationFail}
                        onValidationSuccess={onValidationSuccess}
                        fields={{
                            subdivisions: {
                                isRequired: true,
                                type: 'multiselect',
                                value: subdivisions,
                                title: 'Подразделение',
                                options: allSubdivisions,
                                onSelectOption: this.select,
                                onDeselectOption: this.deSelect,
                                meta: { fieldName: 'subdivisions' },
                                fieldCssClass:
                                    styles.docRecommendationSubdivisions,
                                isDisabled: isEditing,
                            },
                            posts: {
                                isRequired: true,
                                type: 'multiselect',
                                title: 'Должность',
                                value: posts,
                                options: allPosts,
                                onSelectOption: this.select,
                                onDeselectOption: this.deSelect,
                                meta: { fieldName: 'posts' },
                                fieldCssClass: styles.docRecommendationPosts,
                                isDisabled: isEditing,
                            },
                            employees: {
                                isRequired: true,
                                type: 'multiselect',
                                value: employees,
                                title: 'Сотруднику',
                                options: allEmployees,
                                onSelectOption: this.select,
                                onDeselectOption: this.deSelect,
                                meta: { fieldName: 'employees' },
                                fieldCssClass:
                                    styles.docRecommendationEmployees,
                                placeholder: editingForEmployeeName,
                                isDisabled: isEditing,
                            },
                            require: {
                                type: 'checkbox',
                                value: isRequired,
                                title: 'Обязателен к изучению',
                                onCheckboxChange: onChangeRequired,
                                fieldCssClass:
                                    styles.docRecommendationRequiredToLearn,
                            },
                            date: {
                                type: 'date',
                                value: learnBefore,
                                title: 'Изучить до',
                                onDateChange: onLearnBeforeDateChange,
                                minDate: this.minDate,
                                fieldCssClass:
                                    styles.docRecommendationLearnDate,
                            },
                        }}
                        onFormSaved={this.formSaved}
                    />
                    {this.state.isFormValidationFailedOnSave && (
                        <div className={styles.docRecommendationErrorMessage}>
                            Не все обязательные поля формы заполнены или
                            некоторые поля заполнены с ошибками
                        </div>
                    )}
                    <div className={styles.docRecommendationControls}>
                        <button
                            className="btn btn-primary"
                            onClick={this.sendForm}
                        >
                            {!isEditing ? 'Рекомендовать' : 'Редактировать'}
                        </button>
                        <button className="btn" onClick={this.cancel}>
                            Отменить
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    componentDidMount(): void {
        if (this.props.isVisible) {
            this.setMinDate();
            this.props.onShown();
        }
    }

    componentDidUpdate(prevProps: IProps): void {
        const { allPosts, posts, setPosts, onShown } = this.props;
        if (!prevProps.isVisible && this.props.isVisible) {
            this.setMinDate();
            onShown();
        }
        if (prevProps.allPosts !== allPosts && posts && posts.length > 0) {
            const nextPosts = [];
            posts.forEach((postId) => {
                if (allPosts.find((post) => post.value === postId)) {
                    nextPosts.push(postId);
                }
            });
            setPosts(nextPosts);
        }
    }

    componentWillUnmount(): void {
        this.props.onHidden();
    }

    setMinDate(): void {
        this.minDate = moment().startOf('day').valueOf();
    }

    select(
        value: string,
        optMeta: any,
        { fieldName }: { fieldName: string }
    ): void {
        this.props.onSelect(fieldName, value);
    }

    deSelect(
        value: string,
        optMeta: any,
        { fieldName }: { fieldName: string }
    ): void {
        this.props.onDeSelect(fieldName, value);
    }

    sendForm(): void {
        const {
            onSend,
            isRequired,
            subdivisions,
            posts,
            employees,
            learnBefore,
            documentId,
            isEditing,
            editingForProfileId,
            editingRecommendationId,
        } = this.props;
        const { isFormValidationFailedOnSave } = this.state;

        if (!this.formRef.current.getIsFormValid(true)) {
            if (!isFormValidationFailedOnSave)
                this.setState({ isFormValidationFailedOnSave: true });
            return;
        }
        if (isFormValidationFailedOnSave)
            this.setState({ isFormValidationFailedOnSave: false });
        if (isEditing) {
            onSend({
                required: isRequired,
                finishDate: learnBefore,
                recommendationId: editingRecommendationId,
                profileId: editingForProfileId,
            });
        } else {
            onSend({
                required: isRequired,
                subdivisions,
                posts,
                employees,
                finishDate: learnBefore,
                isForCertainEmployees: this.state.isForCertainEmployees,
                documentId,
            });
        }
    }

    cancel(): void {
        this.props.onCancel();
    }

    formSaved(): void {
        this.setState({ isRecommendationSuccessful: true });
    }

    afterModalClose(): void {
        this.props.onHidden();
        this.setState({
            isRecommendationSuccessful: false,
            isForCertainEmployees: true,
            isFormValidationFailedOnSave: false,
        });
    }
}
