/**
 * Created by lkarmelo on 21.06.2019.
 */

import React, { useCallback, useEffect, useRef } from 'react';
import { useToggle } from 'react-use';
import classNames from 'classnames';

import { Header } from 'app/components/header/Header';
import SearchPanel from 'app/components/search/SearchPanel';

import * as styles from './FixedHeader.scss';
import { scrollingHeader } from './helpers/scrollingHeader';

import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';

const FixedHeader: React.FunctionComponent = () => {
    const {
        doodle,
        guideResults: { showTour: isTourOpen },
    } = useTypedSelector((store) => store);

    const { fetchDoodle } = useActions();

    const [isHeaderFixed, toggleIsHeaderFixed] = useToggle(false);

    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        fetchDoodle();
    }, []);

    useEffect(() => {
        scrollingHeader(ref, toggleIsHeaderFixed, isTourOpen);
    }, [isTourOpen]);

    const onSearch = useCallback(() => {
        ref.current && ref.current.classList.remove(styles.fixedHeaderFixed);
        toggleIsHeaderFixed(false);
        window.scrollTo(0, 0);
    }, []);
    const publishedDoodle = doodle && Object.keys(doodle).length;

    if(doodle.link === 'undefined') {
        doodle.link = '/'
    }

    return (
        <>
            <div ref={ref} className={styles.fixedHeader}>
                <Header doodle={doodle} showUserMenu>
                    <div
                        className={classNames(styles.fixedHeaderPanelWrapper, {
                            [styles.fixedHeaderPanelWrapperWithDoodle]:
                                publishedDoodle,
                        })}
                    >
                        {!!publishedDoodle && (
                            <a
                                title={doodle.description}
                                target={doodle.newBlank ? '_blank' : '_self'}
                                className={classNames(
                                    styles.doodleMain,
                                    styles.doodleMainImage
                                )}
                                style={{
                                    backgroundImage: `url(${doodle.mainPageImg})`,
                                }}
                                href={doodle.link}
                                rel="noreferrer"
                            />
                        )}
                        {
                            // @ts-ignore
                            <SearchPanel
                                isHeaderFixed={isHeaderFixed}
                                autofocus={false}
                                onFetchSearchResultsClick={onSearch}
                                onClearSearchQueryClick={onSearch}
                            />
                        }
                    </div>
                </Header>
            </div>
            {/*
                этот элемент нужен, чтобы он занимал место хедера, когда тот получает position: fixed. Если его не будет, вся остальная
                страница будет скакать при смене position
            */}
            <div className={styles.fixedHeaderFiller} />
        </>
    );
};

export default FixedHeader;
