import { createAction } from 'redux-actions';

import Api from 'app/api/Api';

export interface ISetExtractedConceptsPayload {
    extractedConcepts?: Api.IExtractedConcept[];
    setInitialSelected?: boolean;
}
export const setExtractedConcepts = createAction<ISetExtractedConceptsPayload>(
    'SET_EXTRACTED_CONCEPTS'
);

export interface ISetConceptsSelectStatePayload {
    selectState: Record<string, boolean>;
    // без этого флага в редакс будет выставлен {} вместо selectState, если selectState deep equal initialSelectState
    ignoreEqualityToInitial?: boolean;
}
export const setConceptsSelectState =
    createAction<ISetConceptsSelectStatePayload>('SET_CONCEPTS_SELECT_STATE');

export const clearConceptsSelectState = createAction(
    'CLEAR_CONCEPTS_SELECT_STATE'
);
