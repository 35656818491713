import React, { useMemo } from 'react';
import classNames from 'classnames';
import Tooltip from 'antd/es/tooltip';

import { useTypedSelector } from 'app/hooks/useTypedSelector';
import { SearchDocumentName } from 'app/redux/actions/interfaces/DocumentEnum';

import styles from './PersonCard.scss';
import IProps from './interfaces/IPersonCardProps';
import ContactInfo from './components/ContactInfo';
import { getShareContactLink } from './helpers/getShareContactLink';
import UserData from './components/UserData';

import PersonAvatar from '../../../PersonAvatar/PersonAvatarConnected';

const PersonCard = React.forwardRef<HTMLElement, IProps>(
    ({ user, className, onPersonClick }, forwardedRef) => {
        const { content, id } = user;

        const { items: persons } = useTypedSelector(
            (state) => state[SearchDocumentName.search].personResults
        );

        const shareContactLink = useMemo(
            () => getShareContactLink(content),
            [content]
        );

        const avatar = persons.find((pers) => pers.id === id).content.avatar;

        if (!content || !id) {
            return null;
        }

        return (
            <article
                className={classNames(styles.personCard, className)}
                ref={forwardedRef}
            >
                <section className={styles.personCardMainInfo}>
                    <div className={styles.personCardCard}>
                        <PersonAvatar
                            avatar={avatar && avatar.avatar}
                            userId={id}
                            className={styles.personCardAvatar}
                            fullTitle={content.fullTitle}
                        />
                        <div className={styles.personCardNameBlock}>
                            <h2 className={styles.personCardName}>
                                {content.fullTitle}
                                <Tooltip title="Поделиться">
                                    <a
                                        className={styles.personCardShareLink}
                                        href={shareContactLink}
                                    >
                                        <i
                                            className={`nkc-icon-share ${styles.personCardShareIcon}`}
                                        />
                                    </a>
                                </Tooltip>
                            </h2>
                            <h3 className={styles.personCardPosition}>
                                {content.positionName}
                            </h3>
                        </div>
                    </div>
                    <UserData
                        user={user}
                        onPersonClick={onPersonClick}
                        isOpened={false}
                    />
                </section>
                <ContactInfo user={user} />
            </article>
        );
    }
);

export default PersonCard;
