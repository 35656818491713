/**
 * Created by lkarmelo on 27.11.2019.
 */

import React, { useCallback } from 'react';

import TagsInput from './TagsInput';
import IProps from './interfaces/ITagsInputAdapterProps';

const TagsInputAdapter: React.FC<IProps> = (props) => {
    const { onChange, onFocus, onBlur, value, ...rest } = props;

    const onAdd = useCallback(
        (tag: string[]) => {
            if (!Array.isArray(value)) {
                onChange(tag);
                return;
            }
            const nextVal = value.slice();
            nextVal.push(...tag);
            onChange(nextVal);
        },
        [value, onChange]
    );

    const onRemove = useCallback(
        (tag: string) => {
            if (!Array.isArray(value)) {
                onChange([]);
                return;
            }
            onChange(value.filter((t) => t !== tag));
        },
        [onChange, value]
    );
    return (
        <TagsInput {...rest} onAdd={onAdd} onRemove={onRemove} tags={value} />
    );
};

export default TagsInputAdapter;
