import { Action, handleActions } from 'redux-actions';

import {
    ISetModalVisiblePayload,
    setModalVisible,
} from 'app/redux/actions/modals';
import initialState from 'app/redux/store/initialState';

export default handleActions(
    {
        [setModalVisible.toString()]: (
            state,
            { payload }: Action<ISetModalVisiblePayload>
        ) => ({
            ...state,
            [payload.modalName]: payload.visible,
        }),
    },
    initialState.modals
);
