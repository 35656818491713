/*
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 28.06.2018
 * -----
 * Last Modified: 28.06.2018 18:17:52
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import React, { PureComponent } from 'react';
import classNames from 'classnames';

import ISearchProps from './interfaces/ISearchQueryProps';

export default class SearchQuery extends PureComponent<ISearchProps> {
    static defaultProps = {
        value: '',
        hideBtn: false,
    };

    inputRef: React.RefObject<HTMLInputElement> = React.createRef();

    constructor(props: ISearchProps) {
        super(props);
    }

    render() {
        const {
            disabled,
            focusable,
            placeholder,
            value,
            hideBtn,
            onSearchFieldChange,
            onSearchFieldKeyDown,
            handleSearchClick,
        } = this.props;

        return (
            <div className="search-filter">
                <div
                    className={classNames('search-filter__input-wrapper', {
                        'search-filter_disabled': disabled,
                    })}
                >
                    <div className="search-filter__text-area-wrapper">
                        <input
                            ref={this.inputRef}
                            className="search-filter__text-area"
                            placeholder={placeholder}
                            onChange={onSearchFieldChange}
                            value={value}
                            onKeyDown={onSearchFieldKeyDown}
                            tabIndex={focusable ? 0 : null}
                        />
                    </div>
                    {!hideBtn && (
                        <button
                            disabled={!value}
                            className="search-filter__search-btn btn"
                            onClick={handleSearchClick}
                        />
                    )}
                </div>
            </div>
        );
    }
}
