/**
 * Created by lkarmelo on 23.07.2019.
 */

import { MiddlewareAPI } from 'redux';
import { Action } from 'redux-actions';
import { Observable } from 'rxjs/Observable';
import { concat } from 'rxjs/observable/concat';
import { merge } from 'rxjs/observable/merge';
import { forkJoin } from 'rxjs/observable/forkJoin';

import { updateLocation } from 'app/redux/actions/search/filters';
import {
    compressRequestUpdateLocationAndSearch,
    executeSearch,
    ICompressRequestAndSearchPayload,
} from 'app/redux/actions/search/results';
import {
    compressSearchReject,
    compressSearchRequest,
    compressSearchResolve,
    searchResolve,
} from 'app/redux/actions/loading';
import {
    setExecutingSearchAlias,
    setLastExecutedSearchAlias,
} from 'app/redux/actions/search/searchAlias';
import Api from 'app/api/Api';
import * as Store from 'app/redux/store/StoreNamespace';
import { RequestName } from 'app/api/RequestName';
import { setQueryExpansion } from 'app/redux/actions/search/searchQuery';
import { setSingleSearchTabMeta } from 'app/redux/actions/search/searchTabs';

import { getSearchState } from './utils/getSearchState';
import { getOfTypeLoadOnLocationChange } from './loadSearchResultsOnLocationChange';
import { getRequestBody, getRequestQuery } from './utils/getSearchRequestInfo';

import { createRetryOnIEAuthProblem } from '../utils/createRetryOnIEAuthProblem';

const getAliasRequestMeta = (
    state: Store.IState,
    contextState: Store.ISearch
): Api.ISearchAliasMeta | undefined => {
    const { filtersMeta } = state;
    const { filters } = contextState;

    const aliasMeta: Api.ISearchAliasMeta = {
        savedFiltersMetaValues: {},
    };

    Object.entries(filters).forEach(([filterName, { value }]) => {
        const meta = filtersMeta[filterName];
        // нам нужно сохранять информацию только о MultiSelect фильтрах, которые приходят с сервера и помечаются
        // скрытыми - это фильтры, которые используются в подсказках и приходят без списка возможных значений
        const shouldFilterBeSaved =
            meta.type === Store.FilterType.MultiSelect &&
            meta.isFromServer &&
            meta.hidden;

        if (
            !shouldFilterBeSaved ||
            !Array.isArray(value) ||
            value.length === 0
        ) {
            return;
        }

        aliasMeta.savedFiltersMetaValues[filterName] = [];

        value.forEach((valueItem: string) => {
            // берём только активные значения фильтра, а не все, которые будут в meta.values, чтобы минимизировать aliasMeta
            const valueMeta = meta.values.find(
                (metaVal) => metaVal.value === valueItem
            );
            valueMeta &&
                aliasMeta.savedFiltersMetaValues[filterName].push(valueMeta);
        });
    });

    return Object.keys(aliasMeta.savedFiltersMetaValues).length > 0
        ? aliasMeta
        : undefined;
};

export const compressRequestUpdateLocationAndSearchEpic = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(compressRequestUpdateLocationAndSearch.toString())
        .hasContext(false)
        .switchMap((action: Action<ICompressRequestAndSearchPayload>) => {
            const isExpandQueryRequestPending =
                store.getState().loading.pendingRequests[
                    RequestName.ExpandQuery
                ];

            if (
                !action.payload.skipSearchRequest &&
                isExpandQueryRequestPending
            ) {
                return action$
                    .ofType(setQueryExpansion.toString())
                    .mapTo(action)
                    .take(1);
            }
            return Observable.of(action);
        })
        .switchMap((action: Action<ICompressRequestAndSearchPayload>) => {
            if (action.payload.tabHasChanged) {
                return action$
                    .ofType(setSingleSearchTabMeta.toString())
                    .mapTo(action)
                    .take(1);
            }
            return Observable.of(action);
        })
        .mergeMap(({ payload }: Action<ICompressRequestAndSearchPayload>) => {
            const {
                isNewSearch,
                skipSearchRequest,
                setPersonStrictFromResponse,
                setExtractedConcepts,
                setInitialSelectedFromExtractedConcepts,
            } = payload;

            const state = store.getState();
            const contextState = getSearchState(store);

            const originalQuery = getRequestQuery(contextState, isNewSearch);
            const request = getRequestBody(state, contextState, originalQuery);
            const { filters } = contextState;

            const aliasRequestMeta = getAliasRequestMeta(state, contextState);

            return merge(
                forkJoin(
                    // нужно именно Observable.of() с любым аргументом всесто Observable.empty(), потому что иначе forkJoin не сработает
                    skipSearchRequest
                        ? Observable.of(undefined)
                        : action$.ofType(searchResolve.toString()).take(1),
                    action$.ofType(setExecutingSearchAlias.toString()).take(1)
                ).map(([_, alias]: [undefined, string]) =>
                    setLastExecutedSearchAlias(alias)
                ),
                concat(
                    Observable.of(compressSearchRequest()),
                    apiCall
                        .compressSearchRequest(request, aliasRequestMeta)
                        .retryWhen(createRetryOnIEAuthProblem())
                        .mergeMap(({ response }: { response: string }) =>
                            Observable.of(
                                setExecutingSearchAlias(response),
                                updateLocation()
                            )
                        )
                        .catch((e) => {
                            console.error(e);
                            return Observable.of(compressSearchReject(e));
                        })
                        .takeUntil(
                            merge(
                                action$.ofType(compressSearchReject.toString()),
                                getOfTypeLoadOnLocationChange(action$)
                            )
                        ),
                    Observable.of(
                        compressSearchResolve(),
                        setExecutingSearchAlias(null)
                    )
                ),
                skipSearchRequest
                    ? Observable.empty()
                    : Observable.of(
                          executeSearch({
                              requestBody: request,
                              clearQuery: originalQuery,
                              filters,
                              setPersonStrictFromResponse,
                              setExtractedConcepts,
                              setInitialSelectedFromExtractedConcepts,
                          })
                      )
            ).takeUntil(
                action$
                    .ofType(compressRequestUpdateLocationAndSearch.toString())
                    .hasContext(false)
            );
        });
