import { ReactourStep } from 'reactour';

/**
 * Created by Lkarmelo on 26.09.2017.
 */
import { SearchDocumentName } from 'app/redux/actions/interfaces/DocumentEnum';

import { IProgress } from '../actions/files';
import { IGuide } from '../../types/tour';

export interface ISortingOption {
    key: string;
    fields: string[];
    label: string;
    orderless?: boolean;
    startingSortingOrder?: SortingOrder;
}

export const sortingOptions: Record<string, ISortingOption> = {
    // если выбрана сортировка по релевантности, то она будет исключена из запроса
    RelevancySortingOption: {
        key: 'RelevancySortingOption',
        fields: ['RELEVANCY_SORTING'],
        label: 'По релевантности',
        orderless: true,
    },
    DateSortingOption: {
        key: 'DateSortingOption',
        fields: ['year', 'month.keyword', 'originDate'],
        label: 'По дате',
    },
    NameSortingOption: {
        key: 'NameSortingOption',
        fields: ['fullTitleLangOrder', 'fullTitle'],
        label: 'По имени',
        startingSortingOrder: 'asc',
    },
    BookmarkedDateSortingOption: {
        key: 'BookmarkedDateSortingOption',
        fields: ['bookmarkedDate'],
        label: 'По умолчанию',
    },
};

export const SibNefGaz = 'SNGZ';

export enum FileStateEnum {
    Error,
    Uploading,
    Uploaded,
    TmpFile,
    Redo,
    Stored,
    Deleted,
}

export const NotificationContext = 'currentUserNotification';
export const RecommendationsContext = 'recommendations';

export interface IState {
    // какая вкладка выбрана для поиска
    mainSearchPageActiveTab?: string | null;

    // тоже самое, что и активная вкладка, но выставляется только после того, как завершится поиск
    // нужно для того, чтобы при смене вкладки отображать предыдущие результаты на странице, пока не пришли новые
    mainSearchPageActiveTabForResults?: string | null;

    searchTabs: ISearchTabsMeta;

    [SearchDocumentName.search]: ISearch;

    filtersMeta: IFiltersMeta;
    favoritesFiltersMeta: IFiltersMeta;
    defaultFilters: IFilters;

    fileStore: IFileStore;
    [SearchDocumentName.favorites]?: ISearch;
    [SearchDocumentName.subscribes]?: ISearch;
    [SearchDocumentName.readLater]?: ISearch;
    // документы, связанные с пользователем, чей профиль просматривается
    [SearchDocumentName.related]?: ISearch;
    [SearchDocumentName.uploadDocument]?: ISearch;

    objectCard?: IObjectCard;
    [SearchDocumentName.objectCardSimilar]?: ISearch;

    authorization: IAuthorization;
    passwordRecovery: {
        status?: number;
        error?: string;
    };

    // пользователь системы
    user?: ISystemUser;

    // текущий пользователь, чей профиль просматривается
    currentUser?: IUser;
    currentUserWorkInfo?: IEmployeeInfo[];
    currentUserCompetencies?: IUserCompetence[];
    currentUserKnowledgeCategories?: IKnowledgeCategory[];
    currentUserSkills?: IUserSkill[];

    forms?: IForms;
    navMenuDropdownVisibility?: INavMenuDropdownVisibility;
    //
    [NotificationContext]?: INotificationResults;

    // все доступные компетенции
    competencies?: ISimpleCatalogItem[];
    // все доступные навыки
    skills?: ISimpleCatalogItem[];
    // информация о всех доступых организациях, должностях, подразделениях и адресах
    organizationsInfo?: IOrganizationsInfo;
    // нормализованные данные о подразделениях, их иерархии, сотрудниках, должностях по сотрудникам и должностях по подразделениям
    orgStructure?: INormalizedOrgStructure;

    // категории для каталога
    catalogueCategories?: INormalizedHierarchicalCategories;
    [SearchDocumentName.catalogueDocuments]?: ISearch;
    [SearchDocumentName.catalogueDocumentsNew]?: ISearch;

    // обучающие материалы
    [SearchDocumentName.documentManagementAll]?: ISearch;
    [SearchDocumentName.documentManagementOwn]?: ISearch;

    // типы обучающих материалов
    educationMaterialTypes?: ISimpleCatalogItem[];

    [SearchDocumentName.education]?: IEducationMaterials;

    [RecommendationsContext]?: IRecommendationsWithPagingAndFilters;

    outsideRedirectInfo?: IRedirectInfo;
    guideResults?: IGuideResults;

    conceptDescriptions: Record<string, IConceptDescription>;

    loading: ILoadingInfo;
    // добавляется react-redux-loading-bar
    loadingBar: { default: number };

    modals: Record<string, boolean>;
    doodle: IDoodle;
}

export const enum ModalName {
    Report = 'Report',
}

export interface IFileStore {
    files: { [key: string]: IFileMeta };
    keys: string[];
}

export interface IFileMeta {
    fid?: string;
    name: string;
    size: number;
    status: FileStateEnum;
    mimeType: string;
    progress?: IProgress;
    error?: string;

    // info for reupload file
    file?: File;
}

export interface IFiltersMeta {
    [filterName: string]: IFilterMeta;
}

export interface IFilterMeta {
    filterName: string;
    alwaysVisible?: boolean;
    title?: string;
    values?: IFilterMetaValue[];
    type: FilterType;
    hierarchical?: boolean;
    order?: number;
    // тип фильтра, как он приходит с сервера. нужен для правильного построение поискового запроса
    serverType?: ServerFilterType;
    default?: string[] | string | Object;
    isFromServer?: boolean;
    isParam?: boolean;
    hidden?: boolean;
    // дефолтные значения у фильтра с serverType === RangeFilter
    from?: number;
    to?: number;
    searchTargets?: SearchTarget[];
}

export const enum FilterType {
    Select = 'select',
    MultiSelect = 'multiselect',
    LinearMultiSelect = 'linearMultiselect',
    YearRange = 'yearrange',
    DateRange = 'daterange',
    Tags = 'tags',
    CheckBox = 'checkbox',
    Search = 'search',
    Text = 'textfilter',
}

export const enum ServerFilterType {
    SelectFilter = 'SelectFilter',
    RangeFilter = 'RangeFilter',
}

export interface IFilterMetaValue {
    label: string;
    value: string;
    parent?: string;
}

export interface IFilters {
    [filterName: string]: IFilter;
}

export interface IFilter {
    value?: IFilterValue;
    valueSetTime?: IValueTimeMap | number;
}

export interface IValueTimeMap {
    [val: string]: number;
}

export type IFilterValue =
    | number
    | string
    | boolean
    | string[]
    | IDateFilterValue;

export interface IDateFilterValue {
    from?: number;
    to?: number;
}

export type IRangeFilterValue = IDateFilterValue;

// фильтры, которые не приходят с сервера
export const enum StaticFilterName {
    Thesaurus = 'thesaurus',
    Search = 'search',
}

export const staticFilters: IFiltersMeta = {
    [StaticFilterName.Thesaurus]: {
        filterName: StaticFilterName.Thesaurus,
        default: false,
        type: FilterType.CheckBox,
        title: 'Подключить тезаурусы',
    },
    [StaticFilterName.Search]: {
        filterName: StaticFilterName.Search,
        title: '',
        type: FilterType.Search,
    },
};

export const enum SearchTarget {
    document = 'DOC',
    employee = 'EMPLOYEE',
}

export interface ISearchTabsMeta {
    defaultTabCode?: string;
    tabs?: Record<string, ISearchTab>;
    tabCodes?: string[];
}

export interface ISearchTab {
    id: string;
    code: string;
    name: string;
    order?: number;
    searchTarget: SearchTarget;
    showPersonalizationSwitch?: boolean;
    description?: string;

    // Эти свойства догружаются при смене вкладки.
    // При начальном запросе всех вкладок их нет
    filters?: IFiltersMeta;
    sortingOptions?: ISortingOption[];
    personalization?: boolean;
}

export interface IPaging {
    skip: number;
    limit: number;
    isBlocked?: boolean;
}

export interface ILoadingInfo {
    pendingRequests: { [requestName: string]: boolean };
    pendingRequestsCount: number;
    loadingErrors: ILoadingRequestsErrors;
}

export interface ILoadingRequestsErrors {
    [requestName: string]: ILoadingError;
}

export interface ILoadingError {
    message: string;
    showInMainErrorComponent?: boolean;
    btnTitle?: string;
    closeByBtnClickOnly?: boolean;
    onCloseAction?: () => void;
}

export const enum SearchResultPageEnum {
    searchQuery = 'searchQuery',
    results = 'results',
    paging = 'paging',
    filters = 'filters',
    terms = 'terms',
    hints = 'hints',
}

export interface IFiltersAndPaging {
    filters?: IFilters;
    paging?: IPaging;
}

export interface IFilterSettings {
    filtersMeta: IFiltersMeta;
    defaultFilters: IFilters;
}

export interface IConcept {
    uri: string;
    label: string;
}

export interface IConceptWithChildren extends IConcept {
    children: string[];
}

export type IConceptTree = Omit<IConceptWithRelations, 'related'>;

export interface IConceptWithRelations {
    item: ISelectableSchemeConcept;
    tree?: IConceptTree[];
    related?: IConceptWithRelations[];
}

export interface ISchemeConcept extends IConcept {
    inScheme: {
        uri: string;
        label: string;
    };
}

export interface ISelectableConcept extends IConcept {
    selected: boolean;
    fixed: boolean;
}

export interface ISelectableSchemeConcept
    extends ISelectableConcept,
        ISchemeConcept {}

export interface ISearchConcepts {
    // все концепты, пришедщие в ответ на посиковый запрос, включая те, что указаны в tree
    concepts: Record<string, IConceptWithRelations>;
    // список uri концептов поискового запроса, исключая иерархическую структуру в tree
    extractedConcepts: string[];
    selectState: Record<string, boolean>;
    initialSelectState: Record<string, boolean>;
}

export interface ISearch extends IFiltersAndPaging {
    // строгий ли поиск. приходит с бэкенда после первого запроса и меняется на null после изменения текста.
    // пока что только для юзеров, потом будет переделано апи(?)
    personStrict?: boolean | null;

    // вкл/откл персонализация в поиске
    personalization?: boolean;

    // в initialState null используется вместо undefined, потому что redux не позволяет иметь undefined как начальное значение
    searchQuery?: string;
    // последний searchQuery с которым успешно выполнился поиск
    lastExecutedSearchQuery?: string | null;
    // searchQuery с которым отправился текущий запрос
    executingSearchQuery?: string | null;

    // здесь хранятся распаршенные теги, которые были введены в поисковую строку. обновляется при успешном поиске
    tagsFromQuery?: Record<string, boolean>;

    ignoreMistakes?: boolean; // флаг для поискового запроса, чтобы сервер не искал по "исправленному" запросу в случае опечаток
    lastExecutedIgnoreMistakes?: boolean;

    // последний search alias с которым успешно выполнился поиск
    lastExecutedSearchAlias?: string;
    // search alias с которым отправился текущий запрос
    executingSearchAlias?: string;
    // search alias по которому в текущий момент получается хешированный body поискового запроса
    resolvingSearchAlias?: string;

    lastExecutedFilters?: IFilters;

    results?: IDocumentResults;
    personResults?: IPersonResults;

    // активно ли расширение запроса
    isQueryExpansion: boolean;
    queryTags?: IQueryTags;
    // массив lemmaId выбранных расширений
    selectedQueryTags?: string[];

    filterVisibility?: Record<string, IFilterVisibility>;

    hints?: ISearchHint;
    initialFilterStats?: IFilterStats;

    concepts?: ISearchConcepts;

    sorting?: ISorting;
}

export interface IFilterVisibility {
    code: string;
    visible: boolean;
}

export interface IActiveHint {
    label: string;
    position: IQueryPosition;
}

export interface IEducationMaterials extends ISearch {
    statusFilter: EducationMaterialStatusValue;
    numberOfMaterials: INumberOfEducationMaterials;
}

export interface INumberOfEducationMaterials {
    numberOfAll?: number;
    numberOfRecommended?: number;
    numberOfMandatoryRecommended?: number;
    numberOfPlanning?: number;
    numberOfActive?: number;
    numberOfDone?: number;
}

export const enum EducationMaterialStatusValue {
    All = 'All',
    Recommended = 'Recommended',
    Planning = 'Planning',
    Active = 'InProgress',
    Done = 'Done',
}

export interface IRecommendationsWithPagingAndFilters {
    paging: IPaging;
    list: IRecommendations;
    filters: IFilters;
    sorting: {
        field: string;
        order: SortingOrder;
    };
}

export interface IRecommendations {
    totalCount: number;
    recommendations: IUserRecommendationDetail[];
}

export interface IUserRecommendationDetail extends IUserRecommendation {
    profile: IUser;
    recommendation: IRecommendationDetail;

    documentStatus?: DocumentEducationStatus;
    learningStatusChanged?: DateTimeString;
}

export interface IValueStats {
    [valueCode: string]: number;
}

export interface IFilterStats {
    [filterName: string]: IValueStats;
}

export interface IAggregationBucket {
    key: string;
    docCount: number;
    buckets: IAggregationBucket[] | IAggregation[];
}

export const GRAPH_STATS_AGGREGATION_NAME = 'graph-stats';
export const YEAR_STATS_AGGREGATION_NAME = 'year';

export interface IAggregation {
    name: string;
    buckets: IAggregationBucket[] | IAggregation[];
}

export interface IResults<T = any> {
    list?: T[];
    count?: number;
}

export interface IExtractedTerm {
    definition: string;
    id: string;
    storageSource: string;
    title: string;
    url: string;
}

export interface IExtractedService {
    id: string;
    title: string;
    content: string;
    url: string;
    organizations: {
        id: string;
        title: string;
    }[];
}

export interface ICorrectedQuery {
    text: string;
    offset: number;
    length: number;
    replacement: {
        value: string;
        highlighted: string;
        score: number;
    };
}

export interface IDocumentResults extends IResults<IDocumentSnippet> {
    modified?: number;
    categories?: any[];
    filterStats?: IFilterStats;
    aggregations?: IAggregation[];
    correctedQuery?: ICorrectedQuery;
    queryGraph?: {};
    extractedTerms?: {
        items: IExtractedTerm[];
        total: number;
    };
    extractedServices?: {
        items: IExtractedService[];
        total: number;
    };
    extractedConcepts?: IConceptWithRelations[];
    askFeedback?: boolean;
}

export interface IPersonResults {
    totalCount: number;
    items: IPerson[];
    filterStats?: IFilterStats;
    correctedQuery?: ICorrectedQuery;
    aggregations?: IAggregation[];
    askFeedback?: boolean;
    isConfirmShownBigAvatar: boolean;
    bigAvatar?: string;
    userId?: string;
    fullTitle?: string;
    resolution?: string;
}

export interface IPersonAvatar {
    userId?: string;
    avatar?: string;
    fullTitle?: string;
    resolution?: string;
}

export interface IDocumentSnippet {
    id?: string;
    meta?: IDocumentDescription;
    keywords?: IKeyword[];
    categories?: ICategory[];
    tags?: ICategory[];
    favorite?: boolean;
    subscribed?: boolean;
    allowSubscription?: boolean;
    profiles?: IProfileLink[];
    readLater?: boolean;
    modified?: number;
    optionalAttrs?: IDocumentAttr[];
    links?: IDocumentLinks;
    previewLinks?: IPreviewLink[];
    highlights?: IHighlights;
    grouped?: IGroupedSnippets;
    categoryConfirmed?: boolean;
    similarity?: number;
}

export interface IBreadCrumbs {
    domain: string;
    segments?: string[];
    url: string;
    extension?: string;
}

export interface IGroupedSnippets {
    totalCount: number;
    items: { item: IDocumentSnippet; similarity?: number }[];
}

export interface IHighlights {
    content: string[];
    title: string[];
    persons: IInnerHit[];
    organizations: IInnerHit[];
    optionalAttrs: { [key: string]: string[] };
    file?: { filename: string[] };
}

export interface IPreviewLink {
    size: PreviewLinkSize;
    link: string;
}

export const enum PreviewLinkSize {
    default = 'DEFAULT',
    small = 'SMALL',
    medium = 'MEDIUM',
    big = 'BIG',
}

export interface IDocumentLinks {
    totalCount: number;
    items: { item: IDocumentLink; similarity?: number }[];
}

export interface IDocumentLink {
    document: IDocumentSnippet;
    linkType: string;
    reversed: boolean;
}

export interface IProfileLink {
    personId: string;
    login: string;
    loginSource: ISourceToLogin[];
}

export interface ISourceToLogin {
    login: string;
    source?: string;
}

export interface IUpdateDocumentSnippet {
    id: string;
    keywords?: IKeyword[];
    categories?: ICategory[];
    favorite?: boolean;
    subscribed?: boolean;
    readLater?: boolean;
    meta?: IDocumentDescription;
}

export interface ICardRelatedConceptTree {
    topConceptList: string[];
    concepts: Record<string, IConceptWithChildren>;
}

export interface IObjectCard extends IDocumentSnippet {
    annotation?: IAnnotation[];
    similarityCount?: number;
    modifed?: number;
    linksPaging?: IPaging;
    groupedPaging?: IPaging;
    linksSorting?: IObjectCardTableSorting;
    groupedSorting?: IObjectCardTableSorting;
    concepts?: ISchemeConcept[];
    // иерархия для каждого концепта concepts. это не полное дерево концептов, а просто narrower концепты и
    // broader до верхнего концепта в схеме
    conceptsHierarchyTrees?: Record<string, ICardRelatedConceptTree>;
}

export interface IObjectCardTableSorting {
    [field: string]: SortingOrder;
}

export interface IDocumentAttr {
    code: string;
    value: string | boolean | IDocumentAttrDateValue;
    title?: string;
}

export interface IDocumentAttrDateValue {
    $date: number;
}

export interface IDocumentDescription {
    link?: string | { url: string; title: string };
    objectType?: string;
    displayObjectType?: string;
    storageSource?: string;
    displayStorageSource?: string;
    // subType - один из типов, который приходит по запросу educationMaterialTypes
    subType?: string;
    title?: string;
    year?: number;
    day?: number;
    month?: number;
    orgs?: IOrg[];
    originalId?: string;
    persons?: IDocumentPerson[];
    isTutorial?: boolean;
    isEditable?: boolean;
    status?: DocumentEducationStatus;
    recommendationsInfo?: IUserRecommendationDetail[];
    competenceRequirements?: ICompetence[];
    activeStatus?: IActivityStatus;
    file?: IDocumentFileMeta;
    path?: IDocumentFilePath;
    breadCrumbs?: IBreadCrumbs;
    id?: string;
    storageLink?: string; // относительный путь до эндпоинта nkc-root, отдающего файл (временный костыль для Линде)
}

export interface IDocumentFileMeta {
    contentType?: string;
    created?: string;
    lastModified?: string;
    lastAccessed?: string;
    indexingDate?: string;
    filesize?: number;
    indexedChars?: number;
    filename?: string;
    extension?: string;
    checksum?: string;
}

export interface IDocumentFilePath {
    relative?: string;
    real?: string;
}

export interface INkcDocumentInfo {
    id: string;
    title: string;
    storageSource: string;
    objectType: INkcObjectType;
    persons: ISimplePerson[];
    orgs: IOrg[];
    optionalAttrs: { [attrName: string]: string };

    originalId?: string;
    summary?: string;
    year?: number;
    link?: string;
    creationDate?: number;
    modifyDate?: number;
}

export interface INkcObjectType {
    value: string;

    subType?: {};
}

export type DocumentEducationStatus =
    | EducationMaterialStatusValue.Planning
    | EducationMaterialStatusValue.Active
    | EducationMaterialStatusValue.Done;

export interface IRecommendationDetail {
    id: string;
    authorId: string;
    author: IUser;
    documentId: string;
    document: INkcDocumentInfo;
    deleted: boolean;
    created: DateTimeString;
    modified: DateTimeString;
    lastModifier: DateTimeString;
}

export interface IUserRecommendation {
    profileId: string;
    recommendationId: string;
    refused: boolean;
    planDate: DateTimeString;
    statusChanged: DateTimeString;
    accepted: boolean;
    necessity: boolean;

    recommendedForPost?: string;
    recommendedForSubdivision?: string;
}

export interface IOrg {
    id: string;
    title: string;
}

export interface IDocumentPerson {
    fullName: string;
    id: string;
    link?: string;
}

export interface ISimplePerson {
    id: string;
    title: string;
}

export interface IAnnotation {
    sentence: string;
    similarity: number;
    ordering: number;
}

export interface ICategory {
    id: string;
    name: string;
    score: number;
    parentCategory?: ICategory;
}

export interface IKeyword {
    keyword: string;
}

export interface ICategorizationRule {
    id: string;
    type: 'PatternRule' | 'SearchFiltersRule';
    field?: string;
    pattern?: string;
    searchId?: string;
}

export interface ISearchHint {
    queries: IQueryHint[];
    persons: IPerson[];
}

export interface IQueryHint {
    query: string;
    queryValue?: string;
    highlighted?: string; // для улучшения подсветки, когда было неочевидное преобразование (пр: уишевф -> EBITDA)
    count?: number;
    source?: any;
    position: IQueryPosition;
    filter?: {
        code: string;
        type: 'BaseFilter';
        value: {
            DisplayValue: {
                code: string;
                displayValue: string;
            };
        };
    };
    person?: IPersonHint;
    doc?: IDocumentDescription;
}
export interface IPersonHint {
    companyName?: string;
    department?: [];
    email?: string;
    positionName?: string;
    telInternal?: string;
    usrSip?: string;
    userId?: string;
    avatar?: string;
}

export interface IQueryPosition {
    start: number; // с какого символа
    length: number; // сколько символов вырезать
}

export interface IQueryKeyword {
    lemmaId: string;
    similarity: number;
    raw: string;

    tooltip?: string;
}

export interface IQueryTags {
    queryString: string;
    tags: IQueryNode | IQueryTerm;
}

export interface IQueryTerm {
    term: string;
    expansion: IQueryKeyword[];
}

export interface IQueryNode {
    op: 'OR' | 'AND';
    nodes: (IQueryNode | IQueryTerm)[];
}

export interface IAuthorization {
    isAuthorized: boolean;
    authorizationFailed: boolean;
    error?: string;
    loginUrl: string;
}

export interface IUser {
    id: string;
    userId?: string;
    lastName?: string;
    firstName?: string;
    middleName?: string;
    birthDate?: string;
    // логин AD в gpn
    login?: string;
    email: string[];
    phone?: string[];
    created: string;
    modified: string;
    state: number;
    userLogins?: ISourceToLogin[];
    avatar?: IPersonAvatar;
}

export interface ISystemUser extends IUser {
    permissions: EnabledUserRoles;
    littleUserMenuAvatar?: IPersonAvatar;
    dropdownUserMenuAvatar?: IPersonAvatar;
}

export type EnabledUserRoles = { [roleName in PermissionRole]?: boolean };

export const enum PermissionRole {
    AccessAdmin = 'AccessAdmin',
    AccessUser = 'AccessUser',
    AccessManager = 'AccessManager',
    AccessOperator = 'AccessOperator',
}

export interface INkcManager {
    userId: number;
    userLogin?: string;
    fullTitle?: string;
    email?: string;
    telOffice?: string;
    telMobile?: string;
    telInternal?: string;
    positionName?: string;
    usrSip?: string;
}

export interface INkcDepartment {
    deptId: number;
    deptCode?: string;
    name?: string;
    namePath?: string[];
    manager?: INkcManager;
    deptParentid?: number;
    sapCode?: string;
    companyType?: string;
    email?: string;
    sortOrder?: string;
    changeTime?: string;
}

export interface INkcEmployee {
    avatarUrl?: string;
    userId: number;
    persNum?: string;
    userLogin?: string;
    fullTitle?: string;
    positionName?: string;
    telOffice?: string;
    telInternal?: string;
    telMobile?: string;
    workplaceNum?: string;
    userAddress?: string;
    email?: string;
    manager?: INkcManager;
    department?: INkcDepartment;
    departmentPath?: number[];
    companyName?: string;
    birthDate?: string;
    hireDate?: string;
    fireDate?: string;
    positionchangeDate?: string;
    changeTime?: string;
    city?: string;
    region?: string;
    usrLevel?: number;
    usrSid?: string;
    usrSip?: string;
    globalId?: number;
    lock?: boolean;
    outstaff?: string;
    companyFact?: string;
    function?: string;
    avatar?: IPersonAvatar;
}

export interface IEmployeeInfo {
    id: string;
    personId: string;
    address: ISimpleCatalogItem;
    room: string;
    phone: string[];
    post?: ISimpleCatalogItem;
    subdivision?: ISimpleCatalogItem;
    workFunction?: string; // TODO ISimpleCatalogItem
    targetPost?: ISimpleCatalogItem;
    organization?: IOrganization;
}

export interface IPerson {
    id: string;
    content?: INkcEmployee;
}

export interface ISimpleCatalogItem {
    id: string;
    title: string;
    archived: boolean;
    code: string;
    created: DateTimeString;
    modified: DateTimeString;
    catalogId: string;
    parentId?: string;
    catalog?: string;
    description?: string;
}

export interface IHierarchicalCatalogItem {
    item: ISimpleCatalogItem;
    subItems: IHierarchicalCatalogItem[];
}

export interface IOrganization {
    id: string;
    title: string;
    headDivisionId?: string;
    created?: string;
    modified?: string;
}

export interface ICompetence {
    competenceItem: ISimpleCatalogItem;
    grade?: number;
}

export interface IUserCompetence extends ICompetence {
    requiredGrade?: number;
    targetGrade?: number;
}

export interface IKnowledgeCategory {
    item: ISimpleCatalogItem;
    docsCount: number;
}

export interface IUserSkill {
    skill: ISimpleCatalogItem;
    skilledPersonsAmount: number;
    skilledOfAllUsers: number;
}

export const enum ActivityStatusCode {
    Active = 'ACTIVE',
    Canceled = 'CANCELLED',
    Planning = 'PLANNING',
}

export interface IActivityStatus {
    code: ActivityStatusCode;
    title: string;
    ordering?: number;
}

export interface IForms {
    [formName: string]: {
        data: {
            [fieldName: string]: any;
        };
        status: FormStatus;
        response?: any;
    };
}

export interface INavMenuDropdownVisibility {
    isOpen: boolean;
}

export const enum FormStatus {
    Editing = 'EDITING',
    Invalid = 'INVALID',
    SendingFail = 'SENDING_FAIL',
    SendingSuccess = 'SENDING_SUCCESS',
    Pending = 'PENDING',
}

export interface IMarkDocument {
    documentId: string;
    mark: boolean;
}

export interface IOrganizationsInfo {
    organizations: IOrganization[];
    addresses: ISimpleCatalogItem[];
    posts: ISimpleCatalogItem[];
    subdivisions: IHierarchicalCatalogItem[];
}

export interface INormalizedOrgStructure {
    subDivisions: INormalizedHierarchicalSubDivisions;
    employees: IEmployeesMap;
    subDivisionsToPosts: ISubDivisionsToPostsMap;
    subDivisionsToEmployees: ISubDivisionsToEmployeesMap;
    personToEmployees: IPersonToEmployeeMap;
}

export interface INormalizedHierarchicalSubDivisions {
    topSubDivisions: string[];
    items: INormalizedSubDivisionsMap;
}

export interface INormalizedSubDivisionsMap {
    [subDivisionId: string]: INormalizedHierarchicalSubDivisionItem;
}

export interface INormalizedHierarchicalSubDivisionItem {
    item: ISimpleCatalogItem;
    subItems: string[];
    parent?: string;
}

export interface IEmployeesMap {
    [employeeid: string]: INamedEmployee;
}

export interface ISubDivisionsToPostsMap {
    [subDivisionId: string]: string[];
}

export interface ISubDivisionsToEmployeesMap {
    [subDivisionId: string]: string[];
}

export interface IPersonToEmployeeMap {
    [personId: string]: string[];
}

export interface INormalizedHierarchicalCategories {
    topCategories: string[];
    categories: INormalizedHierarchicalCategoriesMap;
}

export interface INormalizedHierarchicalCategoriesMap {
    [id: string]: INormalizedHierarchicalCatalogItem;
}

export interface ICatalogueCategoryItem {
    id: string;
    title: string;
    keywords?: string[];
    description?: string;
    rules?: ICategorizationRule[];
}

export interface INormalizedHierarchicalCatalogItem {
    item: ICatalogueCategoryItem;
    subItems: string[];
    countCat: number;
    countWithSubCat: number;
    countUnverified?: number;
    parent?: string;
}

export interface INamedEmployee {
    id: string;
    personId: string;
    subdivisionId: string;
    postId: string;
    phone: string[];
    created: DateTimeString;
    modified: DateTimeString;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    targetPostId?: string;
    address?: string;
}

export interface INotificationResults {
    // уведомления, загружаемые на странице уведомлений
    notifications?: IUserNotification[];
    // последние несколько уведомлений, загружаемые для попапа
    latestNotifications?: IUserNotification[];
    // количество непрочитанных уведомлений
    newCount: number;
    // всего уведомлений
    total?: number;
    // ID setInterval'а для остановки при не активной страницы
    notificationsIntervalId: number;
}

export interface IGuideResults {
    stats: IGuide[];
    version: string;
    view: boolean;
    steps: Map<string, ReactourStep[]>;
    showTour: boolean;
    creationDate?: string;
}

export interface IUserNotificationList {
    notifications: IUserNotification[];
    total: number;
}

export interface IUserNotification {
    id: string;
    notificationCodeTitle: string;
    theme?: string;
    content: string;
    isRead?: boolean;
    created: string;
    messageCatalogTitle: string;
}

export interface ISorting {
    key?: string;
    order?: SortingOrder;
}

export type SortingOrder = 'asc' | 'desc';

export interface IInnerHit {
    highlight: string[];
    source: INkcInnerDocument;
}

export interface INkcInnerDocument {
    id?: string;
    title?: string;
}

export interface IRedirectInfo {
    link: string;
    checkLinks: string[];
}

export interface ISKOSLabel extends ISKOSValue {
    lang?: string;
    dataType?: string;
}

export interface ISKOSValue {
    value: string;
}

export interface IConceptDescription {
    prefLabels: ISKOSLabel[];
    altLabels: ISKOSLabel[];
    definition: ISKOSValue[];
    related: IConcept[];
}

export enum SKOSLabelClasses {
    AltLabel = 'http://www.w3.org/2004/02/skos/core#altLabel',
    PrefLabel = 'http://www.w3.org/2004/02/skos/core#prefLabel',
    HiddenLabel = 'http://www.w3.org/2004/02/skos/core#hiddenLabel',
}

export enum SKOSNoteClasses {
    Definition = 'http://www.w3.org/2004/02/skos/core#definition',
}

export enum SKOSRelationshipClasses {
    TopConceptOf = 'http://www.w3.org/2004/02/skos/core#topConceptOf',
    HasTopConcept = 'http://www.w3.org/2004/02/skos/core#hasTopConcept',
    InScheme = 'http://www.w3.org/2004/02/skos/core#inScheme',
    Narrower = 'http://www.w3.org/2004/02/skos/core#narrower',
    NarrowerTransitive = 'http://www.w3.org/2004/02/skos/core#narrowerTransitive',
    Broader = 'http://www.w3.org/2004/02/skos/core#broader',
    BroaderTransitive = 'http://www.w3.org/2004/02/skos/core#broaderTransitive',
    Related = 'http://www.w3.org/2004/02/skos/core#related',
}

export interface IDoodle {
    mainPageImg?: string;
    resultPageImg?: string;
    link?: string;
    description?: string;
    newBlank?: boolean;
}
