/**
 * Created by Lkarmelo on 05.02.2018.
 */

import { MiddlewareAPI } from 'redux';
import { Observable } from 'rxjs/Observable';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { AjaxResponse } from 'rxjs/observable/dom/AjaxObservable';
import { concat } from 'rxjs/observable/concat';
import { merge } from 'rxjs/observable/merge';

import { retryOnAuthorizedEpic } from './utils';

import * as Store from '../store/StoreNamespace';
import {
    FETCH_ORGANIZATIONS_INFO,
    setOrganizationsInfo,
    fetchOrganizationsInfo,
} from '../actions/organizationsInfo';
import {
    organizationsInfoRequest,
    organizationsInfoReject,
    organizationsInfoResolve,
} from '../actions/loading';

export const loadOrganizationsInfo = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }
) =>
    merge(
        retryOnAuthorizedEpic(
            action$,
            organizationsInfoReject.toString(),
            fetchOrganizationsInfo
        ),
        action$
            .ofType(FETCH_ORGANIZATIONS_INFO)
            .filter(() => {
                const { organizationsInfo } = store.getState();

                return (
                    !organizationsInfo ||
                    !organizationsInfo.organizations ||
                    !organizationsInfo.addresses ||
                    !organizationsInfo.posts ||
                    !organizationsInfo.subdivisions
                );
            })
            .mergeMap(() =>
                combineLatest(
                    apiCall
                        .organizations()
                        .map((r: AjaxResponse) => ({
                            organizations: r.response,
                        }))
                        .catch((e) => {
                            console.error(e);
                            return Observable.of(e);
                        }),
                    apiCall
                        .addresses()
                        .map((r: AjaxResponse) => ({ addresses: r.response }))
                        .catch((e) => {
                            console.error(e);
                            return Observable.of(e);
                        }),
                    apiCall
                        .posts()
                        .map((r: AjaxResponse) => ({ posts: r.response }))
                        .catch((e) => {
                            console.error(e);
                            return Observable.of(e);
                        }),
                    apiCall
                        .subdivisions()
                        .map((r: AjaxResponse) => ({
                            subdivisions: r.response,
                        }))
                        .catch((e) => {
                            console.error(e);
                            return Observable.of(e);
                        })
                )
                    .mergeMap((responses: {}[]) => {
                        let data = {};
                        let hasErrors = false;
                        const errors: Error[] = [];

                        responses.forEach((res) => {
                            if (res instanceof Error) {
                                hasErrors = true;
                                errors.push(res);
                            } else {
                                data = { ...data, ...res };
                            }
                        });

                        if (hasErrors) {
                            return concat(
                                Observable.of(
                                    setOrganizationsInfo(
                                        data as Store.IOrganizationsInfo
                                    )
                                ),
                                ...errors.map((e) =>
                                    Observable.of(organizationsInfoReject(e))
                                )
                            );
                        }
                        return concat(
                            Observable.of(
                                setOrganizationsInfo(
                                    data as Store.IOrganizationsInfo
                                )
                            ),
                            Observable.of(organizationsInfoResolve())
                        );
                    })
                    .catch((e) => {
                        console.error(e);
                        return Observable.of(organizationsInfoReject(e));
                    })
                    .startWith(organizationsInfoRequest())
            )
    );
