/**
 * Created by Lkarmelo on 04.12.2017.
 */

import React, { PureComponent } from 'react';
import autoBind from 'autobind-decorator';
import classNames from 'classnames';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import * as Store from 'app/redux/store/StoreNamespace';

import IProps from './interfaces/ISkillsProps';
import IState from './interfaces/ISkillsState';

import './Skills.scss';

@autoBind
export default class Skills extends PureComponent<IProps, IState> {
    state: IState = {
        sort: 1,
        skillNamesTruncated: true,
        skillNamesTruncateButtonShow: false,
        skillListTruncated: true,
    };

    skillNamesRef: React.RefObject<HTMLDivElement> = React.createRef();

    maxSkillListLength = 5;

    resizeSubscription: Subscription = null;

    componentDidMount(): void {
        this.resizeSubscription = Observable.fromEvent(window, 'resize')
            .throttleTime(150)
            .subscribe(this.showHideTruncateSkillNamesIfOverflow);
        this.showHideTruncateSkillNamesIfOverflow();
    }

    componentDidUpdate(prevProps: IProps): void {
        if (prevProps.skills !== this.props.skills) {
            this.showHideTruncateSkillNamesIfOverflow();
        }
    }

    componentWillUnmount(): void {
        this.resizeSubscription.unsubscribe();
    }

    showHideTruncateSkillNamesIfOverflow(): void {
        const cls = 'skills__skill-names--all';
        const namesWereShown =
            this.skillNamesRef.current.classList.contains(cls);

        this.skillNamesRef.current.classList.remove(cls);

        if (
            this.skillNamesRef.current.scrollHeight -
                this.skillNamesRef.current.clientHeight >
            2
        ) {
            !this.state.skillNamesTruncateButtonShow &&
                this.setState({ skillNamesTruncateButtonShow: true });
        } else {
            this.state.skillNamesTruncateButtonShow &&
                this.setState({ skillNamesTruncateButtonShow: false });
        }

        if (namesWereShown) {
            this.skillNamesRef.current.classList.add(cls);
        }
    }

    sortSkillsByTitles(
        skillA: Store.IUserSkill,
        skillB: Store.IUserSkill
    ): number {
        if (skillA.skill.title > skillB.skill.title) {
            return 1;
        }
        if (skillA.skill.title < skillB.skill.title) {
            return -1;
        }
        return 0;
    }

    showSkillNames(): void {
        this.setState({ skillNamesTruncated: false }, this.onTruncateChange);
    }

    truncateSkillNames(): void {
        this.setState({ skillNamesTruncated: true }, this.onTruncateChange);
    }

    toggleListOrdering(): void {
        this.setState((state) => ({ sort: state.sort * -1 }));
    }

    sortSkillsByPersonAmount(
        skillA: Store.IUserSkill,
        skillB: Store.IUserSkill
    ): number {
        const { sort } = this.state;
        if (skillA.skilledPersonsAmount > skillB.skilledPersonsAmount) {
            return sort * -1;
        }
        if (skillA.skilledPersonsAmount < skillB.skilledPersonsAmount) {
            return sort;
        }
        return 0;
    }

    showSkillList(): void {
        this.setState({ skillListTruncated: false }, this.onTruncateChange);
    }

    truncateSkillList(): void {
        this.setState({ skillListTruncated: true }, this.onTruncateChange);
    }

    onTruncateChange() {
        this.props.onTruncateChange &&
            this.props.onTruncateChange(
                this.state.skillNamesTruncated && this.state.skillListTruncated
            );
    }

    render(): JSX.Element {
        const { skills } = this.props;
        const {
            skillNamesTruncated,
            skillListTruncated,
            sort,
            skillNamesTruncateButtonShow,
        } = this.state;
        return (
            <div className="skills">
                <div
                    className={classNames('skills__skill-names', {
                        'skills__skill-names--all': !skillNamesTruncated,
                    })}
                    ref={this.skillNamesRef}
                >
                    {skills
                        .slice()
                        .sort(this.sortSkillsByTitles)
                        .map(({ skill }) => (
                            <div key={skill.id} className="skills__skill-name">
                                {`#${skill.title}`}
                            </div>
                        ))}
                </div>
                {skillNamesTruncateButtonShow && skillNamesTruncated && (
                    <button
                        className="skills__truncate-names btn"
                        onClick={this.showSkillNames}
                    >
                        Показать всё
                    </button>
                )}
                {skillNamesTruncateButtonShow && !skillNamesTruncated && (
                    <button
                        className="skills__truncate-names btn"
                        onClick={this.truncateSkillNames}
                    >
                        Показать часть
                    </button>
                )}
                <div className="skills__frequency">
                    <div className="skills__frequency-title">
                        Частота распространения навыков среди сотрудников
                    </div>
                    <div className="skills__list-header clearfix">
                        <div>Навык</div>
                        <button
                            className={classNames(
                                'btn',
                                'skills__list-sorting',
                                {
                                    'skills__list-sorting--asc': sort === -1,
                                }
                            )}
                            onClick={this.toggleListOrdering}
                        >
                            Кол-во сотрудников, обладающих навыком
                        </button>
                    </div>
                    <div className="skills__skill-list">
                        {skills
                            .slice()
                            .sort(this.sortSkillsByPersonAmount)
                            .slice(
                                0,
                                skillListTruncated
                                    ? this.maxSkillListLength
                                    : skills.length
                            )
                            .map((skill) => (
                                <div
                                    key={skill.skill.id}
                                    className="skills__list-item"
                                >
                                    <div className="skills__skill-name">{`#${skill.skill.title}`}</div>
                                    <div className="clearfix">
                                        <div className="skills__bar-container">
                                            <div
                                                className="skills__bar"
                                                style={{
                                                    width: `${
                                                        skill.skilledOfAllUsers *
                                                        100
                                                    }%`,
                                                }}
                                            />
                                        </div>
                                        <div className="skills__skilled-amount">
                                            {skill.skilledPersonsAmount}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    {skills.length > this.maxSkillListLength &&
                        skillListTruncated && (
                            <button
                                className="btn skills__list-truncate"
                                onClick={this.showSkillList}
                            >
                                Показать всё
                            </button>
                        )}
                    {skills.length > this.maxSkillListLength &&
                        !skillListTruncated && (
                            <button
                                className="btn skills__list-truncate"
                                onClick={this.truncateSkillList}
                            >
                                Показать часть
                            </button>
                        )}
                </div>
            </div>
        );
    }
}
