/**
 * Created by lkarmelo on 29.08.2018.
 */

import { handleActions, Action } from 'redux-actions';

import { setSorting, resetSorting } from 'app/redux/actions/sorting';
import * as Store from 'app/redux/store/StoreNamespace';

export default handleActions(
    {
        [setSorting.toString()](
            state: Store.ISorting,
            { payload }: Action<Store.ISorting>
        ) {
            return payload;
        },
        [resetSorting.toString()]() {
            return {};
        },
    },
    {}
);
