/**
 * Created by lkarmelo on 10.08.2018.
 */

export enum ApiFeaturesName {
    UserCompetencies = 'userCompetencies',
    UserSkills = 'userSkills',
    UserKnowledgeCategories = 'userKnowledgeCategories',

    Notifications = 'notifications',
}
