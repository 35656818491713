/**
 * Created by Lkarmelo on 08.11.2017.
 */

import Login from './Login';

export { default as ResetPassword } from './ResetPassword/ResetPassword';
export { default as RecoveryPassword } from './RecoveryPassword/RecoveryPassword';

export default Login;
