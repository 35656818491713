/*
    Взято из https://github.com/SparebankenVest/react-css-collapse в версии 3.1.0
    Заменён scrollHeight на offsetHeight при закрытии из-за бага с дёргающейся высотой, когда элемент с
    position absolute по высоте выходит за пределы контента.
    Добавлен дефолтный css класс
*/

import React, { Component, ReactNode } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const initialStyle = {
    willChange: 'height',
    height: '0px',
    overflow: 'hidden',
};

class Collapse extends Component<{
    isOpen: boolean;
    className?: string;
    onRest?: () => void;
    children: ReactNode;
}> {
    contentRef: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props) {
        super(props);
        this.onTransitionEnd = this.onTransitionEnd.bind(this);
        this.setExpanded = this.setExpanded.bind(this);
        this.setCollapsed = this.setCollapsed.bind(this);
    }

    componentDidMount() {
        if (this.contentRef.current && this.props.isOpen) {
            this.setExpanded();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!this.contentRef.current) {
            return;
        }

        // expand
        if (!this.props.isOpen && nextProps.isOpen) {
            // have the element transition to the height of its inner content
            this.setContentStyleProperty(
                'height',
                `${this.contentRef.current.scrollHeight}px`
            );
        }

        // collapse
        if (this.props.isOpen && !nextProps.isOpen) {
            // explicitly set the element's height to its current pixel height, so we
            // aren't transitioning out of 'auto'
            this.setContentStyleProperty(
                'height',
                `${this.contentRef.current.offsetHeight}px`
            );
            window.requestAnimationFrame(() => {
                // "pausing" the JavaScript execution to let the rendering threads catch up
                // http://stackoverflow.com/questions/779379/why-is-settimeoutfn-0-sometimes-useful
                setTimeout(() => {
                    this.setCollapsed();
                }, 0);
            });
        }
    }

    onTransitionEnd(e) {
        const { onRest, isOpen } = this.props;

        if (isOpen) {
            this.setExpanded();
        }

        if (
            onRest &&
            e.target === this.contentRef.current &&
            e.propertyName === 'height'
        ) {
            onRest();
        }
    }

    setContentStyleProperty(property, value) {
        this.contentRef.current.style[property] = value;
    }

    setCollapsed() {
        this.setContentStyleProperty('height', '0px');
        this.setContentStyleProperty('overflow', 'hidden');
    }

    setExpanded() {
        this.setContentStyleProperty('height', 'auto');
        this.setContentStyleProperty('overflow', 'visible');
    }

    render() {
        return (
            <div
                ref={this.contentRef}
                style={initialStyle}
                className={classNames(
                    'react-css-collapse',
                    this.props.className
                )}
                onTransitionEnd={this.onTransitionEnd}
            >
                {this.props.children && this.props.children}
            </div>
        );
    }
}

// @ts-ignore
Collapse.defaultProps = {
    isOpen: false,
    children: null,
    onRest: null,
};

// @ts-ignore
Collapse.propTypes = {
    children: PropTypes.node,
    isOpen: PropTypes.bool,
    className: PropTypes.string,
    onRest: PropTypes.func,
};

export default Collapse;
