import { createAction } from 'redux-actions';

import { ModalName } from 'app/redux/store/StoreNamespace';

export interface ISetModalVisiblePayload {
    modalName: ModalName;
    visible: boolean;
}

export const setModalVisible = createAction<
    ISetModalVisiblePayload,
    ModalName,
    boolean
>('SET_MODAL_VISIBLE', (modalName: ModalName, visible: boolean) => ({
    modalName,
    visible,
}));
