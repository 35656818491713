/**
 * Created by Lkarmelo on 27.10.2017.
 */

import React, { PureComponent } from 'react';

import {
    FeatureRenderer,
    UIFeaturesName,
} from 'app/components/common/utils/FeatureRenderer';
import Footer from 'app/components/Footer';
import setBodyClassOnMount from 'app/components/high-order/setBodyClassOnMount';
import FixedHeader from 'app/components/search/FixedHeader';

import SiteTour from '../common/SiteTour/SiteTour';

@setBodyClassOnMount('search-layout')
export default class SearchLayout extends PureComponent {
    render(): JSX.Element {
        return (
            <div className="layout-wrapper">
                <FixedHeader />
                {this.props.children}
                <Footer />
            </div>
        );
    }
}
