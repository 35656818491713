/**
 * Created by Lkarmelo on 12.02.2018.
 */

import { handleActions } from 'redux-actions';

import * as Store from '../store/StoreNamespace';
import {
    IAddFileError,
    IUpdateFileMeta,
    IUpdateFileState,
    addFileError,
    updateFileState,
    updateFileInfo,
    IUpdateFileProgress,
    updateFileProgress,
} from '../actions/files';

const findByName = (
    name: string,
    files: { [key: string]: Store.IFileMeta }
): Store.IFileMeta =>
    Object.keys(files)
        .map((key) => files[key])
        .find(({ name: fileName }) => fileName === name);

function updateFileStore(
    store: Store.IFileStore,
    fileKey: string,
    updateAction: (file: Store.IFileMeta) => void
) {
    const files = { ...store.files };
    const orderFileKeys = [...store.keys];

    // try find by name or key
    let fileMeta: Store.IFileMeta = files.hasOwnProperty(fileKey)
        ? files[fileKey]
        : findByName(fileKey, files);

    if (fileMeta === undefined) {
        fileMeta = {
            name: fileKey,
            fid: fileKey,
            mimeType: '',
            size: 0,
            status: Store.FileStateEnum.TmpFile,
        };
        files[fileKey] = fileMeta;
        orderFileKeys.push(fileKey);
    }

    updateAction(fileMeta);

    return { files, keys: orderFileKeys };
}

export default handleActions<
    Store.IFileStore,
    IUpdateFileMeta | IUpdateFileState | IUpdateFileProgress | IAddFileError
>(
    {
        [updateFileState.toString()](
            state: Store.IFileStore,
            { payload }: { payload: IUpdateFileState; type: string }
        ) {
            const { fileKey, state: fileState } = payload;

            return updateFileStore(state, fileKey, (fileMeta) => {
                fileMeta.status = fileState;
            });
        },

        [addFileError.toString()](
            state: Store.IFileStore,
            { payload }: { payload: IAddFileError; type: string }
        ) {
            const { fileKey, error } = payload;

            return updateFileStore(state, fileKey, (fileMeta) => {
                fileMeta.error = error.errorMsg;
                fileMeta.file = error.file;
            });
        },

        [updateFileProgress.toString()](
            state: Store.IFileStore,
            { payload }: { payload: IUpdateFileProgress; type: string }
        ) {
            const { fileKey, progress } = payload;

            return updateFileStore(state, fileKey, (fileMeta) => {
                fileMeta.progress = progress;
            });
        },
        [updateFileInfo.toString()](
            state: Store.IFileStore,
            { payload }: { payload: IUpdateFileMeta; type: string }
        ) {
            const files = { ...state.files };
            const orderFileKeys = [...state.keys];

            Object.keys(payload).forEach((key) => {
                // update meta
                const fileMetaPayload = payload[key];
                if (files.hasOwnProperty(fileMetaPayload.name)) {
                    delete files[fileMetaPayload.name];
                }
                files[key] = fileMetaPayload;

                // update order
                const order = orderFileKeys.indexOf(key);
                const orderByName = orderFileKeys.indexOf(fileMetaPayload.name);
                if (order === -1 && orderByName !== -1) {
                    orderFileKeys[orderByName] = key;
                }

                if (order === -1 && orderByName === -1) {
                    orderFileKeys.push(key);
                }
            });

            return {
                files,
                keys: orderFileKeys.filter((key) => files.hasOwnProperty(key)),
            };
        },
    },
    {
        files: {},
        keys: [],
    } // - default value for skills
);
