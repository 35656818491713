/**
 * Created by Lkarmelo on 21.11.2017.
 */

import { handleActions } from 'redux-actions';

import { SET_ORGANIZATIONS_INFO } from '../actions/organizationsInfo';
import * as Store from '../store/StoreNamespace';

export default handleActions<Partial<Store.IOrganizationsInfo>, any>(
    {
        [SET_ORGANIZATIONS_INFO](
            state: Store.IOrganizationsInfo,
            { payload }: { payload: Store.IOrganizationsInfo; type: string }
        ) {
            return payload;
        },
    },
    {} // - default value for organization info
);
