/**
 * Created by Lkarmelo on 31.10.2017.
 */

import React, {
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import classNames from 'classnames';
import { usePrevious } from 'react-use';

import { IDropDownListRef } from 'app/components/common/controls/DropDownList/interfaces/IDropDownListRef';

import IProps from './interfaces/IDropDownListProps';

import { SlideTransition } from '../../utils/CSSTransitions';
import Option, { IOption } from '../Option';

const DropDownList = React.forwardRef<IDropDownListRef, IProps>(
    (props, ref) => {
        const {
            className,
            classPrefix,
            options,
            isOptionActive,
            onSelectOption,
            onDeselectOption,
            optionRenderer,
            isOpened,
            onOptionHighlighted,
            onOptionUnHighlighted,
            onDocumentMouseDown,
            subjectEnterPressed$,
            onClickPersonList,
        } = props;

        const prevOptions = usePrevious(options);

        const [highlightedIndex, setHighlightedIndex] = useState(-1);

        const optionListWrapperRef = useRef<HTMLDivElement>(null);
        const optionListRef = useRef<HTMLUListElement>(null);

        useEffect(() => {
            if (!onDocumentMouseDown) {
                return;
            }
            document.addEventListener('mousedown', onDocumentMouseDown);

            return () => {
                document.removeEventListener('mousedown', onDocumentMouseDown);
            };
        }, [onDocumentMouseDown]);

        const getClosestNonDisabledOptionIndex = useCallback(
            function _getClosestNonDisabledOptionIndex(
                index: number,
                leftToRight = true
            ) {
                let nonDisabledIndex = -1;

                if (options.length === 0) {
                    return nonDisabledIndex;
                }

                for (
                    let i = index;
                    leftToRight ? i < options.length : i >= 0;
                    leftToRight ? i++ : i--
                ) {
                    const opt = options[i];
                    if (opt && !opt.disabled) {
                        nonDisabledIndex = i;
                        break;
                    }
                }

                // если в одну сторону не нашли не disabled опцию, то пробуем по кругу обойти
                if (nonDisabledIndex === -1) {
                    nonDisabledIndex = _getClosestNonDisabledOptionIndex(
                        leftToRight ? 0 : options.length - 1,
                        leftToRight
                    );
                }

                return nonDisabledIndex;
            },
            [options]
        );

        const onListWrapperClick = useCallback(
            (e: React.MouseEvent<HTMLDivElement>) => {
                e.stopPropagation();
            },
            []
        );

        const highlightNextOption = useCallback(() => {
            const nextHighlightIndex = getClosestNonDisabledOptionIndex(
                highlightedIndex + 1
            );

            onOptionUnHighlighted?.(options[highlightedIndex]);
            onOptionHighlighted?.(options[nextHighlightIndex]);
            setHighlightedIndex(nextHighlightIndex);
        }, [
            getClosestNonDisabledOptionIndex,
            options,
            highlightedIndex,
            onOptionHighlighted,
            onOptionUnHighlighted,
        ]);
        const highlightPrevOption = useCallback(() => {
            const nextHighlightIndex = getClosestNonDisabledOptionIndex(
                highlightedIndex - 1,
                false
            );

            onOptionUnHighlighted?.(options[highlightedIndex]);
            onOptionHighlighted?.(options[nextHighlightIndex]);
            setHighlightedIndex(nextHighlightIndex);
        }, [
            getClosestNonDisabledOptionIndex,
            options,
            highlightedIndex,
            onOptionHighlighted,
            onOptionUnHighlighted,
        ]);
        const highlightOption = useCallback(
            (index: number) => {
                // index может быть -1, чтобы сбросить highlight
                let nextHighlightIndex = index;
                if (nextHighlightIndex >= options.length) {
                    nextHighlightIndex =
                        options.length > 0 ? options.length - 1 : -1;
                }
                onOptionUnHighlighted?.(options[highlightedIndex]);
                onOptionHighlighted?.(options[nextHighlightIndex]);
                setHighlightedIndex(nextHighlightIndex);
            },
            [
                highlightedIndex,
                options,
                onOptionUnHighlighted,
                onOptionHighlighted,
                setHighlightedIndex,
            ]
        );

        const toggleSelectIfHighlighted = useCallback(() => {
            const opt = options[highlightedIndex];
            if (!opt) {
                return false;
            }
            const isOptActive = isOptionActive(opt);

            if (isOptActive) {
                onDeselectOption(opt.value, opt.label, opt.meta);
            } else {
                onSelectOption(opt.value, opt.label, opt.meta);
            }

            return true;
        }, [
            highlightedIndex,
            options,
            isOptionActive,
            onSelectOption,
            onDeselectOption,
        ]);

        const onOptionMouseEnter = useCallback(
            (_, option: IOption) => {
                const optionIndex = option.meta.index;

                onOptionUnHighlighted?.(options[highlightedIndex]);
                onOptionHighlighted?.(option);
                setHighlightedIndex(optionIndex);
            },
            [
                highlightedIndex,
                onOptionHighlighted,
                onOptionUnHighlighted,
                setHighlightedIndex,
            ]
        );

        const onOptionMouseLeave = useCallback(
            (_, option: IOption) => {
                onOptionUnHighlighted?.(option);
            },
            [onOptionUnHighlighted]
        );

        const onDropDownWrapperMouseLeave = useCallback(() => {
            onOptionUnHighlighted?.(options[highlightedIndex]);
            setHighlightedIndex(-1);
        }, [highlightedIndex, options, onOptionUnHighlighted]);

        useImperativeHandle(ref, () => ({
            optionListRef,
            highlightNextOption,
            highlightPrevOption,
            highlightOption,
            toggleSelectIfHighlighted,
        }));

        if (options !== prevOptions && highlightedIndex !== -1) {
            highlightOption(-1);
        }

        return (
            <div className={className}>
                <SlideTransition in={isOpened && options.length > 0}>
                    <div
                        ref={optionListWrapperRef}
                        key={`${classPrefix}__dropdown-wrapper`}
                        onClick={onListWrapperClick}
                        className={`${classPrefix}__dropdown-wrapper`}
                        onMouseLeave={onDropDownWrapperMouseLeave}
                    >
                        <ul
                            ref={optionListRef}
                            className={`${classPrefix}__dropdown`}
                        >
                            {options.map((opt, index) => {
                                const isActive = index === highlightedIndex;
                                return (
                                    <Option
                                        baseClassName={`${classPrefix}__dropdown`}
                                        className={classNames({
                                            [`${classPrefix}__dropdown-item--highlighted`]:
                                                isActive,
                                        })}
                                        key={opt.key || opt.value}
                                        option={{
                                            ...opt,
                                            meta: { ...opt.meta, index },
                                        }}
                                        isActive={isActive}
                                        onSelect={onSelectOption}
                                        onDeselect={onDeselectOption}
                                        optionRenderer={optionRenderer}
                                        onMouseEnter={onOptionMouseEnter}
                                        onMouseLeave={onOptionMouseLeave}
                                        isDisabled={opt.disabled}
                                        subjectEnterPressed$={
                                            subjectEnterPressed$
                                        }
                                        onClickPersonList={onClickPersonList}
                                    />
                                );
                            })}
                        </ul>
                    </div>
                </SlideTransition>
            </div>
        );
    }
);

export default React.memo(DropDownList);
