/**
 * Created by Lkarmelo on 23.08.2017.
 */

import {
    Action,
    ActionFunction1 as ActionFunctionWithArguments,
    createAction,
} from 'redux-actions';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';

import {
    createLoadingActions,
    LoadingErrorInfo,
} from 'app/utils/createLoadingActions';
import Api from 'app/api/Api';
import { FormName } from 'app/api/FormName';
import { RequestName } from 'app/api/RequestName';
import { getErrorAction } from 'app/utils/historyUtils';

import {
    DEFAULT_LOADING_ERROR_MESSAGE,
    LOAD_AVATAR_NOT_FOUND_BTN,
    LOAD_AVATAR_NOT_FOUND_TEXT,
    LOAD_AVATAR_SERVER_ERROR_BTN,
    LOAD_AVATAR_SERVER_ERROR_TEXT,
    LOADING_ERROR_BTN_TITLE_DELETED_DOCUMENT,
    LOADING_ERROR_BTN_TITLE_SEARCH,
    LOADING_ERROR_MESSAGE_DELETED_DOCUMENT,
    LOADING_ERROR_MESSAGE_SEARCH,
} from '../../utils/constants';

export interface IShowLoadingErrorPayload {
    requestName: string;
    message: string;
    showInMainErrorComponent: boolean;
    btnTitle: string;
    closeByBtnClickOnly: boolean;
    onCloseAction: () => void;
}

export const SHOW_LOADING_ERROR = 'SHOW_LOADING_ERROR';
export const showLoadingError: ActionFunctionWithArguments<
    IShowLoadingErrorPayload,
    Action<IShowLoadingErrorPayload>
> = createAction<IShowLoadingErrorPayload, IShowLoadingErrorPayload>(
    SHOW_LOADING_ERROR,
    (payload: IShowLoadingErrorPayload) => {
        return {
            ...payload,
        };
    }
);

export interface IRemoveLoadingErrorPayload {
    requestNames: string[];
}
export const REMOVE_LOADING_ERROR = 'REMOVE_LOADING_ERROR';
export const removeLoadingError = createAction<
    IRemoveLoadingErrorPayload,
    string[]
>(REMOVE_LOADING_ERROR, (requestNames: string[]) => ({ requestNames }));

export const loadingShowActionCreators = [];
export const loadingHideActionCreators = [];
export const setErrorActionCreators = [];
export const logOutOnUnAuthActionCreators = [];
export const logInOnAuthActionCreators = [];

export interface ILoadingActionMeta {
    requestName: string;
    errorMessage?: string;
    showErrorInMainErrorComponent?: boolean;
    btnTitle?: string;
    closeByBtnClickOnly?: boolean;
    onCloseAction?: () => void;
}
export const {
    searchRequest,
    searchResolve,
    searchReject,

    sendFavoriteRequest,
    sendFavoriteResolve,
    sendFavoriteReject,

    sendSubscribeRequest,
    sendSubscribeResolve,
    sendSubscribeReject,

    sendReadLaterRequest,
    sendReadLaterResolve,
    sendReadLaterReject,

    favoriteRequest,
    favoriteResolve,
    favoriteReject,

    subscribeRequest,
    subscribeResolve,
    subscribeReject,

    readLaterRequest,
    readLaterResolve,
    readLaterReject,

    relatedRequest,
    relatedResolve,
    relatedReject,

    expandQueryRequest,
    expandQueryResolve,
    expandQueryReject,

    objectCardRequest,
    objectCardResolve,
    objectCardReject,

    filtersMetaRequest,
    filtersMetaResolve,
    filtersMetaReject,

    favoritesFiltersMetaRequest,
    favoritesFiltersMetaResolve,
    favoritesFiltersMetaReject,

    logInRequest,
    logInResolve,
    logInReject,

    userRequest,
    userResolve,
    userReject,

    currentUserRequest,
    currentUserResolve,
    currentUserReject,

    currentUserWorkInfoRequest,
    currentUserWorkInfoResolve,
    currentUserWorkInfoReject,

    currentUserCompetenciesRequest,
    currentUserCompetenciesResolve,
    currentUserCompetenciesReject,

    currentUserKnowledgeCategoriesRequest,
    currentUserKnowledgeCategoriesResolve,
    currentUserKnowledgeCategoriesReject,

    currentUserSkillsRequest,
    currentUserSkillsResolve,
    currentUserSkillsReject,

    formRequest,
    formResolve,
    formReject,

    competenciesRequest,
    competenciesResolve,
    competenciesReject,

    organizationsInfoRequest,
    organizationsInfoResolve,
    organizationsInfoReject,

    skillsRequest,
    skillsResolve,
    skillsReject,

    createSkillRequest,
    createSkillResolve,
    createSkillReject,

    catalogueCategoriesRequest,
    catalogueCategoriesResolve,
    catalogueCategoriesReject,

    catalogueDocumentsRequest,
    catalogueDocumentsResolve,
    catalogueDocumentsReject,

    catalogueCategoriesKeywordsRequest,
    catalogueCategoriesKeywordsResolve,
    catalogueCategoriesKeywordsReject,

    employeesRequest,
    employeesResolve,
    employeesReject,

    // notifications
    notificationRequest,
    notificationResolve,
    notificationReject,

    sendNotificationStatusRequest,
    sendNotificationStatusResolve,
    sendNotificationStatusReject,

    notificationCountRequest,
    notificationCountResolve,
    notificationCountReject,

    // education
    educationMaterialTypesRequest,
    educationMaterialTypesResolve,
    educationMaterialTypesReject,

    educationStatusChangeRequest,
    educationStatusChangeResolve,
    educationStatusChangeReject,

    educationStatusResetRequest,
    educationStatusResetResolve,
    educationStatusResetReject,

    recommendationsRequest,
    recommendationsResolve,
    recommendationsReject,

    userIdRequest,
    userIdResolve,
    userIdReject,

    initialFilterStatsRequest,
    initialFilterStatsResolve,
    initialFilterStatsReject,

    searchFromAliasRequest,
    searchFromAliasResolve,
    searchFromAliasReject,

    compressSearchRequest,
    compressSearchResolve,
    compressSearchReject,

    redirectInfoRequest,
    redirectInfoResolve,
    redirectInfoReject,

    catalogueRulesRequest,
    catalogueRulesResolve,
    catalogueRulesReject,

    confirmDocumentCategoryRequest,
    confirmDocumentCategoryResolve,
    confirmDocumentCategoryReject,

    revertDocumentCategoryRequest,
    revertDocumentCategoryResolve,
    revertDocumentCategoryReject,

    searchTabsRequest,
    searchTabsResolve,
    searchTabsReject,

    searchTabsSimpleRequest,
    searchTabsSimpleResolve,
    searchTabsSimpleReject,

    searchTabRequest,
    searchTabResolve,
    searchTabReject,

    loadBigAvatarRequest,
    loadBigAvatarResolve,
    loadBigAvatarReject,

    doodleRequest,
    doodleResolve,
    doodleReject,
} = createLoadingActions(
    [
        'search',
        {
            name: 'sendFavorite',
            errors: {
                400: 'Документ уже удалён из избранного',
                500: 'Документ уже добавлен в избранное',
            },
        },
        {
            name: 'sendSubscribe',
            errors: {
                400: 'Документ уже удалён из списка отслеживаемых',
                500: 'Документ уже добавлен в список отслеживаемых',
            },
        },
        {
            name: 'sendReadLater',
            errors: {
                400: 'Документ уже удалён из отложенных документов',
                500: 'Документ уже добавлен в отложенное',
            },
        },
        'favorite',
        'subscribe',
        'readLater',
        'related',
        RequestName.ExpandQuery,
        {
            name: 'objectCard',
            errors: {
                404: {
                    showErrorInMainErrorComponent: true,
                    errorMessage: LOADING_ERROR_MESSAGE_DELETED_DOCUMENT,
                    btnTitle: LOADING_ERROR_BTN_TITLE_DELETED_DOCUMENT,
                    onCloseAction: getErrorAction('objectCard'),
                    closeByBtnClickOnly: true,
                },
            },
        },
        'filtersMeta',
        'favoritesFiltersMeta',
        'logIn',
        {
            name: 'user',
            errors: {
                403: {
                    showErrorInMainErrorComponent: false,
                },
            },
        },
        'currentUser',
        'currentUserWorkInfo',
        'currentUserCompetencies',
        'currentUserKnowledgeCategories',
        'currentUserSkills',
        {
            name: 'form',
            metaCreatorsFactory: {
                request: (options) => (formName: string) => ({
                    requestName: formName,
                }),
                resolve: (options) => (formName: string) => ({
                    requestName: formName,
                }),
                reject: (options) => (er: AjaxError, formName: string) => {
                    if (er) {
                        const meta = {
                            requestName: formName,
                            showErrorInMainErrorComponent: true,
                            errorMessage: 'Произошла ошибка при отправке формы',
                        };
                        if (
                            formName === FormName.ChangePass &&
                            er.status === 400
                        ) {
                            meta.showErrorInMainErrorComponent = false;
                            meta.errorMessage = 'Неверный пароль';
                        } else if (er.status === 403) {
                            meta.errorMessage =
                                'Недостаточно прав на совершение данного действия';
                        }
                        return meta;
                    }
                    return { requestName: formName };
                },
            },
        },
        'competencies',
        'organizationsInfo',
        'skills',
        {
            name: RequestName.CreateSkill,
            errors: {
                500: (er: AjaxError): LoadingErrorInfo => {
                    const errorInfo: LoadingErrorInfo = {
                        showErrorInMainErrorComponent: true,
                        errorMessage: DEFAULT_LOADING_ERROR_MESSAGE,
                    };
                    const errorCodes = Api.errorCodes.get(
                        RequestName.CreateSkill
                    );
                    if (
                        er.response.errorCode !== undefined &&
                        er.response.errorCode === errorCodes.SKILL_ARCHIVED
                    ) {
                        errorInfo.errorMessage =
                            'Данный навык уже существует, но был заархивирован';
                    }
                    return errorInfo;
                },
            },
        },
        'catalogueCategories',
        'catalogueDocuments',
        {
            name: 'catalogueCategoriesKeywords',
            showLoadingBar: false,
            ignoreErrors: true,
            logOutOnUnAuth: false,
        },
        'notification',
        'sendNotificationStatus',
        {
            name: 'notificationCount',
            showLoadingBar: false,
            ignoreErrors: true,
            logOutOnUnAuth: false,
        },

        'educationMaterialTypes',
        'employees',
        'educationStatusChange',
        'loadGuideStats',
        'educationStatusReset',
        'recommendations',
        RequestName.UserId,
        'initialFilterStats',
        {
            name: 'searchFromAlias',
            errors: {
                404: {
                    errorMessage: LOADING_ERROR_MESSAGE_SEARCH,
                    showErrorInMainErrorComponent: true,
                    btnTitle: LOADING_ERROR_BTN_TITLE_SEARCH,
                    onCloseAction: getErrorAction('searchFromAlias'),
                    closeByBtnClickOnly: true,
                },
            },
        },
        'compressSearch',
        'redirectInfo',
        'catalogueRules',
        'confirmDocumentCategory',
        'revertDocumentCategory',
        'searchTabs',
        'searchTabsSimple',
        'searchTab',
        'userGuideStats',
        'userGuideSpecificStats',
        'userGuideSpecificUpdateStats',
        {
            name: 'loadBigAvatar',
            errors: {
                500: {
                    errorMessage: LOAD_AVATAR_SERVER_ERROR_TEXT,
                    showErrorInMainErrorComponent: true,
                    btnTitle: LOAD_AVATAR_SERVER_ERROR_BTN,
                    onCloseAction: getErrorAction('moduleLoading'),
                    closeByBtnClickOnly: true,
                },
                504: {
                    errorMessage: LOAD_AVATAR_SERVER_ERROR_TEXT,
                    showErrorInMainErrorComponent: true,
                    btnTitle: LOAD_AVATAR_SERVER_ERROR_BTN,
                    onCloseAction: getErrorAction('moduleLoading'),
                    closeByBtnClickOnly: true,
                },
                404: {
                    errorMessage: LOAD_AVATAR_NOT_FOUND_TEXT,
                    showErrorInMainErrorComponent: true,
                    btnTitle: LOAD_AVATAR_NOT_FOUND_BTN,
                    onCloseAction: undefined,
                    closeByBtnClickOnly: true,
                },
            },
        },
        {
            name: 'doodle',
            metaCreatorsFactory: {
                request: (options) => (formName: string) => ({
                    requestName: formName,
                }),
                resolve: (options) => (formName: string) => ({
                    requestName: formName,
                }),
                reject: (options) => (er: AjaxError, formName: string) => ({
                    requestName: formName,
                }),
            },
        },
    ],
    loadingShowActionCreators,
    loadingHideActionCreators,
    setErrorActionCreators,
    logOutOnUnAuthActionCreators,
    logInOnAuthActionCreators
);
