/**
 * Created by Lkarmelo on 06.09.2017.
 */

import { handleActions } from 'redux-actions';

import * as Store from '../../store/StoreNamespace';
import { setDefaultFilters } from '../../actions/search/defaultFilters';

export default handleActions(
    {
        [setDefaultFilters.toString()](
            state: Store.IFilters,
            { payload }: { payload: Store.IFiltersMeta; type: string }
        ) {
            const nextState = {};
            Object.keys(payload).forEach((key) => {
                if (payload[key].default !== undefined) {
                    nextState[key] = {
                        value: payload[key].default,
                    };
                }
            });
            return nextState;
        },
    },
    {} // - default value for defaultFilters
);
