import { MiddlewareAPI } from 'redux';
import { Observable } from 'rxjs/Observable';
import { concat } from 'rxjs/observable/concat';

import Api from 'app/api/Api';
import { withIgnoreSearchTriggerMeta } from 'app/redux/utils/withIgnoreSearchTriggerMeta';

import * as Store from '../../store/StoreNamespace';
import {
    fetchSearchResults,
    setSearchResults,
    fetchMoreSearchResults,
    addToSearchResults,
} from '../../actions/search/results';
import { setSkip, setLimit } from '../../actions/search/searchPaging';
import {
    searchRequest,
    searchReject,
    searchResolve,
} from '../../actions/loading';
import {
    addMultiSelectFilterValue,
    removeMultiSelectFilterValue,
    setDateRangeFrom,
    setDateRangeTo,
    setFilters,
    setFilterValue,
    toggleMultiSelectFilterValue,
} from '../../actions/search/filters';
import { resetSorting, setSorting } from '../../actions/sorting';
import { ContextAction, withContext } from '../../context/connectWithContext';
import { SearchDocumentName } from '../../actions/interfaces/DocumentEnum';
import { stateToBookmarkSelector } from '../../selectors/api/bookmark';
import { createRetryOnIEAuthProblem } from '../utils/createRetryOnIEAuthProblem';

const searchTriggers = [
    fetchSearchResults.toString(),
    setSkip.toString(),
    setLimit.toString(),
    fetchMoreSearchResults.toString(),
];

const filtersChangeActions = [
    addMultiSelectFilterValue.toString(),
    toggleMultiSelectFilterValue.toString(),
    removeMultiSelectFilterValue.toString(),
    setFilterValue.toString(),
    setDateRangeFrom.toString(),
    setDateRangeTo.toString(),
    setFilters.toString(),
];

const sortingChangeActions = [setSorting.toString(), resetSorting.toString()];

const fetchApi = (
    actionState: Store.ISearch,
    state: Store.IState,
    context: string,
    apiCall: Api.ApiCalls
) => {
    const fetchFunc = apiCall.hasOwnProperty(context) ? apiCall[context] : {};

    const requestPayload: Api.IDocumentSearchRequestBody =
        stateToBookmarkSelector({
            state,
            actionState,
            searchDocumentName: context as SearchDocumentName,
        });

    switch (context) {
        case SearchDocumentName.readLater:
        case SearchDocumentName.favorites:
        case SearchDocumentName.subscribes:
        case SearchDocumentName.documentManagementAll:
        case SearchDocumentName.documentManagementOwn:
            return fetchFunc(requestPayload);
        case SearchDocumentName.related:
            return fetchFunc(state.currentUser.id, requestPayload);
        case SearchDocumentName.education:
            const educationMaterialsState = <Store.IEducationMaterials>(
                actionState
            );
            const isRecommended =
                educationMaterialsState.statusFilter ===
                Store.EducationMaterialStatusValue.Recommended;
            const status =
                educationMaterialsState.statusFilter ===
                    Store.EducationMaterialStatusValue.All || isRecommended
                    ? undefined
                    : educationMaterialsState.statusFilter;

            return fetchFunc(requestPayload, isRecommended, status);
        default:
            return Observable.empty();
    }
};

export const contextLoadSearchResultsOnFiltersChange = (action$) =>
    action$
        .ofType(...filtersChangeActions, ...sortingChangeActions)
        .hasContext(true)
        .debounceTime(600)
        .mergeMap((action: ContextAction<any>) =>
            Observable.of(
                withContext(
                    withIgnoreSearchTriggerMeta(setSkip(0)),
                    action.context
                ),
                withContext(fetchSearchResults(), action.context)
            )
        );

export const contextLoadSearchResults = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall, context }
) =>
    action$
        .ofType(...searchTriggers)
        .hasContext(true)
        .ignoreSearchActionsByMeta()
        .mergeMap((action: ContextAction<any>) => {
            const modified = Number(new Date());
            const actionState = context(store.getState(), action);
            const shouldAddToResults =
                action.type === fetchMoreSearchResults.toString();

            return concat(
                fetchApi(
                    actionState,
                    store.getState(),
                    action.context[0],
                    apiCall
                )
                    .retryWhen(createRetryOnIEAuthProblem())
                    .map((response) => {
                        const results = Object.assign(response.response, {
                            modified,
                        });
                        const setOrAddAction = shouldAddToResults
                            ? addToSearchResults
                            : setSearchResults;
                        return withContext(
                            setOrAddAction({ results }),
                            action.context,
                            Store.SearchResultPageEnum.results
                        );
                    })
                    .catch((e) => {
                        console.error(e);
                        return Observable.of(searchReject(e));
                    })
                    .takeUntil(
                        action$
                            .ofType(...searchTriggers)
                            .hasContext(true)
                            .ignoreSearchActionsByMeta()
                            .filter(
                                (next) => next.context[0] === action.context[0]
                            )
                    ),
                Observable.of(searchResolve())
            )
                .takeUntil(
                    action$.ofType(searchReject.toString()).hasContext(true)
                )
                .startWith(searchRequest());
        });
