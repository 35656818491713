/**
 * Created by lkarmelo on 29.10.2019.
 */

import React, { useCallback, useState } from 'react';
import { usePrevious, useLatest } from 'react-use';

import TreeSelect, {
    IProps as ISelectProps,
    ISelectOption,
} from '../Select/TreeSelect';
import getMapFromArray from '../../../../utils/getMapFromArray';

export interface IProps extends Omit<ISelectProps, 'onSelect' | 'onDeselect'> {
    onApply(active: string[]): void;

    /**
     * При селекте/деселекте должна возвратить новое значение innerActive
     * @param select - true если был select, false если deselect
     * @param innerActive - текущий innerActive
     * @param option - аргумент onSelect/onDeselect TreeSelect
     * @param descendantsMap - аргумент onSelect/onDeselect TreeSelect
     * @param ascendantsMap - аргумент onSelect/onDeselect TreeSelect
     */
    getInnerActive?(
        select: boolean,
        innerActive: string[],
        option: ISelectOption,
        descendantsMap: Record<string, ISelectOption[]>,
        ascendantsMap: Record<string, ISelectOption[]>
    ): string[];
}

const SelectWithSelectionDelay: React.FC<IProps> = (props) => {
    const { children, active, onApply, getInnerActive } = props;
    const prevActive = usePrevious(active);

    const [innerActive, setInnerActive] = useState<string[]>(active);
    const innerActiveRef = useLatest(innerActive);

    if (prevActive !== active && innerActive !== active) {
        setInnerActive(active);
    }

    const _onSelect = useCallback(
        (option: ISelectOption | ISelectOption[]) => {
            const nextInnerActive = innerActiveRef.current.slice();

            if (Array.isArray(option)) {
                nextInnerActive.push(...option.map((opt) => opt.value));
            } else {
                nextInnerActive.push(option.value);
            }
            setInnerActive(Array.from(new Set(nextInnerActive)));
        },
        [getInnerActive]
    );

    const _onDeselect = useCallback(
        (option: ISelectOption | ISelectOption[]) => {
            let nextInnerActive: string[];
            if (Array.isArray(option)) {
                const optionsValuesToDelete = getMapFromArray(
                    option,
                    true,
                    (opt) => opt.value
                );
                nextInnerActive = innerActiveRef.current.filter(
                    (activeValue) => !optionsValuesToDelete[activeValue]
                );
            } else {
                nextInnerActive = innerActiveRef.current.filter(
                    (activeValue) => activeValue !== option.value
                );
            }
            setInnerActive(Array.from(new Set(nextInnerActive)));
        },
        [getInnerActive]
    );

    const apply = useCallback(() => {
        onApply(innerActive);
    }, [innerActive, onApply]);

    if (typeof children !== 'function') {
        throw Error('children should be a function');
    }

    return children(
        <TreeSelect
            {...props}
            active={innerActive}
            onSelect={_onSelect}
            onDeselect={_onDeselect}
        />,
        apply,
        innerActive
    );
};

SelectWithSelectionDelay.defaultProps = {
    active: [],
};

export default SelectWithSelectionDelay;
