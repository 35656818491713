/**
 * Created by Lkarmelo on 05.02.2018.
 */

import { createSelector } from 'reselect';

import * as Store from 'app/redux/store/StoreNamespace';
import { IOption } from 'app/components/common/controls/Option';

export interface IOrganizationsInfoAsOptions {
    organizations: IOption[];
    addresses: IOption[];
    posts: IOption[];
    targetPosts: IOption[];
    subdivisions: IOption[];
}

interface IMetaMap {
    [fieldName: string]: {};
}

const getSubdivisionsTitleValue = (
    subdivisions: Store.IHierarchicalCatalogItem[],
    metaMap: IMetaMap,
    supTitle = ''
): IOption[] => {
    const titles: IOption[] = [];
    subdivisions.forEach((subDiv) => {
        titles.push({
            label: supTitle
                ? `${supTitle} / ${subDiv.item.title}`
                : subDiv.item.title,
            value: subDiv.item.id,
            meta: metaMap && metaMap.subdivisions,
        });
        if (subDiv.subItems.length !== 0) {
            const subOptions = getSubdivisionsTitleValue(
                subDiv.subItems,
                metaMap,
                subDiv.item.title
            );
            subOptions.forEach((sOpt) =>
                titles.push({
                    label: supTitle
                        ? `${supTitle} / ${sOpt.label}`
                        : sOpt.label,
                    value: sOpt.value,
                    meta: metaMap && metaMap.subdivisions,
                })
            );
        }
    });

    return titles;
};

export const organizationsInfoToOptions = createSelector(
    ({
        state,
    }: {
        state: Store.IState;
        metaMap: IMetaMap;
    }): Store.IOrganizationsInfo => state.organizationsInfo,
    ({ metaMap }: { state: Store.IState; metaMap: IMetaMap }): IMetaMap =>
        metaMap,
    (
        organizationsInfo: Store.IOrganizationsInfo,
        metaMap: IMetaMap
    ): IOrganizationsInfoAsOptions => {
        const res = {};

        Object.keys(organizationsInfo).forEach((key) => {
            if (organizationsInfo[key]) {
                if (key === 'subdivisions') {
                    res[key] = getSubdivisionsTitleValue(
                        organizationsInfo[key],
                        metaMap
                    );
                } else {
                    res[key] = organizationsInfo[key].map((inf) => ({
                        value: inf.id,
                        label: inf.title,
                        meta: metaMap && metaMap[key],
                    }));
                }
            }
        });

        if (organizationsInfo.posts) {
            (<IOrganizationsInfoAsOptions>res).targetPosts =
                organizationsInfo.posts.map((inf) => ({
                    value: inf.id,
                    label: inf.title,
                    meta: metaMap && metaMap.targetPosts,
                }));
        }

        return <IOrganizationsInfoAsOptions>res;
    }
);
