export type TraversableObject<TField extends string> = {
    [P in TField]?: TraversableObject<TField>[];
};

export type Traverser<
    TF extends string,
    TO extends TraversableObject<TF> = TraversableObject<TF>
> = (
    list: TO[] | undefined,
    cb: (item: TO, parentItem?: TO) => void,
    parentItem?: TO
) => void;

/**
 * возвращает функцию, которая обходит в глубину структкуру, где в field лежит список дочерних объектов
 * @param field название поля с массивом дочерних элементов
 */
export const getDepthFirstSearcher = <TF extends string>(field: TF) => {
    const traverse = <T extends TraversableObject<TF>>(
        list: T[] | undefined,
        cb: (item: T, parentItem?: T) => void,
        parentItem?: T
    ) => {
        list?.forEach((item) => {
            cb(item, parentItem);
            traverse(item[field], cb, item);
        });
    };

    return traverse;
};
