/**
 * Created by lkarmelo on 07.10.2019.
 */

import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import clientRoutes from 'app/routing/clientRoutes';
import { getDocumentDate } from 'app/utils/document/getDocumentDate';
import getDocumentNameByType from 'app/utils/document/getDocumentNameByType';
import * as Store from 'app/redux/store/StoreNamespace';

import IProps from './interfaces/IYearSourceTypeLinkProps';
import * as styles from './YearSourceTypeLink.scss';

const YearSourceTypeLink: React.FC<IProps> = (props) => {
    const { document, delimiterClassName, getDocumentLink, similarity } = props;

    const { meta, id } = document || ({} as Store.IDocumentSnippet);
    const { objectType, displayObjectType, displayStorageSource } =
        meta || ({} as Store.IDocumentDescription);

    const formattedDate = useMemo(() => getDocumentDate(meta), [meta]);

    return (
        <div className={styles.yearSourceTypeLink}>
            {typeof similarity === 'number' && (
                <>
                    <div>{`Похож на ${Math.floor(similarity * 100)} %`}</div>
                    <div className={delimiterClassName} />
                </>
            )}
            {formattedDate !== undefined && (
                <>
                    <div>{formattedDate}</div>
                    <div className={delimiterClassName} />
                </>
            )}
            {displayStorageSource && (
                <>
                    <div>{displayStorageSource}</div>
                    <div className={delimiterClassName} />
                </>
            )}
            <div>{getDocumentNameByType(objectType, displayObjectType)}</div>
            <div className={delimiterClassName} />
            <div>
                <Link
                    target="_blank"
                    to={
                        typeof getDocumentLink === 'function'
                            ? getDocumentLink(props)
                            : clientRoutes.objectCard.getUrl({ id })
                    }
                >
                    Подробнее
                    <i className="nkc-icon nkc-icon-long-arrow" />
                </Link>
            </div>
        </div>
    );
};

export default YearSourceTypeLink;
