/**
 * Created by lkarmelo on 30.10.2019.
 */

import * as React from 'react';
import classNames from 'classnames';

interface IProps {
    title: string;
    className?: string;
}

const Header: React.FC<IProps> = ({ title, className }) => {
    return (
        <div className={classNames('nkcm-select-header', className)}>
            {title}
        </div>
    );
};

export default Header;
