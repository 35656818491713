/**
 * Created by Lkarmelo on 23.08.2017.
 */

import { Observable } from 'rxjs/Observable';
import { MiddlewareAPI } from 'redux';
import { Action } from 'redux-actions';

import Api from 'app/api/Api';

import { createRetryOnIEAuthProblem } from './utils/createRetryOnIEAuthProblem';

import * as Store from '../store/StoreNamespace';
import {
    FETCH_SEARCH_HINTS,
    setSearchHints,
    IFetchSearchHintsPayload,
    setAvatarsHints,
    SET_SEARCH_HINTS,
} from '../actions/search/searchHints';

export const loadSearchHints = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(FETCH_SEARCH_HINTS)
        .hasContext(false)
        .mergeMap(({ payload }: Action<IFetchSearchHintsPayload>) => {
            const searchQuery = store.getState().search.searchQuery;
            const { suggestionTypes } = payload;
            // сбрасываем предыдущие загруженные подсказки
            if (!searchQuery) {
                return Observable.of(
                    setSearchHints({
                        persons: [],
                        queries: [],
                    })
                );
            }
            const isDocTypeTab: boolean =
                store.getState().searchTabs.tabs[
                    store.getState().mainSearchPageActiveTab
                ].searchTarget === 'DOC' ||
                !store.getState().search.lastExecutedSearchAlias;
            // не показываем лоадер или ошибку загрузки
            return apiCall
                .searchHints(
                    searchQuery,
                    store.getState().mainSearchPageActiveTab,
                    suggestionTypes,
                    isDocTypeTab
                )
                .retryWhen(createRetryOnIEAuthProblem())
                .map(({ response: hints }) => setSearchHints(hints))
                .catch((e) => {
                    console.error(e);
                    return Observable.empty();
                })
                .takeUntil(action$.ofType(FETCH_SEARCH_HINTS));
        });

export const setAvatarForHints = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(SET_SEARCH_HINTS)
        .map(({ payload }: Action<any>) => payload)
        .mergeMap((data) => {
            const persons = data.queries.filter(
                (query) => query.person?.userId
            );
            return Observable.forkJoin(
                persons.map((person) => {
                    return apiCall
                        .getAvatar(person.person.userId, '50', true)
                        .map((res) => Observable.of(person.person.userId))
                        .catch((error) => Observable.of({ error }));
                })
            );
        })
        .map((response) => {
            return setAvatarsHints(response);
        })
        .catch((err) => {
            console.error(err);
            return Observable.empty();
        });
