/**
 * Created by lkarmelo on 16.12.2019.
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux-actions';

import { fetchCatalogueDocuments } from 'app/redux/actions/catalogue/catalogueDocuments';
import { SearchDocumentName } from 'app/redux/actions/interfaces/DocumentEnum';
import { withContext } from 'app/redux/context/connectWithContext';
import * as Store from 'app/redux/store/StoreNamespace';
import { setSorting } from 'app/redux/actions/sorting';
import { resetPaging } from 'app/redux/actions/search/searchPaging';

import CatalogueDocumentsPanel from './CatalogueDocumentsPanel';

import { createSortingPanel } from '../../common/SortingPanel/SortingPanel';

import {
    ICatalogueDocumentsPanelActionProps,
    ICatalogueDocumentsPanelStateProps,
} from '.';

// т.к. панель сортировки общая для всех и новых документов, то экшены сортировки будут только для catalogueDocuments
const sortingActionsDispatchContext = `${SearchDocumentName.catalogueDocuments}.sorting`;

const mapStateToProps = () => {
    const SortingPanel = createSortingPanel(
        SearchDocumentName.catalogueDocuments,
        sortingActionsDispatchContext
    );

    const sortingPanelOptions = [Store.sortingOptions.DateSortingOption];
    const sortingDefaultSort: Store.ISorting = {
        key: Store.sortingOptions.DateSortingOption.key,
        order: 'desc',
    };

    const renderSortingPanel = (
        _setSorting: (key: string, order: Store.SortingOrder) => void
    ) => (
        <SortingPanel
            options={sortingPanelOptions}
            defaultSort={sortingDefaultSort}
            setSorting={_setSorting}
        />
    );

    return (): ICatalogueDocumentsPanelStateProps => ({
        renderSortingPanel,
    });
};

const mapDispatchToProps = (dispatch): ICatalogueDocumentsPanelActionProps => {
    const loadDocuments = (categoryId: string, newDocuments: boolean): void => {
        newDocuments
            ? dispatch(
                  withContext(
                      fetchCatalogueDocuments({ id: categoryId }),
                      SearchDocumentName.catalogueDocumentsNew
                  )
              )
            : dispatch(
                  withContext(
                      fetchCatalogueDocuments({ id: categoryId }),
                      SearchDocumentName.catalogueDocuments
                  )
              );
    };
    const _resetPaging = (newDocuments: boolean) => {
        newDocuments
            ? dispatch(
                  withContext(
                      resetPaging(),
                      SearchDocumentName.catalogueDocumentsNew
                  )
              )
            : dispatch(
                  withContext(
                      resetPaging(),
                      SearchDocumentName.catalogueDocuments
                  )
              );
    };

    return {
        loadDocuments,
        resetPaging: _resetPaging,
        getLoadDocumentsAction(
            categoryId: string,
            newDocuments: boolean
        ): Action<any> {
            return newDocuments
                ? withContext(
                      fetchCatalogueDocuments({ id: categoryId }),
                      SearchDocumentName.catalogueDocumentsNew
                  )
                : withContext(
                      fetchCatalogueDocuments({ id: categoryId }),
                      SearchDocumentName.catalogueDocuments
                  );
        },
        setSorting(
            key: string,
            order: Store.SortingOrder,
            categoryId: string,
            newDocuments: boolean
        ): void {
            dispatch(
                withContext(
                    setSorting(key, order),
                    sortingActionsDispatchContext
                )
            );
            _resetPaging(newDocuments);
            loadDocuments(categoryId, newDocuments);
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CatalogueDocumentsPanel);
