import { Action, handleActions } from 'redux-actions';

import {
    setCatalogueCategoryKeywords,
    ISetCatalogueCategoryKeywordsPayload,
    setCatalogueCategory,
    ISetCatalogueCategoryPayload,
    setCatalogueCategories,
    setCatalogueCategoryRules,
    ISetCatalogueCategoryRulesPayload,
    categoryChangeCount,
    categoryChangeUnverifiedCount,
    IChangeCategoryCountPayload,
} from '../actions/catalogue/catalogueCategories';
import * as Store from '../store/StoreNamespace';

const getCopiedCategoriesState = (
    state: Store.INormalizedHierarchicalCategories,
    categoryId: string
) => ({
    ...state,
    categories: {
        ...state.categories,
        [categoryId]: { ...state.categories[categoryId] },
    },
});

export default handleActions<
    Partial<Store.INormalizedHierarchicalCategories>,
    any
>(
    {
        [setCatalogueCategories.toString()](
            state: Store.INormalizedHierarchicalCategories,
            { payload }: Action<Store.INormalizedHierarchicalCategories>
        ) {
            return payload;
        },
        [setCatalogueCategoryKeywords.toString()](
            state: Store.INormalizedHierarchicalCategories,
            { payload }: Action<ISetCatalogueCategoryKeywordsPayload>
        ) {
            const nextState = {
                ...state,
                categories: {
                    ...state.categories,
                    [payload.categoryId]: {
                        ...state.categories[payload.categoryId],
                    },
                },
            };
            nextState.categories[payload.categoryId].item = {
                ...nextState.categories[payload.categoryId].item,
                keywords: payload.keywords,
            };
            return nextState;
        },
        [setCatalogueCategory.toString()](
            state: Store.INormalizedHierarchicalCategories,
            { payload }: Action<ISetCatalogueCategoryPayload>
        ) {
            const nextState = { ...state };
            nextState.categories[payload.categoryId] = {
                ...nextState.categories[payload.categoryId],
            };
            nextState.categories[payload.categoryId].item = payload.category;
            return nextState;
        },
        [setCatalogueCategoryRules.toString()](
            state: Store.INormalizedHierarchicalCategories,
            { payload }: Action<ISetCatalogueCategoryRulesPayload>
        ) {
            if (!payload.categoryId || !payload.rules) {
                return state;
            }
            const nextState = getCopiedCategoriesState(
                state,
                payload.categoryId
            );
            nextState.categories[payload.categoryId].item = {
                ...nextState.categories[payload.categoryId].item,
                rules: payload.rules,
            };
            return nextState;
        },
        [categoryChangeCount.toString()](
            state: Store.INormalizedHierarchicalCategories,
            { payload }: Action<IChangeCategoryCountPayload>
        ) {
            if (!payload.categoryId || !payload.delta) {
                return state;
            }

            const nextState = getCopiedCategoriesState(
                state,
                payload.categoryId
            );

            nextState.categories[payload.categoryId].countCat = Math.max(
                nextState.categories[payload.categoryId].countCat +
                    payload.delta,
                0
            );
            return nextState;
        },
        [categoryChangeUnverifiedCount.toString()](
            state: Store.INormalizedHierarchicalCategories,
            { payload }: Action<IChangeCategoryCountPayload>
        ) {
            if (!payload.categoryId || !payload.delta) {
                return state;
            }

            const nextState = getCopiedCategoriesState(
                state,
                payload.categoryId
            );

            nextState.categories[payload.categoryId].countUnverified = Math.max(
                nextState.categories[payload.categoryId].countUnverified +
                    payload.delta,
                0
            );
            return nextState;
        },
    },
    {} // - default value for catalogueCategories
);
