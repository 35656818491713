/**
 * Created by lkarmelo on 15.07.2019.
 */

import React, { useEffect, useRef } from 'react';
import queryString from 'qs';
import { Action, Location } from 'history';

import { setStateFromLocation } from 'app/redux/actions/search/stateFromLocation';
import { queryValueToFilterValue } from 'app/utils/filters/queryValueToFilterValue';
import { compareFilterValues } from 'app/utils/filters/compareFilterValues';
import store from 'app/redux/store/store';
import { parseOptions } from 'app/utils/queryStringOptions';
import {
    SEARCH_ALIAS_PARAM_NAME,
    SEARCH_FILTERS_PARAM_NAME,
    SEARCH_QUERY_PARAM_NAME,
} from 'app/utils/constants';
import * as Store from 'app/redux/store/StoreNamespace';

import IProps from './interfaces/ISearchLocationObserverProps';

/**
 * Получаем актуальный query при смене url
 */
const getNextSearchQuery = (
    action: Action,
    state: Store.IState,
    contextState: Store.ISearch,
    locationSearchParsed
): string | undefined => {
    const { lastExecutedSearchQuery, executingSearchQuery, searchQuery } =
        contextState;
    const queryFromLocation = locationSearchParsed[SEARCH_QUERY_PARAM_NAME];

    if (typeof queryFromLocation !== 'string') {
        return undefined;
    }
    if (action === 'POP') {
        return lastExecutedSearchQuery !== queryFromLocation ||
            executingSearchQuery !== queryFromLocation
            ? queryFromLocation
            : undefined;
    }

    return searchQuery !== queryFromLocation ? queryFromLocation : undefined;
};

/**
 * Получаем актуальные фильтры при смене url
 */
const getNextFilters = (
    state: Store.IState,
    contextState: Store.ISearch,
    locationSearchParsed
): Store.IFilters | undefined => {
    if (locationSearchParsed[SEARCH_ALIAS_PARAM_NAME]) {
        return undefined;
    }

    let filtersFromUrl = {};
    try {
        filtersFromUrl =
            (locationSearchParsed[SEARCH_FILTERS_PARAM_NAME] &&
                JSON.parse(locationSearchParsed[SEARCH_FILTERS_PARAM_NAME])) ??
            {};
    } catch (error) {
        console.error('Фильтры заданы в неверном формате');
    }

    const nextFilters: Store.IFilters = {};
    const activeFilters = contextState.filters;
    state.filtersMeta &&
        Object.entries(state.filtersMeta).forEach(([filterName, filter]) => {
            const activeFilter = activeFilters[filterName];
            const activeFilterValue = activeFilter
                ? activeFilter.value
                : undefined;

            if (
                filtersFromUrl[filterName] === undefined &&
                activeFilter === undefined
            ) {
                return;
            }
            const queryOrDefaultVal =
                filtersFromUrl[filterName] !== undefined
                    ? filtersFromUrl[filterName]
                    : filter.default;
            const filterValueFromQuery = queryValueToFilterValue(
                queryOrDefaultVal,
                filter.type
            );

            if (
                !compareFilterValues(
                    filterValueFromQuery,
                    activeFilterValue,
                    filter.type
                )
            ) {
                nextFilters[filterName] = {
                    value: filterValueFromQuery,
                };
            }
        });

    return Object.keys(nextFilters).length === 0 ? undefined : nextFilters;
};

/**
 * Получаем актуальный searchAlias при смене url
 */
const getNextAlias = (
    action: Action,
    state: Store.IState,
    contextState: Store.ISearch,
    locationSearchParsed
): string | undefined => {
    const aliasFromLocation = locationSearchParsed[SEARCH_ALIAS_PARAM_NAME];
    const {
        lastExecutedSearchAlias,
        executingSearchAlias,
        resolvingSearchAlias,
    } = contextState;
    if (typeof aliasFromLocation !== 'string') {
        return undefined;
    }

    if (action === 'POP') {
        const isAliasNew =
            aliasFromLocation !== lastExecutedSearchAlias ||
            aliasFromLocation !== resolvingSearchAlias ||
            aliasFromLocation !== executingSearchAlias;

        return isAliasNew ? aliasFromLocation : undefined;
    }

    return aliasFromLocation !== executingSearchAlias
        ? aliasFromLocation
        : undefined;
};

const onLocationChange = (
    location: Location,
    action: Action,
    context = 'search'
) => {
    const state = store.getState() as Store.IState;
    const contextState: Store.ISearch = state[context];

    if (!contextState || Object.keys(state.filtersMeta).length === 0) {
        return;
    }

    const locationSearchParsed = queryString.parse(
        location.search,
        parseOptions
    );

    store.dispatch(
        setStateFromLocation({
            query: getNextSearchQuery(
                action,
                state,
                contextState,
                locationSearchParsed
            ),
            filters: getNextFilters(state, contextState, locationSearchParsed),
            alias: getNextAlias(
                action,
                state,
                contextState,
                locationSearchParsed
            ),
        })
    );
};

/**
 * Используем компоненту вместо middleware для redux, чтобы было удобнее в будущем работать с context
 */
const SearchLocationObserver: React.FunctionComponent<IProps> = (props) => {
    const { history, filtersMeta } = props;
    const initialLocationChangeFired = useRef(false);

    useEffect(() => {
        if (
            Object.keys(filtersMeta).length > 0 &&
            !initialLocationChangeFired.current
        ) {
            initialLocationChangeFired.current = true;
            onLocationChange(history.location, history.action);
        }
    }, [filtersMeta]);

    useEffect(() => {
        // запоминаем pathname, потому что колбэек history.listen вызовется при смене адреса до того, как компонента будет unmounted
        const initialPathName = history.location.pathname;
        // подписываемся на историю, чтобы не полагаться на рендер компоненты, потому что актуальные props могут прийти позднее
        const unlisten = history.listen((location, action) => {
            initialPathName === location.pathname &&
                onLocationChange(location, action);
        });

        return () => unlisten();
    }, []);

    return null;
};

export default SearchLocationObserver;
