import Api from 'app/api/Api';
import * as Store from 'app/redux/store/StoreNamespace';
import { addMultiSelectFilterValue, setFilterValue } from 'app/redux/actions/search/filters';
import { useActions } from 'app/hooks/useActions';

interface IUseSearchPanel {
    isMinified: boolean;
    onFetchSearchResultsClickOwnProps: () => void;
}

const useSearchPanel = <T>(
    {
        isMinified,
        onFetchSearchResultsClickOwnProps,
    }:
        IUseSearchPanel) => {
    const {
        filtersToDefaults,
        setQuery,
        fetchSearchResults,
        setQueryExpansionMode,
        setSearchHints,
        withIgnoreSearchTriggerMeta,
        fetchSearchHints,
    } = useActions();

    const onFetchSearchResultsClick = (searchQuery: string) => {
        if (isMinified) {
            filtersToDefaults();
        }
        // searchQuery в store редакса может быть null, см. выше функцию mapStateToProps
        searchQuery === '' && setQuery('');

        fetchSearchResults();
        onFetchSearchResultsClickOwnProps && onFetchSearchResultsClickOwnProps();
    };

    const removeSearchQuery = () => {
        setQuery('');
        setQueryExpansionMode(false);
        setSearchHints({ persons: [], queries: [] });
    };

    const onFetchSearchHintsClick = (reset: boolean, suggestionTypes?: Api.SuggestionType[]) => {
        fetchSearchHints({ suggestionTypes });
        reset && setSearchHints({ persons: [], queries: [] });
    };

    const onSetQueryExpansionMode = (mode: boolean) => {
        setQueryExpansionMode(mode);
    };

    const onUpdateQuery = (query: string) => {
        setQuery(query);
    };

    const onSelectFilterHint = (value: string, label: string, filterName: string, filtersMeta: Store.IFiltersMeta) => {
        const filter = filtersMeta[filterName];
        if (!filter) {
            return;
        }
        if (filter.type === Store.FilterType.MultiSelect) {
            withIgnoreSearchTriggerMeta(addMultiSelectFilterValue(filterName, value, true, label));
        } else if (filter.type === Store.FilterType.Select) {
            withIgnoreSearchTriggerMeta(setFilterValue(filterName, value, true, label));
        }
    };

    return {
        onFetchSearchResultsClick,
        removeSearchQuery,
        onFetchSearchHintsClick,
        onSetQueryExpansionMode,
        onUpdateQuery,
        onSelectFilterHint,
    };
};

export default useSearchPanel;
