import React, { PureComponent, RefObject } from 'react';
import classNames from 'classnames';
import autoBind from 'autobind-decorator';

import * as style from './UploadBox.scss';
import IUploadBoxProps from './interfaces/IUploadBoxProps';

import prettyBytes from '../../../../utils/pretty-bytes';
import { MAX_UPLOAD_FILE_SIZE } from '../../../../utils/constants';

export default class UploadBox extends PureComponent<IUploadBoxProps> {
    static defaultProps = {
        path: '/',
        disabled: false,
    };

    uploadForm: RefObject<HTMLFormElement> = null;

    uploadInput: RefObject<HTMLInputElement> = null;

    constructor(props: IUploadBoxProps) {
        super(props);

        this.uploadForm = React.createRef();
        this.uploadInput = React.createRef();
    }

    componentDidMount() {
        // set upload directory attr's
        // this.uploadInput.current.setAttribute("directory", "true");
        // this.uploadInput.current.setAttribute("webkitdirectory", "true");
        // this.uploadInput.current.setAttribute("mozdirectory", "true");
    }

    render(): JSX.Element {
        const { disabled } = this.props;

        const fileSizeStr = prettyBytes(MAX_UPLOAD_FILE_SIZE, { locale: 'ru' });
        const fileUploadPlaceholder =
            ' или просто перетащите в область ' +
            `файлы RTF, PDF, ... (Размер каждого файла не должен превышать ${fileSizeStr})`;

        return (
            <form
                ref={this.uploadForm}
                encType="multipart/form-data"
                className={classNames(style.uploadForm, { disabled })}
                onDragOver={(e) => {
                    e.dataTransfer && (e.dataTransfer.dropEffect = 'copy');
                    this.uploadForm.current.classList.add('dragover');
                }}
                onDragLeave={() =>
                    this.uploadForm.current.classList.remove('dragover')
                }
            >
                <div
                    className={style.uploadDropboxWrapper}
                    title={`Загрузите с компьютера${fileUploadPlaceholder}`}
                >
                    <input
                        ref={this.uploadInput}
                        className={style.uploadFileInput}
                        type="file"
                        // @ts-ignore
                        name={name}
                        value=""
                        placeholder="Файл не выбран"
                        multiple
                        onChange={this.handleFileChange}
                    />

                    <span className={style.uploadDropboxPlaceholder}>
                        &nbsp;Загрузите с компьютера
                    </span>
                    {fileUploadPlaceholder}
                    <i
                        className={classNames(
                            'nkc-icon-question-circle',
                            style.uploadDropboxHelp
                        )}
                    />
                </div>
            </form>
        );
    }

    @autoBind
    handleFileChange(e: any) {
        e && e.preventDefault();

        const newFiles =
            (e.dataTransfer && e.dataTransfer.files) || e.target.files;
        if (newFiles === null) {
            return;
        }

        const { onFileChange } = this.props;

        // load file by file
        onFileChange(newFiles);
    }
}
