/**
 * Created by Lkarmelo on 23.11.2017.
 */

import React, { PureComponent } from 'react';
import classNames from 'classnames';

import IProps from './interfaces/IEditablePanelProps';

import './EditablePanel.scss';

const pencilImgPath = require('images/pencil.svg');

export default class EditablePanel extends PureComponent<IProps> {
    render(): JSX.Element {
        const {
            children,
            title,
            isEditable,
            onStartEdit,
            onCancelEdit,
            onSave,
            saveDisabled,
            className,
            hideEditBtn,
            showSaveFailMessage,
        } = this.props;
        return (
            <section
                className={classNames('editable-panel', className, {
                    'editable-panel--edit': isEditable,
                })}
            >
                <div className="editable-panel__header clearfix">
                    <div className="editable-panel__title">{title}</div>
                    {!isEditable && !hideEditBtn && (
                        <button
                            className="editable-panel__edit btn"
                            onClick={onStartEdit}
                        >
                            <img src={pencilImgPath} alt="Редактировать" />
                            <span>Редактировать</span>
                        </button>
                    )}
                </div>
                <div className="editable-panel__body">
                    <div className="editable-panel__content">{children}</div>
                    {showSaveFailMessage && (
                        <div className="editable-panel__invalid-msg">
                            Данные не сохранены. Форма заполнена с ошибками.
                        </div>
                    )}
                    {isEditable && (
                        <div className="editable-panel__controls">
                            <button
                                onClick={onSave}
                                className="btn-ok"
                                disabled={saveDisabled}
                            >
                                <span>Сохранить</span>
                            </button>
                            <button
                                onClick={onCancelEdit}
                                className="btn-cancel"
                            >
                                Отменить
                            </button>
                        </div>
                    )}
                </div>
            </section>
        );
    }
}
