/**
 * Created by lkarmelo on 23.07.2019.
 */

import { MiddlewareAPI } from 'redux';
import { Action } from 'redux-actions';
import { Observable } from 'rxjs/Observable';

import {
    blockPaging,
    setLimit,
    setSkip,
} from 'app/redux/actions/search/searchPaging';
import {
    compressRequestUpdateLocationAndSearch,
    executeSearch,
    fetchMoreSearchResults,
    fetchSearchResults,
    IFetchSearchResultsPayload,
} from 'app/redux/actions/search/results';
import { setIgnoreMistakes } from 'app/redux/actions/search/ignoreMistakes';
import {
    addMultiSelectFilterValue,
    removeMultiSelectFilterValue,
    setDateRangeFrom,
    setDateRangeTo,
    setFilters,
    setFilterValue,
    toggleMultiSelectFilterValue,
} from 'app/redux/actions/search/filters';
import { filtersToDefaults } from 'app/redux/actions/search/defaultFilters';
import { toggleSelectedQueryTag } from 'app/redux/actions/search/searchQuery';
import {
    removeTag,
    addTag,
    removeTags,
} from 'app/redux/actions/search/tagsFromQuery';
import { setPersonalization } from 'app/redux/actions/search/personalization';
import { resetSorting, setSorting } from 'app/redux/actions/sorting';
import * as Store from 'app/redux/store/StoreNamespace';
import { withIgnoreSearchTriggerMeta } from 'app/redux/utils/withIgnoreSearchTriggerMeta';
import {
    setSearchActiveTab,
    ISetSearchActiveTabPayload,
    fetchSingleSearchTabFull,
} from 'app/redux/actions/search/searchTabs';
import { SearchTarget } from 'app/redux/store/StoreNamespace';

import { getSearchState } from './utils/getSearchState';
import { getRequestBody, getRequestQuery } from './utils/getSearchRequestInfo';

import {
    clearConceptsSelectState,
    setConceptsSelectState,
} from '../../actions/search/concepts';
import { setStrictForPersons } from '../../actions/search/strict';

export const filtersChangeActions = [
    addMultiSelectFilterValue.toString(),
    toggleMultiSelectFilterValue.toString(),
    removeMultiSelectFilterValue.toString(),
    setFilterValue.toString(),
    setDateRangeFrom.toString(),
    setDateRangeTo.toString(),
    setFilters.toString(),
    toggleSelectedQueryTag.toString(),
];

export const tagsChangeActions = [
    addTag.toString(),
    removeTag.toString(),
    removeTags.toString(),
];

export const sortingChangeActions = [
    setSorting.toString(),
    resetSorting.toString(),
];

export const blockPagingOnSearchOrFiltersChange = (action$) =>
    action$
        .ofType(
            fetchSearchResults.toString(),
            fetchMoreSearchResults.toString(),
            setPersonalization.toString(),
            setConceptsSelectState.toString(),
            clearConceptsSelectState.toString(),
            ...filtersChangeActions,
            ...tagsChangeActions,
            ...sortingChangeActions
        )
        .hasContext(false)
        .ignoreSearchActionsByMeta()
        .mapTo(blockPaging());

export const loadSearchResultsOnPaging = (
    action$,
    store: MiddlewareAPI<Store.IState>
) =>
    action$
        .ofType(setSkip.toString(), setLimit.toString())
        .hasContext(false)
        .ignoreSearchActionsByMeta()
        .filter(() => getSearchState(store).lastExecutedSearchQuery !== null)
        .mergeMap(() =>
            Observable.of(
                compressRequestUpdateLocationAndSearch({ isNewSearch: false })
            )
        );

export const loadSearchResultsOnFetch = (
    action$,
    store: MiddlewareAPI<Store.IState>
) =>
    action$
        .ofType(fetchSearchResults.toString())
        .hasContext(false)
        .ignoreSearchActionsByMeta()
        .throttleTime(500)
        .mergeMap(({ payload }: Action<IFetchSearchResultsPayload>) => {
            const state = store.getState();
            const isFirstSearch = state.search.lastExecutedSearchQuery === null;
            const actions = [
                withIgnoreSearchTriggerMeta(clearConceptsSelectState()),
                withIgnoreSearchTriggerMeta(setSkip(0, true)),
                setIgnoreMistakes(payload.ignoreMistakes),
                setStrictForPersons(null),
            ];

            // загружаем мету для активной вкладки для отображения фильтров
            // экшн сработает при первом поиске с главной странице
            // при смене вкладок сработает setSearchActiveTabEpic, который также загружает мету
            if (isFirstSearch) {
                actions.push(
                    fetchSingleSearchTabFull({
                        tabCode: state.mainSearchPageActiveTab,
                        setPersonalization: true,
                    })
                );
            }

            actions.push(
                compressRequestUpdateLocationAndSearch({
                    isNewSearch: true,
                    setPersonStrictFromResponse: true,
                    setExtractedConcepts: true,
                    setInitialSelectedFromExtractedConcepts: true,
                    tabHasChanged: isFirstSearch,
                })
            );

            return Observable.of(...actions);
        });

export const loadSearchResultsOnFilterChange = (
    action$,
    store: MiddlewareAPI<Store.IState>
) =>
    action$
        .ofType(...filtersChangeActions)
        .hasContext(false)
        .ignoreSearchActionsByMeta()
        .filter(() => {
            const state = getSearchState(store);
            return (
                state.lastExecutedSearchQuery !== null || !!state.searchQuery
            );
        })
        .debounceTime(500)
        .mergeMap(() => {
            const state = getSearchState(store);
            const isFirstSearch = state.lastExecutedSearchQuery === null;
            return Observable.of(
                withIgnoreSearchTriggerMeta(setSkip(0, true)),
                compressRequestUpdateLocationAndSearch({
                    isNewSearch: isFirstSearch,
                    setExtractedConcepts: true,
                    setInitialSelectedFromExtractedConcepts:
                        state.lastExecutedSearchQuery !== state.searchQuery,
                })
            );
        });

export const loadNewSearchResultsOnTagsChange = (
    action$,
    store: MiddlewareAPI<Store.IState>
) =>
    action$
        .ofType(...tagsChangeActions)
        .hasContext(false)
        .ignoreSearchActionsByMeta()
        .mergeMap(() =>
            Observable.of(
                withIgnoreSearchTriggerMeta(setSkip(0, true)),
                compressRequestUpdateLocationAndSearch({ isNewSearch: true })
            )
        );

export const loadSearchResultsOnSearchTabChange = (
    action$,
    store: MiddlewareAPI<Store.IState>
) =>
    action$
        .ofType(setSearchActiveTab.toString())
        .hasContext(false)
        .ignoreSearchActionsByMeta()
        .mergeMap(({ payload }: Action<ISetSearchActiveTabPayload>) => {
            const state = store.getState();
            const newSearchTab = state.searchTabs.tabs?.[payload.tabCode];

            const actions = [
                withIgnoreSearchTriggerMeta(clearConceptsSelectState()),
                filtersToDefaults({ removeTags: false }),
                withIgnoreSearchTriggerMeta(setSkip(0, true)),
            ];

            if (
                newSearchTab.searchTarget === SearchTarget.employee ||
                (payload.preserveIgnoreMistakesFromLastSearch &&
                    state.search.ignoreMistakes)
            ) {
                actions.push(
                    withIgnoreSearchTriggerMeta(setIgnoreMistakes(true))
                );
            } else {
                actions.push(
                    withIgnoreSearchTriggerMeta(setIgnoreMistakes(false))
                );
            }

            if (!payload.preservePersonStrict) {
                actions.push(setStrictForPersons(null));
            }

            actions.push(
                compressRequestUpdateLocationAndSearch({
                    isNewSearch: !!payload.isNewSearch,
                    setPersonStrictFromResponse: true,
                    setExtractedConcepts: true,
                    setInitialSelectedFromExtractedConcepts: true,
                    tabHasChanged: true,
                })
            );

            return Observable.of(...actions);
        });

export const loadSearchResultsOnPersonalizationActions = (
    action$,
    store: MiddlewareAPI<Store.IState>
) =>
    action$
        .ofType(setPersonalization.toString())
        .hasContext(false)
        .ignoreSearchActionsByMeta()
        .mergeMap(() =>
            Observable.of(
                withIgnoreSearchTriggerMeta(setSkip(0, true)),
                compressRequestUpdateLocationAndSearch({ isNewSearch: false })
            )
        );

export const loadSearchResultsOnSortingActions = (
    action$,
    store: MiddlewareAPI<Store.IState>
) =>
    action$
        .ofType(...sortingChangeActions)
        .hasContext(false)
        .ignoreSearchActionsByMeta()
        .mergeMap(() =>
            Observable.of(
                withIgnoreSearchTriggerMeta(setSkip(0, true)),
                compressRequestUpdateLocationAndSearch({ isNewSearch: false })
            )
        );

export const loadSearchResultsOnConceptSelectionChange = (
    action$,
    store: MiddlewareAPI<Store.IState>
) =>
    action$
        .ofType(
            setConceptsSelectState.toString(),
            clearConceptsSelectState.toString()
        )
        .hasContext(false)
        .ignoreSearchActionsByMeta()
        .mergeMap(() =>
            Observable.of(
                withIgnoreSearchTriggerMeta(setSkip(0, true)),
                compressRequestUpdateLocationAndSearch({ isNewSearch: false })
            )
        );

export const loadMoreSearchResults = (
    action$,
    store: MiddlewareAPI<Store.IState>
) =>
    action$
        .ofType(fetchMoreSearchResults.toString())
        .hasContext(false)
        .ignoreSearchActionsByMeta()
        .map(() => {
            const state: Store.IState = store.getState();
            const contextState = getSearchState(store);

            const originalQuery = getRequestQuery(contextState, false);

            return executeSearch({
                shouldAddToSearchResults: true,
                requestBody: getRequestBody(state, contextState, originalQuery),
                clearQuery: originalQuery,
                filters: contextState.filters,
            });
        });
