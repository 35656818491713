/**
 * Created by lkarmelo on 08.08.2019.
 */

import React, { useCallback, useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useMountedState } from 'react-use/esm';
import { Select } from 'antd';

import { useControlledTimeout } from 'app/hooks/useControlledTimeout';
import Modal from 'app/components/Modals/Modal';
import * as Store from 'app/redux/store/StoreNamespace';
import { ModalName } from 'app/redux/store/StoreNamespace';
import { FormName } from 'app/api/FormName';
import {
    initForm as initFormAction,
    removeForm as removeFormAction,
    sendForm,
    setFormStatus,
} from 'app/redux/actions/forms';
import { setModalVisible } from 'app/redux/actions/modals';
import Api from 'app/api/Api';

import * as styles from './Report.scss';

interface IProps extends IReportStateProps, IReportActionProps {}

interface IReportStateProps {
    isVisible: boolean;
    status: Store.FormStatus;
}

interface IReportActionProps {
    send(reportText: string): void;
    initForm(): void;
    removeForm(): void;
    resetFormStatus(): void;
    onCancel(): void;
}

enum Themes {
    FEEDBACK = 'Оценка поиска',
    SUGGESTION = 'Предложение',
    ERROR = 'Ошибка',
}

const Report: React.FC<IProps> = (props) => {
    const {
        isVisible,
        onCancel,
        status,
        initForm,
        removeForm,
        send,
        resetFormStatus,
    } = props;
    const [isSentSuccess, setIsSentSuccess] = useState(false);
    const [text, setText] = useState('');
    const [theme, setTheme] = useState(Themes.FEEDBACK);

    const isMounted = useMountedState();
    const timeout = useControlledTimeout();

    useEffect(() => {
        initForm();
        return () => removeForm();
    }, []);

    useEffect(() => {
        if (!isSentSuccess && status === Store.FormStatus.SendingSuccess) {
            if (isVisible) {
                setIsSentSuccess(true);
                timeout.start(() => isMounted() && onCancel(), 3000);
            }
            setText('');
            resetFormStatus();
        }
    }, [status, isVisible]);

    const afterModalClose = useCallback(() => {
        setIsSentSuccess(false);
        timeout.clear();
    }, []);

    const htmlTextWithTheme = useMemo(
        () => `Тема: ${theme}\nТекст: ${text}`,
        [text, theme]
    );

    return (
        <Modal
            visible={isVisible}
            onCancel={onCancel}
            afterClose={afterModalClose}
            className={classNames(styles.report)}
            showCross={false}
        >
            <div>
                {isSentSuccess ? (
                    <>
                        <i className={styles.reportSuccessIcon} />
                        <div
                            className={classNames(
                                styles.reportTitle,
                                styles.reportTitleSuccess
                            )}
                        >
                            Сообщение отправлено
                        </div>
                        <div className={styles.reportSuccessText}>
                            Спасибо, что помогаете нам стать лучше!
                        </div>
                        <button
                            className={classNames(
                                'btn',
                                styles.reportBtnOk,
                                styles.reportBtnOkSuccess
                            )}
                            onClick={onCancel}
                        >
                            Продолжить
                        </button>
                    </>
                ) : (
                    <>
                        <div className={styles.reportTitle}>Оставьте отзыв</div>
                        <Select
                            className={styles.reportTheme}
                            value={theme}
                            onChange={(value) => setTheme(value as Themes)}
                            dropdownClassName={styles.reportThemeDropdown}
                        >
                            <Select.Option
                                title={Themes.FEEDBACK}
                                value={Themes.FEEDBACK}
                            >
                                {Themes.FEEDBACK}
                            </Select.Option>
                            <Select.Option
                                title={Themes.SUGGESTION}
                                value={Themes.SUGGESTION}
                            >
                                {Themes.SUGGESTION}
                            </Select.Option>
                            <Select.Option
                                title={Themes.ERROR}
                                value={Themes.ERROR}
                            >
                                {Themes.ERROR}
                            </Select.Option>
                        </Select>
                        <textarea
                            value={text}
                            onChange={(e) => setText(e.currentTarget.value)}
                            className={styles.reportText}
                            autoFocus
                            placeholder="Сообщение"
                        />
                        <div className={styles.reportButtons}>
                            <button
                                className={classNames(
                                    'btn',
                                    styles.reportBtnCancel
                                )}
                                onClick={onCancel}
                            >
                                Отмена
                            </button>
                            <button
                                className={classNames(
                                    'btn',
                                    styles.reportBtnOk
                                )}
                                onClick={() => send(htmlTextWithTheme)}
                                disabled={!text}
                            >
                                Отправить
                            </button>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: Store.IState): IReportStateProps => ({
    status:
        state.forms[FormName.FeedbackMessage] &&
        state.forms[FormName.FeedbackMessage].status,
    isVisible: state.modals[ModalName.Report],
});

const mapDispatchToProps = (dispatch): IReportActionProps => ({
    send(message: string): void {
        // fond: true, потому что параметр еще может вернуться
        dispatch(
            sendForm(FormName.FeedbackMessage, {
                message,
                found: true,
            } as Api.FormBody[FormName.FeedbackMessage])
        );
    },
    initForm(): void {
        dispatch(initFormAction(FormName.FeedbackMessage, null));
    },
    removeForm(): void {
        dispatch(removeFormAction(FormName.FeedbackMessage));
    },
    resetFormStatus(): void {
        dispatch(
            setFormStatus(FormName.FeedbackMessage, Store.FormStatus.Editing)
        );
    },
    onCancel(): void {
        dispatch(setModalVisible(ModalName.Report, false));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
