/*
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 13.03.2018
 * -----
 * Last Modified: 10.05.2018 17:59:53
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { Action } from 'redux-actions';

import * as Store from 'app/redux/store/StoreNamespace';
import { toggleSelectedQueryTag } from 'app/redux/actions/search/searchQuery';
import { traverseTerms } from 'app/utils/expansionUtils';
import ExpansionTagsPanel, {
    IExpansionTagsPanelStateProps,
    IExpansionTagsPanelActionProps,
} from 'app/components/common/tables/ExpansionTagsPanel/ExpansionTagsPanel';

const termsSelector = (state: Store.IQueryTags): Store.IQueryTerm[] | null => {
    const terms: Store.IQueryTerm[] = [];
    if (!state || !state.tags) {
        return null;
    }
    traverseTerms(state.tags, (term) => terms.push(term));
    return terms;
};

const tagsValuesSelector = createSelector<
    Store.IQueryTags,
    Store.IQueryTerm[],
    Store.IQueryKeyword[]
>(termsSelector, (terms: Store.IQueryTerm[] | null) => {
    if (!terms) {
        return null;
    }
    const res = new Map();
    terms.forEach((term) =>
        term.expansion.forEach((keyword) =>
            res.set(
                keyword.lemmaId,
                Object.assign(keyword, { tooltip: term.term })
            )
        )
    );

    return Array.from(res.values());
});

const mapStateToProps = (
    state: Store.IState
): IExpansionTagsPanelStateProps => ({
    tags: tagsValuesSelector(state.search.queryTags),
    selectedTagIds: state.search.selectedQueryTags,
});

const mapActionToProps = (
    dispatch: Dispatch<Action<any>>
): IExpansionTagsPanelActionProps => ({
    onSelectExpansionTag: (id: string) => {
        dispatch(toggleSelectedQueryTag(id));
    },
});

export default connect(mapStateToProps, mapActionToProps)(ExpansionTagsPanel);
