/**
 * Created by lkarmelo on 16.05.2019.
 */

import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Modal from 'antd/es/modal/Modal';

import clientRoutes from 'app/routing/clientRoutes';

import IProps from './interfaces/IReplicasProps';
import * as styles from './DocumentReplicas.scss';

const DocumentReplicas: React.FunctionComponent<IProps> = ({
    replicas,
    isVisible,
    onClose,
}) => (
    <Modal
        visible={isVisible}
        footer={null}
        className={`modal ${styles.documentReplicas}`}
        onCancel={onClose}
    >
        <div className={styles.documentReplicasTitle}>Связанные НМД:</div>
        <div className={styles.documentReplicasList}>
            {Array.isArray(replicas) &&
                replicas.map(({ document, isActive }) => (
                    <div
                        key={document.id}
                        className={styles.documentReplicasList}
                    >
                        <div className={styles.documentReplicasLink}>
                            <Link
                                to={clientRoutes.objectCard.getUrl({
                                    id: document.id,
                                })}
                                target="_blank"
                            >
                                {document.meta.title}
                            </Link>
                        </div>
                        <div
                            className={classNames(
                                styles.documentReplicasStatus,
                                {
                                    [styles.documentReplicasStatusActive]:
                                        isActive,
                                }
                            )}
                        />
                    </div>
                ))}
        </div>
    </Modal>
);

export default React.memo(DocumentReplicas);
