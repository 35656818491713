import memoizeOne from 'memoize-one';

import * as Store from 'app/redux/store/StoreNamespace';
import {highlightQueryInHint} from 'app/redux/epics/highlight';

import {getResultSearchQuery} from './getResultSearchQuery';

import {IFilterHints} from '../interfaces/ISearchPanelProps';
import {IOption} from '../../../common/controls/Option';
import {FilterHintOptionMeta, HintOptionMeta, RegularHintOptionMeta} from '../types';

export const getOptionsFromHints = memoizeOne((
    queryHints: Store.IQueryHint[],
    filterHints: IFilterHints,
    personHints: Store.IPerson[],
    searchQuery: string,
    filtersMeta: Store.IFiltersMeta,
): IOption<HintOptionMeta>[] => {
    const res: IOption<HintOptionMeta>[] = [];
    const dateNow = Date.now();

    const regularQueryHints: IOption<RegularHintOptionMeta>[] = [];
    const filterQueryHints: IOption<FilterHintOptionMeta>[] = [];

    queryHints.forEach((queryHint, index) => {
        const position = queryHint.position;
        const resultSearchQuery = getResultSearchQuery(position, searchQuery, queryHint.query);
        const label: string = queryHint.highlighted ?
            queryHint.highlighted :
            highlightQueryInHint(searchQuery, queryHint.query, position);
        regularQueryHints.push({
            label,
            value: resultSearchQuery,
            key: `${resultSearchQuery}_${dateNow}_${index}`,
            meta: {
                documentsCount: queryHint.count,
                hintType: 'regular',
                position,
                originalHintQuery: queryHint.query,
                person: queryHint.person,
                doc: queryHint.doc,
            }
        });
    });

    Object.values(filterHints).forEach((hints) => {
        hints.forEach((queryHint, index) => {
            const position = queryHint.position;
            // сейчас все подсказки по фильтра должны приходить именно с этим полем. в будущем может поменяться, но
            // на всякий случай пока просто пропускаем фильтры с другой структурой
            if (!queryHint.filter.value.DisplayValue) {
                return;
            }

            const resultSearchQuery = getResultSearchQuery(position, searchQuery);
            // правильно ли здесь использовать highlighted? или для фильтра другой формат?
            const label: string = queryHint.highlighted ||
                highlightQueryInHint(searchQuery, queryHint.query, position);

            filterQueryHints.push({
                label,
                value: queryHint.filter.value.DisplayValue.code,
                // можно использовать Date.now() как часть key потому что из-за мемоизации он останется, пока
                // следующие подсказки не придут
                key: `${queryHint.filter.code}_${queryHint.filter.value.DisplayValue.code}_${dateNow}_${index}`,
                meta: {
                    documentsCount: queryHint.count,
                    hintType: 'filter',
                    filterName: queryHint.filter.code,
                    displayValue: queryHint.filter.value.DisplayValue.displayValue || queryHint.filter.value.DisplayValue.code,
                    resultSearchQuery,
                    position,
                    sectionTitle: index === 0 ? filtersMeta[queryHint.filter.code]?.title : undefined,
                }
            });
        });
    });

    res.push(...regularQueryHints, ...filterQueryHints);

    return res;
});
