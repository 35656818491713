import connectWithContext, {
    IInjectedProps,
} from 'app/redux/context/connectWithContext';
import * as Store from 'app/redux/store/StoreNamespace';
import IDocumentPanelOwnProps from 'app/components/document/DocumentPanel/interfaces/IDocumentPanelOwnProps';
import {
    IDocumentPanelStateProps,
    IDocumentPanelActionProps,
} from 'app/components/document/DocumentPanel/interfaces/IDocumentPanelProps';
import {
    SearchDocumentName,
    documentState,
} from 'app/redux/actions/interfaces/DocumentEnum';

import mapActionToProps from '../../../document/DocumentPanelActionDefault';
import DocumentPanel from '../../../document/DocumentPanel/DocumentPanelConnected';

const similarState = documentState(SearchDocumentName.objectCardSimilar);

interface ISimilarDocumentOwnProps extends IDocumentPanelOwnProps {
    count: number;
}

const mapStateToFactoryProps = () => {
    const constProps = {
        renderFilterPanel: (): JSX.Element => {
            return null;
        },
    };

    return (
        state: Store.IState,
        ownProps: ISimilarDocumentOwnProps
    ): Store.IDocumentResults & IInjectedProps & IDocumentPanelStateProps => {
        const actionState: Store.ISearch = similarState(state);

        return {
            rootContext: SearchDocumentName.objectCardSimilar,
            currentUserId: state.objectCard?.id,
            isLoading: state.loading.pendingRequests.search,
            limit: actionState.paging.limit,
            skip: actionState.paging.skip,
            ...actionState.results,
            count: ownProps.count,
            ...constProps,
        };
    };
};

export default connectWithContext<
    Store.IDocumentResults & IDocumentPanelStateProps,
    IDocumentPanelActionProps,
    ISimilarDocumentOwnProps
>(
    mapStateToFactoryProps,
    mapActionToProps
)(DocumentPanel);
