/**
 * Created by Lkarmelo on 01.03.2018.
 */

export enum UIFeaturesName {
    UserMenu = 'userMenu',
    NavigationMenu = 'navigationMenu',
    SiteTour = 'siteTour',

    SearchLink = 'searchLink',
    CatalogueLink = 'catalogueLink',
    AdminLink = 'adminLink',
    DVALink = 'dvaLink',
    DocumentationLink = 'docs',

    FavoriteBtn = 'favoriteBtn',
    ReadLaterBtn = 'readLaterBtn',
    RecommendBtn = 'recommendBtn',

    ProfileLink = 'profileLink',
    FavoriteLink = 'favoriteLink',
    EducationLink = 'educationLink',
    ReadLaterLink = 'readLaterLink',
    RelatedLink = 'relatedLink',
    NotificationLink = 'notificationLink',
    SubscribeLink = 'subscribeLink',

    SearchPageFilters = 'searchPageFilters',
    SearchQueryExtensionBtn = 'searchQueryExtensionBtn',

    FooterContent = 'footerContent',

    AccountProfile = 'accountProfile',
    AccountFavorite = 'accountFavorite',
    AccountEducation = 'accountEducation',
    AccountReadLater = 'accountReadLater',
    AccountRelated = 'accountRelated',
    AccountMyDocument = 'accountDocument',
    AccountNotification = 'accountNotification',
    AccountFiles = 'accountFiles',
    AccountSubscribe = 'accountSubscribe',

    ProfilePersonalInfo = 'profilePersonalInfo',
    ProfileWorkInfo = 'profileWorkInfo',
    ProfileCompetencies = 'profileCompetencies',
    ProfileKnowledgeCategories = 'profileKnowledgeCategories',
    ProfileSkills = 'profileSkills',

    WorkInfoTargetPost = 'workInfoTargetPost',

    DocumentManagementLink = 'documentManagementLink',

    EducationStatusBtns = 'educationStatusBtns',

    // фильтры (значения должны оставаться в том же кейсе, что и в StoreNamespace.StaticFilterName)
    Thesaurus = 'thesaurus',
    SortByField = 'fields',

    LinkToObjectCardWithHighlightedContent = 'linkToObjectCardWithHighlightedContent',

    DocumentSnippetOrgs = 'documentSnippetOrgs',

    DocumentSnippetUsersTitle = 'documentSnippetUsersTitle',

    DocumentSnippetUserAvatar = 'documentSnippetUserAvatar',
    ObjectCardUserAvatar = 'objectCardUserAvatar',
    ObjectCardOrgAvatar = 'objectCardOrgAvatar',

    DocumentReplicas = 'documentReplicas',
}
