/**
 * Created by lkarmelo on 28.10.2019.
 */

import React, { useCallback } from 'react';
import classNames from 'classnames';

interface IProps {
    searchQuery: string;
    placeholder?: string;
    collapsed?: boolean;
    className?: string;
    setSearchQuery(searchQuery: string): void;
}

const SearchBar: React.FC<IProps> = ({
    searchQuery,
    setSearchQuery,
    placeholder,
    collapsed,
    className,
}) => {
    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchQuery(e.currentTarget.value);
        },
        [setSearchQuery]
    );

    if (collapsed) {
        return null;
    }

    return (
        <div
            className={classNames('nkcm-select-search-bar', className, {
                'nkcm-select-search-bar--empty': !searchQuery,
            })}
        >
            <input
                className="nkcm-select-search-bar__input"
                type="text"
                value={searchQuery}
                onChange={onChange}
                placeholder={placeholder}
            />
            <button
                className="nkcm-select-search-bar__clear-btn"
                onClick={() => setSearchQuery('')}
            />
        </div>
    );
};

export default SearchBar;
