import { Observable } from 'rxjs/Observable';
import { concat } from 'rxjs/observable/concat';
import { MiddlewareAPI } from 'redux';
import { Action } from 'redux-actions';

import {
    FETCH_SKILLS,
    setSkills,
    CREATE_SKILL,
    addSkill,
    ADD_SKILL,
} from 'app/redux/actions/skills';
import {
    skillsReject,
    skillsRequest,
    skillsResolve,
    createSkillReject,
    createSkillRequest,
    createSkillResolve,
} from 'app/redux/actions/loading';
import { addSubField } from 'app/redux/actions/forms';
import { FormName } from 'app/api/FormName';

import { createSimpleLoadingEpic } from './utils';

import * as Store from '../store/StoreNamespace';

export const loadSkills = createSimpleLoadingEpic({
    triggers: [FETCH_SKILLS],
    apiCallName: 'skills',
    actions: {
        requestAction: skillsRequest,
        resolveAction: skillsResolve,
        rejectAction: skillsReject,
        setAction: setSkills,
    },
});

export const createNewSkill = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }
) =>
    action$.ofType(CREATE_SKILL).mergeMap((action) =>
        concat(
            apiCall
                .createSkill(action.payload)
                .map((response) => addSkill(response.response))
                .catch((e) => {
                    console.error(e);
                    return Observable.of(createSkillReject(e));
                }),
            Observable.of(createSkillResolve())
        )
            .takeUntil(action$.ofType(createSkillReject.toString()))
            .startWith(createSkillRequest())
    );

export const addCreatedSkillToSkillsFormData = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }
) =>
    action$
        .ofType(ADD_SKILL)
        .filter(() => {
            const form = store.getState().forms[FormName.Skills];
            return !!form && form.data.activeSkills;
        })
        .map((action: Action<Store.ISimpleCatalogItem>) =>
            addSubField(
                FormName.Skills,
                'activeSkills',
                action.payload.code,
                null
            )
        );
