/**
 * Created by Lkarmelo on 05.09.2017.
 */
import { MiddlewareAPI } from 'redux';
import { Observable } from 'rxjs/Observable';

import { createSimpleLoadingEpic } from './utils';

import {
    FETCH_USER_DATA,
    setUserData,
    fetchUserPermissions,
    setUserPermissions,
} from '../actions/user';
import { userReject, userRequest, userResolve } from '../actions/loading';
import { LOG_IN_SUCCESS, setAuthorized } from '../actions/authorization';
import * as Store from '../store/StoreNamespace';
import { fetchGuide } from '../actions/tour';

export const loadUser = createSimpleLoadingEpic({
    triggers: [FETCH_USER_DATA, LOG_IN_SUCCESS],
    apiCallName: 'userData',
    actions: {
        requestAction: userRequest,
        resolveAction: userResolve,
        rejectAction: userReject,
        setAction: setUserData,
        spawnOnSuccess: [
            fetchUserPermissions,
            () => setAuthorized(true),
            fetchGuide,
        ],
        spawnOnError: [
            (e) => {
                if (e.status !== 401) {
                    return setAuthorized(true);
                }
            },
            (e) => {
                if (e.status !== 401) {
                    return fetchUserPermissions();
                }
            },
        ],
    },
});

export const loadUserPermissions = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }
) =>
    action$.ofType(fetchUserPermissions.toString()).mergeMap(() =>
        apiCall
            .userPermissions()
            .map(({ response }) => {
                return setUserPermissions(response);
            })
            .catch((e) => {
                console.error(e);
                // если доступ запрещён, то ставим пустые роли, чтобы отобразилось сообщение, что у пользователя
                // нет прав для просмотра
                if (e.status === 403) {
                    return Observable.of(setUserPermissions([]));
                }
                return Observable.empty();
            })
    );
