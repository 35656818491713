const START_TAG = '<script';
const END_TAG = 'script>';

const clearContentString = (str: string) => {
    const entries: { start: number; end: number }[] = [];

    let startPos = -1;
    let startI = 0;
    while ((startPos = str.indexOf(START_TAG, startPos + 1)) !== -1) {
        entries[startI] = { start: startPos, end: 0 };
        startI++;
    }

    let endPos = -1;
    let endI = 0;
    while ((endPos = str.indexOf(END_TAG, endPos + 1)) !== -1) {
        entries[endI] = { ...entries[endI], end: endPos + END_TAG.length };
        endI++;
    }

    let res = '';

    entries.forEach((value, idx) => {
        if (idx === 0) {
            res += str.slice(0, value.start);
        } else {
            res += str.slice(entries[idx - 1].end, value.start);
        }

        if (idx < entries.length - 1) {
            res += str.slice(value.end, entries[idx + 1].start);
        } else {
            res += str.slice(value.end);
        }
    });

    return res;
};

export const getSafetyInnerHtml = (str: string) => {
    if (str && (str.includes(START_TAG) || str.includes(END_TAG))) {
        return clearContentString(str);
    }
    return str;
};
