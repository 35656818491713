import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import * as Store from 'app/redux/store/StoreNamespace';
import Sidebox, { useSideboxHelper } from 'app/components/common/Sidebox';

import * as styles from './ConceptsTab.scss';

import ConceptsPanel from '../ConceptsPanel/ConceptsPanel';
import Concept from '../../../../common/Concept';

interface IConceptsTabProps
    extends IConceptsTabStateProps,
        IConceptsTabDispatchProps,
        IConceptsTabOwnProps {}

export interface IConceptsTabStateProps {
    conceptDescriptions: Record<string, Store.IConceptDescription>;
}

export interface IConceptsTabDispatchProps {
    loadConceptParents(concept: Store.ISchemeConcept): void;
    loadConceptChildren(concept: Store.ISchemeConcept): void;
    loadConceptInfo(conceptUri: string, schemeUri: string): void;
    loadConceptRelated(conceptUri: string, schemeUri: string): void;
}

interface IConceptsTabOwnProps {
    concepts: Store.ISchemeConcept[];
    conceptsTrees: Record<string, Store.ICardRelatedConceptTree>;
}

const ConceptsTab: React.FC<IConceptsTabProps> = (props) => {
    const {
        concepts,
        loadConceptParents,
        loadConceptChildren,
        conceptsTrees,
        loadConceptInfo,
        conceptDescriptions,
    } = props;

    const [openSiedbox, closeSidebox, sideboxOpened] = useSideboxHelper();

    const [viewedConceptUri, setViewedConceptUri] = useState<string>();

    const conceptsMap: Record<string, Store.ISchemeConcept> = useMemo(
        () =>
            concepts.reduce((res, c) => {
                res[c.uri] = c;
                return res;
            }, {}),
        [concepts]
    );

    useEffect(() => {
        if (viewedConceptUri && conceptsMap[viewedConceptUri]?.inScheme) {
            loadConceptParents(conceptsMap[viewedConceptUri]);
            loadConceptChildren(conceptsMap[viewedConceptUri]);
        }
    }, [
        conceptsMap,
        viewedConceptUri,
        loadConceptParents,
        loadConceptChildren,
    ]);

    const onSelectConceptInPanel = useCallback(
        (uri: string) => {
            // выбранный в панели концепт и viewedConcept находятся в одной и той же схеме
            const schemeUri = conceptsMap[viewedConceptUri]?.inScheme?.uri;
            if (schemeUri) {
                loadConceptInfo(uri, schemeUri);
            }
        },
        [conceptsMap, loadConceptInfo, viewedConceptUri]
    );

    return (
        <div className={styles.cardConcepts}>
            {concepts.map((concept) => (
                <Concept
                    key={concept.uri}
                    className={classNames(styles.cardConceptsConcept, {
                        [styles.cardConceptsConceptSelected]:
                            viewedConceptUri === concept.uri,
                    })}
                    titleClassName={styles.cardConceptsConceptTitle}
                    bodyClassName={styles.cardConceptsConceptBody}
                    onClick={() => {
                        setViewedConceptUri(concept.uri);
                        openSiedbox();
                    }}
                    title={concept.label}
                    schemeTitle={concept.inScheme?.label}
                />
            ))}
            {viewedConceptUri && (
                <Sidebox
                    className={styles.cardConceptsSidebox}
                    opened={sideboxOpened}
                    onOutsideContentClick={closeSidebox}
                    onCloseButtonClick={closeSidebox}
                    showCloseButton
                >
                    <ConceptsPanel
                        concept={conceptsMap[viewedConceptUri]}
                        conceptsTree={conceptsTrees?.[viewedConceptUri]}
                        conceptDescriptions={conceptDescriptions}
                        onConceptSelected={onSelectConceptInPanel}
                    />
                </Sidebox>
            )}
        </div>
    );
};

ConceptsTab.defaultProps = {
    concepts: [],
};

export default ConceptsTab;
