/**
 * Created by Lkarmelo on 31.01.2017.
 */

import { createAction } from 'redux-actions';

import Api from 'app/api/Api';

import * as Store from '../store/StoreNamespace';

export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const fetchUserData = createAction(FETCH_USER_DATA);

export const SET_USER_DATA = 'SET_USER_DATA';
export const setUserData = createAction<Store.IUser>(SET_USER_DATA);

export const fetchUserPermissions = createAction('FETCH_USER_PERMISSIONS');

export const setUserPermissions = createAction<Api.UserPermissionsResponse>(
    'SET_USER_PERMISSIONS'
);
export const clearUserPermissions = createAction('CLEAR_USER_PERMISSIONS');
