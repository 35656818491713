/**
 * Created by Lkarmelo on 23.08.2017.
 */

import { createAction } from 'redux-actions';

import Api from 'app/api/Api';

import * as Store from '../../store/StoreNamespace';

export const FETCH_SEARCH_HINTS = 'FETCH_SEARCH_HINTS';
export const SET_SEARCH_HINTS = 'SET_SEARCH_HINTS';
export const FETCH_AVATARS_HINTS = 'FETCH_AVATARS_HINTS';
export const SET_AVATARS_HINTS = 'SET_AVATARS_HINTS';

export interface IFetchSearchHintsPayload {
    suggestionTypes?: Api.SuggestionType[];
}

export const fetchSearchHints =
    createAction<IFetchSearchHintsPayload>(FETCH_SEARCH_HINTS);
export const setSearchHints = createAction<Store.ISearchHint>(SET_SEARCH_HINTS);
export const fetchAvatarsHints = createAction<string>(FETCH_AVATARS_HINTS);
export const setAvatarsHints = createAction(SET_AVATARS_HINTS);
