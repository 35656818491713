import { Action } from 'redux-actions';
import { MiddlewareAPI } from 'redux';
import { Observable } from 'rxjs/Observable';
import { concat } from 'rxjs/observable/concat';
import queryString from 'qs';

import {
    ISetStateFromLocationPayload,
    setStateFromLocation,
} from 'app/redux/actions/search/stateFromLocation';
import {
    compressRequestUpdateLocationAndSearch,
    executeSearch,
} from 'app/redux/actions/search/results';
import { setResolvingSearchAlias } from 'app/redux/actions/search/searchAlias';
import {
    searchFromAliasReject,
    searchFromAliasRequest,
    searchFromAliasResolve,
} from 'app/redux/actions/loading';
import {
    fetchQueryExpansion,
    setQuery,
    setQueryExpansionMode,
    setSelectedQueryTags,
} from 'app/redux/actions/search/searchQuery';
import { setIgnoreMistakes } from 'app/redux/actions/search/ignoreMistakes';
import { setFilters } from 'app/redux/actions/search/filters';
import { setLimit, setSkip } from 'app/redux/actions/search/searchPaging';
import { replaceFiltersMetaValues } from 'app/redux/actions/search/filtersMeta';
import { setPersonalization } from 'app/redux/actions/search/personalization';
import { replaceDotsWithDashes } from 'app/utils/filters/filterNamesEscape';
import {
    SEARCH_TAB_PARAM_NAME,
    THESAURUS_SPECIAL_SYMBOL,
} from 'app/utils/constants';
import { traverseTerms } from 'app/utils/expansionUtils';
import * as Store from 'app/redux/store/StoreNamespace';
import Api from 'app/api/Api';
import { withIgnoreSearchTriggerMeta } from 'app/redux/utils/withIgnoreSearchTriggerMeta';
import { setSearchActiveTab } from 'app/redux/actions/search/searchTabs';

import { resetSorting, setSorting } from '../../actions/sorting';
import {
    clearConceptsSelectState,
    setConceptsSelectState,
} from '../../actions/search/concepts';
import { setStrictForPersons } from '../../actions/search/strict';
import { parseOptions } from '../../../utils/queryStringOptions';

const isRangeFilterValue = (
    value: Api.ISearchRequestFilter['value']
): value is Api.RangeFilterValue =>
    (<Api.RangeFilterValue>value).hasOwnProperty('RangeFilterValue');

const getFiltersAndQueryFromResponse = (
    response: Api.ICompressedSearch
): { filters: Store.IFilters; query: string } => {
    const { searchRequest } = response;
    let { query } = searchRequest;
    const { doc } = searchRequest;
    const filters: Store.IFilters = {};

    if (doc) {
        Array.isArray(doc.userFilters) &&
            doc.userFilters.forEach(({ code, value }) => {
                const resultFilterValue = isRangeFilterValue(value)
                    ? value.RangeFilterValue
                    : value.SelectFilterValue.values.map(
                          (v) => v.StringFilterValue.value
                      );

                filters[replaceDotsWithDashes(code)] = {
                    value: resultFilterValue,
                };
            });
    }

    if (query.startsWith(THESAURUS_SPECIAL_SYMBOL)) {
        query = query.slice(1);
        filters[Store.StaticFilterName.Thesaurus] = { value: true };
    }

    return {
        filters,
        query,
    };
};

const getSortingFromResponse = (
    response: Api.ICompressedSearch
): Store.ISorting | undefined => {
    const responseSort = response.searchRequest.doc.sortBy;
    const responseSortFields = responseSort && Object.keys(responseSort);

    if (!responseSort || responseSortFields.length === 0) {
        return undefined;
    }

    const key =
        Object.values(Store.sortingOptions).find((option) =>
            option.fields.every((field) => responseSortFields.includes(field))
        )?.key ?? Store.sortingOptions.RelevancySortingOption.key;

    return {
        key,
        order: responseSort[
            responseSortFields[0]
        ].toLocaleLowerCase() as Store.SortingOrder,
    };
};

const filterSetStateFromLocationActions = ({
    payload,
}: Action<ISetStateFromLocationPayload>) =>
    payload.query !== undefined ||
    payload.filters !== undefined ||
    payload.alias !== undefined;

export const getOfTypeLoadOnLocationChange = (action$): Observable<any> =>
    action$
        .ofType(setStateFromLocation.toString())
        .hasContext(false)
        .filter(filterSetStateFromLocationActions);

export const loadSearchResultsOnLocationChange = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    getOfTypeLoadOnLocationChange(action$).mergeMap(
        ({ payload }: Action<ISetStateFromLocationPayload>) => {
            const { query, alias } = payload;

            const { searchTabs } = store.getState();

            const locationSearchParsed = queryString.parse(
                location.search,
                parseOptions
            );
            const tabCodeFromUrl = locationSearchParsed[
                SEARCH_TAB_PARAM_NAME
            ] as string;

            const isCorrectTab = searchTabs.tabCodes?.includes(tabCodeFromUrl);

            if (!alias) {
                const isNewSearch = query !== undefined;
                return Observable.of(
                    withIgnoreSearchTriggerMeta(
                        setSearchActiveTab({
                            tabCode: isCorrectTab
                                ? tabCodeFromUrl
                                : searchTabs.defaultTabCode,
                        })
                    ),
                    compressRequestUpdateLocationAndSearch({
                        isNewSearch,
                        tabHasChanged: true,
                    })
                );
            }

            return concat(
                Observable.of(
                    setResolvingSearchAlias(alias),
                    searchFromAliasRequest()
                ),
                apiCall
                    .searchRequestFromAlias(alias)
                    .mergeMap(
                        ({ response }: { response: Api.ICompressedSearch }) => {
                            if (!response.searchRequest) {
                                return Observable.empty();
                            }
                            const { doc } = response.searchRequest;
                            const responseMeta = response.meta;

                            const {
                                filters: responseFilters,
                                query: responseQuery,
                            } = getFiltersAndQueryFromResponse(response);
                            const sorting = getSortingFromResponse(response);

                            const actions = [
                                setQuery(responseQuery),
                                setIgnoreMistakes(doc.ignoreMistakes),
                                withIgnoreSearchTriggerMeta(
                                    setPersonalization(doc.personalization)
                                ),
                                withIgnoreSearchTriggerMeta(
                                    setFilters(responseFilters)
                                ),
                                withIgnoreSearchTriggerMeta(
                                    setSkip(doc.skip, true)
                                ),
                                withIgnoreSearchTriggerMeta(
                                    setLimit({ limit: doc.limit })
                                ),
                                withIgnoreSearchTriggerMeta(
                                    setQueryExpansionMode(!!doc.expansion)
                                ),
                            ];

                            if (response.searchRequest.searchTab) {
                                actions.push(
                                    withIgnoreSearchTriggerMeta(
                                        setSearchActiveTab({
                                            tabCode:
                                                response.searchRequest
                                                    .searchTab,
                                            setPersonalization: false,
                                        })
                                    )
                                );
                            } else {
                                // ставим пустую строку вместо null, потому что если в сторе активная вклада будет null,
                                // то при загрузке вкладок она поменяется на дефолтную. загрузка вкладок может произойти
                                // позже, чем этот код
                                actions.push(
                                    withIgnoreSearchTriggerMeta(
                                        setSearchActiveTab({
                                            tabCode: '',
                                            setPersonalization: false,
                                        })
                                    )
                                );
                            }

                            if (doc.expansion) {
                                // обходим doc.expansion и выносим все встреченные lemmaId в массив
                                const lemmaIds = [];
                                traverseTerms(
                                    doc.expansion,
                                    ({ expansion }) => {
                                        expansion.forEach(({ lemmaId }) => {
                                            lemmaIds.push(lemmaId);
                                        });
                                    }
                                );
                                actions.push(setSelectedQueryTags(lemmaIds));

                                // добавляем экшн, который загрузит расширения, потому что
                                // withIgnoreSearchTriggerMeta(setQueryExpansionMode(!!doc.expansion)) не загружает их,
                                // а только меняет стейт redux
                                actions.push(fetchQueryExpansion());
                            }

                            if (responseMeta.savedFiltersMetaValues) {
                                actions.push(
                                    replaceFiltersMetaValues(
                                        responseMeta.savedFiltersMetaValues
                                    )
                                );
                            }

                            if (sorting) {
                                actions.push(
                                    withIgnoreSearchTriggerMeta(
                                        setSorting(sorting.key, sorting.order)
                                    )
                                );
                            } else {
                                actions.push(
                                    withIgnoreSearchTriggerMeta(resetSorting())
                                );
                            }

                            if (Array.isArray(doc.concepts)) {
                                const conceptsSelection = doc.concepts.reduce(
                                    (res, { uri, selected }) => {
                                        res[uri] = selected;
                                        return res;
                                    },
                                    {}
                                );
                                actions.push(
                                    withIgnoreSearchTriggerMeta(
                                        setConceptsSelectState({
                                            selectState: conceptsSelection,
                                            ignoreEqualityToInitial: true,
                                        })
                                    )
                                );
                            } else {
                                actions.push(
                                    withIgnoreSearchTriggerMeta(
                                        clearConceptsSelectState()
                                    )
                                );
                            }

                            if (typeof doc.strict === 'boolean') {
                                setStrictForPersons(doc.strict);
                            } else {
                                setStrictForPersons(null);
                            }

                            actions.push(
                                executeSearch({
                                    alias,
                                    requestBody: response.searchRequest,
                                    clearQuery: responseQuery,
                                    filters: responseFilters,
                                    setPersonStrictFromResponse: true,
                                    setExtractedConcepts: true,
                                    setInitialSelectedFromExtractedConcepts:
                                        true,
                                })
                            );

                            return Observable.from(actions);
                        }
                    )
                    .catch((e) => {
                        console.error(e);
                        return Observable.of(searchFromAliasReject(e));
                    })
                    .takeUntil(
                        action$
                            .hasContext(false)
                            .ofType(
                                searchFromAliasReject.toString(),
                                compressRequestUpdateLocationAndSearch.toString()
                            )
                    ),
                Observable.of(
                    searchFromAliasResolve(),
                    setResolvingSearchAlias(null)
                )
            ).takeUntil(getOfTypeLoadOnLocationChange(action$));
        }
    );
