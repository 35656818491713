import { Observable, ObservableInput } from 'rxjs/Observable';
import { Action } from 'redux-actions';
import { MiddlewareAPI } from 'redux';

import * as Store from 'app/redux/store/StoreNamespace';
import { setUserPermissions } from 'app/redux/actions/user';

export const waitForSetPermissions = (
    action$,
    originalAction: Action<any>,
    store: MiddlewareAPI<Store.IState>
): ObservableInput<Action<any>> => {
    const state = store.getState();
    if (state.user && state.user.permissions) {
        return Observable.of(originalAction);
    }
    return action$
        .ofType(setUserPermissions.toString())
        .mapTo(originalAction)
        .take(1);
};
