/**
 * Created by Lkarmelo on 27.02.2018.
 */

import React, { PureComponent } from 'react';
import classNames from 'classnames';
import autoBind from 'autobind-decorator';

import formContainer from 'app/components/high-order/formContainer';

import {
    fieldsFactory,
    textMultipleFieldRenderer,
    dateFieldRenderer,
    selectFieldRenderer,
    textFieldRenderer,
    multiSelectTagsRenderer,
    hiddenTextFieldRenderer,
    multiSelectFieldRenderer,
    checkBoxRenderer,
} from './fieldFactories';
import IProps from './interfaces/IFormProps';

@autoBind
export class BaseForm extends PureComponent<IProps> {
    static defaultProps: IProps = {
        fields: {},
        validationResults: {},
        fieldsRenderer: fieldsFactory,
        textMultipleFieldRenderer,
        dateFieldRenderer,
        selectFieldRenderer,
        textFieldRenderer,
        multiSelectTagsRenderer,
        hiddenTextFieldRenderer,
        multiSelectFieldRenderer,
        checkBoxRenderer,
    };

    render(): JSX.Element {
        const { className, fieldsRenderer } = this.props;
        return (
            <article className={classNames('form', className)}>
                {fieldsRenderer(this.props)}
            </article>
        );
    }
}

export default formContainer(BaseForm);
