/**
 * Created by lkarmelo on 12.09.2019.
 */

import React, { useState } from 'react';
import classNames from 'classnames';
import { pluralize } from 'numeralize-ru';
import { Link } from 'react-router-dom';

import Collapse from 'app/components/common/utils/Collapse';
import clientRoutes from 'app/routing/clientRoutes';
import { CURRENT_HTTP_URL } from 'app/utils/constants';

import IProps from './interfaces/IGroupedDocumentsProps';
import * as styles from './GroupedDocuments.scss';

const GroupedDocuments: React.FC<IProps> = ({ grouped, className }) => {
    const { items, totalCount } = grouped;
    const [isOpened, setIsOpened] = useState(false);

    return (
        <div
            className={classNames(styles.groupedDocuments, className, {
                [styles.groupedDocumentsOpened]: isOpened,
            })}
        >
            <Collapse isOpen={isOpened}>
                <div className={styles.groupedDocumentsRows}>
                    {Array.isArray(items) &&
                        items.map(({ item: doc }) => {
                            const { link } = doc.meta;
                            const linkUrl =
                                typeof link === 'string'
                                    ? link
                                    : typeof link === 'object'
                                    ? link.url
                                    : undefined;

                            const sourceLinkContent = (
                                <>
                                    <div
                                        className={styles.groupedDocumentsTitle}
                                    >
                                        {doc.meta.file?.filename
                                            ? `${doc.meta.file.filename} (from: ${doc.meta.title})`
                                            : doc.meta.title}
                                    </div>
                                    <div className={styles.groupedDocumentsOrg}>
                                        {Array.isArray(doc.meta.orgs) &&
                                            doc.meta.orgs.map((o) => (
                                                <div key={o.title}>
                                                    {o.title}
                                                </div>
                                            ))}
                                    </div>
                                </>
                            );

                            return (
                                <div
                                    key={doc.id}
                                    className={styles.groupedDocumentsRow}
                                >
                                    {linkUrl ? (
                                        <a
                                            className={
                                                styles.groupedDocumentsSourceLink
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={`${CURRENT_HTTP_URL}${clientRoutes.documentRedirect.getUrl(
                                                { id: doc.id }
                                            )}`}
                                        >
                                            {sourceLinkContent}
                                        </a>
                                    ) : (
                                        <div
                                            className={
                                                styles.groupedDocumentsSourceLink
                                            }
                                        >
                                            {sourceLinkContent}
                                        </div>
                                    )}
                                    <Link
                                        className={
                                            styles.groupedDocumentsCardLink
                                        }
                                        target="_blank"
                                        to={clientRoutes.objectCard.getUrl({
                                            id: doc.id,
                                        })}
                                    >
                                        Подробнее
                                        <i className="nkc-icon nkc-icon-long-arrow" />
                                    </Link>
                                </div>
                            );
                        })}
                </div>
            </Collapse>
            <button
                onClick={() => setIsOpened((prevIsOpened) => !prevIsOpened)}
                className={classNames(
                    'btn',
                    styles.groupedDocumentsCollapseBtn
                )}
            >
                {isOpened
                    ? 'Свернуть список'
                    : // tslint:disable-next-line
                      `Показать ${totalCount} связ${pluralize(
                          totalCount,
                          'ь',
                          'и',
                          'ей'
                      )}`}
            </button>
        </div>
    );
};

export default GroupedDocuments;
