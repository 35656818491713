/**
 * Created by Lkarmelo on 05.12.2017.
 */

import { createAction } from 'redux-actions';

import * as Store from 'app/redux/store/StoreNamespace';
import { FormName } from 'app/api/FormName';

export const INIT_FORM = 'INIT_FORM';
export const initForm = createAction<
    { name: FormName; data: any },
    string,
    any
>(INIT_FORM, (name: FormName, data: any) => ({ name, data }));

export const REMOVE_FORM = 'REMOVE_FORM';
export const removeForm = createAction<string>(REMOVE_FORM);

export interface ISetFormStatusPayload {
    formName: FormName;
    status: Store.FormStatus;
}
export const SET_FORM_STATUS = 'SET_FORM_STATUS';
export const setFormStatus = createAction<
    ISetFormStatusPayload,
    FormName,
    Store.FormStatus
>(SET_FORM_STATUS, (formName: FormName, status: Store.FormStatus) => ({
    formName,
    status,
}));

export interface ISetFormResponsePayload {
    formName: FormName;
    response: any;
}
export const SET_FORM_RESPONSE = 'SET_FORM_RESPONSE';
export const setFormResponse = createAction<
    ISetFormResponsePayload,
    FormName,
    any
>(SET_FORM_RESPONSE, (formName: FormName, response: any) => ({
    formName,
    response,
}));

export const CHANGE_FIELD = 'CHANGE_FIELD';
export const changeField = createAction<
    { formName: FormName; fieldName: string; value: any },
    string,
    string,
    any
>(CHANGE_FIELD, (formName: FormName, fieldName: string, value: any) => ({
    formName,
    fieldName,
    value,
}));

export interface IAddSubFieldPayload {
    formName: FormName;
    fieldName: string;
    key?: string;
    value: any;
}
export const ADD_SUB_FIELD = 'ADD_SUB_FIELD';
export const addSubField = createAction<
    IAddSubFieldPayload,
    FormName,
    string,
    any,
    string
>(
    ADD_SUB_FIELD,
    (formName: FormName, fieldName: string, value: any, key?: string) => ({
        formName,
        fieldName,
        value,
        key,
    })
);

export interface IRemoveSubField {
    formName: FormName;
    fieldName: string;
    key: string;
    value: any;
}
export const REMOVE_SUB_FIELD = 'REMOVE_SUB_FIELD';
export const removeSubField = createAction<
    IRemoveSubField,
    FormName,
    string,
    string,
    any
>(
    REMOVE_SUB_FIELD,
    (formName: FormName, fieldName: string, key?: string, value?: any) => ({
        formName,
        fieldName,
        key,
        value,
    })
);

export interface IEditSubFieldPayload {
    formName: FormName;
    fieldName: string;
    key: string;
    value: any;
}
export const CHANGE_SUB_FIELD = 'CHANGE_SUB_FIELD';
export const changeSubField = createAction<
    IAddSubFieldPayload,
    FormName,
    string,
    string,
    any
>(
    CHANGE_SUB_FIELD,
    (formName: FormName, fieldName: string, key: string, value: any) => ({
        formName,
        fieldName,
        value,
        key,
    })
);

export interface ISendFormPayload {
    formName: FormName;
    data: any;
}
export const sendForm = createAction<ISendFormPayload, FormName, any>(
    'SEND_FORM',
    (formName: FormName, data?: any) => ({ formName, data })
);
