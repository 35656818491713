import { Action, handleAction } from 'redux-actions';
import keyBy from 'lodash/fp/keyBy';
import map from 'lodash/fp/map';
import flow from 'lodash/fp/flow';

import {
    ISetSearchResultsPayload,
    setSearchResults,
} from 'app/redux/actions/search/results';
import { replaceDotsWithDashes } from 'app/utils/filters/filterNamesEscape';
import * as Store from 'app/redux/store/StoreNamespace';

export default handleAction(
    setSearchResults.toString(),
    (
        _,
        { payload }: Action<ISetSearchResultsPayload>
    ): Record<string, Store.IFilterVisibility> => {
        if (Array.isArray(payload.dynamicFilterVisible)) {
            return flow(
                map((filter: Store.IFilterVisibility) => ({
                    ...filter,
                    code: replaceDotsWithDashes(filter.code),
                })),
                keyBy(({ code }) => code)
            )(payload.dynamicFilterVisible);
        }
        return null;
    },
    null
);
