import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { usePrevious } from 'react-use';

import * as Store from 'app/redux/store/StoreNamespace';
import { IConceptWithChildren } from 'app/redux/store/StoreNamespace';
import { getTitleFromLabels } from 'app/utils/concepts';

import styles from './ConceptsPanel.scss';
import ConceptOption from './ConceptOption';
import ConceptOptionGroup from './ConceptOprionGroup';

import {
    ISelectOption,
    List,
} from '../../../../../modules/components/TreeSelect';
import { noop } from '../../../../../utils/noop';
import Concept from '../../../../common/Concept';
import { compareSelectOptionsAlphabetically } from '../../../../../utils/compareSelectOptionsAlphabetically';

const enum Tabs {
    Hierarchy,
    Related,
}

interface IProps {
    concept: Store.ISchemeConcept;
    conceptsTree: Store.ICardRelatedConceptTree | undefined;
    conceptDescriptions: Record<string, Store.IConceptDescription>;
    onConceptSelected(conceptUri: string): void;
}

const ConceptsPanel: React.FC<IProps> = (props) => {
    const { concept, conceptsTree, onConceptSelected, conceptDescriptions } =
        props;

    const [activeTab, setActiveTab] = useState(Tabs.Hierarchy);
    const prevActiveTab = usePrevious(activeTab);

    const [viewedConceptUri, setViewedConceptUri] = useState(concept.uri);
    const prevConcept = usePrevious(concept);

    const conceptRelatedList = conceptDescriptions[concept.uri]?.related;
    const prevConceptRelatedList = usePrevious(conceptRelatedList);

    const getDefaultViewedConcept = (): string => {
        if (activeTab === Tabs.Hierarchy) {
            return concept.uri;
        }
        if (activeTab === Tabs.Related) {
            return conceptRelatedList?.[0]?.uri;
        }
    };
    const defaultViewedConcept = getDefaultViewedConcept();

    if (
        activeTab === Tabs.Hierarchy &&
        concept !== prevConcept &&
        viewedConceptUri !== defaultViewedConcept
    ) {
        setViewedConceptUri(defaultViewedConcept);
    }
    if (
        activeTab === Tabs.Related &&
        conceptRelatedList !== prevConceptRelatedList &&
        viewedConceptUri !== defaultViewedConcept
    ) {
        setViewedConceptUri(defaultViewedConcept);
    }
    if (
        prevActiveTab !== activeTab &&
        viewedConceptUri !== defaultViewedConcept
    ) {
        setViewedConceptUri(defaultViewedConcept);
    }

    const _onConceptSelected = useCallback(
        (option: ISelectOption) => {
            setViewedConceptUri(option.value);
        },
        [setViewedConceptUri]
    );

    useEffect(() => {
        viewedConceptUri && onConceptSelected(viewedConceptUri);
    }, [viewedConceptUri, onConceptSelected]);

    const conceptHierarchyAsOptions: ISelectOption[] = useMemo(() => {
        if (!conceptsTree) {
            return [];
        }

        const conceptsToOptions = (
            _concept: IConceptWithChildren
        ): ISelectOption => {
            return {
                label: _concept.label,
                value: _concept.uri,
                options: _concept.children?.map((childUri) =>
                    conceptsToOptions(conceptsTree.concepts[childUri])
                ),
            };
        };

        const options = conceptsTree.topConceptList.map((conceptUri) =>
            conceptsToOptions(conceptsTree.concepts[conceptUri])
        );

        return options;
    }, [conceptsTree]);

    const getOptionComponentProps = useCallback(
        (option: ISelectOption) => {
            return {
                className:
                    option.value === concept.uri
                        ? styles.cardConceptsPanelConceptOptionMain
                        : '',
            };
        },
        [concept]
    );

    return (
        <div className={styles.cardConceptsPanel}>
            <div className={styles.cardConceptsPanelTabs}>
                <button
                    className={classNames('btn', styles.cardConceptsPanelTab, {
                        [styles.cardConceptsPanelTabActive]:
                            activeTab === Tabs.Hierarchy,
                    })}
                    onClick={() => setActiveTab(Tabs.Hierarchy)}
                >
                    Иерархия концепта
                </button>
                <button
                    className={classNames('btn', styles.cardConceptsPanelTab, {
                        [styles.cardConceptsPanelTabActive]:
                            activeTab === Tabs.Related,
                    })}
                    onClick={() => setActiveTab(Tabs.Related)}
                >
                    Похожие концепты
                </button>
            </div>
            <div className={styles.cardConceptsPanelPanelsSplitContainer}>
                <div className={styles.cardConceptsPanelLeftPanel}>
                    {activeTab === Tabs.Hierarchy && (
                        <List
                            options={conceptHierarchyAsOptions}
                            OptionComponent={ConceptOption}
                            OptionGroupComponent={ConceptOptionGroup}
                            optionComponentProps={getOptionComponentProps}
                            optionGroupComponentProps={getOptionComponentProps}
                            active={[viewedConceptUri]}
                            onSelect={_onConceptSelected}
                            onDeselect={noop}
                            className={styles.cardConceptsPanelList}
                            initiallyOpenedNestedLevel={Number.MAX_SAFE_INTEGER}
                            compareOptions={compareSelectOptionsAlphabetically}
                        />
                    )}
                    {activeTab === Tabs.Related && (
                        <div>
                            {conceptRelatedList &&
                                conceptRelatedList.map((related) => (
                                    <Concept
                                        key={related.uri}
                                        className={classNames(
                                            styles.cardConceptsPanelRelatedConcept,
                                            {
                                                [styles.cardConceptsPanelRelatedConceptSelected]:
                                                    related.uri ===
                                                    viewedConceptUri,
                                            }
                                        )}
                                        bodyClassName={
                                            styles.cardConceptsPanelRelatedConceptBody
                                        }
                                        titleClassName={
                                            styles.cardConceptsPanelRelatedConceptTitle
                                        }
                                        title={related.label}
                                        schemeTitle={concept.inScheme.uri}
                                        onClick={() =>
                                            setViewedConceptUri(related.uri)
                                        }
                                    />
                                ))}
                        </div>
                    )}
                </div>
                <div className={styles.cardConceptsPanelRightPanel}>
                    {conceptDescriptions?.[viewedConceptUri] && (
                        <div>
                            <div
                                className={styles.cardConceptsPanelConceptTitle}
                            >
                                {getTitleFromLabels(
                                    conceptDescriptions[viewedConceptUri]
                                        .prefLabels
                                )}
                            </div>
                            <div
                                className={
                                    styles.cardConceptsPanelDescriptionSection
                                }
                            >
                                <div
                                    className={
                                        styles.cardConceptsPanelDescriptionSectionTitle
                                    }
                                >
                                    Библиотека концепта
                                </div>
                                <div
                                    className={
                                        styles.cardConceptsPanelDescriptionSectionBody
                                    }
                                >
                                    {concept.inScheme.label}
                                </div>
                            </div>
                            <div
                                className={
                                    styles.cardConceptsPanelDescriptionSection
                                }
                            >
                                <div
                                    className={
                                        styles.cardConceptsPanelDescriptionSectionTitle
                                    }
                                >
                                    Альтернативные названия
                                </div>
                                <div
                                    className={
                                        styles.cardConceptsPanelDescriptionSectionBody
                                    }
                                >
                                    {conceptDescriptions[
                                        viewedConceptUri
                                    ].altLabels.map((label) => (
                                        <div
                                            key={label.value}
                                            className={
                                                styles.cardConceptsPanelDescriptionSectionBodyPiece
                                            }
                                        >
                                            {label.value}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div
                                className={
                                    styles.cardConceptsPanelDescriptionSection
                                }
                            >
                                <div
                                    className={
                                        styles.cardConceptsPanelDescriptionSectionTitle
                                    }
                                >
                                    Определение
                                </div>
                                <div
                                    className={
                                        styles.cardConceptsPanelDescriptionSectionBody
                                    }
                                >
                                    {conceptDescriptions[
                                        viewedConceptUri
                                    ].definition.map((label) => (
                                        <div
                                            key={label.value}
                                            className={
                                                styles.cardConceptsPanelDescriptionSectionBodyPiece
                                            }
                                        >
                                            {label.value}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ConceptsPanel;
