/**
 * Created by Lkarmelo on 16.11.2017.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autoBind from 'autobind-decorator';
import Modal from 'antd/es/modal/Modal';

import { removeLoadingError } from 'app/redux/actions/loading';
import { errorsShownInMainErrorComponent } from 'app/redux/selectors/errorsShownInMainErrorComponent';
import * as Store from 'app/redux/store/StoreNamespace';

import IProps, {
    ILoadingErrorStateProps as IStateProps,
    ILoadingErrorActionProps as IActionProps,
} from './interfaces/ILoadingErrorProps';
import * as styles from './LoadingError.scss';

class LoadingError extends Component<IProps> {
    shownRequestErrors: string[] = [];

    onCloseAction: () => void;

    render(): JSX.Element {
        const { errors } = this.props;
        const messages = [];
        const errorKeys = Object.keys(errors);
        const isVisible = errorKeys.length > 0;
        let btnTitle;
        let additionalClass;
        let closeByBtnClickOnly = true;
        this.shownRequestErrors = [];

        if (isVisible) {
            const requestName = errorKeys[0];
            messages.push(errors[requestName].message.split('/n'));
            btnTitle = errors[requestName].btnTitle;
            additionalClass = requestName;
            this.onCloseAction = errors[requestName].onCloseAction;
            closeByBtnClickOnly = !!errors[requestName].closeByBtnClickOnly;
            this.shownRequestErrors.push(requestName);
        }

        return (
            <Modal
                visible={isVisible}
                footer={null}
                className={`${styles.loadingError} modal`}
                onCancel={this.onOkClick}
                zIndex={50000}
                closable={!closeByBtnClickOnly}
            >
                <div className={styles.loadingErrorMessage}>
                    {messages.map((message) => (
                        <div key={JSON.stringify(message)}>
                            {message.map((messageParagraph) => (
                                <p key={JSON.stringify(messageParagraph)}>
                                    {messageParagraph}
                                </p>
                            ))}
                        </div>
                    ))}
                </div>
                <button
                    autoFocus
                    onClick={this.onOkClick}
                    className={`btn-primary btn-search btn-search__${additionalClass}`}
                >
                    {btnTitle}
                </button>
            </Modal>
        );
    }

    @autoBind
    onOkClick(): void {
        this.onCloseAction && this.onCloseAction();
        this.props.onOkClick(this.shownRequestErrors);
    }
}

const mapStateToProps = (state: Store.IState): IStateProps => ({
    errors: errorsShownInMainErrorComponent(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>): IActionProps =>
    bindActionCreators({ onOkClick: removeLoadingError }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoadingError);
