/**
 * Created by lkarmelo on 28.11.2019.
 */

import React, { useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { FormApi } from 'final-form';
import { Form, Field } from 'react-final-form';

import Modal from 'app/components/Modals/Modal';
import { HierarchicalSelectAdapter } from 'app/components/common/controls/HierarchicalSelect';
import { useFormSenderHelper } from 'app/hooks/formHelpers';

import IProps from './interfaces/IChangeDocumentCategoryProps';
import * as styles from './ChangeDocumentCategory.scss';

const ChangeDocumentCategory: React.FC<IProps> = (props) => {
    const {
        isVisible,
        close,
        formStatus,
        sendForm,
        resetForm,
        onSuccess,
        categories,
    } = props;

    const formRef = useRef<FormApi>(null);

    const resetFormState = useCallback(() => {
        formRef.current && formRef.current.reset();
    }, []);

    const cbMap = useMemo(() => ({ onSuccess }), [onSuccess]);
    const cbRef = useFormSenderHelper(formStatus, resetForm, cbMap);

    const onSubmit = useCallback(
        (values, _, cb) => {
            cbRef.current = cb;
            sendForm(values.newCategory ? values.newCategory.value : '');
        },
        [sendForm]
    );

    return (
        <Modal
            visible={isVisible}
            maskClosable={false}
            showCross={false}
            afterClose={resetFormState}
            className={classNames(styles.changeDocumentCategory)}
        >
            <div>
                <div className={styles.changeDocumentCategoryTitle}>
                    Выберите новую категорию для документа
                </div>
                <Form onSubmit={onSubmit}>
                    {({ handleSubmit, form, submitting }) => {
                        formRef.current = form;
                        return (
                            <form
                                onSubmit={handleSubmit}
                                className={styles.changeDocumentCategoryForm}
                            >
                                <div>
                                    <Field
                                        name="newCategory"
                                        initialValue={
                                            categories
                                                ? categories[0]
                                                : undefined
                                        }
                                    >
                                        {({ input }) => (
                                            <HierarchicalSelectAdapter
                                                {...input}
                                                options={categories}
                                                disallowEmpty
                                            />
                                        )}
                                    </Field>
                                </div>
                                <div
                                    className={
                                        styles.changeDocumentCategoryControls
                                    }
                                >
                                    <button
                                        disabled={submitting}
                                        className={`btn ${styles.changeDocumentCategoryRemoveBtn}`}
                                    >
                                        Изменить категорию
                                    </button>
                                    <button
                                        disabled={submitting}
                                        type="button"
                                        className={`btn ${styles.changeDocumentCategoryCloseBtn}`}
                                        onClick={close}
                                    >
                                        Отмена
                                    </button>
                                </div>
                            </form>
                        );
                    }}
                </Form>
            </div>
        </Modal>
    );
};

export default ChangeDocumentCategory;
