/**
 * Created by lkarmelo on 24.04.2019.
 */
import { createSelector } from 'reselect';

import * as Store from 'app/redux/store/StoreNamespace';
import {
    isQueryNode,
    traverseTerms,
    traversTags,
} from 'app/utils/expansionUtils';

// можно использовать только 1 селектор, потому что расширения используются только на странице поиска
export const selectedExpansionTagsSelector = createSelector(
    (state: Store.IState) => state.search.queryTags,
    (state: Store.IState) => state.search.selectedQueryTags,
    (
        queryTags,
        selectedQueryTags
    ): Store.IQueryNode | Store.IQueryTerm | undefined => {
        if (!queryTags) {
            return undefined;
        }
        const expansion = traversTags(queryTags, (term) => ({
            term: term.term,
            expansion: term.expansion.filter((item) =>
                selectedQueryTags.includes(item.lemmaId)
            ),
        }));
        if (!expansion || !expansion.tags) {
            return undefined;
        }
        return isQueryNode(expansion.tags)
            ? expansion.tags
            : expansion.tags.term && expansion.tags.expansion.length > 0
            ? expansion.tags
            : undefined;
    }
);

export const selectedExpansionTagRawSelector = createSelector(
    selectedExpansionTagsSelector,
    (tags: Store.IQueryNode | Store.IQueryTerm): string[] => {
        const rawSelectedExpansionTags: string[] = [];
        if (!tags) {
            return rawSelectedExpansionTags;
        }
        traverseTerms(tags, (term) => {
            term.expansion.forEach(
                (exp) =>
                    !rawSelectedExpansionTags.includes(exp.raw) &&
                    rawSelectedExpansionTags.push(exp.raw)
            );
        });
        return rawSelectedExpansionTags;
    }
);
