/**
 * Created by Lkarmelo on 23.08.2017.
 */

import { handleActions, Action } from 'redux-actions';

import * as Store from 'app/redux/store/StoreNamespace';
import {
    SET_AVATARS_HINTS,
    SET_SEARCH_HINTS,
} from 'app/redux/actions/search/searchHints';
import { replaceDotsWithDashes } from 'app/utils/filters/filterNamesEscape';

import store from '../../store/store';
import { EMPLOYEES_TAB_CODE } from '../../../utils/constants';
import apiRoutes from '../../../routing/apiRoutes';
import { bodyAvatar } from '../../../utils/bodyAvatar';

// заменяем в названиях фильтров точки на тире
const replaceFilterHintCode = (hint: Store.IQueryHint): Store.IQueryHint => {
    if (!hint.filter) {
        return hint;
    }
    return {
        ...hint,
        filter: {
            ...hint.filter,
            code: replaceDotsWithDashes(hint.filter.code),
        },
    };
};
const getStore = (): any => {
    return store.getState();
};

export default handleActions<Partial<Store.ISearchHint>, any>(
    {
        [SET_SEARCH_HINTS](
            state: Store.ISearchHint,
            { payload: { queries, persons } }: Action<Store.ISearchHint>
        ) {
            const queryHints =
                Array.isArray(queries) && queries.map(replaceFilterHintCode);
            const lemma = queryHints.filter(
                (sour, index) => sour.source?.type === 'lemma'
            );
            const title = queryHints.filter(
                (sour, index) => sour.source?.type === 'title'
            );
            const employee = queryHints.filter(
                (sour, index) => sour.source?.type === 'employee'
            );

            const mainSearchPageActiveTab = getStore().mainSearchPageActiveTab;
            const haveAlias = getStore().search?.lastExecutedSearchAlias;

            let result = [];
            if (haveAlias) {
                if (mainSearchPageActiveTab === EMPLOYEES_TAB_CODE) {
                    result = [...employee.slice(0, 7)];
                } else {
                    result = [
                        ...lemma.slice(0, 7),
                        ...employee.slice(0, 3),
                        ...title.slice(0, 3),
                    ];
                }
            } else {
                result = [
                    ...lemma.slice(0, 3),
                    ...employee.slice(0, 3),
                    ...title.slice(0, 3),
                ];
            }
            return <Store.ISearchHint>{
                persons,
                queries: result,
            };
        },
        [SET_AVATARS_HINTS](
            state: Store.ISearchHint,
            { payload }: Action<any>
        ) {
            const nextState = { ...state };
            if (payload && nextState.queries.length) {
                nextState.queries = nextState.queries.map((query) => {
                    if (
                        query.person &&
                        query.person.userId &&
                        payload.filter(
                            (val) => val.value === query.person.userId
                        ).length === 1
                    ) {
                        query.person.avatar = apiRoutes.getUserAvatar.getUrl(
                            bodyAvatar({
                                userId: query.person.userId,
                                resolution: 50,
                                needCache: true,
                            })
                        );
                        return query;
                    }
                    return query;
                });
            }
            return nextState;
        },
    },
    {} // - default value for searchHints
);
