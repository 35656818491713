/**
 * Created by Lkarmelo on 23.08.2017.
 */

import { handleActions, combineActions } from 'redux-actions';

import initialState from '../store/initialState';
import * as Store from '../store/StoreNamespace';
import {
    SHOW_LOADING_ERROR,
    REMOVE_LOADING_ERROR,
    loadingShowActionCreators,
    loadingHideActionCreators,
    ILoadingActionMeta,
    IRemoveLoadingErrorPayload,
    IShowLoadingErrorPayload,
} from '../actions/loading';

const getPendingRequestsCount = (state: Store.ILoadingInfo) =>
    Object.entries(state.pendingRequests).filter(([_, isPending]) => isPending)
        .length;

export default handleActions<
    Store.ILoadingInfo,
    IShowLoadingErrorPayload | IShowLoadingErrorPayload,
    ILoadingActionMeta | undefined
>(
    {
        [combineActions(...loadingShowActionCreators).toString()](
            state: Store.ILoadingInfo,
            { meta }: { meta: ILoadingActionMeta }
        ) {
            if (state.pendingRequests[meta.requestName]) {
                return state;
            }
            const nextState: Store.ILoadingInfo = {
                ...state,
                pendingRequests: {
                    ...state.pendingRequests,
                    [meta.requestName]: true,
                },
            };
            nextState.pendingRequestsCount = getPendingRequestsCount(nextState);
            return nextState;
        },
        [SHOW_LOADING_ERROR](
            state: Store.ILoadingInfo,
            { payload }: { payload: ILoadingActionMeta; meta: undefined }
        ) {
            const nextState: Store.ILoadingInfo = {
                ...state,
                loadingErrors: {
                    ...state.loadingErrors,
                    [payload.requestName]: {
                        message: payload.errorMessage,
                        showInMainErrorComponent:
                            payload.showErrorInMainErrorComponent,
                        btnTitle: payload.btnTitle,
                        closeByBtnClickOnly: payload.closeByBtnClickOnly,
                        onCloseAction: payload.onCloseAction,
                    },
                },
            };

            return nextState;
        },
        [combineActions(...loadingHideActionCreators).toString()](
            state: Store.ILoadingInfo,
            { meta }: { error?: boolean; meta: ILoadingActionMeta }
        ) {
            if (!state.pendingRequests[meta.requestName]) {
                return state;
            }
            const nextState = <Store.ILoadingInfo>{
                ...state,
                pendingRequests: {
                    ...state.pendingRequests,
                    [meta.requestName]: false,
                },
            };
            nextState.pendingRequestsCount = getPendingRequestsCount(nextState);
            return nextState;
        },

        [REMOVE_LOADING_ERROR](
            state: Store.ILoadingInfo,
            {
                payload,
            }: { payload: IRemoveLoadingErrorPayload; meta: undefined }
        ) {
            const nextState: Store.ILoadingInfo = {
                ...state,
                loadingErrors: {
                    ...state.loadingErrors,
                },
            };

            payload.requestNames.forEach((requestName) => {
                delete nextState.loadingErrors[requestName];
            });

            return nextState;
        },
    },
    initialState.loading // - default value for loading
);
