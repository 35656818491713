import React, { ReactNode, useCallback, useState } from 'react';
import classNames from 'classnames';

import Collapse from 'app/components/common/utils/Collapse';

import styles from '../Card/Card.scss';

export interface IDocumentAttributes {
    code: string;
    title?: string;
    renderedValue: ReactNode;
}

export interface ICollapseDocumentAttributesProps {
    title: string;
    attributes: IDocumentAttributes[];
}

const CollapseDocumentAttributes: React.FC<
    ICollapseDocumentAttributesProps
> = ({ attributes, title }) => {
    const [isOpen, setIsOpen] = useState(true);
    const onToggleClick = useCallback(() => {
        setIsOpen((state) => !state);
    }, []);

    return (
        <>
            <button
                className={classNames(
                    'btn',
                    styles.cardSectionTitle,
                    styles.cardSectionOpenTitle,
                    {
                        [styles.cardSectionOpenTitleOpened]: isOpen,
                    }
                )}
                onClick={onToggleClick}
                tabIndex={0}
            >
                {title}
                <span className={styles.cardSectionOpenTitleArrow} />
            </button>
            <Collapse
                isOpen={isOpen}
                className={styles.cardAttrsSectionCollapse}
            >
                <ul className={styles.cardAttrs}>
                    {attributes.map((attr) => (
                        <li key={attr.code} className={styles.cardAttr}>
                            <div className={styles.cardAttrTitle}>
                                {attr.title}
                            </div>
                            <div>{attr.renderedValue}</div>
                        </li>
                    ))}
                </ul>
            </Collapse>
        </>
    );
};

export default CollapseDocumentAttributes;
