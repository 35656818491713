/**
 * Created by lkarmelo on 03.09.2018.
 */

import { connect } from 'react-redux';

import { ApproveAction } from 'app/components/Modals/index';
import { initForm, removeForm, sendForm } from 'app/redux/actions/forms';
import { fetchRecommendations } from 'app/redux/actions/recommendations';
import {
    IApproveActionActionProps as IActionProps,
    IApproveActionOwnProps as IOwnProps,
    IApproveActionStateProps as IStateProps,
} from 'app/components/Modals/ApproveAction/interfaces/IApproveActionProps';
import * as Store from 'app/redux/store/StoreNamespace';
import { withContext } from 'app/redux/context/connectWithContext';
import { FormName } from 'app/api/FormName';

import IFormData from './interfaces/IFormData';

interface IAdditionalProps {
    recommendationId: string;
    profileId: string;
    documentTitle: string;
    userName: string;
}

const mapStateToProps = (
    state: Store.IState,
    ownProps: IOwnProps<IAdditionalProps> & IAdditionalProps
): IOwnProps<IAdditionalProps> & IStateProps & IAdditionalProps => {
    return {
        status:
            state.forms[FormName.DeleteRecommendation] &&
            state.forms[FormName.DeleteRecommendation].status,
        isVisible: ownProps.isVisible,
        recommendationId: ownProps.recommendationId,
        profileId: ownProps.profileId,
        onCancel: ownProps.onCancel,
        successMsgText: 'Рекомендация успешно удалена',
        renderBody: ownProps.renderBody,
        documentTitle: ownProps.documentTitle,
        userName: ownProps.userName,
    };
};

const mapDispatchToProps = (dispatch): IActionProps<IAdditionalProps> => ({
    onShown() {
        dispatch(initForm(FormName.DeleteRecommendation, null));
    },
    onHidden() {
        dispatch(removeForm(FormName.DeleteRecommendation));
    },
    onSend({ recommendationId, profileId }: IAdditionalProps) {
        dispatch(
            sendForm(FormName.DeleteRecommendation, <IFormData>{
                recommendationId,
                profileId,
            })
        );
    },
    onSentSuccess() {
        dispatch(
            withContext(fetchRecommendations(), Store.RecommendationsContext)
        );
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApproveAction as any);
