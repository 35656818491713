/**
 * Created by Lkarmelo on 23.08.2017
 * -----
 * Last Modified: 07.02.2018 18:42:03
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router } from 'react-router-dom';
import LoadingBar from 'react-redux-loading-bar';
import autoBind from 'autobind-decorator';
import memoizeOne from 'memoize-one';
import { Observable } from 'rxjs/Observable';
import { ResizeContext } from '@nkc-frontend/nkc-react-hooks';

import { LoadingError, Report } from 'app/components/Modals';
import history from 'app/history';
import Routes from 'app/routing/Routes';
import { fetchUserData } from 'app/redux/actions/user';
import { fetchSearchTabsSimple } from 'app/redux/actions/search/searchTabs';
import './Root.scss';
import {
    setNotificationsIntervalId,
    startNotificationsPolling,
} from 'app/redux/actions/notification';
import {
    FileStateEnum,
    NotificationContext,
} from 'app/redux/store/StoreNamespace';
import * as Store from 'app/redux/store/StoreNamespace';
import { hasRolesSelector } from 'app/redux/selectors/hasRoles';

import IProps from './interfaces/IRootProps';

class Root extends Component<IProps> {
    windowResizeObserver: Observable<unknown> = null;

    render(): JSX.Element {
        return (
            <div className="root">
                <LoadingError />
                <Report />
                <LoadingBar className="loading-bar" progressIncrease={5} />
                <ResizeContext.Provider value={this.windowResizeObserver}>
                    <div className="root__router">
                        <Router history={history}>
                            <div className="root__app-content">
                                <div className="root__route">{Routes}</div>
                            </div>
                        </Router>
                    </div>
                </ResizeContext.Provider>
            </div>
        );
    }

    onActivePage(): void {
        const { dispatch, isUserAuthorized, notificationsIntervalId } =
            this.props;
        if (isUserAuthorized && notificationsIntervalId === -1) {
            dispatch(startNotificationsPolling());
        }
    }

    onAnInactivePage(): void {
        const { dispatch, notificationsIntervalId } = this.props;
        if (notificationsIntervalId !== -1) {
            clearTimeout(notificationsIntervalId);
            dispatch(setNotificationsIntervalId(-1));
        }
    }

    @autoBind
    visibilityChange(): void {
        if (document.hidden) {
            this.onAnInactivePage();
        } else {
            this.onActivePage();
        }
    }

    componentWillMount(): void {
        const { dispatch } = this.props;
        dispatch(fetchUserData());
    }

    componentDidUpdate(prevProps: Readonly<IProps>): void {
        const { dispatch, hasRoles } = this.props;
        if (hasRoles && !prevProps.hasRoles) {
            dispatch(startNotificationsPolling());
            dispatch(fetchSearchTabsSimple());
        }
    }

    componentDidMount(): void {
        window.addEventListener('beforeunload', this.onUnload);
        document.addEventListener('visibilitychange', this.visibilityChange);
        this.createResizeObservable();
    }

    componentWillUnmount(): void {
        window.removeEventListener('beforeunload', this.onUnload);
        document.removeEventListener('visibilitychange', this.visibilityChange);
    }

    createResizeObservable(): void {
        this.windowResizeObserver = Observable.fromEvent(window, 'resize')
            .debounceTime(300)
            .share();
    }

    @autoBind
    onUnload(e: BeforeUnloadEvent) {
        const { reloadConfirmationMessage } = this.props;

        if (!reloadConfirmationMessage) {
            return;
        }

        e.returnValue = reloadConfirmationMessage; // Gecko, Trident, Chrome 34+
        return reloadConfirmationMessage; // Gecko, WebKit, Chrome <34
    }
}

const mapStateToProps = () => {
    const isFileInProgress = memoizeOne((fileStore: Store.IFileStore) => {
        const { files } = fileStore;

        return (
            Object.keys(files).findIndex(
                (key) =>
                    files[key].status === FileStateEnum.Uploading ||
                    files[key].status === FileStateEnum.TmpFile
            ) !== -1
        );
    });

    return (state: Store.IState): IProps => {
        const reloadConfirmationMessage = isFileInProgress(state.fileStore)
            ? 'Файлы загружаются, дождитесь завершения загрузки'
            : '';

        // @ts-ignore
        return {
            reloadConfirmationMessage,
            hasRoles: hasRolesSelector(state),
            isUserAuthorized: state.authorization.isAuthorized,
            notificationsIntervalId:
                state[NotificationContext].notificationsIntervalId,
        };
    };
};

export default connect(mapStateToProps)(Root);
