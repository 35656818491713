import * as React from 'react';
import classNames from 'classnames';

import styles from '../../PersonCard.scss';

export interface IUserDataItemProps {
    itemKey: string;
    primary?: boolean;
    className?: string;
}

const UserDataItem: React.FC<IUserDataItemProps> = ({
    itemKey,
    primary,
    className,
    children,
}) => {
    return children ? (
        <div className={classNames(styles.personCardUserDataItem, className)}>
            <dt className={styles.personCardUserDataKey}>{itemKey}</dt>
            <dd
                className={classNames(styles.personCardUserDataValue, {
                    [styles.personCardUserDataValuePrimary]: primary,
                })}
            >
                {children}
            </dd>
        </div>
    ) : null;
};

export default UserDataItem;
