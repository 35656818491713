/*
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 08.06.2018
 * -----
 * Last Modified: 21.06.2018 14:23:10
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import React, {
    PureComponent,
    MouseEvent as ReactMouseEvent,
    KeyboardEvent,
} from 'react';
import classNames from 'classnames';
import autoBind from 'autobind-decorator';
import { createSelector } from 'reselect';

import { IOption } from 'app/components/common/controls/Option';
import { highlightFunction } from 'app/redux/epics/highlight';

import TreeSelectList from './TreeSelectList/TreeSelectList';
import IProps, { TitleType } from './interfaces/ITreeSelectProps';
import IState from './interfaces/ITreeSelectState';
import * as styles from './TreeSelect.scss';

@autoBind
export default class TreeSelect extends PureComponent<IProps, IState> {
    static defaultProps = {
        options: [],
        titlePrefix: '',
        initiallyExpandedDepth: 1,
        optionRenderer: (value: string, label: string, meta: any) => {
            const { description } = meta;

            return (
                <div className="tree-select-list__option-content-wrapper">
                    {/* eslint-disable-next-line react/no-danger */}
                    <span dangerouslySetInnerHTML={{ __html: label }} />
                    {!!description && (
                        <div className="tree-select-list__option-content-description">
                            {description}
                        </div>
                    )}
                </div>
            );
        },
    };

    state: IState = {
        isOpened: false,
        isUserTyping: false,
        searchQuery: '',
    };

    isClicked = false;

    treeSelectListRef: React.RefObject<typeof TreeSelectList.prototype> =
        React.createRef();

    inputRef: React.RefObject<HTMLInputElement> = React.createRef();

    selfRef: React.RefObject<HTMLDivElement> = React.createRef();

    findTitle = createSelector(
        (props: IProps) => props.options,
        (props: IProps) => props.active,
        (props: IProps) => props.title,
        (props: IProps) => props.placeholder,
        (props: IProps) => props.isMultiSelect,
        (props: IProps) => props.titlePrefix,
        (
            options: IOption[],
            active: string | string[],
            title: TitleType,
            placeholder: string,
            isMultiSelect: boolean,
            titlePrefix: string
        ): TitleType => {
            if (title) {
                return title;
            }

            if (!active || active.length < 1) {
                return placeholder;
            }
            if (isMultiSelect) {
                const labels = [];
                active &&
                    (active as string[]).forEach((val) => {
                        const foundOption = options.find(
                            (opt) => val === opt.value
                        );
                        foundOption && labels.push(foundOption.label);
                    });

                return (
                    <>
                        <div
                            title={placeholder}
                            className={styles.searchSelectInputTitleFilterTitle}
                        >
                            {placeholder}
                        </div>
                        <div className={styles.searchSelectInputTitleCount}>
                            : {labels.length}
                        </div>
                    </>
                );
            }
            const foundOption = options.find((opt) => opt.value === active);
            return `${titlePrefix}${foundOption ? foundOption.label : ''}`;
        }
    );

    filterOptions = createSelector(
        ({ props }: { state: IState; props: IProps }) => props.options,
        ({ state }: { state: IState; props: IProps }) => state.searchQuery,
        (options: IOption[], searchQuery: string): IOption[] => {
            if (!searchQuery) {
                return options;
            }
            return options
                .filter(
                    (opt) =>
                        opt.label
                            .toLowerCase()
                            .indexOf(searchQuery.toLowerCase()) >= 0
                )
                .map((opt) => ({
                    ...opt,
                    label: highlightFunction(
                        searchQuery.toLowerCase(),
                        '<span class="highlight-search">',
                        '</span>'
                    )(opt.label),
                }))
                .sort((optA, optB) => {
                    const queryIndexA = optA.label.indexOf(searchQuery);
                    const queryIndexB = optB.label.indexOf(searchQuery);
                    if (queryIndexA < queryIndexB) {
                        return -1;
                    }
                    if (queryIndexA > queryIndexB) {
                        return 1;
                    }
                    return 0;
                });
        }
    );

    render(): JSX.Element {
        const {
            options,
            active,
            disabled,
            focusable,
            isFilterable,
            initiallyExpandedDepth,
            optionRenderer,
        } = this.props;
        const { searchQuery, isUserTyping } = this.state;

        const title = this.findTitle(this.props);
        return (
            <div
                ref={this.selfRef}
                onClick={isFilterable ? undefined : this.toggleOpenState}
                className={classNames('select', {
                    select_opened: this.state.isOpened,
                    select_disabled: disabled,
                    select_filterable: isFilterable,
                })}
                // onMouseDown={(e) => {e.stopPropagation()}}//this.setClickedTrue}
                // onMouseUp={this.setClickedFalse}
                tabIndex={isFilterable && !disabled ? 1 : undefined}
                onBlur={this.onBlur}
            >
                {isFilterable ? (
                    <div
                        className="search-select__input-title-wrapper"
                        onClick={this.onInputTitleWrapperClick}
                    >
                        {searchQuery === '' && (
                            <span
                                className={classNames(
                                    'search-select__input-title',
                                    {
                                        'search-select__input-title--typing':
                                            isUserTyping,
                                    }
                                )}
                                title={
                                    typeof title === 'string'
                                        ? title
                                        : undefined
                                }
                            >
                                {title}
                            </span>
                        )}
                        <input
                            className={classNames(
                                {
                                    'search-select__input-display':
                                        !!active && active.length > 0,
                                },
                                'search-select__input'
                            )}
                            ref={this.inputRef}
                            type="text"
                            value={searchQuery}
                            onChange={this.onInputChange}
                            onFocus={this.onInputFocus}
                            disabled={disabled}
                            onKeyDown={this.onInputKeyDown}
                        />
                        <span className="select__arrow" />
                    </div>
                ) : (
                    <div
                        className="select__field"
                        tabIndex={focusable ? 0 : null}
                        onKeyDown={this.onFieldKeyDown}
                    >
                        <span
                            className={classNames('select__field-title', {
                                select__placeholder:
                                    !active || active.length < 1,
                            })}
                        >
                            {title}
                        </span>
                        <span className="select__arrow" />
                    </div>
                )}
                <TreeSelectList
                    ref={this.treeSelectListRef}
                    options={
                        isFilterable
                            ? this.filterOptions({
                                  props: this.props,
                                  state: this.state,
                              })
                            : options
                    }
                    className="tree-select-list"
                    active={active}
                    isOptionActive={this.isOptionActive}
                    initiallyExpandedDepth={initiallyExpandedDepth}
                    isOpened={this.state.isOpened}
                    isClear={searchQuery === ''}
                    onBlur={isFilterable ? undefined : this.onListBlur}
                    onCloseClick={this.closePopup}
                    onSelectOption={this.onSelectOption}
                    onDeselectOption={this.onDeselectOption}
                    onDeselectAllOptions={this.onDeselectAllOptions}
                    onClear={this.onClear}
                    optionRenderer={optionRenderer}
                />
            </div>
        );
    }

    closePopup(e: MouseEvent): void {
        if (!this.selfRef.current.contains(e.target as HTMLElement)) {
            this.setState({
                isOpened: false,
                searchQuery: '',
                isUserTyping: false,
            });
        }
    }

    toggleOpenState(e: ReactMouseEvent<HTMLDivElement>): void {
        e.stopPropagation();
        !this.props.disabled &&
            this.setState((state) => ({ isOpened: !state.isOpened }));
    }

    setClickedTrue(): void {
        this.isClicked = true;
    }

    setClickedFalse(): void {
        this.isClicked = false;
    }

    onBlur(): void {
        // IE11 имеет баг с event.relatedTarget :))))) https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/101237/
        // setTimeout(
        //     () => {
        //         const relatedTarget: Element = document.activeElement;
        //         if (
        //             relatedTarget.parentElement !== this.dropDownListRef.optionListRef
        //             && relatedTarget !== this.inputRef
        //             && relatedTarget !== this.selfRef
        //         ) {
        //             !this.isClicked && this.setState({isUserTyping: false, searchQuery: '', isOpened: false});
        //         }
        //     },
        //     0
        // );
    }

    onInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const { onInputValueChange } = this.props;
        const inputValue = e.currentTarget.value;

        onInputValueChange && onInputValueChange(inputValue);
        this.setState({ searchQuery: inputValue, isOpened: true });
    }

    onInputFocus(): void {
        this.setState({ isUserTyping: true });
    }

    onInputTitleWrapperClick(): void {
        if (this.props.disabled) {
            return;
        }
        !this.state.isOpened && this.setState({ isOpened: true });
        this.inputRef.current.focus();
    }

    onInputKeyDown(e: KeyboardEvent<HTMLInputElement>): void {
        const input = e.currentTarget;
        if (e.keyCode === 40) {
            e.preventDefault();
            if (input.selectionStart === input.value.length) {
                this.openAndFocusFirstOption();
            } else {
                input.selectionStart = input.selectionEnd = input.value.length;
            }
        }
    }

    onFieldKeyDown(e: KeyboardEvent<HTMLDivElement>): void {
        if (e.keyCode === 40 || e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();
            this.openAndFocusFirstOption();
        }
    }

    openAndFocusFirstOption() {
        if (!this.state.isOpened) {
            this.setState(
                { isOpened: true },
                () => true // this.dropDownListRef && this.dropDownListRef.focusOption(0)
            );
        } else {
            // this.dropDownListRef && this.dropDownListRef.focusOption(0);
        }
    }

    onSelectOption(value: string, label: string, meta: any): void {
        const { isMultiSelect, filterName, onSelectOption } = this.props;
        if (!isMultiSelect) {
            this.setState({
                isOpened: false,
                isUserTyping: false,
                searchQuery: '',
            });
        }
        onSelectOption(filterName, value, meta, label);
    }

    onDeselectOption(value: string, label: string, meta: any): void {
        const { isMultiSelect, isClearable, onDeselectOption, filterName } =
            this.props;
        if (isMultiSelect || isClearable) {
            onDeselectOption &&
                onDeselectOption(filterName, value, meta, label);
        } else if (!isMultiSelect && !isClearable) {
            this.setState({
                isOpened: false,
                isUserTyping: false,
                searchQuery: '',
            });
        }
    }

    onDeselectAllOptions(): void {
        this.props.onDeselectAllOptions &&
            this.props.onDeselectAllOptions(this.props.filterName);
    }

    onClear(): void {
        this.setState({ searchQuery: '' });
    }

    isOptionActive(opt: IOption): boolean {
        const { isMultiSelect, active } = this.props;

        return isMultiSelect
            ? active && active.includes(opt.value)
            : active === opt.value;
    }

    onListBlur(): void {
        this.state.isOpened && this.setState({ isOpened: false });
    }
}
