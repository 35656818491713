/**
 * Created by Lkarmelo on 29.12.2017.
 */

import { handleActions } from 'redux-actions';

import { SET_CURRENT_USER_KNOWLEDGE_CATEGORIES } from '../actions/currentUserKnowledgeCategories';
import * as Store from '../store/StoreNamespace';

export default handleActions(
    {
        [SET_CURRENT_USER_KNOWLEDGE_CATEGORIES](
            state: Store.IKnowledgeCategory[],
            { payload }: { payload: Store.IKnowledgeCategory[]; type: string }
        ) {
            return payload;
        },
    },
    [] // - default value for userKnowledgeCategories
);
