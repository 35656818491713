/**
 * Created by lkarmelo on 15.07.2019.
 */

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as Store from 'app/redux/store/StoreNamespace';

import SearchLocationObserver from './SearchLocationObserver';
import { ISearchLocationObserverStateProps as IStateProps } from './interfaces/ISearchLocationObserverProps';

const mapStateToProps = (state: Store.IState): IStateProps => ({
    filtersMeta: state.filtersMeta,
});

export default withRouter(connect(mapStateToProps)(SearchLocationObserver));
