import { compose } from 'redux';

import { createFiltersToOptions } from '../../../../utils/filters/filtersToOptions';
import {
    createFilterOrderer,
    createFilterValuesNormalizer,
} from '../../../../utils/filters/createNormalizedFiltersSelector';
import * as Store from '../../../../redux/store/StoreNamespace';
import { filtersMetaForSearchDocument } from '../../../../redux/selectors/filtersMetaForSearchDocument';
import { SearchDocumentName } from '../../../../redux/actions/interfaces/DocumentEnum';
import searchTabConfigSelector from '../../../../redux/selectors/searchTabConfig';
import { getFiltersMetasWithCount } from '../../../../utils/filters/getFiltersMetaWithCount';

const getFiltersOptions = createFiltersToOptions();

const getNormalizedOrderedFiltersMeta = compose(
    createFilterValuesNormalizer<Store.IFilterMeta>(),
    createFilterOrderer()
);

export const createFilterOptions = (state: Store.IState) => {
    const filtersMeta: Store.IFiltersMeta = filtersMetaForSearchDocument({
        state,
        searchDocumentName: SearchDocumentName.search,
    });
    const { getFilterStats } = searchTabConfigSelector(state);
    const metaWithCount = getFiltersMetasWithCount(
        filtersMeta,
        getFilterStats(state)
    );
    const meta = getNormalizedOrderedFiltersMeta(metaWithCount);
    return getFiltersOptions(meta);
};
