/**
 * Created by lkarmelo on 25.07.2019.
 */

import * as Store from 'app/redux/store/StoreNamespace';

export const ignoreFilters: string[] = [
    Store.StaticFilterName.Search,
    Store.StaticFilterName.Thesaurus,
];
