import React, { useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { usePrevious } from 'react-use';

import * as Store from 'app/redux/store/StoreNamespace';
import {
    ISelectOption,
    List,
    IOptionProps,
} from 'app/modules/components/TreeSelect';
import { IConceptWithRelations } from 'app/redux/store/StoreNamespace';
import { traverseConceptTree } from 'app/utils/concepts';

import styles from './ConceptsPanel.scss';

import CheckBox from '../../../../modules/components/Checkbox';
import { CONCEPTS_HINT_TEXT } from '../../../../utils/constants';
import InfoTooltip from '../../../common/InfoTooltip';
import Concept from '../../../common/Concept';

interface IConceptOptionComponentProps extends IOptionProps {
    onClick(opt: ISelectOption): void;
}

const ConceptOptionComponent: React.FC<IConceptOptionComponentProps> = (
    props
) => {
    const {
        active,
        onSelect,
        onDeselect,
        option,
        intermediate,
        onClick,
        className,
    } = props;

    const toggle = () => {
        active ? onDeselect(option) : onSelect(option);
    };

    return (
        <Concept
            className={classNames(className, styles.conceptsPanelConcept)}
            bodyClassName={styles.conceptsPanelConceptBody}
            titleClassName={styles.conceptsPanelConceptTitle}
            onClick={() => onClick(option)}
            title={option.label}
            schemeTitle={option.schemeTitle}
            bodyPrepend={
                <CheckBox
                    className={styles.conceptsPanelConceptCheckbox}
                    checked={active}
                    onChange={toggle}
                    intermediate={intermediate}
                    fixed={option.fixed}
                />
            }
        />
    );
};

const enum Tabs {
    Hierarchy,
    Related,
}

interface IProps {
    concepts: Record<string, Store.IConceptWithRelations>;
    selectedConcepts: Record<string, boolean>;
    mainConceptList: string[];
    applyConceptSelection(conceptSelection: Record<string, boolean>): void;
}

const ConceptsPanel: React.FC<IProps> = (props) => {
    const {
        concepts,
        selectedConcepts,
        mainConceptList,
        applyConceptSelection,
    } = props;

    const ref = useRef<HTMLDivElement>(null);

    const [activeTab, setActiveTab] = useState(Tabs.Hierarchy);

    const [viewedConcept, setViewedConcept] = useState(mainConceptList[0]);
    const prevMainConcepts = usePrevious(mainConceptList);

    if (
        mainConceptList !== prevMainConcepts &&
        viewedConcept !== mainConceptList[0]
    ) {
        setViewedConcept(mainConceptList[0]);
    }

    const initConceptsSelection = useMemo(
        () => ({ ...selectedConcepts }),
        [selectedConcepts]
    );
    const prevInitConceptSelection = usePrevious(initConceptsSelection);

    const [conceptsSelection, setConceptsSelection] = useState(
        initConceptsSelection
    );

    if (
        prevInitConceptSelection !== initConceptsSelection &&
        conceptsSelection !== initConceptsSelection
    ) {
        setConceptsSelection(initConceptsSelection);
    }
    const selectedConceptList = useMemo(
        () =>
            Object.entries(conceptsSelection).reduce(
                (res, [conceptUri, isSelected]) => {
                    if (isSelected) {
                        res.push(conceptUri);
                    }
                    return res;
                },
                []
            ),
        [conceptsSelection]
    );

    const onSelectConcept = useCallback(
        ({ value }: ISelectOption) => {
            const nextSelection = { ...conceptsSelection };
            nextSelection[value] = true;
            setConceptsSelection(nextSelection);
        },
        [conceptsSelection, setConceptsSelection]
    );
    const onDeselectConcept = useCallback(
        ({ value }: ISelectOption) => {
            const nextSelection = { ...conceptsSelection };
            nextSelection[value] = false;
            setConceptsSelection(nextSelection);
        },
        [conceptsSelection, setConceptsSelection]
    );

    // intermediate состояние высчитывается только для самих main concepts, но не для концептов в их дереве
    // концепт принимает intermediate состояние только если соблюдены оба условия:
    // 1. Выбран хотя бы один концепт из дерева, related концептов или сам концепт
    // 2. Не выбраны все концепты из дерева, related концептов и сам концепт
    const intermediateMainConceptUris: string[] = useMemo(() => {
        const mainConceptsToTreeAndRelatedSelection: Record<string, boolean[]> =
            {};

        mainConceptList.forEach((mainConceptUri) => {
            const mainConceptTree = concepts[mainConceptUri].tree;
            const mainConceptRelated = concepts[mainConceptUri].related;

            traverseConceptTree(mainConceptTree, (concept) => {
                if (concept.item.uri !== mainConceptUri) {
                    if (
                        Array.isArray(
                            mainConceptsToTreeAndRelatedSelection[
                                mainConceptUri
                            ]
                        )
                    ) {
                        mainConceptsToTreeAndRelatedSelection[
                            mainConceptUri
                        ].push(conceptsSelection[concept.item.uri]);
                    } else {
                        mainConceptsToTreeAndRelatedSelection[mainConceptUri] =
                            [conceptsSelection[concept.item.uri]];
                    }
                }
            });

            mainConceptRelated?.forEach((relatedConcept) => {
                if (
                    Array.isArray(
                        mainConceptsToTreeAndRelatedSelection[mainConceptUri]
                    )
                ) {
                    mainConceptsToTreeAndRelatedSelection[mainConceptUri].push(
                        conceptsSelection[relatedConcept.item.uri]
                    );
                } else {
                    mainConceptsToTreeAndRelatedSelection[mainConceptUri] = [
                        conceptsSelection[relatedConcept.item.uri],
                    ];
                }
            });
        });

        return mainConceptList.reduce((res, conceptUri) => {
            const isConceptSelected = conceptsSelection[conceptUri];
            // если у концепта нет иерархии или похожих, то это будет undefined
            const hasTreeOrRelated =
                typeof mainConceptsToTreeAndRelatedSelection[conceptUri] !==
                'undefined';

            if (!hasTreeOrRelated) {
                // если у концепта нет иерархии или похожих, то он не может быть intermediate
                return res;
            }

            const anyTreeOrRelatedSelected =
                !!mainConceptsToTreeAndRelatedSelection[conceptUri]?.some(
                    Boolean
                );
            const allTreeAndRelatedSelected =
                !!mainConceptsToTreeAndRelatedSelection[conceptUri]?.every(
                    Boolean
                );

            const everythingSelected =
                isConceptSelected && allTreeAndRelatedSelected;
            const nothingSelected =
                !isConceptSelected && !anyTreeOrRelatedSelected;
            if (!everythingSelected && !nothingSelected) {
                res.push(conceptUri);
            }
            return res;
        }, []);
    }, [mainConceptList, conceptsSelection, concepts]);

    const mainConceptsHierarchyAsOptions: Record<string, ISelectOption[]> =
        useMemo(() => {
            const conceptUriToOptions = {};

            const conceptsToOptions = (
                res: ISelectOption[],
                concept: IConceptWithRelations
            ) => {
                res.push({
                    label: concept.item.label,
                    value: concept.item.uri,
                    options: concept.tree?.reduce(conceptsToOptions, []),
                    fixed: concept.item.fixed,
                    schemeTitle: concept.item.inScheme?.label,
                });
                return res;
            };

            mainConceptList.forEach((extrConceptUri) => {
                const conceptTree = concepts[extrConceptUri].tree;
                conceptUriToOptions[extrConceptUri] = conceptTree
                    ? conceptTree.reduce(conceptsToOptions, [])
                    : [];
            });

            return conceptUriToOptions;
        }, [mainConceptList, concepts]);
    const mainRelatedAsOptions: Record<string, ISelectOption[]> = useMemo(
        () =>
            mainConceptList.reduce((res, extrConceptUri) => {
                res[extrConceptUri] = concepts[extrConceptUri]?.related?.map(
                    (rel) => ({
                        label: rel.item.label,
                        value: rel.item.uri,
                        fixed: rel.item.fixed,
                    })
                );
                return res;
            }, {}),
        [mainConceptList, concepts]
    );
    const mainConceptsAsOptions: ISelectOption[] = useMemo(
        () =>
            mainConceptList.map((extrConceptUri) => ({
                label: concepts[extrConceptUri].item.label,
                value: concepts[extrConceptUri].item.uri,
                fixed: concepts[extrConceptUri].item.fixed,
                schemeTitle: concepts[extrConceptUri].item.inScheme?.label,
            })),
        [mainConceptList, concepts]
    );

    const getConceptListOptionComponentProps = useCallback(
        ({ value }: ISelectOption) => ({
            className:
                viewedConcept === value
                    ? styles.conceptsPanelConceptSelected
                    : '',
            onClick: ({ value: _val }) => setViewedConcept(_val),
        }),
        [viewedConcept, setViewedConcept]
    );

    const getTreeOptionComponentProps = useCallback(
        (option: ISelectOption) => ({
            className:
                option.value === viewedConcept
                    ? styles.conceptsPanelListOptionMain
                    : '',
        }),
        [viewedConcept]
    );

    return (
        <div className={styles.conceptsPanel} ref={ref}>
            <div className={styles.conceptsPanelLeftPanel}>
                <div className={styles.conceptsPanelTitle}>
                    Концепты поискового запроса
                    <InfoTooltip
                        className={styles.conceptsPanelHint}
                        message={CONCEPTS_HINT_TEXT}
                        placement="bottom"
                        getPopupContainer={() => ref.current}
                    />
                </div>
                <List
                    options={mainConceptsAsOptions}
                    intermediate={intermediateMainConceptUris}
                    OptionComponent={ConceptOptionComponent}
                    optionComponentProps={getConceptListOptionComponentProps}
                    active={selectedConceptList}
                    onSelect={onSelectConcept}
                    onDeselect={onDeselectConcept}
                    className={styles.conceptsPanelList}
                />
                <button
                    type="button"
                    className={`btn ${styles.conceptsPanelApplyBtn}`}
                    onClick={() => applyConceptSelection(conceptsSelection)}
                >
                    Применить
                </button>
            </div>
            <div className={styles.conceptsPanelRightPanel}>
                <div className={styles.conceptsPanelTabs}>
                    <button
                        className={classNames('btn', styles.conceptsPanelTab, {
                            [styles.conceptsPanelTabActive]:
                                activeTab === Tabs.Hierarchy,
                        })}
                        onClick={() => setActiveTab(Tabs.Hierarchy)}
                    >
                        Иерархия концепта
                        <span className={styles.conceptsPanelConceptsCount}>
                            {concepts[viewedConcept]?.tree.reduce(
                                function countConcepts(
                                    res: number,
                                    item: Store.IConceptTree
                                ) {
                                    return (
                                        res +
                                        1 +
                                        (item.tree?.reduce(countConcepts, 0) ??
                                            0)
                                    );
                                },
                                0
                            ) ?? 0}
                        </span>
                    </button>
                    <button
                        className={classNames('btn', styles.conceptsPanelTab, {
                            [styles.conceptsPanelTabActive]:
                                activeTab === Tabs.Related,
                        })}
                        onClick={() => setActiveTab(Tabs.Related)}
                    >
                        Похожие концепты
                        <span className={styles.conceptsPanelConceptsCount}>
                            {concepts[viewedConcept]?.related
                                ? Object.keys(concepts[viewedConcept].related)
                                      .length
                                : 0}
                        </span>
                    </button>
                </div>
                <div className={styles.conceptsPanelRightListWrapper}>
                    {activeTab === Tabs.Hierarchy &&
                        mainConceptsHierarchyAsOptions[viewedConcept] && (
                            <List
                                options={
                                    mainConceptsHierarchyAsOptions[
                                        viewedConcept
                                    ]
                                }
                                active={selectedConceptList}
                                onSelect={onSelectConcept}
                                onDeselect={onDeselectConcept}
                                optionComponentProps={
                                    getTreeOptionComponentProps
                                }
                                optionGroupComponentProps={
                                    getTreeOptionComponentProps
                                }
                                initiallyOpenedNestedLevel={3}
                            />
                        )}
                    {activeTab === Tabs.Related &&
                        mainRelatedAsOptions[viewedConcept] && (
                            <List
                                options={mainRelatedAsOptions[viewedConcept]}
                                active={selectedConceptList}
                                onSelect={onSelectConcept}
                                onDeselect={onDeselectConcept}
                                optionComponentProps={
                                    getTreeOptionComponentProps
                                }
                                optionGroupComponentProps={
                                    getTreeOptionComponentProps
                                }
                            />
                        )}
                </div>
            </div>
        </div>
    );
};

export default ConceptsPanel;
