import * as React from 'react';
import classNames from 'classnames';

import * as styles from './PersonTelephones.scss';

export interface IPersonTelephonesProps {
    personId: string;
    telInternal?: string;
    telMobile?: string;
    telOffice?: string;
    count?: number;
}

const PersonTelephones: React.FC<IPersonTelephonesProps> = ({
    personId,
    telInternal,
    telMobile,
    telOffice,
    count,
}) => {
    // В полях telInternal, telMobile, telOffice может быть указано несколько номеров через запятую
    // поэтому надо слить всё в одно строку и затем разделить
    const telephones = [telInternal, telMobile, telOffice]
        .join(',')
        .split(',')
        .map((tel) => tel.trim())
        .filter(Boolean);

    const visibleTelephones = telephones.slice(0, count);

    return (
        <div className={styles.personTelephonesWrapper}>
            {visibleTelephones.length > 0 && (
                <>
                    <i
                        className={classNames(
                            'nkc-icon-phone',
                            styles.personTelephonesIcon
                        )}
                    />
                    {visibleTelephones.map((tel, index) => {
                        const delimiter = index > 0 && (
                            <div
                                className={styles.personTelephonesDelimiterDot}
                            />
                        );
                        return (
                            <React.Fragment key={`${personId}-${tel}`}>
                                {delimiter}
                                <div className={styles.personTelephonesInfo}>
                                    {tel}
                                </div>
                            </React.Fragment>
                        );
                    })}
                    {telephones.length > count && (
                        <>
                            <div
                                className={styles.personTelephonesDelimiterDot}
                            />
                            <div>и ещё {telephones.length - count}</div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default PersonTelephones;
