import * as Store from 'app/redux/store/StoreNamespace';
import { replaceDotsWithDashes } from 'app/utils/filters/filterNamesEscape';

export const getFilterStatsFromAggregation = (
    aggregations: Store.IAggregation[] | undefined
): Store.IFilterStats => {
    const filterStats = {};
    if (Array.isArray(aggregations)) {
        aggregations.forEach(({ name, buckets }) => {
            if (
                name === Store.GRAPH_STATS_AGGREGATION_NAME ||
                name === Store.YEAR_STATS_AGGREGATION_NAME
            ) {
                return;
            }

            const aggrBuckets: Store.IAggregationBucket[] = <
                Store.IAggregationBucket[]
            >buckets;

            const valueStats = {};
            if (Array.isArray(aggrBuckets)) {
                aggrBuckets.forEach(({ key, docCount }) => {
                    valueStats[key] = docCount;
                });
            }

            filterStats[replaceDotsWithDashes(name)] = valueStats;
        });
    }
    return filterStats;
};
