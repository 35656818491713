import { Observable } from 'rxjs/Observable';

/**
 * Created by lkarmelo on 15.08.2019.
 */

export const createRetryOnIEAuthProblem =
    (maxExtraAttempts = 1, codeToRetryOn = 400) =>
    (errors$) =>
        errors$.mergeMap((e, i) => {
            const extraAttempt = i + 1;
            const { status } = e;

            if (extraAttempt > maxExtraAttempts || status !== codeToRetryOn) {
                return Observable.throw(e);
            }
            return Observable.of(undefined);
        });
