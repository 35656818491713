import Badge from 'antd/es/badge';
import React from 'react';
import classNames from 'classnames';

import PersonAvatar from 'app/components/search/PersonAvatar/PersonAvatarConnected';
import getPlaceHolderByUserNames from 'app/utils/getPlaceHolderByUserNames';
import { useTypedSelector } from 'app/hooks/useTypedSelector';

import * as styles from '../../UserMenu.scss';

const Toggle = () => {
    const {
        firstName = '',
        lastName = '',
        littleUserMenuAvatar,
    } = useTypedSelector((state) => state.user);

    const placeHolder = getPlaceHolderByUserNames(firstName, lastName);

    return (
        <Badge count={undefined}>
            {littleUserMenuAvatar ? (
                <PersonAvatar
                    avatar={littleUserMenuAvatar && littleUserMenuAvatar.avatar}
                    className={classNames(
                        styles.userMenuUserPicSmall,
                        styles.userMenuUserPic
                    )}
                />
            ) : (
                <div
                    className={classNames(
                        styles.userMenuUserPicSmall,
                        styles.userMenuUserPic,
                        styles.userMenuDefaultUserPic
                    )}
                >
                    <div className={styles.userMenuPlaceholder}>
                        <span>{placeHolder}</span>
                    </div>
                </div>
            )}
        </Badge>
    );
};

export default Toggle;
