/**
 * Created by Lkarmelo on 05.12.2017.
 */

import React, { PureComponent } from 'react';
import autoBind from 'autobind-decorator';

import formContainer from 'app/components/high-order/formContainer';

import Competencies from './Competencies';
import IProps from './interfaces/IFormProps';

@autoBind
class Form extends PureComponent<IProps> {
    static defaultProps = {
        fields: {},
        validatedFields: {},
    };

    render(): JSX.Element {
        const {
            fields,
            onAddCompetence,
            onChangeCompetence,
            onRemoveCompetence,
            onClickCompetence,
            onChangeGrade,
            allCompetencies,
            targetPost,
        } = this.props;
        return (
            <Competencies
                targetPost={targetPost}
                competencies={fields.competencies.value}
                allCompetencies={allCompetencies}
                isEditable
                onAddCompetence={onAddCompetence}
                onChangeGrade={onChangeGrade}
                onRemoveCompetence={onRemoveCompetence}
                onClickCompetence={onClickCompetence}
                onChangeCompetence={onChangeCompetence}
            />
        );
    }
}

export default formContainer<IProps>(Form);
