/**
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 07.02.2018
 * -----
 * Last Modified: 20.02.2018 18:29:13
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Action } from 'redux-actions';
import memoizeOne from 'memoize-one';

import {
    addMultiSelectFilterValue,
    removeMultiSelectFilterValue,
    setDateRangeFrom,
    setDateRangeTo,
    setFilterValue,
} from 'app/redux/actions/search/filters';
import * as Store from 'app/redux/store/StoreNamespace';
import { filtersValuesSelector } from 'app/redux/selectors/filters';
import { filtersMetaForSearchDocument } from 'app/redux/selectors/filtersMetaForSearchDocument';
import { filtersToDefaults } from 'app/redux/actions/search/defaultFilters';
import { addTag, removeTag } from 'app/redux/actions/search/tagsFromQuery';
import { ISelectOption } from 'app/modules/components/TreeSelect/Select/TreeSelect';
import FiltersSidePanel, {
    IFiltersSidePanelActionProps,
    IFiltersSidePanelStateProps,
} from 'app/components/search/FiltersSidePanel';
import { SearchDocumentName } from 'app/redux/actions/interfaces/DocumentEnum';
import {
    createFilterOrderer,
    createFilterValuesNormalizer,
} from 'app/utils/filters/createNormalizedFiltersSelector';
import { createFiltersToOptions } from 'app/utils/filters/filtersToOptions';
import { getFiltersMetasWithCount } from 'app/utils/filters/getFiltersMetaWithCount';
import { stringToTag } from 'app/utils/document/tagsUtils';
import { caseInsensitiveHasOwnProperty } from 'app/utils/caseInsensitiveHasOwnProperty';
import searchTabConfigSelector from 'app/redux/selectors/searchTabConfig';
import getSearchTarget from 'app/utils/getSearchTarget';

const tagsAggregationName = 'tags-stats';

const getTagsOptions = memoizeOne(
    (
        aggregation: Store.IAggregation[],
        tagsFromQuery: Record<string, boolean>
    ): ISelectOption[] => {
        const tagsAggr = aggregation?.find(
            ({ name }) => name === tagsAggregationName
        );

        const options: ISelectOption[] = [];

        const tagsFromAggr = tagsAggr
            ? (tagsAggr.buckets as Store.IAggregationBucket[])
                  .slice()
                  .sort(
                      ({ docCount: docCountA }, { docCount: docCountB }) =>
                          docCountB - docCountA
                  )
                  .map(({ key }) => stringToTag(key))
            : [];
        const tagsFromAggrLowerCase = tagsFromAggr.map((t) => t.toLowerCase());

        const tagsFromQueryKeys = tagsFromQuery
            ? Object.keys(tagsFromQuery)
            : [];

        // теги, которые пришли в аггрегации и были выбраны пользователем
        const tagsFromAggrInQuery = tagsFromQueryKeys.reduce((res, tag) => {
            if (tagsFromAggrLowerCase.includes(tag.toLowerCase())) {
                res[tag] = true;
            }
            return res;
        }, {});

        tagsFromQueryKeys.forEach((tag, index) => {
            options.push({
                label: tag,
                value: tag,
                sortIndex: tagsFromAggrInQuery[tag]
                    ? // если тэг из аггрегации, то ставим его в конец
                      index + tagsFromQueryKeys.length
                    : index,
            });
        });

        tagsFromAggr.forEach((tag) => {
            if (!caseInsensitiveHasOwnProperty(tagsFromAggrInQuery, tag)) {
                options.push({
                    label: tag,
                    value: tag,
                });
            }
        });

        return options;
    }
);

const getActiveTags = memoizeOne((tagsFromQuery: Record<string, boolean>) => {
    if (!tagsFromQuery) {
        return [];
    }

    return Object.keys(tagsFromQuery).reduce((activeTags, tag) => {
        activeTags.push(tag);
        return activeTags;
    }, []);
});

const getFiltersOptions = createFiltersToOptions();

const getNormalizedOrderedFiltersMeta = compose(
    createFilterValuesNormalizer<Store.IFilterMeta>(),
    createFilterOrderer()
);

const mapStateToProps = (state: Store.IState): IFiltersSidePanelStateProps => {
    const searchPage = state.search;
    const filtersMeta: Store.IFiltersMeta = filtersMetaForSearchDocument({
        state,
        searchDocumentName: SearchDocumentName.search,
    });

    const { getFilterStats } = searchTabConfigSelector(state);

    const metaWithCount = getFiltersMetasWithCount(
        filtersMeta,
        getFilterStats(state)
    );

    const meta = getNormalizedOrderedFiltersMeta(metaWithCount);
    const opts = getFiltersOptions(meta);

    const searchTarget = getSearchTarget(state.mainSearchPageActiveTab, state);
    const isDocumentSearch = searchTarget === Store.SearchTarget.document;

    return {
        orderedNormalizedFiltersMeta: meta,
        filtersOptions: opts,
        activeFilterValues: filtersValuesSelector(searchPage),
        filtersMeta: state.filtersMeta,
        defaultFilters: state.defaultFilters,
        showServerOnlyFiltersOptions: true,
        tagsOptions: getTagsOptions(
            isDocumentSearch
                ? searchPage.results?.aggregations
                : searchPage.personResults?.aggregations,
            searchPage.tagsFromQuery
        ),
        activeTags: getActiveTags(searchPage.tagsFromQuery),
    };
};

const mapDispatchToProps = (
    dispatch: Dispatch<Action<any>>
): IFiltersSidePanelActionProps => ({
    // select filter
    onSelect(filterName: string, value: string | string[]): void {
        dispatch(setFilterValue(filterName, value));
    },
    onSelectMultiSelectFilter({ filterName, value }: ISelectOption): void {
        dispatch(addMultiSelectFilterValue(filterName, value));
    },
    onDeselectMultiSelectFilter({ filterName, value }: ISelectOption): void {
        dispatch(removeMultiSelectFilterValue(filterName, value));
    },

    // range filter
    onStartRangeChange(filterName: string, value: any): void {
        dispatch(setDateRangeFrom(filterName, value));
    },

    onEndRangeChange(filterName: string, value: any): void {
        dispatch(setDateRangeTo(filterName, value));
    },

    onFiltersToDefaultsClick(): void {
        dispatch(filtersToDefaults());
    },

    onSelectTag(option: ISelectOption): void {
        dispatch(addTag(option.value));
    },
    onDeSelectTag(option: ISelectOption): void {
        dispatch(removeTag(option.value));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(FiltersSidePanel);
