import * as React from 'react';

import * as Store from 'app/redux/store/StoreNamespace';
import { ISelectOption } from 'app/modules/components/TreeSelect';

import styles from '../../PersonCard.scss';

interface IProps {
    department: Store.INkcDepartment | undefined;
    isOpened: boolean;
    departmentPath: number[];
    descendantsMap: Record<string, ISelectOption[]>;
    onClickDepartment(departmentId: number): void;
}

type DepartmentHierarchyType = {
    name: string;
    children: DepartmentHierarchyType[];
    id: number;
};

const DepartmentHierarchy: React.FC<IProps> = ({
    department,
    isOpened,
    departmentPath,
    descendantsMap,
    onClickDepartment,
}) => {
    if (!department) {
        return null;
    }

    const { namePath } = department;

    const getHierarchy = (
        names: string[],
        idx = 0
    ): DepartmentHierarchyType[] => {
        if (idx === names.length) {
            return [];
        }
        return [
            {
                name: names[idx],
                id: departmentPath[idx],
                children: getHierarchy(names, idx + 1),
            },
        ];
    };
    const hierarchicalNamePath =
        Array.isArray(namePath) && getHierarchy(namePath);

    const getDepartments = (deptId: number, name: string) => {
        // из-за того, что фильтры "Подразделение" имеют ограничения по вложенности,
        // придется ограничивать кликабельность подразделения
        return descendantsMap[deptId] ? (
            <span
                className={styles.personCardBtnBlue}
                onClick={() => onClickDepartment(deptId)}
            >
                {name}
            </span>
        ) : (
            name
        );
    };

    return (
        <div>
            {isOpened && Array.isArray(hierarchicalNamePath) ? (
                <div className={styles.personCardDepartmentHierarchyItem}>
                    {hierarchicalNamePath.map(function mapper({
                        name,
                        children,
                        id,
                    }: DepartmentHierarchyType) {
                        return (
                            <div key={name}>
                                <div
                                    className={
                                        styles.personCardDepartmentHierarchyName
                                    }
                                >
                                    {getDepartments(id, name)}
                                </div>
                                {children.length > 0 && (
                                    <div
                                        className={
                                            styles.personCardDepartmentHierarchyChildren
                                        }
                                    >
                                        {children.map(mapper)}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            ) : (
                getDepartments(department.deptId, department.name)
            )}
        </div>
    );
};

export default DepartmentHierarchy;
