/**
 * Created by lkarmelo on 29.08.2018.
 */

import React, { PureComponent } from 'react';

import IProps from './interfaces/IRecommendationsTableBodyProps';

export default class RecommendationsTableBody extends PureComponent<IProps> {
    render(): JSX.Element {
        const { dataSource, columns } = this.props;
        return (
            <tbody className="ant-table-tbody">
                {dataSource.map((col) => (
                    <tr
                        key={col.key}
                        className="ant-table-row  ant-table-row-level-0"
                    >
                        {columns.map((colDescription, index) => (
                            <td key={colDescription.key}>
                                {colDescription.render
                                    ? colDescription.render(
                                          colDescription.dataIndex &&
                                              col[colDescription.dataIndex],
                                          col,
                                          index
                                      )
                                    : col[colDescription.dataIndex]}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        );
    }
}
