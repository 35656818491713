/**
 * Created by Lkarmelo on 22.02.2018.
 */

import React, { useEffect } from 'react';
import { usePrevious } from 'react-use';

import CatalogueDocumentsPanel from 'app/components/catalogue/CatalogueDocumentsPanel';
import BreadCrumbs from 'app/components/common/BreadCrumbs';
import clientRoutes from 'app/routing/clientRoutes';

import IProps from './interfaces/ICatalogueCategoryProps';
import * as styles from './CatalogueCategory.scss';

import CatalogueCategoryControls from '../CatalogueCategoryControls';

const CatalogueCategory: React.FC<IProps> = (props) => {
    const {
        categories,
        activeCategory,
        activeCategoriesSequence,
        documentsCount,
        isEditable,
        loadKeyWords,
        showAcceptOrEditDocumentButtons,
        categoriesAsOptions,
    } = props;
    const prevActiveCategory = usePrevious(activeCategory);

    const currentCategory = categories.categories[activeCategory];

    const breadCrumbsPaths = activeCategoriesSequence.map((id) => ({
        title: categories.categories[id].item.title,
        link: clientRoutes.catalogue.getUrl({ id }),
    }));

    useEffect(() => {
        if (
            prevActiveCategory !== activeCategory &&
            categories.categories[activeCategory]
        ) {
            loadKeyWords(activeCategory);
        }
    }, [categories, activeCategory, prevActiveCategory, loadKeyWords]);

    return (
        <div className={styles.catalogueCategory}>
            {currentCategory && (
                <>
                    <div className={styles.catalogueCategoryHeader}>
                        <div className={styles.catalogueCategoryBreadcrumbs}>
                            <BreadCrumbs
                                paths={breadCrumbsPaths}
                                shouldReversePaths
                                delimiter={<span />}
                            />
                        </div>
                        {isEditable && (
                            <div className={styles.catalogueCategoryControls}>
                                <CatalogueCategoryControls
                                    categories={categories}
                                    activeCategoryId={activeCategory}
                                    activeCategoriesSequence={
                                        activeCategoriesSequence
                                    }
                                    categoriesAsOptions={categoriesAsOptions}
                                />
                            </div>
                        )}
                    </div>
                    <div className={styles.catalogueCategoryBody}>
                        {currentCategory.item.keywords &&
                            currentCategory.item.keywords.length > 0 && (
                                <div
                                    className={
                                        styles.catalogueCategoryDescription
                                    }
                                >
                                    <div
                                        className={
                                            styles.catalogueCategoryDescriptionTitle
                                        }
                                    >
                                        Ключевые слова
                                    </div>
                                    <div>
                                        {currentCategory.item.keywords.join(
                                            ', '
                                        )}
                                    </div>
                                </div>
                            )}
                        <CatalogueDocumentsPanel
                            activeCategory={activeCategory}
                            currentCategory={currentCategory}
                            documentsCount={documentsCount}
                            showNewDocuments={showAcceptOrEditDocumentButtons}
                            categories={categoriesAsOptions}
                        />
                    </div>
                </>
            )}
            {!currentCategory && !activeCategory && (
                <div className={styles.catalogueCategoryPlaceholder}>
                    Выберите категорию для изучения
                </div>
            )}
            {!currentCategory && activeCategory && (
                <div className={styles.catalogueCategoryPlaceholder}>
                    Категория не существует
                </div>
            )}
        </div>
    );
};

export default CatalogueCategory;
