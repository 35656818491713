/**
 * Created by lkarmelo on 23.07.2019.
 */

import { MiddlewareAPI } from 'redux';
import queryString from 'qs';

import { updateLocation } from 'app/redux/actions/search/filters';
import { parseOptions, stringifyOptions } from 'app/utils/queryStringOptions';
import { SEARCH_ALIAS_PARAM_NAME } from 'app/utils/constants';
import history from 'app/history';
import clientRoutes from 'app/routing/clientRoutes';
import * as Store from 'app/redux/store/StoreNamespace';

import { getSearchState } from './utils/getSearchState';

export const updateLocationEpic = (
    action$,
    store: MiddlewareAPI<Store.IState>
) =>
    action$
        .ofType(updateLocation.toString())
        .hasContext(false)
        .do(() => {
            const alias = getSearchState(store).executingSearchAlias;

            const locationSearchParsed = queryString.parse(
                location.search,
                parseOptions
            );

            const nextLocationSearchDefinition = {
                [SEARCH_ALIAS_PARAM_NAME]: alias,
            };

            const nextLocationSearch = queryString.stringify(
                nextLocationSearchDefinition,
                { ...stringifyOptions }
            );

            if (history.location.search !== nextLocationSearch) {
                const parametersCount =
                    Object.keys(locationSearchParsed).length;
                if (
                    parametersCount > 1 ||
                    (parametersCount === 1 &&
                        !locationSearchParsed[SEARCH_ALIAS_PARAM_NAME])
                ) {
                    history.replace(
                        clientRoutes.search.getUrl(
                            undefined,
                            nextLocationSearchDefinition
                        )
                    );
                } else {
                    history.push(
                        clientRoutes.search.getUrl(
                            undefined,
                            nextLocationSearchDefinition
                        )
                    );
                }
            }
        })
        .ignoreElements();
