/**
 * Created by lkarmelo on 14.08.2018.
 */

import React, { PureComponent } from 'react';
import autoBind from 'autobind-decorator';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { Portal } from 'react-portal';

import { SlideTransition } from 'app/components/common/utils/CSSTransitions';
import { linkChildWithParent } from 'app/utils/portalDOMUtils';
import { getRelatedTarget } from 'app/utils/getRelatedTargetFromFocusEvent';
import { getElementOffset } from 'app/utils/getElementOffset';

import IProps from './interfaces/INumberInputProps';
import IState from './interfaces/INumberInputState';
import * as styles from './NumberInput.scss';

@autoBind
export default class NumberInput extends PureComponent<IProps, IState> {
    static defaultProps: Partial<IProps> = {
        minValue: 0,
        maxValue: 100,
        step: 1,
        showDropDown: false,
        showStepBtns: true,
        showClearBtn: true,
        includeEmptyOptionInDropDown: true,
        inputRegExp: /^[0-9]*$/,
        filterDropDownOptions: false,
    };

    dropDownWrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

    inputLabelWrapperRef: React.RefObject<HTMLInputElement> = React.createRef();

    selfRef: React.RefObject<HTMLDivElement> = React.createRef();

    focusedElementBeforeDropDownOpened: HTMLElement = null;

    state: IState = {
        isDropDownOpened: false,
        isUserTyping: false,
        value: undefined,
    };

    static getDerivedStateFromProps(
        props: IProps,
        state: IState
    ): Partial<IState> {
        if (!state.isUserTyping && state.value !== props.value) {
            return {
                value: props.value === undefined ? null : props.value,
            };
        }
        return null;
    }

    render(): JSX.Element {
        const {
            value,
            maxValue,
            minValue,
            showStepBtns,
            showClearBtn,
            dropDownClassName,
            showDropDown,
            placeholder,
            label,
        } = this.props;
        const {
            value: stateValue,
            isUserTyping,
            isDropDownOpened,
        } = this.state;
        const valToRender = isUserTyping ? stateValue : value;
        const isValueDefined =
            valToRender !== undefined && valToRender !== null;
        return (
            <div
                className={classNames(styles.numberInput, {
                    [styles.numberInputOutOfRange]:
                        isValueDefined &&
                        (valToRender < minValue || valToRender > maxValue),
                })}
                ref={this.selfRef}
            >
                <div
                    ref={this.inputLabelWrapperRef}
                    className={styles.numberInputInputLabelWrapper}
                >
                    {label && (
                        <div className={styles.numberInputLabel}>{label}</div>
                    )}
                    <input
                        className={styles.numberInputInput}
                        value={isValueDefined ? valToRender : ''}
                        onClick={this.onInputClick}
                        onChange={this.onInputChange}
                        onBlur={this.onInputBlur}
                        placeholder={placeholder}
                    />
                </div>
                {showStepBtns && (
                    <>
                        <button
                            className={classNames(
                                'btn',
                                styles.numberInputStepUp
                            )}
                            onClick={this.onStepUp}
                        />
                        <button
                            className={classNames(
                                'btn',
                                styles.numberInputStepDown
                            )}
                            onClick={this.onStepDown}
                        />
                    </>
                )}
                {showClearBtn && (
                    <button
                        className={classNames('btn', styles.numberInputClear)}
                        onClick={this.onClear}
                    />
                )}
                {showDropDown && (
                    <Portal>
                        <SlideTransition in={isDropDownOpened}>
                            <div
                                className={classNames(
                                    dropDownClassName,
                                    styles.numberInputDropDownWrapper
                                )}
                                ref={this.dropDownWrapperRef}
                                tabIndex={1}
                            >
                                <Scrollbars
                                    style={{ maxHeight: 190, width: '100%' }}
                                    autoHeight
                                >
                                    <ul className={styles.numberInputDropDown}>
                                        {this.renderDropDownOptions()}
                                    </ul>
                                </Scrollbars>
                            </div>
                        </SlideTransition>
                    </Portal>
                )}
            </div>
        );
    }

    renderDropDownOptions(): JSX.Element[] {
        const {
            minValue,
            maxValue,
            value,
            reverseDropDownOptionsOrder,
            filterDropDownOptions,
        } = this.props;
        const { isUserTyping, value: typingValue } = this.state;
        const options: JSX.Element[] = [];
        const from = reverseDropDownOptionsOrder ? maxValue : minValue;
        const to = reverseDropDownOptionsOrder ? minValue : maxValue;

        for (
            let currentNumber = from;
            reverseDropDownOptionsOrder
                ? currentNumber >= to
                : currentNumber <= to;
            reverseDropDownOptionsOrder ? currentNumber-- : currentNumber++
        ) {
            if (
                filterDropDownOptions &&
                isUserTyping &&
                typeof typingValue === 'number'
            ) {
                const typingValueStr = typingValue.toString();
                const currentNumberStr = currentNumber.toString();

                if (currentNumberStr.indexOf(typingValueStr) !== 0) {
                    continue;
                }
            }
            options.push(
                <li
                    key={currentNumber}
                    tabIndex={1}
                    className={classNames(styles.numberInputDropDownItem, {
                        [styles.numberInputDropDownItemActive]:
                            value === currentNumber,
                    })}
                    onClick={() => {
                        this.props.onValueChange(currentNumber);
                        this.setState({ isUserTyping: false });
                        this.closeDropDownAndFocusPrevElement();
                    }}
                >
                    <span>{currentNumber}</span>
                </li>
            );
        }

        return options;
    }

    componentDidMount(): void {
        this.props.showDropDown &&
            document.addEventListener(
                'click',
                this.closeDropDownOnOutsideClick
            );
    }

    componentDidUpdate(prevProps: IProps, prevState: IState): void {
        linkChildWithParent(
            this.dropDownWrapperRef.current,
            this.selfRef.current
        );
        if (!prevState.isDropDownOpened && this.state.isDropDownOpened) {
            this.focusedElementBeforeDropDownOpened =
                document.activeElement as HTMLElement;
            this.setDropDownPosition();
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener('click', this.closeDropDownOnOutsideClick);
    }

    onInputBlur(e: React.FocusEvent<HTMLInputElement>): void {
        if (!this.props.showDropDown) {
            this.setState({ isUserTyping: false, value: this.props.value });
            return;
        }
        getRelatedTarget(e).then((relatedTarget: HTMLElement) => {
            const dropDownNode = this.dropDownWrapperRef.current;
            if (
                relatedTarget === null ||
                !dropDownNode.contains(relatedTarget)
            ) {
                this.setState({
                    isUserTyping: false,
                    value: this.props.value,
                    isDropDownOpened: false,
                });
            }
        });
    }

    onInputClick(): void {
        const { showDropDown } = this.props;
        showDropDown &&
            this.setState((state: IState) => ({
                isDropDownOpened: !state.isDropDownOpened,
            }));
    }

    onInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const {
            maxValue,
            minValue,
            passNullOnClear,
            onValueChange,
            onValueOutOfRange,
            inputRegExp,
            maxInputStrLength,
        } = this.props;

        if (e.currentTarget.value === '') {
            this.setState({ value: null });
            onValueChange(passNullOnClear ? null : undefined);
            return;
        }

        const isInputTooLong =
            typeof maxInputStrLength === 'number'
                ? e.currentTarget.value.length > maxInputStrLength
                : false;
        if (!inputRegExp.test(e.currentTarget.value) || isInputTooLong) {
            return;
        }

        const val: number = +e.currentTarget.value;
        if (isNaN(val)) {
            return;
        }
        this.setState({
            value: val,
        });
        if (val <= maxValue && val >= minValue) {
            this.closeDropDownAndFocusPrevElement();
            onValueChange(val);
            this.setState({
                isUserTyping: false,
            });
        } else {
            onValueOutOfRange && onValueOutOfRange(val, val < minValue);
            this.setState({
                isUserTyping: true,
            });
        }
    }

    onStepUp(): void {
        const { value, step, maxValue, minValue, onValueChange } = this.props;
        if (value === undefined || value === null) {
            onValueChange(minValue);
        } else if (value + step <= maxValue) {
            onValueChange(value + step);
        }
    }

    onStepDown(): void {
        const { value, step, maxValue, minValue, onValueChange } = this.props;
        if (value === undefined || value === null) {
            onValueChange(maxValue);
        } else if (value - step >= minValue) {
            onValueChange(value - step);
        }
    }

    onClear(): void {
        const { passNullOnClear, onValueChange } = this.props;
        onValueChange(passNullOnClear ? null : undefined);
    }

    closeDropDownAndFocusPrevElement(): void {
        this.focusedElementBeforeDropDownOpened.focus();
        this.setState({ isDropDownOpened: false });
    }

    closeDropDownOnOutsideClick(e: MouseEvent): void {
        if (!this.state.isDropDownOpened) {
            return;
        }
        const target: HTMLElement = e.target as HTMLElement;
        if (
            !this.selfRef.current.contains(target) &&
            !this.dropDownWrapperRef.current.contains(target)
        ) {
            this.setState({ isDropDownOpened: false });
        }
    }

    setDropDownPosition(): void {
        const target = this.inputLabelWrapperRef.current;
        const dropDownWrapper = this.dropDownWrapperRef.current;

        const topPosition = getElementOffset(target).top;

        const inputBoundingRect = target.getBoundingClientRect();

        dropDownWrapper.style.top = `${
            topPosition + inputBoundingRect.height + 4
        }px`;
        dropDownWrapper.style.left = `${inputBoundingRect.left}px`;
    }
}
