import * as React from 'react';
import { connect } from 'react-redux';
import autoBind from 'autobind-decorator';

import AbstractEditablePanelContainer from 'app/components/common/forms/AbstractEditablePanelContainer';
import Form from 'app/components/common/forms/Form';
import {
    initForm,
    removeForm,
    addSubField,
    changeField,
    changeSubField,
    removeSubField,
    setFormStatus,
    sendForm,
} from 'app/redux/actions/forms';
import {
    setCurrentUserWorkInfo,
    fetchCurrentUserWorkInfo,
} from 'app/redux/actions/currentUserWorkInfo';
import { fetchCurrentUserCompetencies } from 'app/redux/actions/currentUserCompetencies';
import { organizationsInfoToOptions } from 'app/redux/selectors/organizationsInfoToOptions';
import { FormName } from 'app/api/FormName';
import * as Store from 'app/redux/store/StoreNamespace';

import IProps, {
    IWorkInfoOwnProps as IOwnProps,
    IWorkInfoStateProps as IStateProps,
    IWorkInfoDispatchProps as IDispatchProps,
} from './interfaces/IWorkInfoProps';
import IState from './interfaces/IWorkInfoState';
import IFormData from './interfaces/IFormData';

import WorkInfoPresentational from '../presentational/WorkInfo';

@autoBind
class WorkInfo extends AbstractEditablePanelContainer<IProps, IState> {
    static defaultProps = {
        workPhone: [],
    };

    state = {
        isEditable: false,
        isFormValidationFailedOnSave: false,
    };

    formRef: React.RefObject<typeof Form.prototype> = React.createRef();

    render(): JSX.Element {
        const {
            organization,
            department,
            post,
            workAddress,
            workPhone,
            room,
            targetPost,
            workFunction,
        } = this.props;
        return (
            <WorkInfoPresentational
                organization={organization && organization.title}
                department={department && department.title}
                workFunction={workFunction}
                post={post && post.title}
                targetPost={targetPost && targetPost.title}
                workPhone={workPhone}
                workAddress={workAddress && workAddress.title}
                room={room}
            />
        );
    }

    componentDidMount() {
        this.props.onUpdate && this.props.onUpdate(this.state.isEditable);
    }

    componentDidUpdate() {
        this.props.onUpdate && this.props.onUpdate(this.state.isEditable);
    }

    onStartEdit() {
        const {
            initializeForm,
            workAddress,
            workPhone,
            organization,
            department,
            post,
            targetPost,
            onEditStart,
            room,
        } = this.props;

        const formData: IFormData = {
            workPhone,
            addressId: workAddress ? workAddress.id : '',
            organizationId: organization ? organization.id : '',
            subdivisionId: department ? department.id : '',
            targetPostId: targetPost ? targetPost.id : '',
            currentPostId: post ? post.id : '',
            room,
        };

        onEditStart && onEditStart();

        initializeForm(formData);
    }

    onSave() {
        const { isFormValidationFailedOnSave } = this.state;
        if (!this.formRef.current.getIsFormValid(true)) {
            if (!isFormValidationFailedOnSave)
                this.setState({ isFormValidationFailedOnSave: true });
            return;
        }
        if (isFormValidationFailedOnSave)
            this.setState({ isFormValidationFailedOnSave: false });
        this.props.sendFormData();
    }

    onCancelEdit() {
        this.state.isFormValidationFailedOnSave &&
            this.setState({ isFormValidationFailedOnSave: false });
        this.props.deleteForm();
    }

    onValidationFail() {
        const { formStatus, changeFormStatus } = this.props;
        if (formStatus !== Store.FormStatus.Invalid) {
            changeFormStatus(Store.FormStatus.Invalid);
        }
    }

    onValidationSuccess() {
        const { formStatus, changeFormStatus } = this.props;
        if (formStatus !== Store.FormStatus.Editing) {
            changeFormStatus(Store.FormStatus.Editing);
        }
    }
}

const formName = FormName.WorkInfo;

const metaMap = {
    organizations: { fieldName: 'organizationId' },
    addresses: { fieldName: 'addressId' },
    posts: { fieldName: 'currentPostId' },
    subdivisions: { fieldName: 'subdivisionId' },
    targetPosts: { fieldName: 'targetPostId' },
};

const mapStateToProps = (
    state: Store.IState,
    props: IOwnProps
): IStateProps & IOwnProps => {
    const workInfo = state.currentUserWorkInfo && state.currentUserWorkInfo[0];
    return {
        employeeId: workInfo && workInfo.id,
        organization: workInfo && workInfo.organization,
        department: workInfo && workInfo.subdivision,
        workFunction: workInfo && workInfo.workFunction,
        post: workInfo && workInfo.post,
        targetPost: workInfo && workInfo.targetPost,
        workPhone: workInfo && workInfo.phone,
        workAddress: workInfo && workInfo.address,
        room: workInfo && workInfo.room,
        formData: state.forms[formName] && state.forms[formName].data,
        formStatus: state.forms[formName] && state.forms[formName].status,
        formResponse: state.forms[formName] && state.forms[formName].response,
        onUpdate: props.onUpdate,
        onEditStart: props.onEditStart,
        isEditable: props.isEditable,
        organizationsInfo: organizationsInfoToOptions({ state, metaMap }),
    };
};

const mapDispatchToProps = (dispatch): IDispatchProps => ({
    setUserWorkInfo(workInfo: Store.IEmployeeInfo[]) {
        dispatch(setCurrentUserWorkInfo(workInfo));
        dispatch(fetchCurrentUserCompetencies());
    },
    getUserWorkInfo() {
        dispatch(fetchCurrentUserWorkInfo());
    },
    initializeForm(formData: IFormData) {
        dispatch(initForm(formName, formData));
    },
    deleteForm() {
        dispatch(removeForm(formName));
    },
    changeFormStatus(status: Store.FormStatus) {
        dispatch(setFormStatus(formName, status));
    },
    changeFormField(fieldName: string, value: any) {
        dispatch(changeField(formName, fieldName, value));
    },
    addFormSubField(fieldName: string, value: any, key?: string) {
        dispatch(addSubField(formName, fieldName, value, key));
    },
    changeFormSubField(fieldName: string, key: string, value: any) {
        dispatch(changeSubField(formName, fieldName, key, value));
    },
    removeFormSubField(fieldName: string, key: string) {
        dispatch(removeSubField(formName, fieldName, key, null));
    },
    sendFormData() {
        dispatch(sendForm(formName, null));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkInfo);
