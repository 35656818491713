/**
 * Created by lkarmelo on 17.09.2019.
 */
import { createAction } from 'redux-actions';

export const addTag = createAction<string>('ADD_TAG');
export const removeTag = createAction<string>('REMOVE_TAG');

export const setTagsFromQuery = createAction<string>('SET_TAGS_FROM_QUERY');

export const removeTags = createAction('REMOVE_TAGS');
