import * as React from 'react';
import { SkeletonItem as Skeleton } from 'react-skeleton-loader-pulse';

import * as styles from './DocumentSnippet.scss';

const SkeletonSnippet = () => {
    return (
        <article className={styles.skeletonSnippet}>
            <div className={styles.skeletonSnippetTitle}>
                <Skeleton height="20px" width="80%" />
                <Skeleton borderRadius="50%" width="10px" />
            </div>
            <Skeleton marginBottom="4px" />
            <div className={styles.skeletonSnippetAnnotation}>
                <Skeleton />
                <Skeleton width="85%" />
                <div>...</div>
                <Skeleton />
            </div>
            <div className={styles.skeletonSnippetFooter}>
                {[...Array(3)].map((item) => (
                    <React.Fragment key={item}>
                        <Skeleton width="79px" height="15px" />
                        <div className={styles.documentSnippetDelimiterDot} />
                    </React.Fragment>
                ))}
                <Skeleton width="86px" height="15px" />
            </div>
        </article>
    );
};

export default SkeletonSnippet;
