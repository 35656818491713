/**
 * Created by Lkarmelo on 04.12.2017.
 */

import React, { PureComponent } from 'react';
import autoBind from 'autobind-decorator';
import classNames from 'classnames';

import IProps from './interfaces/IInputFieldProps';

import './InputField.scss';

@autoBind
export default class InputField extends PureComponent<IProps> {
    static defaultProps = {
        classPrefix: 'input-field',
        cssClass: '',
        errorMessages: [],
        value: '',
    };

    render(): JSX.Element {
        const {
            title,
            value,
            id,
            classPrefix,
            cssClass,
            errorMessages,
            isDisabled,
            placeholder,
            isMultiLine,
            isRequired,
        } = this.props;

        const passedEvents = {
            onChange: this.onInputChange,
            onClick: this.onInputClick,
            onBlur: this.onInputBlur,
            onFocus: this.onInputFocus,
            onMouseDown: this.onInputMouseDown,
            onMouseUp: this.onInputMouseUp,
        };
        return (
            <div
                className={classNames(classPrefix, cssClass, {
                    [`${classPrefix}--errors`]: errorMessages.length > 0,
                    [`${classPrefix}--required`]: isRequired,
                })}
            >
                <label className={`${classPrefix}__title`} htmlFor={id}>
                    {title}
                </label>
                {isMultiLine ? (
                    <textarea
                        placeholder={placeholder}
                        disabled={isDisabled}
                        className={`${classPrefix}__input`}
                        id={id}
                        value={value}
                        {...passedEvents}
                    />
                ) : (
                    <input
                        placeholder={placeholder}
                        disabled={isDisabled}
                        className={`${classPrefix}__input`}
                        id={id}
                        type="text"
                        value={value}
                        {...passedEvents}
                    />
                )}
                {errorMessages.length > 0 && (
                    <div className={`${classPrefix}__error-messages`}>
                        {errorMessages.map((msg) => (
                            <div
                                key={msg}
                                className={`${classPrefix}__error-message`}
                            >
                                {msg}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    onInputChange(
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void {
        const { onChange, meta, id } = this.props;
        onChange && onChange(e.currentTarget.value, meta, id);
    }

    onInputClick(
        e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void {
        const { onClick, meta, id } = this.props;
        onClick && onClick(e, meta, id);
    }

    onInputFocus(
        e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void {
        const { onFocus, meta, id } = this.props;
        onFocus && onFocus(e, meta, id);
    }

    onInputBlur(
        e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void {
        const { onBlur, meta, id } = this.props;
        onBlur && onBlur(e, meta, id);
    }

    onInputMouseDown(
        e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void {
        const { onMouseDown, meta, id } = this.props;
        onMouseDown && onMouseDown(e, meta, id);
    }

    onInputMouseUp(
        e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void {
        const { onMouseUp, meta, id } = this.props;
        onMouseUp && onMouseUp(e, meta, id);
    }
}
