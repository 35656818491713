/**
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 14.03.2018
 * -----
 * Last Modified: 10.05.2018 13:20:17
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import { combineReducers } from 'redux';

import results from './documentResults';
import {
    searchQueryReducer,
    executingSearchQueryReducer,
    lastExecutedSearchQueryReducer,
} from './searchQuery';
import { filtersReducer, lastExecutedFiltersReducer } from './filters';
import paging from './searchPaging';
import hints from './searchHints';
import { isQueryExpansion, queryTags, selectedQueryTags } from './queryTags';
import initialFilterStats from './initialFilterStats';
import {
    ignoreMistakesReducer,
    lastExecutedIgnoreMistakesReducer,
} from './ignoreMistakes';
import {
    executingSearchAliasReducer,
    lastExecutedSearchAliasReducer,
    resolvingSearchAliasReducer,
} from './searchAlias';
import tagsFromQuery from './tagsFromQuery';
import personalization from './personalization';
import sorting from './sorting';
import concepts from './concepts';
import personResults from './personResults';
import { personStrict } from './strict';
import filterVisibility from './filterVisibility';

export default combineReducers({
    searchQuery: searchQueryReducer,
    lastExecutedSearchQuery: lastExecutedSearchQueryReducer,
    executingSearchQuery: executingSearchQueryReducer,
    executingSearchAlias: executingSearchAliasReducer,
    lastExecutedSearchAlias: lastExecutedSearchAliasReducer,
    resolvingSearchAlias: resolvingSearchAliasReducer,
    results,
    personResults,
    initialFilterStats,
    paging,
    filters: filtersReducer,
    lastExecutedFilters: lastExecutedFiltersReducer,
    hints,
    isQueryExpansion,
    queryTags,
    selectedQueryTags,
    ignoreMistakes: ignoreMistakesReducer,
    lastExecutedIgnoreMistakes: lastExecutedIgnoreMistakesReducer,
    tagsFromQuery,
    personalization,
    sorting,
    concepts,
    personStrict,
    filterVisibility,
});
