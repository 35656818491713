/**
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 15.01.2018
 * -----
 * Last Modified: 19.02.2018 12:21:22
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import React, { Component } from 'react';
import autoBind from 'autobind-decorator';
import classNames from 'classnames';
import Col from 'antd/es/col';
import Row from 'antd/es/row';

import ScrollToTop from 'app/components/common/utils/ScrollToTop';
import DocumentSnippet from 'app/components/search-snippets/DocumentSnippet';
import withApproveDeleteDocumentModal from 'app/components/high-order/withApproveDeleteDocumentModal';
import * as Store from 'app/redux/store/StoreNamespace';

import IProps from './interfaces/IDocumentPanelProps';

import './DocumentPanel.scss';

@autoBind
class DocumentPanel extends Component<IProps> {
    static defaultProps = {
        noDocumentsPlaceholder: 'Список пуст',
        renderSearchResult: (
            doc: Store.IDocumentSnippet,
            onApproveDeleteDocumentClick
        ): JSX.Element => {
            return (
                <DocumentSnippet
                    document={doc}
                    onApproveDeleteDocumentClick={onApproveDeleteDocumentClick}
                />
            );
        },
        renderFilterPanel: (): JSX.Element => {
            return null;
        },

        checkRemoved: () => false,
    };

    get currentPage(): number {
        return Math.floor(this.props.skip / this.props.limit + 1);
    }

    renderFilter(): JSX.Element {
        return this.props.renderFilterPanel();
    }

    renderSearchResult(doc: Store.IDocumentSnippet): JSX.Element {
        return this.props.renderSearchResult(
            doc,
            this.props.onApproveDeleteDocumentClick
        );
    }

    render(): JSX.Element {
        const {
            list,
            count,
            limit,
            checkRemoved,
            isLoading,
            noDocumentsPlaceholder,
            onLoadMore,
            renderSortingPanel,
            showDocumentsCount,
        } = this.props;

        const documentsBlock = (
            <div className="search">
                {list && list.length === 0 && !isLoading && (
                    <div className="search-result-documents__list">
                        {noDocumentsPlaceholder}
                    </div>
                )}
                {list && list.length > 0 && (
                    <div>
                        {(renderSortingPanel || showDocumentsCount) && (
                            <div className="bookmarked__top-row">
                                <div>
                                    {typeof count === 'number' && (
                                        <span className="bookmarked__document-count">
                                            <span className="bookmarked__document-count-title">
                                                Документы
                                            </span>{' '}
                                            {count}
                                        </span>
                                    )}
                                </div>
                                <div>{renderSortingPanel()}</div>
                            </div>
                        )}
                        <div className="search-result-documents__list">
                            {list.map((res) => (
                                <div
                                    key={res.id}
                                    className={
                                        checkRemoved(res)
                                            ? 'bookmarked__removed'
                                            : ''
                                    }
                                >
                                    {this.renderSearchResult(res)}
                                </div>
                            ))}
                        </div>
                        {list && list.length < count && limit < count && (
                            <button
                                onClick={() => onLoadMore(list.length)}
                                className="btn search-result-documents__more-btn"
                            >
                                Показать ещё
                            </button>
                        )}
                    </div>
                )}
                <ScrollToTop />
            </div>
        );

        const filtersPanel = this.renderFilter();

        return (
            <section
                className={classNames('bookmarked', {
                    'bookmarked--loading': isLoading,
                })}
            >
                <div className="bookmarked__content">
                    {filtersPanel ? (
                        <Row gutter={30}>
                            <Col
                                lg={{ span: 6, push: 18 }}
                                md={{ span: 24, push: 0 }}
                            >
                                {filtersPanel}
                            </Col>
                            <Col
                                lg={{ span: 18, pull: 6 }}
                                md={{ span: 24, pull: 0 }}
                            >
                                {documentsBlock}
                            </Col>
                        </Row>
                    ) : (
                        <div>{documentsBlock}</div>
                    )}
                </div>
            </section>
        );
    }

    onPageChange(page: number) {
        const { limit, onSkipChange, onPageChange } = this.props;

        onSkipChange((page - 1) * limit);
        window.scrollTo(0, 0);

        onPageChange && onPageChange(this.currentPage, page);
    }

    onLimitChange(limit: number) {
        const { onLimitChange } = this.props;

        onLimitChange(limit);
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        this.props.beforeFetchOnChange?.();
        this.props.onFetchResults();
    }

    componentDidUpdate(prevProps: IProps): void {
        if (prevProps.currentUserId !== this.props.currentUserId) {
            this.props.beforeFetchOnChange?.();
            this.props.onFetchResults();
        }
    }

    componentWillUnmount(): void {
        this.props.resetPaging();
    }
}

export default withApproveDeleteDocumentModal(DocumentPanel);
