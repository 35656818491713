/**
 * Created by lkarmelo on 23.08.2018.
 */
import { Action, handleActions } from 'redux-actions';

import { DEFAULT_PAGING_ITEMS_LIMIT } from 'app/utils/constants';

import { filtersReducer } from './search/filters';
import paging from './search/searchPaging';
import sorting from './search/sorting';

import { setRecommendations } from '../actions/recommendations';
import * as Store from '../store/StoreNamespace';
import { contextMixinReducers } from '../context/reducer';

const recommendationsListReducer = handleActions<
    Partial<Store.IRecommendations>
>(
    {
        [setRecommendations.toString()](
            state: Store.IRecommendations,
            { payload }: Action<Store.IRecommendations>
        ) {
            return payload;
        },
    },
    {}
);

export default contextMixinReducers(
    {},
    {
        paging: (
            state = { limit: DEFAULT_PAGING_ITEMS_LIMIT, skip: 0 },
            action
        ) => paging(state, action),
        list: recommendationsListReducer,
        filters: filtersReducer,
        sorting,
    }
);
