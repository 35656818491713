import React, { useState, useMemo, useLayoutEffect } from 'react';
import { useTruncate } from '@nkc-frontend/nkc-react-hooks';
import classNames from 'classnames';
import { usePrevious } from 'react-use';

import CarouselWidget from 'app/components/common/Wigets/CarouselWidget';
import ISlide from 'app/components/common/Wigets/CarouselWidget/interfaces/ISlide';
import { IExtractedService } from 'app/redux/store/StoreNamespace';
import clientRoutes from 'app/routing/clientRoutes';
import { CURRENT_HTTP_URL } from 'app/utils/constants';

import IProps from './interfaces/IServicesProps';
import styles from './Services.scss';

const Services: React.FC<IProps> = ({ services, className }) => {
    const [currentServiceIndex, setCurrentServiceIndex] = useState(0);
    const prevServices = usePrevious(services);

    const [
        containerRef,
        isToggleTruncateBtnShown,
        ,
        isManuallyOpened,
        setIsManuallyOpened,
        showHideOpenBtnIfOverflown,
    ] = useTruncate<HTMLDivElement>(styles.servicesContentOpen, 'height', 2);

    if (
        prevServices &&
        prevServices !== services &&
        currentServiceIndex !== 0
    ) {
        setCurrentServiceIndex(0);
    }

    useLayoutEffect(() => {
        setIsManuallyOpened(false);
        showHideOpenBtnIfOverflown();
    }, [currentServiceIndex, services, showHideOpenBtnIfOverflown]);

    const servicesSlides = useMemo(
        () =>
            services.map<ISlide<IExtractedService>>((service) => ({
                value: service,
                renderSlide: () => (
                    <div className={styles.services}>
                        <div className={styles.servicesBreakContainer}>
                            <a
                                className={styles.servicesTitleLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${CURRENT_HTTP_URL}${clientRoutes.documentRedirect.getUrl(
                                    { id: service.id }
                                )}`}
                            >
                                <h2 className={styles.servicesTitle}>
                                    {service.title}:
                                </h2>
                            </a>
                            <div
                                ref={containerRef}
                                className={classNames(styles.servicesContent, {
                                    [styles.servicesContentOpen]:
                                        isManuallyOpened,
                                })}
                                dangerouslySetInnerHTML={{
                                    __html: service.content,
                                }}
                            />
                        </div>
                        {isToggleTruncateBtnShown && !isManuallyOpened && (
                            <button
                                className={classNames(
                                    'btn',
                                    styles.servicesToggleTruncate
                                )}
                                onClick={() => setIsManuallyOpened(true)}
                            >
                                Подробнее
                            </button>
                        )}
                        {isToggleTruncateBtnShown && isManuallyOpened && (
                            <button
                                className={classNames(
                                    'btn',
                                    styles.servicesToggleTruncate
                                )}
                                onClick={() => setIsManuallyOpened(false)}
                            >
                                Свернуть
                            </button>
                        )}
                    </div>
                ),
            })),
        [
            services,
            isToggleTruncateBtnShown,
            isManuallyOpened,
            setIsManuallyOpened,
        ]
    );

    if (!services || services.length === 0) {
        return null;
    }

    return (
        <CarouselWidget
            className={className}
            slides={servicesSlides}
            renderSlidesCount={(index, count, service) => (
                <div className={styles.servicesFooter}>
                    <div className={styles.servicesOrgs}>
                        {Array.isArray(service.organizations) &&
                        service.organizations.length > 0
                            ? service.organizations
                                  .map(({ title }) => title)
                                  .join(', ')
                            : 'Все организации'}
                    </div>
                    <span className={styles.servicesCount}>
                        Сервис {index + 1} из {count}
                    </span>
                </div>
            )}
            currentSlideIndex={currentServiceIndex}
            setCurrentSlideIndex={setCurrentServiceIndex}
        />
    );
};

Services.defaultProps = {
    services: [],
};

export default Services;
