import { createSelector } from 'reselect';

import * as Store from 'app/redux/store/StoreNamespace';
import apiCalls from 'app/api/apiCalls';
import Api from 'app/api/Api';

import { ISetSearchResultsPayload } from '../actions/search/results';

type ResponseBody =
    | Api.IGlobalSearchResponseBody
    | Api.IPersonSearchResponseBody;
export interface ISearchTabConfig<T extends ResponseBody = ResponseBody> {
    apiCallName: Omit<keyof typeof apiCalls, 'forms'>;
    sortingOptions: Store.ISortingOption[];
    getFilterStats(state: Store.IState): Store.IFilterStats;
    getSearchResultsPayload(
        searchResponse: T
    ): ISetSearchResultsPayload<
        T extends Api.IGlobalSearchResponseBody
            ? Api.IDocumentSearchResponseBody
            : Api.IPersonSearchResponseBody
    >;
}

export const searchTargetToTabConfig: Record<
    Store.SearchTarget,
    ISearchTabConfig
> = {
    [Store.SearchTarget.document]: {
        apiCallName: 'search',
        sortingOptions: [
            Store.sortingOptions.RelevancySortingOption,
            Store.sortingOptions.DateSortingOption,
        ],
        getFilterStats(state: Store.IState) {
            return state.search.results.filterStats;
        },
        getSearchResultsPayload(
            searchResponse: Api.IGlobalSearchResponseBody
        ): ISetSearchResultsPayload<Api.IDocumentSearchResponseBody> {
            return {
                results: searchResponse.documents,
                personResults: searchResponse.persons,
                searchTarget: Store.SearchTarget.document,
                askFeedback: searchResponse.askFeedback,
                dynamicFilterVisible:
                    searchResponse.documents.dynamicFilterVisible,
            };
        },
    },
    [Store.SearchTarget.employee]: {
        apiCallName: 'personSearch',
        sortingOptions: [
            Store.sortingOptions.RelevancySortingOption,
            Store.sortingOptions.NameSortingOption,
        ],
        getFilterStats(state: Store.IState) {
            return state.search.personResults.filterStats;
        },
        getSearchResultsPayload(
            searchResponse: Api.IPersonSearchResponseBody
        ): ISetSearchResultsPayload<Api.IPersonSearchResponseBody> {
            return {
                personResults: searchResponse,
                searchTarget: Store.SearchTarget.employee,
                askFeedback: searchResponse.askFeedback,
                dynamicFilterVisible: searchResponse.dynamicFilterVisible,
            };
        },
    },
};

const searchTabConfigSelector = createSelector(
    ({ mainSearchPageActiveTab }: Store.IState) => mainSearchPageActiveTab,
    ({ searchTabs }: Store.IState) => searchTabs,
    (activeTab, searchTabs): ISearchTabConfig => {
        const searchTarget = searchTabs.tabs?.[activeTab]?.searchTarget;

        return (
            searchTargetToTabConfig[searchTarget] ?? searchTargetToTabConfig.DOC
        );
    }
);

export default searchTabConfigSelector;
