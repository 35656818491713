/**
 * Created by Lkarmelo on 27.03.2018.
 */

import { createSelector } from 'reselect';

import * as Store from 'app/redux/store/StoreNamespace';

export const errorsShownInMainErrorComponent = createSelector(
    (state: Store.IState) => state.loading.loadingErrors,
    (errors: Store.ILoadingRequestsErrors) => {
        const shownErrors = {};
        Object.keys(errors).reduce((res, requestName) => {
            if (errors[requestName].showInMainErrorComponent) {
                res[requestName] = errors[requestName];
            }
            return res;
        }, shownErrors);
        return shownErrors;
    }
);
