/**
 * Created by lkarmelo on 17.09.2019.
 */

import React, { useMemo } from 'react';
import classNames from 'classnames';

import * as Store from 'app/redux/store/StoreNamespace';

import ActiveIndicator from '../../ActiveIndicator/ActiveIndicator';
import CardTable from '../Table/CardTable';
import * as cardTableStyles from '../Table/CardTable.scss';

interface ICardGroupedProps {
    grouped: Store.IGroupedSnippets;
    paging: Store.IPaging;
    sorting: Store.IObjectCardTableSorting;
    onChangeSkip(tableName: string, skip: number): void;
    onChangeLimit(tableName: string, limit: number): void;
    onChangeSorting(
        tableName: string,
        field: string | string[],
        order: string
    ): void;
}

type RawRow = {
    docId: string;
    title: string;
    orgs: Store.IOrg[];
    activeStatus: Store.IActivityStatus;
};

type TableRow = Omit<RawRow, 'docId'>;

const sortArrowClass = classNames(
    'nkc-icon',
    'nkc-icon-arrow-down',
    cardTableStyles.cardTableSortArrow
);

const columns = [
    {
        Header: (
            <span>
                Документ
                <span className={sortArrowClass} />
            </span>
        ),
        accessor: 'title',
        sortingFieldName: 'title.keyword',
        className: cardTableStyles.cardTablePreWrap,
    },
    {
        Header: (
            <span>
                Организация
                <span className={sortArrowClass} />
            </span>
        ),
        accessor: 'orgs',
        Cell: ({ row }: { row: TableRow }) => (
            <div>
                {Array.isArray
                    ? row.orgs.map((org) => <div key={org.id}>{org.title}</div>)
                    : null}
            </div>
        ),
        sortingFieldName: 'organizations.title.keyword',
        className: cardTableStyles.cardTablePreWrap,
    },
    {
        Header: (
            <span>
                Степень сходства
                <span className={sortArrowClass} />
            </span>
        ),
        accessor: 'similarity',
        sortingFieldName: 'similarity',
    },
    {
        Header: (
            <span>
                Состояние
                <span className={sortArrowClass} />
            </span>
        ),
        accessor: 'activeStatus',
        Cell: ({ row }: { row: TableRow }) => (
            <ActiveIndicator activeStatus={row.activeStatus} />
        ),
        sortingFieldName: 'activeStatus.ordering',
    },
];

const CardGrouped: React.FC<ICardGroupedProps> = ({
    grouped,
    paging,
    onChangeLimit,
    onChangeSkip,
    onChangeSorting,
    sorting,
}) => {
    const data = useMemo((): RawRow[] => {
        const getTitle = (meta) => {
            if (meta.file && meta.file.filename) {
                return `${meta.file.filename} (from: ${meta.title})`;
            }
            return meta.title;
        };

        return grouped && Array.isArray(grouped.items)
            ? grouped.items.map(({ item: gr, similarity }) => {
                  const activeStatus = gr.meta?.activeStatus;
                  return {
                      docId: gr.id,
                      title: getTitle(gr.meta),
                      similarity:
                          typeof similarity === 'number' && similarity > 0
                              ? `${Math.floor(similarity * 100)} %`
                              : '< 60 %',
                      activeStatus,
                      orgs: gr.meta.orgs,
                  };
              })
            : [];
    }, [grouped]);

    return (
        <CardTable
            title="Связанные документы"
            tableName="grouped"
            sortable
            defaultSort={sorting}
            data={data}
            columns={columns}
            totalCount={grouped && grouped.totalCount}
            onChangeSkip={onChangeSkip}
            onChangeLimit={onChangeLimit}
            paging={paging}
            onChangeSorting={onChangeSorting}
        />
    );
};

export default CardGrouped;
