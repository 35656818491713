/**
 * Created by Lkarmelo on 14.06.2018.
 */

import { MiddlewareAPI } from 'redux';
import { AjaxResponse } from 'rxjs/observable/dom/AjaxObservable';
import { Observable } from 'rxjs/Observable';

import * as Store from '../store/StoreNamespace';
import {
    fetchNumberOfEducationMaterials,
    setNumberOfEducationMaterials,
} from '../actions/education';
import { SearchDocumentName } from '../actions/interfaces/DocumentEnum';
import { withContext } from '../context/connectWithContext';

export const loadNumberOfEducationMaterials = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }
) =>
    action$.ofType(fetchNumberOfEducationMaterials.toString()).mergeMap(() =>
        apiCall
            .numberOfEducationMaterials()
            .map((r: AjaxResponse) => {
                const { response } = r;

                const actionPayload: Store.INumberOfEducationMaterials = {
                    numberOfAll: response.total,
                    numberOfRecommended: response.recommendations.total,
                    numberOfMandatoryRecommended:
                        response.recommendations.mandatory,
                    numberOfPlanning:
                        response.documentStatus[
                            Store.EducationMaterialStatusValue.Planning
                        ],
                    numberOfActive:
                        response.documentStatus[
                            Store.EducationMaterialStatusValue.Active
                        ],
                    numberOfDone:
                        response.documentStatus[
                            Store.EducationMaterialStatusValue.Done
                        ],
                };
                return withContext(
                    setNumberOfEducationMaterials(actionPayload),
                    SearchDocumentName.education
                );
            })
            .catch((e) => {
                console.error(e);
                return Observable.empty();
            })
    );
