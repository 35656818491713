/**
 * Created by Lkarmelo on 05.09.2017.
 */

import { Action, createAction, createActions } from 'redux-actions';

import * as Store from '../store/StoreNamespace';
import Api from '../../api/Api';

interface IFetchObjectCard {
    (
        id: string,
        query?: string,
        expansion?: string
    ): Action<IFetchObjectCardPayload>;
}
interface IFetchObjectCardPayload {
    id: string;
    query?: string;
    expansion?: string;
}
export const fetchObjectCard: IFetchObjectCard = createAction<
    IFetchObjectCardPayload,
    string,
    string,
    string
>('FETCH_OBJECT_CARD', (id, query, expansion) => ({ id, query, expansion }));

export const setObjectCard =
    createAction<Api.IObjectCardResponse>('SET_OBJECT_CARD');
export const resetObjectCard = createAction('RESET_OBJECT_CARD');

export interface IObjectCardTableSortingPayload {
    field: string | string[];
    order: Store.SortingOrder;
}
const pagingActions = {
    SET_LIMIT: (limit: number): number => limit,
    SET_SKIP: (skip: number): number => skip,
};
const sortingAction = {
    SET_SORTING: (
        field: string | string[],
        order: Store.SortingOrder
    ): IObjectCardTableSortingPayload => ({ field, order }),
};
// в @types/redux-actions кривые типы для createActions
export const objectCardTablesActions: any = createActions({
    OBJECT_CARD: {
        GROUPED: {
            ...pagingActions,
            ...sortingAction,
        },
        LINKS: {
            ...pagingActions,
            ...sortingAction,
        },
    },
}).objectCard;

export const setObjectCardLinksGrouped = createAction<Api.ILinkedResponseBody>(
    'SET_OBJECT_CARD_LINKS_GROUPED'
);

export const fetchObjectCardSimilar = createAction<{ id: string }>(
    'FETCH_OBJECT_CARD_SIMILAR'
);

// ------------------КОНЦЕПТЫ------------------------
export interface ILoadParentConceptsPayload {
    concept: Store.ISchemeConcept;
}
export const fetchParentConcepts = createAction<ILoadParentConceptsPayload>(
    'FETCH_PARENT_CONCEPTS'
);

export interface ILoadMoreParentConcepts {
    originalConcept: Store.ISchemeConcept;
    concept: Store.IConcept;
    childrenConceptUri: string;
}
export const fetchMoreParentConcepts = createAction<ILoadMoreParentConcepts>(
    'FETCH_MORE_PARENT_CONCEPTS'
);

export const fetchChildrenConcepts = createAction<ILoadParentConceptsPayload>(
    'FETCH_CHILDREN_CONCEPTS'
);

export interface IAddParentsToConceptHierarchyTree {
    originalConcept: Store.IConcept;
    concepts: Record<string, Store.IConceptWithChildren>;
    topConceptUri: string | undefined;
}
export const addParentsToConceptHierarchyTree =
    createAction<IAddParentsToConceptHierarchyTree>(
        'ADD_PARENT_CONCEPTS_TO_CONCEPT_HIERARCHY'
    );

export interface IAddChildrenToConceptHierarchyTree {
    originalConcept: Store.IConcept;
    concepts: Record<string, Store.IConceptWithChildren>;
}
export const addChildrenToConceptHierarchyTree =
    createAction<IAddChildrenToConceptHierarchyTree>(
        'ADD_CHILD_CONCEPTS_TO_CONCEPT_HIERARCHY'
    );
