/**
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 20.02.2018
 * -----
 * Last Modified: 30.10.2018 19:13:30
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import * as Store from 'app/redux/store/StoreNamespace';

export enum SearchDocumentName {
    related = 'related',
    favorites = 'favorites',
    subscribes = 'subscribes',
    readLater = 'readLater',
    catalogueDocuments = 'catalogueDocuments',
    catalogueDocumentsNew = 'catalogueDocumentsNew',
    documentManagementAll = 'documentManagementAll',
    documentManagementOwn = 'documentManagementOwn',
    education = 'education',
    uploadDocument = 'uploadDocument',
    search = 'search',
    objectCardSimilar = 'objectCardSimilar',
}

export const searchResultDocumentList = Object.keys(SearchDocumentName).filter(
    (key) => key !== SearchDocumentName.search
);

export const documentState =
    (branch: Exclude<SearchDocumentName, SearchDocumentName.search>) =>
    (state: Store.IState): Store.ISearch =>
        state[branch];
