function vh(percent) {
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (percent * h) / 100;
}

const SEARCH_PANEL_MARGIN = 32;
const SEARCH_PANEL_TOP = vh(60);

export const DROPDOWN_PADDING = 44;
export const MAX_DROPDOWN_HEIGHT = SEARCH_PANEL_TOP - SEARCH_PANEL_MARGIN;
