/**
 * Created by Lkarmelo on 01.02.2018.
 */

import React, { PureComponent } from 'react';

export default (WrappedComponent: React.ComponentType): any =>
    class ScrollToTopOnMount extends PureComponent<any> {
        render(): JSX.Element {
            return <WrappedComponent {...this.props} />;
        }

        componentWillMount(): void {
            window.scrollTo(0, 0);
        }
    };
