import React, { useCallback } from 'react';
import numeral from 'numeral';

import * as Store from 'app/redux/store/StoreNamespace';

import { MessageType } from '../../types';
import { getResultMessagesBySearchTarget } from '../../helpers';
import * as styles from '../../SearchResultsMessage.scss';

interface IResultMessageProps {
    type: MessageType;
    searchTarget: Store.SearchTarget;
    count: number;
}

export const ResultMessage: React.FC<IResultMessageProps> = ({
    type,
    searchTarget,
    count,
}) => {
    const resultMessages = getResultMessagesBySearchTarget(
        searchTarget || Store.SearchTarget.document
    );
    const renderCount = useCallback(
        () => numeral(count).format('0,0').replace(/,/g, ' '),
        [count, resultMessages]
    );

    return (
        <div
            className={
                type === 'found'
                    ? styles.searchResultsMessageMessage
                    : styles.searchResultsMessageTitle
            }
        >
            {type === 'found' ? (
                <>
                    {resultMessages.found}: {renderCount()}
                </>
            ) : (
                resultMessages[type]
            )}
        </div>
    );
};
