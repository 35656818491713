/**
 * Created by Lkarmelo on 13.02.2018.
 */

import { createSelector } from 'reselect';

import * as Store from 'app/redux/store/StoreNamespace';
import { IOption } from 'app/components/common/controls/Option';

export const skillsToOptions = createSelector(
    ({
        state,
    }: {
        state: Store.IState;
        meta: any;
    }): Store.ISimpleCatalogItem[] => state.skills,
    ({ meta }: { state: Store.IState; meta: any }): any => meta,
    (skills: Store.ISimpleCatalogItem[], meta: any): IOption[] =>
        skills.map((skill) => ({ label: skill.title, value: skill.code, meta }))
);
