import { Action } from 'redux-actions';

import clientRoutes from 'app/routing/clientRoutes';
import history from 'app/history';

import { redirectToDocument } from '../actions/education';
import * as Store from '../store/StoreNamespace';

export const redirectToDocumentOnSelectCompetence = (action$) =>
    action$
        .ofType(redirectToDocument.toString())
        .do((action: Action<Store.ISimpleCatalogItem>) =>
            // set filter in url
            history.push(
                clientRoutes.documentManagement.subRoutes.all.getUrl(null, {
                    competencies: action.payload.code,
                })
            )
        )
        .ignoreElements();
