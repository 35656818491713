/**
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 27.06.2018
 * -----
 * Last Modified: 29.06.2018 12:47:41
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import { createAction } from 'redux-actions';

import Api from 'app/api/Api';

export const startNotificationsPolling = createAction(
    'NOTIFICATIONS_START_POLLING'
);

export const fetchNotificationCount = createAction('NOTIFICATIONS_FETCH_COUNT');
export const setNotificationsCount = createAction<number>(
    'NOTIFICATIONS_SET_COUNT'
);
export const setNotificationsIntervalId = createAction<number>(
    'NOTIFICATIONS_SET_INTERVAL_ID'
);

// загружают N уведомлений для страницы уведомлений
export const fetchNotificationListAndCount = createAction<number | void>(
    'NOTIFICATIONS_FETCH_LIST_AND_COUNT'
);
export const setNotificationListAndCount =
    createAction<Api.INotificationsResponse>(
        'NOTIFICATIONS_SET_LIST_AND_COUNT'
    );

// загружают последние N уведомлений в попап
export const fetchLatestNotificationsAndCount = createAction<number | void>(
    'NOTIFICATIONS_FETCH_LATEST_AND_COUNT'
);
export const setLatestNotificationsAndCount =
    createAction<Api.INotificationsResponse>(
        'NOTIFICATIONS_SET_LATEST_AND_COUNT'
    );

// экшн отправляет запрос, чтобы пометить уведомления как прочитанные
export const requestNotificationsRead = createAction<string[]>(
    'NOTIFICATIONS_REQUEST_READ'
);
// после того, как запрос успешно выполнился, обновляем стор
export const markNotificationsRead = createAction<string[]>(
    'NOTIFICATIONS_MARK_READ'
);
