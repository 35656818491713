/**
 * Created by lkarmelo on 21.11.2019.
 */

import React, { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import Tooltip from 'antd/es/tooltip';

import DocumentSnippet from 'app/components/search-snippets/DocumentSnippet';
import ChangeDocumentCategory from 'app/components/catalogue/ChangeDocumentCategory';
import * as Store from 'app/redux/store/StoreNamespace';
import { ISelectOption } from 'app/modules/components/TreeSelect';

import * as styles from './CatalogueDocumentSnippet.scss';

interface IProps
    extends ICatalogueDocumentSnippetActionProps,
        ICatalogueDocumentSnippetOwnProps,
        ICatalogueDocumentSnippetStateProps {}

export interface ICatalogueDocumentSnippetStateProps {
    changeCategoryFormStatus: Store.FormStatus;
}

export interface ICatalogueDocumentSnippetOwnProps {
    document: Store.IDocumentSnippet;
    categories: ISelectOption[];
    activeCategoryId: string;
}

export interface ICatalogueDocumentSnippetActionProps {
    resetChangeCategoryForm(): void;
    changeCategory(
        docId: string,
        categoryId: string,
        newCategoryId: string
    ): void;
    changeCategoriesDocumentCountOnDocumentChange(
        categoryId: string,
        newCategoryId: string
    ): void;
    confirmCategory(docId: string, categoryId: string): void;
    revertCategory(docId: string, categoryId: string): void;
    markDocumentCategoryConfirmed(docId: string): void;
}

const CatalogueDocumentSnippet: React.FC<IProps> = (props) => {
    const {
        document,
        categories,
        resetChangeCategoryForm,
        changeCategory,
        changeCategoryFormStatus,
        activeCategoryId,
        markDocumentCategoryConfirmed,
        confirmCategory,
        revertCategory,
        changeCategoriesDocumentCountOnDocumentChange,
    } = props;

    const [isChangeModalOpen, setIsChangeModalOpen] = useState(false);
    // категория, в которую перенесли документ при changeCategory
    const newCategory = useRef(null);
    // из-за того, что модалка создаётся в каждом снипете, а форма в редаксе одна на все документы - приходится запоминать,
    // что именно у этого документа изменяется категория. TODO: вынести модалку на уровень выше, чтобы она была одна для всех снипетов
    const changingCategory = useRef(false);

    const openChangeCategoryModal = useCallback(() => {
        changingCategory.current = true;
        setIsChangeModalOpen(true);
    }, []);
    const closeChangeCategoryModal = useCallback(() => {
        changingCategory.current = false;
        setIsChangeModalOpen(false);
    }, []);

    const _resetChangeCategoryForm = useCallback(() => {
        if (changingCategory.current) {
            resetChangeCategoryForm();
            changingCategory.current = false;
        }
    }, [resetChangeCategoryForm]);
    const onChangeCategorySuccess = useCallback(() => {
        if (changingCategory.current) {
            markDocumentCategoryConfirmed(document.id);
            setIsChangeModalOpen(false);
            changeCategoriesDocumentCountOnDocumentChange(
                activeCategoryId,
                newCategory.current
            );
        }
    }, [
        markDocumentCategoryConfirmed,
        document,
        activeCategoryId,
        setIsChangeModalOpen,
        changeCategoriesDocumentCountOnDocumentChange,
    ]);
    const sendChangeCategoryForm = useCallback(
        (newCategoryId: string) => {
            newCategory.current = newCategoryId;
            changeCategory(document.id, activeCategoryId, newCategoryId);
        },
        [changeCategory, document, activeCategoryId]
    );

    const renderControls = useCallback(
        () =>
            document.categoryConfirmed ? (
                // если была изменена категория, то нельзя делать revert, поэтому не отображаем никакие действия
                newCategory.current ? null : (
                    <Tooltip
                        placement="bottomRight"
                        title="Отменить"
                        mouseEnterDelay={0.3}
                    >
                        <button
                            className={`btn ${styles.catalogueDocumentSnippetControlButton} ${styles.catalogueDocumentSnippetRevertBtn}`}
                            onClick={() => {
                                revertCategory(document.id, activeCategoryId);
                            }}
                        >
                            <span className="nkc-icon-redo" />
                        </button>
                    </Tooltip>
                )
            ) : (
                <>
                    <Tooltip
                        placement="bottomRight"
                        title="Задать другую категорию"
                        mouseEnterDelay={0.3}
                    >
                        <button
                            className={classNames(
                                'btn',
                                styles.catalogueDocumentSnippetControlButton,
                                styles.catalogueDocumentSnippetChangeCategoryBtn
                            )}
                            onClick={() => openChangeCategoryModal()}
                        >
                            <span className="nkc-icon-shuffle" />
                        </button>
                    </Tooltip>
                    <Tooltip
                        placement="bottomRight"
                        title="Подтвердить категорию"
                        mouseEnterDelay={0.3}
                    >
                        <button
                            className={classNames(
                                'btn',
                                styles.catalogueDocumentSnippetControlButton,
                                styles.catalogueDocumentSnippetAcceptCategoryBtn
                            )}
                            onClick={() => {
                                newCategory.current = null;
                                confirmCategory(document.id, activeCategoryId);
                            }}
                        />
                    </Tooltip>
                </>
            ),
        [document]
    );

    return (
        <>
            <ChangeDocumentCategory
                close={closeChangeCategoryModal}
                isVisible={isChangeModalOpen}
                categories={categories}
                resetForm={_resetChangeCategoryForm}
                formStatus={changeCategoryFormStatus}
                sendForm={sendChangeCategoryForm}
                onSuccess={onChangeCategorySuccess}
            />
            <DocumentSnippet
                className={styles.catalogueDocumentSnippet}
                document={document}
                renderControls={renderControls}
            />
        </>
    );
};

export default CatalogueDocumentSnippet;
