/**
 * Created by lkarmelo on 27.11.2019.
 */

import React, { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';

import IProps from './interfaces/ITagsInputProps';
import * as styles from './TagsInput.scss';

const TagsInput: React.FC<IProps> = (props) => {
    const {
        tags,
        onAdd,
        onRemove,
        separator: SEPARATOR,
        tagRegExp: TAG_REGEXP,
    } = props;
    const [inputValue, setInputValue] = useState('');
    const [isInputFocused, setIsInputFocused] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const focusInput = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        // если пользователь промахивается кликом по инпуту, фокусируемся на нём
        if (e.target === containerRef.current) {
            inputRef.current && inputRef.current.focus();
        }
    }, []);

    const onInputFocused = useCallback(() => setIsInputFocused(true), []);
    const onInputBlur = useCallback(() => setIsInputFocused(false), []);

    const parseTags = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!e.currentTarget.value.includes(SEPARATOR)) {
                setInputValue(e.currentTarget.value);
                return;
            }
            // здесь могут быть подстроки без букв и чисел, такие не считать ключевым словом и игнорировать
            const possibleTags = e.currentTarget.value
                .split(SEPARATOR)
                .map((t) => t.trim());

            const filteredTags = possibleTags.filter((pT) =>
                TAG_REGEXP.test(pT)
            );

            if (filteredTags.length > 0) {
                onAdd(filteredTags);
                setInputValue('');
            }
        },
        [onAdd, SEPARATOR, TAG_REGEXP]
    );

    const addTagOnEnter = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter' && e.currentTarget.value) {
                e.preventDefault();
                e.stopPropagation();

                const tag = e.currentTarget.value.trim();

                if (TAG_REGEXP.test(tag)) {
                    onAdd([tag]);
                    setInputValue('');
                }
            }
        },
        [onAdd, TAG_REGEXP]
    );

    return (
        <div
            className={classNames(styles.tagsInput, {
                [styles.tagsInputFocused]: isInputFocused,
            })}
            onClick={focusInput}
        >
            <div ref={containerRef} className={styles.tagsInputContainer}>
                {Array.isArray(tags) &&
                    tags.map((tag) => (
                        <div
                            key={tag}
                            className={styles.tagsInputTag}
                            title={tag}
                        >
                            <span className={styles.tagsInputTagText}>
                                {tag}
                            </span>
                            <button
                                type="button"
                                className={`btn ${styles.tagsInputTagRemove}`}
                                onClick={() => onRemove(tag)}
                            />
                        </div>
                    ))}
                <input
                    ref={inputRef}
                    value={inputValue}
                    className={styles.tagsInputInput}
                    type="text"
                    onChange={parseTags}
                    onKeyDown={addTagOnEnter}
                    onFocus={onInputFocused}
                    onBlur={onInputBlur}
                    placeholder={
                        !tags || tags.length === 0
                            ? 'Введите ключевые слова через запятую'
                            : undefined
                    }
                />
            </div>
        </div>
    );
};

TagsInput.defaultProps = {
    tags: [],
    separator: ',',
    tagRegExp: /[\d\wА-Яа-яеЁ]+/g,
};

export default TagsInput;
