import { useMemo, useRef } from 'react';

/**
 * Created by lkarmelo on 12.08.2019.
 */

export const useControlledTimeout = () => {
    const timeoutIdRef = useRef(undefined);
    return useMemo(
        () => ({
            start(cb: () => void, time: number) {
                if (timeoutIdRef.current) {
                    window.clearTimeout(timeoutIdRef.current);
                }
                timeoutIdRef.current = setTimeout(cb, time);
                return timeoutIdRef.current;
            },
            clear() {
                window.clearTimeout(timeoutIdRef.current);
                timeoutIdRef.current = undefined;
            },
        }),
        []
    );
};
