/**
 * Created by Lkarmelo on 23.08.2017.
 */

import { handleActions, Action } from 'redux-actions';

import * as Store from 'app/redux/store/StoreNamespace';
import Api from 'app/api/Api';
import {
    setSearchResults,
    updateSearchResult,
    clearCorrectedQuery,
    ISetSearchResultsPayload,
    addToSearchResults,
} from 'app/redux/actions/search/results';
import {
    ISetEducationMaterialStatusPayload,
    setEducationMaterialStatus,
} from 'app/redux/actions/education';
import { documentTransformFileLink } from 'app/utils/document/documentTransformFileLink';
import { getFilterStatsFromAggregation } from 'app/utils/getFilterStatsFromAggregation';

const mergeDocumentSearchResults = (
    results: Partial<Store.IDocumentResults>,
    payload: ISetSearchResultsPayload
): Store.IDocumentResults => {
    if (
        payload.searchTarget &&
        payload.searchTarget === Store.SearchTarget.employee
    ) {
        return results;
    }

    const resultsList = results?.list;

    const hashMap = resultsList
        ? new Map<string, Store.IDocumentSnippet>(
              resultsList.map(
                  (item) => [item.id, item] as [string, Store.IDocumentSnippet]
              )
          )
        : new Map();

    const payloadList = (<Api.IDocumentSearchResponseBody>payload.results)
        ?.list;

    const mergedResultList = payloadList
        ? payloadList.map(documentTransformFileLink).map((item) => {
              if (
                  hashMap.has(item.id) &&
                  !!hashMap.get(item.id).modified &&
                  (<Store.IDocumentResults>payload).modified <
                      hashMap.get(item.id).modified
              ) {
                  return Object.assign(item, hashMap.get(item.id), {
                      modified: null,
                  });
              }
              return item;
          })
        : undefined;

    const aggregations = (<Api.IDocumentSearchResponseBody>payload.results)
        ?.aggregations;

    return Object.assign(payload.results ?? {}, {
        list: mergedResultList,
        filterStats: getFilterStatsFromAggregation(aggregations),
        askFeedback: payload.askFeedback,
    });
};

const updateDocumentSearchResults = (
    state: Store.IDocumentResults,
    document: Store.IUpdateDocumentSnippet
): Store.IDocumentResults => {
    const results: Store.IDocumentSnippet[] = state?.list;
    const index: number = results
        ? results.findIndex((item) => item.id === document.id)
        : -1;
    if (index === -1) {
        return state;
    }

    const updateDocument: Store.IDocumentSnippet = {
        ...results[index],
        ...document,
        modified: Number(new Date()),
    };

    return {
        ...state,
        list: [
            ...results.slice(0, index),
            updateDocument,
            ...results.slice(index + 1),
        ],
    };
};

export default handleActions<Partial<Store.IDocumentResults>, any>(
    {
        [setSearchResults.toString()](
            state: Store.IDocumentResults,
            { payload }: Action<ISetSearchResultsPayload>
        ) {
            return mergeDocumentSearchResults(state, payload);
        },
        [clearCorrectedQuery.toString()](state: Store.IDocumentResults) {
            if (!state || !state.correctedQuery) {
                return state;
            }
            const nextState = { ...state };
            delete nextState.correctedQuery;
            return nextState;
        },
        [updateSearchResult.toString()](
            state: Store.IDocumentResults,
            { payload }: Action<Store.IUpdateDocumentSnippet>
        ) {
            return updateDocumentSearchResults(state, payload);
        },
        [setEducationMaterialStatus.toString()](
            state: Store.IDocumentResults,
            { payload }: Action<ISetEducationMaterialStatusPayload>
        ) {
            if (!state.list) {
                return state;
            }

            const nextState = { ...state };
            const nextResultsList = nextState.list.slice();
            const docToChange = nextResultsList.find(
                (res) => res.id === payload.id
            );

            if (!docToChange) {
                return state;
            }

            docToChange.meta = { ...docToChange.meta, status: payload.status };

            nextState.list = nextResultsList;

            return nextState;
        },
        [addToSearchResults.toString()](
            state: Store.IDocumentResults,
            { payload }: Action<ISetSearchResultsPayload>
        ) {
            if (
                payload.searchTarget &&
                payload.searchTarget === Store.SearchTarget.employee
            ) {
                return state;
            }

            const resList = (<Api.IDocumentSearchResponseBody>payload.results)
                ?.list;

            let nextResultList: Store.IDocumentSnippet[];
            if (Array.isArray(resList)) {
                nextResultList = Array.isArray(state.list)
                    ? state.list.slice()
                    : [];
                nextResultList.push(...resList);
            }

            return {
                ...state,
                list: nextResultList ?? state.list,
                askFeedback: payload.askFeedback,
            };
        },
    },
    {} // -- default results value
);
