import * as styles from '../FixedHeader.scss';

const fixedHeaderHeight = 60;

export const scrollingHeader = (ref, toggleIsHeaderFixed, isTourOpen) => {
    let lastScrollPos = window.pageYOffset;
    let headerFixed = false;
    let shouldRaiseOrLowerHeader = false;
    let staticHeaderHeight;

    const staticHeader = () => {
        // делаем хедер снова static и прекращаем анимировать

        ref.current.classList.remove(styles.fixedHeaderFixed);

        shouldRaiseOrLowerHeader = false;
        headerFixed = false;
        toggleIsHeaderFixed(false);
    };

    const hideShowHeader = () => {
        if (!ref.current) {
            return;
        }

        const scrollY = window.pageYOffset;
        const scrollDif = scrollY - lastScrollPos;
        const isScrollToBottom = scrollDif > 0;
        lastScrollPos = scrollY;

        if (!headerFixed) {
            // приходится постоянно обновлять высоту хедера, потому что из-за анимации первого поиска у него может сильно
            // увеличиваться высота
            staticHeaderHeight = ref.current.clientHeight;
        }

        const headerStateThreshold = staticHeaderHeight;

        if (
            !headerFixed &&
            isScrollToBottom &&
            scrollY >= headerStateThreshold &&
            !document.body.classList.contains(styles.searchNoSearchDone) // если нет результатов поиска, игнорируем
        ) {
            // делаем хедер fixed и ставим отрицательный top, чтобы дальше анимировать выезд сверху-вниз на скролл

            staticHeaderHeight = ref.current.clientHeight;

            // нужно присвоить элементу высоту, которую имел хедер, потому что она может быть переменной из-за раскрывающихся
            // фильтров
            (
                document.querySelector(
                    `.${styles.fixedHeaderFiller}`
                ) as HTMLElement
            ).style.height = `${staticHeaderHeight}px`;

            ref.current.classList.add(styles.fixedHeaderFixed);

            ref.current.style.top = `${-fixedHeaderHeight}px`;

            // shouldRaiseOrLowerHeader и isHeaderFixed - разные переменные, потому что анимировать нужно только на
            // коротком отрезке длиной в fixedHeaderHeight
            shouldRaiseOrLowerHeader = true;
            headerFixed = true;
            toggleIsHeaderFixed(true);
        } else if (
            headerFixed &&
            !isScrollToBottom &&
            scrollY > headerStateThreshold &&
            scrollY <= headerStateThreshold + fixedHeaderHeight
        ) {
            // на этом отрезке снова начинаем анимировать хедер
            shouldRaiseOrLowerHeader = true;
        } else if (
            headerFixed &&
            !isScrollToBottom &&
            scrollY <= headerStateThreshold
        ) {
            // делаем хедер снова static и прекращаем анимировать
            staticHeader();
        }
        if (!shouldRaiseOrLowerHeader) {
            return;
        }

        window.requestAnimationFrame(() => {
            // ещё раз проверяем, потому что могла образоваться очередь и тогда будет лишняя анимация
            if (!shouldRaiseOrLowerHeader) {
                return;
            }
            const currentTop = parseInt(ref.current.style.top, 10);
            let nextTop = currentTop + scrollDif;

            if (nextTop >= 0) {
                nextTop = 0;
                shouldRaiseOrLowerHeader = false;
            } else if (nextTop <= -fixedHeaderHeight) {
                nextTop = -fixedHeaderHeight;
                shouldRaiseOrLowerHeader = false;
            }
            ref.current.style.top = `${nextTop}px`;
        });
    };

    if (!isTourOpen) {
        window.addEventListener('scroll', hideShowHeader);

        return () => {
            window.removeEventListener('scroll', hideShowHeader);
        };
    }
    window.removeEventListener('scroll', hideShowHeader);
    staticHeader();
};
