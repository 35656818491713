/**
 * Created by Lkarmelo on 04.12.2017.
 */

import * as React from 'react';
import { connect } from 'react-redux';
import autoBind from 'autobind-decorator';
import Row from 'antd/es/row';
import Col from 'antd/es/col';

import AbstractEditablePanelContainer from 'app/components/common/forms/AbstractEditablePanelContainer';
import EditablePanel from 'app/components/common/forms/EditablePanel';
import { fetchCompetencies } from 'app/redux/actions/competencies';
import {
    initForm,
    changeSubField,
    addSubField,
    removeSubField,
    removeForm,
    sendForm,
} from 'app/redux/actions/forms';
import { setCurrentUserCompetencies } from 'app/redux/actions/currentUserCompetencies';
import { competenciesSortedByGroups } from 'app/redux/selectors/competenciesSortedByGroups';
import { FormName } from 'app/api/FormName';
import * as Store from 'app/redux/store/StoreNamespace';
import { redirectToDocument } from 'app/redux/actions/education';

import IProps, {
    ICompetenciesOwnProps as IOwnProps,
} from './interfaces/ICompetenciesProps';

import CompetenciesForm from '../presentational/Form';
import PresentationalCompetencies from '../presentational/Competencies';

@autoBind
class Competencies extends AbstractEditablePanelContainer<IProps> {
    static defaultProps = {
        competencies: [],
        targetPost: '',
    };

    render(): JSX.Element {
        const {
            formStatus,
            formData,
            targetPost,
            allCompetencies,
            isEditable,
        } = this.props;
        return (
            <EditablePanel
                isEditable={this.state.isEditable}
                title="КОМПЕТЕНЦИИ"
                onStartEdit={this.startEdit}
                onCancelEdit={this.cancelEdit}
                onSave={this.save}
                className="competencies-panel"
                hideEditBtn={!isEditable}
            >
                <Row>
                    <Col lg={19}>
                        {this.state.isEditable ? (
                            <CompetenciesForm
                                status={formStatus}
                                onFormSaved={this.onFormSaveSuccess}
                                fields={{
                                    competencies: {
                                        value: formData.competencies,
                                        type: 'select',
                                        title: '',
                                    },
                                }}
                                onAddCompetence={this.onAddCompetence}
                                onChangeGrade={this.onChangeCompetenceGrade}
                                onChangeCompetence={this.onChangeCompetence}
                                onRemoveCompetence={this.onRemoveCompetence}
                                onClickCompetence={this.onClickCompetence}
                                targetPost={targetPost}
                                allCompetencies={allCompetencies}
                            />
                        ) : (
                            <PresentationalCompetencies
                                competencies={this.props.competencies}
                                targetPost={this.props.targetPost}
                                onClickCompetence={this.onClickCompetence}
                            />
                        )}
                    </Col>
                    <Col lg={5}>
                        <div className="competencies__legend">
                            <div className="competencies__legend-required">
                                <div className="competencies__legend-sample" />
                                <div className="competencies__legend-desc">
                                    Требуемый уровень компетенций текущей
                                    должности
                                </div>
                            </div>
                            <div className="competencies__legend-target">
                                <div className="competencies__legend-sample" />
                                <div className="competencies__legend-desc">
                                    Требуемый уровень компетенций целевой
                                    должности
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </EditablePanel>
        );
    }

    componentWillMount(): void {
        this.props.dispatch(fetchCompetencies());
    }

    onStartEdit(): void {
        const { dispatch, formName, competencies } = this.props;
        dispatch(fetchCompetencies());
        dispatch(initForm(formName, { competencies }));
    }

    onCancelEdit() {
        this.props.dispatch(removeForm(this.props.formName));
    }

    onSave(): void {
        const { dispatch, formName, formData } = this.props;

        const includedCompetencies: { [id: string]: boolean } = {};

        const data = formData.competencies
            .filter((com) => {
                if (
                    (!com.requiredGrade && !com.targetGrade && !com.grade) ||
                    includedCompetencies[com.competenceItem.id]
                ) {
                    return false;
                }
                includedCompetencies[com.competenceItem.id] = true;
                return true;
            })
            .map((com) => ({
                competenceCode: com.competenceItem.code,
                grade: com.grade,
            }));

        dispatch(sendForm(formName, data));
    }

    onFormSaveSuccess(): void {
        this.props.dispatch(
            setCurrentUserCompetencies(this.props.formResponse)
        );
        this.cancelEdit();
    }

    onChangeCompetenceGrade(index: number, grade: number): void {
        const { formData, dispatch, formName } = this.props;
        const nextValue = { ...formData.competencies[index] };
        nextValue.grade = grade;
        dispatch(
            changeSubField(
                formName,
                'competencies',
                index.toString(),
                nextValue
            )
        );
    }

    onChangeCompetence(
        index: number,
        competence: Store.ISimpleCatalogItem
    ): void {
        const { dispatch, formName } = this.props;
        const nextValue: Store.IUserCompetence = { competenceItem: competence };
        nextValue.grade = 0;
        dispatch(
            changeSubField(
                formName,
                'competencies',
                index.toString(),
                nextValue
            )
        );
    }

    onRemoveCompetence(index: number): void {
        const { dispatch, formName } = this.props;
        dispatch(
            removeSubField(formName, 'competencies', index.toString(), null)
        );
    }

    onClickCompetence(competence: Store.ISimpleCatalogItem): void {
        const { dispatch } = this.props;

        dispatch(redirectToDocument(competence));
    }

    onAddCompetence(competence: Store.ISimpleCatalogItem): void {
        const { dispatch, formName } = this.props;
        dispatch(
            addSubField(
                formName,
                'competencies',
                { competenceItem: competence, grade: 0 },
                null
            )
        );
    }
}

const mapStateToProps = (state: Store.IState, props: IOwnProps): IProps => {
    const formName = FormName.UserCompetencies;
    const userWorkInfo = state.currentUserWorkInfo;

    // @ts-ignore
    return {
        formName,
        formData:
            state.forms[formName] &&
            (state.forms[formName].data as {
                competencies: Store.IUserCompetence[];
            }),
        formStatus: state.forms[formName] && state.forms[formName].status,
        formResponse: state.forms[formName] && state.forms[formName].response,
        competencies: competenciesSortedByGroups(state),
        targetPost:
            userWorkInfo &&
            userWorkInfo[0] &&
            userWorkInfo[0].targetPost &&
            userWorkInfo[0].targetPost.title,
        allCompetencies: state.competencies,
        isEditable: props.isEditable,
    };
};

export default connect(mapStateToProps)(Competencies);
