import * as React from 'react';
import classNames from 'classnames';

import {
    IActivityStatus,
    ActivityStatusCode,
} from 'app/redux/store/StoreNamespace';

import * as styles from './ActiveIndicator.scss';

interface IActiveIndicatorProps {
    activeStatus?: IActivityStatus;
}

const ActiveIndicator: React.FC<IActiveIndicatorProps> = ({ activeStatus }) => {
    const { code, title } = activeStatus || {};
    const codeUpperCase = code?.toUpperCase();

    return (
        <div>
            <span
                className={classNames(styles.activeIndicator, {
                    [styles.activeIndicatorNoInfo]: !activeStatus,
                    [styles.activeIndicatorActive]:
                        codeUpperCase === ActivityStatusCode.Active,
                    [styles.activeIndicatorNotActive]:
                        codeUpperCase === ActivityStatusCode.Canceled,
                    [styles.activeIndicatorPlanning]:
                        codeUpperCase === ActivityStatusCode.Planning,
                })}
            >
                {!title ? 'Нет данных' : title}
            </span>
        </div>
    );
};

export default ActiveIndicator;
