import * as React from 'react';

import * as styles from './HintIcon.scss';

interface IProps {
    isActive: boolean;
}

export const HintIcon: React.FC<IProps> = (props) => {
    const { isActive } = props;

    return (
        <svg
            className={!isActive ? styles.hintSvg : styles.hintSvgActive}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM5.69199 5H4C4 3.2 5.28325 2 7.2 2C8.98564 2 10.2 3.14549 10.2 4.7C10.2 5.70942 9.78033 6.44145 8.9 7C8.06962 7.51817 7.74 7.74697 7.74 8.393L7.79 9H6.14L6.07 8.37954C5.98883 7.30283 6.4384 6.74509 7.3 6.2C8.10541 5.68183 8.3 5.42584 8.3 4.8C8.3 4.10584 7.79193 3.61507 7 3.61507C6.20807 3.61507 5.72945 4.25976 5.69199 5ZM8.10822 11.0472C8.10822 11.7538 7.67742 12.1913 6.97815 12.1913C6.27888 12.1913 5.84183 11.7538 5.84183 11.0472C5.84183 10.3339 6.27888 9.8965 6.97815 9.8965C7.67742 9.8965 8.10822 10.3339 8.10822 11.0472Z"
            />
        </svg>
    );
};
