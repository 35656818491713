/**
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 15.01.2018
 * -----
 * Last Modified: 19.02.2018 12:22:23
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import * as React from 'react';
import { connect } from 'react-redux';

import DocumentSnippet from 'app/components/search-snippets/DocumentSnippet';
import IDocumentPanelOwnProps from 'app/components/document/DocumentPanel/interfaces/IDocumentPanelOwnProps';

import DocumentPanel from './DocumentPanel';

const mapStateToFactoryProps = () => {
    const constProps = {
        renderSearchResult: (
            doc,
            onApproveDeleteDocumentClick
        ): JSX.Element => (
            <DocumentSnippet
                document={doc}
                onApproveDeleteDocumentClick={onApproveDeleteDocumentClick}
            />
        ),
    };

    return () => constProps;
};

export default connect<IDocumentPanelOwnProps>(mapStateToFactoryProps)(
    DocumentPanel
);
