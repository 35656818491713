/**
 * Created by Lkarmelo on 19.06.2018.
 */

import { normalize, schema } from 'normalizr';

import Api from 'app/api/Api';

import { createSimpleLoadingEpic } from './utils';

import * as Store from '../store/StoreNamespace';
import { fetchOrgStructure, setOrgStructure } from '../actions/orgstructure';
import {
    employeesRequest,
    employeesResolve,
    employeesReject,
} from '../actions/loading';

export const loadOrgStructureWithEmployees = createSimpleLoadingEpic({
    triggers: [fetchOrgStructure.toString()],
    apiCallName: 'orgStructureWithEmployees',
    actions: {
        requestAction: employeesRequest,
        resolveAction: employeesResolve,
        rejectAction: employeesReject,
        setAction: setOrgStructure,
    },
    retryOnAfterAuth: true,
    fetchAction: fetchOrgStructure,
    transformResponse(
        response: Api.IHierarchicalOrgStructure
    ): Store.INormalizedOrgStructure {
        const subDivisionsToPosts: Store.ISubDivisionsToPostsMap = {};
        const subDivisionsToEmployees: Store.ISubDivisionsToEmployeesMap = {};
        const personToEmployees: Store.IPersonToEmployeeMap = {};

        const divisionSchema = new schema.Entity('divisions', undefined, {
            processStrategy: (
                division: Store.ISimpleCatalogItem,
                parent: Api.IHierarchicalOrgStructure
            ) => {
                let subItems;
                if (parent && parent.childSubdivision) {
                    subItems = parent.childSubdivision.map(
                        (subDiv) => subDiv.division.id
                    );
                }

                parent.employees.forEach((emp) => {
                    if (!subDivisionsToPosts[division.id]) {
                        subDivisionsToPosts[division.id] = [emp.postId];
                    } else {
                        subDivisionsToPosts[division.id].push(emp.postId);
                    }

                    if (!subDivisionsToEmployees[division.id]) {
                        subDivisionsToEmployees[division.id] = [emp.id];
                    } else {
                        subDivisionsToEmployees[division.id].push(emp.id);
                    }

                    if (!personToEmployees[emp.personId]) {
                        personToEmployees[emp.personId] = [emp.id];
                    } else {
                        personToEmployees[emp.personId].push(emp.id);
                    }
                });

                return { item: division, subItems, parent: division.parentId };
            },
        });

        const employeesSchema = new schema.Entity('employees');

        const divisionWithSubDivisionsSchema = new schema.Object({
            division: divisionSchema,
            employees: [employeesSchema],
        });
        divisionWithSubDivisionsSchema.define({
            childSubdivision: [divisionWithSubDivisionsSchema],
        });

        const normalized = normalize(response, divisionWithSubDivisionsSchema);

        const subDivisions: Store.INormalizedHierarchicalSubDivisions = {
            items: normalized.entities.divisions,
            topSubDivisions: (<Api.IHierarchicalOrgStructure[]>(
                normalized.result.childSubdivision
            )).map((subDiv) => subDiv.division.id),
        };

        return {
            subDivisions,
            employees: normalized.entities.employees,
            subDivisionsToPosts,
            subDivisionsToEmployees,
            personToEmployees,
        };
    },
});
