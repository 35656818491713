/**
 * Created by Lkarmelo on 21.11.2017.
 */

import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

const slideTransition = (props) => {
    let { classPrefix } = props;
    const { ...nextProps } = props;
    classPrefix = props.classPrefix || 'slide-transition';
    return (
        <CSSTransition classNames={classPrefix} timeout={200} {...nextProps}>
            {(status) => (
                <div className={`${classPrefix}-${status}`}>
                    {nextProps.children}
                </div>
            )}
        </CSSTransition>
    );
};

export default slideTransition;
