import * as React from 'react';
import classNames from 'classnames';

import styles from '../../PersonCard.scss';

export interface IContactItemProps {
    icon: string;
    onClick?: () => void;
}

const ContactItem: React.FC<IContactItemProps> = ({
    icon,
    onClick,
    children,
}) => {
    return children ? (
        <li onClick={onClick} className={styles.personCardContactItem}>
            <div className={styles.personCardContactItemIconContainer}>
                <i
                    className={classNames(
                        icon,
                        styles.personCardContactItemIcon
                    )}
                />
            </div>
            <div>{children}</div>
        </li>
    ) : null;
};

export default ContactItem;
