/**
 * Created by Lkarmelo on 26.10.2017.
 */

export default (type: string, displayType: string): string => {
    let name = displayType;
    if (!name) {
        name = type;
    }
    return name;
};
