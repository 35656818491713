/**
 * Created by lkarmelo on 15.08.2019.
 */

import React, {
    MouseEventHandler,
    useCallback,
    useEffect,
    useRef,
} from 'react';
import { CSSTransition } from 'react-transition-group';
import { Portal } from 'react-portal';
import classNames from 'classnames';
import { useEvent } from 'react-use/esm';

import { findClosest } from 'app/utils/findClosest';

import * as styles from './Modal.scss';

export const useCloseOnNonModalClick = (
    callback: Function,
    hasEntered: React.MutableRefObject<boolean>,
    maskClosable: boolean,
    isVisible: boolean
): MouseEventHandler => {
    const isClicked = useRef(false);

    const onDocumentMouseDown = useCallback(
        (e: MouseEvent) => {
            if (isClicked.current) {
                isClicked.current = false;
                return;
            }

            if (!maskClosable || !hasEntered.current || !isVisible) {
                return;
            }

            // т.к. модалки antd с ошибками загрузки могут возникать поверх наших, приходится проверять, не нажимают ли на неё
            if (
                findClosest(e.target as HTMLElement, (el) =>
                    el.classList.contains('ant-modal-wrap')
                )
            ) {
                return;
            }

            callback?.();
        },
        [maskClosable, isVisible]
    );

    useEvent('mousedown', onDocumentMouseDown, document); // typeof document !== 'undefined'

    return useCallback(() => {
        isClicked.current = true;
    }, []);
};

export interface IModalProps {
    visible: boolean;
    showCross?: boolean;
    centered?: boolean;
    className?: string;
    maskClosable?: boolean;

    onCancel?(): void;

    afterClose?(): void;
}

const Modal: React.FC<IModalProps> = (props) => {
    const {
        children,
        visible,
        afterClose,
        onCancel,
        showCross,
        centered,
        className,
        maskClosable,
    } = props;

    const hasEntered = useRef(false);
    const onMouseDown = useCloseOnNonModalClick(
        onCancel,
        hasEntered,
        maskClosable,
        visible
    );

    // вычисляем ширину скроллбара и ставим паддинг body, чтобы оно не дёргалось, когда делаем overflow hidden
    useEffect(() => {
        if (visible) {
            const scrollbarWidth =
                window.innerWidth - document.body.clientWidth;
            document.body.style.paddingRight = `${scrollbarWidth}px`;
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.paddingRight = '';
            document.body.style.overflow = '';
        }
    }, [visible]);

    const onEntered = useCallback(() => {
        hasEntered.current = true;
    }, []);

    const ownOnExited = useCallback(() => {
        hasEntered.current = false;
        afterClose && afterClose();
    }, [afterClose]);

    return (
        <Portal>
            <CSSTransition
                classNames={styles.modalCstm}
                mountOnEnter
                unmountOnExit
                onExited={ownOnExited}
                onEntered={onEntered}
                timeout={200}
                in={visible}
            >
                {() => (
                    <div
                        className={classNames(className, styles.modalCstm, {
                            [styles.modalCstmCentered]: centered,
                        })}
                    >
                        <div className={styles.modalCstmOuterContainer}>
                            <div
                                className={styles.modalCstmInnerContainer}
                                onMouseDown={onMouseDown}
                            >
                                {showCross && (
                                    <button
                                        className={`${styles.modalCstmClose} icon-cross btn`}
                                        onClick={onCancel}
                                    />
                                )}
                                <div className={styles.modalCstmContent}>
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </CSSTransition>
        </Portal>
    );
};

Modal.defaultProps = {
    showCross: true,
    centered: true,
    maskClosable: true,
};

export default Modal;
