/**
 * Created by Lkarmelo on 05.09.2017.
 */

import ApiRoute from './Route/ApiRoute';

const prefix = '/api';

const routes = {
    getUserAvatar: new ApiRoute(
        `${prefix}/profiles/getPhotoByEmployeeId/{userId}?resolution={resolution}&needCache={needCache}`
    ),
    getSystemUserAvatar: new ApiRoute(
        `${prefix}/profiles/getPhotoByUserId/{userId}?resolution={resolution}`
    ),
    filtersMeta: new ApiRoute(`${prefix}/filters`),
    favoritesFiltersMeta: new ApiRoute(`${prefix}/favorites/filters`),
    paramsMeta: new ApiRoute(`${prefix}/params`),

    tabsMeta: new ApiRoute(`${prefix}/tabs`),
    tabsMetaSimple: new ApiRoute(`${prefix}/tabs-simple`),
    tab: new ApiRoute(`${prefix}/tab/{tabCode}`),

    globalSearch: new ApiRoute(`${prefix}/search`),
    personSearch: new ApiRoute(`${prefix}/search/person`),
    documentSearch: new ApiRoute(`${prefix}/search/document`),
    queryExpansion: new ApiRoute(`${prefix}/buildQueryExpansion/{query}`),
    searchHints: new ApiRoute(`${prefix}/search/suggestions`),

    compressSearchRequest: new ApiRoute(`${prefix}/search/alias`),
    searchRequestFromAlias: new ApiRoute(`${prefix}/search/alias/{alias}`),

    searchByCategory: new ApiRoute(`${prefix}/documents/{categoryCode}{?new}`),

    objectCard: new ApiRoute(`${prefix}/document/{id}{?query,expansion}`),
    objectCardLinked: new ApiRoute(`${prefix}/document/fetch/linked/{id}`),
    objectCardSimilar: new ApiRoute(
        `${prefix}/document/similar/{id}{?skip,limit}`
    ),

    logIn: new ApiRoute(`${prefix}/signIn`),
    logOut: new ApiRoute(`${prefix}/signOut`),
    changePassword: new ApiRoute(`${prefix}/user/changePassword`),
    resetPassword: new ApiRoute(`${prefix}/resetPassword/{token}`),
    forgotPassword: new ApiRoute(`${prefix}/forgetPassword`),

    userData: new ApiRoute(`${prefix}/user`),
    userPermissions: new ApiRoute(`${prefix}/user/roles`),

    currentUserData: new ApiRoute(`${prefix}/user/profile/{id}`),
    updatePersonalInfo: new ApiRoute(`${prefix}/user/profile/edit{?profileId}`),
    currentUserWorkInfo: new ApiRoute(`${prefix}/user/employees/{id}`),
    updateUserWorkInfo: new ApiRoute(
        `${prefix}/user/employee{?profileId}{&employeeId}`
    ),
    currentUserCompetencies: new ApiRoute(
        `${prefix}/user/competencies/requirement/{id}`
    ),
    updateUserCompetencies: new ApiRoute(
        `${prefix}/user/competencies{?profileId}`
    ),
    currentUserCategories: new ApiRoute(
        `${prefix}/user/knowledgeCategories/documents/{id}`
    ),
    currentUserSkills: new ApiRoute(`${prefix}/user/skills/{id}`),
    updateUserSkills: new ApiRoute(`${prefix}/user/skills{?profileId}`),

    userNotification: new ApiRoute(`${prefix}/user/notifications/list{?limit}`),
    userNotificationCount: new ApiRoute(
        `${prefix}/user/notifications/unreadCount`
    ),
    markNotificationReaded: new ApiRoute(`${prefix}/user/notifications/read`),
    markNotificationUnreaded: new ApiRoute(
        `${prefix}/user/notifications/unread`
    ),

    userGuideStats: new ApiRoute(`${prefix}/user/guide`),
    userGuideSpecificStats: new ApiRoute(`${prefix}/user/guide/{version}`),
    userGuideSpecificUpdateStats: new ApiRoute(
        `${prefix}/user/guide/{version}/view`
    ),

    relatedDocuments: new ApiRoute(`${prefix}/relatedDocuments{?id}`),

    favorites: new ApiRoute(`${prefix}/favoriteDocuments`),
    markFavorite: new ApiRoute(`${prefix}/favorites`),
    unmarkFavorite: new ApiRoute(`${prefix}/favorites/{documentId}`),

    readLater: new ApiRoute(`${prefix}/readLaterDocuments`),
    markReadLater: new ApiRoute(`${prefix}/readLater`),
    unmarkReadLater: new ApiRoute(`${prefix}/readLater/{documentId}`),

    subscribes: new ApiRoute(`${prefix}/subscribedDocuments`),
    markSubscribes: new ApiRoute(`${prefix}/subscribe`),
    unmarkSubscribes: new ApiRoute(`${prefix}/subscribe/{documentId}`),

    organizations: new ApiRoute(`${prefix}/organizations`),
    addresses: new ApiRoute(`${prefix}/workAddresses`),
    posts: new ApiRoute(`${prefix}/posts`),
    subdivisions: new ApiRoute(`${prefix}/orgstructure`),

    orgStructureWithEmployees: new ApiRoute(`${prefix}/orgstruct`),

    competencies: new ApiRoute(`${prefix}/competencies`),
    skills: new ApiRoute(`${prefix}/skills`),
    createSkill: new ApiRoute(`${prefix}/skills`),

    catalogueCategories: new ApiRoute(`${prefix}/knowledgeCategories`),
    catalogueCategory: new ApiRoute(`${prefix}/knowledgeCategory{/code}`),
    categorizationRules: new ApiRoute(
        `${prefix}/categorization/rules{?categoryCode,ruleId}`
    ),
    deleteCategorizationRules: new ApiRoute(
        `${prefix}/categorization/rules/{categoryCode}{?ruleId*}`
    ),
    categorizationCategoryRule: new ApiRoute(
        `${prefix}/categorization/rules/{categoryCode}`
    ),
    moveCategory: new ApiRoute(`${prefix}/move/subcategories{?from,to}`),

    // education -----
    educationMaterials: new ApiRoute(`${prefix}/education/materials/all`),
    ownEducationMaterials: new ApiRoute(`${prefix}/education/materials/own`),
    addNewDocument: new ApiRoute(`${prefix}/education/material`),
    editDocument: new ApiRoute(`${prefix}/education/material/{documentId}`),
    deleteDocument: new ApiRoute(`${prefix}/education/material/{documentId}`),
    educationMaterialTypes: new ApiRoute(`${prefix}/education/materialTypes`),
    numberOfEducationMaterials: new ApiRoute(
        `${prefix}/education/recommendations/ownCount`
    ),

    recommendDocumentByProfiles: new ApiRoute(
        `${prefix}/education/recommendations/manage/byProfiles`
    ),
    recommendDocumentBySubdivisions: new ApiRoute(
        `${prefix}/education/recommendations/manage/bySubdivisions`
    ),

    educationMaterialsByStatus: new ApiRoute(
        `${prefix}/education/recommendations/own{?recommendationsOnly,documentStatus}`
    ),

    changeEducationMaterialStatus: new ApiRoute(
        `${prefix}/education/tutorial/{documentId}`
    ),
    resetEducationMaterialStatus: new ApiRoute(
        `${prefix}/education/tutorial/{documentId}`
    ),

    refuseRecommendation: new ApiRoute(
        `${prefix}/education/recommendations/own/{documentId}/refuse`
    ),

    recommendations: new ApiRoute(
        `${prefix}/education/recommendations/manage` +
            '{?document,createdDateFrom,createdDateTo,planDateFrom,planDateTo,learnedDateTo,author,' +
            'learnedDateFrom,necessity,status,recommendationStatus,receiver,orderBy,orderAsc,page,count}'
    ),
    editRecommendation: new ApiRoute(
        `${prefix}/education/recommendations/manage/{id}{?forUser}`
    ),
    deleteRecommendation: new ApiRoute(
        `${prefix}/education/recommendations/manage/{id}{?forUser}`
    ),

    userIdByLogin: new ApiRoute(`${prefix}/profile/{login}{?sourceId}`),

    uploadFile: new ApiRoute(`${prefix}/files/upload`),
    processFile: new ApiRoute(`${prefix}/files/{fid}`, {
        paramsWithBackslash: true,
    }),
    downloadFile: new ApiRoute(`${prefix}/files/{fid}`, {
        paramsWithBackslash: true,
    }),
    deleteFile: new ApiRoute(`${prefix}/files/{fid}`, {
        paramsWithBackslash: true,
    }),
    listFile: new ApiRoute(`${prefix}/files/list/{location}`, {
        paramsWithBackslash: true,
    }),

    publicFileLocation: new ApiRoute(`${prefix}/public/{location}`, {
        paramsWithBackslash: true,
    }),

    errorReport: new ApiRoute(`${prefix}/feedback/error`),
    feedbackMessage: new ApiRoute(`${prefix}/feedback/report`),

    documentOriginalLink: new ApiRoute(`${prefix}/original/document/{id}/link`),

    documentVerifyCategory: new ApiRoute(
        `${prefix}/verify/knowledgeCategory/{docId}{?categoryCode}`
    ),
    documentChangeCategory: new ApiRoute(
        `${prefix}/replace/knowledgeCategory/{docId}{?categoryCode,newCategoryCode}`
    ),
    documentDeclineCategory: new ApiRoute(
        `${prefix}/decline/knowledgeCategory/{docId}{?categoryCode}`
    ),

    broaderConcepts: new ApiRoute(`${prefix}/skos/v2/getBroaderConcepts`),
    narrowerConcepts: new ApiRoute(`${prefix}/skos/v2/getNarrowerConcepts`),
    skosResourceView: new ApiRoute(`${prefix}/skos/v2/getResourceView`),

    logMetrics: new ApiRoute(`${prefix}/logMetricsEvent`),
    logUserSipClick: new ApiRoute(`${prefix}/journal/log/sipClick`),
    logUserMailToClick: new ApiRoute(`${prefix}/journal/log/mailtoClick`),
    logUserDetailsClick: new ApiRoute(`${prefix}/journal/log/userDetailsClick`),
    logEmployeeTabFromWidgetClick: new ApiRoute(
        `${prefix}/journal/log/changeTabEmployeeWidget`
    ),

    getDoodle: new ApiRoute(`${prefix}/doodle`),
};

export default routes;
