/**
 * Created by lkarmelo on 19.11.2018.
 */

import { Action } from 'redux-actions';
import { Observable } from 'rxjs/Observable';
import { MiddlewareAPI } from 'redux';

import * as Store from 'app/redux/store/StoreNamespace';
import Api from 'app/api/Api';
import {
    expandQueryRequest,
    expandQueryReject,
    expandQueryResolve,
} from 'app/redux/actions/loading';
import {
    compressRequestUpdateLocationAndSearch,
    fetchSearchResults,
} from 'app/redux/actions/search/results';
import { withIgnoreSearchTriggerMeta } from 'app/redux/utils/withIgnoreSearchTriggerMeta';
import {
    setQueryExpansionMode,
    setQueryExpansion,
    fetchQueryExpansion,
    setSelectedQueryTags,
} from 'app/redux/actions/search/searchQuery';
import { setSkip } from 'app/redux/actions/search/searchPaging';
import { traverseTerms } from 'app/utils/expansionUtils';

export const switchExpansionMode = (
    action$,
    store: MiddlewareAPI<Store.IState>
) =>
    action$
        .ofType(setQueryExpansionMode.toString())
        .hasContext(false)
        .ignoreSearchActionsByMeta()
        .debounceTime(300)
        .mergeMap((action: Action<boolean>) => {
            if (action.payload) {
                return Observable.of(fetchQueryExpansion());
            }

            const actions: Action<any>[] = [
                setQueryExpansion(null),
                setSelectedQueryTags([]),
            ];

            // если не было выбрано никакое расширение, то можно снова не выполнять поисковый запрос
            if (store.getState().search.selectedQueryTags?.length > 0) {
                actions.push(
                    withIgnoreSearchTriggerMeta(setSkip(0)),
                    compressRequestUpdateLocationAndSearch({
                        isNewSearch: false,
                        setPersonStrictFromResponse: true,
                        setExtractedConcepts: false,
                    })
                );
            }

            return Observable.from(actions);
        });

const queryToExpansionRequest = (search: Store.ISearch) => search.searchQuery;

export const loadQueryExpansion = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(fetchQueryExpansion.toString(), fetchSearchResults.toString())
        .hasContext(false)
        .filter(
            (action: Action<any>) =>
                action.type !== fetchSearchResults.toString() ||
                store.getState().search.isQueryExpansion
        )
        .filter(() => {
            const state = store.getState().search;
            return (
                !state.queryTags ||
                state.queryTags.queryString !== state.searchQuery
            );
        })
        .switchMap((action) => {
            const state = store.getState().search;
            const queryString = state.searchQuery;

            const isFetchSearchResultsAction =
                action.type === fetchSearchResults.toString();

            if (!queryString && isFetchSearchResultsAction) {
                return Observable.of<any>(
                    setQueryExpansionMode(false),
                    setSelectedQueryTags([])
                );
            }

            return apiCall
                .queryExpansion(queryToExpansionRequest(state))
                .mergeMap(
                    ({
                        response,
                    }: {
                        response: Store.IQueryTerm | Store.IQueryNode;
                    }) => {
                        const { selectedQueryTags } = state;
                        // если уже выбрано какое-то расширение, то после загрузки новог списка расширений, нужно
                        // оставить выбранные, которые встречаются в новом списке и убрать те, которые не встречаются
                        const nextSelectedQueryTags = [];
                        traverseTerms(response, ({ expansion }) => {
                            expansion.forEach(({ lemmaId }) => {
                                if (selectedQueryTags?.includes(lemmaId)) {
                                    nextSelectedQueryTags.push(lemmaId);
                                }
                            });
                        });

                        return Observable.of(
                            setSelectedQueryTags(nextSelectedQueryTags),
                            setQueryExpansion({ queryString, tags: response }),
                            expandQueryResolve()
                        );
                    }
                )
                .catch((e) => {
                    console.error(e);
                    return Observable.of(expandQueryReject(e));
                })
                .startWith(expandQueryRequest());
        });
