import React, { useMemo } from 'react';
import classNames from 'classnames';
import Tooltip from 'antd/es/tooltip';

import { prependHttp } from 'app/utils/prependHttp';
import * as Store from 'app/redux/store/StoreNamespace';
import clientRoutes from 'app/routing/clientRoutes';
import {
    getBiggestPreviewLink,
    getPreviewLinksMap,
    getSmallestPreviewLink,
} from 'app/utils/document/previewLinksUtils';
import {
    BREAD_CRUMBS_MAX_LENGTH,
    CURRENT_HTTP_URL,
    DOCUMENT_EMPTY_CONTENT_TEXT,
    DOCUMENT_EMPTY_TEXT,
    BREAD_CRUMBS_DIVIDER,
} from 'app/utils/constants';
import {
    getClearedDangerousHtml,
    getFormattedByBrText,
} from 'app/utils/clearifyDangerousHtml';

import * as styles from './DocumentSnippet.scss';
import IProps from './interfaces/IDocumentSnippetProps';

import YearSourceTypeLink from '../YearSourceTypeLink/YearSourceTypeLink';
import GroupedDocuments from '../GroupedDocuments/GroupedDocuments';
import OrgsAuthors from '../OrgsAuthors/OrgsAuthors';

const DocumentSnippet: React.FunctionComponent<IProps> = (props) => {
    const {
        document,
        getDocumentLink,
        ignoreHighlight,
        renderControls,
        className,
    } = props;
    const { meta, id, highlights, previewLinks, grouped, similarity } =
        document || ({} as Store.IDocumentSnippet);
    const { link, title, persons, orgs, file } =
        meta || ({} as Store.IDocumentDescription);

    const linkUrl =
        typeof link === 'string'
            ? link
            : typeof link === 'object'
            ? link.url
            : undefined;

    const previewLinksMap = useMemo(
        () => getPreviewLinksMap(previewLinks),
        [previewLinks]
    );
    const previewLinkSmall = useMemo(
        () => getSmallestPreviewLink(previewLinksMap),
        [previewLinksMap]
    );
    const previewLinkDefault =
        previewLinksMap.get(Store.PreviewLinkSize.default) ||
        getBiggestPreviewLink(previewLinksMap);
    const hasLinkUrl = !!linkUrl;

    const plainFileName = file?.filename;

    const highlightedFinalTitle =
        highlights.title.length === 0
            ? meta.title
            : highlights.title.join('. ');

    // мы подставляем plainFinalTitle внутрь аттрибута title, поэтому можем использовать только plainFileName, в котором
    // нет html тегов
    const plainFinalTitle = plainFileName || title;

    const onRenderBreadCrumbs = () => {
        let titleBreadCrumbs = '';
        let altBreadCrumbs = '';

        if (meta.breadCrumbs) {
            const { domain } = meta.breadCrumbs;
            const segments = meta.breadCrumbs.segments
                ? [...meta.breadCrumbs.segments]
                : [];
            const segmentsLength = segments.length;
            titleBreadCrumbs = `${domain}${BREAD_CRUMBS_DIVIDER}${segments.join(
                BREAD_CRUMBS_DIVIDER
            )}`;
            altBreadCrumbs = titleBreadCrumbs;

            if (altBreadCrumbs.length > BREAD_CRUMBS_MAX_LENGTH) {
                altBreadCrumbs = `${domain}${BREAD_CRUMBS_DIVIDER}`;
                let lineCount = 0;
                segments.forEach((segment) => {
                    const currentBread = `${segment}${BREAD_CRUMBS_DIVIDER}`;
                    if (
                        altBreadCrumbs.length + currentBread.length >
                        BREAD_CRUMBS_MAX_LENGTH * (lineCount + 1)
                    ) {
                        lineCount++;
                        altBreadCrumbs += '\n';
                    }
                    altBreadCrumbs += currentBread;
                });
                altBreadCrumbs = altBreadCrumbs.slice(
                    0,
                    altBreadCrumbs.length - BREAD_CRUMBS_DIVIDER.length
                );
            }

            if (titleBreadCrumbs.length > BREAD_CRUMBS_MAX_LENGTH) {
                const lengthWithoutFirstPart =
                    BREAD_CRUMBS_MAX_LENGTH -
                    `${domain}${BREAD_CRUMBS_DIVIDER}...${BREAD_CRUMBS_DIVIDER}`
                        .length;
                if (
                    segments[segments.length - 1].length >
                    lengthWithoutFirstPart
                ) {
                    const ellipsis =
                        segments.length === 1
                            ? BREAD_CRUMBS_DIVIDER
                            : `${BREAD_CRUMBS_DIVIDER}...${BREAD_CRUMBS_DIVIDER}`;
                    titleBreadCrumbs = `${domain}${ellipsis}${segments
                        .pop()
                        .substring(0, lengthWithoutFirstPart - 3)}...`;
                    return { titleBreadCrumbs, altBreadCrumbs };
                }
                const lastSegment = segments.pop();
                for (let i = 0; i < segmentsLength - 1; i++) {
                    segments.shift();
                    titleBreadCrumbs = `${domain}${BREAD_CRUMBS_DIVIDER}...${BREAD_CRUMBS_DIVIDER}${segments.join(
                        BREAD_CRUMBS_DIVIDER
                    )}`;
                    if (
                        titleBreadCrumbs.length <
                        lengthWithoutFirstPart - lastSegment.length
                    ) {
                        break;
                    }
                }
                titleBreadCrumbs = `${domain}${BREAD_CRUMBS_DIVIDER}...${BREAD_CRUMBS_DIVIDER}${
                    segments.length
                        ? segments.join(BREAD_CRUMBS_DIVIDER) +
                          BREAD_CRUMBS_DIVIDER
                        : ''
                }${lastSegment}`;
            }
        }

        return { titleBreadCrumbs, altBreadCrumbs };
    };

    const breadCrumbs = onRenderBreadCrumbs();
    const isExtension = meta.breadCrumbs && !!meta.breadCrumbs.extension;

    return (
        <article className={classNames(styles.documentSnippet, className)}>
            <div className={styles.documentSnippetActions}>
                {renderControls(props, styles)}
            </div>
            <div className={styles.documentSnippetContent}>
                {!!previewLinkSmall && (
                    <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={prependHttp(previewLinkDefault)}
                        className={styles.documentSnippetPreviewImage}
                    >
                        <img src={previewLinkSmall} alt={plainFinalTitle} />
                    </a>
                )}
                <div className={styles.documentSnippetBody}>
                    {hasLinkUrl ? (
                        <div
                            className={classNames(
                                styles.documentSnippetTitle,
                                isExtension &&
                                    styles.documentSnippetTitleExtension
                            )}
                            title={plainFinalTitle}
                        >
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${CURRENT_HTTP_URL}${clientRoutes.documentRedirect.getUrl(
                                    { id }
                                )}`}
                            >
                                {getClearedDangerousHtml(highlightedFinalTitle)}
                            </a>
                            {isExtension && (
                                <span
                                    className={
                                        styles.documentSnippetBreadCrumbsExtension
                                    }
                                >
                                    {meta.breadCrumbs.extension}
                                </span>
                            )}
                        </div>
                    ) : (
                        <div
                            className={styles.documentSnippetTitle}
                            title={plainFinalTitle}
                        >
                            {getClearedDangerousHtml(highlightedFinalTitle)}
                        </div>
                    )}
                    {meta.breadCrumbs && (
                        <div
                            title={breadCrumbs.altBreadCrumbs}
                            className={styles.documentSnippetBreadCrumbs}
                        >
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={meta.breadCrumbs.url}
                                className={
                                    styles.documentSnippetBreadCrumbsLink
                                }
                            >
                                {breadCrumbs.titleBreadCrumbs}
                            </a>
                        </div>
                    )}
                    {((Array.isArray(orgs) && orgs.length > 0) ||
                        (Array.isArray(persons) && persons.length > 0)) && (
                        <OrgsAuthors
                            document={document}
                            delimiterClassName={
                                styles.documentSnippetDelimiterDot
                            }
                            ignoreHighlight={ignoreHighlight}
                        />
                    )}
                    <div className={styles.documentSnippetAnnotation}>
                        {highlights &&
                        Array.isArray(highlights.content) &&
                        highlights.content.length > 0
                            ? highlights.content.map((str, i) => {
                                  const key = `${id}-annotation-${i}`;

                                  return (
                                      <React.Fragment key={key}>
                                          <span>
                                              {getFormattedByBrText(str)}
                                          </span>
                                          {i <
                                              highlights.content.length - 1 && (
                                              <span> ... </span>
                                          )}
                                      </React.Fragment>
                                  );
                              })
                            : highlights.optionalAttrs &&
                              Object.keys(highlights.optionalAttrs).length > 0
                            ? DOCUMENT_EMPTY_CONTENT_TEXT
                            : DOCUMENT_EMPTY_TEXT}
                    </div>

                    <YearSourceTypeLink
                        document={document}
                        delimiterClassName={styles.documentSnippetDelimiterDot}
                        getDocumentLink={getDocumentLink}
                        similarity={similarity}
                    />
                </div>
            </div>
            {grouped && grouped.items && grouped.items.length > 0 && (
                <div className={styles.documentSnippetGroupedContainer}>
                    <GroupedDocuments grouped={grouped} />
                </div>
            )}
        </article>
    );
};

DocumentSnippet.defaultProps = {
    renderControls(
        { document, onFavoriteClick, onSubscribeClick }: IProps,
        clsNames: typeof styles
    ): JSX.Element {
        const favorite = document && document.favorite;
        const subscribed = document && document.subscribed;
        const isShowIconSubscribe =
            document && (document.subscribed || document.allowSubscription);
        return (
            <div className={styles.documentSnippetActionContainer}>
                <Tooltip
                    placement="left"
                    title={
                        favorite
                            ? 'Убрать из избранного'
                            : 'Добавить в избранное'
                    }
                    mouseEnterDelay={0.3}
                >
                    <button
                        onClick={onFavoriteClick}
                        className={classNames(
                            `btn ${clsNames.documentSnippetActionIcon}`,
                            {
                                [clsNames.documentSnippetActionFavorite]:
                                    favorite,
                                [clsNames.documentSnippetActionFavoriteO]:
                                    !favorite,
                            }
                        )}
                    />
                </Tooltip>
                {isShowIconSubscribe && (
                    <Tooltip
                        placement="left"
                        title={
                            subscribed
                                ? 'Перестать получать письма об изменениях в документе'
                                : 'Получать письма об изменениях в документе'
                        }
                        mouseEnterDelay={0.3}
                    >
                        <button
                            onClick={onSubscribeClick}
                            className={classNames(
                                `btn ${clsNames.documentSnippetActionIcon}`,
                                {
                                    [clsNames.documentSnippetActionSubscribe]:
                                        subscribed,
                                    [clsNames.documentSnippetActionSubscribeO]:
                                        !subscribed,
                                }
                            )}
                        />
                    </Tooltip>
                )}
            </div>
        );
    },
};

export default DocumentSnippet;
