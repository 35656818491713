import * as React from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import {
    FeatureRenderer,
    UIFeaturesName,
} from 'app/components/common/utils/FeatureRenderer';
import UserMenu from 'app/components/header/UserMenu';
import NavigationMenu from 'app/components/header/NavigationMenu';
import SectionTitle from 'app/components/header/SectionTitle';
import NotificationMenu from 'app/components/header/NotificationMenu';
import clientRoutes from 'app/routing/clientRoutes';

import IProps from './interfaces/IHeaderProps';
import * as styles from './Header.scss';

// import { ConstantFuturesName } from '../../../features/ConstantFuturesName';

const Header: React.FC<IProps> = (props) => {
    const { showUserMenu, children, location, doodle } = props;

    const publishedDoodle = doodle && Object.keys(doodle).length;

    return (
        <header
            className={classNames(styles.header, {
                [styles.headerWithShadow]:
                    location.pathname !== clientRoutes.search.getUrl(),
            })}
        >
            <div className={styles.headerLeftSide}>
                <div className={styles.headerLogoTitle}>
                    <div className={styles.headerLogoTitleLeftBox}>
                        {/*<a*/}
                        {/*    href={clientRoutes.search.getUrl()}*/}
                        {/*    className={styles.headerLogo}*/}
                        {/*    dangerouslySetInnerHTML={{*/}
                        {/*        __html: APP_FEATURES.constant[*/}
                        {/*            ConstantFuturesName.HeaderLogoText*/}
                        {/*            ] as string,*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {}*/}
                        {/*</a>*/}
                        {!!publishedDoodle && (
                            <a
                                title={doodle.description}
                                target={doodle.newBlank ? '_blank' : '_self'}
                                className={classNames(
                                    styles.headerDoodle,
                                    styles.headerDoodleImage
                                )}
                                style={{
                                    backgroundImage: `url(${doodle.resultPageImg})`,
                                }}
                                href={doodle.link}
                                rel="noreferrer"
                            />
                        )}
                    </div>
                    <SectionTitle />
                </div>
                {children}
            </div>
            <div className={styles.headerRightSide}>
                <NotificationMenu />
                <FeatureRenderer featureName={UIFeaturesName.NavigationMenu}>
                    <NavigationMenu />
                </FeatureRenderer>
                {showUserMenu && (
                    <FeatureRenderer featureName={UIFeaturesName.UserMenu}>
                        <UserMenu />
                    </FeatureRenderer>

                )}
                <a href="https://pm.ac.gov.ru/" className={styles.headerIms}>
                    <i></i>
                    <span>PM</span>
                </a>
                {/* {showSearchWidget && */}
                {/*    <div className={styles.headerSearchWidget}> */}
                {/*        <SearchPanel isMinified /> */}
                {/*    </div> */}
                {/* } */}
            </div>
        </header>
    );
};

export default withRouter(React.memo(Header) as React.FC<IProps>);
