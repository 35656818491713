/**
 * Created by lkarmelo on 11.11.2019.
 */

import * as React from 'react';
import classNames from 'classnames';

import * as styles from '../../../../components/common/tables/Filters/TreeSelect/TreeSelect.scss';

interface IProps {
    isVisible: boolean;
    selectAll: boolean;
    className?: string;
    toggleSelectAll(nextSelectAllState: boolean): void;
}

const SelectAllToggle: React.FC<IProps> = ({
    isVisible,
    selectAll,
    toggleSelectAll,
    className,
}) => {
    if (!isVisible) {
        return null;
    }

    const handleToggleBtnClick = (isSelected: boolean) => () =>
        toggleSelectAll(isSelected);

    return (
        <div
            className={classNames(styles.nkcmSelectSelectAllToggle, className)}
        >
            {selectAll ? (
                <button onClick={handleToggleBtnClick(false)}>
                    Очистить всё
                </button>
            ) : (
                <button onClick={handleToggleBtnClick(true)}>
                    Выбрать всё
                </button>
            )}
        </div>
    );
};

export default SelectAllToggle;
