/**
 * Created by Lkarmelo on 03.10.2017
 * -----
 * Last Modified: 01.02.2018 18:08:25
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import { createSelector } from 'reselect';

import * as Store from '../store/StoreNamespace';

const userKnowledgeCategoriesSelector = (state: Store.IState) =>
    state.currentUserKnowledgeCategories;

export const userKnowledgeCategoriesSortedSelector = createSelector<
    Store.IState,
    Store.IKnowledgeCategory[],
    Store.IKnowledgeCategory[]
>(
    userKnowledgeCategoriesSelector,
    (knowledgeCategories: Store.IKnowledgeCategory[]) =>
        (knowledgeCategories || []).sort((catA, catB) => {
            if (catA.docsCount > catB.docsCount) {
                return -1;
            }
            if (catA.docsCount === catB.docsCount) {
                return 0;
            }
            return 1;
        })
);
