/**
 * Created by Lkarmelo on 23.08.2017.
 */

import { handleActions, Action } from 'redux-actions';

import * as Store from 'app/redux/store/StoreNamespace';
import {
    setSearchResults,
    ISetSearchResultsPayload,
    addToSearchResults,
    clearCorrectedQuery,
    fetchAvatar,
    setAvatars,
    onShowBigAvatar,
    getBigAvatar,
    onCloseBigImage,
    confirmShowImage,
} from 'app/redux/actions/search/results';
import { getFilterStatsFromAggregation } from 'app/utils/getFilterStatsFromAggregation';

import {
    fetchPersonViewWidgetAvatar,
    setPersonViewWidgetAvatar,
} from '../../actions/personWidget';

export default handleActions(
    {
        [setSearchResults.toString()](
            state: Store.IPersonResults,
            { payload }: Action<ISetSearchResultsPayload>
        ) {
            const aggregations = payload.personResults?.aggregations;

            return {
                ...payload.personResults,
                filterStats: getFilterStatsFromAggregation(aggregations),
                askFeedback: payload.askFeedback,
                isConfirmShownBigAvatar: false,
            };
        },
        [clearCorrectedQuery.toString()](state: Store.IPersonResults) {
            if (!state || !state.correctedQuery) {
                return state;
            }
            const nextState = { ...state };
            delete nextState.correctedQuery;
            nextState.isConfirmShownBigAvatar = false;
            return nextState;
        },
        [addToSearchResults.toString()](
            state: Store.IPersonResults,
            { payload }: Action<ISetSearchResultsPayload>
        ) {
            let nextPersons: Store.IPerson[];
            if (typeof payload.personResults !== 'undefined') {
                nextPersons = Array.isArray(state.items)
                    ? state.items.slice()
                    : [];
                nextPersons.push(...payload.personResults.items);
            }

            return {
                ...state,
                items: nextPersons ?? state.items,
                totalCount:
                    payload.personResults?.totalCount ?? state.totalCount,
                askFeedback: payload.askFeedback,
                isConfirmShownBigAvatar: false,
            };
        },
        [fetchAvatar.toString()](state: Store.IPersonResults) {
            const nextState = { ...state };
            nextState.isConfirmShownBigAvatar = false;
            return nextState;
        },
        [setAvatars.toString()](
            state: Store.IPersonResults,
            { payload }: Action<ISetSearchResultsPayload & Store.IPersonAvatar>
        ) {
            const nextState = { ...state };
            if (payload) {
                nextState.items = nextState.items.map((person) => {
                    if (person.content.userId.toString() === payload.userId) {
                        person.content.avatar = payload;
                        return person;
                    }
                    return person;
                });
            }
            return nextState;
        },
        [fetchPersonViewWidgetAvatar.toString()](state: Store.IPersonResults) {
            return {
                ...state,
            };
        },
        [setPersonViewWidgetAvatar.toString()](
            state: Store.IPersonResults,
            { payload }: Action<Store.IPersonAvatar>
        ) {
            const nextState = { ...state };
            if (payload) {
                nextState.items = nextState.items.map((person) => {
                    if (person.content.userId.toString() === payload.userId) {
                        person.content.avatar = payload;
                        return person;
                    }
                    return person;
                });
            }
            return nextState;
        },
        [onShowBigAvatar.toString()](state: Store.IPersonResults) {
            const nextState = { ...state };
            nextState.isConfirmShownBigAvatar =
                !nextState.isConfirmShownBigAvatar;
            return nextState;
        },
        [getBigAvatar.toString()](
            state: Store.IPersonResults,
            { payload }: Action<ISetSearchResultsPayload & Store.IPersonAvatar>
        ) {
            const nextState = { ...state };
            nextState.bigAvatar = payload.avatar;
            nextState.userId = payload.userId;
            nextState.fullTitle = payload.fullTitle;
            nextState.resolution = payload.resolution;
            return nextState;
        },
        [confirmShowImage.toString()](state: Store.IPersonResults) {
            const nextState = { ...state };
            nextState.isConfirmShownBigAvatar = false;
            return nextState;
        },
        [onCloseBigImage.toString()](state: Store.IPersonResults) {
            const nextState = { ...state };
            nextState.isConfirmShownBigAvatar = false;
            nextState.bigAvatar = '';
            return nextState;
        },
    },
    {
        totalCount: 0,
        items: [],
        isConfirmShownBigAvatar: false,
    } // -- default results value
);
