/**
 * Created by Lkarmelo on 23.08.2017.
 * -----
 * Last Modified: 09.07.2018 11:21:12
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import { combineEpics } from 'redux-observable';

import {
    switchExpansionMode,
    loadQueryExpansion,
} from 'app/redux/epics/load-search-results/queryExpansion';
import { filtersToDefaultsEpic } from 'app/redux/epics/filtersToDefaultsEpic';
import {
    loadSearchTabsMeta,
    loadSearchTabsMetaSimple,
    loadSingleTabFullMeta,
    setSearchTabFullMetaEpic,
    setSearchActiveTabEpic,
} from 'app/redux/epics/searchTabs';

import {
    deleteFilesEpic,
    fetchFilesEpic,
    redoUploadFileEpic,
    processRedoUploadFileEpic,
} from './files';
import { stopLoading, startLoading } from './loadingBar';
import {
    loadObjectCard,
    loadObjectCardLinked,
    loadObjectCardSimilar,
    loadParentConceptsEpic,
    loadChildrenConceptsEpic,
} from './objectCard';
import {
    passwordRecovery,
    resetPassword,
    logIn,
    logOut,
    logoutOnUnauthorizedError,
    redirectToPreviousPageOnLoginSuccess,
} from './authorization';
import { showAjaxErrorMessage } from './showAjaxErrorMessage';
import { loadUser, loadUserPermissions } from './loadUser';
import {
    loadCurrentUserCompetencies,
    loadCurrentUserPersonalData,
    loadCurrentUserWorkInfo,
    loadCurrentUserKnowledgeCategories,
    loadCurrentUserSkills,
    loadUserIdByLogin,
} from './loadCurrentUser';
import { sendForm } from './sendForm';
import { sendFavoriteMark, updateFavoriteMark } from './favorites';
import { loadCompetencies } from './loadCompetencies';
import { loadOrganizationsInfo } from './loadOrganizationsInfo';
import {
    loadSkills,
    createNewSkill,
    addCreatedSkillToSkillsFormData,
} from './skills';
import { loadCatalogueCategories } from './catalogue/loadCatalogueCategories';
import { loadCatalogueCategoryDocuments } from './catalogue/loadCatalogueCategoryDocuments';
import { loadCatalogueCategoryWithKeywords } from './catalogue/loadCatalogueCategoryWithKeywords';
import { loadCatalogueCategoriesRules } from './catalogue/catalogueCategoriesRules';
import {
    requestConfirmCategory,
    requestRevertCategory,
} from './catalogue/catalogueDocumentCategory';
import { loadEducationMaterialTypes } from './loadEducationMaterialTypes';
import { redirectToDocumentOnSelectCompetence } from './education';
import {
    startNotificationCountDispatcher,
    sendNotificationStatus,
    loadNotificationList,
    loadNewNotificationsCount,
    loadLatestNotifications,
} from './notification';
import { loadNumberOfEducationMaterials } from './loadNumberOfEducationMaterials';
import { loadOrgStructureWithEmployees } from './loadOrgStructureWithEmployees';
import {
    requestChangeEducationMaterialStatus,
    requestResetEducationMaterialStatus,
    updateEducationMaterialStatus,
} from './educationMaterialStatus';
import { loadRecommendations } from './loadRecommendations';
import {
    blockPagingOnSearchOrFiltersChange,
    loadSearchResultsOnFetch,
    loadSearchResultsOnPaging,
    loadSearchResultsOnFilterChange,
    loadNewSearchResultsOnTagsChange,
    loadMoreSearchResults,
    loadSearchResultsOnPersonalizationActions,
    loadSearchResultsOnSortingActions,
    loadSearchResultsOnSearchTabChange,
    loadSearchResultsOnConceptSelectionChange,
} from './load-search-results/userSearchTriggerEpics';
import { executeSearchRequest } from './load-search-results/executeSearchRequest';
import { compressRequestUpdateLocationAndSearchEpic } from './load-search-results/compressRequestUpdateLocationAndSearchEpic';
import { updateLocationEpic } from './load-search-results/updateLocation';
import { resetSearchQueryToLastExecutedQuery } from './load-search-results/resetSearchQueryToLastExecutedQuery';
import { loadSearchResultsOnLocationChange } from './load-search-results/loadSearchResultsOnLocationChange';
import { loadFiltersMeta, loadFavoritesFiltersMeta } from './loadFiltersMeta';
import { loadSearchHints, setAvatarForHints } from './loadSearchHints';
import {
    contextLoadSearchResults,
    contextLoadSearchResultsOnFiltersChange,
} from './load-search-results/contextLoadSearchResults';
import { loadInitialFilterStats } from './loadInitialFilterStats';
import { loadRedirectionInfo } from './loadRedirectInfo';
import { logEventEpic } from './logEvents';
import { loadResourceViewEpic } from './conceptDescriptions';
import { loadGuideStats, loadListGuideStats, updateGuideStats } from './tour';
import {
    loadAvatar,
    loadPersonWidgetAvatar,
    loadBigAvatar,
    loadProfileAvatar,
    loadLittleUserMenuAvatar,
    loadDropDownUserMenuAvatar,
} from './loadAvatar';
import { sendSubscribeMark, updateSubscribeMark } from './subscribes';
import { loadDoodle } from './doodle';

export default combineEpics(
    loadQueryExpansion,

    // search non context
    blockPagingOnSearchOrFiltersChange,
    resetSearchQueryToLastExecutedQuery,
    loadSearchResultsOnFetch,
    loadSearchResultsOnPaging,
    loadSearchResultsOnFilterChange,
    loadSearchResultsOnLocationChange,
    loadNewSearchResultsOnTagsChange,
    loadSearchResultsOnSearchTabChange,
    loadSearchResultsOnPersonalizationActions,
    loadSearchResultsOnSortingActions,
    loadSearchResultsOnConceptSelectionChange,
    loadMoreSearchResults,
    compressRequestUpdateLocationAndSearchEpic,
    updateLocationEpic,

    loadFiltersMeta,
    loadFavoritesFiltersMeta,
    loadInitialFilterStats,
    switchExpansionMode,
    executeSearchRequest,
    loadSearchHints,
    setAvatarForHints,
    // search context
    contextLoadSearchResults,
    contextLoadSearchResultsOnFiltersChange,

    filtersToDefaultsEpic,

    // favorites
    sendFavoriteMark,
    updateFavoriteMark,

    // subscribes
    sendSubscribeMark,
    updateSubscribeMark,

    stopLoading,
    startLoading,

    // login epics
    logoutOnUnauthorizedError,
    resetPassword,
    passwordRecovery,
    logIn,
    logOut,
    redirectToPreviousPageOnLoginSuccess,

    // education
    loadEducationMaterialTypes,
    loadNumberOfEducationMaterials,
    requestChangeEducationMaterialStatus,
    requestResetEducationMaterialStatus,
    updateEducationMaterialStatus,
    redirectToDocumentOnSelectCompetence,

    startNotificationCountDispatcher,
    sendNotificationStatus,
    loadNotificationList,
    loadNewNotificationsCount,
    loadLatestNotifications,

    // files
    fetchFilesEpic,
    deleteFilesEpic,
    redoUploadFileEpic,
    processRedoUploadFileEpic,

    // others
    loadObjectCard,
    loadObjectCardLinked,
    loadObjectCardSimilar,

    loadUser,
    loadUserPermissions,
    loadCurrentUserPersonalData,
    sendForm,
    loadCurrentUserWorkInfo,
    loadCurrentUserCompetencies,
    loadCurrentUserKnowledgeCategories,
    loadCurrentUserSkills,
    loadUserIdByLogin,
    loadCompetencies,
    loadOrganizationsInfo,
    loadSkills,
    createNewSkill,
    addCreatedSkillToSkillsFormData,
    loadCatalogueCategories,
    loadCatalogueCategoryDocuments,
    loadCatalogueCategoryWithKeywords,
    loadCatalogueCategoriesRules,

    requestConfirmCategory,
    requestRevertCategory,

    loadOrgStructureWithEmployees,
    loadRecommendations,
    loadRedirectionInfo,

    showAjaxErrorMessage,

    logEventEpic,

    loadParentConceptsEpic,
    loadChildrenConceptsEpic,
    loadResourceViewEpic,

    loadSearchTabsMeta,
    loadSearchTabsMetaSimple,
    loadSingleTabFullMeta,
    setSearchTabFullMetaEpic,
    setSearchActiveTabEpic,

    // Tour
    loadGuideStats,
    loadListGuideStats,
    updateGuideStats,

    loadAvatar,
    loadBigAvatar,
    loadPersonWidgetAvatar,
    loadProfileAvatar,
    loadLittleUserMenuAvatar,
    loadDropDownUserMenuAvatar,

    // Doodles
    loadDoodle
);
