/**
 * Created by lkarmelo on 27.02.2019.
 */

import memoizeOne from 'memoize-one';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

type Meta = {
    [filterName: string]: FilterMeta;
};

type FilterMeta = {
    order?: number;
    values?: Filter[];
};

export type Filter = {
    label: string;
    value: string;
    parent?: string;
};

export type FilterWithChildren<TFilterExt = {}> = Filter & {
    children?: string[];
} & TFilterExt;
export type MetaWithNormalizedValues<
    TMeta extends FilterMeta,
    TFilterExt = {}
> = Omit<TMeta, 'values'> & {
    topValues: string[];
    values: {
        [valueId: string]: FilterWithChildren<TFilterExt>;
    };
};

const defaultSortFunc = (keyValA, keyValB) => {
    const orderA = keyValA[1].order;
    const orderB = keyValB[1].order;
    if (orderA === null && orderB !== null) {
        return 1;
    }
    if (orderA !== null && orderB === null) {
        return -1;
    }
    if (orderA !== null && orderB !== null) {
        return orderA - orderB;
    }
    return 0;
};
export const createFilterOrderer = (sortFunc = defaultSortFunc, mapFilter?) =>
    memoizeOne((filtersMeta: Meta) => {
        if (
            !filtersMeta ||
            typeof filtersMeta !== 'object' ||
            Array.isArray(filtersMeta)
        ) {
            throw TypeError('Filters meta should be an object');
        }

        return Object.entries(filtersMeta)
            .filter(([_, meta]) => !!meta)
            .sort(sortFunc)
            .map(([filterName, meta]) => {
                return mapFilter ? mapFilter(filterName, meta) : meta;
            });
    });

export const createFilterValuesNormalizer = <T extends FilterMeta>(
    getValueId = (item) => item.value
) =>
    memoizeOne((filterMeta: T[]): MetaWithNormalizedValues<T>[] => {
        if (!Array.isArray(filterMeta)) {
            throw TypeError('filter meta should be an array');
        }
        return filterMeta.map((meta) => {
            const { values: oldValues, ...nextMeta } = meta;

            if (!meta || !Array.isArray(oldValues)) {
                return { ...nextMeta, topValues: [], values: {} };
            }

            const values = {};
            const topValues = [];

            oldValues.forEach((value) => {
                const valId = getValueId(value);

                values[valId] = values[valId]
                    ? { ...values[valId], ...value }
                    : { ...value };

                if (!value.parent) {
                    topValues.push(valId);
                } else {
                    if (!values[value.parent]) {
                        values[value.parent] = { children: [] };
                    }
                    if (!values[value.parent].children) {
                        values[value.parent].children = [];
                    }
                    values[value.parent].children.push(valId);
                }
            });

            return { ...nextMeta, values, topValues };
        });
    });
