/**
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 16.01.2018
 * -----
 * Last Modified: 19.02.2018 13:34:17
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import { createAction } from 'redux-actions';

import * as Store from '../store/StoreNamespace';

export const SET_FAVORITE = 'SET_FAVORITE';
export const SEND_FAVORITE = 'SEND_FAVORITE';

export const setFavorite = createAction<Store.IMarkDocument>(SET_FAVORITE);
export const sendFavorite = createAction<Store.IMarkDocument>(SEND_FAVORITE);
