import { Action } from 'redux-actions';
import {
    createStore,
    applyMiddleware,
    compose,
    MiddlewareAPI,
    Dispatch,
} from 'redux';
import { createEpicMiddleware, ActionsObservable } from 'redux-observable';

import initialState from './initialState';
import * as IStore from './StoreNamespace';

import reducers from '../reducers';
import epics from '../epics';
import api from '../../api/apiCalls';
import { ContextAction } from '../context/connectWithContext';

const dependencies: {
    apiCall: any;
    context: (state: IStore.IState, action: ContextAction<any>) => any;
} = {
    apiCall: api,
    context: (state: IStore.IState, action: ContextAction<any>): any => {
        if (!action.originalContext) {
            return state;
        }

        let actionState = state;
        const currentIndex =
            action.originalContext.length - action.context.length + 1;
        const paths = action.originalContext.slice(0, currentIndex);

        for (let i = 0; i < paths.length; i++) {
            actionState = actionState && actionState[paths[i]];
        }

        return actionState;
    },
};

const epicMiddleware = createEpicMiddleware(epics, {
    dependencies,
    adapter: {
        input: (action$: ActionsObservable<any>) =>
            action$.map((action) =>
                Object.assign(
                    action,
                    !!action.context && { originalContext: action.context }
                )
            ),
        output: (action$) => action$,
    },
});

type ErrorHandler = (
    error: Error,
    state: () => IStore.IState,
    action: Action<any>,
    dispatchFunction: Dispatch<any>
) => void;

const createCatchMiddleware =
    (errorHandler: ErrorHandler) =>
    (store: MiddlewareAPI<any>) =>
    (next: Dispatch<any>) =>
    (action) => {
        try {
            return next(action);
        } catch (err) {
            errorHandler(err, store.getState, action, store.dispatch);
            return err;
        }
    };

const reducerErrorHandler: ErrorHandler = (error, getState, lastAction) => {
    console.error(
        `THE ERROR\n ${error}\n last action  was: ${JSON.stringify(lastAction)}`
    );
    console.trace(error.stack);
};

const catchMiddleware = createCatchMiddleware(reducerErrorHandler);

export default createStore(
    reducers,
    initialState,
    compose(
        applyMiddleware(catchMiddleware, epicMiddleware),
        (<any>window).__REDUX_DEVTOOLS_EXTENSION__
            ? (<any>window).__REDUX_DEVTOOLS_EXTENSION__()
            : (f) => f
    )
);
