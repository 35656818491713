/**
 * Created by Lkarmelo on 24.05.2018.
 */

import { createSelector } from 'reselect';

import * as Store from 'app/redux/store/StoreNamespace';
import { IOption } from 'app/components/common/controls/Option';

export const knowledgeCategoriesToOptions = createSelector(
    (
        categories: Store.INormalizedHierarchicalCategories
    ): Store.INormalizedHierarchicalCategoriesMap => categories.categories,
    (categories: Store.INormalizedHierarchicalCategoriesMap): IOption[] =>
        categories
            ? Object.keys(categories).map((catId) => {
                  const category = categories[catId];
                  return {
                      label: category.item.title,
                      value: catId,
                  };
              })
            : []
);
