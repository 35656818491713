/**
 * Created by lkarmelo on 03.09.2019.
 */

import { connect } from 'react-redux';

import {
    IQueryTooLongActionProps,
    IQueryTooLongStateProps,
} from 'app/components/search/QueryTooLong/interfaces/IQueryTooLongProps';
import { setQuery } from 'app/redux/actions/search/searchQuery';
import * as Store from 'app/redux/store/StoreNamespace';
import { fetchSearchResults } from 'app/redux/actions/search/results';

import QueryTooLong from './QueryTooLong';

const mapStateToProps = (state: Store.IState): IQueryTooLongStateProps => ({
    lastExecutedSearchQuery: state.search.lastExecutedSearchQuery,
});

const mapDispatchToProps = (dispatch): IQueryTooLongActionProps => ({
    onShortenQuery(query: string) {
        dispatch(setQuery(query));
        dispatch(fetchSearchResults());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(QueryTooLong);
