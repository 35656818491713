import * as Store from 'app/redux/store/StoreNamespace';

export const getResultSearchQuery = (
    inSearchPosition: Store.IQueryPosition, searchQuery: string, query?: string
): string => {
    let resultSearchQuery = '';
    if (typeof inSearchPosition === 'object') {
        const {start, length} = inSearchPosition;
        const searchBeforeQuery = searchQuery.slice(0, start);
        const searchAfterQuery = searchQuery.slice(start + length, searchQuery.length);
        if (typeof query === 'string' && query.length > 0) {
            resultSearchQuery = searchBeforeQuery + query + searchAfterQuery;
        } else {
            resultSearchQuery = searchBeforeQuery + searchAfterQuery;
        }
    }
    return resultSearchQuery;
};
