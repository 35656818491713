import React from 'react';

import { isFiltersTitle, isHintFilter, isHintPerson, isHintRegular } from '../../types';
import * as styles from '../../SearchPanel.scss';

import { RenderFilterHintContent, RenderPersonHintContent, RenderQueryHintContent } from '.';

export const RenderHintContent = (value, label, meta, onClickPersonList) => {
    if (isHintPerson(meta)) {
        return (<RenderPersonHintContent
            userId={value}
            userName={label}
            meta={meta}
            onClickPersonList={onClickPersonList}
        />);
    } if (isHintRegular(meta)) {
        return (<RenderQueryHintContent label={label} meta={meta} />);
    } if (isHintFilter(meta)) {
        return <RenderFilterHintContent label={label} meta={meta} />;
    } if (isFiltersTitle(meta)) {
        return (<div className={`${styles.searchPanelFiltersTitle}`}>
            Включить фильтр...
        </div>);
    }
};
