/**
 * Created by lkarmelo on 15.08.2018.
 */

declare global {
    /* tslint:disable-next-line: interface-name */
    interface HTMLElement {
        __childWithPortal: HTMLElement;
        __parentWithPortal: HTMLElement;
    }
}

export function linkChildWithParent(child: HTMLElement, parent: HTMLElement) {
    child.__parentWithPortal = parent;
    parent.__childWithPortal = child;
}

export function isElementInParentChain(
    supposedParent: HTMLElement,
    elementToTraverse: HTMLElement
): boolean {
    let parent = elementToTraverse;
    let isElementInChain = false;
    do {
        if (parent === supposedParent) {
            isElementInChain = true;
            break;
        }
        parent = parent.__parentWithPortal
            ? parent.__parentWithPortal
            : parent.parentElement;
    } while (parent !== null);
    return isElementInChain;
}
