import React, { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { MAX_USERS_SHOWN_IN_SEARCH_HINTS } from 'app/utils/constants';
import { getParametersForLinkToProfileFromIdAndLogins } from 'app/utils/document/login';
import clientRoutes from 'app/routing/clientRoutes';
import { getColorFromHash } from 'app/utils/document/getColorFromHash';

import { PersonHintOptionMeta } from '../../types';
import * as styles from '../../SearchPanel.scss';

interface IRenderPersonHintContent {
    userId: string;
    userName: string;
    meta: PersonHintOptionMeta;
    onClickPersonList: () => void;
}

export const RenderPersonHintContent: FC<IRenderPersonHintContent> = (
    {
        userId,
        userName,
        meta: { overallPersonsCount, fullPost, userLogins },
        onClickPersonList,
    }) => {
    if (overallPersonsCount !== undefined) {
        // используем замыкание, чтобы всегда использовалась введённая строка, по которой вышли результаты, а не последняя напечатанная
        // рендерим строку с информацией, она всегда будет первой
        return (
            <div className={styles.searchPanelPersonHintsInfo}>
                <span className={styles.searchPanelPersonNumber}>Пользователи: {overallPersonsCount}</span>
                {(overallPersonsCount > MAX_USERS_SHOWN_IN_SEARCH_HINTS) &&
                    <button
                        onClick={onClickPersonList}
                        className={classNames(styles.searchPanelPersonListLink, 'btn')}
                    >
                        Показать все
                    </button>
                }
            </div>
        );
    }
    const profileLinkParams = getParametersForLinkToProfileFromIdAndLogins(userId, userLogins);

    return (
        <Link
            to={clientRoutes.account.subRoutes.profile.getUrl(null, profileLinkParams)}
            className={styles.searchPanelPersonHint}
        >
            <div
                className={styles.searchPanelPersonHintPic}
                style={{
                    backgroundColor: getColorFromHash(userId),
                }}
            />
            <div className={styles.searchPanelPersonHintNamePostBlock}>
                <div className={styles.searchPanelPersonHintName}>
                    {userName}
                </div>
                <div className={styles.searchPanelPersonHintPost}>
                    {fullPost}
                </div>
            </div>
        </Link>
    );
};
