/**
 * Created by lkarmelo on 29.10.2019.
 */

import React, {
    useLayoutEffect,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useToggle } from 'react-use';

import TreeSelectComponent, {
    sortOptionsByActiveState,
    sortOptionsByCount,
    ISelectOption,
} from 'app/modules/components/TreeSelect';
import { PubSubContext } from 'app/react-context-pubsub';
import './TreeSelect.scss';
import { useDidMount } from 'app/hooks/useDidMount';
import { useDidUpdate } from 'app/hooks/useDidUpdate';
import TreeSelectWithSelectAllBtn from 'app/modules/components/TreeSelect/Select/TreeSelectWithSelectAllBtn';

import IProps from './interfaces/ITreeSelectProps';

const LINES_COUNT = 4;

const compareOptions = (
    optionA: ISelectOption,
    optionB: ISelectOption,
    activeMap: Record<string, boolean>
) => {
    const sortByActiveResult = sortOptionsByActiveState(
        optionA,
        optionB,
        activeMap
    );
    if (sortByActiveResult === 0) {
        return sortOptionsByCount(optionA, optionB);
    }
    return sortByActiveResult;
};

// максимальная высота списка в пикселях
const maxListHeight = 255;

const listComponentProps = {
    compareOptions,
    noFoundPlaceholder: 'Нет подходящих элементов',
};

const TreeSelect: React.FC<IProps> = (props) => {
    const {
        title,
        clearSearchQueryEvent,
        showSearchBar,
        enableVirtualRendering,
        ...nextProps
    } = props;
    const { itemSize, options } = nextProps;

    const [searchQuery, setSearchQuery] = useState('');
    const [isCollapsed, toggleIsCollapsed] = useToggle(true);

    const treeSelectRef = useRef<HTMLDivElement>(null);

    const pubSubContext = useContext(PubSubContext);
    const [listHeight, setListHeight] = useState('');
    const [listElement, setListElement] = useState(null);

    useDidMount(() => {
        setListElement(
            treeSelectRef.current.querySelector('.nkcm-select-list')
        );
    });

    useEffect(() => {
        if (!clearSearchQueryEvent) {
            return;
        }
        const clearSearchQuery = () => {
            setSearchQuery('');
        };
        pubSubContext.subscribe(clearSearchQueryEvent, clearSearchQuery);
        return () =>
            pubSubContext.unSubscribe(clearSearchQueryEvent, clearSearchQuery);
    }, [clearSearchQueryEvent]);

    const limitOpenedListHeight = useCallback(() => {
        const innerListHeight = itemSize * options.length;
        const openedListHeight = Math.min(maxListHeight, innerListHeight);

        setListHeight(openedListHeight.toString());
        listElement.style.height = `${openedListHeight}px`;
    }, [enableVirtualRendering, itemSize, options?.length, listElement]);

    useLayoutEffect(() => {
        if (isCollapsed && listElement) {
            setListHeight(
                options.length < LINES_COUNT
                    ? `${itemSize * options.length}`
                    : `${itemSize * LINES_COUNT}`
            );
            return;
        }

        // если изменилось количество опций, надо проверить, фиксированная ли высота у открытого списка,
        // потому что её в этом случае нужно отрегулировать
        if (listElement && listElement.style.height) {
            setListHeight('');
            limitOpenedListHeight();
        }
    }, [props.options?.length, limitOpenedListHeight]);

    // во время раскрытия списка устанавливаем ему фиксированную высоту, что при поиске по опциям не скакала высота
    const setListHeightOnToggle = useCallback(
        (collapsed: boolean) => {
            if (!treeSelectRef.current) {
                return;
            }

            if (collapsed && listElement) {
                setListHeight(`${itemSize * LINES_COUNT}`);
                return;
            }

            limitOpenedListHeight();
        },
        [limitOpenedListHeight, isCollapsed]
    );

    useDidUpdate(() => {
        if (listElement && listElement.style.height) {
            listElement.style.height = `${listHeight}px`;
        }
    }, [listHeight, isCollapsed]);

    const headerComponentProps = useMemo(
        () => ({
            title,
        }),
        [title]
    );

    const collapseToggleComponentProps = useMemo(
        () => ({
            onAfterToggleChange: setListHeightOnToggle,
            toggleCollapse: (nextCollapse: boolean) => {
                toggleIsCollapsed(nextCollapse);
                nextCollapse && setSearchQuery('');
            },
        }),
        [toggleIsCollapsed, setListHeightOnToggle, setSearchQuery]
    );

    const SearchBarComponent = useMemo(
        () => (showSearchBar ? undefined : () => null),
        [showSearchBar]
    );
    const searchBarComponentProps = useMemo(() => ({ setSearchQuery }), []);

    const getContent = () =>
        enableVirtualRendering ? (
            <TreeSelectComponent
                ref={treeSelectRef}
                {...nextProps}
                collapsed={isCollapsed}
                // нет смысла включать виртуальный рендеринг, если отображается только несколько опций
                enableVirtualRendering={enableVirtualRendering && !isCollapsed}
                searchQuery={searchQuery}
                headerComponentProps={headerComponentProps}
                listComponentProps={listComponentProps}
                collapseToggleComponentProps={collapseToggleComponentProps}
                searchBarComponentProps={searchBarComponentProps}
                SearchBarComponent={SearchBarComponent}
            />
        ) : (
            <TreeSelectWithSelectAllBtn
                ref={treeSelectRef}
                {...nextProps}
                collapsed={isCollapsed}
                searchQuery={searchQuery}
                headerComponentProps={headerComponentProps}
                listComponentProps={listComponentProps}
                collapseToggleComponentProps={collapseToggleComponentProps}
                searchBarComponentProps={searchBarComponentProps}
                SearchBarComponent={SearchBarComponent}
            />
        );

    return getContent();
};

TreeSelect.defaultProps = {
    showSearchBar: true,
    itemSize: 26,
};

export default React.memo(TreeSelect);
