import { connect } from 'react-redux';

import * as Store from 'app/redux/store/StoreNamespace';

import SearchConcepts, {
    ISearchConceptsActionProps,
    ISearchConceptsStateProps,
} from './SearchConcepts';

import {
    clearConceptsSelectState,
    setConceptsSelectState,
} from '../../../../redux/actions/search/concepts';

const mapStateToProps = (state: Store.IState): ISearchConceptsStateProps => ({
    ...state.search.concepts,
});

const mapDispatchToProps = (dispatch): ISearchConceptsActionProps => ({
    applyConceptSelection(selectedConcepts: Record<string, boolean>): void {
        dispatch(setConceptsSelectState({ selectState: selectedConcepts }));
    },
    clearConceptSelection(): void {
        dispatch(clearConceptsSelectState());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchConcepts);
