import React, { useContext, useCallback } from 'react';

import { PubSubContext } from 'app/react-context-pubsub';

import { MessageType } from '../../types';
import {
    HIGHLIGHT_CHOSEN_FILTERS_EVENT,
    UNHIGHLIGHT_CHOSEN_FILTERS_EVENT,
} from '../../../../../ServerOnlyFiltersOptions/ServerOnlyFiltersOptions';
import * as styles from '../../SearchResultsMessage.scss';

interface IResultMessageActionsProps {
    type: MessageType;
    focusSearchQueryInput?: () => void;
    resetFilters(): void;
}

export const ResultMessageActions: React.FC<IResultMessageActionsProps> = ({
    type,
    focusSearchQueryInput,
    resetFilters,
}) => {
    const pubSubContext = useContext(PubSubContext);

    const highlightFilters = useCallback(() => {
        pubSubContext.emitEvent(HIGHLIGHT_CHOSEN_FILTERS_EVENT);
    }, [pubSubContext]);

    const unHighlightFilters = useCallback(() => {
        pubSubContext.emitEvent(UNHIGHLIGHT_CHOSEN_FILTERS_EVENT);
    }, [pubSubContext]);

    switch (type) {
        case 'notFoundBecauseQuery':
            return (
                focusSearchQueryInput && (
                    <div className={styles.searchResultsMessageMessage}>
                        Вы можете&nbsp;
                        <button className="btn" onClick={focusSearchQueryInput}>
                            изменить запрос
                        </button>
                    </div>
                )
            );
        case 'notFoundBecauseFilters':
            return (
                <div className={styles.searchResultsMessageMessage}>
                    Вы можете&nbsp;
                    <button
                        className="btn"
                        onClick={resetFilters}
                        onMouseOver={highlightFilters}
                        onMouseLeave={unHighlightFilters}
                    >
                        сбросить все фильтры&nbsp;
                    </button>
                    или удалить отдельные значения
                </div>
            );
        case 'notFoundBecauseQueryAndFilters':
            return (
                <div className={styles.searchResultsMessageMessage}>
                    Вы можете&nbsp;
                    <button
                        className="btn"
                        onClick={resetFilters}
                        onMouseOver={highlightFilters}
                        onMouseLeave={unHighlightFilters}
                    >
                        сбросить фильтры&nbsp;
                    </button>
                    {focusSearchQueryInput && (
                        <>
                            или &nbsp;
                            <button
                                className="btn"
                                onClick={focusSearchQueryInput}
                            >
                                изменить запрос
                            </button>
                        </>
                    )}
                </div>
            );
        default:
            return null;
    }
};
