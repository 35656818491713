/**
 * Created by lkarmelo on 25.04.2019.
 */
import * as Store from 'app/redux/store/StoreNamespace';
import Api from 'app/api/Api';
import { getFilterStatsFromAggregation } from 'app/utils/getFilterStatsFromAggregation';

import { createSimpleLoadingEpic } from './utils';

import {
    initialFilterStatsReject,
    initialFilterStatsRequest,
    initialFilterStatsResolve,
} from '../actions/loading';
import {
    fetchInitialFilterStats,
    setInitialFilterStats,
} from '../actions/search/initialFilterStats';

export const loadInitialFilterStats = createSimpleLoadingEpic({
    triggers: [fetchInitialFilterStats.toString()],
    apiCallName: 'initialFilterStats',
    actions: {
        requestAction: initialFilterStatsRequest,
        resolveAction: initialFilterStatsResolve,
        rejectAction: initialFilterStatsReject,
        setAction: setInitialFilterStats,
    },
    retryOnAfterAuth: true,
    fetchAction: fetchInitialFilterStats,
    transformResponse: (
        response: Api.IDocumentSearchResponseBody
    ): Store.IFilterStats =>
        getFilterStatsFromAggregation(response.aggregations),
});
