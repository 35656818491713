/**
 * Created by lkarmelo on 22.08.2018.
 */

import { MiddlewareAPI } from 'redux';
import { concat } from 'rxjs/observable/concat';
import { Observable } from 'rxjs/Observable';
import { merge } from 'rxjs/observable/merge';

import {
    fetchRecommendations,
    setRecommendations,
} from 'app/redux/actions/recommendations';
import {
    addMultiSelectFilterValue,
    removeMultiSelectFilterValue,
    setDateRangeFrom,
    setDateRangeTo,
    setFilterValue,
} from 'app/redux/actions/search/filters';
import { resetSorting, setSorting } from 'app/redux/actions/sorting';
import {
    recommendationsReject,
    recommendationsRequest,
    recommendationsResolve,
} from 'app/redux/actions/loading';
import * as Store from 'app/redux/store/StoreNamespace';
import Api from 'app/api/Api';

import { setLimit, setSkip } from '../actions/search/searchPaging';
import { withContext } from '../context/connectWithContext';
import { RecommendationsColumnName } from '../../components/document-management/Recommendations/interfaces/ITableRecord';

const filtersChangeActions = [
    addMultiSelectFilterValue.toString(),
    removeMultiSelectFilterValue.toString(),
    setFilterValue.toString(),
    setDateRangeFrom.toString(),
    setDateRangeTo.toString(),
];

const sortingChangeActions = [setSorting.toString(), resetSorting.toString()];

const pagingChangeActions = [setSkip.toString(), setLimit.toString()];

const triggers = [
    ...filtersChangeActions,
    ...sortingChangeActions,
    ...pagingChangeActions,
];

const recommendationsColumnToParamColumn = new Map<string, string>([
    [RecommendationsColumnName.title, 'document'],
    [RecommendationsColumnName.author, 'author'],
    [RecommendationsColumnName.receiver, 'receiver'],
    [RecommendationsColumnName.createdDate, 'createDate'],
    [RecommendationsColumnName.planDate, 'planDate'],
    [RecommendationsColumnName.isNecessary, 'necessity'],
    [RecommendationsColumnName.finishDate, 'learnedDate'],
    [RecommendationsColumnName.status, 'documentStatus'],
]);

const getDateFilterValue = (filter): Store.IDateFilterValue =>
    filter ? filter.value : {};

export const loadRecommendations = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    merge(
        action$
            .ofType(
                fetchRecommendations.toString(),
                ...filtersChangeActions,
                ...sortingChangeActions
            )
            .filter(
                (action) =>
                    // for context action check filter
                    triggers.indexOf(action.type) !== -1 &&
                    action.context &&
                    action.context[0] === Store.RecommendationsContext
            )
            .mapTo(withContext(setSkip(0), Store.RecommendationsContext)),
        action$
            .ofType(fetchRecommendations.toString(), ...triggers)
            .filter(
                (action) =>
                    action.context &&
                    action.context[0] === Store.RecommendationsContext
            )
            .debounceTime(300)
            .mergeMap(() => {
                const recommendationsState = store.getState().recommendations;
                const { filters } = recommendationsState;
                const createdDateFrom = getDateFilterValue(
                    filters[RecommendationsColumnName.createdDate]
                ).from;
                const createdDateTo = getDateFilterValue(
                    filters[RecommendationsColumnName.createdDate]
                ).to;
                const planDateFrom = getDateFilterValue(
                    filters[RecommendationsColumnName.planDate]
                ).from;
                const planDateTo = getDateFilterValue(
                    filters[RecommendationsColumnName.planDate]
                ).to;
                const learnedDateFrom = getDateFilterValue(
                    filters[RecommendationsColumnName.finishDate]
                ).from;
                const learnedDateTo = getDateFilterValue(
                    filters[RecommendationsColumnName.finishDate]
                ).to;

                const requestParams: Required<Api.IRecommendationsRequestParameters> =
                    {
                        document:
                            filters[RecommendationsColumnName.title] &&
                            <string>(
                                filters[RecommendationsColumnName.title].value
                            ),
                        author:
                            filters[RecommendationsColumnName.author] &&
                            <string>(
                                filters[RecommendationsColumnName.author].value
                            ),
                        createdDateFrom,
                        createdDateTo,
                        planDateFrom,
                        planDateTo,
                        learnedDateFrom,
                        learnedDateTo,
                        status:
                            filters[RecommendationsColumnName.status] &&
                            <Store.DocumentEducationStatus[]>(
                                (<string[]>(
                                    filters[RecommendationsColumnName.status]
                                        .value
                                )).filter((status) => status !== 'refused')
                            ),
                        recommendationStatus:
                            filters[RecommendationsColumnName.status] &&
                            (<string[]>(
                                filters[RecommendationsColumnName.status].value
                            )).includes('refused')
                                ? true
                                : undefined,
                        necessity:
                            filters[RecommendationsColumnName.isNecessary] &&
                            <boolean>(
                                filters[RecommendationsColumnName.isNecessary]
                                    .value
                            ),
                        receiver:
                            filters[RecommendationsColumnName.receiver] &&
                            <string>(
                                filters[RecommendationsColumnName.receiver]
                                    .value
                            ),
                        orderBy:
                            recommendationsState.sorting.field &&
                            recommendationsColumnToParamColumn.get(
                                recommendationsState.sorting.field
                            ),
                        orderAsc:
                            typeof recommendationsState.sorting.order ===
                            'string'
                                ? recommendationsState.sorting.order === 'asc'
                                : undefined,
                        page:
                            Math.floor(
                                recommendationsState.paging.skip /
                                    recommendationsState.paging.limit
                            ) + 1,
                        count: recommendationsState.paging.limit,
                    };

                return concat(
                    apiCall
                        .recommendations(requestParams)
                        .mergeMap(
                            ({
                                response,
                            }: {
                                response: Store.IRecommendations;
                            }) => {
                                return Observable.of(
                                    withContext(
                                        setRecommendations(response),
                                        Store.RecommendationsContext
                                    )
                                );
                            }
                        )
                        .catch((e) => {
                            console.error(e);
                            return Observable.of(recommendationsReject(e));
                        })
                        .takeUntil(
                            action$.ofType(
                                fetchRecommendations.toString(),
                                ...triggers
                            )
                        ),
                    Observable.of(recommendationsResolve())
                )
                    .takeUntil(action$.ofType(recommendationsReject.toString()))
                    .startWith(recommendationsRequest());
            })
    );
