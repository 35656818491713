/**
 * Created by Lkarmelo on 18.10.2017.
 */

import React, { PureComponent } from 'react';
import classNames from 'classnames';
import autoBind from 'autobind-decorator';

import Option from 'app/components/common/controls/Option/Option';

import IProps from './interfaces/ILinearMultiSelectProps';

import './LinearMultiSelect.scss';

@autoBind
export default class LinearMultiSelect extends PureComponent<IProps> {
    static defaultProps = {
        options: [],
        activeOptionValues: [],
    };

    get areAllSelected(): boolean {
        const { activeOptionValues, options } = this.props;
        return (
            activeOptionValues.length === 0 ||
            activeOptionValues.length === options.length
        );
    }

    get isOneSelected(): boolean {
        const activeLength = this.props.activeOptionValues.length;
        return activeLength === 0 || activeLength === 1;
    }

    render() {
        const { options, activeOptionValues, allowAllOption } = this.props;
        return (
            <div
                className={classNames('linear-multiselect', {
                    'linear-multiselect--single': this.isOneSelected,
                })}
            >
                <ul className="clearfix">
                    {allowAllOption && (
                        <li
                            className={classNames('linear-multiselect-item', {
                                'linear-multiselect-item--active':
                                    this.areAllSelected,
                            })}
                            onClick={this.onAllClick}
                        >
                            Все
                        </li>
                    )}
                    {options.map((opt) => (
                        <Option
                            baseClassName="linear-multiselect"
                            key={opt.value}
                            option={opt}
                            isActive={
                                activeOptionValues.includes(opt.value) &&
                                (!allowAllOption || !this.areAllSelected)
                            }
                            onSelect={this.onSelectOption}
                            onDeselect={this.onDeselectOption}
                        />
                    ))}
                </ul>
            </div>
        );
    }

    onAllClick() {
        this.props.onSelectAll(this.props.filterName);
    }

    onSelectOption(value: string): void {
        const {
            allowAllOption,
            activeOptionValues,
            options,
            onSelectAll,
            filterName,
        } = this.props;
        if (
            allowAllOption &&
            activeOptionValues.length + 1 === options.length
        ) {
            onSelectAll(filterName);
        } else {
            this.props.onSelectOption(value, this.props.filterName);
        }
    }

    onDeselectOption(value: string): void {
        this.props.onDeselectOption(value, this.props.filterName);
    }
}
