import { useState, useMemo, useEffect } from 'react';

import { FEEDBACK_BLOCK_INDEX_IN_SEARCH_RESULTS } from 'app/utils/constants';

interface IOptions<TResult> {
    list: TResult[];
    askFeedback: boolean;
    skip: number;
}

const feedbackBlock = 'ASK_FEEDBACK';

const useSearchResultsWithFeedback = <TResult>(
    options: IOptions<TResult>
): (TResult | string)[] => {
    const { list, askFeedback, skip } = options;
    const [feedbackBlockIndices, setFeedbackBlockIndices] = useState<number[]>(
        []
    );

    const listWithFeedback = useMemo(() => {
        if (!list) {
            return list;
        }

        const newList = [...list];
        feedbackBlockIndices.forEach((feedbackIndex) => {
            (newList as (TResult | string)[]).splice(
                feedbackIndex,
                0,
                `${feedbackBlock}_${feedbackIndex}`
            );
        });

        return newList;
    }, [list, feedbackBlockIndices]);

    useEffect(() => {
        if (!askFeedback) {
            skip === 0 && setFeedbackBlockIndices([]);
        } else {
            skip === 0 &&
                setFeedbackBlockIndices([
                    FEEDBACK_BLOCK_INDEX_IN_SEARCH_RESULTS,
                ]);
        }
    }, [list]);

    useEffect(() => {
        if (!askFeedback || !list) {
            return;
        }

        skip !== 0 &&
            setFeedbackBlockIndices((prevIndices) => [
                ...prevIndices,
                skip +
                    FEEDBACK_BLOCK_INDEX_IN_SEARCH_RESULTS +
                    prevIndices.length,
            ]);
    }, [list && list.length]);

    return listWithFeedback;
};

export default useSearchResultsWithFeedback;
