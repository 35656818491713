import 'app/types/helpers';

import './app/utils/rxjsAdd';

import 'stylessheets/perfect-scrollbar.scss';
import 'stylessheets/react-date-picker.scss';
import 'stylessheets/react-split-pane.scss';
import 'stylessheets/antd.scss';
import 'stylessheets/common.scss';

import moment from 'moment';
import * as React from 'react';
import ReactDOM from 'react-dom';

import App from 'app/components/App/App';

import { initializingAnalytics } from './initializingAnalytics';
import { startPollForIE } from './pollForIE';

import 'stylessheets/theme.scss';
// import registerServiceWorker from './registerServiceWorker';

moment.locale('ru');

ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();
initializingAnalytics();
startPollForIE();
