/**
 * Created by Lkarmelo on 05.07.2018.
 */

import { connect } from 'react-redux';

import { ApproveAction } from 'app/components/Modals';
import { initForm, removeForm, sendForm } from 'app/redux/actions/forms';
import { refuseEducationMaterialRecommendations } from 'app/redux/actions/education';
import { withContext } from 'app/redux/context/connectWithContext';
import { SearchDocumentName } from 'app/redux/actions/interfaces/DocumentEnum';
import {
    IApproveActionActionProps as IActionProps,
    IApproveActionOwnProps as IOwnProps,
    IApproveActionStateProps as IStateProps,
} from 'app/components/Modals/ApproveAction/interfaces/IApproveActionProps';
import * as Store from 'app/redux/store/StoreNamespace';
import { FormName } from 'app/api/FormName';

interface IAdditionalProps {
    documentId: string;
}

const mapStateToProps = (
    state: Store.IState,
    ownProps: IOwnProps & IAdditionalProps
): IOwnProps & IStateProps & IAdditionalProps => {
    return {
        status:
            state.forms[FormName.DenyRecommendation] &&
            state.forms[FormName.DenyRecommendation].status,
        isVisible: ownProps.isVisible,
        documentId: ownProps.documentId,
        onCancel: ownProps.onCancel,
        bodyText: 'Вы уверены, что хотите отклонить рекомендации к документу?',
        successMsgText: 'Рекомендация успешно отклонена',
    };
};

const mapDispatchToProps = (dispatch): IActionProps<IAdditionalProps> => ({
    onShown() {
        dispatch(initForm(FormName.DenyRecommendation, null));
    },
    onHidden() {
        dispatch(removeForm(FormName.DenyRecommendation));
    },
    onSend({ documentId }: IAdditionalProps) {
        dispatch(sendForm(FormName.DenyRecommendation, documentId));
    },
    onSentSuccess({ documentId }: IAdditionalProps) {
        dispatch(
            withContext(
                refuseEducationMaterialRecommendations(documentId),
                SearchDocumentName.education
            )
        );
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApproveAction as any);
