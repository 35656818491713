/**
 * Created by Lkarmelo on 11.09.2017.
 */

import * as customHistory from 'history';
import { History } from 'history';

const history: History = customHistory.createBrowserHistory();

export default history;
