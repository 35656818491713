import { Action, handleActions } from 'redux-actions';
import deepEqual from 'deep-equal';

import * as Store from 'app/redux/store/StoreNamespace';
import initialState from 'app/redux/store/initialState';
import { traverseConceptTree } from 'app/utils/concepts';

import {
    setConceptsSelectState,
    clearConceptsSelectState,
    ISetConceptsSelectStatePayload,
    ISetExtractedConceptsPayload,
    setExtractedConcepts,
} from '../../actions/search/concepts';

export default handleActions<Partial<Store.ISearchConcepts>, any>(
    {
        [setExtractedConcepts.toString()](
            state: Store.ISearchConcepts,
            { payload }: Action<ISetExtractedConceptsPayload>
        ): Store.ISearchConcepts {
            if (!Array.isArray(payload.extractedConcepts)) {
                return initialState.search.concepts;
            }

            const concepts: Record<string, Store.IConceptWithRelations> = {};
            const extractedConcepts: string[] = [];
            const initialSelectState: Record<string, boolean> = {};

            const tryAddToConceptsAndSelection = (
                concept: Store.IConceptWithRelations
            ) => {
                const conceptUri = concept.item.uri;
                if (!concepts[conceptUri]) {
                    concepts[conceptUri] = concept;
                    initialSelectState[conceptUri] = concept.item.selected;
                }
            };

            const processedPayload: Store.IConceptWithRelations[] =
                payload.extractedConcepts.map((concept) => {
                    // приводим related к виду IExtractedConcept для однородности
                    return {
                        ...concept,
                        related: concept.related?.map((rel) => ({ item: rel })),
                    };
                });

            processedPayload.forEach(tryAddToConceptsAndSelection);
            // сначала заполняем концептами верхнего уровня, а потом уже related и tree
            processedPayload.forEach(({ related }) =>
                related?.forEach(tryAddToConceptsAndSelection)
            );

            traverseConceptTree(
                processedPayload,
                (
                    concept: Store.IConceptWithRelations,
                    parent?: Store.IConceptWithRelations
                ) => {
                    const conceptUri = concept.item.uri;
                    tryAddToConceptsAndSelection(concept);

                    if (!parent) {
                        extractedConcepts.push(conceptUri);
                    }
                }
            );

            const nextState: Store.ISearchConcepts = {
                concepts,
                extractedConcepts,
                selectState: { ...state.selectState },
                initialSelectState: payload.setInitialSelected
                    ? initialSelectState
                    : state.initialSelectState,
            };

            return nextState;
        },
        [setConceptsSelectState.toString()](
            state: Store.ISearchConcepts,
            { payload }: Action<ISetConceptsSelectStatePayload>
        ): Store.ISearchConcepts {
            let nextSelectState;
            if (payload.ignoreEqualityToInitial) {
                nextSelectState = payload.selectState;
            } else {
                // если selectState в payload равен initialSelectState, то это эквивалентно пустому selectState, поэтому
                // ставим пустой
                nextSelectState = deepEqual(
                    payload.selectState,
                    state.initialSelectState
                )
                    ? {}
                    : payload.selectState;
            }
            return {
                ...state,
                selectState: nextSelectState,
            };
        },
        [clearConceptsSelectState.toString()](
            state: Store.ISearchConcepts
        ): Store.ISearchConcepts {
            return {
                ...state,
                selectState: initialState.search.concepts.selectState,
            };
        },
    },
    initialState.search.concepts
);
