/**
 * Created by Lkarmelo on 05.12.2017.
 */

import React, { PureComponent } from 'react';

import {
    FieldValidationRecursive,
    IField as IFormField,
    IFieldValidation,
    IFormProps,
} from 'app/components/common/forms/Form';
import * as Store from 'app/redux/store/StoreNamespace';

export { IFormProps as IWrappedFormProps };

export interface IFormContainerProps {
    status: Store.FormStatus;
    fields?: IFormFields;
    validation?: Validator;
    activateValidationForAllFields?: boolean;
    onValidationFail?(): void;
    onValidationSuccess?(): void;
    onFormSaved?(): void;
    onFormSaveFail?(): void;
}

interface IFormFields {
    [fieldName: string]: IFormField;
}

type Validator = ValidatorFunc | { [fieldName: string]: ValidatorFunc[] };

type ValidatorFunc = (fieldValue: any) => IValidatorFuncResult;

interface IValidatorFuncResult {
    isValid: boolean;
    message?: string;
}

export interface IFormContainerState {
    validatedFields: {
        [fieldName: string]: FieldValidationRecursive;
    };
}

type ActivateValidationFieldsMap = {
    [fieldName: string]: boolean | { [fieldName: string]: boolean };
};

const formContainer = <
    WT extends IFormProps = IFormProps,
    T extends IFormContainerProps & WT = WT & IFormContainerProps
>(
    WrappedComponent: React.ComponentType<WT>
) =>
    class FormContainer extends PureComponent<T, IFormContainerState> {
        state: IFormContainerState = {
            validatedFields: {},
        };

        activeFieldsForValidation: ActivateValidationFieldsMap = {};

        constructor(props: T) {
            super(props);

            this.setActivateValidationForField =
                this.setActivateValidationForField.bind(this);
            this.validateSingleField = this.validateSingleField.bind(this);

            if (props.validation && props.activateValidationForAllFields) {
                const validatedFields = this.getValidationResult(
                    props.fields,
                    props.validation,
                    props,
                    true
                );
                this.state = { validatedFields };
            }
        }

        render(): JSX.Element {
            const {
                status,
                onFormSaved,
                onFormSaveFail,
                activateValidationForAllFields,
                ...rest
            } = this.props as IFormContainerProps;
            return (
                <WrappedComponent
                    {...(rest as WT)}
                    validationResults={this.state.validatedFields}
                    setActivateValidationForField={
                        this.setActivateValidationForField
                    }
                    validateSingleField={this.validateSingleField}
                />
            );
        }

        componentDidMount(): void {
            this.callValidationCallbacks(
                this.props,
                this.getValidationHasErrors(this.state.validatedFields)
            );
        }

        componentWillReceiveProps(nextProps: T) {
            this.validateFields(nextProps);
        }

        componentDidUpdate(prevProps: T) {
            const { status, onFormSaved, onFormSaveFail } = this.props;
            const prevStatus = prevProps.status;
            if (prevStatus !== status) {
                if (status === Store.FormStatus.SendingSuccess) {
                    onFormSaved();
                } else if (status === Store.FormStatus.SendingFail) {
                    onFormSaveFail && onFormSaveFail();
                }
            }
        }

        validateFields(props: T): void {
            const { fields, validation, activateValidationForAllFields } =
                props;
            if (!validation) {
                return;
            }
            const fieldsValidation = this.getValidationResult(
                fields,
                validation,
                props,
                activateValidationForAllFields,
                this.activeFieldsForValidation
            );

            this.callValidationCallbacks(
                props,
                this.getValidationHasErrors(fieldsValidation)
            );

            this.setState({ validatedFields: fieldsValidation });
        }

        getIsFormValid(shouldCallValidationCallbacks?: boolean): boolean {
            const { fields, validation } = this.props;
            if (!validation) {
                return;
            }
            const fieldsValidation = this.getValidationResult(
                fields,
                validation,
                this.props,
                true
            );
            const hasValidationErrors =
                this.getValidationHasErrors(fieldsValidation);

            shouldCallValidationCallbacks &&
                this.callValidationCallbacks(this.props, hasValidationErrors);

            this.setState({ validatedFields: fieldsValidation });

            return !hasValidationErrors;
        }

        setActivateValidationForField(
            activate: boolean,
            field: string,
            subField?: string
        ): void {
            if (subField === undefined) {
                this.activeFieldsForValidation[field] = activate;
                return;
            }
            if (typeof this.activeFieldsForValidation[field] === 'object') {
                this.activeFieldsForValidation[field][subField] = activate;
            } else {
                this.activeFieldsForValidation[field] = {
                    [subField]: activate,
                };
            }
        }

        validateSingleField(fieldName: string): void {
            const { fields, validation, activateValidationForAllFields } =
                this.props;
            const validationResult: FieldValidationRecursive =
                this.validateField(
                    fieldName,
                    fields[fieldName].value,
                    validation,
                    this.props,
                    activateValidationForAllFields,
                    this.activeFieldsForValidation
                );
            this.callValidationCallbacks(
                this.props,
                this.getValidationHasErrors({ [fieldName]: validationResult })
            );
            this.setState((state) => ({
                validatedFields: {
                    ...state.validatedFields,
                    ...{ [fieldName]: validationResult },
                },
            }));
        }

        getValidationResult(
            fields: IFormFields,
            validation: Validator,
            props: T,
            shouldValidateAllFields: boolean,
            activeFieldsForValidation: ActivateValidationFieldsMap = {}
        ): { [fieldName: string]: FieldValidationRecursive } {
            const fieldsValidation = {};
            const fieldNames = Object.keys(fields);

            fieldNames.forEach((fName) => {
                fieldsValidation[fName] = this.validateField(
                    fName,
                    fields[fName].value,
                    validation,
                    props,
                    shouldValidateAllFields,
                    activeFieldsForValidation
                );
            });
            return fieldsValidation;
        }

        validateField(
            fieldName: string,
            value: any,
            validator: Validator,
            props: T,
            shouldValidateAllFields: boolean,
            activeFieldsForValidation: ActivateValidationFieldsMap
        ): FieldValidationRecursive {
            const isValidationFunc = typeof validator === 'function';

            if (
                typeof value !== 'object' ||
                (Array.isArray(value) &&
                    props.fields[fieldName].type !== 'text-multiple')
            ) {
                const validationResult: IFieldValidation = {
                    isValid: true,
                    messages: [],
                };
                if (
                    !shouldValidateAllFields &&
                    !activeFieldsForValidation[fieldName]
                ) {
                    return validationResult;
                }
                if (isValidationFunc) {
                    const validatorRes: IValidatorFuncResult = (
                        validator as ValidatorFunc
                    )(value);
                    validationResult.isValid = validatorRes.isValid;
                    validatorRes.message &&
                        validationResult.messages.push(validatorRes.message);
                } else {
                    validator[fieldName] &&
                        validator[fieldName].forEach((valid) => {
                            const singleValidatorRes: IValidatorFuncResult =
                                valid(value);
                            if (!singleValidatorRes.isValid) {
                                validationResult.isValid = false;
                                validationResult.messages.push(
                                    singleValidatorRes.message
                                );
                            }
                        });
                }
                return validationResult;
            }
            if (
                Array.isArray(value) &&
                props.fields[fieldName].type === 'text-multiple'
            ) {
                const res = {};
                Object.keys(value).forEach((valName) => {
                    if (
                        !shouldValidateAllFields &&
                        (!activeFieldsForValidation[fieldName] ||
                            (typeof activeFieldsForValidation[fieldName] ===
                                'object' &&
                                activeFieldsForValidation[fieldName] &&
                                !activeFieldsForValidation[fieldName][valName]))
                    ) {
                        res[valName] = { isValid: true, messages: [] };
                        return;
                    }
                    res[valName] = this.validateField(
                        fieldName,
                        value[valName],
                        validator,
                        props,
                        true,
                        activeFieldsForValidation
                    );
                });
                return res;
            }
            // TODO: сделать валидацию объектов с подполями, если понадобится
            console.error(
                'Validation of field value as object is not implemented'
            );
        }

        callValidationCallbacks(
            props: T,
            hasValidationErrors: boolean,
            ignoreFailCallBack = false
        ): void {
            const { onValidationFail, onValidationSuccess, status } = props;

            if (
                hasValidationErrors &&
                !ignoreFailCallBack &&
                onValidationFail &&
                status !== Store.FormStatus.Invalid
            ) {
                onValidationFail();
            } else if (
                !hasValidationErrors &&
                onValidationSuccess &&
                status === Store.FormStatus.Invalid
            ) {
                onValidationSuccess();
            }
        }

        getValidationHasErrors(fieldsValidation: {
            [fieldName: string]: FieldValidationRecursive;
        }): boolean {
            let hasValidationErrors = false;

            Object.keys(fieldsValidation).forEach((fName) => {
                if (
                    this.hasFieldValidationResultErrors(fieldsValidation[fName])
                ) {
                    hasValidationErrors = true;
                }
            });
            return hasValidationErrors;
        }

        hasFieldValidationResultErrors(
            field: FieldValidationRecursive
        ): boolean {
            if ((field as IFieldValidation).isValid === undefined) {
                let subFieldHasErrors = false;
                Object.keys(field).forEach((fName) => {
                    if (this.hasFieldValidationResultErrors(field[fName])) {
                        subFieldHasErrors = true;
                    }
                });
                return subFieldHasErrors;
            }
            return !(field as IFieldValidation).isValid;
        }
    };

export default formContainer;
