import urlTemplate from 'url-template';

import Route, { IRouteOptions } from './Route';

export default class ApiRoute extends Route {
    constructor(path: string, options?: IRouteOptions) {
        super();

        if (options) this.options = options;
        this.template = urlTemplate.parse(path);
    }
}
