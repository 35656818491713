import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { DEPARTMENT_PATH } from 'app/utils/constants';
import { IPerson } from 'app/redux/store/StoreNamespace';
import { useActions } from 'app/hooks/useActions';
import { useDescendantsAscendantsMaps } from 'app/modules/components/TreeSelect/List/useDescendantsAscendatsMaps';
import { useTypedSelector } from 'app/hooks/useTypedSelector';
import * as Store from 'app/redux/store/StoreNamespace';
import { scrollIntoView } from 'app/utils/scrollIntoView';

import { createFilterOptions } from '../../../../helpers/createFilterOptions';
import { getBirthDateView, getFullAddress } from '../../helpers';
import DepartmentHierarchy from '../DepartmentHierarchy';
import UserDataItem from '../UserDataItem';
import styles from '../../PersonCard.scss';

interface IProps {
    user: IPerson;
    isOpened: boolean;
    onPersonClick: (person: Store.IPerson, isManager?: boolean) => void;
}

const UserData = ({ user, isOpened, onPersonClick }: IProps) => {
    const { content, id } = user;
    const {
        function: contentFunction,
        companyName,
        department,
        departmentPath,
        birthDate,
        workplaceNum,
        companyFact,
        userAddress,
        manager,
    } = content;

    const [isOpen, setIsOpen] = useState(isOpened);
    const {
        fetchAvatar,
        fetchSearchResults,
        logEvent,
        setQuery,
        setQueryExpansionMode,
        setSearchHints,
        searchEmployeesWithFilterValue,
    } = useActions();
    const state = useTypedSelector((store) => store);

    const onLoad = (userId: string) => {
        fetchAvatar({ userId, resolution: '100', needCache: true });
    };

    const logDetailsClick = (person: IPerson) => {
        logEvent('userDetailsClickEvent', [person]);
    };

    const filtersOptions = useMemo(() => createFilterOptions(state), [state]);
    const { descendantsMap } = useDescendantsAscendantsMaps(
        filtersOptions['department-path']
    );

    const onSelect = (filterName: string, value: string | string[]): void => {
        scrollIntoView();
        setQuery('');
        setQueryExpansionMode(false);
        setSearchHints({ persons: [], queries: [] });
        searchEmployeesWithFilterValue(filterName, value);
        fetchSearchResults();
    };

    const onClickDepartment = (departmentId: number) => {
        const childrens = descendantsMap[departmentId]
            ? descendantsMap[departmentId]
                  .map((a) => a.value)
                  .concat([`${departmentId}`])
            : `${departmentId}`;
        onSelect(DEPARTMENT_PATH, childrens);
    };

    useEffect(() => {
        if (id && content) {
            onLoad(id);
            if (isOpened) {
                logDetailsClick(user);
            }
        }
    }, [isOpened, user]);

    const onMoreClick = () => {
        setIsOpen(!isOpen);

        if (!isOpen) {
            logDetailsClick(user);
        }
    };

    const hasAdditionalInfo =
        userAddress ||
        workplaceNum ||
        companyFact ||
        birthDate ||
        manager?.fullTitle ||
        department?.namePath.length > 1;

    return (
        <>
            <dl className={styles.personCardUserData}>
                <UserDataItem itemKey="Функция">
                    {contentFunction && (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                        <span
                            className={styles.personCardBtnBlue}
                            onClick={() =>
                                onSelect('function', contentFunction)
                            }
                        >
                            {contentFunction}
                        </span>
                    )}
                </UserDataItem>
                <UserDataItem itemKey="Организация">
                    {companyName && (
                        <span
                            className={styles.personCardBtnBlue}
                            onClick={() => onSelect('companyName', companyName)}
                        >
                            {companyName}
                        </span>
                    )}
                </UserDataItem>
                <UserDataItem
                    itemKey="Подразделение"
                    className={styles.personCardUserDataDepartment}
                >
                    {department && (
                        <DepartmentHierarchy
                            departmentPath={departmentPath}
                            department={department}
                            isOpened={isOpen}
                            descendantsMap={descendantsMap}
                            onClickDepartment={onClickDepartment}
                        />
                    )}
                </UserDataItem>
                {isOpen && (
                    <>
                        <UserDataItem itemKey="Адрес работы">
                            {getFullAddress(userAddress, workplaceNum)}
                        </UserDataItem>
                        <UserDataItem itemKey="Фактическое место работы">
                            {companyFact}
                        </UserDataItem>
                        <UserDataItem itemKey="Дата рождения">
                            {birthDate && getBirthDateView(birthDate)}
                        </UserDataItem>
                        <UserDataItem itemKey="ФИО руководителя">
                            {manager && (
                                <span
                                    className={styles.personCardBtnBlue}
                                    onClick={() =>
                                        onPersonClick(
                                            {
                                                content: manager,
                                                id: String(manager.userId),
                                            },
                                            true
                                        )
                                    }
                                >
                                    {manager.fullTitle}
                                </span>
                            )}
                        </UserDataItem>
                    </>
                )}
            </dl>
            {hasAdditionalInfo && (
                <button
                    className={classNames('btn', styles.personCardOpenBtn)}
                    onClick={onMoreClick}
                >
                    {isOpen ? 'Свернуть' : 'Подробнее'}
                </button>
            )}
        </>
    );
};

export default UserData;
