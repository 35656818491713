import React, { useMemo } from 'react';
import moment from 'moment';
import classNames from 'classnames';

import IProps from './interfaces/INotificationProps';
import * as styles from './Notification.scss';

const Notification: React.FC<IProps> = ({ notification, className }) => {
    const dateTime = useMemo(
        () => moment(notification.created).format('DD MMMM YYYY HH:mm'),
        [notification.created]
    );

    return (
        <div
            className={classNames(styles.notification, className, {
                [styles.notificationUnread]: !notification.isRead,
            })}
        >
            <h2 className={styles.notificationHeader}>
                <i
                    className={classNames(
                        styles.notificationBullet,
                        'nkc-icon-bullet'
                    )}
                />
                <span
                    title={notification.theme}
                    className={styles.notificationTheme}
                >
                    {notification.theme}
                </span>
            </h2>
            <div className={classNames(styles.notificationText, 'mde-preview')}>
                <div
                    className="mde-preview-content"
                    dangerouslySetInnerHTML={{ __html: notification.content }}
                />
            </div>
            <div className={styles.notificationTime}>{dateTime}</div>
        </div>
    );
};

export default Notification;
