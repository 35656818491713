/**
 * Created by lkarmelo on 02.12.2019.
 */

import {
    catalogueRulesReject,
    catalogueRulesRequest,
    catalogueRulesResolve,
} from 'app/redux/actions/loading';
import Api from 'app/api/Api';

import {
    fetchCatalogueCategoryRules,
    setCatalogueCategoryRules,
    ISetCatalogueCategoryRulesPayload,
} from '../../actions/catalogue/catalogueCategories';
import { createSimpleLoadingEpic } from '../utils';

export const loadCatalogueCategoriesRules = createSimpleLoadingEpic({
    triggers: [fetchCatalogueCategoryRules.toString()],
    apiCallName: 'catalogueCategorizationRules',
    actions: {
        requestAction: catalogueRulesRequest,
        resolveAction: catalogueRulesResolve,
        rejectAction: catalogueRulesReject,
        setAction: setCatalogueCategoryRules,
    },
    retryOnAfterAuth: true,
    fetchAction: fetchCatalogueCategoryRules,
    transformResponse(
        response: Api.ICategorizationRulesResponse
    ): ISetCatalogueCategoryRulesPayload {
        const [categoryId, rules] = Object.entries(
            response.categoryCodeToRules
        )[0];
        return { categoryId, rules };
    },
});
