/**
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 23.01.2018
 * -----
 * Last Modified: 08.06.2018 17:58:59
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import * as Store from '../store/StoreNamespace';

export const highlightFunction = (query, highlightPreTag, highlightPostTag) => {
    // escape regexp special characters in search string
    const searchStr = query.replace(/[-/\\^$*+?.()|[\]{}]/gi, '\\$&');

    const searchRegExp = new RegExp(searchStr, 'gi');
    const negSearchRegExp = new RegExp(
        `(?:${highlightPreTag}.*?)(${searchStr})(?:${highlightPostTag})`,
        'gi'
    );
    const replaceStr = (str) => `${highlightPreTag}${str}${highlightPostTag}`;

    return (sentence) => {
        searchRegExp.lastIndex = 0;
        negSearchRegExp.lastIndex = 0;

        let lastMatch = negSearchRegExp.exec(sentence);
        return sentence.replace(searchRegExp, (str, offset) => {
            if (!!lastMatch && negSearchRegExp.lastIndex < offset) {
                lastMatch = negSearchRegExp.exec(sentence);
            }

            return !lastMatch || lastMatch.index > offset
                ? replaceStr(str)
                : str;
        });
    };
};

const unHighlightQuery = (
    query: string,
    highlightPreTag: string,
    highlightPostTag: string
): string => {
    // escape regexp special characters in search string
    const negSearchRegExp = new RegExp(
        `(?:${highlightPreTag}.*?)(.*?)(?:${highlightPostTag})`,
        'gi'
    );

    return query.replace(negSearchRegExp, '$1');
};

export const hintsHighlightPreTag = '<span class="highlight-suggestions">';
export const hintsHighlightPostTag = '</span>';

export const unHighlightHints = (query: string): string =>
    unHighlightQuery(query, hintsHighlightPreTag, hintsHighlightPostTag);

export const highlightQueryInHint = (
    searchQuery: string,
    hint: string,
    position: Store.IQueryPosition
): string => {
    if (typeof position !== 'object') {
        return hint;
    }
    const { start, length } = position;
    const foundQuery = searchQuery.slice(start, start + length);
    return highlightFunction(
        foundQuery,
        hintsHighlightPreTag,
        hintsHighlightPostTag
    )(hint);
};

export const searchMarkedPreTag = '<span class="mark-suggestions">';
export const searchMarkedPostTag = '</span>';

export const highlightQueryInSearch = (
    searchQuery: string,
    position: Store.IQueryPosition
): string => {
    if (typeof position !== 'object') {
        return searchQuery;
    }
    const { start, length } = position;
    const searchBeforeQuery = searchQuery.slice(0, start);
    const query = searchQuery.slice(start, start + length);
    const searchAfterQuery = searchQuery.slice(
        start + length,
        searchQuery.length
    );
    return `${searchBeforeQuery}${searchMarkedPreTag}${query}${searchMarkedPostTag}${searchAfterQuery}`;
};
