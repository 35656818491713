/**
 * Created by lkarmelo on 13.11.2019.
 */
import { createSimpleLoadingEpic } from './utils';

import {
    redirectInfoResolve,
    redirectInfoReject,
    redirectInfoRequest,
} from '../actions/loading';
import {
    fetchRedirectInfo,
    setRedirectInfo,
} from '../actions/outsideRedirectInfo';

export const loadRedirectionInfo = createSimpleLoadingEpic({
    apiCallName: 'documentOriginalLink',
    triggers: [fetchRedirectInfo.toString()],
    actions: {
        requestAction: redirectInfoRequest,
        resolveAction: redirectInfoResolve,
        rejectAction: redirectInfoReject,
        setAction: setRedirectInfo,
    },
});
