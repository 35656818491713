/**
 * Created by Lkarmelo on 05.06.2018.
 */

import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';

import {
    initForm,
    changeField,
    removeForm,
    sendForm,
    addSubField,
    removeSubField,
    setFormStatus,
} from 'app/redux/actions/forms';
import { fetchRecommendations } from 'app/redux/actions/recommendations';
import {
    organizationsInfoToOptions,
    IOrganizationsInfoAsOptions,
} from 'app/redux/selectors/organizationsInfoToOptions';
import { employeesToOptions } from 'app/redux/selectors/employeesToOptions';
import {
    IDocumentRecommendationStateProps as IStateProps,
    IDocumentRecommendationOwnProps as IOwnProps,
    IDocumentRecommendationActionProps as IActionProps,
} from 'app/components/Modals/legacy/DocumentRecommendation/interfaces/IDocumentRecommendationProps';
import { FormName } from 'app/api/FormName';
import * as Store from 'app/redux/store/StoreNamespace';
import { IOption } from 'app/components/common/controls/Option';
import Api from 'app/api/Api';
import { withContext } from 'app/redux/context/connectWithContext';

import IFormData, {
    IEditRecommendationFormData as IEditFormData,
    ICreateRecommendationFormData as ICreateFormData,
} from './interfaces/IDocumentRecommendationFormData';
import DocumentRecommendation from './DocumentRecommendation';

const getFormName = (ownProps: IOwnProps): FormName =>
    ownProps.isEditing
        ? FormName.EditRecommendation
        : FormName.RecommendDocument;

const getPostsAsOptionsBySubdivision = memoizeOne(
    (
        posts: Store.ISimpleCatalogItem[],
        subDivisionsToPosts: Store.ISubDivisionsToPostsMap,
        activeSubDivisions: string[]
    ): IOption[] => {
        if (!posts || !subDivisionsToPosts || !activeSubDivisions) {
            return [];
        }
        const allowedPostsIds = [];
        activeSubDivisions.forEach((subDiv) => {
            allowedPostsIds.push(...subDivisionsToPosts[subDiv]);
        });

        return posts
            .filter((post) => allowedPostsIds.indexOf(post.id) >= 0)
            .map((post) => ({ value: post.id, label: post.title }));
    }
);

const getUsersAlreadyRecommendedList = memoizeOne(
    (
        response: Api.ICreateRecommendationResponse,
        alreadyLearnt: Store.IUser[]
    ): Store.IUser[] =>
        response.declinedAssignments.declinedByCollisions &&
        response.declinedAssignments.declinedByCollisions
            .map((d) => d.profile)
            .filter(
                (u) => alreadyLearnt.findIndex((uL) => uL.id === u.id) === -1
            )
);

const excludeUserFromAllEmployees = memoizeOne(
    (employees: IOption[], user: Store.IUser): IOption[] =>
        employees.filter((opt) => opt.value !== user.id)
);

const mapStateToProps = (
    state: Store.IState,
    ownProps: IOwnProps
): IOwnProps & IStateProps => {
    const form = state.forms[getFormName(ownProps)];
    const formData: IFormData = form ? <IFormData>form.data : <IFormData>{};
    const status = form && form.status;
    const organisationInfo: IOrganizationsInfoAsOptions =
        organizationsInfoToOptions({ state, metaMap: undefined });

    const response: Api.ICreateRecommendationResponse = form && form.response;
    const usersAlreadyLearnt =
        response &&
        response.declinedAssignments &&
        response.declinedAssignments.declinedByDocumentBeenStudied;
    const usersAlreadyRecommended =
        response &&
        response.declinedAssignments &&
        getUsersAlreadyRecommendedList(response, usersAlreadyLearnt);
    let numberOfUsersDeclined = 0;
    usersAlreadyLearnt && (numberOfUsersDeclined += usersAlreadyLearnt.length);
    usersAlreadyRecommended &&
        (numberOfUsersDeclined += usersAlreadyRecommended.length);

    return {
        status,
        employees: (<ICreateFormData>formData).employees,
        subdivisions: (<ICreateFormData>formData).subdivisions,
        posts: (<ICreateFormData>formData).posts,
        isRequired: formData.required,
        learnBefore: formData.finishDate,
        allEmployees: excludeUserFromAllEmployees(
            employeesToOptions(state),
            state.user
        ),
        allPosts:
            ownProps.isVisible && !ownProps.isEditing
                ? getPostsAsOptionsBySubdivision(
                      state.organizationsInfo.posts,
                      state.orgStructure.subDivisionsToPosts,
                      (<ICreateFormData>formData).subdivisions
                  )
                : undefined,
        allSubdivisions: organisationInfo.subdivisions,
        isEditing: ownProps.isEditing,
        documentId: ownProps.documentId,
        isVisible: ownProps.isVisible,
        onCancel: ownProps.onCancel,
        editingForEmployeeName: ownProps.editingForEmployeeName,
        editingForDocumentTitle: ownProps.editingForDocumentTitle,
        editingLearnBefore: ownProps.editingLearnBefore,
        editingIsRequired: ownProps.editingIsRequired,
        usersAlreadyLearnt,
        usersAlreadyRecommended,
        numberOfUsersDeclined,
        numberOfUsersSent:
            response &&
            Array.isArray(response.receivers) &&
            response.receivers.length,
    };
};

const mapDispatchToProps = (dispatch, ownProps: IOwnProps): IActionProps => ({
    onShown(): void {
        ownProps.isEditing
            ? dispatch(
                  initForm(getFormName(ownProps), <IEditFormData>{
                      required: ownProps.editingIsRequired,
                      finishDate:
                          ownProps.editingLearnBefore !== undefined
                              ? ownProps.editingLearnBefore
                              : new Date().valueOf(),
                      recommendationId: ownProps.editingRecommendationId,
                      profileId: ownProps.editingForProfileId,
                  })
              )
            : dispatch(
                  initForm(getFormName(ownProps), <ICreateFormData>{
                      employees: [],
                      subdivisions: [],
                      posts: [],
                      required: false,
                      finishDate: new Date().valueOf(),
                  })
              );
    },
    onHidden(): void {
        dispatch(removeForm(getFormName(ownProps)));
    },
    onSend(data: IFormData): void {
        dispatch(sendForm(getFormName(ownProps), data));
    },
    onSentSuccess(): void {
        if (ownProps.isEditing) {
            dispatch(
                withContext(
                    fetchRecommendations(),
                    Store.RecommendationsContext
                )
            );
        }
    },
    onSelect(fieldName: string, value: string): void {
        dispatch(addSubField(getFormName(ownProps), fieldName, value, null));
    },
    onDeSelect(fieldName: string, value: string): void {
        dispatch(removeSubField(getFormName(ownProps), fieldName, null, value));
    },
    setPosts(posts: string[]): void {
        dispatch(changeField(getFormName(ownProps), 'posts', posts));
    },
    onChangeRequired(isRequired: boolean): void {
        dispatch(changeField(getFormName(ownProps), 'required', isRequired));
    },
    onLearnBeforeDateChange(value: number): void {
        dispatch(changeField(getFormName(ownProps), 'finishDate', value));
    },
    onValidationFail() {
        dispatch(
            setFormStatus(getFormName(ownProps), Store.FormStatus.Invalid)
        );
    },
    onValidationSuccess() {
        dispatch(
            setFormStatus(getFormName(ownProps), Store.FormStatus.Editing)
        );
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentRecommendation);
