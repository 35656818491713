/**
 * Created by Lkarmelo on 05.09.2017
 * -----
 * Last Modified: 07.05.2018 12:24:24
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import { handleActions, Action, combineActions } from 'redux-actions';
import merge from 'deepmerge';

import { documentTransformFileLink } from 'app/utils/document/documentTransformFileLink';

import {
    setObjectCard,
    objectCardTablesActions,
    setObjectCardLinksGrouped,
    IObjectCardTableSortingPayload,
    resetObjectCard,
    addParentsToConceptHierarchyTree,
    IAddParentsToConceptHierarchyTree,
    addChildrenToConceptHierarchyTree,
    IAddChildrenToConceptHierarchyTree,
} from '../actions/objectCard';
import {
    ISetEducationMaterialStatusPayload,
    setEducationMaterialStatus,
} from '../actions/education';
import { updateSearchResult } from '../actions/search/results';
import * as Store from '../store/StoreNamespace';
import initialState from '../store/initialState';
import Api from '../../api/Api';

const getNextCardTableSorting = (
    field: string | string[],
    order: Store.SortingOrder
) => {
    const nextSorting = {};
    if (Array.isArray(field)) {
        field.forEach((f) => {
            nextSorting[f] = order;
        });
    } else {
        nextSorting[field] = order;
    }
    return nextSorting;
};

export default handleActions<Store.IObjectCard, any>(
    {
        [setObjectCard.toString()](
            state: Store.IObjectCard,
            { payload }: Action<Api.IObjectCardResponse>
        ) {
            if (!payload) {
                return state;
            }

            const card = documentTransformFileLink(payload);

            return {
                ...card,
                linksPaging: state.linksPaging,
                groupedPaging: state.groupedPaging,
                linksSorting: state.linksSorting,
                groupedSorting: state.groupedSorting,
                concepts: card.relatedConcepts,
            };
        },
        [resetObjectCard.toString()]() {
            return initialState.objectCard;
        },
        [updateSearchResult.toString()](
            state: Store.IObjectCard,
            { payload }: Action<Store.IUpdateDocumentSnippet>
        ) {
            if (payload.id === state.id) {
                return merge(state, payload, { clone: false });
            }

            return state;
        },
        [setEducationMaterialStatus.toString()](
            state: Store.IObjectCard,
            { payload }: Action<ISetEducationMaterialStatusPayload>
        ) {
            if (!state || state.id !== payload.id) {
                return state;
            }

            const nextState = { ...state };
            const nextStateMeta = { ...state.meta };
            nextState.meta = nextStateMeta;

            nextStateMeta.status = payload.status;

            return nextState;
        },
        [setObjectCardLinksGrouped.toString()](
            state: Store.IObjectCard,
            { payload }: Action<Api.ILinkedResponseBody>
        ) {
            return {
                ...state,
                grouped: payload.grouped,
                links: payload.links,
            };
        },
        [objectCardTablesActions.grouped.setLimit.toString()](
            state: Store.IObjectCard,
            { payload }: Action<number>
        ): Store.IObjectCard {
            const nextPaging = { ...state.groupedPaging, limit: payload };
            return {
                ...state,
                groupedPaging: nextPaging,
            };
        },
        [objectCardTablesActions.links.setLimit.toString()](
            state: Store.IObjectCard,
            { payload }: Action<number>
        ): Store.IObjectCard {
            const nextPaging = { ...state.linksPaging, limit: payload };
            return {
                ...state,
                linksPaging: nextPaging,
            };
        },
        [objectCardTablesActions.grouped.setSkip.toString()](
            state: Store.IObjectCard,
            { payload }: Action<number>
        ): Store.IObjectCard {
            const nextPaging = { ...state.groupedPaging, skip: payload };
            return {
                ...state,
                groupedPaging: nextPaging,
            };
        },
        [objectCardTablesActions.links.setSkip.toString()](
            state: Store.IObjectCard,
            { payload }: Action<number>
        ): Store.IObjectCard {
            const nextPaging = { ...state.linksPaging, skip: payload };
            return {
                ...state,
                linksPaging: nextPaging,
            };
        },
        [objectCardTablesActions.grouped.setSorting.toString()](
            state: Store.IObjectCard,
            { payload }: Action<IObjectCardTableSortingPayload>
        ): Store.IObjectCard {
            const nextSorting = getNextCardTableSorting(
                payload.field,
                payload.order
            );
            return {
                ...state,
                groupedSorting: nextSorting,
            };
        },
        [objectCardTablesActions.links.setSorting.toString()](
            state: Store.IObjectCard,
            { payload }: Action<IObjectCardTableSortingPayload>
        ): Store.IObjectCard {
            const nextSorting = getNextCardTableSorting(
                payload.field,
                payload.order
            );
            return {
                ...state,
                linksSorting: nextSorting,
            };
        },
        [combineActions(
            addParentsToConceptHierarchyTree,
            addChildrenToConceptHierarchyTree
        ).toString()](
            state: Store.IObjectCard,
            {
                payload,
                type,
            }: Action<
                | IAddParentsToConceptHierarchyTree
                | IAddChildrenToConceptHierarchyTree
            >
        ): Store.IObjectCard {
            const nextState = { ...state };
            const { originalConcept } = payload;

            const nextRelatedConceptTreeObj = nextState
                .conceptsHierarchyTrees?.[originalConcept.uri]
                ? { ...nextState.conceptsHierarchyTrees[originalConcept.uri] }
                : { concepts: {}, topConceptList: [] };

            nextState.conceptsHierarchyTrees = {
                ...nextState.conceptsHierarchyTrees,
                [originalConcept.uri]: nextRelatedConceptTreeObj,
            };

            nextRelatedConceptTreeObj.concepts = {
                ...nextRelatedConceptTreeObj.concepts,
                ...payload.concepts,
            };

            nextRelatedConceptTreeObj.concepts[originalConcept.uri] = {
                ...nextRelatedConceptTreeObj.concepts[originalConcept.uri],
                ...originalConcept,
            };

            if (type === addChildrenToConceptHierarchyTree.toString()) {
                const parentConcept =
                    nextRelatedConceptTreeObj.concepts[originalConcept.uri];
                parentConcept.children = Array.from(
                    new Set([
                        ...(parentConcept.children || []),
                        ...Object.keys(payload.concepts),
                    ])
                );
            } else {
                nextRelatedConceptTreeObj.topConceptList = [
                    (<IAddParentsToConceptHierarchyTree>payload).topConceptUri,
                ];
            }

            return nextState;
        },
    },
    initialState.objectCard // - default value for objectCard
);
