/**
 * Created by lkarmelo on 21.08.2018.
 */

import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import autoBind from 'autobind-decorator';
import memoizeOne from 'memoize-one';
import classNames from 'classnames';
import Icon from 'antd/es/icon';
import { ColumnProps } from 'antd/es/table/interface';

import Pagination from 'app/components/common/tables/Pagination';
import ScrollToTop from 'app/components/common/utils/ScrollToTop';
import {
    TimePeriod,
    TreeSelectOld,
    DropDown,
} from 'app/components/common/tables/Filters';
import {
    DocumentRecommendation,
    DeleteRecommendation,
} from 'app/components/Modals';
import { getUserNameByUserObject } from 'app/utils/getUserNameByUserObject';
import { DATE_DOTS_FORMAT, FULL_DATE_TIME_FORMAT } from 'app/utils/constants';
import { getReadableStringFromDocumentStatus } from 'app/utils/document/getReadableStringFromDocumentStatus';
import clientRoutes from 'app/routing/clientRoutes';
import * as Store from 'app/redux/store/StoreNamespace';

import IState from './interfaces/IRecommendationsState';
import IProps from './interfaces/IRecommendationsProps';
import ITableRecord, {
    RecommendationsColumnName as ColumnName,
} from './interfaces/ITableRecord';
import RecommendationsTableBody from './RecommendationsTableBody';
import * as styles from './Recommendations.scss';

const dateRenderer = (date: DateTimeString): string =>
    date && moment(date, FULL_DATE_TIME_FORMAT).format(DATE_DOTS_FORMAT);

const RecommendationsColumns: ColumnProps<ITableRecord>[] = [
    {
        title: 'Документ',
        dataIndex: 'documentId',
        key: ColumnName.title,
        width: '25%',
        render: (documentId: string, row: ITableRecord): JSX.Element => (
            <Link
                target="_blank"
                to={clientRoutes.objectCard.getUrl({ id: documentId })}
            >
                {row.title}
            </Link>
        ),
    },
    {
        title: 'Кем рекомендован',
        dataIndex: ColumnName.author,
        key: ColumnName.author,
    },
    {
        title: 'Когда рекомендован',
        dataIndex: ColumnName.createdDate,
        key: ColumnName.createdDate,
        render: dateRenderer,
    },
    {
        title: 'Кому рекомендован',
        dataIndex: ColumnName.receiver,
        key: ColumnName.receiver,
    },
    {
        title: 'Обязателен к изучению',
        dataIndex: ColumnName.isNecessary,
        key: ColumnName.isNecessary,
        render: (isNecessary: boolean): string => (isNecessary ? 'Да' : 'Нет'),
    },
    {
        title: 'Изучить до',
        dataIndex: ColumnName.planDate,
        key: ColumnName.planDate,
        render: dateRenderer,
    },
    {
        title: 'Дата изучения',
        dataIndex: ColumnName.finishDate,
        key: ColumnName.finishDate,
        render: dateRenderer,
    },
    {
        title: 'Статус',
        dataIndex: ColumnName.status,
        key: ColumnName.status,
        render: (
            status: Store.DocumentEducationStatus,
            row: ITableRecord
        ): string =>
            row.isRefused
                ? 'Отклонено'
                : status && getReadableStringFromDocumentStatus(status),
    },
    {
        title: <Icon type="filter" />,
        key: ColumnName.actions,
        render(data: undefined, row: ITableRecord): JSX.Element {
            return (
                <div className={styles.recommendationsActions}>
                    <button
                        className={`btn ${styles.recommendationsEditBtn}`}
                        onClick={() => row.openEditRecommendation(row)}
                        title="Редактировать"
                    />
                    <button
                        className={`btn ${styles.recommendationsDeleteBtn}`}
                        onClick={() => row.openDeleteRecommendation(row)}
                        title="Удалить"
                    />
                </div>
            );
        },
        sorter: false,
    },
];

@autoBind
class Recommendations extends Component<IProps, IState> {
    static defaultProps: Partial<IProps> = {
        recommendations: [],
    };

    static statusFilterOptions = [
        {
            value: Store.EducationMaterialStatusValue.Planning,
            label: getReadableStringFromDocumentStatus(
                Store.EducationMaterialStatusValue.Planning
            ),
        },
        {
            value: Store.EducationMaterialStatusValue.Active,
            label: getReadableStringFromDocumentStatus(
                Store.EducationMaterialStatusValue.Active
            ),
        },
        {
            value: Store.EducationMaterialStatusValue.Done,
            label: getReadableStringFromDocumentStatus(
                Store.EducationMaterialStatusValue.Done
            ),
        },
        {
            value: 'refused',
            label: 'Отклонено',
        },
    ];

    static necessityFilterOptions = [
        { value: 'true', label: 'Да' },
        { value: 'false', label: 'Нет' },
    ];

    state: IState = {
        isFilterRowVisible: false,
        isDeleteRecommendationModalShown: false,
        isEditRecommendationModalShown: false,
        selectedRecommendationId: undefined,
        selectedReceiverProfileId: undefined,
        selectedReceiverName: undefined,
        selectedDocumentTitle: undefined,
        selectedPlanDate: undefined,
        selectedIsNecessary: undefined,
    };

    getDataSource = memoizeOne(
        (
            userRecommendationDetails: Store.IUserRecommendationDetail[]
        ): ITableRecord[] =>
            userRecommendationDetails.map(
                (userRec: Store.IUserRecommendationDetail): ITableRecord => {
                    const { author } = userRec.recommendation;
                    return {
                        key: `${userRec.recommendationId}_${userRec.profileId}`,
                        recommendationId: userRec.recommendationId,
                        title: userRec.recommendation.document.title,
                        documentId: userRec.recommendation.document.id,
                        author: author && getUserNameByUserObject(author),
                        createdDate: userRec.recommendation.created,
                        receiver: getUserNameByUserObject(userRec.profile),
                        receiverProfileId: userRec.profileId,
                        isNecessary: !!userRec.planDate,
                        planDate: userRec.planDate,
                        isRefused: userRec.refused,
                        status: userRec.documentStatus,
                        finishDate:
                            userRec.documentStatus ===
                            Store.EducationMaterialStatusValue.Done
                                ? userRec.learningStatusChanged
                                : undefined,
                        openEditRecommendation: this.openEditRecommendation,
                        openDeleteRecommendation: this.openDeleteRecommendation,
                    };
                }
            )
    );

    render(): JSX.Element {
        const { recommendations, skip, limit, count, setPageSize } = this.props;
        const {
            isEditRecommendationModalShown,
            isDeleteRecommendationModalShown,
            selectedRecommendationId,
            selectedReceiverName,
            selectedIsNecessary,
            selectedPlanDate,
            selectedReceiverProfileId,
            selectedDocumentTitle,
            isFilterRowVisible,
        } = this.state;
        const dataSource: ITableRecord[] = this.getDataSource(recommendations);
        const currentPage = Math.floor(skip / limit) + 1;

        return (
            <div
                className={classNames(styles.recommendations, {
                    [styles.recommendationsFiltersHidden]: !isFilterRowVisible,
                    [styles.recommendationsFiltersApplied]:
                        this.isAnyFilterApplied(),
                })}
            >
                <DocumentRecommendation
                    onCancel={this.hideEditRecommendationModal}
                    isVisible={isEditRecommendationModalShown}
                    documentId=""
                    isEditing
                    editingRecommendationId={selectedRecommendationId}
                    editingForDocumentTitle={selectedDocumentTitle}
                    editingForProfileId={selectedReceiverProfileId}
                    editingForEmployeeName={selectedReceiverName}
                    editingIsRequired={selectedIsNecessary}
                    editingLearnBefore={selectedPlanDate}
                />
                <DeleteRecommendation
                    isVisible={isDeleteRecommendationModalShown}
                    recommendationId={selectedRecommendationId}
                    profileId={selectedReceiverProfileId}
                    onCancel={this.hideDeleteRecommendationModal}
                    documentTitle={selectedDocumentTitle}
                    userName={selectedReceiverName}
                    renderBody={this.renderDeleteRecommendationModalBody}
                />
                <div className="ant-table">
                    <div className="ant-table-content">
                        <div className="ant-table-body">
                            <table>
                                {this.renderColGroup()}
                                <thead className="ant-table-thead">
                                    {this.renderHeaderColumnTitles()}
                                    {this.renderHeaderFilters()}
                                </thead>
                                <RecommendationsTableBody
                                    dataSource={dataSource}
                                    columns={RecommendationsColumns}
                                />
                            </table>
                        </div>
                        {count > 0 ? (
                            <div className="ant-table-footer">
                                <Pagination
                                    current={currentPage}
                                    count={count}
                                    limit={limit}
                                    onPageChange={this.onPageChange}
                                    onLimitChange={setPageSize}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
                <ScrollToTop bottom={80} />
            </div>
        );
    }

    renderColGroup(): JSX.Element {
        return (
            <colgroup>
                {RecommendationsColumns.map((col) => (
                    <col key={col.key} width={col.width} />
                ))}
            </colgroup>
        );
    }

    renderHeaderColumnTitles(): JSX.Element {
        const { sortColumn, sortOrder, resetSorting, setSorting } = this.props;
        return (
            <tr>
                {RecommendationsColumns.map((col, index) => {
                    const key: string = col.key as string;
                    const isAscActive =
                        key === sortColumn && sortOrder === 'asc';
                    const isDescActive =
                        key === sortColumn && sortOrder === 'desc';
                    return (
                        <th key={key}>
                            <span
                                onClick={
                                    index === RecommendationsColumns.length - 1
                                        ? this.toggleFilterRowVisibility
                                        : undefined
                                }
                            >
                                {col.title}
                                {col.sorter !== false && (
                                    <div className="ant-table-column-sorter">
                                        <span
                                            className={classNames(
                                                'ant-table-column-sorter-up',
                                                {
                                                    off: !isAscActive,
                                                    on: isAscActive,
                                                }
                                            )}
                                            title="↑"
                                            onClick={
                                                isAscActive
                                                    ? resetSorting
                                                    : () =>
                                                          setSorting(key, 'asc')
                                            }
                                        >
                                            <i className="anticon anticon-caret-up" />
                                        </span>
                                        <span
                                            className={classNames(
                                                'ant-table-column-sorter-down',
                                                {
                                                    off: !isDescActive,
                                                    on: isDescActive,
                                                }
                                            )}
                                            title="↓"
                                            onClick={
                                                isDescActive
                                                    ? resetSorting
                                                    : () =>
                                                          setSorting(
                                                              key,
                                                              'desc'
                                                          )
                                            }
                                        >
                                            <i className="anticon anticon-caret-down" />
                                        </span>
                                    </div>
                                )}
                            </span>
                        </th>
                    );
                })}
            </tr>
        );
    }

    renderHeaderFilters(): JSX.Element {
        const {
            setDateRangeFrom,
            setDateRangeTo,
            setDateRangePeriod,
            selectMultiValueFilter,
            deselectMultiValueFilter,
            deselectAll,
            selectFilter,
            deselectFilter,
            setTextFilter,
        } = this.props;
        return (
            <tr className={styles.recommendationsFiltersRow}>
                <th className={styles.recommendationsFilterContainerText}>
                    <input
                        type="text"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setTextFilter(
                                ColumnName.title,
                                e.currentTarget.value
                            )
                        }
                        value={this.props[ColumnName.title] || ''}
                    />
                </th>
                <th className={styles.recommendationsFilterContainerText}>
                    <input
                        type="text"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setTextFilter(
                                ColumnName.author,
                                e.currentTarget.value
                            )
                        }
                        value={this.props[ColumnName.author] || ''}
                    />
                </th>
                <th>
                    <TimePeriod
                        filterName={ColumnName.createdDate}
                        startDate={
                            this.props[ColumnName.createdDate] &&
                            this.props[ColumnName.createdDate].from
                        }
                        endDate={
                            this.props[ColumnName.createdDate] &&
                            this.props[ColumnName.createdDate].to
                        }
                        onStartDateChange={setDateRangeFrom}
                        onEndDateChange={setDateRangeTo}
                        onPeriodChange={setDateRangePeriod}
                    />
                </th>
                <th className={styles.recommendationsFilterContainerText}>
                    <input
                        type="text"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setTextFilter(
                                ColumnName.receiver,
                                e.currentTarget.value
                            )
                        }
                        value={this.props[ColumnName.receiver] || ''}
                    />
                </th>
                <th>
                    <DropDown
                        filterName={ColumnName.isNecessary}
                        options={Recommendations.necessityFilterOptions}
                        active={this.props[ColumnName.isNecessary]}
                        placeholder="Обязательность"
                        onSelectOption={selectFilter}
                        onDeselectOption={deselectFilter}
                        isClearable
                    />
                </th>
                <th>
                    <TimePeriod
                        filterName={ColumnName.planDate}
                        startDate={
                            this.props[ColumnName.planDate] &&
                            this.props[ColumnName.planDate].from
                        }
                        endDate={
                            this.props[ColumnName.planDate] &&
                            this.props[ColumnName.planDate].to
                        }
                        onStartDateChange={setDateRangeFrom}
                        onEndDateChange={setDateRangeTo}
                        onPeriodChange={setDateRangePeriod}
                    />
                </th>
                <th>
                    <TimePeriod
                        filterName={ColumnName.finishDate}
                        startDate={
                            this.props[ColumnName.finishDate] &&
                            this.props[ColumnName.finishDate].from
                        }
                        endDate={
                            this.props[ColumnName.finishDate] &&
                            this.props[ColumnName.finishDate].to
                        }
                        onStartDateChange={setDateRangeFrom}
                        onEndDateChange={setDateRangeTo}
                        onPeriodChange={setDateRangePeriod}
                    />
                </th>
                <th className={styles.recommendationsFilterContainerStatus}>
                    <TreeSelectOld
                        options={Recommendations.statusFilterOptions}
                        filterName={ColumnName.status}
                        active={this.props[ColumnName.status]}
                        placeholder="Статус"
                        onSelectOption={selectMultiValueFilter}
                        onDeselectOption={deselectMultiValueFilter}
                        onDeselectAllOptions={deselectAll}
                        isMultiSelect
                    />
                </th>
                <th />
            </tr>
        );
    }

    renderDeleteRecommendationModalBody({
        documentTitle,
        userName,
    }: {
        documentTitle: string;
        userName: string;
    }): JSX.Element {
        return (
            <div className={styles.recommendationsDeleteModalBody}>
                <h2>Вы уверены, что хотите удалить рекомендацию?</h2>
                <div className={styles.recommendationsDeleteModalFieldTitle}>
                    Документа
                </div>
                <div className={styles.recommendationsDeleteModalField}>
                    {documentTitle}
                </div>
                <div className={styles.recommendationsDeleteModalFieldTitle}>
                    Сотруднику
                </div>
                <div className={styles.recommendationsDeleteModalField}>
                    {userName}
                </div>
            </div>
        );
    }

    componentDidMount(): void {
        this.props.fetchRecommendations();
    }

    componentWillUnmount(): void {
        this.props.resetFilters();
    }

    onPageChange(nextPage: number): void {
        const { limit, setSkip } = this.props;
        setSkip((nextPage - 1) * limit);
    }

    openEditRecommendation(row: ITableRecord): void {
        this.setState({
            isEditRecommendationModalShown: true,
            selectedRecommendationId: row.recommendationId,
            selectedReceiverProfileId: row.receiverProfileId,
            selectedReceiverName: row.receiver,
            selectedDocumentTitle: row.title,
            selectedPlanDate:
                row.planDate !== undefined
                    ? moment(row.planDate, FULL_DATE_TIME_FORMAT).valueOf()
                    : undefined,
            selectedIsNecessary: row.isNecessary,
        });
    }

    openDeleteRecommendation(row: ITableRecord): void {
        this.setState({
            isDeleteRecommendationModalShown: true,
            selectedRecommendationId: row.recommendationId,
            selectedReceiverProfileId: row.receiverProfileId,
            selectedReceiverName: row.receiver,
            selectedDocumentTitle: row.title,
        });
    }

    hideEditRecommendationModal(): void {
        this.setState({ isEditRecommendationModalShown: false });
    }

    hideDeleteRecommendationModal(): void {
        this.setState({ isDeleteRecommendationModalShown: false });
    }

    toggleFilterRowVisibility(): void {
        this.setState((state) => ({
            isFilterRowVisible: !state.isFilterRowVisible,
        }));
    }

    isAnyFilterApplied(): boolean {
        const isAnySimpleFilterApplied = [
            this.props[ColumnName.title],
            this.props[ColumnName.author],
            this.props[ColumnName.receiver],
            this.props[ColumnName.isNecessary],
        ].some((filterValue: string) => {
            return !!filterValue;
        });

        const isAnyDateFilterApplied = [
            this.props[ColumnName.createdDate],
            this.props[ColumnName.planDate],
            this.props[ColumnName.finishDate],
        ].some((filterValue: Store.IDateFilterValue) => {
            // приходится добавить проверку на null перед typeof, потому что typeof null === 'object' - true
            // разделил на 2 if для читаемости
            if (filterValue === undefined || filterValue === null) {
                return false;
            }
            const hasValue =
                typeof filterValue === 'object' &&
                (filterValue.from !== undefined ||
                    filterValue.to !== undefined);

            return hasValue;
        });

        const isAnyMultiValueFilterApplied = [
            this.props[ColumnName.status],
        ].some((filterValue: string[]) => {
            return Array.isArray(filterValue) && filterValue.length > 0;
        });

        return (
            isAnyDateFilterApplied ||
            isAnySimpleFilterApplied ||
            isAnyMultiValueFilterApplied
        );
    }
}

export default Recommendations;
