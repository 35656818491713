import { createAction } from 'redux-actions';

import * as Store from '../store/StoreNamespace';

export const fetchPersonViewWidgetAvatar = createAction(
    'FETCH_PERSON_VIEW_WIDGET_AVATAR'
);
export const setPersonViewWidgetAvatar = createAction<Store.IPersonAvatar>(
    'SET_PERSON_VIEW_WIDGET_AVATAR'
);
