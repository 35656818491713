import { Action } from 'redux-actions';
import { MiddlewareAPI } from 'redux';
import { Observable } from 'rxjs/Observable';
import { concat } from 'rxjs/observable/concat';
import { merge } from 'rxjs/observable/merge';

import {
    compressRequestUpdateLocationAndSearch,
    executeSearch,
    IExecuteSearchPayload,
    setSearchResults,
    addToSearchResults,
} from 'app/redux/actions/search/results';
import {
    setExecutingSearchQuery,
    setLastExecutedSearchQuery,
} from 'app/redux/actions/search/searchQuery';
import {
    searchReject,
    searchRequest,
    searchResolve,
} from 'app/redux/actions/loading';
import { unblockPaging } from 'app/redux/actions/search/searchPaging';
import { setLastExecutedIgnoreMistakes } from 'app/redux/actions/search/ignoreMistakes';
import { setLastExecutedSearchAlias } from 'app/redux/actions/search/searchAlias';
import { setTagsFromQuery } from 'app/redux/actions/search/tagsFromQuery';
import { setExtractedConcepts as setExtractedConceptsAction } from 'app/redux/actions/search/concepts';
import * as Store from 'app/redux/store/StoreNamespace';
import Api from 'app/api/Api';
import { setSearchActiveTabForResults } from 'app/redux/actions/search/searchTabs';
import searchTabConfigSelector from 'app/redux/selectors/searchTabConfig';

import { getOfTypeLoadOnLocationChange } from './loadSearchResultsOnLocationChange';

import { setLastExecutedFilters } from '../../actions/search/filters';
import { createRetryOnIEAuthProblem } from '../utils/createRetryOnIEAuthProblem';
import { setStrictForPersons } from '../../actions/search/strict';

import IDocumentSearchResponseBody = Api.IDocumentSearchResponseBody;

export const executeSearchRequest = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(executeSearch.toString())
        .hasContext(false)
        .mergeMap(({ payload }: Action<IExecuteSearchPayload>) => {
            const {
                requestBody,
                alias,
                clearQuery,
                filters,
                shouldAddToSearchResults,
                setPersonStrictFromResponse,
                setExtractedConcepts,
                setInitialSelectedFromExtractedConcepts,
            } = payload;

            const state = store.getState();
            const searchTabConfig = searchTabConfigSelector(state);

            // Метод апи меняется в зависимости от текущей вкладки
            // На вкладке может быть поиск документов либо поиск пользователей
            const { apiCallName, getSearchResultsPayload } = searchTabConfig;

            return concat(
                Observable.of(
                    searchRequest(),
                    unblockPaging(),
                    setExecutingSearchQuery(clearQuery)
                ),
                apiCall[apiCallName as any](requestBody)
                    .retryWhen(createRetryOnIEAuthProblem())
                    .map(({ response }) =>
                        Object.assign(response, { modified: Date.now() })
                    )
                    .mergeMap(
                        (
                            searchResponse: Api.IGlobalSearchResponseBody &
                                Api.IPersonSearchResponseBody
                        ) => {
                            const searchResultsPayload =
                                getSearchResultsPayload(searchResponse);
                            const actions = [
                                searchResolve(),
                                shouldAddToSearchResults
                                    ? addToSearchResults(searchResultsPayload)
                                    : setSearchResults(searchResultsPayload),
                                setLastExecutedSearchQuery(clearQuery),
                                setTagsFromQuery(clearQuery),
                                setLastExecutedFilters(filters),
                                setLastExecutedIgnoreMistakes(
                                    requestBody.doc.ignoreMistakes
                                ),
                                setSearchActiveTabForResults(
                                    state.mainSearchPageActiveTab
                                ),
                            ];
                            if (alias) {
                                actions.push(setLastExecutedSearchAlias(alias));
                            }
                            if (
                                setPersonStrictFromResponse &&
                                searchResultsPayload.personResults
                            ) {
                                actions.push(
                                    setStrictForPersons(
                                        searchResultsPayload.personResults
                                            .strict
                                    )
                                );
                            }
                            if (setExtractedConcepts) {
                                actions.push(
                                    setExtractedConceptsAction({
                                        extractedConcepts: (
                                            searchResultsPayload.results as IDocumentSearchResponseBody
                                        )?.extractedConcepts,
                                        setInitialSelected:
                                            setInitialSelectedFromExtractedConcepts,
                                    })
                                );
                            }

                            return Observable.from(actions);
                        }
                    )
                    .catch((e) => {
                        console.error(e);
                        return Observable.of(searchReject(e));
                    })
                    .takeUntil(action$.ofType(searchReject.toString())),
                Observable.of(setExecutingSearchQuery(null))
            ).takeUntil(
                merge(
                    action$
                        .ofType(
                            executeSearch.toString(),
                            compressRequestUpdateLocationAndSearch.toString()
                        )
                        .hasContext(false),
                    getOfTypeLoadOnLocationChange(action$)
                )
            );
        });
