import { connect } from 'react-redux';

import * as Store from 'app/redux/store/StoreNamespace';
import { IServicesStateProps } from 'app/components/search/Services/interfaces/IServicesProps';

import Services from './Services';

const mapStateToProps = (state: Store.IState): IServicesStateProps => ({
    services: state.search.results?.extractedServices?.items,
});

export default connect(mapStateToProps)(Services);
