import React, {
    useRef,
    useLayoutEffect,
    useState,
    useCallback,
    useMemo,
} from 'react';
import Badge from 'antd/es/badge';
import Tooltip from 'antd/es/tooltip';
import classNames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router';

import DropDownMenu from 'app/components/common/DropDownMenu';
import clientRoutes from 'app/routing/clientRoutes';
import { useActions } from 'app/hooks/useActions';
import { useTypedSelector } from 'app/hooks/useTypedSelector';
import * as Store from 'app/redux/store/StoreNamespace';

import * as styles from './NotificationMenu.scss';
import DropdownContent from './components/DropdownContent/DropdownContent';

interface IProps extends RouteComponentProps<{}> {
    numberOfNotificationsToDisplay?: number;
}

const NotificationMenu: React.FC<IProps> = ({
    numberOfNotificationsToDisplay,
    history,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const [dropdownOpened, setDropdownOpened] = useState(false);

    const {
        requestNotificationsRead: markAsRead,
        fetchLatestNotificationsAndCount: loadNotifications,
    } = useActions();

    const {
        latestNotifications: notifications,
        newCount: newNotificationCount,
    } = useTypedSelector((state) => state[Store.NotificationContext]);

    const notificationsToRender = useMemo(
        () =>
            Array.isArray(notifications)
                ? notifications.slice(0, numberOfNotificationsToDisplay)
                : [],
        [notifications, numberOfNotificationsToDisplay]
    );

    const markNotificationsAsRead = useCallback(() => {
        const ids = notificationsToRender
            .filter(({ isRead }) => !isRead)
            .map(({ id }) => id);

        markAsRead(ids);
    }, [notificationsToRender, markAsRead]);

    const onCloseClick = useCallback(() => {
        setDropdownOpened(false);
        markNotificationsAsRead();
    }, [markNotificationsAsRead]);

    const onOpenClick = useCallback(() => {
        setDropdownOpened(true);
        loadNotifications(numberOfNotificationsToDisplay);
    }, [loadNotifications, numberOfNotificationsToDisplay]);

    useLayoutEffect(() => {
        if (newNotificationCount > 0) {
            const element = document.querySelector(
                '.notification-menu .ant-badge-count'
            );
            element && element.setAttribute('title', '');
        }
    }, [newNotificationCount]);

    const onMenuClick = useCallback(
        () => history.push(clientRoutes.notifications.getUrl()),
        [history]
    );

    return (
        <div className={styles.notificationMenu} ref={ref}>
            <DropDownMenu
                className={styles.notificationMenuList}
                toggleElement={
                    <Tooltip
                        title="Уведомления"
                        getPopupContainer={() => ref.current}
                    >
                        <Badge
                            dot={newNotificationCount > 0}
                            offset={[5, -5]}
                            style={{ backgroundColor: '#008EFA' }}
                        >
                            <button
                                className={classNames(
                                    'btn',
                                    'nkc-icon-bell',
                                    styles.notificationMenuToggle,
                                    {
                                        [styles.notificationMenuToggleEnabled]:
                                            dropdownOpened,
                                    }
                                )}
                            />
                        </Badge>
                    </Tooltip>
                }
                dropDownContentRenderer={() => (
                    <DropdownContent
                        onMenuClick={onMenuClick}
                        notificationsToRender={notificationsToRender}
                    />
                )}
                onClose={onCloseClick}
                onOpen={onOpenClick}
            />
        </div>
    );
};

NotificationMenu.defaultProps = {
    numberOfNotificationsToDisplay: 3,
};

export default withRouter(NotificationMenu);
