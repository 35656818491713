/**
 * Created by Lkarmelo on 24.09.2018.
 */

import { connect } from 'react-redux';
import moment from 'moment';

import {
    initForm,
    removeForm,
    sendForm,
    changeField,
    changeSubField,
    removeSubField,
    addSubField,
    setFormStatus,
} from 'app/redux/actions/forms';
import { fetchCompetencies } from 'app/redux/actions/competencies';
import { fetchEducationMaterialTypes } from 'app/redux/actions/educationMaterialTypes';
import { fetchObjectCard, setObjectCard } from 'app/redux/actions/objectCard';
import { objectCardReject } from 'app/redux/actions/loading';
import { knowledgeCategoriesToOptions } from 'app/redux/selectors/knowledgeCategoriesToOptions';
import { simpleCatalogItemsToOptions } from 'app/redux/selectors/simpleCatalogItemsToOptions';
import { FormName } from 'app/api/FormName';
import {
    IEditDocumentStateProps as IStateProps,
    IEditDocumentActionProps as IActionProps,
} from 'app/components/document-management/EditDocument/interfaces/IEditDocumentProps';
import * as Store from 'app/redux/store/StoreNamespace';

import IFormData from './interfaces/IEditDocumentFormData';
import EditDocument from './EditDocument';

const mapStateToProps = () => {
    const allDocumentClasses = [
        { label: 'Обучающий материал', value: '1241453141415215' },
    ];

    return (state: Store.IState): IStateProps => {
        const formData: IFormData =
            <IFormData>(
                (state.forms[FormName.EditDocument] &&
                    state.forms[FormName.EditDocument].data)
            ) || <IFormData>{};
        const formStatus =
            state.forms[FormName.EditDocument] &&
            state.forms[FormName.EditDocument].status;
        return {
            card: state.objectCard,
            isLoading:
                state.loading.pendingRequests.objectCard ||
                state.loading.pendingRequests[FormName.EditDocument],
            status: formStatus,
            documentClass: formData.documentClass,
            type: formData.type,
            title: formData.title,
            author: formData.author,
            org: formData.org,
            source: formData.source,
            date: formData.date,
            description: formData.description,
            category: formData.category,
            competencies: formData.competencies,
            allCategories: knowledgeCategoriesToOptions(
                state.catalogueCategories
            ),
            allDocumentClasses,
            allDocumentTypes: simpleCatalogItemsToOptions(
                state.educationMaterialTypes,
                'code'
            ),
            allCompetencies: state.competencies,
        };
    };
};

const mapDispatchToProps = (dispatch): IActionProps => ({
    onMount() {
        dispatch(fetchCompetencies());
        dispatch(fetchEducationMaterialTypes());
    },
    onUnmount() {
        dispatch(removeForm(FormName.EditDocument));
        dispatch(objectCardReject());
        dispatch(setObjectCard(null));
    },
    loadObjectCard(id: string) {
        dispatch(objectCardReject());
        dispatch(fetchObjectCard(id));
    },
    fillForm(card: Store.IObjectCard) {
        dispatch(
            initForm(FormName.EditDocument, <IFormData>{
                documentId: card.id,
                documentClass: '1241453141415215',
                type: card.meta.subType,
                title: card.meta.title,
                author:
                    card.meta.persons &&
                    card.meta.persons.map((p) => p.fullName).join(', '),
                source: card.meta.link,
                // FIXME: убрать захардкоженную дату
                date: moment(`25.09.${card.meta.year}`, 'DD.MM.YYYY').valueOf(),
                org:
                    card.meta.orgs &&
                    card.meta.orgs.map((o) => o.title).join(' ,'),
                description:
                    card.annotation &&
                    card.annotation.map((a) => a.sentence).join('. '),
                category: card.categories
                    ? card.categories.map((c) => c.id)
                    : [],
                competencies: card.meta.competenceRequirements
                    ? card.meta.competenceRequirements.slice()
                    : [],
            })
        );
    },
    onValidationFail() {
        dispatch(
            setFormStatus(FormName.EditDocument, Store.FormStatus.Invalid)
        );
    },
    onValidationSuccess() {
        dispatch(
            setFormStatus(FormName.EditDocument, Store.FormStatus.Editing)
        );
    },
    onSend() {
        dispatch(sendForm(FormName.EditDocument, null));
    },
    onChangeField(fieldName: string, value: string) {
        dispatch(changeField(FormName.EditDocument, fieldName, value));
    },
    onSelect(fieldName: string, value: string) {
        dispatch(addSubField(FormName.EditDocument, fieldName, value, null));
    },
    onDeSelect(fieldName: string, value: string) {
        dispatch(removeSubField(FormName.EditDocument, fieldName, null, value));
    },
    onAddCompetence(
        fieldName: string,
        competence: Store.ISimpleCatalogItem
    ): void {
        dispatch(
            addSubField(
                FormName.EditDocument,
                fieldName,
                { competenceItem: competence, grade: 0 },
                null
            )
        );
    },
    onRemoveCompetence(fieldName: string, index: number): void {
        dispatch(
            removeSubField(
                FormName.EditDocument,
                fieldName,
                index.toString(),
                null
            )
        );
    },
    onChangeCompetence(
        fieldName: string,
        index: number,
        competence: Store.ISimpleCatalogItem
    ): void {
        const nextValue: Store.IUserCompetence = { competenceItem: competence };
        nextValue.grade = 0;
        dispatch(
            changeSubField(
                FormName.EditDocument,
                fieldName,
                index.toString(),
                nextValue
            )
        );
    },
    onChangeCompetenceGrade(
        fieldName: string,
        index: number,
        competence: Store.IUserCompetence,
        grade: number
    ): void {
        const nextValue: Store.IUserCompetence = { ...competence };
        nextValue.grade = grade;
        dispatch(
            changeSubField(
                FormName.EditDocument,
                fieldName,
                index.toString(),
                nextValue
            )
        );
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDocument);
