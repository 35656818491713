/**
 * Created by lkarmelo on 08.07.2019.
 */

import { connect } from 'react-redux';

import * as Store from 'app/redux/store/StoreNamespace';
import {
    setLastExecutedSearchQuery,
    setQuery,
} from 'app/redux/actions/search/searchQuery';
import {
    clearCorrectedQuery,
    compressRequestUpdateLocationAndSearch,
    fetchSearchResults,
} from 'app/redux/actions/search/results';
import {
    IQueryCorrectionStateProps as IStateProps,
    IQueryCorrectionActionProps as IActionProps,
} from 'app/components/search/QueryCorrection/interfaces/IQueryCorrectionProps';
import getSearchTarget from 'app/utils/getSearchTarget';

import QueryCorrection from './QueryCorrection';

const mapStateToProps = (state: Store.IState): IStateProps => {
    const { search } = state;
    const searchTarget = getSearchTarget(
        state.mainSearchPageActiveTabForResults,
        state
    );
    return {
        originalSearchQuery: search.lastExecutedSearchQuery,
        lastIgnoreMistakes: search.lastExecutedIgnoreMistakes,
        correctedSearchQuery:
            searchTarget === Store.SearchTarget.employee
                ? search.personResults?.correctedQuery?.replacement.value
                : search.results?.correctedQuery?.replacement.value,
    };
};

const mapDispatchToProps = (dispatch): IActionProps => ({
    searchOriginalQuery(originalQuery: string): void {
        dispatch(setQuery(originalQuery));
        dispatch(fetchSearchResults(true));
    },
    correctSearchQuery(correctedSearchQuery: string): void {
        dispatch(setQuery(correctedSearchQuery));
        dispatch(setLastExecutedSearchQuery(correctedSearchQuery));
        dispatch(clearCorrectedQuery());
        dispatch(
            compressRequestUpdateLocationAndSearch({
                isNewSearch: true,
                skipSearchRequest: true,
            })
        );
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(QueryCorrection);
