import { connect } from 'react-redux';

import * as Store from 'app/redux/store/StoreNamespace';
import { setSearchActiveTab } from 'app/redux/actions/search/searchTabs';
import { logEvent } from 'app/redux/actions/logEvents';

import PersonsWidget from './PersonsWidget';
import {
    IPersonsResultsWidgetStateProps,
    IPersonsResultsWidgetActionProps,
    IPersonsResultsWidgetOwnProps,
} from './interfaces/IPersonsWidgetProps';

const mapStateToProps = (
    state: Store.IState
): IPersonsResultsWidgetStateProps => ({
    persons: state.search.personResults?.items,
});

const mapDispatchToProps = (
    dispatch,
    ownProps: IPersonsResultsWidgetOwnProps
): IPersonsResultsWidgetActionProps => ({
    setActiveTab(tabCode: string): void {
        dispatch(
            setSearchActiveTab({
                tabCode,
                preserveIgnoreMistakesFromLastSearch: true,
                preservePersonStrict: true,
            })
        );
    },
    onMailToClick(person: Store.IPerson): void {
        dispatch(logEvent('userMailToClickEvent', [person]));
    },
    onPersonClick(person: Store.IPerson) {
        dispatch(logEvent('employeeTabFromWidgetClickEvent', [person]));
        ownProps.onPersonClick(person);
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonsWidget);
