/**
 * Created by lkarmelo on 31.01.2020.
 */
import { createAction } from 'redux-actions';

import Api from 'app/api/Api';

export type LogEvent = keyof Api.ApiCalls['log'];
export type LogEventParams<Event extends LogEvent> = Parameters<
    Api.ApiCalls['log'][Event]
>;

export type ILogEventPayload<Event extends LogEvent> = {
    event: Event;
    params: LogEventParams<Event>;
};

/**
 * Экшн для логирования событий. Все события - это запросы из apiCalls.log
 */
export const logEvent = createAction(
    'LOG_EVENT',
    <Event extends LogEvent>(
        event: Event,
        params: LogEventParams<Event>
    ): ILogEventPayload<Event> => ({
        event,
        params,
    })
);
