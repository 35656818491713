import { connect } from 'react-redux';

import * as Store from 'app/redux/store/StoreNamespace';

import ConceptsTab, {
    IConceptsTabStateProps,
    IConceptsTabDispatchProps,
} from './ConceptsTab';

import {
    fetchChildrenConcepts,
    fetchParentConcepts,
} from '../../../../../redux/actions/objectCard';
import { fetchResourceView } from '../../../../../redux/actions/conceptDescriptions';

const mapStateToProps = (state: Store.IState): IConceptsTabStateProps => ({
    conceptDescriptions: state.conceptDescriptions,
});

const mapDispatchToProps = (dispatch): IConceptsTabDispatchProps => ({
    loadConceptParents(concept: Store.ISchemeConcept) {
        dispatch(fetchParentConcepts({ concept }));
    },
    loadConceptChildren(concept: Store.ISchemeConcept) {
        dispatch(fetchChildrenConcepts({ concept }));
    },
    loadConceptInfo(conceptUri: string, schemeUri: string) {
        dispatch(fetchResourceView({ conceptUri, schemeUri }));
    },
    loadConceptRelated(conceptUri: string, schemeUri: string) {},
});

export default connect(mapStateToProps, mapDispatchToProps)(ConceptsTab);
