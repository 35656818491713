/**
 * Created by Lkarmelo on 23.08.2017.
 */

import { Observable } from 'rxjs/Observable';
import { concat } from 'rxjs/observable/concat';
import { MiddlewareAPI } from 'redux';
import { merge } from 'rxjs/observable/merge';
import { AjaxResponse } from 'rxjs/observable/dom/AjaxObservable';
import { combineLatest } from 'rxjs/observable/combineLatest';

import { replaceDotsWithDashes } from 'app/utils/filters/filterNamesEscape';
import Api from 'app/api/Api';

import { createSimpleLoadingEpic, retryOnAuthorizedEpic } from './utils';

import {
    fetchFavoritesFiltersMeta,
    fetchFiltersMeta,
    setFavoritesFiltersMeta,
    setFiltersMeta,
} from '../actions/search/filtersMeta';
import { setDefaultFilters } from '../actions/search/defaultFilters';
import {
    favoritesFiltersMetaReject,
    favoritesFiltersMetaRequest,
    favoritesFiltersMetaResolve,
    filtersMetaReject,
    filtersMetaRequest,
    filtersMetaResolve,
} from '../actions/loading';
import * as Store from '../store/StoreNamespace';

const trim = (text: string): string =>
    !!text && text.replace(/^"?(.*?)"?$/g, '$1');

const convertType = (item: any): Store.FilterType | undefined => {
    const { type } = item;
    switch (type) {
        case Store.ServerFilterType.SelectFilter: {
            return Store.FilterType.MultiSelect;
        }
        case Store.ServerFilterType.RangeFilter:
            return Store.FilterType.YearRange;
        default:
            return undefined;
    }
};

export const copyFiltersMeta = (
    item: Api.IFilterDescription & { isParam: boolean },
    index: number
): { [key: string]: Store.IFilterMeta } => {
    const filterName = replaceDotsWithDashes(trim(item.code));

    const filter: Store.IFilterMeta = {
        title: trim(item.name),
        type: convertType(item),
        hierarchical: item.hierarchical,
        serverType: item.type,
        isParam: item.isParam,
        isFromServer: true,
        hidden: item.hidden,
        order: item.order,
        filterName,
        alwaysVisible: item.alwaysVisible,
        searchTargets: item.searchTargets,
    };

    if (item.isMultiSelect) {
        const filterValues = (item.value as Api.IFilterValueList)?.values;

        const processedValues = Array.isArray(filterValues)
            ? filterValues.map(({ DisplayValue }) => ({
                  label: DisplayValue.displayValue || '',
                  value: DisplayValue.code,
                  parent: DisplayValue.parent,
              }))
            : [];

        Object.assign(filter, {
            values: processedValues,
            default: [],
        });
    } else if (
        item.value &&
        (item.value as Api.IFilterValueRange).from !== undefined
    ) {
        const filterRangeValues = item.value as Api.IFilterValueRange;

        Object.assign(filter, {
            from: filterRangeValues.from,
            to: filterRangeValues.to,
            default: {},
        });
    }

    return { [filterName]: filter };
};

export const loadFiltersMeta = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }
) =>
    merge(
        retryOnAuthorizedEpic(
            action$,
            filtersMetaReject.toString(),
            fetchFiltersMeta
        ),
        action$
            .ofType(fetchFiltersMeta.toString())
            .hasContext(false)
            .mergeMap(() =>
                combineLatest(
                    apiCall
                        .filters()
                        .map((r: AjaxResponse) => r.response)
                        .catch((e) => {
                            console.error(e);
                            return Observable.of(e);
                        }),
                    apiCall
                        .params()
                        .map((r: AjaxResponse) =>
                            r.response.map((param) => ({
                                ...param,
                                isParam: true,
                            }))
                        )
                        .catch((e) => {
                            console.error(e);
                            return Observable.of(e);
                        })
                )
                    .mergeMap((responses: {}[]) => {
                        let hasErrors = false;
                        const errors: Error[] = [];
                        const filterMap = { ...Store.staticFilters };

                        responses.forEach((res) => {
                            if (res instanceof Error) {
                                hasErrors = true;
                                errors.push(res);
                            } else {
                                Object.assign(
                                    filterMap,
                                    ...(res as []).map(copyFiltersMeta)
                                );
                            }
                        });

                        return concat(
                            Observable.of(setFiltersMeta(filterMap)),
                            Observable.of(setDefaultFilters(filterMap)),
                            hasErrors
                                ? concat(
                                      ...errors.map((e) =>
                                          Observable.of(filtersMetaReject(e))
                                      )
                                  )
                                : Observable.of(filtersMetaResolve())
                        );
                    })
                    .catch((e) => {
                        console.error(e);
                        return Observable.of(filtersMetaReject(e));
                    })
                    .takeUntil(action$.ofType(filtersMetaReject.toString()))
                    .startWith(filtersMetaRequest())
            )
    );

export const loadFavoritesFiltersMeta = createSimpleLoadingEpic({
    triggers: [fetchFavoritesFiltersMeta.toString()],
    apiCallName: 'favoritesFilters',
    actions: {
        requestAction: favoritesFiltersMetaRequest,
        resolveAction: favoritesFiltersMetaResolve,
        rejectAction: favoritesFiltersMetaReject,
        setAction: setFavoritesFiltersMeta,
    },
});
