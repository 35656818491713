/**
 * Created by lkarmelo on 26.11.2019.
 */

import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useOnOutsideClick } from '@nkc-frontend/nkc-react-hooks';

import {
    List,
    IOptionProps,
    IOptionGroupProps,
} from 'app/modules/components/TreeSelect';

import IProps from './interfaces/IHierarchicalSelectProps';
import * as styles from './HierarchicalSelect.scss';

const OptionGroup: React.FC<
    IOptionGroupProps & Pick<IProps, 'getIsOptionDisabled'>
> = (props) => {
    const {
        option,
        children,
        onSelect,
        onDeselect,
        active,
        getIsOptionDisabled,
    } = props;

    const [isOpened, setIsOpen] = useState(false);

    const toggleSelect = useCallback(() => {
        active ? onDeselect(option) : onSelect(option);
    }, [active, onSelect, onDeselect]);

    return (
        <div
            className={classNames(styles.hierarchicalSelectOptionGroup, {
                [styles.hierarchicalSelectOptionGroupActive]: active,
                [styles.hierarchicalSelectOptionGroupOpened]: isOpened,
            })}
        >
            <div className={styles.hierarchicalSelectOptionGroupBody}>
                <button
                    disabled={
                        typeof getIsOptionDisabled === 'function'
                            ? getIsOptionDisabled(option)
                            : false
                    }
                    type="button"
                    className={`btn ${styles.hierarchicalSelectOptionGroupSelectBtn}`}
                    onClick={toggleSelect}
                >
                    {option.label}
                </button>
                <button
                    type="button"
                    className={`btn ${styles.hierarchicalSelectOptionGroupArrowBtn}`}
                    onClick={() => setIsOpen((state) => !state)}
                />
            </div>
            {isOpened && (
                <div className={styles.hierarchicalSelectOptionGroupChildren}>
                    {typeof children === 'function'
                        ? children(props)
                        : children}
                </div>
            )}
        </div>
    );
};

const Option: React.FC<IOptionProps & Pick<IProps, 'getIsOptionDisabled'>> = (
    props
) => {
    const { option, onSelect, onDeselect, active, getIsOptionDisabled } = props;

    const toggleSelect = useCallback(() => {
        active ? onDeselect(option) : onSelect(option);
    }, [active, onSelect, onDeselect]);

    return (
        <div
            className={classNames(styles.hierarchicalSelectOption, {
                [styles.hierarchicalSelectOptionActive]: active,
            })}
        >
            <div className={styles.hierarchicalSelectOptionBody}>
                <button
                    disabled={
                        typeof getIsOptionDisabled === 'function'
                            ? getIsOptionDisabled(option)
                            : false
                    }
                    type="button"
                    className={`btn ${styles.hierarchicalSelectOptionSelectBtn}`}
                    onClick={toggleSelect}
                >
                    {option.label}
                </button>
            </div>
        </div>
    );
};

const HierarchicalSelect: React.FC<IProps> = (props) => {
    const {
        active,
        options,
        placeholder,
        onSelect,
        onDeselect,
        getIsOptionDisabled,
    } = props;
    const [isOpened, setIsOpened] = useState(false);

    const onMouseDown = useOnOutsideClick(() => setIsOpened(false));

    const optionProps = useMemo(
        () => ({ getIsOptionDisabled }),
        [getIsOptionDisabled]
    );

    return (
        <div
            onMouseDown={onMouseDown}
            className={classNames(styles.hierarchicalSelect, {
                [styles.hierarchicalSelectOpened]: isOpened,
            })}
        >
            <button
                type="button"
                className={`btn ${styles.hierarchicalSelectToggle}`}
                onClick={() => setIsOpened((state) => !state)}
                title={(active && active.label) || placeholder}
            >
                {(active && active.label) || placeholder}
            </button>
            <div className={styles.hierarchicalSelectListContainer}>
                <List
                    active={active ? [active.value] : []}
                    options={options}
                    onSelect={onSelect}
                    onDeselect={onDeselect}
                    OptionGroupComponent={OptionGroup}
                    optionGroupComponentProps={optionProps}
                    OptionComponent={Option}
                    optionComponentProps={optionProps}
                />
            </div>
        </div>
    );
};

export default HierarchicalSelect;
