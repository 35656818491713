/**
 * Created by Lkarmelo on 25.08.2017.
 */

import React, { useCallback } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

import withApproveDeleteDocumentModal from 'app/components/high-order/withApproveDeleteDocumentModal';
import { UIFeaturesName } from 'app/components/common/utils/FeatureRenderer';
import { selectedExpansionTagRawSelector } from 'app/redux/selectors/selectedExpansionTags';
import clientRoutes from 'app/routing/clientRoutes';
import DocumentSnippet, {
    IDocumentSnippetProps,
} from 'app/components/search-snippets/DocumentSnippet';
import Feedback from 'app/components/search/Feedback';
import { useTypedSelector } from 'app/hooks/useTypedSelector';
import { IDocumentSnippet } from 'app/redux/store/StoreNamespace';

import SearchResultMessageRow from './components/SearchResultMessageRow';
import useSearchResults from './hooks/useSearchResults';
import * as styles from './SearchResult.scss';
import IProps from './interfaces/ISearchResultDocumentsProps';
import SearchResults from './SearchResult';

import SkeletonSnippet from '../../search-snippets/DocumentSnippet/Snippet/SkeletonSnippet';

const SKELETON_ARRAY_LENGTH = 3;

const SearchResultDocuments: React.FunctionComponent<IProps> = (props) => {
    const {
        onApproveDeleteDocumentClick,
        isLinkToDocumentWithHighlight,
        focusSearchQueryInput,
    } = props;

    const {
        lastExecutedSearchQuery,
        lastExecutedIgnoreMistakes: lastIgnoreMistakes,
        results,
    } = useTypedSelector((store) => store.search);
    const { list, count, askFeedback } = results;
    const { search: isLoading } = useTypedSelector(
        (store) => store.loading.pendingRequests
    );
    const { value: correctedSearchQuery } =
        useTypedSelector(
            (store) => store.search.results?.correctedQuery?.replacement
        ) || {};
    const selectedExpansions = useTypedSelector(
        selectedExpansionTagRawSelector
    );

    const {
        onLoadMoreResults,
        listWithFeedback,
        hasListWithFeedback,
        isButtonShown,
    } = useSearchResults<IDocumentSnippet>({ items: list, askFeedback, count });

    const getDocumentSnippetLink = useCallback(
        ({ document }: IDocumentSnippetProps): string => {
            const params = {
                expansion: selectedExpansions,
                query:
                    correctedSearchQuery && !lastIgnoreMistakes
                        ? correctedSearchQuery
                        : lastExecutedSearchQuery,
            };

            return clientRoutes.objectCard.getUrl({ id: document.id }, params);
        },
        [
            selectedExpansions,
            lastExecutedSearchQuery,
            correctedSearchQuery,
            lastIgnoreMistakes,
        ]
    );

    return (
        <SearchResults>
            <SearchResultMessageRow
                focusSearchQueryInput={focusSearchQueryInput}
                list={list}
            />
            {hasListWithFeedback && (
                <>
                    <div className={styles.searchResultList}>
                        {listWithFeedback.map((res) =>
                            typeof res === 'string' ? (
                                <Feedback
                                    className={
                                        styles.searchResultFeedbackDocument
                                    }
                                    key={res}
                                />
                            ) : (
                                <DocumentSnippet
                                    key={res.id}
                                    document={res}
                                    onApproveDeleteDocumentClick={
                                        onApproveDeleteDocumentClick
                                    }
                                    getDocumentLink={
                                        isLinkToDocumentWithHighlight
                                            ? getDocumentSnippetLink
                                            : undefined
                                    }
                                />
                            )
                        )}
                    </div>
                    {isLoading &&
                        !document.body.classList.contains(
                            'search--no-search-done'
                        ) &&
                        [...Array(SKELETON_ARRAY_LENGTH)].map((_, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <SkeletonSnippet key={index} />
                        ))}
                </>
            )}
            {isButtonShown && (
                <button
                    onClick={onLoadMoreResults}
                    className={classNames('btn', styles.searchResultMoreBtn)}
                >
                    Показать ещё
                </button>
            )}
        </SearchResults>
    );
};

SearchResultDocuments.defaultProps = {
    isLinkToDocumentWithHighlight:
        APP_FEATURES.ui[UIFeaturesName.LinkToObjectCardWithHighlightedContent],
};

export default withRouter(
    withApproveDeleteDocumentModal(SearchResultDocuments)
);
