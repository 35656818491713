/**
 * Created by lkarmelo on 20.03.2019.
 */

import deepEqual from 'deep-equal';

import * as Store from 'app/redux/store/StoreNamespace';

const compareMultiSelect = (valA: string[], valB: string[]): boolean => {
    if (!Array.isArray(valA) || !Array.isArray(valB)) {
        return Object.is(valA, valB);
    }
    if (valA === valB) {
        return true;
    }
    const sortedValA = valA.slice().sort();
    const sortedValB = valB.slice().sort();
    return deepEqual(sortedValA, sortedValB);
};

const compareYearRange = (
    valA: Store.IDateFilterValue,
    valB: Store.IDateFilterValue
): boolean => {
    if (typeof valA !== 'object' || typeof valB !== 'object') {
        return Object.is(valA, valB);
    }
    return Object.is(valA.from, valB.from) && Object.is(valA.to, valB.to);
};

export const compareFilterValues = (
    valA: Store.IFilterValue,
    valB: Store.IFilterValue,
    filterType: Store.FilterType
): boolean => {
    switch (filterType) {
        case Store.FilterType.Tags:
        case Store.FilterType.MultiSelect: {
            return compareMultiSelect(valA as string[], valB as string[]);
        }
        case Store.FilterType.DateRange:
        case Store.FilterType.YearRange: {
            return compareYearRange(
                valA as Store.IDateFilterValue,
                valB as Store.IDateFilterValue
            );
        }
        default:
            return Object.is(valA, valB);
    }
};
