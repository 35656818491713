/**
 * Created by Lkarmelo on 20.11.2017.
 */

import * as React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import Modal from 'antd/es/modal/Modal';

import * as Store from 'app/redux/store/StoreNamespace';

import IProps from './interfaces/IClarifySuggestionProps';
import IOwnProps from './interfaces/IClarifySuggestionOwnProps';

import './ClarifySuggestion.scss';

const clarifySuggestion = (props: IProps) => (
    <Modal
        visible={props.visible}
        footer={null}
        className="clarify-suggestion modal"
        onCancel={props.onCancelClick}
    >
        <h2 className="clarify-suggestion__title">
            Рекомендуем уточнить запрос
        </h2>
        <div className="clarify-suggestion__message">
            По вашему вопросу найдено{' '}
            {numeral(props.resultsCount).format('0,0').replace(/,/g, ' ')}{' '}
            записей. Рекомендуем уточнить запрос
        </div>
        <button autoFocus onClick={props.onOkClick} className="btn-primary">
            Уточнить запрос
        </button>
        <button
            onClick={props.onCancelClick}
            className="btn clarify-suggestion__cancel-btn"
        >
            Нет, спасибо
        </button>
    </Modal>
);

const mapStateToProps = (state: Store.IState, ownProps: IOwnProps) => ({
    resultsCount: state.search.results ? state.search.results.count : 0,
    visible: ownProps.visible,
    onOkClick: ownProps.onOkClick,
    onCancelClick: ownProps.onCancelClick,
});

export default connect(mapStateToProps)(clarifySuggestion);
