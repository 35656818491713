/**
 * Created by lkarmelo on 05.12.2019.
 */
import { createFormStatusHandlers } from '@nkc-frontend/nkc-react-hooks';

import * as Store from 'app/redux/store/StoreNamespace';

const { useFormSenderHelper } = createFormStatusHandlers(
    {
        onSuccess: Store.FormStatus.SendingSuccess,
        onFail: Store.FormStatus.SendingFail,
        onEditing: Store.FormStatus.Editing,
        onInvalid: Store.FormStatus.Invalid,
        onPending: Store.FormStatus.Pending,
    },
    { error: 'Произошла ошибка при отправке формы' }
);

export { useFormSenderHelper };
