/**
 * Created by Lkarmelo on 26.10.2017.
 */

import hashCode from 'string-hash';

const defaultColors = ['#2D9CDB', '#27AE60', '#977BB2', '#E0E0E0', '#DAADEA'];

const gpnColors = [
    '#F68F09',
    '#339FD0',
    '#BC0000',
    '#742055',
    '#F5680C',
    '#38B69F',
    '#3C4D9F',
];

const rffiColors = ['#DDDFE7'];

export const getColorFromHash = (id?: string) => {
    let colors = defaultColors;
    switch (process.env.INTERFACE_THEME) {
        case 'gpn':
            colors = gpnColors;
            break;
        case 'rfbr':
            colors = rffiColors;
            break;
        default:
            break;
    }

    if (!id) {
        return colors[Math.floor(Math.random() * colors.length)];
    }
    return colors[hashCode(id) % colors.length];
};
