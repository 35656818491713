import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import mime from 'mime';

import UploadBoxAction from 'app/components/common/files/UploadBoxAction/UploadBoxAction';
import UploadBox from 'app/components/common/files/UploadBox/UploadBox';
import * as Store from 'app/redux/store/StoreNamespace';
import {
    addFileError,
    IProgress,
    updateFileInfo,
    updateFileProgress,
    updateFileState,
} from 'app/redux/actions/files';
import { IUploadBoxActionDispatchProps } from 'app/components/common/files/UploadBoxAction/interfaces/IUploadBoxActionProps';

export interface IFileInfo {
    name: string;
    size: number;
    storageKey: string;
    updated: string;
    mimeType: string;
}

export const processFileInfoResponse = (
    fileInfo: IFileInfo[]
): Store.IFileMeta[] =>
    fileInfo.map(({ name, size, storageKey, mimeType }) => ({
        name,
        size,
        fid: storageKey,
        status: Store.FileStateEnum.Stored,
        mimeType: mimeType || mime.getType(name),
    }));

const mapDispatchToProps = (
    dispatch: Dispatch<any>
): IUploadBoxActionDispatchProps => ({
    ajaxProcess: processFileInfoResponse,
    onFileChange: (fileMeta: Store.IFileMeta) => {
        dispatch(updateFileInfo(fileMeta));
    },
    onProgress: (name: string, { loaded, total }: IProgress) => {
        dispatch(updateFileState(name, Store.FileStateEnum.Uploading));
        dispatch(updateFileProgress(name, { loaded, total }));
    },
    onComplete: (name: string) => {
        dispatch(updateFileState(name, Store.FileStateEnum.Uploaded));
    },
    onSuccess: (fileMeta: Store.IFileMeta[]) => {
        dispatch(updateFileInfo(fileMeta));
    },
    onFailed: (error: any, file: File) => {
        dispatch(updateFileState(file.name, Store.FileStateEnum.Error));
        dispatch(addFileError(file.name, { errorMsg: error.toString(), file }));
    },
});

export default connect(null, mapDispatchToProps)(UploadBoxAction(UploadBox));
