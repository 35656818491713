/**
 * Created by Lkarmelo on 04.12.2017.
 */

import * as React from 'react';
import { connect } from 'react-redux';
import autoBind from 'autobind-decorator';
import memoizeOne from 'memoize-one';

import AbstractEditablePanelContainer from 'app/components/common/forms/AbstractEditablePanelContainer';
import EditablePanel from 'app/components/common/forms/EditablePanel';
import Form from 'app/components/common/forms/Form';
import {
    addSubField,
    initForm,
    removeForm,
    removeSubField,
    sendForm,
} from 'app/redux/actions/forms';
import { setCurrentUserSkills } from 'app/redux/actions/currentUserSkills';
import { fetchSkills, createSkill } from 'app/redux/actions/skills';
import { skillsToOptions } from 'app/redux/selectors/skillsToOptions';
import { FormName } from 'app/api/FormName';
import * as Store from 'app/redux/store/StoreNamespace';

import IProps, {
    ISkillsOwnProps as IOwnProps,
    ISkillsStateProps as IStateProps,
    ISkillsDispatchProps as IDispatchProps,
} from './interfaces/ISkillsProps';
import IFormData from './interfaces/IFormData';

import PresentationalSkills from '../presentational/Skills';

@autoBind
class Skills extends AbstractEditablePanelContainer<IProps> {
    static defaultProps = {
        activeSkills: [],
    };

    getActiveSkillsForForm = memoizeOne(
        (
            editingActiveSkillsCodes: string[],
            activeSkills: Store.IUserSkill[]
        ) =>
            editingActiveSkillsCodes.map((code) => {
                const activeSkill = activeSkills.find(
                    (skill) => skill.skill.code === code
                );
                if (!activeSkill || !activeSkill.skill.archived) {
                    return code;
                }
                return {
                    label: activeSkill.skill.title,
                    value: activeSkill.skill.code,
                };
            })
    );

    render(): JSX.Element {
        const {
            formData,
            formStatus,
            availableSkills,
            onTruncateChange,
            isEditable,
            activeSkills,
        } = this.props;
        return (
            <EditablePanel
                isEditable={this.state.isEditable}
                title="НАВЫКИ"
                onStartEdit={this.startEdit}
                onCancelEdit={this.cancelEdit}
                onSave={this.save}
                hideEditBtn={!isEditable}
            >
                {this.state.isEditable ? (
                    <Form
                        className="skills-form"
                        status={formStatus}
                        onFormSaved={this.onFormSaveSuccess}
                        fields={{
                            skills: {
                                value: this.getActiveSkillsForForm(
                                    formData.editingActiveSkills,
                                    activeSkills
                                ),
                                type: 'multiselect-tags',
                                title: '',
                                options: availableSkills,
                                onCreateOption: this.createSkill,
                                onSelectOption: this.addActiveSkill,
                                onDeselectOption: this.removeActiveSkill,
                            },
                        }}
                    />
                ) : (
                    <PresentationalSkills
                        skills={this.props.activeSkills}
                        onTruncateChange={onTruncateChange}
                    />
                )}
            </EditablePanel>
        );
    }

    componentDidUpdate(): void {
        if (this.props.onUpdate) this.props.onUpdate(this.state.isEditable);
    }

    onStartEdit(): void {
        const { getSkills, initializeForm, activeSkills, onEditStart } =
            this.props;

        if (onEditStart) onEditStart();

        getSkills();

        initializeForm(activeSkills);
    }

    onCancelEdit(): void {
        this.props.deleteForm();
    }

    addActiveSkill(id: string): void {
        this.props.addActiveSkill(id);
    }

    removeActiveSkill(id: string): void {
        this.props.removeActiveSkill(id);
    }

    createSkill(title: string): void {
        this.props.createNewSkill(title);
    }

    onSave(): void {
        this.props.sendFormData(this.props.formData);
    }

    onFormSaveSuccess(): void {
        this.props.setUserSkills(this.props.formResponse);
        this.cancelEdit();
    }
}

const meta = { fieldName: 'editingActiveSkills' };

const formName = FormName.Skills;

const mapStateToProps = (
    state: Store.IState,
    props: IOwnProps
): IStateProps & IOwnProps => ({
    activeSkills: state.currentUserSkills,
    isEditable: props.isEditable,
    availableSkills: skillsToOptions({ state, meta }),
    formData:
        state.forms[formName] && (state.forms[formName].data as IFormData),
    formStatus: state.forms[formName] && state.forms[formName].status,
    formResponse: state.forms[formName] && state.forms[formName].response,
    onEditStart: props.onEditStart,
    onTruncateChange: props.onTruncateChange,
});

const mapDispatchToProps = (dispatch): IDispatchProps => ({
    getSkills() {
        dispatch(fetchSkills());
    },
    initializeForm(activeSkills: Store.IUserSkill[]) {
        dispatch(
            initForm(formName, {
                [meta.fieldName]: activeSkills.map((skill) => skill.skill.code),
            })
        );
    },
    deleteForm() {
        dispatch(removeForm(formName));
    },
    sendFormData(formData: IFormData) {
        dispatch(
            sendForm(
                formName,
                formData.editingActiveSkills.map((sk) => ({ skillCode: sk }))
            )
        );
    },
    addActiveSkill(id: string) {
        dispatch(addSubField(formName, meta.fieldName, id, null));
    },
    removeActiveSkill(id: string) {
        dispatch(removeSubField(formName, meta.fieldName, null, id));
    },
    createNewSkill(title: string) {
        dispatch(createSkill(title));
    },
    setUserSkills(skills: Store.IUserSkill[]) {
        dispatch(setCurrentUserSkills(skills));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Skills);
