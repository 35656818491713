/**
 * Created by Lkarmelo on 27.10.2017.
 */

import * as React from 'react';
import { Route } from 'react-router-dom';

const RouteWithLayout = ({
    layout: Layout,
    component: Component,
    subRoutes,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout>
                    {Component && <Component {...props}>{subRoutes}</Component>}
                </Layout>
            )}
        />
    );
};

export default RouteWithLayout;
