import { useLatest, usePrevious } from 'react-use';
import { useMemo } from 'react';

import { ISelectOption } from '..';

/**
 * Оптимизация для того, чтобы рендерить только то поддерево опций, в котором изменился active.
 *
 * Идея в том, чтобы составить для каждой опции список потомков и во время рендера проверять, изменился ли хотя бы один потомок
 * и если изменился, то эта опция должна ререндериться
 * @param activeMap
 * @param fullDescendantsMap
 */
export const useDescendantsOptimization = (
    activeMap: Record<string, boolean>,
    fullDescendantsMap: Record<string, ISelectOption[]>
) => {
    const prevActiveMap = usePrevious(activeMap);

    /** Получаем объект, в котором будут только value опций, у которых изменилось состояние active */
    const activeDiff = useMemo(() => {
        // здесь должны быть все отличия в значениях activeMap и prevActiveMap. Не важно, добавились ли они или убрались
        const diff: Record<string, boolean> = {};
        // сначала проверяем значения, которые есть в activeMap, но их нет в prevActiveMap (добавившиеся)
        Object.keys(activeMap).forEach((activeVal) => {
            if (!prevActiveMap || !prevActiveMap.hasOwnProperty(activeVal)) {
                diff[activeVal] = true;
            }
        });
        // теперь проверяем значения, которые есть в prevActiveMap, но их нет в activeMap (убранные)
        if (prevActiveMap)
            Object.keys(prevActiveMap).forEach((prevActiveVal) => {
                if (!activeMap.hasOwnProperty(prevActiveVal)) {
                    diff[prevActiveVal] = true;
                }
            });
        return diff;
    }, [activeMap, prevActiveMap]);
    const activeDiffRef = useLatest(activeDiff);

    /**
     * Ищем среди списка потомков опцию, у которой изменилось значение. если изменилось, значит, нужно ререндерить
     * поддерево, начиная от текущего компонента
     */
    const isAnyDescendantChanged = (option: ISelectOption) => {
        return (
            option &&
            option.value &&
            fullDescendantsMap[option.value] &&
            fullDescendantsMap[option.value].some(
                (descendant) => activeDiffRef.current[descendant.value]
            )
        );
    };

    return isAnyDescendantChanged;
};
