/**
 * Created by lkarmelo on 25.07.2019.
 */

import Api from 'app/api/Api';
import * as Store from 'app/redux/store/StoreNamespace';
import { YEAR_PERIOD_FILTER_MIN_YEAR } from 'app/utils/constants';

const checkAndFillEmptyYearInYearRangeFilter = (
    filterValues: Store.IDateFilterValue,
    filterMeta: Store.IFilterMeta
): Required<Store.IDateFilterValue> => {
    const defaultFrom =
        typeof filterMeta.from === 'number'
            ? filterMeta.from
            : YEAR_PERIOD_FILTER_MIN_YEAR;
    const defaultTo =
        typeof filterMeta.to === 'number'
            ? filterMeta.to
            : new Date().getFullYear();

    return {
        from:
            typeof filterValues.from === 'number'
                ? filterValues.from
                : defaultFrom,
        to: typeof filterValues.to === 'number' ? filterValues.to : defaultTo,
    };
};

export const getFilterValue = (
    filterValue: Store.IFilterValue,
    filterMeta: Store.IFilterMeta
): Api.ISearchRequestFilter['value'] | undefined => {
    if (filterMeta.serverType === Store.ServerFilterType.SelectFilter) {
        const selectValues: string[] = <string[]>filterValue;

        if (!Array.isArray(selectValues) || selectValues.length === 0) {
            return undefined;
        }

        return {
            SelectFilterValue: {
                values: selectValues.map((val) => ({
                    StringFilterValue: { value: val },
                })),
            },
        };
    }
    if (filterMeta.serverType === Store.ServerFilterType.RangeFilter) {
        const rangeValue: Store.IDateFilterValue = <Store.IDateFilterValue>(
            filterValue
        );

        if (
            typeof rangeValue.from !== 'number' &&
            typeof rangeValue.to !== 'number'
        ) {
            return undefined;
        }

        return {
            RangeFilterValue: checkAndFillEmptyYearInYearRangeFilter(
                rangeValue,
                filterMeta
            ),
        };
    }

    return undefined;
};
