/**
 * Created by Lkarmelo on 13.11.2017.
 */

import React, { PureComponent } from 'react';

import withSetBodyClass, { IBodyClassSetterProps } from './setBodyClass';

export interface ISetBodyClassProps {
    isBodyClassSet?: boolean;
}

interface ISetBodyClassOnMountState {
    isBodyClassSet: boolean;
}

export default <T extends ISetBodyClassProps = ISetBodyClassProps>(
        className: string,
        {
            shouldPassIsBodyClassSetProp,
        }: { shouldPassIsBodyClassSetProp: boolean } = {
            shouldPassIsBodyClassSetProp: false,
        }
    ) =>
    (WrappedComponent: React.ComponentType<T>): any =>
        withSetBodyClass(
            class SetBodyClassOnMount extends PureComponent<
                IBodyClassSetterProps & ISetBodyClassProps,
                ISetBodyClassOnMountState
            > {
                state: ISetBodyClassOnMountState = {
                    isBodyClassSet: false,
                };

                render(): JSX.Element {
                    const {
                        setBodyClass,
                        removeBodyClass,
                        toggleBodyClass,
                        getBodyContainsClass,
                        ...propsToPass
                    } = this.props;
                    propsToPass.isBodyClassSet = shouldPassIsBodyClassSetProp
                        ? this.state.isBodyClassSet
                        : undefined;

                    return <WrappedComponent {...(propsToPass as T)} />;
                }

                componentDidMount(): void {
                    this.props.setBodyClass(className);
                    if (shouldPassIsBodyClassSetProp) {
                        this.setState({ isBodyClassSet: true });
                    }
                }

                componentWillUnmount(): void {
                    this.props.removeBodyClass(className);
                }
            }
        );
