/**
 * Created by Lkarmelo on 13.06.2018.
 */

import React, { PureComponent } from 'react';
import classNames from 'classnames';

import * as Store from 'app/redux/store/StoreNamespace';
import { getReadableStringFromDocumentStatus } from 'app/utils/document/getReadableStringFromDocumentStatus';

import IProps from './interfaces/IEducationMaterialStatusFilterProps';
import * as styles from './EducationMaterialStatusFilter.scss';

export default class EducationMaterialStatusFilter extends PureComponent<IProps> {
    static defaultProps = {
        numberOfAll: 0,
        numberOfActive: 0,
        numberOfDone: 0,
        numberOfPlanning: 0,
        numberOfRecommended: 0,
        numberOfNecessaryRecommended: 0,
    };

    render(): JSX.Element {
        const {
            status,
            numberOfActive,
            numberOfAll,
            numberOfDone,
            numberOfPlanning,
            numberOfRecommended,
            numberOfNecessaryRecommended,
        } = this.props;
        return (
            <div className={styles.statusFilters}>
                <button
                    className={classNames(
                        `btn ${styles.statusFiltersFilter} ${styles.statusFiltersAll}`,
                        {
                            [styles.statusFiltersFilterSelected]:
                                status ===
                                Store.EducationMaterialStatusValue.All,
                        }
                    )}
                    onClick={() =>
                        this.props.onFilterSelect(
                            Store.EducationMaterialStatusValue.All
                        )
                    }
                >
                    <span>
                        <span className={styles.statusFiltersFilterName}>
                            Все
                        </span>
                        &nbsp;
                        <span className={styles.statusFiltersDocCount}>
                            {numberOfAll}
                        </span>
                    </span>
                </button>
                <button
                    className={classNames(
                        `btn ${styles.statusFiltersFilter} ${styles.statusFiltersRecommended}`,
                        {
                            [styles.statusFiltersFilterSelected]:
                                status ===
                                Store.EducationMaterialStatusValue.Recommended,
                        }
                    )}
                    onClick={() =>
                        this.props.onFilterSelect(
                            Store.EducationMaterialStatusValue.Recommended
                        )
                    }
                >
                    <span className={styles.statusFiltersIcon} />
                    <span>
                        <span className={styles.statusFiltersFilterName}>
                            Рекомендации
                        </span>
                        &nbsp;
                        <span
                            className={
                                styles.statusFiltersMandatoryRecommendedNumber
                            }
                        >
                            {numberOfNecessaryRecommended}
                        </span>
                        &nbsp;
                        <span className={styles.statusFiltersDocCount}>/</span>
                        &nbsp;
                        <span className={styles.statusFiltersDocCount}>
                            {numberOfRecommended}
                        </span>
                    </span>
                </button>
                <button
                    className={classNames(
                        `btn ${styles.statusFiltersFilter} ${styles.statusFiltersPlanning}`,
                        {
                            [styles.statusFiltersFilterSelected]:
                                status ===
                                Store.EducationMaterialStatusValue.Planning,
                        }
                    )}
                    onClick={() =>
                        this.props.onFilterSelect(
                            Store.EducationMaterialStatusValue.Planning
                        )
                    }
                >
                    <span className={styles.statusFiltersIcon} />
                    <span>
                        <span className={styles.statusFiltersFilterName}>
                            {getReadableStringFromDocumentStatus(
                                Store.EducationMaterialStatusValue.Planning
                            )}
                        </span>
                        &nbsp;
                        <span className={styles.statusFiltersDocCount}>
                            {numberOfPlanning}
                        </span>
                    </span>
                </button>
                <button
                    className={classNames(
                        `btn ${styles.statusFiltersFilter} ${styles.statusFiltersActive}`,
                        {
                            [styles.statusFiltersFilterSelected]:
                                status ===
                                Store.EducationMaterialStatusValue.Active,
                        }
                    )}
                    onClick={() =>
                        this.props.onFilterSelect(
                            Store.EducationMaterialStatusValue.Active
                        )
                    }
                >
                    <span className={styles.statusFiltersIcon} />
                    <span>
                        <span className={styles.statusFiltersFilterName}>
                            {getReadableStringFromDocumentStatus(
                                Store.EducationMaterialStatusValue.Active
                            )}
                        </span>
                        &nbsp;
                        <span className={styles.statusFiltersDocCount}>
                            {numberOfActive}
                        </span>
                    </span>
                </button>
                <button
                    className={classNames(
                        `btn ${styles.statusFiltersFilter} ${styles.statusFiltersDone}`,
                        {
                            [styles.statusFiltersFilterSelected]:
                                status ===
                                Store.EducationMaterialStatusValue.Done,
                        }
                    )}
                    onClick={() =>
                        this.props.onFilterSelect(
                            Store.EducationMaterialStatusValue.Done
                        )
                    }
                >
                    <span className={styles.statusFiltersIcon} />
                    <span>
                        <span className={styles.statusFiltersFilterName}>
                            {getReadableStringFromDocumentStatus(
                                Store.EducationMaterialStatusValue.Done
                            )}
                        </span>
                        &nbsp;
                        <span className={styles.statusFiltersDocCount}>
                            {numberOfDone}
                        </span>
                    </span>
                </button>
            </div>
        );
    }

    componentDidMount(): void {
        this.props.onMount();
    }
}
