/**
 * Created by Lkarmelo on 01.02.2018.
 */

import React, { PureComponent } from 'react';
import { Subject } from 'rxjs/Subject';

import { DOCUMENT_TITLE_CHANGE_DEBOUNCE_TIME } from 'app/utils/constants';

const defaultTitle = document.title;

export interface IDocumentTittleSetterProps {
    setDocumentTitle(title: string): void;
}

const titleChanger: Subject<string> = new Subject();
titleChanger
    .debounceTime(DOCUMENT_TITLE_CHANGE_DEBOUNCE_TIME)
    .subscribe((title: string) => {
        document.title = title;
    });

export default <T extends IDocumentTittleSetterProps>(
    WrappedComponent: React.ComponentType<T>
): any =>
    class DocumentTitleSetter extends PureComponent<T> {
        static setTitle(title: string) {
            titleChanger.next(title);
        }

        componentWillUnmount(): void {
            DocumentTitleSetter.setTitle(defaultTitle);
        }

        render(): JSX.Element {
            return (
                <WrappedComponent
                    {...this.props}
                    setDocumentTitle={DocumentTitleSetter.setTitle}
                />
            );
        }
    };
