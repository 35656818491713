/**
 * Created by Lkarmelo on 08.11.2017.
 */

import React, { PureComponent } from 'react';

import Footer from 'app/components/Footer';
import setBodyClassOnMount from 'app/components/high-order/setBodyClassOnMount';

@setBodyClassOnMount('login-layout')
export default class LoginLayout extends PureComponent {
    render(): JSX.Element {
        return (
            <div className="layout-wrapper">
                {this.props.children}
                <Footer />
            </div>
        );
    }
}
