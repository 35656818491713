/**
 * Created by lkarmelo on 22.11.2019.
 */

import React, {useCallback, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {useLatest, usePrevious} from 'react-use';

import Modal from 'app/components/Modals/Modal';
import * as Store from 'app/redux/store/StoreNamespace';

import IProps from './interfaces/ICategoryRulesFormProps';
import * as styles from './CategoryRulesForm.scss';

import InputField from '../../common/controls/InputField';

interface IFieldSetProps {
    code: string;
    value: string;
    index: number;

    changeCodeOrValue(
        index: number,
        field: 'code' | 'value',
        codeOrValue: string
    ): void;

    removeFieldSet(index: number): void;
}

const FieldSet = React.memo<IFieldSetProps>((props) => {
    const {code, value, index, changeCodeOrValue, removeFieldSet} = props;
    const [inputCode, SetInputCode] = useState(code);
    const [inputValue, SetInputValue] = useState(value);

    useEffect(()=>{
        SetInputCode(code)
    },[code])

    const sendValue = (type, value) => {
        changeCodeOrValue(index, type, value);
    }

    return (
        <div className={styles.categoryRulesFormFieldSet}>
            <InputField
                value={inputCode}
                onChange={(e) => SetInputCode(e)}
                onBlur={() => sendValue('code', inputCode)}
                placeholder="Например, storageSource"
            />
            <InputField
                value={inputValue}
                onChange={(e) => SetInputValue(e)}
                onBlur={() => sendValue('value', inputValue)}
                placeholder="Введите только одно значение"
            />
            <button
                className={`btn nkc-icon-trash ${styles.categoryRulesFormRemoveFieldSet}`}
                onClick={() => removeFieldSet(index)}
            />
        </div>
    );
});

type Field = { code: string; value: string; id: string };

const fieldsToRules = (fields: Field[]): Store.ICategorizationRule[] =>
    fields
        .filter(({code, value}) => !!code || !!value)
        .map(({code, value, id}) => ({
            id,
            type: (value
                ? 'PatternRule'
                : 'SearchFiltersRule') as Store.ICategorizationRule['type'],
            field: value ? code : undefined,
            pattern: value || undefined,
            searchId: value ? undefined : code,
        }));

const rulesToFields = (rules: Store.ICategorizationRule[]): Field[] =>
    rules.map(({id, field, pattern, searchId}) => ({
        id,
        code: searchId || field,
        value: pattern || '',
    }));

const getEmptyFields = () => ({
    code: '',
    value: '',
    id: Date.now().toString(),
});

const CategoryRulesForm: React.FC<IProps> = (props) => {
    const {
        isVisible,
        close,
        rules,
        loadRules,
        categoryId,
        sendRules,
        formStatus,
        initForm,
        removeForm,
    } = props;
    const [fieldSets, setFieldSets] = useState<Field[]>([]);
    const [showUnsavedWarning, setShowUnsavedWarning] = useState(false);
    const fieldSetsRef = useLatest(fieldSets);
    const prevCategoryId = usePrevious(categoryId);
    const wasChanged = useRef(false);

    useEffect(() => {
        isVisible && initForm();
    }, [isVisible, initForm]);

    useEffect(() => {
        formStatus === Store.FormStatus.SendingSuccess && close();
    }, [formStatus, close]);

    useEffect(() => {
        Array.isArray(rules) && setFieldSets(rulesToFields(rules));
    }, [rules]);

    useEffect(() => {
        if (categoryId !== prevCategoryId) {
            loadRules(categoryId);
        }
    }, [categoryId, prevCategoryId, loadRules]);

    const onAfterClose = useCallback(() => {
        wasChanged.current = false;
        removeForm();
        setShowUnsavedWarning(false);
    }, [close, removeForm]);

    const addField = useCallback(() => {
        const nextFieldSets = fieldSetsRef.current.slice();
        nextFieldSets.push(getEmptyFields());
        setFieldSets(nextFieldSets);
    }, [setFieldSets]);

    const removeFieldSet = useCallback(
        (index: number) => {
            wasChanged.current = true;
            const nextFieldSets = fieldSetsRef.current.slice();

            if (nextFieldSets.length === 1) {
                nextFieldSets[index] = getEmptyFields();
            } else {
                nextFieldSets.splice(index, 1);
            }

            setFieldSets(nextFieldSets);
        },
        [categoryId, setFieldSets]
    );

    const changeCodeOrValue = useCallback(
        (index: number, field: 'code' | 'value', codeOrValue: string) => {
            if (!fieldSetsRef.current[index]) {
                return;
            }
            wasChanged.current = true;
            const nextFieldSets = fieldSetsRef.current.slice();
            nextFieldSets[index][field] = codeOrValue;
            setFieldSets(nextFieldSets);
        },
        [setFieldSets]
    );

    const onClose = useCallback(() => {
        if (wasChanged.current) {
            setShowUnsavedWarning(true);
        } else {
            loadRules(categoryId);
            close();
        }
    }, [categoryId, close, loadRules]);

    return (
        <Modal
            visible={isVisible}
            maskClosable={false}
            afterClose={onAfterClose}
            showCross={false}
            className={classNames(styles.categoryRulesForm)}
        >
            {showUnsavedWarning ? (
                <div>
                    <div className={styles.categoryRulesFormTitle}>
                        Продолжить без сохранения?
                    </div>
                    <div className={styles.categoryRulesFormControls}>
                        <button
                            className={`btn ${styles.categoryRulesFormSaveBtn}`}
                            onClick={() => {
                                wasChanged.current = false;
                                onClose();
                            }}
                        >
                            Продолжить
                        </button>
                        <button
                            type="button"
                            className={`btn ${styles.categoryRulesFormCloseBtn}`}
                            onClick={() => setShowUnsavedWarning(false)}
                        >
                            Отмена
                        </button>
                    </div>
                </div>
            ) : (
                <div>
                    <div className={styles.categoryRulesFormTitle}>
                        Правила категории
                    </div>
                    {fieldSets.length === 0 && (
                        <div className={styles.categoryRulesFormNoRulesMsg}>
                            У вас еще не добавлено ни одного условия
                        </div>
                    )}
                    {fieldSets.length > 0 && (
                        <div className={styles.categoryRulesFormFieldLabels}>
                            <div>Короткая ссылка или код атрибута</div>
                            <div>Значение атрибута</div>
                        </div>
                    )}
                    {fieldSets.map(({code, value}, index) => (
                        <FieldSet
                            key={index}
                            code={code}
                            value={value}
                            index={index}
                            changeCodeOrValue={changeCodeOrValue}
                            removeFieldSet={removeFieldSet}
                        />
                    ))}
                    <button
                        className={`btn ${styles.categoryRulesFormAddButton}`}
                        onClick={addField}
                    >
                        Добавить условия
                    </button>
                    <div className={styles.categoryRulesFormControls}>
                        <button
                            className={`btn ${styles.categoryRulesFormSaveBtn}`}
                            onClick={() =>
                                sendRules(categoryId, fieldsToRules(fieldSets))
                            }
                        >
                            Сохранить
                        </button>
                        <button
                            type="button"
                            className={`btn ${styles.categoryRulesFormCloseBtn}`}
                            onClick={onClose}
                        >
                            Отмена
                        </button>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default CategoryRulesForm;
