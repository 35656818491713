/**
 * Created by lkarmelo on 24.04.2019.
 */

import * as Store from 'app/redux/store/StoreNamespace';

export const isQueryNode = (node: object): node is Store.IQueryNode =>
    node.hasOwnProperty('op');

type QTags = Store.IQueryTags | null;
type QNode = Store.IQueryNode | Store.IQueryTerm;

export const traversTags = (
    state: QTags,
    process: (term: Store.IQueryTerm) => Store.IQueryTerm
): QTags => {
    const processNode = (element: QNode): QNode => {
        if (isQueryNode(element)) {
            return {
                op: element.op,
                nodes: element.nodes
                    .map(processNode)
                    .filter((item) => Object.keys(item).length > 0),
            };
        }
        return process(element);
    };

    return state
        ? {
              queryString: state.queryString,
              tags: state.tags ? processNode(state.tags) : undefined,
          }
        : state;
};

export const traverseTerms = (
    toTraverse: QNode | null,
    cb: (term: Store.IQueryTerm) => void
) => {
    if (!toTraverse) {
        return;
    }
    if (isQueryNode(toTraverse)) {
        toTraverse.nodes.forEach((node) => traverseTerms(node, cb));
    } else {
        cb(toTraverse);
    }
};
