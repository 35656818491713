/**
 * Created by lkarmelo on 17.07.2019.
 */

import { Action, handleAction } from 'redux-actions';

import {
    setLastExecutedSearchAlias,
    setExecutingSearchAlias,
    setResolvingSearchAlias,
} from '../../actions/search/searchAlias';

const setStateFunc = (state: string, { payload }: Action<string>): string =>
    payload;

export const lastExecutedSearchAliasReducer = handleAction(
    setLastExecutedSearchAlias.toString(),
    setStateFunc,
    null
);

export const executingSearchAliasReducer = handleAction(
    setExecutingSearchAlias.toString(),
    setStateFunc,
    null
);

export const resolvingSearchAliasReducer = handleAction(
    setResolvingSearchAlias.toString(),
    setStateFunc,
    null
);
