import { MiddlewareAPI } from 'redux';
import { Observable } from 'rxjs/Observable';
import { Action } from 'redux-actions';
import { concat } from 'rxjs/observable/concat';

import * as Store from '../store/StoreNamespace';
import {
    requestEducationMaterialStatusChange,
    IRequestEducationMaterialStatusChange,
    setEducationMaterialStatus,
    requestEducationMaterialStatusReset,
    fetchNumberOfEducationMaterials,
    updateEducationStatus,
    IUpdateEducationMaterialStatusPayload,
} from '../actions/education';
import {
    educationStatusChangeRequest,
    educationStatusChangeResolve,
    educationStatusChangeReject,
    educationStatusResetRequest,
    educationStatusResetResolve,
    educationStatusResetReject,
} from '../actions/loading';
import { SearchDocumentName } from '../actions/interfaces/DocumentEnum';
import { withContext } from '../context/connectWithContext';

export const requestChangeEducationMaterialStatus = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }
) =>
    action$
        .ofType(requestEducationMaterialStatusChange.toString())
        .mergeMap((action: Action<IRequestEducationMaterialStatusChange>) => {
            const { id: documentId, status } = action.payload;

            return concat(
                apiCall
                    .changeEducationMaterialStatus(documentId, {
                        documentStatus: status,
                    })
                    .mergeMap(() =>
                        Observable.of(updateEducationStatus(documentId, status))
                    )
                    .catch((e) => {
                        console.error(e);
                        return Observable.of(educationStatusChangeReject(e));
                    }),
                Observable.of(educationStatusChangeResolve())
            )
                .takeUntil(
                    action$.ofType(educationStatusChangeReject.toString())
                )
                .startWith(educationStatusChangeRequest());
        });

export const requestResetEducationMaterialStatus = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }
) =>
    action$
        .ofType(requestEducationMaterialStatusReset.toString())
        .mergeMap((action: Action<string>) => {
            const documentId = action.payload;

            return concat(
                apiCall
                    .resetEducationMaterialStatus(documentId)
                    .mergeMap(() =>
                        Observable.of(
                            updateEducationStatus(documentId, undefined)
                        )
                    )
                    .catch((e) => {
                        console.error(e);
                        return Observable.of(educationStatusResetReject(e));
                    }),
                Observable.of(educationStatusResetResolve())
            )
                .takeUntil(
                    action$.ofType(educationStatusResetReject.toString())
                )
                .startWith(educationStatusResetRequest());
        });

export const updateEducationMaterialStatus = (action$) =>
    action$
        .ofType(updateEducationStatus.toString())
        .mergeMap((action: Action<IUpdateEducationMaterialStatusPayload>) => {
            const { id: documentId, status } = action.payload;
            return Observable.of(
                setEducationMaterialStatus(documentId, status),
                withContext(
                    setEducationMaterialStatus(documentId, status),
                    SearchDocumentName.objectCardSimilar
                ),
                withContext(
                    setEducationMaterialStatus(documentId, status),
                    SearchDocumentName.related
                ),
                withContext(
                    setEducationMaterialStatus(documentId, status),
                    SearchDocumentName.favorites
                ),
                withContext(
                    setEducationMaterialStatus(documentId, status),
                    SearchDocumentName.readLater
                ),
                withContext(
                    setEducationMaterialStatus(documentId, status),
                    SearchDocumentName.catalogueDocuments
                ),
                withContext(
                    setEducationMaterialStatus(documentId, status),
                    SearchDocumentName.documentManagementAll
                ),
                withContext(
                    setEducationMaterialStatus(documentId, status),
                    SearchDocumentName.documentManagementOwn
                ),
                withContext(
                    setEducationMaterialStatus(documentId, status),
                    SearchDocumentName.education
                ),
                fetchNumberOfEducationMaterials()
            );
        });
