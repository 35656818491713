import { Route } from 'react-router-dom';
import * as React from 'react';

/**
 * Created by lkarmelo on 22.08.2018.
 */

export const RouteWithSubRoutes = ({
    component: Component,
    subRoutes,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                Component ? <Component {...props}>{subRoutes}</Component> : null
            }
        />
    );
};
