/**
 * Created by lkarmelo on 09.11.2018.
 */

import React, { PureComponent } from 'react';

import { DeleteDocument } from 'app/components/Modals';

export interface IWithApproveDeleteDocumentModalProps {
    onApproveDeleteDocumentClick(
        documentId: string,
        documentTitle: string
    ): void;
}

interface IWithApproveDeleteDocumentModalState {
    isModalShow: boolean;
    documentId: string;
    documentTitle: string;
}

export default <T extends IWithApproveDeleteDocumentModalProps>(
    WrappedComponent: React.ComponentType<T>,
    onSuccess?: (props: T) => void
): React.ComponentType<Omit<T, 'onApproveDeleteDocumentClick'>> =>
    class WithApproveDeleteDocumentModal extends PureComponent<
        T,
        IWithApproveDeleteDocumentModalState
    > {
        state = {
            isModalShow: false,
            documentId: undefined,
            documentTitle: '',
        };

        constructor(props: T) {
            super(props);
            this.openModal = this.openModal.bind(this);
            this.hideModal = this.hideModal.bind(this);
            this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
        }

        hideModal(): void {
            this.setState({ isModalShow: false, documentId: undefined });
        }

        onDeleteSuccess(): void {
            onSuccess(this.props);
        }

        openModal(documentId: string, documentTitle: string): void {
            this.setState({ isModalShow: true, documentId, documentTitle });
        }

        render(): JSX.Element {
            return (
                <>
                    <DeleteDocument
                        isVisible={this.state.isModalShow}
                        onCancel={this.hideModal}
                        documentId={this.state.documentId}
                        bodyText={`Подтвердите удаление документа "${this.state.documentTitle}"`}
                        onDeleteSuccess={
                            onSuccess ? this.onDeleteSuccess : undefined
                        }
                    />
                    <WrappedComponent
                        {...this.props}
                        onApproveDeleteDocumentClick={this.openModal}
                    />
                </>
            );
        }
    };
