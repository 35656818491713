import React, { PureComponent } from 'react';
import moment, { Moment } from 'moment';
import autoBind from 'autobind-decorator';
import DatePicker from 'react-datepicker';

import { isNumber } from 'app/utils/isNumber';
import {
    DATE_DOTS_FORMAT,
    DATE_INPUT_FORMAT_HINT,
    DATE_INPUT_DEFAULT_MIN_VALUE,
    DATE_INPUT_DEFAULT_MAX_VALUE,
} from 'app/utils/constants';

import IProps from './interfaces/IDateInputFieldProps';
import IState from './interfaces/IDateInputFieldState';

import InputField from '../InputField';
import { SlideTransition } from '../../utils/CSSTransitions';

import './DateInputField.scss';

@autoBind
export default class DateInputField extends PureComponent<IProps, IState> {
    static defaultProps: Pick<
        IProps,
        'errorMessages' | 'minDate' | 'maxDate' | 'className'
    > = {
        errorMessages: [],
        minDate: DATE_INPUT_DEFAULT_MIN_VALUE,
        maxDate: DATE_INPUT_DEFAULT_MAX_VALUE,
        className: '',
    };

    state: IState = {
        isDatePickerShown: false,
    };

    isDatePickerClicked = false;

    static canValueBeConverted(value: number | string): boolean {
        return (
            value !== undefined &&
            value !== null &&
            typeof value !== 'string' &&
            isNumber(value)
        );
    }

    render(): JSX.Element {
        const {
            value,
            title,
            errorMessages,
            cssClass,
            className,
            classPrefix,
            id,
            isRequired,
            maxDate,
            minDate,
        } = this.props;
        const canConvertValue = DateInputField.canValueBeConverted(value);
        const inputValue = canConvertValue
            ? moment(value).format(DATE_DOTS_FORMAT)
            : (value as string);

        return (
            <div className={`date-input-field ${className}`}>
                <InputField
                    value={inputValue}
                    title={title}
                    errorMessages={errorMessages}
                    cssClass={cssClass}
                    classPrefix={classPrefix}
                    id={id}
                    isRequired={isRequired}
                    onFocus={this.showDatePicker}
                    onBlur={this.closeDatePickerOnOutsideClick}
                    onChange={this.onInputChange}
                    placeholder={DATE_INPUT_FORMAT_HINT}
                    onMouseDown={this.setDatePickerClicked}
                />
                <SlideTransition in={this.state.isDatePickerShown}>
                    <div
                        className="date-input-field__date-picker"
                        onMouseDown={this.setDatePickerClicked}
                    >
                        <DatePicker
                            inline
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onSelect={this.onDateSelect}
                            selected={
                                canConvertValue &&
                                this.isDateValueInBounds(value as number)
                                    ? moment(value)
                                    : undefined
                            }
                            onChange={undefined}
                            maxDate={maxDate && moment(maxDate)}
                            minDate={minDate && moment(minDate)}
                        />
                    </div>
                </SlideTransition>
                <button
                    className="date-input-field__toggle-calendar btn"
                    onClick={this.toggleDatePickerVisibility}
                    onMouseDown={this.setDatePickerClicked}
                />
            </div>
        );
    }

    componentDidMount(): void {
        document.addEventListener(
            'mousedown',
            this.closeDatePickerOnOutsideClick
        );
        document.addEventListener('mouseup', this.setDatePickerNotClicked);
        this.props.onMount && this.props.onMount();
    }

    componentWillUnmount(): void {
        document.removeEventListener(
            'mousedown',
            this.closeDatePickerOnOutsideClick
        );
        document.removeEventListener('mouseup', this.setDatePickerNotClicked);
    }

    setDatePickerClicked(): void {
        this.isDatePickerClicked = true;
    }

    setDatePickerNotClicked(): void {
        this.isDatePickerClicked = false;
    }

    onDateSelect(date: Moment): void {
        this.setState({ isDatePickerShown: false });
        this.props.onChange(date.valueOf(), this.props.meta);
    }

    isDateValueInBounds(value: number) {
        return value >= this.props.minDate && value <= this.props.maxDate;
    }

    onInputChange(str: string): void {
        if (!/^[0-9.]*$/.test(str)) {
            return;
        }
        const { onChange, meta } = this.props;
        const date = moment(str, DATE_DOTS_FORMAT, true);

        onChange(date.isValid() ? date.valueOf() : str, meta);
    }

    showDatePicker(): void {
        this.setState({ isDatePickerShown: true });
    }

    toggleDatePickerVisibility(
        e: React.MouseEvent<
            HTMLButtonElement | HTMLInputElement | HTMLTextAreaElement
        >
    ): void {
        e.preventDefault();
        this.setState((state) => ({
            isDatePickerShown: !state.isDatePickerShown,
        }));
    }

    closeDatePickerOnOutsideClick(): void {
        !this.isDatePickerClicked &&
            this.setState({ isDatePickerShown: false });
    }
}
