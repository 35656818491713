/**
 * Created by Lkarmelo on 06.03.2018.
 */

import { MiddlewareAPI } from 'redux';
import { Observable } from 'rxjs/Observable';
import { concat } from 'rxjs/observable/concat';

import { SearchDocumentName } from 'app/redux/actions/interfaces/DocumentEnum';
import {
    addToSearchResults,
    fetchMoreSearchResults,
    setSearchResults,
} from 'app/redux/actions/search/results';
import { fetchCatalogueDocuments } from 'app/redux/actions/catalogue/catalogueDocuments';
import {
    catalogueDocumentsReject,
    catalogueDocumentsRequest,
    catalogueDocumentsResolve,
} from 'app/redux/actions/loading';
import * as Store from 'app/redux/store/StoreNamespace';
import Api from 'app/api/Api';
import { withContext } from 'app/redux/context/connectWithContext';
import { stateToBookmarkSelector } from 'app/redux/selectors/api/bookmark';

import { createRetryOnIEAuthProblem } from '../utils/createRetryOnIEAuthProblem';

export const loadCatalogueCategoryDocuments = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall, context }
) =>
    action$
        .ofType(
            fetchCatalogueDocuments.toString(),
            fetchMoreSearchResults.toString()
        )
        .filter(
            (action) =>
                !!action.context &&
                (action.context[0] === SearchDocumentName.catalogueDocuments ||
                    action.context[0] ===
                        SearchDocumentName.catalogueDocumentsNew)
        )
        .scan((accumulated, action) => {
            if (action.type === fetchCatalogueDocuments.toString()) {
                return { lastCategoryId: action.payload.id, action };
            }
            return { ...accumulated, action };
        }, {})
        .groupBy(({ lastCategoryId, action }) => action.context[0])
        .mergeMap((group) =>
            group.switchMap(({ lastCategoryId, action }) => {
                const shouldAddToResults =
                    action.type === fetchMoreSearchResults.toString();
                const shouldLoadNewDocuments =
                    action.context[0] ===
                    SearchDocumentName.catalogueDocumentsNew;

                const storeState = store.getState();

                const contextState = context(storeState, action);
                // для документов в каталоге нужна общая сортировка, поэтому если ищем по новым документам,
                // то всё равно берём сортировку из catalogueDocuments
                const actionState = {
                    ...contextState,
                    sorting:
                        storeState[SearchDocumentName.catalogueDocuments]
                            .sorting,
                };

                const requestPayload = stateToBookmarkSelector({
                    state: storeState,
                    actionState,
                    searchDocumentName: action.context[0],
                });
                const categories = storeState.catalogueCategories;

                if (
                    !categories.categories ||
                    !categories.categories[lastCategoryId]
                ) {
                    return Observable.of(
                        catalogueDocumentsReject(new Error('No such category'))
                    );
                }

                return concat(
                    apiCall
                        .searchByCategory(
                            categories.categories[lastCategoryId].item.id,
                            requestPayload,
                            shouldLoadNewDocuments
                        )
                        .retryWhen(createRetryOnIEAuthProblem())
                        .map(
                            ({
                                response,
                            }: {
                                response: Api.IDocumentSearchResponseBody;
                            }) => {
                                const setOrAddAction = shouldAddToResults
                                    ? addToSearchResults
                                    : setSearchResults;
                                return withContext(
                                    setOrAddAction({ results: response }),
                                    action.context
                                );
                            }
                        )
                        .catch((e) => {
                            console.error(e);
                            return Observable.of(catalogueDocumentsReject(e));
                        }),
                    Observable.of(catalogueDocumentsResolve())
                )
                    .takeUntil(
                        action$.ofType(catalogueDocumentsReject.toString())
                    )
                    .startWith(catalogueDocumentsRequest());
            })
        );
