import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { sendForm, setFormStatus } from 'app/redux/actions/forms';
import * as Store from 'app/redux/store/StoreNamespace';
import { FormName } from 'app/api/FormName';

import DeleteCategory from './DeleteCategory';

import { IDeleteCategoryStateProps, IDeleteCategoryActionProps } from '.';

const mapStateToProps = (state: Store.IState): IDeleteCategoryStateProps => {
    return {
        deleteCategoryFormStatus:
            state.forms[FormName.CatalogueDeleteCategory]?.status,
        moveCategoryFormStatus:
            state.forms[FormName.CatalogueMoveSubCategories]?.status,
    };
};

const mapDispatchToProps = (dispatch): IDeleteCategoryActionProps => ({
    resetDeleteCategoryForm() {
        dispatch(setFormStatus(FormName.CatalogueDeleteCategory, null));
    },
    resetMoveCategoryForm(): void {
        dispatch(setFormStatus(FormName.CatalogueMoveSubCategories, null));
    },
    deleteCategory(code: string): void {
        dispatch(sendForm(FormName.CatalogueDeleteCategory, code));
    },
    moveSubCategories(categoryFromId: string, categoryToId: string): void {
        dispatch(
            sendForm(FormName.CatalogueMoveSubCategories, {
                categoryFromId,
                categoryToId,
            })
        );
    },
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DeleteCategory)
);
