import { ISelectOption } from '../modules/components/TreeSelect';

// создаём collator, потому что вручную сравнивание строк через localCompare может замедлять сортировку в сотни раз
const collator = new Intl.Collator(['ru', 'en'], { numeric: false });

/**
 * Сортирует ISelectOption по лейблам в порядке символы -> ru -> en
 * @param option1 ISelectOption
 * @param option2 ISelectOption
 */
export const compareSelectOptionsAlphabetically = (
    option1: ISelectOption,
    option2: ISelectOption
): number => {
    return collator.compare(option1.label, option2.label);
};
