/**
 * Created by lkarmelo on 17.12.2018.
 */

import * as Store from 'app/redux/store/StoreNamespace';
import apiRoutes from 'app/routing/apiRoutes';

export const documentTransformFileLink = <
    T extends { meta?: Store.IDocumentDescription }
>(
    doc: T
): T => {
    const newDoc = { ...(<{ meta: Store.IDocumentDescription }>doc) };
    if (!newDoc.meta) {
        return doc;
    }
    const { link } = newDoc.meta;
    if (typeof link === 'string' && link.startsWith('file://')) {
        const location = link.replace('file://', '');
        newDoc.meta.link = {
            title: newDoc.meta.title,
            url: apiRoutes.publicFileLocation.getUrl({ location }),
        };
        return <T>newDoc;
    }
    return doc;
};
