import { MAX_HREF_LENGTH } from 'app/utils/constants';

import { mapToMailBody, getTelephones } from './index';

export const getShareContactLink = (content) => {
    const telephones = getTelephones([
        content.telInternal,
        content.telMobile,
        content.telOffice,
    ]);

    const contactInfo = {
        ФИО: content.fullTitle,
        Должность: content.positionName,
        Адрес: content.userAddress,
        'Электронная почта': content.email,
        Skype: content.usrSip?.replace('sip:', ''),
        Телефон: telephones.join(', '),
        Местоположение: content.city,
    };

    const singleDepartment = `\r\n${mapToMailBody({
        Подразделение: content.department?.name,
    })}`;
    const fullDepartment =
        Array.isArray(content.department?.namePath) &&
        content.department.namePath.length > 1
            ? `\r\n${mapToMailBody({
                  Подразделение: content.department.namePath
                      .map((name, idx) => '>'.repeat(idx) + name)
                      .join('\r\n'),
              })}`
            : '';

    const encodedSingleDepartment = encodeURIComponent(singleDepartment);
    const encodedFullDepartment = encodeURIComponent(fullDepartment);

    const contactInfoRendered = mapToMailBody(contactInfo);

    let shareContactLink = `mailto:?subject=${encodeURIComponent(
        content.fullTitle
    )}&body=${encodeURIComponent(contactInfoRendered)}`;

    if (shareContactLink.length > MAX_HREF_LENGTH) {
        shareContactLink = shareContactLink.slice(0, MAX_HREF_LENGTH);
    } else if (
        shareContactLink.length + encodedFullDepartment.length <=
        MAX_HREF_LENGTH
    ) {
        shareContactLink += encodedFullDepartment;
    } else if (
        shareContactLink.length + encodedSingleDepartment.length <=
        MAX_HREF_LENGTH
    ) {
        shareContactLink += encodedSingleDepartment;
    }

    return shareContactLink;
};
