import React, { PureComponent } from 'react';
import autoBind from 'autobind-decorator';
import classNames from 'classnames';

import {
    ITreeOptionNodeProps,
    IViewOption,
} from './interfaces/ITreeSelectListProps';

import TreeOptionLeaf from '../TreeOptionLeaf/TreeOptionLeaf';
import { IOption } from '../../../../controls/Option';

const filterOptions = (options: IOption[], parent?: string) => {
    if (parent) {
        return options.filter((item) => item.parent === parent);
    }

    // filter without parent items or with broken parent
    const ids = options.map((item) => item.value);
    return options.filter(
        (item) => !item.parent || ids.indexOf(item.parent) === -1
    );
};

const optionList = (
    options: IOption[],
    parent?: IViewOption
): IViewOption[] => {
    const levelOptions: IOption[] = filterOptions(
        options,
        parent && parent.value
    );

    if (levelOptions.length === 0) {
        return [];
    }

    return levelOptions.map((item) => {
        const childLength = filterOptions(options, item.value).length;

        return {
            ...item,
            meta: {
                ...item.meta,
                level: (parent ? parent.meta.level : -1) + 1,
                expand: childLength > 0 ? false : null,
                expandable: childLength > 0,
            },
        } as IViewOption;
    });
};

interface ITreeOptionNodeState {
    expand: boolean;
}

export default class TreeOptionNode extends PureComponent<
    ITreeOptionNodeProps,
    ITreeOptionNodeState
> {
    static defaultProps = {
        depth: 0,
    };

    state = {
        expand: false,
    };

    optionListRef: HTMLUListElement = null;

    rootOptionRef: TreeOptionLeaf = null;

    constructor(props: ITreeOptionNodeProps) {
        super(props);

        if (props.depth <= props.initiallyExpandedDepth) {
            this.state.expand = true;
        }
    }

    // parent.meta.expand
    @autoBind
    onOptionExpand(e: React.MouseEvent<HTMLSpanElement>) {
        e.stopPropagation();
        e.preventDefault();

        const { expand: prevExpand } = this.state;

        this.setState({ expand: !prevExpand });
    }

    render(): JSX.Element {
        const { expand } = this.state;
        const {
            parent,
            className,
            options,
            optionRenderer,
            depth,
            isOptionActive,
            onSelectOption,
            onDeselectOption,
        } = this.props;

        const levelOptions =
            (!parent || expand) &&
            optionList(options, parent).map((opt, index) => {
                const itemActive = isOptionActive(opt);

                return opt.meta.expandable ? (
                    <TreeOptionNode
                        {...this.props}
                        depth={depth + 1}
                        key={opt.value}
                        parent={opt}
                    />
                ) : (
                    <TreeOptionLeaf
                        baseClassName={`${className}__option`}
                        key={opt.value}
                        option={{
                            ...opt,
                            label: `${'\t'.repeat(opt.meta.level)}${opt.label}`,
                            meta: {
                                ...opt.meta,
                                index,
                            },
                        }}
                        isActive={itemActive}
                        optionRenderer={optionRenderer}
                        onExpand={this.onOptionExpand}
                        onSelect={onSelectOption}
                        onDeselect={onDeselectOption}
                    />
                );
            });

        if (!parent) {
            return (
                <div className={`${className}__option-root-wrapper`}>
                    <ul
                        className={classNames(
                            `${className}__option-root`,
                            `${className}__option`,
                            {
                                [`${className}__child-tree-open`]:
                                    parent && expand,
                            }
                        )}
                    >
                        {levelOptions}
                    </ul>
                </div>
            );
        }

        const optionActive = isOptionActive(parent);

        return (
            <ul className={`${className}__option`}>
                <TreeOptionLeaf
                    ref={(ref) => {
                        this.rootOptionRef = ref;
                    }}
                    baseClassName={`${className}__option`}
                    option={{
                        ...parent,
                        label: `${'\t'.repeat(parent.meta.level)}${
                            parent.label
                        }`,
                        meta: {
                            ...parent.meta,
                            expand,
                        },
                    }}
                    isActive={optionActive}
                    optionRenderer={optionRenderer}
                    onSelect={onSelectOption}
                    onDeselect={onDeselectOption}
                    onExpand={this.onOptionExpand}
                />

                {levelOptions}
            </ul>
        );
    }
}
