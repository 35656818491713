/**
 * Created by Lkarmelo on 23.08.2017.
 */

import {
    handleActions,
    combineActions,
    Action,
    handleAction,
} from 'redux-actions';

import { DEPARTMENT_PATH } from 'app/utils/constants';

import * as Store from '../../store/StoreNamespace';
import ISetFiltersByNamePayloadItem from '../../actions/interfaces/ISetFiltersByNamePayloadItem';
import {
    IChangeFilterValuePayload,
    addMultiSelectFilterValue,
    searchEmployeesWithFilterValue,
    removeMultiSelectFilterValue,
    toggleMultiSelectFilterValue,
    setFilterValue,
    setFilters,
    setFiltersByNames,
    setDateRangeFrom,
    setDateRangeTo,
    setLastExecutedFilters,
} from '../../actions/search/filters';
import {
    setStateFromLocation,
    ISetStateFromLocationPayload,
} from '../../actions/search/stateFromLocation';
import { setReducer } from '../../utils/setReducer';

const setValueTimeToFilter = (
    value: Store.IFilterValue,
    filter: Store.IFilter
): void => {
    const time = Date.now();
    if (Array.isArray(value)) {
        filter.valueSetTime = {};

        value.forEach((v) => {
            filter.valueSetTime[v] = time;
        });
    } else {
        filter.valueSetTime = time;
    }
};

export const filtersReducer = handleActions(
    {
        [addMultiSelectFilterValue.toString()](
            state: Store.IFilters,
            { payload }: Action<IChangeFilterValuePayload>
        ) {
            const nextState: Store.IFilters = { ...state };
            const value: string = <string>payload.value;

            if (
                state[payload.filterName] &&
                (<string[]>state[payload.filterName].value).indexOf(
                    <string>payload.value
                ) >= 0
            ) {
                return state;
            }

            const filter = { ...nextState[payload.filterName] };
            let filterValue: string[] = <string[]>filter.value;
            if (!Array.isArray(filterValue)) {
                filterValue = [value];
            } else {
                filterValue = filterValue.slice();
                filterValue.push(value);
            }
            filter.value = filterValue;

            filter.valueSetTime = {
                ...(<Store.IValueTimeMap>filter.valueSetTime),
                ...{ [value]: Date.now() },
            };

            nextState[payload.filterName] = filter;

            return nextState;
        },
        [searchEmployeesWithFilterValue.toString()](
            state: Store.IFilters,
            { payload }: Action<IChangeFilterValuePayload>
        ) {
            const nextState: Store.IFilters = {};
            const filter = { ...nextState[payload.filterName] };
            if (payload.filterName === DEPARTMENT_PATH) {
                filter.value = payload.value;
                setValueTimeToFilter(payload.value, filter);
                nextState[payload.filterName] = filter;

                return nextState;
            }

            const value: string = <string>payload.value;

            let filterValue: string[] = <string[]>filter.value;
            if (!Array.isArray(filterValue)) {
                filterValue = [value];
            } else {
                filterValue = filterValue.slice();
                filterValue.push(value);
            }
            filter.value = filterValue;

            filter.valueSetTime = {
                ...(<Store.IValueTimeMap>filter.valueSetTime),
                ...{ [value]: Date.now() },
            };

            nextState[payload.filterName] = filter;
            return nextState;
        },
        [removeMultiSelectFilterValue.toString()](
            state: Store.IFilters,
            { payload }: Action<IChangeFilterValuePayload>
        ) {
            const nextState: Store.IFilters = { ...state };
            nextState[payload.filterName] = {
                ...nextState[payload.filterName],
            };

            const filter = nextState[payload.filterName];
            const filterValue: string[] = <string[]>filter.value;

            filter.value = Array.isArray(filterValue)
                ? filterValue.slice().filter((v) => v !== payload.value)
                : [];

            const nextSetTime: Store.IValueTimeMap = {
                ...(<Store.IValueTimeMap>filter.valueSetTime),
            };
            delete nextSetTime[<string>payload.value];
            filter.valueSetTime = nextSetTime;

            return nextState;
        },
        [toggleMultiSelectFilterValue.toString()](
            state: Store.IFilters,
            { payload }: Action<IChangeFilterValuePayload>
        ) {
            const nextState: Store.IFilters = { ...state };
            const filter = { ...nextState[payload.filterName] };

            nextState[payload.filterName] = filter;

            if (!Array.isArray(filter.value)) {
                filter.value = <string[]>[payload.value];
                filter.valueSetTime = { [<string>payload.value]: Date.now() };
                return nextState;
            }

            const filterValue = <string[]>filter.value;
            const payloadValue = <string>payload.value;
            const indexOfVal = filterValue.indexOf(payloadValue);

            filter.value = filterValue.slice();
            if (indexOfVal < 0) {
                filter.value.push(payloadValue);
            } else {
                filter.value.splice(indexOfVal, 1);
            }

            const nextSetTime: Store.IValueTimeMap = {
                ...(<Store.IValueTimeMap>filter.valueSetTime),
            };
            if (nextSetTime.hasOwnProperty(payloadValue)) {
                delete nextSetTime[payloadValue];
            } else {
                nextSetTime[payloadValue] = Date.now();
            }
            filter.valueSetTime = nextSetTime;

            return nextState;
        },
        [setFilterValue.toString()](
            state: Store.IFilters,
            { payload }: Action<IChangeFilterValuePayload>
        ) {
            const nextState: Store.IFilters = { ...state };

            const filter = { ...nextState[payload.filterName] };

            filter.value = payload.value;

            setValueTimeToFilter(payload.value, filter);

            nextState[payload.filterName] = filter;

            return nextState;
        },
        [combineActions(setDateRangeFrom, setDateRangeTo).toString()](
            state: Store.IFilters,
            { payload, type }: Action<IChangeFilterValuePayload>
        ) {
            const nextState: Store.IFilters = { ...state };

            // add filter

            const filter = { ...nextState[payload.filterName] };

            const filterValue: Store.IDateFilterValue = {
                ...(<Store.IDateFilterValue>filter.value),
            };

            if (type === setDateRangeFrom.toString()) {
                if (payload.value === undefined) {
                    delete filterValue.from;
                } else {
                    filterValue.from = <number>payload.value;
                }
            }

            if (type === setDateRangeTo.toString()) {
                if (payload.value === undefined) {
                    delete filterValue.to;
                } else {
                    filterValue.to = <number>payload.value;
                }
            }

            filter.value = filterValue;
            filter.valueSetTime = Date.now();

            nextState[payload.filterName] = filter;
            return nextState;
        },
        [setFilters.toString()]: setReducer,
        [setFiltersByNames.toString()](
            state: Store.IFilters,
            { payload }: Action<ISetFiltersByNamePayloadItem[]>
        ) {
            const nextState: Store.IFilters = { ...state };
            for (const nextFilter of payload) {
                const { value, filterName } = nextFilter;
                const filter = { ...nextState[filterName] };

                filter.value = value;

                setValueTimeToFilter(value, filter);

                nextState[filterName] = filter;
            }
            return nextState;
        },
        [setStateFromLocation.toString()](
            state: Store.IFilters,
            { payload }: Action<ISetStateFromLocationPayload>
        ): Store.IFilters {
            if (!payload.filters) {
                return state;
            }
            const nextState = { ...state };
            Object.entries(payload.filters).forEach(([filterName, filter]) => {
                const nextFilter = {
                    ...nextState[filterName],
                    value: filter.value,
                };
                setValueTimeToFilter(filter.value, nextFilter);
                nextState[filterName] = nextFilter;
            });
            return nextState;
        },
    },
    {} // - default value for filters
);

export const lastExecutedFiltersReducer = handleAction(
    setLastExecutedFilters.toString(),
    setReducer,
    {}
);
