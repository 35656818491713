import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import LoadingScreen from 'app/components/common/utils/LoadingScreen';
import * as Store from 'app/redux/store/StoreNamespace';
import NoRolesScreen from 'app/components/common/utils/NoRolesScreen';
import { hasRolesSelector } from 'app/redux/selectors/hasRoles';

import { withLoadingScreen } from './withLoading';

import clientRoute from '../../routing/clientRoutes';

const RedirectWithRefresh: React.FunctionComponent<{ to: string; }> = (redirect) => {
    window.location.href = redirect.to; // нужен рефреш страницы для корректной авторизации со стороны бэка
    return <LoadingScreen />;
}

const RedirectOnAccessDeniedWrapper = (
    WrappedComponent
): React.FunctionComponent<any> =>
    function ({ isAllowed, redirectTarget, ...props }) {
        return isAllowed ? (
            <WrappedComponent {...props} />
        ) : (
            <RedirectWithRefresh to={redirectTarget} />
        );
    };

const NoRolesBlocker = (WrappedComponent): React.FC<any> =>
    function ({ hasRoles, rolesLoading, ...props }) {
        if (rolesLoading) {
            return <LoadingScreen />;
        }
        return hasRoles ? <WrappedComponent {...props} /> : <NoRolesScreen />;
    };

const redirectAuthorizedProps = (state: Store.IState, ownProps) => ({
    redirectTarget: `${clientRoute.main.getUrl()}`,
    isLoading: state.authorization.isAuthorized === null,
    isAllowed: !state.authorization.isAuthorized,
    ...ownProps,
});

export const redirectAuthorized = (WrappedComponent) =>
    connect(redirectAuthorizedProps)(
        withLoadingScreen(RedirectOnAccessDeniedWrapper(WrappedComponent))
    );

const redirectUnauthorizedProps = (state: Store.IState, ownProps) => {
    // current location
    const currentPagePath = location.pathname + location.search;
    const backURLParams = currentPagePath
        ? `?backUrl=${encodeURIComponent(currentPagePath)}`
        : '';

    return {
        redirectTarget: `${state.authorization.loginUrl}${backURLParams}`,
        isLoading: state.authorization.isAuthorized === null,
        isAllowed: !!state.authorization.isAuthorized,
        rolesLoading: state.user?.permissions === undefined,
        hasRoles: hasRolesSelector(state),
        ...ownProps,
    };
};

export default (WrappedComponent) =>
    connect(redirectUnauthorizedProps)(
        withLoadingScreen(
            RedirectOnAccessDeniedWrapper(NoRolesBlocker(WrappedComponent))
        )
    );
