/**
 * Created by lkarmelo on 13.11.2019.
 */
import { handleAction } from 'redux-actions';

import { setRedirectInfo } from '../actions/outsideRedirectInfo';
import { setReducer } from '../utils/setReducer';
import initialState from '../store/initialState';

export default handleAction(
    setRedirectInfo.toString(),
    setReducer,
    initialState.outsideRedirectInfo
);
