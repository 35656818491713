/**
 * Created by lkarmelo on 16.08.2018.
 */

import { FocusEvent } from 'react';

import { IEVersion } from 'app/utils/IEVersion';

/**
 * Получает event.relatedTarget
 * в IE11 есть баг с event.relatedTarget https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/101237/
 * поэтому из-за костыля приходится оборачивать в промис
 */
export function getRelatedTarget(
    e: FocusEvent<any>
): Promise<HTMLElement | null> {
    return new Promise<HTMLElement>((resolve) => {
        if (IEVersion === null) {
            resolve(<HTMLElement>e.relatedTarget);
        }
        setTimeout(() => {
            resolve(<HTMLElement>document.activeElement);
        }, 0);
    });
}
