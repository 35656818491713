import React, { FC, FormEvent, KeyboardEvent, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import Tooltip from 'antd/es/tooltip';
import {Subject} from 'rxjs/Subject';

import { MAX_SEARCH_QUERY_LENGTH } from 'app/utils/constants';
import { useTypedSelector } from 'app/hooks/useTypedSelector';
import * as Store from 'app/redux/store/StoreNamespace';
import Api from 'app/api/Api';
import getSearchTarget from 'app/utils/getSearchTarget';
import { highlightQueryInSearch } from 'app/redux/epics/highlight';
import { IDropDownListRef } from 'app/components/common/controls/DropDownList';
import { IOption } from 'app/components/common/controls/Option';
import { useDidUpdate } from 'app/hooks/useDidUpdate';

import { RenderHintList } from './Renders';

import { getInputInnerWidth, getQueryHints } from '../helpers';
import useSearchPanel from '../hooks/useSearchPanel';
import * as styles from '../SearchPanel.scss';
import { HintOptionMeta, isHintFilter, isHintRegular } from '../types';

interface ISearchPanelLongQueryTooltip {
    textAreaWrapperRef: MutableRefObject<HTMLDivElement>;
    inputWrapperRef: MutableRefObject<HTMLDivElement>;
    inputRef: MutableRefObject<HTMLInputElement>;
    autofocus?: boolean;
    isMinified?: boolean;
    aheadRef: MutableRefObject<HTMLInputElement>;
    aheadFillerRef: MutableRefObject<HTMLElement>;
    markedQueryTextRef: MutableRefObject<HTMLSpanElement>;
    hasHints: () => boolean;
    showHints: boolean;
    setShowHints: (showHints: boolean) => void;
    focusInput: () => void;
    handleVisibleChange: (visible) => void;
    handleSearchClick: () => void;
    closeHints: () => void;
    onFetchSearchResultsClickOwnProps?: (searchQuery?: string) => void;
    subjectEnterPressed$: Subject<any>;
}

const SearchPanelLongQueryTooltip: FC<ISearchPanelLongQueryTooltip> = (props) => {
    const {
        textAreaWrapperRef,
        inputWrapperRef,
        inputRef,
        autofocus,
        isMinified,
        aheadRef,
        aheadFillerRef,
        markedQueryTextRef,
        hasHints,
        showHints,
        setShowHints,
        onFetchSearchResultsClickOwnProps,
        focusInput,
        handleVisibleChange,
        handleSearchClick,
        closeHints,
        subjectEnterPressed$
    } = props;
    const state = useTypedSelector(store => store);
    const { search: { searchQuery, hints } } = state;
    const searchTarget = getSearchTarget(state.mainSearchPageActiveTab, state);
    const { queryHints } = getQueryHints(hints?.queries);

    const [lastAheadSearchQuery, setLastAheadSearchQuery] = useState('');
    const [originalSearchQuery, setOriginalSearchQuery] = useState('');
    const [focusedHint, setFocusedHint] = useState(null);
    const [isTextAreaWrapperClicked, setIsTextAreaWrapperClicked] = useState(false);

    const {
        onFetchSearchHintsClick,
        onUpdateQuery,
        onSelectFilterHint,
    } = useSearchPanel({
        isMinified, onFetchSearchResultsClickOwnProps,
    });

    const hintListRef = useRef<IDropDownListRef>(null);

    const getLongQueryTooltipContainer = () => inputWrapperRef.current;

    const highlightQueryFromFilterHint = (position: Store.IQueryPosition | null) => {
        if (!aheadFillerRef.current || !inputRef.current || !markedQueryTextRef.current) {
            return;
        }

        const inputStringWidth = aheadFillerRef.current.clientWidth;
        const inputInnerWidth = getInputInnerWidth(inputRef);

        const textOverflowsInput = inputStringWidth > inputInnerWidth;
        const inputCaretAtTheEnd = inputRef.current.selectionEnd === inputRef.current.value.length;
        const isInputFocused = document.activeElement === inputRef.current;

        let highlightedText;
        // если текст длиннее, чем инпут и курсор не стоит в самом конце, убираем хайлайт. нужно потому что текст в инпуте может сдвинуться и
        // хайлайт будет на неправильном месте
        if (position === null || (textOverflowsInput &&
            (!inputCaretAtTheEnd || !isInputFocused))) {
            highlightedText = '';
        } else {
            highlightedText = highlightQueryInSearch(searchQuery, position);
        }

        markedQueryTextRef.current.innerHTML = highlightedText;
    };

    const onTextAreaWrapperMouseDown = () => {
        setIsTextAreaWrapperClicked(true);
    };

    const onTextAreaWrapperMouseUp = () => {
        setIsTextAreaWrapperClicked(false);
    };

    const onHintUnHighlight = useCallback((option: IOption<HintOptionMeta>) => {
        if (!option) {
            return;
        }
        const { meta } = option;
        if (isHintFilter(meta)) {
            highlightQueryFromFilterHint(null);
        }
        setFocusedHint(isHintRegular(meta) ? null : focusedHint);
    }, [isHintFilter, highlightQueryFromFilterHint, setFocusedHint, focusedHint]);

    const updateQuery = useCallback((query: string, isOriginalSearchQuery = true) => {
        const limitedQuery = typeof query === 'string' ? query.slice(0, MAX_SEARCH_QUERY_LENGTH) : query;
        onUpdateQuery(limitedQuery);
        setOriginalSearchQuery(isOriginalSearchQuery ? limitedQuery : originalSearchQuery);
    }, [onUpdateQuery, setOriginalSearchQuery]);

    const fetchSearchHints = (reset: boolean) => {
        onFetchSearchHintsClick(
            reset,
            searchTarget === Store.SearchTarget.employee
                ? [Api.SuggestionType.employee]
                : undefined,
        );
    };

    const onSearchFieldChange = (e: FormEvent<HTMLInputElement>) => {
        const nextQuery = e.currentTarget.value;

        updateQuery(nextQuery);

        if (!isMinified) {
            fetchSearchHints(Math.abs(nextQuery.length - searchQuery.length) > 1);
            setShowHints(true);
        }
    };

    const completeQueryWithAhead = (): void => {
        updateQuery(lastAheadSearchQuery);
        fetchSearchHints(false);
        focusInput();
    };

    const focusSuggestions = () => {
        hintListRef.current?.highlightOption(0);
    };

    const onSearchFieldKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        const input: HTMLInputElement = e.currentTarget;

        if (e.key === 'Enter') {
            handleVisibleChange(false);
            subjectEnterPressed$.next();
            if (!hintListRef.current?.toggleSelectIfHighlighted()) {
                handleSearchClick();
            }
        } else if (e.key === 'Escape' || e.key === 'Esc') {
            // в IE11 при нажатии на esc значение в инпуте меняется на значение, которое было до фокуса и это не вызывает onchange эвент
            // поэтому его надо отменить
            e.preventDefault();
            setShowHints(!(hasHints() && showHints));
        }
        if (isMinified) {
            return;
        }

        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();

            setShowHints(hasHints() && true);

            if (input.selectionStart === input.value.length) {
                if (e.key === 'ArrowUp') {
                    hintListRef.current?.highlightPrevOption();
                } else {
                    hintListRef.current?.highlightNextOption();
                }
            } else {
                input.selectionStart = input.selectionEnd = input.value.length;
                focusSuggestions();
            }
        }
        if (e.key === 'ArrowRight' && lastAheadSearchQuery.length > searchQuery.length) {
            e.preventDefault();
            completeQueryWithAhead();
        }
    };

    /**
     * Очищает хайлайт, если текст длиннее, чем инпут и курсор не стоит в самом конце. Иначе при сдвиге текста в инпуте хайлайт будет
     * отображаться на неправильном месте
     */
    const clearHighlightedQueryIfNecessary = () => {
        const inputStringWidth = aheadFillerRef.current.clientWidth;
        const inputInnerWidth = getInputInnerWidth(inputRef);

        const textOverflowsInput: boolean = inputStringWidth > inputInnerWidth;
        const inputCaretAtTheEnd: boolean = inputRef.current.selectionEnd === inputRef.current.value.length;

        if (textOverflowsInput && !inputCaretAtTheEnd) {
            markedQueryTextRef.current.innerHTML = '';
        }
    };

    const getInputAheadValue = () => {
        const isEmpty = !queryHints || !queryHints[0] || !showHints || !searchQuery;

        setLastAheadSearchQuery(isEmpty ? '' : lastAheadSearchQuery);

        if (isEmpty) {
            return '';
        }

        let aheadQuery = '';
        const firstHint = focusedHint || queryHints[0];
        const firstHintQuery = firstHint.query || '';
        const firstHintQueryLowerCase = firstHintQuery.toLowerCase();

        const hintStartPos = firstHint.position ? firstHint.position.start : 0;

        const searchQueryChunkLowerCase = searchQuery.toLowerCase().slice(hintStartPos);

        if (firstHintQueryLowerCase.indexOf(searchQueryChunkLowerCase) === 0 && searchQueryChunkLowerCase !== firstHintQueryLowerCase) {
            aheadQuery = searchQuery.slice(0, hintStartPos) +
                searchQuery.slice(hintStartPos) +
                firstHintQuery.slice(searchQuery.length - hintStartPos);
        }

        setLastAheadSearchQuery(aheadQuery);
    };

    const handleClickPersonList = useCallback(() => {
        showHints && closeHints();
    }, [showHints, closeHints]);

    useDidUpdate(() => {
        const isHints = !showHints || !hasHints();
        if (isHints) {
            highlightQueryFromFilterHint(null);
            hintListRef.current?.highlightOption(-1);
        }
        setFocusedHint(isHints ? null : focusedHint);
    }, [showHints, hasHints]);

    useEffect(() => {
        getInputAheadValue();
    }, [queryHints, showHints, searchQuery]);

    return (
        <Tooltip
            overlayClassName={styles.searchPanelLongQueryTooltip}
            visible={searchQuery.length >= MAX_SEARCH_QUERY_LENGTH}
            title={
                `Достигнута максимальная длина запроса ${MAX_SEARCH_QUERY_LENGTH} символов,
                        последующий введенный текст будет проигнорирован при поиске`
            }
            placement='bottomRight'
            getPopupContainer={getLongQueryTooltipContainer}
        >
            <div
                onMouseDown={onTextAreaWrapperMouseDown}
                onMouseUp={onTextAreaWrapperMouseUp}
                className={styles.searchPanelTextAreaWrapper}
                ref={textAreaWrapperRef}
            >
                <input
                    ref={inputRef}
                    className={styles.searchPanelTextArea}
                    onChange={onSearchFieldChange}
                    placeholder='Поиск'
                    value={searchQuery}
                    onKeyDown={onSearchFieldKeyDown}
                    onKeyUp={clearHighlightedQueryIfNecessary}
                    onClick={clearHighlightedQueryIfNecessary}
                    autoFocus={autofocus && !isMinified}
                />
                {!isMinified &&
                    <div
                        ref={aheadRef}
                        className={styles.searchPanelTextAreaAhead}
                    >
                        {/* Нужен для измерения длины введённой searchQuery в пикселях */}
                        <span
                            ref={aheadFillerRef}
                            className={styles.searchPanelTextAreaAheadFiller}
                        >
                                    {searchQuery}
                        </span>
                        <input
                            className={styles.searchPanelTextAreaAheadHint}
                            disabled
                            autoComplete='off'
                            value={lastAheadSearchQuery}
                        />
                    </div>
                }
                {/* подсвечивает часть searchQuery, по которой пришла подсказка */}
                <div className={styles.searchPanelTextAreaMarkedWrapper}>
                            <span
                                ref={markedQueryTextRef}
                                className={styles.searchPanelTextAreaMarkedText}
                            />
                </div>
                {!isMinified && hasHints() &&
                    <RenderHintList
                        hintListRef={hintListRef}
                        showHints={showHints}
                        onHintUnHighlight={onHintUnHighlight}
                        onClickPersonList={handleClickPersonList}
                        updateQuery={updateQuery}
                        handleSearchClick={handleSearchClick}
                        onSelectFilterHint={onSelectFilterHint}
                        setFocusedHint={setFocusedHint}
                        highlightQueryFromFilterHint={highlightQueryFromFilterHint}
                        isTextAreaWrapperClicked={isTextAreaWrapperClicked}
                        closeHints={closeHints}
                        subjectEnterPressed$={subjectEnterPressed$}
                        fetchSearchHints={fetchSearchHints}
                    />
                }
            </div>
        </Tooltip>
    );
};

export default SearchPanelLongQueryTooltip;
