/**
 * Created by lkarmelo on 17.07.2019.
 */
import { createAction } from 'redux-actions';

export const setLastExecutedSearchAlias = createAction<string | null>(
    'SET_LAST_EXECUTED_SEARCH_ALIAS'
);
export const setExecutingSearchAlias = createAction<string | null>(
    'SET_EXECUTING_SEARCH_ALIAS'
);
export const setResolvingSearchAlias = createAction<string | null>(
    'SET_RESOLVING_SEARCH_ALIAS'
);
