import { Action, handleActions } from 'redux-actions';
import keyBy from 'lodash/keyBy';

import initialState from 'app/redux/store/initialState';
import {
    setSearchActiveTab,
    setSearchTabsMeta,
    setSearchActiveTabForResults,
    ISetSearchActiveTabPayload,
    setSingleSearchTabMeta,
    ISetSingleSearchTabMetaPayload,
} from 'app/redux/actions/search/searchTabs';
import * as Store from 'app/redux/store/StoreNamespace';
import { copyFiltersMeta } from 'app/redux/epics/loadFiltersMeta';
import Api from 'app/api/Api';
import { searchTargetToTabConfig } from 'app/redux/selectors/searchTabConfig';
import {
    searchTabsReject,
    searchTabsSimpleReject,
} from 'app/redux/actions/loading';

const searchTabsMetaReducer = handleActions<
    Store.ISearchTabsMeta,
    Api.ISearchTabSimple[] | ISetSingleSearchTabMetaPayload
>(
    {
        [setSearchTabsMeta.toString()](
            state: Store.ISearchTabsMeta,
            { payload }: Action<Api.ISearchTabSimple[]>
        ): Store.ISearchTabsMeta {
            if (!Array.isArray(payload)) {
                return state;
            }

            const defaultTabCode = payload[0]?.code;

            return {
                defaultTabCode,
                tabs: keyBy(payload, 'code'),
                tabCodes: payload.map(({ code }) => code),
            };
        },
        [setSingleSearchTabMeta.toString()](
            state: Store.ISearchTabsMeta,
            { payload }: Action<ISetSingleSearchTabMetaPayload>
        ): Store.ISearchTabsMeta {
            const { defaultTabCode, tabCodes } = state;
            const tabs = { ...state.tabs };

            const staticFilters = { ...Store.staticFilters };
            tabs[payload.tab.code] = {
                ...payload.tab,
                filters: Object.assign(
                    {},
                    staticFilters,
                    ...payload.tab.filters.map(copyFiltersMeta)
                ),

                // TODO: В будущем сортировки будут приходить с сервера, сейчас приходится задавать на фронте
                sortingOptions:
                    searchTargetToTabConfig[payload.tab.searchTarget]
                        ?.sortingOptions ?? [],
            };

            return {
                defaultTabCode,
                tabCodes,
                tabs,
            };
        },
        [searchTabsReject.toString()]() {
            return {
                tabs: {},
                tabCodes: [],
            };
        },
        [searchTabsSimpleReject.toString()]() {
            return {
                tabs: {},
                tabCodes: [],
            };
        },
    },
    initialState.searchTabs
);

// когда загрузятся с сервера вкладки, сразу ставим дефолтную как активную, но только если до этого ещё не было
// выбрано никакое значение
const defaultTabReducer = (
    state: string,
    { payload }: Action<Store.ISearchTab[]>
): string => {
    if (typeof state === 'string') {
        return state;
    }

    const defaultTabCode = payload[0]?.code;
    if (typeof defaultTabCode === 'string') {
        return defaultTabCode;
    }
    return state;
};

const activeTabReducer = handleActions<string, any>(
    {
        [setSearchActiveTab.toString()](
            _: string,
            { payload }: Action<ISetSearchActiveTabPayload>
        ): string {
            return payload.tabCode;
        },
        [setSearchTabsMeta.toString()]: defaultTabReducer,
    },
    initialState.mainSearchPageActiveTab
);

const activeTabForResultsReducer = handleActions<string, any>(
    {
        [setSearchActiveTabForResults.toString()](
            _: string,
            { payload }: Action<string | null>
        ): string {
            return payload;
        },
        [setSearchTabsMeta.toString()]: defaultTabReducer,
    },
    initialState.mainSearchPageActiveTabForResults
);

export { searchTabsMetaReducer, activeTabReducer, activeTabForResultsReducer };
