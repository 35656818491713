import React, { useCallback, useEffect, useMemo, useState } from 'react';
import numeral from 'numeral';
import classNames from 'classnames';
import { usePrevious } from 'react-use';

import {
    CatalogueDocuments,
    CatalogueDocumentsNew,
} from 'app/components/catalogue/CatalogueDocuments';
import { DocumentSnippetCategoryLinkTargetContext } from 'app/components/search-snippets/DocumentSnippet';
import * as Store from 'app/redux/store/StoreNamespace';

import IProps from './interfaces/ICatalogueDocumentsPanelProps';
import * as styles from './CatalogueDocumentsPanel.scss';

const enum DocumentsList {
    All = 'all',
    New = 'new',
}

const CatalogueDocumentsPanel: React.FC<IProps> = (props) => {
    const {
        getLoadDocumentsAction,
        currentCategory,
        activeCategory,
        showNewDocuments,
        categories,
        loadDocuments,
        renderSortingPanel,
        setSorting,
        resetPaging,
    } = props;
    const prevActiveCategory = usePrevious(activeCategory);
    const documentsCount = currentCategory.countCat;
    const documentsNewCount = currentCategory.countUnverified;

    const [activeTab, setActiveTab] = useState(DocumentsList.All);

    useEffect(() => {
        if (prevActiveCategory !== activeCategory) {
            // при смене выбранной категории надо загрузить обе вкладки с документами, чтобы правильно отразить количество документов.
            // ниже в _loadDocuments загружается текущая выбранная вкладка, а здесь наоборот не выбранная
            resetPaging(activeTab !== DocumentsList.New);
            loadDocuments(activeCategory, activeTab !== DocumentsList.New);
        }
    }, [
        activeCategory,
        activeTab,
        prevActiveCategory,
        loadDocuments,
        resetPaging,
    ]);

    const documentsCountFormatted = useMemo(
        () =>
            typeof documentsCount === 'number'
                ? numeral(documentsCount).format('0,0').replace(/,/g, ' ')
                : undefined,
        [documentsCount]
    );
    const documentsNewCountFormatted = useMemo(
        () =>
            typeof documentsNewCount === 'number'
                ? numeral(documentsNewCount).format('0,0').replace(/,/g, ' ')
                : undefined,
        [documentsNewCount]
    );

    const _getLoadDocumentsAction = useCallback(
        () =>
            getLoadDocumentsAction(
                activeCategory,
                activeTab === DocumentsList.New
            ),
        [activeCategory, activeTab, getLoadDocumentsAction]
    );

    const _setSorting = useCallback(
        (key: string, order: Store.SortingOrder) => {
            setSorting(
                key,
                order,
                activeCategory,
                activeTab === DocumentsList.New
            );
        },
        [setSorting, activeTab, activeCategory]
    );

    const _resetPaging = useCallback(() => {
        resetPaging(activeTab === DocumentsList.New);
    }, [activeTab, resetPaging]);

    const DocumentsComponent =
        activeTab === DocumentsList.All
            ? CatalogueDocuments
            : CatalogueDocumentsNew;
    const documentComponentProps =
        activeTab === DocumentsList.All ? {} : { categories };
    const currentTabDocumentCount =
        activeTab === DocumentsList.All ? documentsCount : documentsNewCount;

    return (
        <article
            className={classNames(styles.catalogueDocumentsPanel, {
                [styles.catalogueDocumentsPanelWithNewDocuments]:
                    showNewDocuments,
                [styles.catalogueDocumentsPanelNewDocumentsOpen]:
                    activeTab === DocumentsList.New,
            })}
        >
            <div className={styles.catalogueDocumentsPanelHeader}>
                <div>
                    {!showNewDocuments && (
                        <div
                            className={classNames(
                                styles.catalogueDocumentsPanelToggleLists
                            )}
                        >
                            <span
                                className={styles.catalogueDocumentsPanelTitle}
                            >
                                Все документы
                            </span>
                            <span
                                className={styles.catalogueDocumentsPanelNumber}
                            >
                                {documentsCountFormatted}
                            </span>
                        </div>
                    )}
                    {showNewDocuments && (
                        <>
                            <button
                                onClick={() => setActiveTab(DocumentsList.All)}
                                className={classNames(
                                    'btn',
                                    styles.catalogueDocumentsPanelToggleLists,
                                    {
                                        [styles.catalogueDocumentsPanelToggleListsActive]:
                                            activeTab === DocumentsList.All,
                                    }
                                )}
                            >
                                <span
                                    className={
                                        styles.catalogueDocumentsPanelTitle
                                    }
                                >
                                    Все документы
                                </span>
                                <span
                                    className={
                                        styles.catalogueDocumentsPanelNumber
                                    }
                                >
                                    {documentsCountFormatted}
                                </span>
                            </button>
                            <button
                                onClick={() => setActiveTab(DocumentsList.New)}
                                className={classNames(
                                    'btn',
                                    styles.catalogueDocumentsPanelToggleLists,
                                    {
                                        [styles.catalogueDocumentsPanelToggleListsActive]:
                                            activeTab === DocumentsList.New,
                                    }
                                )}
                            >
                                <span
                                    className={
                                        styles.catalogueDocumentsPanelTitle
                                    }
                                >
                                    Только новые
                                </span>
                                <span
                                    className={
                                        styles.catalogueDocumentsPanelNumber
                                    }
                                >
                                    {documentsNewCountFormatted}
                                </span>
                            </button>
                        </>
                    )}
                </div>
                <div>
                    {typeof currentTabDocumentCount === 'number' &&
                        currentTabDocumentCount > 0 &&
                        renderSortingPanel(_setSorting)}
                </div>
            </div>
            <DocumentSnippetCategoryLinkTargetContext.Provider
                value={undefined}
            >
                <DocumentsComponent
                    fetchBookmarkedResults={_getLoadDocumentsAction}
                    beforeFetchOnChange={_resetPaging}
                    activeCategoryId={activeCategory}
                    noDocumentsPlaceholder={
                        currentCategory.subItems.length > 0
                            ? 'В категории нет связанных документов. Перейдите к дочерним категориям'
                            : 'В категории нет связанных документов'
                    }
                    {...documentComponentProps}
                />
            </DocumentSnippetCategoryLinkTargetContext.Provider>
        </article>
    );
};

export default CatalogueDocumentsPanel;
