/**
 * Created by Lkarmelo on 28.08.2017.
 */

import { Observable } from 'rxjs/Observable';

import { showLoadingError, setErrorActionCreators } from '../actions/loading';

export const showAjaxErrorMessage = (action$) =>
    action$
        .ofType(...setErrorActionCreators.map((aC) => aC.toString()))
        .filter((action) => action.error && action.payload.status !== 401)
        .mergeMap((action) => Observable.of(showLoadingError(action.meta)));
