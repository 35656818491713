/**
 * Created by Lkarmelo on 24.05.2018.
 */

import { connect } from 'react-redux';
import moment from 'moment';

import {
    initForm,
    removeForm,
    sendForm,
    changeField,
    changeSubField,
    removeSubField,
    addSubField,
    setFormStatus,
} from 'app/redux/actions/forms';
import { fetchCompetencies } from 'app/redux/actions/competencies';
import { fetchEducationMaterialTypes } from 'app/redux/actions/educationMaterialTypes';
import { knowledgeCategoriesToOptions } from 'app/redux/selectors/knowledgeCategoriesToOptions';
import { simpleCatalogItemsToOptions } from 'app/redux/selectors/simpleCatalogItemsToOptions';
import { FormName } from 'app/api/FormName';
import {
    IAddDocumentStateProps as IStateProps,
    IAddDocumentActionProps as IActionProps,
} from 'app/components/document-management/AddDocument/interfaces/IAddDocumentProps';
import * as Store from 'app/redux/store/StoreNamespace';
import history from 'app/history';
import clientRoutes from 'app/routing/clientRoutes';

import IFormData from './interfaces/IAddDocumentFormData';
import AddDocument from './AddDocument';

const mapStateToProps = () => {
    const allDocumentClasses = [
        { label: 'Обучающий материал', value: '1241453141415215' },
    ];

    return (state: Store.IState): IStateProps => {
        const formData: IFormData =
            <IFormData>(
                (state.forms[FormName.AddNewDocument] &&
                    state.forms[FormName.AddNewDocument].data)
            ) || <IFormData>{};
        const formStatus =
            state.forms[FormName.AddNewDocument] &&
            state.forms[FormName.AddNewDocument].status;
        return {
            status: formStatus,
            isDisabled: state.loading.pendingRequests[FormName.AddNewDocument],
            documentClass: formData.documentClass,
            type: formData.type,
            title: formData.title,
            author: formData.author,
            org: formData.org,
            source: formData.source,
            date: formData.date,
            description: formData.description,
            category: formData.category,
            competencies: formData.competencies,
            allCategories: knowledgeCategoriesToOptions(
                state.catalogueCategories
            ),
            allDocumentClasses,
            allDocumentTypes: simpleCatalogItemsToOptions(
                state.educationMaterialTypes,
                'code'
            ),
            allCompetencies: state.competencies,
        };
    };
};

const mapDispatchToProps = (dispatch): IActionProps => ({
    onMount() {
        dispatch(fetchCompetencies());
        dispatch(fetchEducationMaterialTypes());
        dispatch(
            initForm(FormName.AddNewDocument, {
                documentClass: '1241453141415215',
                type: '',
                title: '',
                author: '',
                source: '',
                date: moment(new Date()).valueOf(),
                category: [],
                competencies: [],
            })
        );
    },
    onUnmount() {
        dispatch(removeForm(FormName.AddNewDocument));
    },
    onValidationFail() {
        dispatch(
            setFormStatus(FormName.AddNewDocument, Store.FormStatus.Invalid)
        );
    },
    onValidationSuccess() {
        dispatch(
            setFormStatus(FormName.AddNewDocument, Store.FormStatus.Editing)
        );
    },
    onSend() {
        dispatch(sendForm(FormName.AddNewDocument, null));
    },
    onChangeField(fieldName: string, value: string) {
        dispatch(changeField(FormName.AddNewDocument, fieldName, value));
    },
    onSelect(fieldName: string, value: string) {
        dispatch(addSubField(FormName.AddNewDocument, fieldName, value, null));
    },
    onDeSelect(fieldName: string, value: string) {
        dispatch(
            removeSubField(FormName.AddNewDocument, fieldName, null, value)
        );
    },
    onAddCompetence(
        fieldName: string,
        competence: Store.ISimpleCatalogItem
    ): void {
        dispatch(
            addSubField(
                FormName.AddNewDocument,
                fieldName,
                { competenceItem: competence, grade: 0 },
                null
            )
        );
    },
    onRemoveCompetence(fieldName: string, index: number): void {
        dispatch(
            removeSubField(
                FormName.AddNewDocument,
                fieldName,
                index.toString(),
                null
            )
        );
    },
    onChangeCompetence(
        fieldName: string,
        index: number,
        competence: Store.ISimpleCatalogItem
    ): void {
        const nextValue: Store.IUserCompetence = { competenceItem: competence };
        nextValue.grade = 0;
        dispatch(
            changeSubField(
                FormName.AddNewDocument,
                fieldName,
                index.toString(),
                nextValue
            )
        );
    },
    onChangeCompetenceGrade(
        fieldName: string,
        index: number,
        competence: Store.IUserCompetence,
        grade: number
    ): void {
        const nextValue: Store.IUserCompetence = { ...competence };
        nextValue.grade = grade;
        dispatch(
            changeSubField(
                FormName.AddNewDocument,
                fieldName,
                index.toString(),
                nextValue
            )
        );
    },
    onFormSaved(): void {
        history.push(clientRoutes.documentManagement.subRoutes.all.getUrl());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDocument);
