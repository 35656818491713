/**
 * Created by Lkarmelo on 23.08.2017.
 */

import { createAction } from 'redux-actions';

import * as Store from '../../store/StoreNamespace';

export const fetchFiltersMeta = createAction('FETCH_FILTERS_META');
export const setFiltersMeta =
    createAction<Store.IFiltersMeta>('SET_FILTERS_META');

export interface IUpdateFiltersMetaPayload {
    [filterName: string]: Store.IFilterMetaValue[];
}
export const replaceFiltersMetaValues = createAction<IUpdateFiltersMetaPayload>(
    'UPDATE_FILTERS_META_VALUES'
);

export const fetchFavoritesFiltersMeta = createAction(
    'FETCH_FAVORITES_FILTERS_META'
);
export const setFavoritesFiltersMeta = createAction<Store.IFiltersMeta>(
    'SET_FAVORITES_FILTERS_META'
);
