/**
 * Created by Lkarmelo on 22.03.2018.
 */

import React, { PureComponent } from 'react';
import autoBind from 'autobind-decorator';
import classNames from 'classnames';
import Modal from 'antd/es/modal/Modal';

import Form from 'app/components/common/forms/Form';
import { PASSWORD_REG_EXP, PASSWORD_MIN_LENGTH } from 'app/utils/constants';

import IProps, { PassFieldName } from './interfaces/IChangePasswordProps';
import IState from './interfaces/IChangePasswordState';
import * as styles from './ChangePassword.scss';

@autoBind
export default class ChangePassword extends PureComponent<IProps, IState> {
    static defaultProps = {
        passwords: {
            [PassFieldName.oldPass]: '',
            [PassFieldName.newPass]: '',
            [PassFieldName.newPassRep]: '',
        },
    };

    static passValidators = [
        (val: string) => ({
            isValid: !!val,
            message: 'Поле не должно быть пустым',
        }),
        (val: string) => ({
            isValid: !!val && PASSWORD_REG_EXP.test(val),
            message: 'Пароль не должен содержать в себе пробелов',
        }),
        (val: string) => ({
            isValid: !!val && val.length >= PASSWORD_MIN_LENGTH,
            message: `Пароль не должен быть короче ${PASSWORD_MIN_LENGTH} символов`,
        }),
    ];

    state = {
        isSaveAttempted: false,
        hasPasswordSuccessfullyChanged: false,
        isFormValidationFailedOnSave: false,
    };

    formRef: React.RefObject<typeof Form.prototype> = React.createRef();

    render(): JSX.Element {
        const { status, passwords, isVisible } = this.props;
        const { newPass, oldPass, newPassRep } = passwords;
        const { isSaveAttempted, hasPasswordSuccessfullyChanged } = this.state;
        const errorMessage = this.getErrorMessage();

        return (
            <Modal
                maskClosable={false}
                visible={isVisible}
                footer={null}
                className={classNames(
                    `${styles.changePassword} modal`,
                    {
                        [styles.changePasswordNoMatch]:
                            isSaveAttempted && newPass !== newPassRep,
                    },
                    {
                        [styles.changePasswordChangeSuccess]:
                            hasPasswordSuccessfullyChanged,
                    }
                )}
                onCancel={this.onCancel}
                afterClose={this.onAfterModalClose}
            >
                <div className={styles.changePasswordTitle}>Смена пароля</div>
                <div className={styles.changePasswordChangeSuccessMessage}>
                    Пароль успешно изменён
                </div>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Form
                        ref={this.formRef}
                        activateValidationForAllFields={
                            this.state.isFormValidationFailedOnSave
                        }
                        status={status}
                        validation={{
                            [PassFieldName.oldPass]:
                                ChangePassword.passValidators,
                            [PassFieldName.newPass]:
                                ChangePassword.passValidators,
                            [PassFieldName.newPassRep]:
                                ChangePassword.passValidators,
                        }}
                        fields={{
                            [PassFieldName.oldPass]: {
                                type: 'hidden-text',
                                value: oldPass,
                                onTextChange: this.onPassChange,
                                meta: { fieldName: PassFieldName.oldPass },
                                placeholder: 'Текущий пароль',
                                title: '',
                                fieldCssClass: classNames(
                                    styles.changePasswordOldPass,
                                    {
                                        [styles.changePasswordPassFieldNotFilled]:
                                            isSaveAttempted && !oldPass,
                                    }
                                ),
                                autoComplete: 'current-password',
                            },
                            [PassFieldName.newPass]: {
                                type: 'hidden-text',
                                value: newPass,
                                onTextChange: this.onPassChange,
                                meta: { fieldName: PassFieldName.newPass },
                                placeholder: 'Новый пароль',
                                title: '',
                                fieldCssClass: classNames(
                                    styles.changePasswordNewPass,
                                    {
                                        [styles.changePasswordPassFieldNotFilled]:
                                            isSaveAttempted && !newPass,
                                    }
                                ),
                                autoComplete: 'new-password',
                            },
                            [PassFieldName.newPassRep]: {
                                type: 'hidden-text',
                                value: newPassRep,
                                onTextChange: this.onPassChange,
                                meta: { fieldName: PassFieldName.newPassRep },
                                placeholder: 'Повтор нового пароля',
                                title: '',
                                fieldCssClass: classNames(
                                    styles.changePasswordNewPassRep,
                                    {
                                        [styles.changePasswordPassFieldNotFilled]:
                                            isSaveAttempted && !newPassRep,
                                    }
                                ),
                                autoComplete: 'new-password',
                            },
                        }}
                        onFormSaved={this.onSaved}
                    />
                    <div className={styles.changePasswordErrorMessage}>
                        {errorMessage}
                    </div>
                    <div className={styles.changePasswordControls}>
                        <button onClick={this.onSave} className="btn-ok">
                            <span>Сохранить пароль</span>
                        </button>
                        <button
                            onClick={this.onCancel}
                            className="btn-cancel"
                            type="button"
                        >
                            Отменить
                        </button>
                    </div>
                </form>
            </Modal>
        );
    }

    componentWillUpdate(nextProps: IProps): void {
        if (nextProps.isVisible && !this.props.isVisible) {
            this.props.onShown();
        }
    }

    getErrorMessage(): string {
        const { isSaveAttempted, isFormValidationFailedOnSave } = this.state;
        const { error, passwords } = this.props;
        if (!isSaveAttempted && !error) {
            return null;
        }
        if (!passwords.newPassRep || !passwords.newPass || !passwords.oldPass) {
            return 'Не все поля заполнены';
        }
        if (passwords.newPassRep !== passwords.newPass) {
            return 'Введенные пароли не совпадают';
        }
        if (isFormValidationFailedOnSave) {
            return 'Форма заполнена некорректно';
        }
        if (error) {
            return error;
        }
    }

    onSaved(): void {
        this.setState({ hasPasswordSuccessfullyChanged: true });
        setTimeout(() => {
            if (this.props.isVisible) this.onCancel();
        }, 1500);
    }

    onPassChange(
        value: string,
        { fieldName }: { fieldName: PassFieldName }
    ): void {
        const { onChangePass, error, removeErrorMessage } = this.props;
        if (error) {
            removeErrorMessage();
        }
        onChangePass(fieldName, value);
    }

    onSave(): void {
        const { passwords, onSavePass } = this.props;
        const { isFormValidationFailedOnSave, isSaveAttempted } = this.state;
        if (!this.formRef.current.getIsFormValid(true)) {
            if (!isFormValidationFailedOnSave)
                this.setState({ isFormValidationFailedOnSave: true });
            return;
        }
        if (isFormValidationFailedOnSave || !isSaveAttempted) {
            this.setState((state) => ({
                isFormValidationFailedOnSave: state.isFormValidationFailedOnSave
                    ? false
                    : undefined,
                isSaveAttempted: !state.isSaveAttempted ? true : undefined,
            }));
        }
        onSavePass(passwords);
    }

    onCancel(): void {
        this.props.onCancel();
        this.props.onHidden();
        this.setState({ isSaveAttempted: false });
    }

    onAfterModalClose(): void {
        this.setState({
            hasPasswordSuccessfullyChanged: false,
            isFormValidationFailedOnSave: false,
        });
        this.formRef.current.activeFieldsForValidation = {};
    }
}
