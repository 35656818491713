import { useMemo } from 'react';

import { ISelectOption } from '../Select/TreeSelect';
import { getDepthFirstSearcher } from '../../../utils/depthFirstSearcher';

const traverseOptions = getDepthFirstSearcher('options');

/**
 * Возвращает две мапы:
 * - descendantsMap - для каждой опции массив всех потомков, то есть не просто дочерние элементы, а дочерние дочерних и т.д.
 * - ascendantsMap - для каждой опции массив всех родителей. Гарантируется, что последний элемент массива - это прямой родитель опции
 */
export const useDescendantsAscendantsMaps = (options: ISelectOption[]) =>
    useMemo(() => {
        const _descendantsMap: Record<string, ISelectOption[]> = {};
        const _ascendantsMap: Record<string, ISelectOption[]> = {};
        // т.к. мы обходим в глубину, мы можем составить цепочку родительских элементов текущего элемента
        let currentParentChain: ISelectOption[] = [];

        traverseOptions(
            options,
            (option: ISelectOption, parentOption: ISelectOption) => {
                if (!Array.isArray(_descendantsMap[option.value])) {
                    _descendantsMap[option.value] = [];
                }
                if (!Array.isArray(_ascendantsMap[option.value])) {
                    _ascendantsMap[option.value] = [];
                }
                if (!parentOption) {
                    currentParentChain = [];
                    return;
                }
                const parentInChainIndex = currentParentChain.findIndex(
                    ({ value }) => value === parentOption.value
                );
                // если текущий parentOption встретился между краёв в currentParentChain, то значит мы вернулись вверх
                // по иерархии и нужно убрать крайние не актуальные parent values
                if (
                    parentInChainIndex >= 0 &&
                    parentInChainIndex !== currentParentChain.length - 1
                ) {
                    currentParentChain = currentParentChain.slice(
                        0,
                        parentInChainIndex + 1
                    );
                } else if (parentInChainIndex < 0) {
                    currentParentChain.push(parentOption);
                }

                currentParentChain.forEach(({ value: parentVal }) => {
                    // всем родителям в список потомков добавляем текущую опцию
                    if (Array.isArray(_descendantsMap[parentVal])) {
                        _descendantsMap[parentVal].push(option);
                    } else {
                        _descendantsMap[parentVal] = [option];
                    }
                });

                // можно просто присвоить опции parent chain
                _ascendantsMap[option.value] = currentParentChain.slice();
            }
        );
        return {
            descendantsMap: _descendantsMap,
            ascendantsMap: _ascendantsMap,
        };
    }, [options]);
