/**
 * Created by Lkarmelo on 21.11.2017.
 */

import { handleActions } from 'redux-actions';

import { SET_COMPETENCIES } from '../actions/competencies';
import * as Store from '../store/StoreNamespace';

export default handleActions(
    {
        [SET_COMPETENCIES](
            state: Store.ISimpleCatalogItem[],
            { payload }: { payload: Store.ISimpleCatalogItem[]; type: string }
        ) {
            return payload;
        },
    },
    [] // - default value for competencies
);
