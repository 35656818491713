/**
 * Created by lkarmelo on 05.07.2019.
 */

import { createAction } from 'redux-actions';

export const setIgnoreMistakes = createAction<boolean>('SET_IGNORE_MISTAKES');

export const setLastExecutedIgnoreMistakes = createAction<boolean>(
    'SET_LAST_EXECUTED_IGNORE_MISTAKES'
);
