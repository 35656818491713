/**
 * Created by Lkarmelo on 05.09.2017.
 */

import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedComponent } from 'react-redux';

import { RoutingFeaturesName } from 'app/features/RoutingFeaturesName';
import Search from 'app/components/search/Search';
import ObjectCard from 'app/components/object-card/ObjectCard';
import Login, { RecoveryPassword, ResetPassword } from 'app/components/Login';
import DocumentRedirect from 'app/components/document-redirect';
import RouteWithLayout from 'app/components/RouteWithLayout';
import RouteWithSubRoutes from 'app/components/RouteWithSubRoutes';
import withRedirectUnauthorized, {
    redirectAuthorized,
} from 'app/components/high-order/redirectUnauthorized';
import Notifications from 'app/components/notifications/Notifications';
import Redirect from 'app/components/redirect';

import clientRoutes from './clientRoutes';

import {
    DefaultLayout,
    LoginLayout,
    SearchLayout,
} from '../components/layouts';
import { withSearchTabsLoadingScreen } from '../components/high-order/withLoading';
import { BuildInfo } from '../components/Build-info/BuildInfo';

const AuthRoute = withRedirectUnauthorized(Route);
const AuthRouteWithLayout = withRedirectUnauthorized(RouteWithLayout);
const UnauthRoute = redirectAuthorized(Route);
const UnauthRouteWithLayout = redirectAuthorized(RouteWithLayout);

interface IConfigItem {
    layout?: React.ComponentType;
    path: string;
    // TODO Delete ConnectedComponent after migration to hooks
    component: React.ComponentType | ConnectedComponent<any, any>;
    options?: {
        exact?: boolean;
    };
    authOnly?: boolean;
    unauthOnly?: boolean;
    subRoutes?: IConfigItem[];
}

const config: IConfigItem[] = [
    {
        layout: withSearchTabsLoadingScreen(SearchLayout),
        path: clientRoutes.search.reactRouterTemplate,
        component: Search,
        options: {
            exact: true,
        },
        authOnly: true,
    },
    {
        layout: DefaultLayout,
        path: clientRoutes.objectCard.reactRouterTemplate,
        component: ObjectCard,
        authOnly: true,
    },
    {
        layout: DefaultLayout,
        path: clientRoutes.analysis.reactRouterTemplate,
        component: null,
        authOnly: true,
    },
    {
        layout: DefaultLayout,
        path: clientRoutes.admin.reactRouterTemplate,
        component: null,
        authOnly: true,
    },
    {
        layout: LoginLayout,
        path: clientRoutes.login.reactRouterTemplate,
        component: Login,
        authOnly: false,
    },
    {
        layout: LoginLayout,
        path: clientRoutes.resetPassword.reactRouterTemplate,
        component: ResetPassword,
        unauthOnly: true,
    },
    {
        layout: LoginLayout,
        path: clientRoutes.recoveryPassword.reactRouterTemplate,
        component: RecoveryPassword,
        unauthOnly: true,
    },
    {
        path: clientRoutes.documentRedirect.reactRouterTemplate,
        component: DocumentRedirect,
    },
    {
        path: clientRoutes.buildInfo.reactRouterTemplate,
        component: BuildInfo,
    },
    {
        layout: DefaultLayout,
        path: clientRoutes.notifications.reactRouterTemplate,
        component: Notifications,
        authOnly: true,
    },
    {
        path: clientRoutes.redirect.reactRouterTemplate,
        component: Redirect,
    },
];

if (APP_FEATURES.routing[RoutingFeaturesName.Account]) {
    const Account = require('app/components/account/Account').default;

    config.push({
        layout: DefaultLayout,
        path: clientRoutes.account.reactRouterTemplate,
        component: Account,
        authOnly: true,
        subRoutes: [],
    });

    if (APP_FEATURES.routing[RoutingFeaturesName.Profile]) {
        const Profile = require('app/components/account/Profile').default;

        config[config.length - 1].subRoutes.push({
            path: clientRoutes.account.subRoutes.profile.reactRouterTemplate,
            component: Profile,
        });
    }

    if (APP_FEATURES.routing[RoutingFeaturesName.Favorite]) {
        const { Favorite } = require('app/components/account/DocumentPanels');

        config[config.length - 1].subRoutes.push({
            path: clientRoutes.account.subRoutes.favorite.reactRouterTemplate,
            component: Favorite,
        });
    }

    if (APP_FEATURES.routing[RoutingFeaturesName.Education]) {
        const { Education } = require('app/components/account/DocumentPanels');

        config[config.length - 1].subRoutes.push({
            path: clientRoutes.account.subRoutes.education.reactRouterTemplate,
            component: Education,
        });
    }

    if (APP_FEATURES.routing[RoutingFeaturesName.Related]) {
        const {
            RelatedDocuments,
        } = require('app/components/account/DocumentPanels');

        config[config.length - 1].subRoutes.push({
            path: clientRoutes.account.subRoutes.related.reactRouterTemplate,
            component: RelatedDocuments,
        });
    }

    if (APP_FEATURES.routing[RoutingFeaturesName.ReadLater]) {
        const { Pending } = require('app/components/account/DocumentPanels');

        config[config.length - 1].subRoutes.push({
            path: clientRoutes.account.subRoutes.pending.reactRouterTemplate,
            component: Pending,
        });
    }

    if (APP_FEATURES.routing[RoutingFeaturesName.UserFiles]) {
        const { UserFiles } = require('app/components/account/UserFiles');

        config[config.length - 1].subRoutes.push({
            path: clientRoutes.account.subRoutes.files.reactRouterTemplate,
            component: UserFiles,
        });
    }

    if (APP_FEATURES.routing[RoutingFeaturesName.UploadDocument]) {
        const { MyDocument } = require('app/components/account/DocumentPanels');

        config[config.length - 1].subRoutes.push({
            path: clientRoutes.account.subRoutes.myDocument.reactRouterTemplate,
            component: MyDocument,
        });
    }

    if (APP_FEATURES.routing[RoutingFeaturesName.Subscribe]) {
        const { Subscribe } = require('app/components/account/DocumentPanels');

        config[config.length - 1].subRoutes.push({
            path: clientRoutes.account.subRoutes.subscribe.reactRouterTemplate,
            component: Subscribe,
        });
    }
}

if (APP_FEATURES.routing[RoutingFeaturesName.Catalogue]) {
    const Catalogue = require('app/components/catalogue/Catalogue').default;

    config.push({
        layout: DefaultLayout,
        path: clientRoutes.catalogue.reactRouterTemplate,
        component: Catalogue,
        authOnly: true,
    });
}

if (APP_FEATURES.routing[RoutingFeaturesName.DocumentManagement]) {
    const Recommendations =
        require('app/components/document-management/Recommendations').default;
    const AddedDocuments =
        require('app/components/document-management/AddedDocuments').default;
    const AllDocuments =
        require('app/components/document-management/AllDocuments').default;
    const OwnDocuments =
        require('app/components/document-management/OwnDocuments').default;
    const AddDocument =
        require('app/components/document-management/AddDocument').default;
    const EditDocument =
        require('app/components/document-management/EditDocument').default;
    // эти пути должены идти раньше, потому что все пути у нас обёрнуты в <Switch> и если поместить их
    // после следующего, то вместо нужного компонента будет рендериться AddedDocuments
    config.push({
        layout: DefaultLayout,
        path: clientRoutes.documentManagement.subRoutes.add.reactRouterTemplate,
        component: AddDocument,
        authOnly: true,
    });
    config.push({
        layout: DefaultLayout,
        path: clientRoutes.documentManagement.subRoutes.edit
            .reactRouterTemplate,
        component: EditDocument,
        authOnly: true,
    });

    config.push({
        layout: DefaultLayout,
        path: clientRoutes.documentManagement.reactRouterTemplate,
        component: AddedDocuments,
        authOnly: true,
        subRoutes: [
            {
                path: clientRoutes.documentManagement.subRoutes.own
                    .reactRouterTemplate,
                component: OwnDocuments,
            },
            {
                path: clientRoutes.documentManagement.subRoutes.recommendations
                    .reactRouterTemplate,
                component: Recommendations,
            },
            {
                path: clientRoutes.documentManagement.subRoutes.all
                    .reactRouterTemplate,
                component: AllDocuments,
            },
        ],
    });
}

const renderRoutesComponents = (cfg: IConfigItem[]): JSX.Element => {
    return (
        <Switch>
            {cfg.map((conf) => {
                const props = {
                    key: `route-${conf.path}`,
                    layout: conf.layout,
                    path: conf.path,
                    component: conf.component,
                    subRoutes:
                        conf.subRoutes &&
                        renderRoutesComponents(conf.subRoutes),
                    ...conf.options,
                };

                if (conf.unauthOnly) {
                    return conf.layout ? (
                        <UnauthRouteWithLayout {...props} />
                    ) : (
                        <UnauthRoute {...props} />
                    );
                }

                if (conf.authOnly) {
                    return conf.layout ? (
                        <AuthRouteWithLayout {...props} />
                    ) : (
                        <AuthRoute {...props} />
                    );
                }
                return conf.layout ? (
                    <RouteWithLayout {...props} />
                ) : (
                    <RouteWithSubRoutes {...props} />
                );
            })}
        </Switch>
    );
};

export default renderRoutesComponents(config);
