import React, { useCallback, useMemo } from 'react';

import Sidebox, { useSideboxHelper } from 'app/components/common/Sidebox';
import InfoTooltip from 'app/components/common/InfoTooltip';

import * as styles from './SearchConcepts.scss';

import * as Store from '../../../../redux/store/StoreNamespace';
import ConceptsPanel from '../ConceptsPanel/ConceptsPanel';
import { CONCEPTS_HINT_TEXT } from '../../../../utils/constants';

export interface ISearchConceptsStateProps {
    selectState: Record<string, boolean>;
    initialSelectState: Record<string, boolean>;
    concepts: Record<string, Store.IConceptWithRelations>;
    extractedConcepts: string[];
}

export interface ISearchConceptsActionProps {
    applyConceptSelection(selectedConcepts: Record<string, boolean>): void;
    clearConceptSelection(): void;
}

export interface IConceptsPanelProps
    extends ISearchConceptsActionProps,
        ISearchConceptsStateProps {}

const SearchConcepts: React.FC<IConceptsPanelProps> = (props) => {
    const {
        concepts,
        extractedConcepts,
        selectState,
        initialSelectState,
        applyConceptSelection,
        clearConceptSelection,
    } = props;

    const [openSidebox, closeSidebox, sideboxOpened] = useSideboxHelper();

    const currentSelectState =
        Object.keys(selectState).length > 0 ? selectState : initialSelectState;
    const edited = currentSelectState === selectState;

    const selectedCount = useMemo(
        () =>
            Object.values(currentSelectState).reduce((count, isSelected) => {
                if (isSelected) {
                    count++;
                }
                return count;
            }, 0),
        [currentSelectState]
    );

    const _applyConceptSelection = useCallback(
        (selection: Record<string, boolean>) => {
            closeSidebox();
            applyConceptSelection(selection);
        },
        [applyConceptSelection, closeSidebox]
    );

    if (!extractedConcepts || extractedConcepts.length === 0) {
        return null;
    }

    return (
        <div className={styles.searchConcepts}>
            <div className={styles.searchConceptsHeadingContainer}>
                <div className={styles.searchConceptsHeader}>
                    Концепты
                    <InfoTooltip
                        className={styles.searchConceptsHint}
                        message={CONCEPTS_HINT_TEXT}
                    />
                </div>
                {edited && (
                    <button
                        onClick={clearConceptSelection}
                        className={`btn ${styles.searchConceptsClear}`}
                    >
                        <span className={styles.searchConceptsClearCross} />
                        Сбросить
                    </button>
                )}
            </div>
            {concepts && (
                <div className={styles.searchConceptsCountContainer}>
                    Найдено концептов: {Object.keys(concepts).length}
                </div>
            )}
            <div className={styles.searchConceptsCountContainer}>
                <div className={styles.searchConceptsConceptsSelected}>
                    Выбрано концептов: {selectedCount}
                </div>
                {edited && (
                    <div className={styles.searchConceptsEdited}>Изменено</div>
                )}
            </div>
            <button
                className={`btn ${styles.searchConceptsOpenBtn}`}
                onClick={openSidebox}
            >
                Выбрать
            </button>
            <Sidebox
                opened={sideboxOpened}
                onOutsideContentClick={closeSidebox}
                className={styles.searchConceptsSidebox}
                onCloseButtonClick={closeSidebox}
                showCloseButton
            >
                <ConceptsPanel
                    concepts={concepts}
                    mainConceptList={extractedConcepts}
                    selectedConcepts={currentSelectState}
                    applyConceptSelection={_applyConceptSelection}
                />
            </Sidebox>
        </div>
    );
};

export default SearchConcepts;
