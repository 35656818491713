/**
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 16.01.2018
 * -----
 * Last Modified: 07.07.2018 16:11:43
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import { MiddlewareAPI } from 'redux';
import { Observable } from 'rxjs/Observable';
import { concat } from 'rxjs/observable/concat';
import { empty } from 'rxjs/observable/empty';
import { Action } from 'redux-actions';

import { createRetryOnIEAuthProblem } from './utils/createRetryOnIEAuthProblem';

import { SEND_FAVORITE, SET_FAVORITE, setFavorite } from '../actions/favorites';
import {
    sendFavoriteReject,
    sendFavoriteRequest,
    sendFavoriteResolve,
} from '../actions/loading';
import { updateSearchResult } from '../actions/search/results';
import * as Store from '../store/StoreNamespace';
import { withContext } from '../context/connectWithContext';
import { searchResultDocumentList } from '../actions/interfaces/DocumentEnum';

export const sendFavoriteMark = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }
) =>
    action$
        .ofType(SEND_FAVORITE)
        .mergeMap((action: Action<Store.IMarkDocument>) =>
            concat(
                (action.payload.mark
                    ? apiCall.markFavorites(action.payload.documentId)
                    : apiCall.unmarkFavorites(action.payload.documentId)
                )
                    .retryWhen(createRetryOnIEAuthProblem())
                    .map((response) => setFavorite(action.payload))
                    .catch((e) => {
                        console.error(e);
                        return (!action.payload.mark && e.status === 400) ||
                            (action.payload.mark && e.status === 500)
                            ? concat(
                                  Observable.of(setFavorite(action.payload)),
                                  Observable.of(sendFavoriteReject(e))
                              )
                            : Observable.of(sendFavoriteReject(e));
                    })
                    .takeUntil(action$.ofType(SEND_FAVORITE)),
                Observable.of(sendFavoriteResolve())
            )
                .takeUntil(action$.ofType(sendFavoriteReject.toString()))
                .startWith(sendFavoriteRequest())
        );

export const updateFavoriteMark = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }
) =>
    action$
        .ofType(SET_FAVORITE)
        .mergeMap((action: Action<Store.IMarkDocument>) => {
            const updateAtom: Store.IUpdateDocumentSnippet = {
                id: action.payload.documentId,
                favorite: action.payload.mark,
            };

            const updateAction = updateSearchResult(updateAtom);
            const updateDocumentActions = searchResultDocumentList.map((key) =>
                Observable.of(withContext(updateAction, key))
            );

            return concat(Observable.of(updateAction), ...updateDocumentActions)
                .catch((e) => {
                    console.error(e);
                    return empty();
                })
                .takeUntil(action$.ofType(SET_FAVORITE));
        });
