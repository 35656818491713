import { MiddlewareAPI } from 'redux';
import { Action } from 'redux-actions';
import { Observable } from 'rxjs/Observable';

import * as Store from 'app/redux/store/StoreNamespace';
import Api from 'app/api/Api';
import {
    confirmDocumentCategoryReject,
    confirmDocumentCategoryRequest,
    confirmDocumentCategoryResolve,
    revertDocumentCategoryRequest,
    revertDocumentCategoryResolve,
    revertDocumentCategoryReject,
} from 'app/redux/actions/loading';

import {
    confirmDocumentCategory,
    IConfirmDocumentCategoryPayload,
    revertDocumentCategory,
} from '../../actions/catalogue/catalogueDocuments';
import { withContext } from '../../context/connectWithContext';
import { updateSearchResult } from '../../actions/search/results';
import { SearchDocumentName } from '../../actions/interfaces/DocumentEnum';
import { categoryChangeUnverifiedCount } from '../../actions/catalogue/catalogueCategories';

export const requestConfirmCategory = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(confirmDocumentCategory.toString())
        .groupBy(
            ({ payload }: Action<IConfirmDocumentCategoryPayload>) =>
                payload.docId,
            undefined,
            () => Observable.timer(15000)
        )
        .mergeMap((group) =>
            group.exhaustMap(
                ({ payload }: Action<IConfirmDocumentCategoryPayload>) =>
                    apiCall
                        .documentConfirmCategory(payload)
                        .mergeMap(() => {
                            return Observable.of(
                                withContext(
                                    updateSearchResult({
                                        id: payload.docId,
                                        categoryConfirmed: true,
                                    }),
                                    SearchDocumentName.catalogueDocumentsNew
                                ),
                                categoryChangeUnverifiedCount({
                                    categoryId: payload.categoryId,
                                    delta: -1,
                                }),
                                confirmDocumentCategoryResolve()
                            );
                        })
                        .catch((e) => {
                            console.error(e);
                            return Observable.of(
                                confirmDocumentCategoryReject(e)
                            );
                        })
                        .startWith(confirmDocumentCategoryRequest())
            )
        );

export const requestRevertCategory = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(revertDocumentCategory.toString())
        .groupBy(
            ({ payload }: Action<IConfirmDocumentCategoryPayload>) =>
                payload.docId,
            undefined,
            () => Observable.timer(15000)
        )
        .mergeMap((group) =>
            group.exhaustMap(
                ({ payload }: Action<IConfirmDocumentCategoryPayload>) =>
                    apiCall
                        .documentRevertCategory(payload)
                        .mergeMap(() => {
                            const actions = [
                                withContext(
                                    updateSearchResult({
                                        id: payload.docId,
                                        categoryConfirmed: false,
                                    }),
                                    SearchDocumentName.catalogueDocumentsNew
                                ),
                                revertDocumentCategoryResolve(),
                                categoryChangeUnverifiedCount({
                                    categoryId: payload.categoryId,
                                    delta: 1,
                                }),
                            ];

                            return Observable.from(actions);
                        })
                        .catch((e) => {
                            console.error(e);
                            return Observable.of(
                                revertDocumentCategoryReject(e)
                            );
                        })
                        .startWith(revertDocumentCategoryRequest())
            )
        );
