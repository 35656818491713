import * as React from 'react';
import mime from 'mime';
import { ajax } from 'rxjs/observable/dom/ajax';
import { Subscriber } from 'rxjs/Subscriber';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';

import ApiRoute from 'app/routing/Route/ApiRoute';
import prettyBytes from 'app/utils/pretty-bytes';
import * as Store from 'app/redux/store/StoreNamespace';

import IUploadBoxActionProps from './interfaces/IUploadBoxActionProps';

import { MAX_UPLOAD_FILE_SIZE } from '../../../../utils/constants';

const uploadFile = (
    path: string,
    uploadUrl: ApiRoute,
    files: File[],
    progressSubscriber: Subscriber<any>
) => {
    const fd = new FormData();
    fd.append('location', path);
    files.forEach((file, idx) => {
        fd.append(`file${idx}`, file);
    });

    const conf = {
        Accept: 'application/json',
        // cancelToken: CancelCallback.token,
        // onUploadProgress: onProgress
    };

    return ajax({
        url: uploadUrl.getUrl({ path }),
        method: 'POST',
        body: fd,
        async: true,
        headers: conf,
        progressSubscriber,
    });
};

export const handleFileChange = (file: File, props: IUploadBoxActionProps) => {
    const {
        onFileChange,
        onProgress,
        onComplete,
        onSuccess,
        onFailed,
        ajaxProcess,
    } = props;

    const { name: fullFileName, size } = file;

    const name = fullFileName.substring(
        fullFileName.lastIndexOf('/'),
        fullFileName.length
    );
    const fileInfo: Store.IFileMeta = {
        name,
        size,
        fid: name,
        status: Store.FileStateEnum.TmpFile,
        mimeType: mime.getType(name),
    };

    // set file in list
    onFileChange(fileInfo);

    // check file;
    if (fileInfo.size > MAX_UPLOAD_FILE_SIZE) {
        const fileSizeStr = prettyBytes(MAX_UPLOAD_FILE_SIZE, { locale: 'ru' });
        return onFailed(
            `Файл превышает допустимые размеры в ${fileSizeStr}`,
            file
        );
    }

    const progressSubscriber = new Subscriber({
        next: ({ loaded, total }) => {
            // console.log('Загружено на сервер ' + loaded + ' байт из ' + total);

            onProgress(name, { loaded, total });
        },
        complete: () => {
            // console.log('Загрузка завершена');

            onComplete(name);
        },
    });

    uploadFile(
        props.path,
        props.uploadUrl,
        [file],
        progressSubscriber
    ).subscribe({
        next: ({ response }) => onSuccess(ajaxProcess(response)),
        error: (error: AjaxError | string) => {
            const errorMsg =
                typeof error === 'string'
                    ? error
                    : error.status === 0
                    ? 'Нет соединения с сервером.'
                    : error.message;

            onFailed(errorMsg, file);
        },
    });
};

export const filesToArray = (fileListOrFile: File | FileList) => {
    const files = [];

    if (fileListOrFile instanceof FileList) {
        for (let idx = 0; idx < fileListOrFile.length; idx++) {
            files.push(fileListOrFile[idx]);
        }
    } else {
        files.push(fileListOrFile);
    }

    return files;
};

export default (
    WrappedComponent
): React.FunctionComponent<any & IUploadBoxActionProps> =>
    function (props) {
        const {
            onFileChange,
            onProgress,
            onComplete,
            onSuccess,
            onFailed,
            ...wrappedComponentProps
        } = props;

        return (
            <WrappedComponent
                onFileChange={(fileListOrFile: File | FileList) => {
                    const files = filesToArray(fileListOrFile);

                    files.forEach((file) => handleFileChange(file, props));
                }}
                {...wrappedComponentProps}
            />
        );
    };
