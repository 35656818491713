/**
 * Created by Lkarmelo on 18.01.2018.
 */

import * as React from 'react';

import * as styles from './NoRolesScreen.scss';

const NoRolesScreen = () => {
    return (
        <div className={styles.noRolesScreen}>
            <div>
                <div className={styles.noRolesScreenContent}>
                    <div className={styles.noRolesScreenLogo} />
                    <p>
                        У вас нет доступа к данному ресурсу.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default NoRolesScreen;
