/**
 * Created by Lkarmelo on 28.02.2018.
 */

import * as React from 'react';
import classNames from 'classnames';

import MultipleInputFields from 'app/components/common/controls/MultipleInputFields/MultipleInputFields';
import DateInputField from 'app/components/common/controls/DateInputField';
import { DropDown } from 'app/components/common/tables/Filters';
import InputField from 'app/components/common/controls/InputField';
import HiddenInputField from 'app/components/common/controls/HiddenInputField';
import DropDownWithTags from 'app/components/common/tables/Filters/DropDownWithTags';
import CheckBox from 'app/components/common/controls/CheckBox';

import IProps from './interfaces/IFormProps';
import IField from './interfaces/IField';
import IFieldValidation from './interfaces/IFieldValidation';

export function fieldsFactory(props: IProps): JSX.Element[] {
    const { fields } = props;
    return Object.keys(fields).map((fName) => {
        const field = fields[fName];
        switch (field.type) {
            case 'text-multiple': {
                return props.textMultipleFieldRenderer(fName, field, props);
            }
            case 'date': {
                return props.dateFieldRenderer(fName, field, props);
            }
            case 'select': {
                return props.selectFieldRenderer(fName, field, props);
            }
            case 'multiselect': {
                return props.multiSelectFieldRenderer(fName, field, props);
            }
            case 'text': {
                return props.textFieldRenderer(fName, field, props);
            }
            case 'multiselect-tags': {
                return props.multiSelectTagsRenderer(fName, field);
            }
            case 'hidden-text': {
                return props.hiddenTextFieldRenderer(fName, field, props);
            }
            case 'checkbox': {
                return props.checkBoxRenderer(fName, field);
            }
            default:
                return null;
        }
    });
}

export function textMultipleFieldRenderer(
    fName: string,
    field: IField,
    props: IProps
): JSX.Element {
    const {
        value,
        meta,
        onSubFieldChange,
        maxSubFields,
        isRequired,
        addExtraSubFieldButtonText,
        onRemoveExtraSubField,
        onAddExtraSubField,
        subFieldsMetas,
        subFieldTitles,
        isSubFieldDeletable,
        isSubFieldEditable,
    } = field;
    const {
        validationResults,
        setActivateValidationForField,
        validateSingleField,
    } = props;

    return (
        <MultipleInputFields
            isRequired={isRequired}
            key={fName}
            titles={subFieldTitles}
            values={value}
            meta={meta}
            subFieldsMetas={subFieldsMetas}
            addBtnText={addExtraSubFieldButtonText}
            onChange={onSubFieldChange}
            onRemoveField={(
                val: string,
                subFieldMeta?: any,
                inputMeta?: any
            ) => {
                setActivateValidationForField(false, fName, subFieldMeta.index);
                onRemoveExtraSubField(val, subFieldMeta, inputMeta);
            }}
            onAddField={onAddExtraSubField}
            errorMessages={field.value.map(
                (v, i) =>
                    validationResults[fName] &&
                    validationResults[fName][i] &&
                    validationResults[fName][i].messages
            )}
            isDeletable={isSubFieldDeletable}
            isEditable={isSubFieldEditable}
            maxSubFields={maxSubFields}
            onFocus={
                setActivateValidationForField
                    ? ({ index }) => {
                          setActivateValidationForField(true, fName, index);
                          validateSingleField(fName);
                      }
                    : undefined
            }
        />
    );
}

export function dateFieldRenderer(
    fName: string,
    field: IField,
    props: IProps
): JSX.Element {
    const {
        dateDisplayFormat,
        value,
        meta,
        title,
        convertDateIn,
        convertDateOut,
        onDateChange,
        isRequired,
        maxDate,
        minDate,
        fieldCssClass,
    } = field;
    const { validationResults, setActivateValidationForField } = props;

    const changeDate = (date: number) => {
        onDateChange(convertDateOut ? convertDateOut(date) : date, meta);
    };

    return (
        <DateInputField
            isRequired={isRequired}
            key={fName}
            value={convertDateIn ? convertDateIn(value) : value}
            dateDisplayFormat={dateDisplayFormat}
            title={title}
            onChange={changeDate}
            meta={meta}
            cssClass={classNames({
                invalid: !(validationResults[fName] as IFieldValidation),
            })}
            className={fieldCssClass}
            errorMessages={
                validationResults[fName] &&
                (validationResults[fName] as IFieldValidation).messages
            }
            maxDate={maxDate}
            minDate={minDate}
            onMount={
                setActivateValidationForField
                    ? () => setActivateValidationForField(true, fName)
                    : undefined
            }
        />
    );
}

export function selectFieldRenderer(
    fName: string,
    field: IField,
    props: IProps
): JSX.Element {
    const {
        value,
        title,
        meta,
        options,
        placeholderValue,
        onSelectOption,
        isDisabled,
        isRequired,
        fieldCssClass,
    } = field;
    const { validationResults } = props;
    const fieldValidation = validationResults[fName];

    const selectOption = (val: string, filterName: string, optionMeta: any) => {
        onSelectOption(val, optionMeta, meta);
    };

    return (
        <div
            key={fName}
            className={classNames('input-field', fieldCssClass, {
                'input-field--required': isRequired,
                'input-field--errors':
                    fieldValidation &&
                    !(validationResults[fName] as IFieldValidation).isValid,
            })}
        >
            <label className="input-field__title">{title}</label>
            <DropDown
                disabled={isDisabled}
                options={options}
                onSelectOption={selectOption}
                active={value}
                title={!options && placeholderValue}
                isFilterable
            />
        </div>
    );
}

export function multiSelectFieldRenderer(
    fName: string,
    field: IField,
    props: IProps
): JSX.Element {
    const {
        value,
        title,
        meta,
        options,
        placeholderValue,
        onSelectOption,
        onDeselectOption,
        isDisabled,
        isRequired,
        fieldCssClass,
        placeholder,
    } = field;
    const { validationResults } = props;
    const fieldValidation = validationResults[fName];

    const selectOption = (val: string, filterName: string, optionMeta: any) => {
        onSelectOption(val, optionMeta, meta);
    };

    const deselectOption = (
        val: string,
        filterName: string,
        optionMeta: any
    ) => {
        onDeselectOption(val, optionMeta, meta);
    };

    return (
        <div
            key={fName}
            className={classNames('input-field', fieldCssClass, {
                'input-field--required': isRequired,
                'input-field--errors':
                    fieldValidation &&
                    !(validationResults[fName] as IFieldValidation).isValid,
            })}
        >
            <label className="input-field__title">{title}</label>
            <DropDown
                disabled={isDisabled}
                isMultiSelect
                options={options}
                onSelectOption={selectOption}
                onDeselectOption={deselectOption}
                active={value}
                title={!options && placeholderValue}
                placeholder={placeholder}
                isFilterable
            />
        </div>
    );
}

export function textFieldRenderer(
    fName: string,
    field: IField,
    props: IProps
): JSX.Element {
    const {
        value,
        title,
        meta,
        placeholder,
        onTextChange,
        fieldCssClass,
        isMultiLine,
        isRequired,
    } = field;
    const {
        validationResults,
        setActivateValidationForField,
        validateSingleField,
    } = props;

    return (
        <InputField
            isRequired={isRequired}
            key={fName}
            value={value}
            title={title}
            onChange={onTextChange}
            placeholder={placeholder}
            meta={meta}
            isMultiLine={isMultiLine}
            cssClass={classNames(
                {
                    invalid:
                        validationResults[fName] &&
                        !(validationResults[fName] as IFieldValidation).isValid,
                },
                fieldCssClass
            )}
            errorMessages={
                validationResults[fName] &&
                (validationResults[fName] as IFieldValidation).messages
            }
            onFocus={
                setActivateValidationForField
                    ? () => {
                          setActivateValidationForField(true, fName);
                          validateSingleField(fName);
                      }
                    : undefined
            }
        />
    );
}

export function multiSelectTagsRenderer(
    fName: string,
    field: IField
): JSX.Element {
    const {
        value,
        options,
        onSelectOption,
        onDeselectOption,
        onCreateOption,
        meta,
        title,
        isRequired,
    } = field;

    const selectOption = (val: string, filterName: string, optionMeta: any) => {
        onSelectOption(val, optionMeta, meta);
    };
    const deselectOption = (
        val: string,
        filterName: string,
        optionMeta: any
    ) => {
        onDeselectOption(val, optionMeta, meta);
    };

    return (
        <div
            key={fName}
            className={classNames('input-field', {
                'input-field--required': isRequired,
            })}
        >
            <label className="input-field__title">{title}</label>
            <DropDownWithTags
                active={value}
                options={options}
                onSelectOption={selectOption}
                onDeselectOption={deselectOption}
                onCreateOption={onCreateOption}
            />
        </div>
    );
}

export function hiddenTextFieldRenderer(
    fName: string,
    field: IField,
    props: IProps
): JSX.Element {
    const {
        value,
        title,
        meta,
        placeholder,
        onTextChange,
        fieldCssClass,
        autoComplete,
        isRequired,
    } = field;
    const {
        validationResults,
        setActivateValidationForField,
        validateSingleField,
    } = props;

    return (
        <HiddenInputField
            isRequired={isRequired}
            key={fName}
            value={value}
            title={title}
            onChange={onTextChange}
            placeholder={placeholder}
            meta={meta}
            className={classNames(
                {
                    invalid:
                        validationResults[fName] &&
                        !(validationResults[fName] as IFieldValidation).isValid,
                },
                fieldCssClass
            )}
            errorMessages={
                validationResults[fName] &&
                (validationResults[fName] as IFieldValidation).messages
            }
            autoComplete={autoComplete}
            onFocus={
                setActivateValidationForField
                    ? () => {
                          setActivateValidationForField(true, fName);
                          validateSingleField(fName);
                      }
                    : undefined
            }
        />
    );
}

export function checkBoxRenderer(fName: string, field: IField): JSX.Element {
    const { value, onCheckboxChange, title, fieldCssClass } = field;

    return (
        <CheckBox
            checked={value}
            onCheckedChange={onCheckboxChange}
            className={fieldCssClass}
            key={fName}
        >
            <span>{title}</span>
        </CheckBox>
    );
}
