/**
 * Created by lkarmelo on 29.10.2019.
 */

import React, { useState, useCallback } from 'react';
import classNames from 'classnames';

import CheckBox from '../../Checkbox';
import { ISelectOption } from '../Select/TreeSelect';

export interface IProps {
    active: boolean;
    option: ISelectOption;
    intermediate?: boolean;
    initiallyOpened?: boolean;
    className?: string;
    opened?: boolean;
    toggleOpened?(): void;
    onSelect(option: ISelectOption): void;
    onDeselect(option: ISelectOption): void;
}

const OptionGroup: React.FC<IProps> = (props) => {
    const {
        option,
        children,
        opened,
        active,
        onDeselect,
        onSelect,
        initiallyOpened,
        intermediate,
        className,
        toggleOpened,
    } = props;

    const [isOpenedState, setIsOpenState] = useState(initiallyOpened);

    const shouldOpen = typeof opened === 'boolean' ? opened : isOpenedState;

    const toggleSelect = useCallback(() => {
        active ? onDeselect(option) : onSelect(option);
    }, [active, onSelect, onDeselect, option]);

    return (
        <div
            className={classNames(className, 'nkcm-select-option-group', {
                'nkcm-select-option-group--open': shouldOpen,
            })}
        >
            <div className="nkcm-select-option-group__wrapper">
                <div className="nkcm-select-option-group__checbox-toggle">
                    <CheckBox
                        checked={active}
                        onChange={toggleSelect}
                        intermediate={intermediate}
                        fixed={option.fixed}
                    />
                    <button
                        className="nkcm-select-option-group__toggle"
                        onClick={() => {
                            if (toggleOpened) {
                                toggleOpened();
                            } else {
                                setIsOpenState((s) => !s);
                            }
                        }}
                    >
                        <span
                            className="nkcm-select-option-group__label"
                            title={option.label}
                        >
                            {option.label}
                        </span>
                        <span className="nkcm-select-option-group__arrow" />
                    </button>
                </div>

                <div className="nkcm-select-option-group__count">
                    {option.count}
                </div>
            </div>
            {shouldOpen && (
                <div className="nkcm-select-option-group__children">
                    {typeof children === 'function'
                        ? children(props)
                        : children}
                </div>
            )}
        </div>
    );
};

OptionGroup.defaultProps = {
    initiallyOpened: false,
};

export default React.memo(OptionGroup);
