/**
 * Created by Lkarmelo on 21.11.2017.
 */

import { handleActions } from 'redux-actions';

import { SET_CURRENT_USER_WORK_INFO } from '../actions/currentUserWorkInfo';
import * as Store from '../store/StoreNamespace';

export default handleActions(
    {
        [SET_CURRENT_USER_WORK_INFO](
            state: Store.IEmployeeInfo[],
            { payload }: { payload: Store.IEmployeeInfo[]; type: string }
        ) {
            return payload;
        },
    },
    [] // - default value for userWorkInfo
);
