/**
 * Created by lkarmelo on 30.10.2019.
 */

import * as React from 'react';
import classNames from 'classnames';
import { Portal } from 'react-portal';
import { useBodyClass, useOnOutsideClick } from '@nkc-frontend/nkc-react-hooks';

export interface IProps {
    opened: boolean;
    className?: string;
    onOutsideContentClick?(): void;
}

const Sidebox: React.FC<IProps> = ({
    opened,
    className,
    children,
    onOutsideContentClick,
}) => {
    useBodyClass('nkcm-sidebox--opened', opened);

    const onMouseDown = useOnOutsideClick(() => onOutsideContentClick?.());

    return (
        <Portal>
            {opened && (
                <div className={classNames('nkcm-sidebox', className)}>
                    <div className="nkcm-sidebox__window" />
                    <div
                        className="nkcm-sidebox__content"
                        onMouseDown={onMouseDown}
                    >
                        {children}
                    </div>
                </div>
            )}
        </Portal>
    );
};

export default Sidebox;
