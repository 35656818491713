import React, { useCallback } from 'react';
import { useLatest } from 'react-use';

import List, { IListProps } from '../List/List';
import { ISelectOption } from '..';

interface IHierarchicalListProps
    extends Omit<IListProps, 'onSelect' | 'onDeselect'> {
    onSelect(options: ISelectOption[]): void;
    onDeselect(options: ISelectOption[]): void;
}

/**
 * List, в котором реализован иерархический выбор опций.
 * При deselect опции будет снят выбор с её родителей и всех потомков. При select будут выбраны все потомки.
 * Самостоятельно реалзиует intermediate в зависимости от выбранных опций
 */
const HierarchicalList: React.FC<IHierarchicalListProps> = (props) => {
    const { active, onSelect, onDeselect } = props;

    const activeRef = useLatest(active);

    const _onSelect = useCallback(
        (
            option: ISelectOption,
            descendantsMap: Record<string, ISelectOption[]>,
            ascendantsMap: Record<string, ISelectOption[]>
        ) => {
            const descendants = descendantsMap[option.value];
            // сначала выбираем всех потомков

            const selectedOptions: ISelectOption[] = descendants.slice();
            selectedOptions.push(option);

            // теперь проверяем для всех родителских опций состояние active их прямых потомков
            const ascendants = ascendantsMap[option.value];
            ascendants
                .slice()
                .reverse() // переворачиваем массив, потому что ближайщий родитель находился в конце массива
                .forEach((ascOption) => {
                    if (
                        ascOption.options?.every(
                            (opt) =>
                                selectedOptions.some(
                                    ({ value }) => value === opt.value
                                ) || activeRef.current.includes(opt.value)
                        )
                    ) {
                        selectedOptions.push(ascOption);
                    }
                });

            onSelect(selectedOptions);
        },
        [onSelect]
    );

    const _onDeselect = useCallback(
        (
            option: ISelectOption,
            descendantsMap: Record<string, ISelectOption[]>,
            ascendantsMap: Record<string, ISelectOption[]>
        ) => {
            const descendants = descendantsMap[option.value];
            const ascendants = ascendantsMap[option.value];

            const deselectedOptions = [option, ...descendants, ...ascendants];

            // удаляем и потомков и всех родителей
            onDeselect(deselectedOptions);
        },
        [onDeselect]
    );

    const getIntermediate = useCallback(
        (
            activeMap: Record<string, boolean>,
            ascendantsMap: Record<string, ISelectOption[]>
        ) => {
            const intermediate = Object.keys(activeMap).reduce(
                (res, activeValue) => {
                    const ascendants = ascendantsMap[activeValue];
                    res.push(...ascendants?.map(({ value }) => value));

                    return res;
                },
                []
            );

            return intermediate;
        },
        []
    );

    return (
        <List
            {...props}
            intermediate={getIntermediate}
            onSelect={_onSelect}
            onDeselect={_onDeselect}
        />
    );
};

export default HierarchicalList;
