import * as React from 'react';
import classNames from 'classnames';

import IProps from './interfaces/ICarouselPagingProps';
import styles from './CarouselPaging.scss';

const CarouselPaging: React.FC<IProps> = ({ toNextSlide, toPrevSlide }) => {
    return (
        <div className={styles.carouselPaging}>
            <button
                className={classNames(
                    'btn',
                    styles.carouselPagingButton,
                    styles.carouselPagingButtonPrev
                )}
                onClick={toPrevSlide}
            />
            <button
                className={classNames(
                    'btn',
                    styles.carouselPagingButton,
                    styles.carouselPagingButtonNext
                )}
                onClick={toNextSlide}
            />
        </div>
    );
};

export default CarouselPaging;
