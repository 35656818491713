import React, { FC } from 'react';
import classNames from 'classnames';
import numeral from 'numeral';

import { CURRENT_HTTP_URL } from 'app/utils/constants';
import clientRoutes from 'app/routing/clientRoutes';

import * as styles from '../../SearchPanel.scss';
import { RegularHintOptionMeta } from '../../types';

interface IRenderQueryHintContent {
    label: string;
    meta: RegularHintOptionMeta;
}

export const RenderQueryHintContent: FC<IRenderQueryHintContent> = (
    {
        label,
        meta: { documentsCount, sectionTitle, doc },
    }) => {
    return (
        <div
            className={classNames(
                'clearfix',
                styles.searchPanelQueryHint,
            )}
        >
            {sectionTitle && <div className={styles.searchPanelQueryHintSectionTitle}>
                {sectionTitle}
            </div>}
            {doc?.id ?
                <div className={styles.searchPanelQueryHintLabel}>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${CURRENT_HTTP_URL}${clientRoutes.documentRedirect.getUrl({id: doc.id})}`}
                    >
                        <div className={styles.searchPanelQueryHintLabel}
                             dangerouslySetInnerHTML={{__html: label}}/>
                        {doc?.file?.extension &&
                          <div className={styles.searchPanelQueryHintExtension}>{doc.file.extension}</div>}
                    </a>
                </div>
                : <div className={styles.searchPanelQueryHintLabel} dangerouslySetInnerHTML={{__html: label}}/>}
            {documentsCount !== undefined &&
              <div className={styles.searchPanelQueryHintNumber}>
                  {numeral(documentsCount).format('0,0').replace(/,/g, ' ')} документов
              </div>
            }
        </div>
    );
};
