import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import queryString from 'qs';

import OuterRedirect from 'app/components/common/OuterRedirect';
import { parseOptions } from 'app/utils/queryStringOptions';

const Redirect: React.FC<RouteComponentProps<{}>> = ({ location }) => {
    const redirectLink = queryString.parse(location.search, parseOptions)
        .url as string;
    let checkLinks = queryString.parse(location.search, parseOptions)
        .checkLinks as string[];
    if (checkLinks && !Array.isArray(checkLinks)) {
        checkLinks = [checkLinks];
    }
    return <OuterRedirect link={redirectLink} checkLinks={checkLinks} />;
};

export default Redirect;
