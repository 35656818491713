import { Link } from 'react-router-dom';
import React from 'react';
import classNames from 'classnames';

import PersonAvatar from 'app/components/search/PersonAvatar/PersonAvatarConnected';
import {
    FeatureRenderer,
    UIFeaturesName,
} from 'app/components/common/utils/FeatureRenderer';
import clientRoutes from 'app/routing/clientRoutes';
import { useTypedSelector } from 'app/hooks/useTypedSelector';
import getPlaceHolderByUserNames from 'app/utils/getPlaceHolderByUserNames';

import * as styles from './Content.scss';

import * as userMenuStyles from '../../UserMenu.scss';

const profileImgPath = require('images/profile.svg');
const favoriteImgPath = require('images/favorite-o.svg');

interface IProps {
    onClose: () => void;
}

const Content: React.FunctionComponent<IProps> = ({ onClose }) => {
    const {
        firstName = '',
        lastName = '',
        email,
        dropdownUserMenuAvatar,
    } = useTypedSelector((state) => state.user);

    const placeHolder = getPlaceHolderByUserNames(firstName, lastName);

    return (
        <>
            <div className={classNames(styles.contentHeader, 'clearfix')}>
                {dropdownUserMenuAvatar ? (
                    <PersonAvatar
                        avatar={
                            dropdownUserMenuAvatar &&
                            dropdownUserMenuAvatar.avatar
                        }
                        className={userMenuStyles.userMenuUserPic}
                    />
                ) : (
                    <div
                        className={classNames(
                            userMenuStyles.userMenuUserPicSmall,
                            userMenuStyles.userMenuUserPic,
                            userMenuStyles.userMenuDefaultUserPic
                        )}
                    >
                        <div className={userMenuStyles.userMenuPlaceholder}>
                            <span>{placeHolder}</span>
                        </div>
                    </div>
                )}
                <div className={styles.contentInfo}>
                    <div className={styles.contentName}>
                        {`${firstName} ${lastName}`}
                    </div>
                    <div className={styles.contentEmail}>
                        {email && email.length > 0 && email[0]}
                    </div>
                </div>
            </div>
            <ul className={styles.contentNavigation}>
                <FeatureRenderer featureName={UIFeaturesName.ProfileLink}>
                    <li className={styles.contentLinkWrapper}>
                        <div className={styles.contentIconWrapper}>
                            <img src={profileImgPath} alt="Профиль" />
                        </div>
                        <Link
                            onClick={onClose}
                            className={styles.contentLink}
                            to={clientRoutes.account.subRoutes.profile.getUrl()}
                        >
                            Профиль
                        </Link>
                    </li>
                </FeatureRenderer>
                <FeatureRenderer featureName={UIFeaturesName.FavoriteLink}>
                    <li className={styles.contentLinkWrapper}>
                        <div className={styles.contentIconWrapper}>
                            <img src={favoriteImgPath} alt="Избранное" />
                        </div>
                        <Link
                            onClick={onClose}
                            className={styles.contentLink}
                            to={clientRoutes.account.subRoutes.favorite.getUrl()}
                        >
                            Избранное
                        </Link>
                    </li>
                </FeatureRenderer>
               {/* <FeatureRenderer featureName={UIFeaturesName.SubscribeLink}>
                    <li className={styles.contentLinkWrapper}>
                        <div className={styles.contentIconWrapper}>
                            <img src={subscribeImgPath} alt="Отслеживаемое" />
                        </div>
                        <Link
                            onClick={onClose}
                            className={styles.contentLink}
                            to={clientRoutes.account.subRoutes.subscribe.getUrl()}
                        >
                            Отслеживаемое
                        </Link>
                    </li>
                </FeatureRenderer>
                <FeatureRenderer featureName={UIFeaturesName.EducationLink}>
                    <li className={styles.contentLinkWrapper}>
                        <div className={styles.contentIconWrapper}>
                            <img
                                src={educationImgPath}
                                alt="Обучение и развитие"
                            />
                        </div>
                        <Link
                            onClick={onClose}
                            className={styles.contentLink}
                            to={clientRoutes.account.subRoutes.education.getUrl()}
                        >
                            Обучение и развитие
                        </Link>
                    </li>
                </FeatureRenderer>
                <FeatureRenderer featureName={UIFeaturesName.RelatedLink}>
                    <li className={styles.contentLinkWrapper}>
                        <div className={styles.contentIconWrapper}>
                            <img
                                src={sharedImgPath}
                                alt="Связанные документы"
                            />
                        </div>
                        <Link
                            onClick={onClose}
                            className={styles.contentLink}
                            to={clientRoutes.account.subRoutes.related.getUrl()}
                        >
                            Связанные документы
                        </Link>
                    </li>
                </FeatureRenderer>
                <FeatureRenderer featureName={UIFeaturesName.AccountMyDocument}>
                    <li className={styles.contentLinkWrapper}>
                        <div className={styles.contentIconWrapper}>
                            <i
                                className="nkc-icon-files"
                                title="Мои документы"
                            />
                        </div>
                        <Link
                            onClick={onClose}
                            className={styles.contentLink}
                            to={clientRoutes.account.subRoutes.myDocument.getUrl()}
                        >
                            Мои документы
                        </Link>
                    </li>
                </FeatureRenderer>*/}
            </ul>
        </>
    );
};

export default Content;
