/**
 * Created by lkarmelo on 13.11.2019.
 */

import React, { useEffect } from 'react';

import OuterRedirect from 'app/components/common/OuterRedirect';

import IProps from './interfaces/IDocumentRedirectProps';

const DocumentRedirect: React.FC<IProps> = (props) => {
    const { link, checkLinks, match, fetchLink, logDocumentSourceViewEvent } =
        props;
    const documentId = match.params.id;

    useEffect(() => {
        if (documentId) {
            fetchLink(documentId);
            logDocumentSourceViewEvent(documentId);
        }
    }, [documentId, fetchLink, logDocumentSourceViewEvent]);

    return <OuterRedirect link={link} checkLinks={checkLinks} />;
};

export default DocumentRedirect;
