/**
 * Created by: Lkarmelo on 11.07.2018
 * -----
 * Last Modified: 01.10.2018 13:18:43
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import { createSelector } from 'reselect';
import pickBy from 'lodash/pickBy';

import * as Store from 'app/redux/store/StoreNamespace';
import {
    SearchDocumentName,
    searchResultDocumentList,
} from 'app/redux/actions/interfaces/DocumentEnum';
import { compareFilterValues } from 'app/utils/filters/compareFilterValues';

const panelStaticFiltersBySearchDocument = new Map<
    string,
    { filters: string[]; disabledFilters?: string[] }
>([
    [
        SearchDocumentName.search,
        { filters: ['server'], disabledFilters: ['competencies'] },
    ],
    [
        SearchDocumentName.education,
        { filters: [Store.StaticFilterName.Search, 'server'] },
    ],
    [
        SearchDocumentName.documentManagementOwn,
        { filters: [Store.StaticFilterName.Search, 'server'] },
    ],
    [
        SearchDocumentName.documentManagementAll,
        { filters: [Store.StaticFilterName.Search, 'server'] },
    ],
    [Store.NotificationContext, { filters: [] }],
]);

searchResultDocumentList.forEach((docName) => {
    !panelStaticFiltersBySearchDocument.has(<SearchDocumentName>docName) &&
        panelStaticFiltersBySearchDocument.set(<SearchDocumentName>docName, {
            filters: [Store.StaticFilterName.Search, 'server'],
            disabledFilters: ['competencies'],
        });
});

const getVisibleFilters = (filtersMeta: Store.IFiltersMeta) => {
    return (
        filtersMeta &&
        pickBy(filtersMeta, (filterMeta) => {
            return filterMeta.alwaysVisible || !filterMeta.hidden;
        })
    );
};

const getDynamicVisibleFilters = (
    search: Store.ISearch | undefined,
    currentTabFiltersMeta: Store.IFiltersMeta | undefined,
    defaultFilters: Store.IFilters
): Store.IFiltersMeta | undefined => {
    const filterVisibility = search?.filterVisibility;

    if (filterVisibility && currentTabFiltersMeta) {
        return pickBy(currentTabFiltersMeta, (filterMeta) => {
            const filterValue = search.filters[filterMeta.filterName]?.value;
            const defaultValue = defaultFilters[filterMeta.filterName]?.value;
            const filterHasValue =
                typeof filterValue !== 'undefined' &&
                !compareFilterValues(
                    filterValue,
                    defaultValue,
                    filterMeta.type
                );

            return (
                filterMeta.alwaysVisible ||
                filterHasValue ||
                (filterVisibility[filterMeta.filterName]?.visible &&
                    !filterMeta.hidden)
            );
        });
    }
};

type Arg = {
    state: Store.IState;
    searchDocumentName: SearchDocumentName;
    onlyVisible?: boolean;
};
export const filtersMetaForSearchDocument = createSelector(
    ({ state, searchDocumentName }: Arg): Store.IFiltersMeta =>
        searchDocumentName === SearchDocumentName.favorites
            ? state.favoritesFiltersMeta
            : state.filtersMeta,
    ({ state }: Arg): Record<string, Store.ISearchTab> => state.searchTabs.tabs,
    ({ searchDocumentName }: Arg): SearchDocumentName => searchDocumentName,
    ({ state }: Arg): string => state.mainSearchPageActiveTab,
    ({ state, searchDocumentName }: Arg): Store.ISearch =>
        state[searchDocumentName],
    ({ state }: Arg): Store.IFilters => state.defaultFilters,
    ({ onlyVisible }: Arg): boolean | undefined => onlyVisible,
    (
        defaultFiltersMeta: Store.IFiltersMeta,
        tabs: Record<string, Store.ISearchTab>,
        searchDocumentName: SearchDocumentName,
        activeTab: string,
        search: Store.ISearch,
        defaultFilters,
        onlyVisible = true
    ): Store.IFiltersMeta => {
        const nextMeta: Store.IFiltersMeta = {};

        let meta: Store.IFiltersMeta = defaultFiltersMeta ?? {};

        const filtersFromTabs =
            searchDocumentName === SearchDocumentName.search;

        if (filtersFromTabs) {
            meta = activeTab ? tabs?.[activeTab]?.filters : {};

            if (onlyVisible) {
                meta =
                    getDynamicVisibleFilters(search, meta, defaultFilters) ??
                    {};
            }
        } else if (onlyVisible) {
            meta = getVisibleFilters(meta) ?? {};
        }

        const { filters: filtersList, disabledFilters = [] } =
            panelStaticFiltersBySearchDocument.get(searchDocumentName);

        // copy filters
        filtersList.forEach((filterName) => {
            switch (filterName) {
                case 'server':
                    Object.keys(meta)
                        .filter((name) => meta[name].isFromServer)
                        .forEach((name) => {
                            nextMeta[name] = meta[name];
                        });
                    break;
                default:
                    nextMeta[filterName] = meta[filterName];
            }
        });

        // remove disabled filters
        disabledFilters.forEach((filterName) => delete nextMeta[filterName]);

        return nextMeta;
    }
);
