import { createSelector } from 'reselect';

import * as Store from '../store/StoreNamespace';

export interface IFiltersValues {
    [filterName: string]: Store.IFilterValue;
}

export interface IFiltersDisabled {
    [filterName: string]: boolean;
}

const filtersSelector = (state: Store.IFiltersAndPaging) => state.filters || {};

export const filtersValuesSelector = createSelector<
    Store.IFiltersAndPaging,
    Store.IFilters,
    IFiltersValues
>(filtersSelector, (filters) => {
    const res = {};
    Object.keys(filters).forEach((key) => {
        res[key] = filters[key].value;
    });

    return res;
});
