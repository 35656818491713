import { connect } from 'react-redux';

import * as Store from 'app/redux/store/StoreNamespace';
import { setSearchActiveTab } from 'app/redux/actions/search/searchTabs';

import DocumentSearchTabsPanel, {
    IDocumentSearchTabsPanelStateProps,
    IDocumentSearchTabsPanelActionProps,
} from './DocumentSearchTabsPanel';

const mapStateToProps = (
    state: Store.IState
): IDocumentSearchTabsPanelStateProps => ({
    tabs: state.searchTabs.tabs,
    tabCodes: state.searchTabs.tabCodes,
    activeTab: state.mainSearchPageActiveTab,
    lastExecutedSearchQuery: state.search.lastExecutedSearchQuery,
});

const mapDispatchToProps = (dispatch): IDocumentSearchTabsPanelActionProps => ({
    setActiveTab(tabCode: string): void {
        dispatch(setSearchActiveTab({ tabCode, isNewSearch: true }));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentSearchTabsPanel);
