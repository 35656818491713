/**
 * Created by lkarmelo on 24.09.2018.
 */

import React, { PureComponent } from 'react';
import autoBind from 'autobind-decorator';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';
import queryString from 'qs';

import AddDocument from 'app/components/document-management/AddDocument/AddDocument';
import clientRoutes, {
    EditEducationMaterialQueryParams,
} from 'app/routing/clientRoutes';
import { noop } from 'app/utils/noop';
import { parseOptions } from 'app/utils/queryStringOptions';

import IProps from './interfaces/IEditDocumentProps';

@autoBind
export default class EditDocument extends PureComponent<IProps> {
    id$: BehaviorSubject<string> = null;

    idSubscription: Subscription = null;

    render(): JSX.Element {
        const { card, loadObjectCard, fillForm, isLoading, ...rest } =
            this.props;
        return (
            <AddDocument
                {...rest}
                isDisabled={isLoading}
                cancelBtnLink={this.getFallBackLink()}
                onMount={noop}
                formTitle="Редактирование документа"
                onFormSaved={this.onFormSavedSuccess}
            />
        );
    }

    componentDidMount(): void {
        const { onMount, card, match, fillForm } = this.props;
        this.createObservableId();
        if (card && card.id === match.params.id) {
            fillForm(card);
        }
        onMount();
    }

    componentDidUpdate(prevProps: IProps): void {
        const { card, match, fillForm } = this.props;
        const { id } = match.params;
        if (card && card !== prevProps.card && card.id === id) {
            fillForm(card);
        }

        this.id$.next(this.props.match.params.id);
    }

    createObservableId(): void {
        this.id$ = new BehaviorSubject(this.props.match.params.id);
        this.idSubscription = this.id$
            .filter((x) => !this.props.card || this.props.card.id !== x)
            .debounceTime(50)
            .subscribe((x) => {
                this.props.loadObjectCard(x);
            });
    }

    componentWillUnmount(): void {
        this.idSubscription.unsubscribe();
    }

    getFallBackLink(): string {
        const { location, card } = this.props;
        const { backUrl } = queryString.parse(
            location.search,
            parseOptions
        ) as Record<EditEducationMaterialQueryParams, string>;
        if (!backUrl) {
            return card ? clientRoutes.objectCard.getUrl({ id: card.id }) : '#';
        }
        return backUrl;
    }

    onFormSavedSuccess(): void {
        this.props.history.push(this.getFallBackLink());
    }
}
