import React, { useEffect } from 'react';
import classNames from 'classnames';
import Tooltip from 'antd/es/tooltip';

import * as styles from './OptionPersonCard.scss';

import PersonAvatar from '../PersonAvatar/PersonAvatarConnected';
import apiRoutes from '../../../routing/apiRoutes';
import { bodyAvatar } from '../../../utils/bodyAvatar';
import { isCached } from '../../../utils/isCached';

const iconPhone = require('images/IconPhone.svg');
const iconMail = require('images/IconMail.svg');
const iconSkype = require('images/IconSkype.svg');

const OptionPersonCard: React.FC<any> = ({ meta }) => {
    const { person } = meta.meta;
    const url = apiRoutes.getUserAvatar.getUrl(
        bodyAvatar({ userId: person.userId, resolution: 50, needCache: true })
    );
    useEffect(() => {
        isCached(url);
    }, [person.avatar]);

    return (
        <article className={styles.optionPersonCard}>
            <section className={styles.optionPersonCardMainInfo}>
                <div className={styles.optionPersonCardCard}>
                    <PersonAvatar
                        avatar={
                            isCached(url)
                                ? url
                                : person.avatar
                                ? person.avatar
                                : ''
                        }
                        className={styles.optionPersonCardAvatar}
                    />
                    <div className={styles.optionPersonCardNameBlock}>
                        <h2 className={styles.optionPersonCardName}>
                            <div
                                className={
                                    styles.optionPersonCardFilterHintLabel
                                }
                                dangerouslySetInnerHTML={{ __html: meta.label }}
                            />
                        </h2>
                        <h3 className={styles.optionPersonCardPosition}>
                            {person.positionName}
                        </h3>
                        <div className={styles.optionPersonCardUserDataItem}>
                            {person.department[0].name && (
                                <dt
                                    className={classNames(
                                        styles.optionPersonCardUserDataKey,
                                        styles.optionPersonCardUserDataKeyFirst
                                    )}
                                >
                                    {person.department[0].name}
                                </dt>
                            )}
                            <dt className={styles.optionPersonCardUserDataKey}>
                                <div
                                    className={
                                        styles.optionPersonCardCompanyDelimiter
                                    }
                                />
                                {person.companyName}
                            </dt>
                        </div>
                    </div>
                </div>
            </section>
            <section className={styles.optionPersonCardContact}>
                <ul className={styles.optionPersonCardContactInfo}>
                    {person.telInternal && (
                        <li className={styles.optionPersonCardContactItem}>
                            <div
                                className={
                                    styles.optionPersonCardContactItemIconContainer
                                }
                            >
                                <Tooltip title={person.telInternal}>
                                    <img src={iconPhone} alt="phone" />
                                </Tooltip>
                            </div>
                            <div className={styles.data}>
                                {person.telInternal}
                            </div>
                        </li>
                    )}
                    {person.email && (
                        <li
                            className={styles.optionPersonCardContactItem}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <div
                                className={
                                    styles.optionPersonCardContactItemIconContainer
                                }
                            >
                                <Tooltip title={person.email}>
                                    <a href={`mailto:${person.email}`}>
                                        <img src={iconMail} alt="mail" />
                                    </a>
                                </Tooltip>
                            </div>
                            <div
                                className={classNames(
                                    styles.data,
                                    styles.linkColor
                                )}
                            >
                                <a href={`mailto:${person.email}`}>
                                    {person.email}
                                </a>
                            </div>
                        </li>
                    )}
                    {person.usrSip && (
                        <li
                            className={styles.optionPersonCardContactItem}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <div
                                className={
                                    styles.optionPersonCardContactItemIconContainer
                                }
                            >
                                <a href={person.usrSip}>
                                    <img src={iconSkype} alt="skype" />
                                </a>
                            </div>
                            <div
                                className={classNames(
                                    styles.data,
                                    styles.linkColor
                                )}
                            >
                                <a href={person.usrSip}>
                                    Написать в Skype for business
                                </a>
                            </div>
                        </li>
                    )}
                </ul>
            </section>
        </article>
    );
};

export default OptionPersonCard;
