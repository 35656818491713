/**
 * Created by lkarmelo on 03.12.2018.
 */
/**
 * Возвращает координаты элементы относительно document
 * @param el
 */
export const getElementOffset = (
    el: HTMLElement
): { top: number; left: number } => {
    const offset = {
        top: 0,
        left: 0,
    };

    do {
        offset.top += el.offsetTop;
        offset.left += el.offsetLeft;

        el = el.offsetParent as HTMLElement;
    } while (el !== document.body && el !== null);

    return offset;
};
