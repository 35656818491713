/**
 * Created by Lkarmelo on 16.10.2017.
 */

import queryString from 'qs';

import { stringifyOptions } from 'app/utils/queryStringOptions';

export type ParamMap = { [paramName: string]: string | number | boolean };

export type TParamMap<T extends string> = {
    [paramName in T]?: string | number | boolean | string[];
};

export interface IRouteOptions {
    paramsWithBackslash?: boolean;
}

export default class Route<
    TParams extends string = any,
    TQuery extends string = any
> {
    options: IRouteOptions = {};

    template: { expand: Function } = null;

    getUrl(params?: TParamMap<TParams>, query?: TParamMap<TQuery>): string {
        if (!this.template) {
            return;
        }
        let url: string = this.template.expand(params);
        if (this.options.paramsWithBackslash) {
            url = url.replace(/%2F|%2f/g, '/');
        }

        if (query === undefined) {
            return url;
        }

        const paramStr = queryString.stringify(query, stringifyOptions);

        return `${url}${paramStr}`;
    }
}
