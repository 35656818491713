import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Tooltip from 'antd/es/tooltip';
import Icon from 'antd/es/icon';
import { Popover } from 'antd';
import {Subject} from 'rxjs/Subject';

import ExpansionTagsPanel from 'app/components/search/ExpansionTagsPanel';
import DocumentSearchTabsPanel from 'app/components/search/DocumentSearchTabsPanel';
import * as Store from 'app/redux/store/StoreNamespace';
import { useTypedSelector } from 'app/hooks/useTypedSelector';
import getSearchTarget from 'app/utils/getSearchTarget';
import { useDidUpdate } from 'app/hooks/useDidUpdate';

import IProps from './interfaces/ISearchPanelProps';
import useSearchPanel from './hooks/useSearchPanel';
import { getQueryHints } from './helpers/getQueryHints';
import { getInputInnerWidth } from './helpers';
import * as styles from './SearchPanel.scss';
import { HintText, SearchPanelLongQueryTooltip } from './components';

import { HintIcon } from '../../svg-components/HintIcon';

const SearchPanel = forwardRef<HTMLDivElement, IProps>((props, forwardedRef) => {
    const {
        isMinified,
        autofocus = true,
        searchIsEmpty,
        isHeaderFixed,
        onClearSearchQueryClick,
        onFetchSearchResultsClick: onFetchSearchResultsClickOwnProps,
    } = props;
    const state = useTypedSelector(store => store);
    const {
        search: { searchQuery, isQueryExpansion, hints },
        guideResults: { view: viewTour },
    } = state;
    const personHints = hints?.persons ?? [];
    const { search: searchIsLoading } = useTypedSelector(store => store.loading.pendingRequests);
    const searchTarget = getSearchTarget(state.mainSearchPageActiveTab, state);
    const showShadow = searchIsEmpty && !searchIsLoading;
    const showExpansionButton = searchTarget !== Store.SearchTarget.employee;
    const { queryHints, filterHints } = getQueryHints(hints?.queries);

    const [showHints, setShowHints] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isVisibleQuickCommands, setIsVisibleQuickCommands] = useState(false);

    const {
        onFetchSearchResultsClick,
        removeSearchQuery,
        onSetQueryExpansionMode,
        onUpdateQuery
    } = useSearchPanel({
        isMinified, onFetchSearchResultsClickOwnProps,
    });

    const inputWrapperRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const aheadFillerRef = useRef<HTMLElement>(null);
    const aheadRef = useRef<HTMLInputElement>(null);
    const markedQueryTextRef = useRef<HTMLSpanElement>(null);
    const textAreaWrapperRef = useRef<HTMLDivElement>(null);

    const hasHints = () => queryHints.length > 0
        || Object.keys(filterHints).length > 0
        || personHints.length > 0;

    const closeHints = useCallback(() => {
        setShowHints(false);
    }, []);

    const focusInput = useCallback(() => {
        if (viewTour) {
            inputRef.current.focus();
        }
    }, [viewTour, inputRef]);

    const handleSearchClick = useCallback(() => {
        showHints && closeHints();
        onFetchSearchResultsClick && onFetchSearchResultsClick(searchQuery);
        focusInput();
    }, [showHints, closeHints, onFetchSearchResultsClick, searchQuery, focusInput]);

    const onRemoveSearchQueryClick = () => {
        setIsVisibleQuickCommands(false);
        removeSearchQuery();
        onClearSearchQueryClick && onClearSearchQueryClick();
        focusInput();
    };

    const onQueryExpansionClick = () => {
        window.scrollTo(0, 0);
        onSetQueryExpansionMode(!isQueryExpansion);
    };

    /**
     * ставит clip для контейнера с подсказкой в инпуте, чтобы скрывать часть подсказки, которая совпадает с введённой строкой
     */
    const setAheadClip = () => {
        const inputStringWidth = aheadFillerRef.current.clientWidth;
        const inputPaddingLeft = parseFloat(window.getComputedStyle(inputRef.current, null).getPropertyValue('padding-left'));
        const clip = inputStringWidth + inputPaddingLeft;

        aheadRef.current.style.clip = `rect(auto, auto, auto, ${clip}px)`;
    };

    /**
     * Ставит left у контейнера с текстом для хайлайта если текст в инпуте длинне инпута. Предполагается, что курсор стоит в самом конце
     */
    const setMarkedQueryOffset = () => {
        if (!aheadFillerRef.current || !inputRef.current || !markedQueryTextRef.current) {
            return;
        }

        const inputStringWidth = aheadFillerRef.current.clientWidth;
        const inputInnerWidth = getInputInnerWidth(inputRef);

        if (document.activeElement !== inputRef.current || inputStringWidth <= inputInnerWidth) {
            markedQueryTextRef.current.style.left = `0`;
            return;
        }

        const makerQueryTextLeft = inputStringWidth - inputInnerWidth;
        markedQueryTextRef.current.style.left = `-${makerQueryTextLeft}px`;
    };

    const handleVisibleChange = useCallback(visible => {
        setIsVisible(visible);
    }, []);

    const handleMouseEnterFound = () => {
        textAreaWrapperRef.current.classList.add(styles.searchPanelTextAreaWrapperSearchHover);
    };

    const handleMouseLeaveFound = () => {
        textAreaWrapperRef.current.classList.remove(styles.searchPanelTextAreaWrapperSearchHover);
    };

    const isShowExpansionButton = showExpansionButton && searchQuery.length > 0 && !isMinified;
    const isExpansionTagsPanel = !isMinified && showExpansionButton && isQueryExpansion && !isHeaderFixed;
    const subjectEnterPressed$ = new Subject<any>();

    useEffect(() => {
        if (inputRef.current) {
            focusInput();
        }
    }, []);

    useDidUpdate(() => {
        setAheadClip();
        setMarkedQueryOffset();
    }, [searchQuery]);

    useDidUpdate(() => {
        document.querySelector('.header').addEventListener('click', () => {
            focusInput();
        });
    }, [focusInput]);

    //быстрые команды
    const normalizeQuery = rawQuery => {
        return rawQuery.replace(/[+"-]|OR /gi, '');
    }
    const processExactPhrase = () => {
        let trimmedQuery = searchQuery.trim();
        trimmedQuery = normalizeQuery(trimmedQuery);
        const newQuery = `"${trimmedQuery}"`;
        onUpdateQuery(newQuery);
        onFetchSearchResultsClick && onFetchSearchResultsClick(searchQuery);
    }

    const processAllWords = () => {
        let trimmedQuery = searchQuery.trim();
        trimmedQuery = normalizeQuery(trimmedQuery);
        const words = trimmedQuery.split(" ");
        const newQuery = words.length > 1 ? words.map(word => `+${word}`).join(" ") : trimmedQuery;
        onUpdateQuery(newQuery);
        onFetchSearchResultsClick && onFetchSearchResultsClick(searchQuery);
    }

    const processWithoutLastWord = () => {
        let trimmedQuery = searchQuery.trim();
        trimmedQuery = normalizeQuery(trimmedQuery);
        const words = trimmedQuery.split(" ");
        const newQuery = words.length > 1 ? words.slice(0, -1).join(" ") + " -" + words.slice(-1) : trimmedQuery;
        onUpdateQuery(newQuery);
        onFetchSearchResultsClick && onFetchSearchResultsClick(searchQuery);
    }

    const processOrOperator = () => {
        let trimmedQuery = searchQuery.trim();
        trimmedQuery = normalizeQuery(trimmedQuery);
        const words = trimmedQuery.split(" ");
        const newQuery = words.length > 1 ? words.map((word, i) => i === 0 ? word : `OR ${word}`).join(" ") : trimmedQuery;
        onUpdateQuery(newQuery);
        onFetchSearchResultsClick && onFetchSearchResultsClick(searchQuery);
    }

    return (
        <div
            className={classNames(
                styles.searchPanel,
                { [styles.searchPanelMinified]: isMinified },
            )}
            ref={forwardedRef}
        >
            <div className={styles.searchPanelContent}>
                <div className={styles.hintWrapper}>
                    <Popover
                        placement='bottom'
                        content={<HintText />}
                        trigger='click'
                        visible={isVisible}
                        onVisibleChange={handleVisibleChange}
                        overlayStyle={{ fontSize: '14px' }}
                    >
                        <div className={styles.hintIcon}>
                            <HintIcon isActive={isVisible} />
                        </div>
                    </Popover>
                </div>
                <div
                    ref={inputWrapperRef}
                    className={classNames(
                        styles.searchPanelInputWrapper,
                        { [styles.searchPanelInputWrapperWithShadow]: showShadow },
                    )}
                >
                    <SearchPanelLongQueryTooltip
                        inputWrapperRef={inputWrapperRef}
                        textAreaWrapperRef={textAreaWrapperRef}
                        inputRef={inputRef}
                        aheadRef={aheadRef}
                        aheadFillerRef={aheadFillerRef}
                        markedQueryTextRef={markedQueryTextRef}
                        autofocus={autofocus}
                        isMinified={isMinified}
                        hasHints={hasHints}
                        showHints={showHints}
                        setShowHints={setShowHints}
                        onFetchSearchResultsClickOwnProps={onFetchSearchResultsClickOwnProps}
                        focusInput={focusInput}
                        handleVisibleChange={handleVisibleChange}
                        handleSearchClick={handleSearchClick}
                        closeHints={closeHints}
                        subjectEnterPressed$={subjectEnterPressed$}
                    />
                    <div className={styles.searchPanelControls}>
                        {isMinified &&
                            <button
                                className={classNames(styles.searchPanelSearchBtn, 'btn')}
                                onClick={handleSearchClick}
                            >
                                <div />
                            </button>
                        }
                        {searchQuery.length > 0 && !isMinified &&
                            <Tooltip
                                placement="bottom"
                                title="Быстрые команды"
                                mouseEnterDelay={0.3}
                            >
                                <button
                                    className={classNames(isVisibleQuickCommands ? styles.searchPanelQuickCommandsIconActive : styles.searchPanelQuickCommandsIcon, 'btn')}
                                    onClick={() => setIsVisibleQuickCommands(!isVisibleQuickCommands)}
                                >
                                    <div/>
                                </button>
                            </Tooltip>

                        }
                        {searchQuery.length > 0 && !isMinified &&
                            <button
                                onClick={onRemoveSearchQueryClick}
                                className={classNames(styles.searchPanelRemoveQueryBtn, 'btn')}
                            >
                                <div />
                            </button>
                        }
                    </div>
                    {!isMinified &&
                        <button
                            onClick={handleSearchClick}
                            className={classNames(styles.searchPanelSearchBtn, 'btn-primary')}
                            onMouseEnter={handleMouseEnterFound}
                            onMouseLeave={handleMouseLeaveFound}
                        >
                            <Icon className={styles.searchPanelSearchBtnIcon} type='search' />
                            Найти
                        </button>
                    }
                </div>
                {/*Быстрые команды*/}
                {isVisibleQuickCommands &&
                    <div className={styles.searchPanelQuickCommands}>
                        <button onClick={processExactPhrase}>
                            Точное слово или фраза
                        </button>
                        <button onClick={processAllWords}>
                            Все слова в одном документе
                        </button>
                        <button onClick={processWithoutLastWord}>
                            Без последнего слова
                        </button>
                        <button onClick={processOrOperator}>
                            Переменные (или)
                        </button>
                    </div>
                }
                {isExpansionTagsPanel &&
                    <ExpansionTagsPanel />
                }
                {!isMinified && !isHeaderFixed &&
                    <DocumentSearchTabsPanel />
                }
            </div>
        </div>
    );
});

export default SearchPanel;
