import * as Store from '../../redux/store/StoreNamespace';
import { IPreviewLink } from '../../redux/store/StoreNamespace';

type PreviewLinksMapType = Map<Store.PreviewLinkSize, string>;

export function getPreviewLinksMap(
    previewLinks: Store.IPreviewLink[]
): PreviewLinksMapType {
    if (Array.isArray(previewLinks)) {
        return new Map<Store.PreviewLinkSize, string>(
            previewLinks.map((pLink: IPreviewLink) => {
                return [pLink.size, pLink.link] as [
                    Store.PreviewLinkSize,
                    string
                ];
            })
        );
    }
    return new Map<Store.PreviewLinkSize, string>();
}

export function getSmallestPreviewLink(
    previewLinksMap: PreviewLinksMapType
): string {
    if (previewLinksMap.size === 0) {
        return '';
    }
    if (previewLinksMap.has(Store.PreviewLinkSize.small)) {
        return previewLinksMap.get(Store.PreviewLinkSize.small);
    }
    if (previewLinksMap.has(Store.PreviewLinkSize.medium)) {
        return previewLinksMap.get(Store.PreviewLinkSize.medium);
    }
    if (previewLinksMap.has(Store.PreviewLinkSize.big)) {
        return previewLinksMap.get(Store.PreviewLinkSize.big);
    }
    if (previewLinksMap.has(Store.PreviewLinkSize.default)) {
        return previewLinksMap.get(Store.PreviewLinkSize.default);
    }
    return '';
}

export function getBiggestPreviewLink(
    previewLinksMap: PreviewLinksMapType
): string {
    if (previewLinksMap.size === 0) {
        return '';
    }
    if (previewLinksMap.has(Store.PreviewLinkSize.big)) {
        return previewLinksMap.get(Store.PreviewLinkSize.big);
    }
    if (previewLinksMap.has(Store.PreviewLinkSize.default)) {
        return previewLinksMap.get(Store.PreviewLinkSize.default);
    }
    if (previewLinksMap.has(Store.PreviewLinkSize.medium)) {
        return previewLinksMap.get(Store.PreviewLinkSize.medium);
    }
    if (previewLinksMap.has(Store.PreviewLinkSize.small)) {
        return previewLinksMap.get(Store.PreviewLinkSize.small);
    }
    return '';
}
