import { handleActions } from 'redux-actions';

import * as Store from '../store/StoreNamespace';
import { setDoodle } from '../actions/doodle';

export default handleActions<Partial<Store.IDoodle>, Store.IDoodle>(
    {
        [setDoodle.toString()](
            state: Store.IDoodle,
            { payload }: { payload?: any }
        ) {
            return payload;
        },
    },
    {} // - default value for doodle
);
