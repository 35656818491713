/**
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 29.01.2018
 * -----
 * Last Modified: 16.10.2018 12:35:30
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import { createSelector, Selector } from 'reselect';

import * as Store from 'app/redux/store/StoreNamespace';
import Api from 'app/api/Api';
import { replaceDashesWithDots } from 'app/utils/filters/filterNamesEscape';

import { ISearchResultSelectorProps, sortingSelector } from './search';
import { ignoreFilters } from './ignoreFilters';
import { getFilterValue } from './getFilterValue';

import { filtersMetaForSearchDocument } from '../filtersMetaForSearchDocument';
import { filtersValuesSelector, IFiltersValues } from '../filters';

const filtersMetaSelector: Selector<
    ISearchResultSelectorProps,
    Store.IFiltersMeta
> = ({ state, searchDocumentName }) =>
    Store.RecommendationsContext.toString() === searchDocumentName
        ? state.filtersMeta
        : filtersMetaForSearchDocument({
              state,
              searchDocumentName,
              onlyVisible: false,
          });

const filtersSelector: Selector<ISearchResultSelectorProps, IFiltersValues> = ({
    actionState,
}) => filtersValuesSelector(actionState);

const pagingSelector: Selector<ISearchResultSelectorProps, Store.IPaging> = ({
    actionState,
}) => actionState.paging;

const personalizationSelector: Selector<
    ISearchResultSelectorProps,
    boolean
> = ({ actionState }) => actionState.personalization;

export const stateToBookmarkSelector = createSelector<
    ISearchResultSelectorProps,
    Store.IFiltersMeta,
    IFiltersValues,
    Store.IPaging,
    boolean,
    Record<string, Api.SearchSorting>,
    Api.IDocumentSearchRequestBody
>(
    [
        filtersMetaSelector,
        filtersSelector,
        pagingSelector,
        personalizationSelector,
        sortingSelector,
    ],
    (
        filtersMeta,
        filters,
        searchPaging,
        personalization,
        sortBy
    ): Api.IDocumentSearchRequestBody => {
        const result: Api.IDocumentSearchRequestBody = {
            skip: searchPaging.skip,
            limit: searchPaging.limit,
            userFilters: [],
            queryParams: [],
            query: '',
            personalization,
            sortBy,
        };

        // prepare filter
        Object.keys(filters).forEach((code) => {
            if (ignoreFilters.includes(code)) {
                return;
            }

            const payload: Api.ISearchRequestFilter = {
                type: 'BaseFilter',
                code: replaceDashesWithDots(code),
                // value: filterEmptyValues( filters[code] )
                value: getFilterValue(filters[code], filtersMeta[code]),
            };
            if (payload.value) {
                !!filtersMeta[code] && filtersMeta[code].isParam === true
                    ? result.queryParams.push(payload)
                    : result.userFilters.push(payload);
            }
        });

        !!filters.search && (result.query = filters.search as string);

        return result;
    }
);
