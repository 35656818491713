/**
 * Created by Lkarmelo on 21.02.2018.
 */

import React, { PureComponent } from 'react';
import autoBind from 'autobind-decorator';
import classNames from 'classnames';

import Collapse from 'app/components/common/utils/Collapse';

import IProps from './interfaces/ICollapsibleMenuProps';
import IState from './interfaces/ICollapsibleMenuState';

import './CollapsibleMenu.scss';

@autoBind
export default class CollapsibleMenu extends PureComponent<IProps, IState> {
    static defaultProps = {
        toggleOnArrowOnly: true,
    };

    state = {
        isOpened: false,
    };

    constructor(props: IProps) {
        super(props);

        if (props.isInitiallyOpened) {
            this.state.isOpened = true;
        }
    }

    render(): JSX.Element {
        const { toggleElement, className, toggleOnArrowOnly } = this.props;

        return (
            <div
                className={classNames('collapsible-menu', className, {
                    'collapsible-menu--opened': this.state.isOpened,
                })}
            >
                {toggleOnArrowOnly ? (
                    <div className="collapsible-menu__toggle">
                        <button
                            onClick={this.toggleOpenedState}
                            className="btn collapsible-menu__arrow"
                        />
                        <span className="collapsible-menu__toggle-text">
                            {toggleElement}
                        </span>
                    </div>
                ) : (
                    <button
                        onClick={this.toggleOpenedState}
                        className="btn collapsible-menu__toggle"
                    >
                        <span className="collapsible-menu__arrow" />
                        <span className="collapsible-menu__toggle-text">
                            {toggleElement}
                        </span>
                    </button>
                )}
                <Collapse isOpen={this.state.isOpened}>
                    <ul className="collapsible-menu__list">
                        {this.props.children}
                    </ul>
                </Collapse>
            </div>
        );
    }

    toggleOpenedState(): void {
        const { onCollapseChange } = this.props;
        this.setState(
            (state) => ({ isOpened: !state.isOpened }),
            () => onCollapseChange && onCollapseChange(this.state.isOpened)
        );
    }

    open(): void {
        const { onCollapseChange } = this.props;
        !this.state.isOpened &&
            this.setState(
                { isOpened: true },
                () => onCollapseChange && onCollapseChange(this.state.isOpened)
            );
    }
}
