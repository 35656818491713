import React, { useCallback, useState } from 'react';
import classNames from 'classnames';

import DefaultSidebox, {
    ISideboxProps as IDefaultSideboxProps,
} from 'app/modules/components/Sidebox';

import * as styles from './Sidebox.scss';

export enum OpenedState {
    Opened = 'opened',
    Closed = 'closed',
    Intermediate = 'intermediate',
}

type OpenCloseFunc = () => void;

export const useSideboxHelper = (
    initialOpenedState = false
): [OpenCloseFunc, OpenCloseFunc, boolean] => {
    const [isOpened, setIsOpened] = useState(initialOpenedState);

    const closeSidebox = useCallback(() => setIsOpened(false), [setIsOpened]);
    const openSidebox = useCallback(() => setIsOpened(true), [setIsOpened]);

    return [openSidebox, closeSidebox, isOpened];
};

export const useSideboxWithIntermediateHelper = (
    initialOpenedState = OpenedState.Closed
): [
    OpenCloseFunc,
    OpenCloseFunc,
    OpenCloseFunc,
    OpenCloseFunc,
    OpenedState
] => {
    const [openedState, setOpenedState] = useState(initialOpenedState);

    const closeSidebox = useCallback(
        () => setOpenedState(OpenedState.Closed),
        [setOpenedState]
    );
    const openSidebox = useCallback(
        () => setOpenedState(OpenedState.Opened),
        [setOpenedState]
    );
    const intermediateSidebox = useCallback(
        () => setOpenedState(OpenedState.Intermediate),
        [setOpenedState]
    );

    const toggleIntermediate = useCallback(() => {
        if (openedState === OpenedState.Opened) {
            intermediateSidebox();
        } else if (openedState === OpenedState.Intermediate) {
            openSidebox();
        }
    }, [openedState, intermediateSidebox, openSidebox]);

    return [
        openSidebox,
        closeSidebox,
        toggleIntermediate,
        intermediateSidebox,
        openedState,
    ];
};

interface ISideboxProps extends Omit<IDefaultSideboxProps, 'opened'> {
    opened: OpenedState | boolean;
    showCloseButton?: boolean;
    intermediateBtnClassName?: string;
    closeBtnClassName?: string;
    onCloseButtonClick?(): void;
    onIntermediateButtonClick?(): void;
}

const Sidebox: React.FC<ISideboxProps> = (props) => {
    const {
        opened,
        showCloseButton,
        onCloseButtonClick,
        intermediateBtnClassName,
        closeBtnClassName,
        onIntermediateButtonClick,
    } = props;

    const closeButtonElement = showCloseButton ? (
        <button
            type="button"
            className={classNames(
                'btn',
                styles.sideboxCloseBtn,
                closeBtnClassName
            )}
            onClick={onCloseButtonClick}
        >
            <div />
        </button>
    ) : null;

    const intermediateButton =
        typeof opened === 'string' ? (
            <>
                <button
                    type="button"
                    className={classNames(
                        'btn',
                        styles.sideboxIntermediateBtn,
                        intermediateBtnClassName,
                        {
                            [styles.sideboxIntermediateBtnStateOpened]:
                                opened === OpenedState.Opened,
                            [styles.sideboxIntermediateBtnStateIntermediate]:
                                opened === OpenedState.Intermediate,
                        }
                    )}
                    onClick={() => onIntermediateButtonClick?.()}
                >
                    <div className={styles.sideboxIntermediateBtnArrow} />
                </button>
                <div className={styles.sideboxIntermediateShadowHider} />
            </>
        ) : null;

    if (typeof opened === 'boolean') {
        return (
            <DefaultSidebox {...(props as IDefaultSideboxProps)}>
                {closeButtonElement}
                {props.children}
            </DefaultSidebox>
        );
    }
    if (typeof opened === 'string') {
        if (opened === OpenedState.Opened || opened === OpenedState.Closed) {
            return (
                <DefaultSidebox
                    {...props}
                    opened={opened === OpenedState.Opened}
                >
                    {intermediateButton}
                    {props.children}
                </DefaultSidebox>
            );
        }
        return (
            <div className={styles.sideboxIntermediate}>
                {intermediateButton}
            </div>
        );
    }
    return null;
};

export default Sidebox;
