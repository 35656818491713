/**
 * Created by Lkarmelo on 24.10.2017.
 */

import React, { PureComponent } from 'react';
import autoBind from 'autobind-decorator';
import numeral from 'numeral';
import classNames from 'classnames';
import ReactPaginate from 'react-paginate';
import Dropdown from 'antd/es/dropdown';
import Menu, { ClickParam } from 'antd/es/menu';
import Icon from 'antd/es/icon';

import IProps from './interfaces/IPaginationProps';
import * as styles from './Pagination.scss';

@autoBind
export default class Pagination extends PureComponent<IProps> {
    onPageChange({ selected }: { selected: number }) {
        this.props.onPageChange(selected + 1);
    }

    getMenuItems(): JSX.Element {
        return (
            <Menu
                className={styles.paginationLimitControlDropDown}
                onClick={this.onLimitDropDownClick}
            >
                <Menu.Item key={5}>Показывать по 5</Menu.Item>
                <Menu.Item key={10}>Показывать по 10</Menu.Item>
                <Menu.Item key={30}>Показывать по 30</Menu.Item>
            </Menu>
        );
    }

    onLimitDropDownClick(param: ClickParam): void {
        +param.key !== this.props.limit && this.props.onLimitChange(+param.key);
    }

    render(): JSX.Element {
        const { current, count, limit, isDisabled } = this.props;
        return (
            <div
                className={classNames(styles.pagination, {
                    [styles.paginationDisabled]: isDisabled,
                })}
            >
                <div className={styles.paginationDisabledOverlay} />
                <div className={styles.paginationPages}>
                    <ReactPaginate
                        // так точно будет ререндериться каждый раз, когда ререндерится весь Pagination,
                        // иногда рассинхронизируются выбранная страница внутри в state и страница в forcePage,
                        // когда юзер может нажать на кнопку с другой страницей, но её на самом деле не нужно менять
                        key={Math.random()}
                        pageCount={Math.ceil(count / limit)}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={0}
                        previousLabel="Предыдущая"
                        nextLabel="Следующая"
                        disableInitialCallback
                        onPageChange={this.onPageChange}
                        forcePage={current - 1}
                    />
                </div>
                <div className={styles.paginationInfo}>
                    <span>{(current - 1) * limit + 1}</span>
                    <span> — </span>
                    <span className={styles.paginationInfoTo}>
                        {count - (current - 1) * limit > limit
                            ? current * limit
                            : count}
                    </span>
                    <span>
                        из {numeral(count).format('0,0').replace(/,/g, ' ')}
                    </span>
                </div>
                <div className={styles.paginationLimitControl}>
                    <Dropdown
                        placement="bottomRight"
                        overlay={this.getMenuItems()}
                    >
                        <div>
                            Показывать по {limit} <Icon type="down" />
                        </div>
                    </Dropdown>
                </div>
            </div>
        );
    }
}
