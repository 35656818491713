import { useCallback } from 'react';

import { useActions } from 'app/hooks/useActions';
import useSearchResultsWithFeedback from 'app/hooks/useSearchResultsWithFeedback';
import { useTypedSelector } from 'app/hooks/useTypedSelector';

import { withIgnoreSearchTriggerMeta } from '../../../../redux/utils/withIgnoreSearchTriggerMeta';

const useSearchResults = <T>({
    items,
    askFeedback,
    count,
}: {
    items: Array<T>;
    askFeedback?: boolean;
    count: number;
}) => {
    const { paging } = useTypedSelector((store) => store.search);
    const { skip, limit } = paging;
    const { setSkip, fetchMoreSearchResults } = useActions();

    const onLoadMoreResults = useCallback(() => {
        if (!items) {
            return;
        }
        withIgnoreSearchTriggerMeta(setSkip(items.length));
        fetchMoreSearchResults();
    }, [items]);

    const listWithFeedback = useSearchResultsWithFeedback({
        list: items,
        askFeedback,
        skip,
    });

    const hasListWithFeedback = listWithFeedback && listWithFeedback.length > 0;

    const isButtonShown =
        hasListWithFeedback && items && items.length < count && limit < count;

    return {
        onLoadMoreResults,
        listWithFeedback,
        hasListWithFeedback,
        isButtonShown,
    };
};

export default useSearchResults;
