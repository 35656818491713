/**
 * Created by lkarmelo on 01.07.2019.
 */

import { useMemo } from 'react';
import { normalize, schema } from 'normalizr';

import * as Store from 'app/redux/store/StoreNamespace';

export const useNormalizedOrgs = (
    orgs: Store.IOrg[]
): {
    ids: string[];
    orgs: {
        [id: string]: Store.IOrg;
    };
} =>
    useMemo(() => {
        if (!orgs || !Array.isArray(orgs) || orgs.length === 0) {
            return {
                ids: [],
                orgs: {},
            };
        }
        const org = new schema.Entity('orgs');
        const normOrgs = normalize(orgs, [org]);
        return {
            ids: normOrgs.result,
            orgs: normOrgs.entities.orgs,
        };
    }, [orgs]);
