import * as Store from '../../../redux/store/StoreNamespace';
import { IDocumentDescription, IPersonHint } from '../../../redux/store/StoreNamespace';

type HintType = 'person' | 'regular' | 'filter' | 'filtersTitle';

export type RegularHintOptionMeta = {
    hintType: HintType;
    position?: Store.IQueryPosition;
    documentsCount?: number;
    sectionTitle?: string;
    originalHintQuery?: string;
    person?: IPersonHint;
    doc?: IDocumentDescription;
};

export type PersonHintOptionMeta = RegularHintOptionMeta & {
    overallPersonsCount?: number;
    fullPost?: string;
    userLogins?: Store.ISourceToLogin[];
};

export type FilterHintOptionMeta = RegularHintOptionMeta & {
    filterName?: string;
    resultSearchQuery?: string;
    displayValue?: string;
};

export type HintOptionMeta = RegularHintOptionMeta | PersonHintOptionMeta | FilterHintOptionMeta;

export const isHintRegular = (meta: HintOptionMeta): meta is RegularHintOptionMeta => meta.hintType === 'regular' || !!meta.person;
export const isHintPerson = (meta: HintOptionMeta): meta is PersonHintOptionMeta => meta.hintType === 'person';
export const isHintFilter = (meta: HintOptionMeta): meta is FilterHintOptionMeta => meta.hintType === 'filter';
export const isFiltersTitle = (meta: HintOptionMeta): meta is RegularHintOptionMeta => meta.hintType === 'filtersTitle';
