import { connect } from 'react-redux';

import * as Store from 'app/redux/store/StoreNamespace';
import { FormName } from 'app/api/FormName';
import { sendForm, setFormStatus } from 'app/redux/actions/forms';
import { updateSearchResult } from 'app/redux/actions/search/results';
import { withContext } from 'app/redux/context/connectWithContext';
import { SearchDocumentName } from 'app/redux/actions/interfaces/DocumentEnum';
import {
    confirmDocumentCategory,
    revertDocumentCategory,
} from 'app/redux/actions/catalogue/catalogueDocuments';
import {
    categoryChangeCount,
    categoryChangeUnverifiedCount,
} from 'app/redux/actions/catalogue/catalogueCategories';

import CatalogueDocumentSnippet from './CatalogueDocumentSnippet';

import {
    ICatalogueDocumentSnippetActionProps,
    ICatalogueDocumentSnippetStateProps,
} from '.';

const mapStateToProps = (
    state: Store.IState
): ICatalogueDocumentSnippetStateProps => ({
    changeCategoryFormStatus:
        state.forms[FormName.DocumentChangeCategory]?.status,
});

const mapDispatchToProps = (
    dispatch
): ICatalogueDocumentSnippetActionProps => ({
    changeCategory(
        docId: string,
        categoryId: string,
        newCategoryId: string
    ): void {
        dispatch(
            sendForm(FormName.DocumentChangeCategory, {
                docId,
                categoryId,
                newCategoryId,
            })
        );
    },
    changeCategoriesDocumentCountOnDocumentChange(
        categoryId: string,
        newCategoryId: string
    ): void {
        dispatch(categoryChangeUnverifiedCount({ categoryId, delta: -1 }));
        dispatch(categoryChangeCount({ categoryId, delta: -1 }));
        dispatch(categoryChangeCount({ categoryId: newCategoryId, delta: 1 }));
    },
    confirmCategory(docId: string, categoryId: string): void {
        dispatch(confirmDocumentCategory({ docId, categoryId }));
    },
    revertCategory(docId: string, categoryId: string): void {
        dispatch(revertDocumentCategory({ docId, categoryId }));
    },
    resetChangeCategoryForm(): void {
        dispatch(setFormStatus(FormName.DocumentChangeCategory, null));
    },
    markDocumentCategoryConfirmed(docId: string): void {
        dispatch(
            withContext(
                updateSearchResult({ id: docId, categoryConfirmed: true }),
                SearchDocumentName.catalogueDocumentsNew
            )
        );
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CatalogueDocumentSnippet);
