import { createAction } from 'redux-actions';

import * as Store from 'app/redux/store/StoreNamespace';

export interface IFetchResourceViewPayload {
    conceptUri: string;
    schemeUri: string;
}
export const fetchResourceView = createAction<IFetchResourceViewPayload>(
    'FETCH_RESOURCE_VIEW'
);

export interface ISetResourceViewPayload {
    conceptUri: string;
    prefLabels: Store.ISKOSLabel[];
    altLabels: Store.ISKOSLabel[];
    definition: Store.ISKOSValue[];
    related: Store.IConcept[];
}

export const setResourceView =
    createAction<ISetResourceViewPayload>('SET_RESOURCE_VIEW');
