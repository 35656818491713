import * as React from 'react';

import ScrollToTop from 'app/components/common/utils/ScrollToTop';
import UploadPanel from 'app/components/common/UploadPanel';

const UserFiles: React.FunctionComponent = () => (
    <section className="user_files">
        <UploadPanel path="/" />
        <ScrollToTop />
    </section>
);

export default UserFiles;
