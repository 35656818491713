/**
 * Created by Lkarmelo on 21.11.2017.
 */

import { Observable, ObservableInput } from 'rxjs/Observable';
import { concat } from 'rxjs/observable/concat';
import { merge } from 'rxjs/observable/merge';
import { MiddlewareAPI } from 'redux';
import { Action } from 'redux-actions';

import Api from 'app/api/Api';
import { RequestName } from 'app/api/RequestName';
import { ApiFeaturesName } from 'app/features/ApiFeatresName';
import { getFunctionalityAllowed, FunctionalityName } from 'app/roles';

import { createSimpleLoadingEpic, waitForSetPermissions } from './utils';

import * as Store from '../store/StoreNamespace';
import {
    fetchCurrentUserData,
    setCurrentUserData,
    IFetchCurrentUserDataPayload,
    fetchUserIdByLogin,
    setCurrentUserId,
} from '../actions/currentUser';
import {
    FETCH_CURRENT_USER_WORK_INFO,
    setCurrentUserWorkInfo,
} from '../actions/currentUserWorkInfo';
import {
    FETCH_CURRENT_USER_COMPETENCIES,
    setCurrentUserCompetencies,
} from '../actions/currentUserCompetencies';
import {
    FETCH_CURRENT_USER_KNOWLEDGE_CATEGORIES,
    setCurrentUserKnowledgeCategories,
} from '../actions/currentUserKnowledgeCategories';
import {
    FETCH_CURRENT_USER_SKILLS,
    setCurrentUserSkills,
} from '../actions/currentUserSkills';
import {
    currentUserReject,
    currentUserRequest,
    currentUserResolve,
    currentUserWorkInfoRequest,
    currentUserWorkInfoReject,
    currentUserWorkInfoResolve,
    currentUserCompetenciesRequest,
    currentUserCompetenciesResolve,
    currentUserCompetenciesReject,
    currentUserKnowledgeCategoriesReject,
    currentUserKnowledgeCategoriesRequest,
    currentUserKnowledgeCategoriesResolve,
    currentUserSkillsReject,
    currentUserSkillsRequest,
    currentUserSkillsResolve,
    userIdRequest,
    userIdResolve,
    userIdReject,
} from '../actions/loading';

export const loadCurrentUserPersonalData = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    merge(
        action$
            .ofType(fetchCurrentUserData.toString())
            .mergeMap(
                ({
                    payload: { id, login, source },
                }: Action<IFetchCurrentUserDataPayload>) => {
                    return id !== undefined
                        ? Observable.of(setCurrentUserId(id))
                        : Observable.of(fetchUserIdByLogin({ login, source }));
                }
            ),
        action$
            .ofType(fetchCurrentUserData.toString())
            .switchMap((action: Action<IFetchCurrentUserDataPayload>) => {
                const { id } = action.payload;
                if (id !== undefined) {
                    return Observable.of(id);
                }
                return action$
                    .ofType(setCurrentUserId.toString())
                    .map(({ payload }: Action<string>) => payload)
                    .take(1);
            })
            .mergeMap((userId: string) => {
                return concat(
                    apiCall
                        .currentUserData(userId)
                        .map(({ response }: { response: Store.IUser }) =>
                            setCurrentUserData(response)
                        )
                        .catch((e) => {
                            console.error(e);
                            return Observable.of(currentUserReject(e));
                        }),
                    Observable.of(currentUserResolve())
                )
                    .takeUntil(action$.ofType(currentUserReject.toString()))
                    .startWith(currentUserRequest());
            })
    );

export const loadUserIdByLogin = createSimpleLoadingEpic({
    triggers: [fetchUserIdByLogin.toString()],
    apiCallName: 'userIdByLogin',
    actions: {
        requestAction: userIdRequest,
        resolveAction: userIdResolve,
        rejectAction: userIdReject,
        setAction: setCurrentUserId,
    },
});

const waitForSetUserId = (
    action$,
    originalAction,
    store: MiddlewareAPI<Store.IState>
): ObservableInput<Action<any>> => {
    if (!store.getState().loading.pendingRequests[RequestName.UserId]) {
        return Observable.of(originalAction);
    }
    return action$
        .ofType(setCurrentUserId.toString())
        .mapTo(originalAction)
        .take(1);
};

export const loadCurrentUserWorkInfo = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(FETCH_CURRENT_USER_WORK_INFO)
        .switchMap((action) => waitForSetUserId(action$, action, store))
        .mergeMap(() =>
            concat(
                apiCall
                    .currentUserWorkInfo(store.getState().currentUser.id)
                    .map((response) =>
                        setCurrentUserWorkInfo(response.response)
                    )
                    .catch((e) => {
                        console.error(e);
                        return Observable.of(currentUserReject(e));
                    })
                    .takeUntil(action$.ofType(FETCH_CURRENT_USER_WORK_INFO)),
                Observable.of(currentUserWorkInfoResolve())
            )
                .takeUntil(action$.ofType(currentUserWorkInfoReject.toString()))
                .startWith(currentUserWorkInfoRequest())
        );

export const loadCurrentUserCompetencies = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(FETCH_CURRENT_USER_COMPETENCIES)
        .filter(() => APP_FEATURES.api[ApiFeaturesName.UserCompetencies])
        .switchMap((action) => waitForSetPermissions(action$, action, store))
        .switchMap((action) => waitForSetUserId(action$, action, store))
        .filter(() => {
            const state = store.getState();
            const isOwnProfile = state.user.id === state.currentUser.id;
            return getFunctionalityAllowed(
                state.user,
                isOwnProfile
                    ? FunctionalityName.OwnCompetenciesView
                    : FunctionalityName.ForeignCompetenciesView
            );
        })
        .mergeMap(() =>
            concat(
                apiCall
                    .currentUserCompetencies(store.getState().currentUser.id)
                    .map((response) =>
                        setCurrentUserCompetencies(response.response)
                    )
                    .catch((e) => {
                        console.error(e);
                        return Observable.of(currentUserCompetenciesReject(e));
                    })
                    .takeUntil(action$.ofType(FETCH_CURRENT_USER_COMPETENCIES)),
                Observable.of(currentUserCompetenciesResolve())
            )
                .takeUntil(
                    action$.ofType(currentUserCompetenciesReject.toString())
                )
                .startWith(currentUserCompetenciesRequest())
        );

export const loadCurrentUserKnowledgeCategories = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(FETCH_CURRENT_USER_KNOWLEDGE_CATEGORIES)
        .filter(() => APP_FEATURES.api[ApiFeaturesName.UserKnowledgeCategories])
        .switchMap((action) => waitForSetUserId(action$, action, store))
        .mergeMap(() =>
            concat(
                apiCall
                    .currentUserCategories(store.getState().currentUser.id)
                    .map((response) =>
                        setCurrentUserKnowledgeCategories(response.response)
                    )
                    .catch((e) => {
                        console.error(e);
                        return Observable.of(
                            currentUserKnowledgeCategoriesReject(e)
                        );
                    })
                    .takeUntil(
                        action$.ofType(FETCH_CURRENT_USER_KNOWLEDGE_CATEGORIES)
                    ),
                Observable.of(currentUserKnowledgeCategoriesResolve())
            )
                .takeUntil(
                    action$.ofType(
                        currentUserKnowledgeCategoriesReject.toString()
                    )
                )
                .startWith(currentUserKnowledgeCategoriesRequest())
        );

export const loadCurrentUserSkills = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(FETCH_CURRENT_USER_SKILLS)
        .filter(() => APP_FEATURES.api[ApiFeaturesName.UserSkills])
        .switchMap((action) => waitForSetUserId(action$, action, store))
        .mergeMap(() =>
            concat(
                apiCall
                    .currentUserSkills(store.getState().currentUser.id)
                    .map((response) => setCurrentUserSkills(response.response))
                    .catch((e) => {
                        console.error(e);
                        return Observable.of(currentUserSkillsReject(e));
                    })
                    .takeUntil(action$.ofType(FETCH_CURRENT_USER_SKILLS)),
                Observable.of(currentUserSkillsResolve())
            )
                .takeUntil(action$.ofType(currentUserSkillsReject.toString()))
                .startWith(currentUserSkillsRequest())
        );
