import {
    ReducersMapObject,
    combineReducers as simpleCombineReducers,
} from 'redux';
import { Reducer, Action } from 'redux-actions';

import { ContextAction } from './connectWithContext';

const contextReducer =
    <State extends {}, Payload>(contextReducerMap: ReducersMapObject) =>
    (state: State, action: ContextAction<Payload>) => {
        const { context } = action;
        const contextKey = context.length > 0 ? context[0] : '';

        const partialState = {};
        Object.keys(contextReducerMap).forEach((key) => {
            let i;
            const reducer = contextReducerMap[key];
            let actionContextKey = contextKey;
            let actionState = state;
            let lastPart = partialState;
            const paths = key.split('.');

            // travers state and check context
            for (
                i = 0;
                i < paths.length - 1 && paths[i] === actionContextKey;
                lastPart = lastPart[paths[i]],
                    actionContextKey = context.length > i ? context[i] : '',
                    i++
            ) {
                actionState = actionState && actionState[paths[i]];
                lastPart[paths[i]] = lastPart[paths[i]]
                    ? // keep old resolve part
                      lastPart[paths[i]]
                    : (!!actionState && { ...(actionState as any) }) || {};
            }

            // ?: validate and call reducer
            actionContextKey = context.length > i ? context[i] : '';
            if (paths[i] === actionContextKey) {
                actionState = actionState && actionState[paths[i]];
                const newAction = {
                    ...action,
                    context: context.slice(i + 1),
                };

                lastPart[paths[i]] = reducer(actionState, newAction);
            }
        });

        return partialState;
    };

export const contextCombineReducers = <State extends {}, Payload>(
    contextReducerMap: ReducersMapObject,
    defaults: ReducersMapObject
) => {
    const switchReducer = contextReducer(contextReducerMap);
    const defaultCombineReducersFunc = simpleCombineReducers(defaults);

    const defaultReducers = (state: State, action: Action<Payload>) => {
        const nextState = { ...(state as any) };
        Object.keys(contextReducerMap).forEach((key) => delete nextState[key]);

        // @ts-ignore
        return { ...state, ...defaultCombineReducersFunc(nextState, action) };
    };

    return (state: State, action: ContextAction<Payload> | Action<Payload>) => {
        const nextState = { ...(state as any) };

        Object.keys(contextReducerMap)
            .filter((key) => !state[key])
            .forEach((key) => {
                nextState[key] = contextReducerMap[key](undefined, action);
            });

        // ?: with context action
        const partialState =
            (<ContextAction<Payload>>action).context !== undefined
                ? switchReducer(nextState, action as ContextAction<Payload>)
                : {};

        const defaultState =
            Object.keys(partialState).length === 0
                ? defaultReducers(nextState, action as Action<Payload>)
                : { ...(nextState as any) };

        return Object.assign(defaultState, partialState);
    };
};

export const contextMixinReducers = <State extends {}, Payload>(
    contextReducerMap: ReducersMapObject,
    defaults: ReducersMapObject
) => {
    const switchReducer = contextReducer(contextReducerMap);
    const defaultCombineReducersFunc = simpleCombineReducers(defaults);

    return (state: State, action: ContextAction<Payload> | Action<Payload>) => {
        const nextState = defaultCombineReducersFunc(state, action) as State;

        // ?: with context action
        const partialState =
            (<ContextAction<Payload>>action).context !== undefined
                ? switchReducer(nextState, <ContextAction<Payload>>action)
                : {};

        return Object.keys(partialState).length === 0
            ? nextState
            : { ...nextState, ...partialState };
    };
};

export const contextReduceWrapper =
    <State extends {}, Payload extends {}>(
        contextStr: string,
        reducer: Reducer<State, Payload>
    ) =>
    (state: State, action: ContextAction<Payload>) => {
        const context = action.context || [];
        const contextKey =
            context.length > 0 ? context[context.length - 1] : '';

        if (state && contextKey !== contextStr) {
            return state;
        }

        const newAction = { ...action };
        if (context.length > 0) {
            newAction.context = context.slice(0, context.length - 1);
        } else {
            delete newAction.context;
        }

        return reducer(state, action);
    };
