/**
 * Created by Lkarmelo on 02.10.2017.
 */

import { Action, handleActions } from 'redux-actions';
import escapeStringRegexp from 'escape-string-regexp';

import { TAG_REG_EXP } from 'app/utils/constants';

import { unHighlightHints } from '../../epics/highlight';
import {
    setExecutingSearchQuery,
    setLastExecutedSearchQuery,
    setQuery,
} from '../../actions/search/searchQuery';
import {
    setStateFromLocation,
    ISetStateFromLocationPayload,
} from '../../actions/search/stateFromLocation';
import {
    addTag,
    removeTag,
    removeTags,
} from '../../actions/search/tagsFromQuery';

export const searchQueryReducer = handleActions<string, any>(
    {
        [setQuery.toString()](
            state: string | null,
            { payload }: Action<string | null>
        ): string | null {
            const isString = typeof payload === 'string';
            const payloadReplaced = isString
                ? payload.replace(/\r?\n|\r|\u21b5/g, ' ')
                : payload;
            return isString ? unHighlightHints(payloadReplaced) : payload;
        },
        [setStateFromLocation.toString()](
            state: string | null,
            { payload }: Action<ISetStateFromLocationPayload>
        ): string | null {
            if (payload.query !== undefined) {
                return payload.query;
            }
            return state;
        },
        [addTag.toString()](
            state: string | null,
            { payload }: Action<string>
        ): string | null {
            return typeof state === 'string' && !!state
                ? `${state.trimRight()} ${payload}`
                : payload;
        },
        [removeTag.toString()](
            state: string | null,
            { payload }: Action<string>
        ): string | null {
            const escapedPayload = escapeStringRegexp(payload);
            return typeof state === 'string'
                ? state
                      .replace(new RegExp(escapedPayload, 'gi'), '')
                      .trimRight()
                : state;
        },
        [removeTags.toString()](state: string | null): string | null {
            return typeof state === 'string'
                ? state
                      .replace(TAG_REG_EXP, '')
                      // заменяем двойные пробелы, которые могли остаться после удаления тегов, на одиночные
                      .replace(/ +/g, ' ')
                      .trimRight()
                : state;
        },
    },
    null // - default value for query
);

export const lastExecutedSearchQueryReducer = handleActions(
    {
        [setLastExecutedSearchQuery.toString()](
            state: string,
            { payload }: Action<string | null>
        ) {
            return payload;
        },
    },
    null // null используется вместо undefined, потому что redux не позволяет иметь undefined как начальное значение
);

export const executingSearchQueryReducer = handleActions(
    {
        [setExecutingSearchQuery.toString()](
            state: string,
            { payload }: Action<string | null>
        ) {
            return payload;
        },
    },
    null // null используется вместо undefined, потому что redux не позволяет иметь undefined как начальное значение
);
