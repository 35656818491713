/**
 * Created by lkarmelo on 28.11.2019.
 */

import React, { useCallback, useMemo, useRef } from 'react';
import { FormApi } from 'final-form';
import { Form, Field } from 'react-final-form';

import Modal from 'app/components/Modals/Modal';
import { HierarchicalSelectAdapter } from 'app/components/common/controls/HierarchicalSelect';
import { ISelectOption } from 'app/modules/components/TreeSelect';
import { useFormSenderHelper } from 'app/hooks/formHelpers';
import clientRoutes from 'app/routing/clientRoutes';
import { TOP_LEVEL_CATALOGUE_CATEGORY_LABEL } from 'app/utils/constants';

import IProps from './interfaces/IDeleteCategoryProps';
import * as styles from './DeleteCategory.scss';

import {
    EMPTY_OPTION,
    isRemovingCategoryDescendant,
} from '../utils/catalogueUtils';

const DeleteCategory: React.FC<IProps> = (props) => {
    const {
        isVisible,
        categories,
        close,
        categoryToRemoveId,
        deleteCategoryFormStatus,
        deleteCategory,
        resetDeleteCategoryForm,
        history,
        moveCategoryFormStatus,
        moveSubCategories,
        resetMoveCategoryForm,
        categoriesAsOptions,
    } = props;

    const categoryHasDescendants =
        categories.categories &&
        categories.categories[categoryToRemoveId] &&
        categories.categories[categoryToRemoveId].subItems.length > 0;

    const categoriesAsOptionsWithNullOption = useMemo(() => {
        const nextCats = categoriesAsOptions.slice();
        nextCats.unshift(EMPTY_OPTION);
        return nextCats;
    }, [categoriesAsOptions]);

    const formRef = useRef<FormApi>(null);

    const resetFormState = useCallback(() => {
        formRef.current && formRef.current.reset();
    }, []);

    // к каждой опции в селекте применяется колбек. Если опция - потомок удаляемой категории, в селекте не должно
    // быть возможности её выбрать
    const isOptionChildOfRemovingCategory = useCallback(
        (categoryId: string) => {
            if (categoryId === EMPTY_OPTION.value) {
                return false;
            }
            return isRemovingCategoryDescendant(
                categoryId,
                categoryToRemoveId,
                categories
            );
        },
        [categoryToRemoveId, categories]
    );

    const getIsOptionDisabled = useCallback(
        ({ value }: ISelectOption) => isOptionChildOfRemovingCategory(value),
        [isOptionChildOfRemovingCategory]
    );

    const onDeleteSuccess = useCallback(() => {
        history.push(clientRoutes.catalogue.getUrl({}));
        // TODO: улучшить UX
        window.location.reload();
    }, []);

    const cbDeleteMap = useMemo(
        () => ({ onSuccess: onDeleteSuccess }),
        [onDeleteSuccess]
    );
    const cbDeleteRef = useFormSenderHelper(
        deleteCategoryFormStatus,
        resetDeleteCategoryForm,
        cbDeleteMap
    );

    const onMoveSuccess = useCallback(() => {
        deleteCategory(categoryToRemoveId);
    }, [categoryToRemoveId]);

    const onMoveFail = useCallback(() => {
        cbDeleteRef.current && cbDeleteRef.current('Ошибка удаления категории');
    }, []);

    const cbMoveMap = useMemo(
        () => ({ onSuccess: onMoveSuccess, onFail: onMoveFail }),
        [onMoveSuccess]
    );
    useFormSenderHelper(
        moveCategoryFormStatus,
        resetMoveCategoryForm,
        cbMoveMap
    );

    const onSubmit = useCallback(
        (values, _, cb) => {
            cbDeleteRef.current = cb;
            if (categoryHasDescendants) {
                moveSubCategories(
                    categoryToRemoveId,
                    values.newCategory && values.newCategory.value
                        ? values.newCategory.value
                        : ''
                );
            } else {
                deleteCategory(categoryToRemoveId);
            }
        },
        [deleteCategory, categoryToRemoveId, categoryHasDescendants]
    );

    return (
        <Modal
            visible={isVisible}
            maskClosable={false}
            showCross={false}
            afterClose={resetFormState}
            className={styles.deleteCategory}
        >
            <div>
                <div className={styles.deleteCategoryTitle}>
                    Удаление категории
                </div>
                {categoryHasDescendants && (
                    <div className={styles.deleteCategoryText}>
                        Категория содержит подкатегории, перед удалением
                        выберите новое расположение подкатегорий
                    </div>
                )}
                <Form onSubmit={onSubmit}>
                    {({ handleSubmit, form, submitting }) => {
                        formRef.current = form;
                        return (
                            <form
                                onSubmit={handleSubmit}
                                className={styles.deleteCategoryForm}
                            >
                                {categoryHasDescendants && (
                                    <div>
                                        <Field
                                            name="newCategory"
                                            initialValue={EMPTY_OPTION}
                                        >
                                            {({ input }) => (
                                                <HierarchicalSelectAdapter
                                                    {...input}
                                                    options={
                                                        categoriesAsOptionsWithNullOption
                                                    }
                                                    placeholder={
                                                        TOP_LEVEL_CATALOGUE_CATEGORY_LABEL
                                                    }
                                                    valueOnDeselected={
                                                        EMPTY_OPTION
                                                    }
                                                    getIsOptionDisabled={
                                                        getIsOptionDisabled
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </div>
                                )}
                                <div className={styles.deleteCategoryControls}>
                                    <button
                                        className={`btn ${styles.deleteCategoryRemoveBtn}`}
                                        disabled={submitting}
                                    >
                                        {categoryHasDescendants
                                            ? 'Удалить и перенести'
                                            : 'Удалить'}
                                    </button>
                                    <button
                                        type="button"
                                        className={`btn ${styles.deleteCategoryCloseBtn}`}
                                        disabled={submitting}
                                        onClick={close}
                                    >
                                        Отмена
                                    </button>
                                </div>
                            </form>
                        );
                    }}
                </Form>
            </div>
        </Modal>
    );
};

export default DeleteCategory;
