import { MiddlewareAPI } from 'redux';
import { Observable } from 'rxjs/Observable';

import * as Store from '../store/StoreNamespace';
import Api from '../../api/Api';
import {
    confirmShowImage,
    fetchAvatar,
    getBigAvatar,
    onShowBigAvatar,
    setAvatars,
} from '../actions/search/results';
import {
    fetchPersonViewWidgetAvatar,
    setPersonViewWidgetAvatar,
} from '../actions/personWidget';
import { loadBigAvatarReject } from '../actions/loading';
import { fetchProfileAvatar, setProfileAvatar } from '../actions/profile';
import {
    fetchDropDownUserMenuAvatar,
    fetchLittleUserMenuAvatar,
    setDropDownUserMenuAvatar,
    setLittleUserMenuAvatar,
} from '../actions/userMenu';
import apiRoutes from '../../routing/apiRoutes';
import { bodyAvatar } from '../../utils/bodyAvatar';
import { isCached } from '../../utils/isCached';

export const loadAvatar = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$.ofType(fetchAvatar.toString()).mergeMap((action) => {
        const payload = action.payload;
        const url = apiRoutes.getUserAvatar.getUrl(bodyAvatar(payload));
        return isCached(url)
            ? Observable.of(setAvatars({ avatar: url, userId: payload.userId }))
            : apiCall
                  .getAvatar(
                      payload.userId,
                      payload.resolution,
                      payload.needCache
                  )
                  .map((response) => {
                      return setAvatars({
                          avatar: url,
                          userId: payload.userId,
                      });
                  })
                  .catch((err) => {
                      console.error(err);
                      return Observable.empty();
                  });
    });

export const loadPersonWidgetAvatar = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(fetchPersonViewWidgetAvatar.toString())
        .mergeMap((action) => {
            const payload = action.payload;
            const url = apiRoutes.getUserAvatar.getUrl(bodyAvatar(payload));
            return isCached(url)
                ? Observable.of(
                      setPersonViewWidgetAvatar({
                          avatar: url,
                          userId: payload.userId,
                      })
                  )
                : apiCall
                      .getAvatar(
                          payload.userId,
                          payload.resolution,
                          payload.needCache
                      )
                      .map((response) => {
                          return setPersonViewWidgetAvatar({
                              avatar: url,
                              userId: payload.userId,
                          });
                      })
                      .catch((err) => {
                          console.error(err);
                          return Observable.empty();
                      });
        });

export const loadBigAvatar = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$.ofType(onShowBigAvatar.toString()).mergeMap((action) => {
        const payload = action.payload;
        return apiCall
            .getAvatar(payload.userId, payload.resolution)
            .map((response) => {
                const url = apiRoutes.getUserAvatar.getUrl(bodyAvatar(payload));
                return getBigAvatar({
                    avatar: url,
                    userId: payload.userId,
                    fullTitle: payload.fullTitle,
                    resolution: payload.resolution,
                });
            })
            .catch((e) => {
                const code =
                    e.status === 500 || e.status === 504 || e.status === 404;
                console.error(e);
                return code
                    ? Observable.of(loadBigAvatarReject(e), confirmShowImage())
                    : Observable.of(confirmShowImage());
            });
    });

export const loadProfileAvatar = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$.ofType(fetchProfileAvatar.toString()).mergeMap((action) => {
        const payload = action.payload;
        return apiCall
            .getSystemAvatar(payload.userId, payload.resolution)
            .map((response) => {
                const url = apiRoutes.getSystemUserAvatar.getUrl(
                    bodyAvatar(payload)
                );
                return setProfileAvatar({
                    avatar: url,
                    userId: payload.userId,
                });
            })
            .catch((err) => {
                console.error(err);
                return Observable.empty();
            });
    });

export const loadLittleUserMenuAvatar = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$.ofType(fetchLittleUserMenuAvatar.toString()).mergeMap((action) => {
        const payload = action.payload;
        return apiCall
            .getSystemAvatar(payload.userId, payload.resolution)
            .map((response) => {
                const url = apiRoutes.getSystemUserAvatar.getUrl(
                    bodyAvatar(payload)
                );
                return setLittleUserMenuAvatar({
                    avatar: url,
                    userId: payload.userId,
                });
            })
            .catch((err) => {
                console.error(err);
                return Observable.empty();
            });
    });

export const loadDropDownUserMenuAvatar = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(fetchDropDownUserMenuAvatar.toString())
        .mergeMap((action) => {
            const payload = action.payload;
            return apiCall
                .getSystemAvatar(payload.userId, payload.resolution)
                .map((response) => {
                    const url = apiRoutes.getSystemUserAvatar.getUrl(
                        bodyAvatar(payload)
                    );
                    return setDropDownUserMenuAvatar({
                        avatar: url,
                        userId: payload.userId,
                    });
                })
                .catch((err) => {
                    console.error(err);
                    return Observable.empty();
                });
        });
