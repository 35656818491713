import { MiddlewareAPI } from 'redux';
import { Action } from 'redux-actions';
import { Observable } from 'rxjs/Observable';
import { concat } from 'rxjs/observable/concat';

import Api from 'app/api/Api';
import * as Store from 'app/redux/store/StoreNamespace';

import {
    loadCatalogueCategoryKeywords,
    setCatalogueCategoryKeywords,
} from '../../actions/catalogue/catalogueCategories';
import {
    catalogueCategoriesKeywordsReject,
    catalogueCategoriesKeywordsRequest,
    catalogueCategoriesKeywordsResolve,
} from '../../actions/loading';

export const loadCatalogueCategoryWithKeywords = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }
) =>
    action$
        .ofType(loadCatalogueCategoryKeywords.toString())
        .switchMap((action: Action<string>) =>
            concat(
                apiCall
                    .catalogueCategory(action.payload)
                    .map(
                        ({
                            response,
                        }: {
                            response: Api.ICategoryWithCatalogItem;
                        }) =>
                            setCatalogueCategoryKeywords(
                                action.payload,
                                response.keywords
                            )
                    )
                    .catch((e) => {
                        console.error(e);
                        return Observable.of(
                            catalogueCategoriesKeywordsReject(e)
                        );
                    }),
                Observable.of(catalogueCategoriesKeywordsResolve())
            )
                .takeUntil(
                    action$.ofType(catalogueCategoriesKeywordsReject.toString())
                )
                .startWith(catalogueCategoriesKeywordsRequest())
        );
