import DocumentPanel from 'app/components/document/DocumentPanel';
import connectWithContext, {
    IInjectedProps,
} from 'app/redux/context/connectWithContext';
import * as Store from 'app/redux/store/StoreNamespace';
import IDocumentPanelOwnProps from 'app/components/document/DocumentPanel/interfaces/IDocumentPanelOwnProps';
import {
    IDocumentPanelStateProps,
    IDocumentPanelActionProps,
} from 'app/components/document/DocumentPanel/interfaces/IDocumentPanelProps';
import mapActionToProps from 'app/components/document/DocumentPanelActionDefault';
import {
    SearchDocumentName,
    documentState,
} from 'app/redux/actions/interfaces/DocumentEnum';

const favoriteState = documentState(SearchDocumentName.catalogueDocuments);

interface ICatalogueDocumentsOwnProps extends IDocumentPanelOwnProps {
    activeCategoryId: string;
}

const mapStateToFactoryProps = () => {
    const constProps = {
        checkRemoved: () => false,
    };

    return (
        state: Store.IState,
        ownProps: ICatalogueDocumentsOwnProps
    ): Store.IDocumentResults &
        IInjectedProps &
        IDocumentPanelStateProps &
        IDocumentPanelOwnProps => {
        const actionState: Store.ISearch = favoriteState(state);

        return {
            rootContext: SearchDocumentName.catalogueDocuments,
            currentUserId: ownProps.activeCategoryId,
            isLoading: state.loading.pendingRequests.catalogueDocuments,
            limit: actionState.paging.limit,
            skip: actionState.paging.skip,
            ...actionState.results,
            ...constProps,
        };
    };
};

export default connectWithContext<
    Store.IDocumentResults & IDocumentPanelStateProps,
    IDocumentPanelActionProps,
    ICatalogueDocumentsOwnProps
>(
    mapStateToFactoryProps,
    mapActionToProps
)(DocumentPanel);
