/**
 * Created by Lkarmelo on 06.03.2018.
 */

import * as React from 'react';
import memoizeOne from 'memoize-one';

import DocumentPanel from 'app/components/document/DocumentPanel';
import connectWithContext, {
    IInjectedProps,
} from 'app/redux/context/connectWithContext';
import * as Store from 'app/redux/store/StoreNamespace';
import IDocumentPanelOwnProps from 'app/components/document/DocumentPanel/interfaces/IDocumentPanelOwnProps';
import {
    IDocumentPanelStateProps,
    IDocumentPanelActionProps,
} from 'app/components/document/DocumentPanel/interfaces/IDocumentPanelProps';
import mapActionToProps from 'app/components/document/DocumentPanelActionDefault';
import {
    SearchDocumentName,
    documentState,
} from 'app/redux/actions/interfaces/DocumentEnum';
import CatalogueDocumentSnippet from 'app/components/catalogue/CatalogueDocumentSnippet';
import { ISelectOption } from 'app/modules/components/TreeSelect';

const contextState = documentState(SearchDocumentName.catalogueDocumentsNew);

interface ICatalogueDocumentsOwnProps extends IDocumentPanelOwnProps {
    activeCategoryId: string;
    categories: ISelectOption[];
}

const renderCatalogueSnippet = memoizeOne(
    (categories: ISelectOption[], activeCategoryId: string) =>
        function (document: Store.IDocumentSnippet) {
            return (
                <CatalogueDocumentSnippet
                    document={document}
                    categories={categories}
                    activeCategoryId={activeCategoryId}
                />
            );
        }
);

const mapStateToFactoryProps = () => {
    const constProps = {
        checkRemoved: (document: Store.IDocumentSnippet) => {
            return document.categoryConfirmed;
        },
    };

    return (
        state: Store.IState,
        ownProps: ICatalogueDocumentsOwnProps
    ): Store.IDocumentResults &
        IInjectedProps &
        IDocumentPanelStateProps &
        IDocumentPanelOwnProps => {
        const actionState: Store.ISearch = contextState(state);

        return {
            rootContext: SearchDocumentName.catalogueDocumentsNew,
            currentUserId: ownProps.activeCategoryId,
            isLoading: state.loading.pendingRequests.catalogueDocuments,
            limit: actionState.paging.limit,
            skip: actionState.paging.skip,
            renderSearchResult: renderCatalogueSnippet(
                ownProps.categories,
                ownProps.activeCategoryId
            ),
            ...actionState.results,
            ...constProps,
        };
    };
};

export default connectWithContext<
    Store.IDocumentResults & IDocumentPanelStateProps,
    IDocumentPanelActionProps,
    ICatalogueDocumentsOwnProps
>(
    mapStateToFactoryProps,
    mapActionToProps
)(DocumentPanel);
