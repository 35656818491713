import Switch from 'antd/es/switch';
import React, { useCallback } from 'react';

import { useActions } from 'app/hooks/useActions';
import { useTypedSelector } from 'app/hooks/useTypedSelector';

import IProps from './types';

import SearchSortingPanel from '../SortingPanel';
import DocumentResultsMessage from '../SearchResultsMessage/SearchResultsMessage';
import * as styles from '../../SearchResult.scss';

const SearchResultMessageRow: React.FunctionComponent<IProps> = (props) => {
    const { list, focusSearchQueryInput } = props;

    const { personalization, lastExecutedSearchQuery } = useTypedSelector(
        (store) => store.search
    );
    const { showPersonalizationSwitch } = useTypedSelector(
        (store) => store.searchTabs?.tabs[store.mainSearchPageActiveTab]
    );
    const { setPersonalization } = useActions();
    const onSwitchChange = useCallback(
        (checked) => setPersonalization(checked),
        []
    );

    return (
        <div className={styles.searchResultResultMsgRowWrapper}>
            {lastExecutedSearchQuery !== null && (
                <DocumentResultsMessage
                    focusSearchQueryInput={focusSearchQueryInput}
                />
            )}
            {list && (
                <div className={styles.searchResultSortingAndPersonalization}>
                    {list.length > 0 && <SearchSortingPanel />}
                    {showPersonalizationSwitch && (
                        <div className={styles.searchResultPersonalization}>
                            <div>Персонализация</div>
                            <Switch
                                checked={personalization}
                                onChange={onSwitchChange}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchResultMessageRow;
