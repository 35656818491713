/**
 * Created by Lkarmelo on 13.10.2017.
 */

import urlTemplate from 'url-template';
import { parse, Token } from 'path-to-regexp';

import Route from './Route';

export type TSubRoutesConfig = { [subRouteName: string]: ClientRoute };

export default class ClientRoute<
    SubRoutesConfig extends TSubRoutesConfig = {},
    TParams extends string = any,
    TQuery extends string = any
> extends Route<TParams, TQuery> {
    originalPath: string = null;

    reactRouterTemplate: string = null;

    subRoutes: SubRoutesConfig = null;

    constructor(
        path: string,
        subRoutesConf?: { [subRouteName: string]: string | ClientRoute }
    ) {
        super();

        this.originalPath = path;

        if (subRoutesConf) {
            this.createSubRoutes(path, subRoutesConf);
        }

        this.updatePath(path);

        this.updateSubRoutes();
    }

    updatePath(path: string): void {
        this.reactRouterTemplate = path;

        const tokens: Token[] = parse(path);
        let template = '';

        tokens.forEach((token: Token) => {
            if (typeof token === 'string') {
                template += token;
            } else {
                template += `{${token.prefix}${token.name}}`;
            }
        });
        this.template = urlTemplate.parse(template);
    }

    updateSubRoutes(): void {
        if (this.subRoutes)
            Object.keys(this.subRoutes).forEach((routeName) => {
                this.subRoutes[routeName].updatePath(
                    this.reactRouterTemplate +
                        this.subRoutes[routeName].originalPath
                );
                this.subRoutes[routeName].updateSubRoutes();
            });
    }

    createSubRoutes(
        path: string,
        subRoutesConf: { [subRouteName: string]: string | ClientRoute }
    ) {
        this.subRoutes = <SubRoutesConfig>{};
        Object.keys(subRoutesConf).forEach((key) => {
            const subRoute = subRoutesConf[key];

            if (typeof subRoute === 'string') {
                (this.subRoutes as TSubRoutesConfig)[key] = new ClientRoute(
                    subRoute
                );
            } else {
                (this.subRoutes as TSubRoutesConfig)[key] = <ClientRoute>(
                    subRoutesConf[key]
                );
            }
        });
    }
}
