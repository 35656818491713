/**
 * Created by Lkarmelo on 28.09.2017.
 */

import { createAction } from 'redux-actions';

import * as Store from '../../store/StoreNamespace';

export const setDefaultFilters = createAction<Store.IFiltersMeta>(
    'SET_DEFAULT_FILTERS'
);

export type FiltersToDefaultsPayload = { removeTags: boolean } | void;
export const filtersToDefaults = createAction<FiltersToDefaultsPayload>(
    'FILTERS_TO_DEFAULTS'
);
