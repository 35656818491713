/**
 * Created by lkarmelo on 27.11.2019.
 */

import React, { useCallback } from 'react';

import { ISelectOption } from 'app/modules/components/TreeSelect';

import HierarchicalSelect from './HierarchicalSelect';
import IProps from './interfaces/IHierarchicalSelectAdapterProps';

const HierarchicalSelectAdapter: React.FC<IProps> = (props) => {
    const {
        onChange,
        onFocus,
        onBlur,
        value,
        disallowEmpty,
        valueOnDeselected,
        ...rest
    } = props;

    const onDeselect = useCallback(
        (option: ISelectOption) => {
            !disallowEmpty && onChange(valueOnDeselected);
        },
        [onChange, disallowEmpty, valueOnDeselected]
    );

    const onSelect = useCallback(
        (option: ISelectOption) => {
            onChange(option);
        },
        [onChange]
    );

    return (
        <HierarchicalSelect
            {...rest}
            active={value}
            onSelect={onSelect}
            onDeselect={onDeselect}
        />
    );
};

HierarchicalSelectAdapter.defaultProps = {
    valueOnDeselected: '',
};

export default HierarchicalSelectAdapter;
