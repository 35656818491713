/**
 * Created by lkarmelo on 13.11.2019.
 */

import React, { useEffect, useState } from 'react';
import Tooltip from 'antd/es/tooltip';
import Button from 'antd/es/button/button';
import { message } from 'antd';

import { prependHttp } from 'app/utils/prependHttp';
import { RedirectLevel } from 'app/utils/constants';

import IProps from './interfaces/IOuterRedirectProps';
import * as styles from './OuterRedirect.scss';

import { IEVersion } from '../../../utils/IEVersion';

const OuterRedirect: React.FC<IProps> = ({ link, timeout, checkLinks }) => {
    const [availabilityLevel, setAvailabilityLevel] = useState<RedirectLevel>(
        RedirectLevel.redirect
    );
    useEffect(() => {
        if (!link) {
            return;
        }
        const redirect = () => {
            const timeoutId = window.setTimeout(() => {
                // replace нужен, чтобы пользователь не мог кнопкой "назад" попасть обратно на страницу редиректа
                window.location.replace(prependHttp(link));
            }, timeout);

            return () => window.clearInterval(timeoutId);
        };
        const isLocal =
            /(^https?:\/\/.+\.local(\/.*)?)|(^https?:\/\/10)|(^https?:\/\/[A-Za-z0-9--]+\/)/gm.test(
                link
            );
        const isFile = /(^[A-Za-z]:)|(^file:)/gm.test(link);

        if (isFile) {
            if (IEVersion !== null) {
                return redirect();
            }
            setAvailabilityLevel(RedirectLevel.redirectDFSError);
            return;
        }

        const checkLink = (tmpLink) => {
            // Создаем img для проверки доступности сервера для клиента. Картинка для обхода отсутствия CORS заголовков
            const tester = new Image();
            tester.onload = function () {
                redirect();
            };
            tester.onerror = function (error: any) {
                console.error(error);
                setAvailabilityLevel(
                    isLocal
                        ? RedirectLevel.redirectLocalError
                        : RedirectLevel.redirectGlobalError
                );
            };
            tester.src = tmpLink;
        };

        if (checkLinks && checkLinks[0]) {
            checkLink(checkLinks[0]);
        } else {
            return redirect();
        }
    }, [link, timeout, checkLinks]);

    const copyLink = () => {
        const aux = document.createElement('input');

        // Assign it the value of the specified element
        aux.setAttribute('value', link);

        // Append it to the body
        document.body.appendChild(aux);

        // Highlight its content
        aux.select();

        // Copy the highlighted text
        document.execCommand('copy');

        // Remove it from the body
        document.body.removeChild(aux);

        message.success('Ссылка скопирована в буфер обмена');
    };

    return (
        <div className={styles.outerRedirect}>
            <div className={styles.outerRedirectText}>
                {availabilityLevel === RedirectLevel.redirect && (
                    <span className={styles.outerRedirectTitle}>
                        Происходит переход по ссылке, пожалуйста, подождите
                    </span>
                )}
                {availabilityLevel === RedirectLevel.redirectLocalError && (
                    <span className={styles.outerRedirectTitle}>
                        Похоже, у вас закрыты межсетевые экраны, переход к
                        оригиналу невозможен.
                        <br /> Перейдите в{' '}
                        <a
                            href="http://selfservice.gazprom-neft.local/SitePages/Home20.aspx#/lk/s2|s3|78|78T::tplname=714"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            КСУИТ
                        </a>{' '}
                        для регистрации обращения
                        <br /> на изменение списков доступа на межсетевых
                        экранах.
                    </span>
                )}
                {availabilityLevel === RedirectLevel.redirectGlobalError && (
                    <span className={styles.outerRedirectTitle}>
                        Для перехода к оригиналу документа скопируйте ссылку
                        ниже
                        <br /> и перейдите по ней в браузере с выходом в
                        Интернет.
                        <br />
                        Чтобы запустить браузер с выходом в Интернет откройте
                        меню "Пуск" и в списке программ <br /> выберите{' '}
                        <span className={styles.outerRedirectIe} /> Microsoft
                        Edge Chromium /{' '}
                        <span className={styles.outerRedirectIe} /> IE_SOBD{' '}
                        <br /> или используйте кнопку "Выход в интернет" на{' '}
                        <a
                            href="http://portal.gazprom-neft.local/Pages/default.aspx"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            корпоративном портале
                        </a>
                    </span>
                )}
                {availabilityLevel === RedirectLevel.redirectDFSError && (
                    <span className={styles.outerRedirectTitle}>
                        Для перехода к оригиналу документа скопируйте ссылку
                        ниже <br /> и перейдите по ней в проводнике своей
                        операционной системы.
                    </span>
                )}
                {link && (
                    <>
                        <Tooltip
                            title={
                                <div className={styles.outerRedirectTooltip}>
                                    Нажмите для копирования ссылки
                                </div>
                            }
                        >
                            <span
                                className={styles.outerRedirectLink}
                                onClick={copyLink}
                            >
                                {link}
                            </span>
                        </Tooltip>
                        {availabilityLevel !== RedirectLevel.redirect && (
                            <Button
                                size="large"
                                className={styles.outerRedirectBt}
                                onClick={copyLink}
                            >
                                Копировать ссылку
                            </Button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

OuterRedirect.defaultProps = {
    timeout: 2000,
};

export default OuterRedirect;
