/**
 * Created by Lkarmelo on 05.06.2018.
 */

import React, { PureComponent } from 'react';
import autoBind from 'autobind-decorator';
import AntCheckBox, { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';

import IProps from './interfaces/ICheckBoxProps';
import * as styles from './CheckBox.scss';

export default class CheckBox extends PureComponent<IProps> {
    render(): JSX.Element {
        const { onChange, className, ...restProps } = this.props;

        return (
            <div className={`${styles.checkBox} ${className}`}>
                <AntCheckBox {...restProps} onChange={this.onChange}>
                    {this.props.children}
                </AntCheckBox>
            </div>
        );
    }

    @autoBind
    onChange(e: CheckboxChangeEvent) {
        this.props.onCheckedChange(e.target.checked, this.props.meta);
    }
}
