import * as React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { setModalVisible } from 'app/redux/actions/modals';
import { ModalName } from 'app/redux/store/StoreNamespace';

import styles from './Feedback.scss';

interface IFeedbackDispatchProps {
    setReportVisible(): void;
}

interface IFeedbackOwnProps {
    className?: string;
}

interface IProps extends IFeedbackDispatchProps, IFeedbackOwnProps {}

const Feedback: React.FC<IProps> = ({ className, setReportVisible }) => {
    return (
        <div className={classNames(className, styles.feedback)}>
            <i
                className={classNames('nkc-icon-thumb-up', styles.feedbackLike)}
            />
            <p className={styles.feedbackText}>
                <em className={styles.feedbackTextTitle}>
                    Помогите улучшить сервис поиска
                </em>
                <br />
                <span className={styles.feedbackTextDescription}>
                    У вас есть идея улучшения или что-то работает не так?
                    Напишите нам.
                </span>
            </p>
            <button
                className={classNames('btn', styles.feedbackButton)}
                onClick={() => setReportVisible()}
            >
                Оставить отзыв
            </button>
        </div>
    );
};

const mapDispatchToProps = (dispatch): IFeedbackDispatchProps => ({
    setReportVisible(): void {
        dispatch(setModalVisible(ModalName.Report, true));
    },
});

export default connect(null, mapDispatchToProps)(Feedback);
