import moment from 'moment';

export const getBirthDateView = (date: string) => {
    const normalizedDate = date.slice(4, 8);

    return moment(normalizedDate, 'MMDD').format('DD MMMM');
};

export const getFullAddress = (userAddress?: string, workplaceNum?: string) => {
    if (userAddress && workplaceNum) {
        return `${userAddress}, рабочее место ${workplaceNum}`;
    }

    return `${userAddress || ''}${
        workplaceNum ? `рабочее место ${workplaceNum}` : ''
    }`;
};

// В полях telInternal, telMobile, telOffice может быть указано несколько номеров через запятую
// поэтому надо слить всё в одно строку и затем разделить

export const getTelephones = (arr) =>
    [...arr]
        .join(',')
        .split(',')
        .map((tel) => tel.trim())
        .filter(Boolean);

export const mapToMailBody = (obj: object) =>
    Object.entries(obj)
        .filter(([_, value]) => value)
        .map(([title, value]) => `${title}: ${value}`)
        .join('\r\n');
