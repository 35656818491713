import * as React from 'react';

import DocumentPanel from 'app/components/document/DocumentPanel/DocumentPanelConnected';
import connectWithContext, {
    IInjectedProps,
} from 'app/redux/context/connectWithContext';
import * as Store from 'app/redux/store/StoreNamespace';
import { IDocumentPanelStateProps } from 'app/components/document/DocumentPanel/interfaces/IDocumentPanelProps';
import mapActionToProps from 'app/components/document/DocumentPanelActionDefault';
import {
    SearchDocumentName,
    documentState,
} from 'app/redux/actions/interfaces/DocumentEnum';

const allDocumentsState = documentState(
    SearchDocumentName.documentManagementOwn
);

const DocumentFilterPanel = () => {
    return null;
};

const mapStateToFactoryProps = () => {
    const constProps = {
        renderFilterPanel: (): JSX.Element => {
            return <DocumentFilterPanel />;
        },
        checkRemoved: () => false,
    };

    return (
        state: Store.IState
    ): Store.IDocumentResults & IInjectedProps & IDocumentPanelStateProps => {
        const actionState: Store.ISearch = allDocumentsState(state);

        return {
            rootContext: SearchDocumentName.documentManagementOwn,
            isLoading: state.loading.pendingRequests.search,
            limit: actionState.paging.limit,
            skip: actionState.paging.skip,
            ...actionState.results,
            ...constProps,
        };
    };
};

export default connectWithContext(
    mapStateToFactoryProps,
    mapActionToProps
)(DocumentPanel);
