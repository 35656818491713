/**
 * Created by Lkarmelo on 04.12.2017.
 */

import React, { PureComponent } from 'react';
import autoBind from 'autobind-decorator';
import Row from 'antd/es/row';
import Col from 'antd/es/col';

import IProps from './interfaces/ICategoriesProps';
import IState from './interfaces/ICategoriesState';
import * as styles from './Categories.scss';

import Chart from '../Chart/Chart';

const legendColors = ['#2D9CDB', '#6FCF97', '#56CCF2', '#F16F36', '#977BB2'];

export default class Categories extends PureComponent<IProps, IState> {
    state = {
        isLegendTruncated: true,
    };

    chartRef: React.RefObject<Chart> = React.createRef();

    isTipShownByClick = false;

    maxNonTruncatedLegendLength = 10;

    render(): JSX.Element {
        const { categories } = this.props;
        const colorMap = {};
        categories.forEach((cat, index) => {
            colorMap[cat.item.id] = legendColors[index % 5];
        });
        return (
            <div
                className="categories"
                onMouseLeave={(e: React.MouseEvent<HTMLDivElement>) => {
                    if (this.isTipShownByClick) {
                        const chart = this.chartRef.current;
                        const tipElement: HTMLElement = chart.getTipElement();
                        if (
                            !tipElement.contains(
                                e.nativeEvent.relatedTarget as Node
                            )
                        ) {
                            this.chartRef.current.hideTip();
                        }
                    }
                    this.isTipShownByClick = false;
                }}
            >
                <div className="categories__title">
                    Связанные документы по категориям
                </div>
                <Row className="categories__body">
                    <Col className="categories__chart" lg={12}>
                        <Chart
                            ref={this.chartRef}
                            categories={categories}
                            colorsMap={colorMap}
                        />
                    </Col>
                    <Col lg={12} className="categories__legend">
                        {this.renderLegend(colorMap)}
                        {categories.length >
                            this.maxNonTruncatedLegendLength && (
                            <button
                                className={`btn ${styles.categoriesLegendTruncate}`}
                                onClick={this.toggleTruncateLegend}
                            >
                                Показать{' '}
                                {this.state.isLegendTruncated ? 'всё' : 'часть'}
                            </button>
                        )}
                    </Col>
                </Row>
            </div>
        );
    }

    renderLegend(colorMap: { [catId: string]: string }): JSX.Element[] {
        const { categories } = this.props;
        const categoriesShown: number =
            this.state.isLegendTruncated &&
            categories.length > this.maxNonTruncatedLegendLength
                ? this.maxNonTruncatedLegendLength
                : categories.length;
        const res: JSX.Element[] = [];

        for (let i = 0; i < categoriesShown; i++) {
            const cat = categories[i];
            res.push(
                <div
                    key={cat.item.id}
                    className="categories__legend-item clearfix"
                    onClick={() => {
                        const chart = this.chartRef.current;
                        chart.showSlicePercentage(cat.item.id);
                        chart.showTip(cat.item.id);
                        this.isTipShownByClick = true;
                    }}
                >
                    <div
                        className="categories__legend-sample"
                        style={{ background: colorMap[cat.item.id] }}
                    />
                    <div className="categories__legend-desc">
                        {cat.item.title}
                    </div>
                </div>
            );
        }

        return res;
    }

    @autoBind
    toggleTruncateLegend(): void {
        this.setState(
            (state) => ({ isLegendTruncated: !state.isLegendTruncated }),
            this.onTruncateChange
        );
    }

    @autoBind
    onTruncateChange() {
        this.props.onTruncateChange &&
            this.props.onTruncateChange(this.state.isLegendTruncated);
    }
}
