import * as React from 'react';
import Tooltip, { TooltipProps } from 'antd/es/tooltip';
import classNames from 'classnames';
import Icon, { InfoCircleOutlined } from '@ant-design/icons';

import * as styles from './InfoTooltip.scss';

interface IProps extends TooltipProps {
    message: string;
    className?: string;
}

const InfoTooltip: React.FC<IProps> = ({
    message,
    className,
    ...tooltipProps
}) => {
    return (
        <Tooltip
            {...tooltipProps}
            title={message}
            mouseEnterDelay={0.3}
            z-index={15000}
        >
            <Icon
                component={
                    InfoCircleOutlined as React.ForwardRefExoticComponent<any>
                }
                className={classNames(styles.infoTooltipIcon, className)}
            />
        </Tooltip>
    );
};

export default InfoTooltip;
