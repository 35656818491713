import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useMemoThrottled } from '@nkc-frontend/nkc-react-hooks';
import { usePrevious } from 'react-use';

import List from '../List/List';
import OptionGroup from '../OptionGroup/OptionGroup';
import Option from '../Option/Option';
import SearchBar from '../SearchBar/SearchBar';
import Header from '../Header/Header';
import CollapseToggle from '../CollapseToggle/CollapseToggle';

export interface ISelectOption {
    value: string;
    label: string;
    options?: ISelectOption[];
    count?: number;
    fixed?: boolean;
    [key: string]: any;
}

type AnyProps = { [propName: string]: any };

export interface IProps<
    ListProps extends AnyProps = AnyProps,
    HeaderProps extends AnyProps = AnyProps,
    SearchBarProps extends AnyProps = AnyProps,
    CollapseToggleProps extends AnyProps = AnyProps
> {
    options: ISelectOption[];
    active: string[];
    intermediate?: string[] | ((active: Record<string, boolean>) => string[]);
    className?: string;
    collapsible?: boolean;
    collapsed?: boolean;
    initiallyCollapsed?: boolean;
    searchQuery?: string;
    optionFilteringThrottleWait?: number;
    hierarchical?: boolean;
    isTags?: boolean;
    /**
     * Включает виртуальный рендеринг опций в List, с помощью либы react-vtree
     */
    enableVirtualRendering?: boolean;
    /**
     * высота элемента списка при включённом виртуальном рендеринге, см. документацию react-vtree и react-window
     */
    itemSize?: number;

    headerComponentProps?: Partial<HeaderProps>;
    searchBarComponentProps?: Partial<SearchBarProps>;
    listComponentProps?: Partial<ListProps>;
    collapseToggleComponentProps?: Partial<CollapseToggleProps>;
    HeaderComponent?: React.ComponentType<HeaderProps>;
    SearchBarComponent?: React.ComponentType<SearchBarProps>;
    ListComponent?: React.ComponentType<ListProps>;
    CollapseToggleComponent?: React.ComponentType<CollapseToggleProps>;

    onSelect?: ListProps['onSelect'];
    onDeselect?: ListProps['onDeselect'];
    getFilteredOptions?(
        searchQuery: string,
        options: ISelectOption[]
    ): ISelectOption[];
}

const defaultGetFilteredOptions = (
    searchQuery: string,
    options: ISelectOption[]
) => {
    if (!searchQuery) {
        return options;
    }

    const searchQueryLowerCase = searchQuery.toLowerCase();

    const optionFilterCb = (result: ISelectOption[], option: ISelectOption) => {
        const { label, options: subOptions } = option;

        const searchQueryInLabel = label
            .toLowerCase()
            .includes(searchQueryLowerCase);

        if (Array.isArray(subOptions)) {
            const filteredSubOptions = subOptions.reduce(optionFilterCb, []);

            if (searchQueryInLabel) {
                const optionToPush = {
                    ...option,
                    options:
                        filteredSubOptions.length > 0
                            ? filteredSubOptions
                            : null,
                };
                result.push(optionToPush);
            } else if (filteredSubOptions.length > 0) {
                result.push(...filteredSubOptions);
            }

            return result;
        }

        searchQueryInLabel && result.push(option);
        return result;
    };

    return options.reduce(optionFilterCb, []);
};

const TreeSelect = React.forwardRef<HTMLDivElement, IProps>(
    (props, forwardedRef) => {
        const {
            options,
            active,
            HeaderComponent,
            SearchBarComponent,
            ListComponent,
            headerComponentProps,
            listComponentProps,
            searchBarComponentProps,
            getFilteredOptions,
            onSelect,
            onDeselect,
            collapsible,
            initiallyCollapsed,
            collapseToggleComponentProps,
            CollapseToggleComponent,
            className,
            searchQuery: searchQueryProp,
            intermediate,
            optionFilteringThrottleWait,
            enableVirtualRendering,
            itemSize,
            collapsed: collapsedProp,
        } = props;

        const prevSearchQueryProp = usePrevious(searchQueryProp);
        const prevCollapsedProp = usePrevious(collapsedProp);

        const [searchQueryState, setSearchQueryState] = useState('');
        const [isCollapsed, setIsCollapsed] = useState(initiallyCollapsed);

        if (
            typeof searchQueryProp === 'string' &&
            prevSearchQueryProp !== searchQueryProp &&
            searchQueryProp !== searchQueryState
        ) {
            setSearchQueryState(searchQueryProp);
        }
        if (
            typeof collapsedProp === 'boolean' &&
            prevCollapsedProp !== collapsedProp &&
            collapsedProp !== isCollapsed
        ) {
            setIsCollapsed(collapsedProp);
        }

        const filterOptions = useMemoThrottled(
            () =>
                (opts: ISelectOption[]): ISelectOption[] =>
                    getFilteredOptions(searchQueryState, opts),
            [getFilteredOptions],
            [searchQueryState],
            optionFilteringThrottleWait,
            false,
            true
        );

        const toggleCollapse = useCallback(
            (nextCollapseState: boolean) => {
                setIsCollapsed(nextCollapseState);
                nextCollapseState && setSearchQueryState('');
            },
            [setIsCollapsed]
        );

        return (
            <div
                ref={forwardedRef}
                className={classNames('nkcm-select', className, {
                    'nkcm-select--collapsible': collapsible,
                    'nkcm-select--collapsed': isCollapsed,
                })}
            >
                <HeaderComponent
                    searchQuery={searchQueryState}
                    collapsed={collapsible && isCollapsed}
                    {...headerComponentProps}
                />
                <SearchBarComponent
                    searchQuery={searchQueryState}
                    setSearchQuery={setSearchQueryState}
                    collapsed={collapsible && isCollapsed}
                    {...searchBarComponentProps}
                />
                <ListComponent
                    options={options}
                    filterOptions={filterOptions}
                    active={active}
                    intermediate={intermediate}
                    collapsed={collapsible && isCollapsed}
                    OptionGroupComponent={OptionGroup}
                    OptionComponent={Option}
                    showNoFoundPlaceholderOnNoOptions={!!searchQueryState}
                    onSelect={onSelect}
                    onDeselect={onDeselect}
                    enableVirtualRendering={enableVirtualRendering}
                    itemSize={itemSize}
                    {...listComponentProps}
                />
                <CollapseToggleComponent
                    collapsible={collapsible}
                    collapsed={isCollapsed}
                    toggleCollapse={toggleCollapse}
                    {...collapseToggleComponentProps}
                />
            </div>
        );
    }
);

TreeSelect.defaultProps = {
    initiallyCollapsed: true,
    optionFilteringThrottleWait: 0,
    getFilteredOptions: defaultGetFilteredOptions,
    ListComponent: List,
    SearchBarComponent: SearchBar,
    HeaderComponent: Header,
    CollapseToggleComponent: CollapseToggle,
    onDeselect: () => {},
    active: [],
    intermediate: [],
};

export default TreeSelect;
