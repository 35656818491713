import { createAction } from 'redux-actions';

import * as Store from '../store/StoreNamespace';

export const fetchLittleUserMenuAvatar = createAction(
    'FETCH_LITTLE_USER_MENU_AVATAR'
);
export const setLittleUserMenuAvatar = createAction<Store.IPersonAvatar>(
    'SET_LITTLE_USER_MENU_AVATAR'
);

export const fetchDropDownUserMenuAvatar = createAction(
    'FETCH_DROP_DOWN_USER_MENU_AVATAR'
);
export const setDropDownUserMenuAvatar = createAction<Store.IPersonAvatar>(
    'SET_DROP_DOWN_LITTLE_USER_MENU_AVATAR'
);
