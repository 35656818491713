import React, {
    CSSProperties,
    useRef,
    useCallback,
    useEffect,
    useState,
    useMemo,
} from 'react';
import classNames from 'classnames';

import { debounce } from 'app/utils/debounce';

import { useDescendantsAscendantsMaps } from './useDescendantsAscendatsMaps';
import { RegularOptionsRenderer } from './RegularOptionsRenderer';
import { VirtualOptionsRenderer } from './VirtualOptionsRenderer';

import OptionGroup from '../OptionGroup/OptionGroup';
import Option from '../Option/Option';
import { ISelectOption } from '../Select/TreeSelect';

type SortOptionsFunc = (
    optionA: ISelectOption,
    optionB: ISelectOption,
    activeMap?: Record<string, boolean>
) => number;
export type GetPropsFunc = (option: ISelectOption) => Record<string, any>;

export type ListSelectDeselectFunc = (
    option: ISelectOption,
    descendantsMap: Record<string, ISelectOption[]>,
    ascendantsMap: Record<string, ISelectOption[]>
) => void;

type IntermediateFunc = (
    active: Record<string, boolean>,
    ascendantsMap: Record<string, ISelectOption[]>,
    descendantsMap: Record<string, ISelectOption[]>
) => string[];

export interface IListProps {
    options: ISelectOption[];
    active: string[];
    intermediate?: string[] | IntermediateFunc;
    className?: string;
    initiallyOpenedNestedLevel?: number;
    showNoFoundPlaceholderOnNoOptions?: boolean;
    noFoundPlaceholder?: string;
    collapsedOptionsCount?: number;
    collapsed?: boolean;
    withDelay?: boolean;
    /**
     * функция, которая будет использоваться для сортировки всех опций.
     * если определена, то sortChildOptions не будет использоваться
     */
    compareOptions?: SortOptionsFunc;
    /**
     * этот колбек будет вызван для сортировки дочерних опций, если родительская опция раскрыта.
     * вызывается при каждом рендере
     * если в props есть compareOptions, то sortChildOptions не будет использоваться
     */
    sortChildOptions?: (
        options: ISelectOption[],
        parentOption?: ISelectOption,
        activeMap?: Record<string, boolean>
    ) => ISelectOption[];
    /**
     * включает виртуальный рендеринг опций с помощью либы react-vtree
     */
    enableVirtualRendering?: boolean;
    /**
     * высота элемента списка при включённом виртуальном рендеринге, см. документацию react-vtree и react-window
     */
    itemSize?: number;

    filterOptions?: (options: ISelectOption[]) => ISelectOption[];

    style?: CSSProperties;

    OptionGroupComponent?: React.ComponentType<any>;
    OptionComponent?: React.ComponentType<any>;
    optionComponentProps?: Record<string, any> | GetPropsFunc;
    optionGroupComponentProps?: Record<string, any> | GetPropsFunc;

    onSelect: ListSelectDeselectFunc;
    onDeselect: ListSelectDeselectFunc;
}

export const sortOptionsByCount = (
    optionA: ISelectOption,
    optionB: ISelectOption
) => {
    if (
        (optionA.count !== undefined && optionB.count === undefined) ||
        optionA.count > optionB.count
    ) {
        return -1;
    }
    if (
        (optionA.count === undefined && optionB.count !== undefined) ||
        optionA.count < optionB.count
    ) {
        return 1;
    }
    return 0;
};

export const sortOptionsByActiveState = (
    optionA: ISelectOption,
    optionB: ISelectOption,
    activeMap: Record<string, boolean>
) => {
    const isOptAActive = activeMap[optionA.value];
    const isOptBActive = activeMap[optionB.value];

    if (isOptAActive && !isOptBActive) {
        return -1;
    }
    if (!isOptAActive && isOptBActive) {
        return 1;
    }
    return 0;
};

export const sortOptionsWithSubOptions = (
    options: ISelectOption[],
    sorter: SortOptionsFunc,
    activeMap?: Record<string, boolean>
): ISelectOption[] =>
    options
        .slice()
        .sort((a, b) => sorter(a, b, activeMap))
        .map((option) => {
            if (!option.options) {
                return option;
            }
            const nextOption = { ...option };
            nextOption.options = sortOptionsWithSubOptions(
                nextOption.options,
                sorter,
                activeMap
            );
            return nextOption;
        });

export const LIST_ACTION_DELAY = 1000;

const List: React.FC<IListProps> = (props) => {
    const {
        options,
        active,
        onDeselect,
        onSelect,
        className,
        OptionGroupComponent,
        OptionComponent,
        showNoFoundPlaceholderOnNoOptions,
        initiallyOpenedNestedLevel,
        noFoundPlaceholder,
        optionGroupComponentProps,
        optionComponentProps,
        collapsed,
        collapsedOptionsCount,
        compareOptions,
        intermediate,
        enableVirtualRendering,
        itemSize,
        sortChildOptions,
        filterOptions,
        style,
        withDelay,
    } = props;

    const [sortedOptions, setSortedOptions] = useState<ISelectOption[]>([]);
    const [filteredOptions, setFilteredOptions] = useState<ISelectOption[]>([]);
    const [optionsToRender, setOptionsToRender] = useState<ISelectOption[]>([]);
    const isDisabledAction = useRef(false);
    const wrapperRef = useRef<HTMLDivElement>();

    const activeMap = useMemo<Record<string, boolean>>(
        () =>
            active.reduce((map, activeElement) => {
                map[activeElement] = true;
                return map;
            }, {}),
        [active]
    );

    const { ascendantsMap, descendantsMap } =
        useDescendantsAscendantsMaps(options);

    const intermediateValues = useMemo(() => {
        if (typeof intermediate === 'function') {
            return intermediate(activeMap, ascendantsMap, descendantsMap);
        }
        return intermediate;
    }, [intermediate, activeMap, ascendantsMap, descendantsMap]);

    const intermediateMap = useMemo<Record<string, boolean>>(
        () =>
            intermediateValues.reduce((map, intermediateElement) => {
                map[intermediateElement] = true;
                return map;
            }, {}),
        [intermediateValues]
    );

    const debouncedSetSortedOptions = debounce(
        () =>
            setSortedOptions(
                typeof compareOptions === 'function'
                    ? sortOptionsWithSubOptions(
                          options,
                          compareOptions,
                          activeMap
                      )
                    : options
            ),
        LIST_ACTION_DELAY
    );

    useEffect(() => {
        withDelay && isDisabledAction.current
            ? debouncedSetSortedOptions()
            : setSortedOptions(
                  typeof compareOptions === 'function'
                      ? sortOptionsWithSubOptions(
                            options,
                            compareOptions,
                            activeMap
                        )
                      : options
              );
    }, [options, compareOptions, activeMap]);

    useEffect(() => {
        setFilteredOptions(
            typeof filterOptions === 'function'
                ? filterOptions(sortedOptions)
                : sortedOptions
        );
    }, [sortedOptions, filterOptions]);

    useEffect(() => {
        setOptionsToRender(
            collapsed
                ? filteredOptions.slice(0, collapsedOptionsCount)
                : filteredOptions
        );
    }, [filteredOptions, collapsed, collapsedOptionsCount]);

    const _onSelect = useCallback(
        (opt) => {
            isDisabledAction.current = false;
            onSelect(opt, descendantsMap, ascendantsMap);
        },
        [onSelect, descendantsMap, ascendantsMap]
    );

    const _onDeselect = useCallback(
        (opt) => {
            isDisabledAction.current = true;
            onDeselect(opt, descendantsMap, ascendantsMap);
        },
        [onDeselect, descendantsMap, ascendantsMap]
    );

    const getSortedOptionChildren = useCallback(
        (
            optionsToSort: ISelectOption[],
            parentOption: ISelectOption | undefined
        ): ISelectOption[] => {
            if (typeof compareOptions === 'function') {
                return optionsToSort;
            }
            if (typeof sortChildOptions === 'function') {
                return sortChildOptions(optionsToSort, parentOption, activeMap);
            }
            return optionsToSort;
        },
        [activeMap, sortChildOptions, compareOptions]
    );

    return (
        <div
            className={classNames(className, 'nkcm-select-list', {
                'nkcm-select-list-v': enableVirtualRendering,
            })}
            style={style}
            ref={wrapperRef}
        >
            {enableVirtualRendering ? (
                <VirtualOptionsRenderer
                    itemSize={itemSize}
                    activeMap={activeMap}
                    intermediateMap={intermediateMap}
                    options={optionsToRender}
                    onSelect={_onSelect}
                    onDeselect={_onDeselect}
                    getSortedOptionChildren={getSortedOptionChildren}
                    initiallyOpenedNestedLevel={initiallyOpenedNestedLevel}
                    OptionGroupComponent={OptionGroupComponent}
                    OptionComponent={OptionComponent}
                    optionComponentProps={optionComponentProps}
                    optionGroupComponentProps={optionGroupComponentProps}
                />
            ) : (
                <RegularOptionsRenderer
                    activeMap={activeMap}
                    descendantsMap={descendantsMap}
                    intermediateMap={intermediateMap}
                    options={optionsToRender}
                    onSelect={_onSelect}
                    onDeselect={_onDeselect}
                    getSortedOptionChildren={getSortedOptionChildren}
                    initiallyOpenedNestedLevel={initiallyOpenedNestedLevel}
                    OptionGroupComponent={OptionGroupComponent}
                    OptionComponent={OptionComponent}
                    optionComponentProps={optionComponentProps}
                    optionGroupComponentProps={optionGroupComponentProps}
                    withDelay={withDelay}
                />
            )}
            {optionsToRender.length === 0 &&
                showNoFoundPlaceholderOnNoOptions &&
                !!noFoundPlaceholder && (
                    <div className="nkcm-select-list__no-found-placeholder">
                        {noFoundPlaceholder}
                    </div>
                )}
        </div>
    );
};

List.defaultProps = {
    options: [],
    initiallyOpenedNestedLevel: 0,
    OptionGroupComponent: OptionGroup,
    OptionComponent: Option,
    collapsedOptionsCount: 4,
    intermediate: [],
};

export default React.memo(List);
