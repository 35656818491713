declare global {
    // tslint:disable-next-line:interface-name
    interface Window {
        gpnSpaUrl: string;
        gpnCounterId: number;
    }
}

export const initializingAnalytics = () => {
    const urlAnalytics = process.env.REACT_APP_URL_ANALYTICS;
    const urlAnalyticsScrip = process.env.REACT_APP_URL_ANALYTICS_SCRIPT;
    const urlAnalyticsId = +process.env.REACT_APP_URL_ANALYTICS_ID;

    if (urlAnalytics && urlAnalyticsScrip && urlAnalyticsId) {
        console.warn('Initializing analytics');
        window.gpnSpaUrl = `${urlAnalytics}`;
        window.gpnCounterId = urlAnalyticsId;
        const headEl = document.getElementsByTagName('head')[0];
        const counterLoadingScript = document.createElement('script');
        counterLoadingScript.async = true;
        counterLoadingScript.onerror = function () {
            console.error('analytics not loaded');
        };
        counterLoadingScript.onload = function () {
            console.warn('analytics loaded');
        };
        counterLoadingScript.src = `${urlAnalyticsScrip}`;
        headEl.appendChild(counterLoadingScript);
    }
};
