import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import classNames from 'classnames';

import IProps from './interfaces/IBigImageProps';
import * as styles from './BigImage.scss';

const BigImage: React.FC<IProps> = ({
    img,
    visible,
    userId,
    fullTitle = '',
    resolution,
    onClose,
}) => {
    const [loaded, setLoaded] = useState(false);

    const handleClose = () => {
        setLoaded(false);
        onClose();
    };

    const handleSave = () => {
        const fileName =
            fullTitle.length > 0
                ? `${fullTitle.split(' ').join('_')}_${userId}x${resolution}`
                : `${userId}x${resolution}`;
        saveAs(img, `${fileName}.jpg`);
    };

    const handleLoad = () => setLoaded(true);

    useEffect(() => {
        if (visible) {
            const scrollbarWidth =
                window.innerWidth - document.body.clientWidth;
            document.body.style.paddingRight = `${scrollbarWidth}px`;
            document.body.style.overflow = 'hidden';
            document.getElementsByTagName('header')[0].style.paddingRight =
                !document.getElementById('search-tabs-panel')
                    ? `${scrollbarWidth}px`
                    : '';
        } else {
            document.body.style.paddingRight = '';
            document.body.style.overflow = '';
            document.getElementsByTagName('header')[0].style.paddingRight = '';
        }
    }, [visible]);

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {img && (
                <div className={styles.bigImagePopup} hidden={!visible}>
                    <div
                        className={styles.bigImagePopupBg}
                        onClick={handleClose}
                    />
                    <div
                        className={classNames(styles.bigImagePopupImg, {
                            loaded,
                        })}
                    >
                        <button
                            onClick={handleClose}
                            className={classNames(
                                styles.bigImagePopupImgCloseBtn,
                                'btn'
                            )}
                        >
                            <div />
                        </button>
                        <button
                            onClick={handleSave}
                            className={classNames(
                                styles.bigImagePopupImgSaveBtn,
                                'btn'
                            )}
                        >
                            <div />
                        </button>
                        <img
                            alt="Изображение в модальном окне"
                            className={styles.bigImagePopupImgPicture}
                            src={img}
                            onLoad={handleLoad}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default BigImage;
