/**
 * Created by lkarmelo on 22.08.2018.
 */

import { createAction } from 'redux-actions';

import * as Store from 'app/redux/store/StoreNamespace';

export const fetchRecommendations = createAction('FETCH_RECOMMENDATIONS');

export const setRecommendations = createAction<Store.IRecommendations>(
    'SET_RECOMMENDATIONS'
);

export interface IEditRecommendationPayload {
    recommendationId: string;
    userId: string;
    isNecessary: boolean;
    planDate: number;
}
