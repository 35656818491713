import { createSimpleLoadingEpic } from './utils';

import { fetchDoodle, setDoodle } from '../actions/doodle';
import { doodleReject, doodleRequest, doodleResolve } from '../actions/loading';

export const loadDoodle = createSimpleLoadingEpic({
    triggers: [fetchDoodle.toString()],
    apiCallName: 'doodle',
    actions: {
        requestAction: doodleRequest,
        resolveAction: doodleResolve,
        rejectAction: doodleReject,
        setAction: setDoodle,
    },
    fetchAction: fetchDoodle,
});
