/**
 * Created by Lkarmelo on 21.02.2018.
 */

import React, { PureComponent } from 'react';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import autoBind from 'autobind-decorator';
import { Subject } from 'rxjs/Subject';
import { merge } from 'rxjs/observable/merge';
import memoizeOne from 'memoize-one';
import Affix from 'antd/es/affix';
import SplitPane from 'react-split-pane';
import { ResizeContext } from '@nkc-frontend/nkc-react-hooks';

import withSetBodyClass from 'app/components/high-order/setBodyClass';
import scrollToTopOnMount from 'app/components/high-order/scrollToTopOnMount';
import CategoriesMenu from 'app/components/catalogue/CategoriesMenu';
import CatalogueCategory from 'app/components/catalogue/CatalogueCategory';
import { GridSizeMin, footerHeight } from 'app/utils/constants';

import IProps from './interfaces/ICatalogueProps';
import * as styles from './Catalogue.scss';

@autoBind
class Catalogue extends PureComponent<IProps> {
    static bodyClass = styles.cataloguePage;

    selfRef: React.RefObject<HTMLElement> = React.createRef<HTMLElement>();

    splitPaneWrapper: HTMLDivElement = null;

    windowResizeSubscription: Subscription = null;

    paneResizeSubscription: Subscription = null;

    initialCategoriesListTopOffset: number;

    paneResizeObserver: Subject<any> = null;

    mergedResizeObserver: Observable<undefined> = null;

    getActiveCategoriesSequence = memoizeOne(
        (categories: IProps['categories'], activeCategoryId: string) => {
            const categoriesSequence = [];
            if (categories.categories) {
                let parentCategory = categories.categories[activeCategoryId];

                while (parentCategory) {
                    categoriesSequence.push(parentCategory.item.id);
                    parentCategory =
                        categories.categories[parentCategory.parent];
                }
            }
            return categoriesSequence;
        }
    );

    componentDidMount(): void {
        this.props.setBodyClass(Catalogue.bodyClass);

        this.createResizeObservables();

        this.setCategoriesHeight();

        this.props.fetchCategories();
    }

    componentDidUpdate(prevProps: IProps): void {
        const { skipToFirstPage } = this.props;
        if (
            this.getActiveCategoryId() !== this.getActiveCategoryId(prevProps)
        ) {
            window.scrollTo(0, 0);
            skipToFirstPage();
        }
    }

    componentWillUnmount(): void {
        this.props.removeBodyClass(Catalogue.bodyClass);
        this.windowResizeSubscription.unsubscribe();
        this.paneResizeSubscription.unsubscribe();
    }

    getActiveCategoryId(props?: IProps): string {
        const { categories, match } = props || this.props;
        if (
            match.params.id !== undefined ||
            !categories ||
            !Array.isArray(categories.topCategories)
        ) {
            return match.params.id;
        }
        return categories.topCategories[0];
    }

    pushToPaneResizeObserver(leftPanelSize: number): void {
        this.paneResizeObserver.next(leftPanelSize);
    }

    createResizeObservables(): void {
        const windowResizeObservable: Observable<undefined> =
            Observable.fromEvent(window, 'resize');

        this.windowResizeSubscription = windowResizeObservable
            .debounceTime(100)
            .subscribe(() => {
                this.setCategoriesHeight();

                const splitPaneLeft = this.splitPaneWrapper.firstChild
                    .firstChild as HTMLElement;
                if (!splitPaneLeft.style.height) {
                    this.adjustAffixWidth(splitPaneLeft.offsetWidth);
                }

                this.setBodyClassByWindowWidth();
            });
        this.setBodyClassByWindowWidth();

        this.paneResizeObserver = new Subject();
        this.paneResizeSubscription = this.paneResizeObserver.subscribe(
            this.onSplitPaneResize
        );

        this.mergedResizeObserver = merge(
            windowResizeObservable,
            this.paneResizeObserver
        );
    }

    onSplitPaneResize(leftPanelSize: number): void {
        this.adjustAffixWidth(leftPanelSize);
        this.setBodyClassByPaneWidth(
            this.splitPaneWrapper.getBoundingClientRect().width - leftPanelSize
        );
    }

    adjustAffixWidth(leftPanelSize: number): void {
        const affixInternalWrapper = this.splitPaneWrapper.firstChild.firstChild
            .firstChild as HTMLElement;
        const affix = affixInternalWrapper.firstChild as HTMLElement;
        if (affix.style.position === 'fixed') {
            affixInternalWrapper.style.width = `${leftPanelSize}px`;
            affix.style.width = `${leftPanelSize}px`;
        }
    }

    setBodyClassByWindowWidth(): void {
        const categoryPane = this.splitPaneWrapper.firstChild
            .lastChild as HTMLElement;
        this.setBodyClassByPaneWidth(
            categoryPane.getBoundingClientRect().width
        );
    }

    setBodyClassByPaneWidth(paneWidth: number): void {
        const { removeBodyClass, setBodyClass, getBodyContainsClass } =
            this.props;
        const bodyContainClass = getBodyContainsClass(
            styles.cataloguePagePaneWidthMedium
        );

        if (window.innerWidth < GridSizeMin.LargeMin) {
            bodyContainClass &&
                removeBodyClass(styles.cataloguePagePaneWidthMedium);
            return;
        }

        if (paneWidth > 0 && paneWidth < GridSizeMin.LargeMin) {
            !bodyContainClass &&
                setBodyClass(styles.cataloguePagePaneWidthMedium);
        } else {
            removeBodyClass(styles.cataloguePagePaneWidthMedium);
        }
    }

    setCategoriesHeight(): void {
        const categoriesList = document.querySelector(
            '.catalogue .categories-menu__list'
        ) as HTMLElement;

        if (this.initialCategoriesListTopOffset === undefined) {
            this.initialCategoriesListTopOffset =
                categoriesList.getBoundingClientRect().top -
                document.querySelector('.header').getBoundingClientRect().top;
        }

        categoriesList.style.height = `${
            window.innerHeight -
            this.initialCategoriesListTopOffset -
            footerHeight
        }px`;
    }

    render(): JSX.Element {
        const {
            categories,
            documentsCount,
            canEditCategory,
            canAddCategory,
            canAcceptOrChangeDocumentCategory,
            fetchCategoryKeywords,
            categoriesAsOptions,
        } = this.props;
        const activeCategoryId = this.getActiveCategoryId();
        const categoriesSequence = this.getActiveCategoriesSequence(
            categories,
            activeCategoryId
        );

        return (
            <section className="catalogue" ref={this.selfRef}>
                <div
                    className="catalogue__split-pane-wrapper"
                    ref={(r) => {
                        this.splitPaneWrapper = r;
                    }}
                >
                    <SplitPane
                        defaultSize={300}
                        minSize={250}
                        maxSize={-150}
                        onChange={this.pushToPaneResizeObserver}
                    >
                        <Affix>
                            <CategoriesMenu
                                categories={categories}
                                categoriesAsOptions={categoriesAsOptions}
                                active={activeCategoryId}
                                showAddCategory={canAddCategory}
                                showNewDocumentsCount={
                                    canAcceptOrChangeDocumentCategory
                                }
                                activeCategoriesSequence={categoriesSequence}
                            />
                        </Affix>
                        <ResizeContext.Provider
                            value={this.mergedResizeObserver}
                        >
                            {categories.categories ? (
                                <CatalogueCategory
                                    categories={categories}
                                    categoriesAsOptions={categoriesAsOptions}
                                    activeCategory={activeCategoryId}
                                    activeCategoriesSequence={
                                        categoriesSequence
                                    }
                                    isEditable={canEditCategory}
                                    showAcceptOrEditDocumentButtons={
                                        canAcceptOrChangeDocumentCategory
                                    }
                                    documentsCount={documentsCount}
                                    loadKeyWords={fetchCategoryKeywords}
                                />
                            ) : (
                                <div />
                            )}
                        </ResizeContext.Provider>
                    </SplitPane>
                </div>
            </section>
        );
    }
}

export default scrollToTopOnMount(withSetBodyClass(Catalogue));
