/**
 * Created by Lkarmelo on 02.10.2017.
 */

import { handleActions, Action, ActionMeta } from 'redux-actions';

import { DEFAULT_PAGING_ITEMS_LIMIT } from 'app/utils/constants';

import * as Store from '../../store/StoreNamespace';
import {
    setSkip,
    setLimit,
    blockPaging,
    unblockPaging,
    ISetSkipMeta,
    resetPaging,
    IResetPagingPayload,
    ISetLimitPayload,
} from '../../actions/search/searchPaging';

export default handleActions<Partial<Store.IPaging>, any>(
    {
        [setSkip.toString()](
            state: Store.IPaging,
            { payload, meta }: ActionMeta<number, ISetSkipMeta>
        ): Store.IPaging {
            if (state.isBlocked && !(meta && meta.ignorePagingBlock)) {
                return state;
            }
            return { ...state, skip: payload };
        },
        [setLimit.toString()](
            state: Store.IPaging,
            { payload }: Action<ISetLimitPayload>
        ): Store.IPaging {
            if (state.isBlocked) {
                return state;
            }
            return {
                ...state,
                limit: payload.limit,
                skip: payload.resetSkip ? 0 : state.skip,
            };
        },
        [resetPaging.toString()](
            state: Store.IPaging,
            { payload }: Action<IResetPagingPayload>
        ): Store.IPaging {
            const nextState = { ...state, skip: 0 };
            if (payload.resetLimit) {
                nextState.limit = DEFAULT_PAGING_ITEMS_LIMIT;
            }
            return nextState;
        },
        [blockPaging.toString()](state: Store.IPaging): Store.IPaging {
            return { ...state, isBlocked: true };
        },
        [unblockPaging.toString()](state: Store.IPaging): Store.IPaging {
            return { ...state, isBlocked: false };
        },
    },
    {} // - default value for searchPaging
);
