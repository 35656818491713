/**
 * Created by lkarmelo on 25.04.2019.
 */
import { createAction } from 'redux-actions';

import * as Store from 'app/redux/store/StoreNamespace';

export const fetchInitialFilterStats = createAction(
    'FETCH_INITIAL_FILTER_STATS'
);
export const setInitialFilterStats = createAction<Store.IFilterStats>(
    'SET_INITIAL_FILTER_STATS'
);
