import React, { PureComponent } from 'react';
import classNames from 'classnames';
import autoBind from 'autobind-decorator';
import Popover from 'antd/es/popover';

import { DropDown } from 'app/components/common/tables/Filters';
import { IEVersion } from 'app/utils/IEVersion';

import IProps from './interfaces/ICompetenceRowProps';

@autoBind
export default class CompetenceRow extends PureComponent<IProps> {
    render(): JSX.Element {
        const { competence, isEditable, availableCompetencies } = this.props;
        return (
            <tr className="competence-row">
                <td className="competence-row__title">
                    {isEditable ? (
                        <DropDown
                            options={availableCompetencies}
                            active={competence.competenceItem.id}
                            title={competence.competenceItem.title}
                            onSelectOption={this.selectCompetence}
                            isFilterable
                        />
                    ) : (
                        competence.competenceItem.title
                    )}
                </td>
                {this.renderScale()}
            </tr>
        );
    }

    renderScale(): JSX.Element[] {
        const { gradeScale, isDeletable, isDeleteButtonDisabled } = this.props;
        const tds = [];

        for (let i = 0; i <= gradeScale; i++) {
            tds.push(
                <td
                    key={i}
                    className={classNames({
                        'competence-row__delete':
                            i === gradeScale && isDeletable,
                    })}
                >
                    {i === 0 && this.renderScaleLine()}
                    {i === gradeScale && isDeletable && (
                        <button
                            disabled={isDeleteButtonDisabled}
                            onClick={this.remove}
                            className="btn"
                        />
                    )}
                </td>
            );
        }
        return tds;
    }

    renderScaleLine(): JSX.Element {
        const { competence, isGradeChangAble, gradeScale } = this.props;
        const { grade, requiredGrade, targetGrade, competenceItem } =
            competence;

        const popoverContent = (
            <div className="competence-row__popover-content">
                <div
                    className={classNames('competence-row__popover-title', {
                        'competence-row__popover-title-link':
                            !!this.props.onCompetenceClick,
                    })}
                    onClick={this.onCompetenceClick}
                >
                    {competenceItem.title}
                </div>
                <table className="competence-row__popover-grades">
                    <tbody>
                        <tr className="competence-row__popover-grade">
                            <td className="competence-row__popover-description">
                                Текущий уровень компетенции
                            </td>
                            <td className="competence-row__popover-grade-number">
                                {grade ? this.gradeToScale(grade) : '0'}
                            </td>
                        </tr>
                        {requiredGrade && (
                            <tr className="competence-row__popover-grade">
                                <td className="competence-row__popover-description">
                                    Требуемый уровень для должности
                                </td>
                                <td className="competence-row__popover-grade-number">
                                    {this.gradeToScale(requiredGrade)}
                                </td>
                            </tr>
                        )}
                        {targetGrade && (
                            <tr className="competence-row__popover-grade">
                                <td className="competence-row__popover-description">
                                    Требуемый уровень для целевой должности
                                </td>
                                <td className="competence-row__popover-grade-number">
                                    {this.gradeToScale(targetGrade)}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
        let scaleLineWidth = '0';
        if (grade) {
            // 6px = 1px рамки + 2px паддинга с 2 сторон
            scaleLineWidth = `calc((100% + 6px)*${grade})`;
        }
        const inputChangeAction =
            IEVersion === null
                ? { onInput: this.changeGrade }
                : { onChange: this.changeGrade };
        return (
            <Popover
                content={popoverContent}
                trigger="hover"
                placement="bottomLeft"
                overlayClassName="competence-row__popover-wrapper"
            >
                <div className="competence-row__scale-line-input-wrapper">
                    {isGradeChangAble && (
                        <input
                            onChange={this.changeGrade}
                            {...inputChangeAction}
                            value={grade ? grade * gradeScale : 0}
                            className="competence-row__range-input"
                            type="range"
                            min={0}
                            max={gradeScale}
                        />
                    )}
                    <div className="competence-row__scale-line-wrapper">
                        {requiredGrade && (
                            <div
                                style={{ left: `${requiredGrade * 100}%` }}
                                className="competence-row__required-mark"
                            />
                        )}
                        {targetGrade && (
                            <div
                                style={{ left: `${targetGrade * 100}%` }}
                                className="competence-row__target-mark"
                            />
                        )}
                        <div
                            className={classNames(
                                'competence-row__scale-line',
                                {
                                    'competence-row__scale-line--not-enough':
                                        requiredGrade && grade < requiredGrade,
                                }
                            )}
                            style={{ width: scaleLineWidth }}
                        />
                    </div>
                </div>
            </Popover>
        );
    }

    selectCompetence(value: string, filterName: string, meta: any): void {
        this.props.onChangeCompetence(this.props.index, meta);
    }

    onCompetenceClick(e: React.MouseEvent<HTMLDivElement>): void {
        e.preventDefault();

        this.props.onCompetenceClick(this.props.index);
    }

    gradeToScale(grade: number): number {
        const { gradeScale } = this.props;
        return Math.floor(grade * gradeScale + 0.5);
    }

    remove(): void {
        this.props.onRemove(this.props.index);
    }

    changeGrade(e: React.ChangeEvent<HTMLInputElement>): void {
        this.props.onChangeGrade(
            this.props.index,
            +e.currentTarget.value / this.props.gradeScale
        );
    }
}
