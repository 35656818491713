/**
 * Created by Lkarmelo on 23.08.2017.
 */

import { createActions, createAction } from 'redux-actions';

import * as Store from '../../store/StoreNamespace';
import ISetFiltersByNamePayloadItem from '../interfaces/ISetFiltersByNamePayloadItem';

export interface IChangeFilterValuePayload {
    filterName: string;
    value: Store.IFilterValue;
    updateFilterMetaIfValueUnknown?: boolean;
    label?: string; // label который будет использован если  updateFilterMetaIfValueUnknown === true
}

const changeFilterValuePayloadCreator = (
    filterName,
    value,
    updateFilterMetaIfValueUnknown,
    label
): IChangeFilterValuePayload => ({
    filterName,
    value,
    updateFilterMetaIfValueUnknown,
    label,
});

export const {
    addMultiSelectFilterValue,
    removeMultiSelectFilterValue,
    toggleMultiSelectFilterValue,
    setDateRangeFrom,
    setDateRangeTo,
    setFilterValue,
    searchEmployeesWithFilterValue,
} = createActions<IChangeFilterValuePayload>({
    ADD_MULTI_SELECT_FILTER_VALUE: changeFilterValuePayloadCreator,
    REMOVE_MULTI_SELECT_FILTER_VALUE: changeFilterValuePayloadCreator,
    TOGGLE_MULTI_SELECT_FILTER_VALUE: changeFilterValuePayloadCreator,
    SET_DATE_RANGE_FROM: changeFilterValuePayloadCreator,
    SET_DATE_RANGE_TO: changeFilterValuePayloadCreator,
    SET_FILTER_VALUE: changeFilterValuePayloadCreator,
    SEARCH_EMPLOYEES_WITH_FILTER_VALUE: changeFilterValuePayloadCreator,
});

export const setFilters = createAction<Store.IFilters>('SET_FILTERS');

export const setFiltersByNames = createAction<ISetFiltersByNamePayloadItem[]>(
    'SET_FILTERS_BY_NAMES'
);

export const updateLocation = createAction('UPDATE_LOCATION');

export const setLastExecutedFilters = createAction<Store.IFilters>(
    'SET_LAST_EXECUTED_FILTERS'
);
