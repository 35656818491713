/**
 * Created by lkarmelo on 07.10.2019.
 */

import moment from 'moment';

import * as Store from 'app/redux/store/StoreNamespace';

import { DATE_FULL_MONTH_FORMAT } from '../constants';

export const getDocumentDate = (
    meta: Store.IDocumentDescription = {}
): string | number => {
    const { year, day, month } = meta;
    if (year !== undefined && month !== undefined && day !== undefined) {
        return moment(`${year}.${month}.${day}`, 'YYYY.MM.DD').format(
            DATE_FULL_MONTH_FORMAT
        );
    }
    if (month !== undefined && day === undefined) {
        return moment(`${year}.${month}`, 'YYYY.MM').format('MMMM YYYY');
    }
    return year;
};
