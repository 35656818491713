import { MiddlewareAPI } from 'redux';
import { Action } from 'redux-actions';
import { Observable } from 'rxjs/Observable';

import Api from 'app/api/Api';
import * as Store from 'app/redux/store/StoreNamespace';
import { processFileInfoResponse } from 'app/components/common/UploadPanel/UploadBoxAction';

import {
    addFileError,
    deleteFile,
    fetchFileInfo,
    IProgress,
    IUploadFileData,
    redoUploadFile,
    updateFileInfo,
    updateFileProgress,
    updateFileState,
    uploadFile,
} from '../actions/files';
import {
    filesToArray,
    handleFileChange,
} from '../../components/common/files/UploadBoxAction/UploadBoxAction';
import routes from '../../routing/apiRoutes';

export const fetchFilesEpic = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(fetchFileInfo.toString())
        .mergeMap((action: Action<string>) =>
            apiCall
                .listFile(action.payload)
                .map(({ response }) => processFileInfoResponse(response))
                .map((fileMeta: Store.IFileMeta[]) => updateFileInfo(fileMeta))
                .catch((e) => {
                    console.error(e);
                    return Observable.empty();
                })
                .takeUntil(action$.ofType(fetchFileInfo.toString))
        );

export const deleteFilesEpic = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(deleteFile.toString())
        .switchMap((action: Action<string>) => {
            const fileMeta = store.getState().fileStore.files[action.payload];

            if (
                fileMeta.status === Store.FileStateEnum.Uploaded ||
                fileMeta.status === Store.FileStateEnum.Stored
            ) {
                return apiCall
                    .deleteFile(action.payload)
                    .map(() =>
                        updateFileState(
                            action.payload,
                            Store.FileStateEnum.Deleted
                        )
                    )
                    .catch((e) => {
                        console.error(e);
                        return Observable.empty();
                    });
            }

            if (fileMeta.status === Store.FileStateEnum.Error) {
                return Observable.of(
                    updateFileState(action.payload, Store.FileStateEnum.Deleted)
                );
            }

            if (
                fileMeta.status === Store.FileStateEnum.TmpFile ||
                fileMeta.status === Store.FileStateEnum.Uploading
            ) {
                return Observable.of(
                    updateFileState(action.payload, Store.FileStateEnum.Error)
                );
            }

            return Observable.empty();
        });

// TODO: необходимо отрефакторить
export const redoUploadFileEpic = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(redoUploadFile.toString())
        .switchMap((action: Action<string>) => {
            const fileMeta = store.getState().fileStore.files[action.payload];

            if (fileMeta.status === Store.FileStateEnum.Error) {
                return apiCall
                    .listFile(fileMeta.fid)
                    .map(({ response }) => processFileInfoResponse(response))
                    .mergeMap<Store.IFileMeta[], any>(
                        (fileListMeta: Store.IFileMeta[]) => {
                            if (fileListMeta.length !== 0) {
                                return Observable.of(
                                    updateFileInfo(fileListMeta)
                                );
                            }

                            return Observable.of(
                                uploadFile({
                                    fid: action.payload,
                                    files: fileMeta.file,
                                })
                            );
                        }
                    );
            }

            if (fileMeta.status === Store.FileStateEnum.Stored) {
                return apiCall
                    .processFile(fileMeta.fid)
                    .ignoreElements()
                    .catch((e) => {
                        console.error(e);
                        return Observable.empty();
                    });
            }

            return Observable.empty();
        });

export const processRedoUploadFileEpic = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(uploadFile.toString())
        .do((action: Action<IUploadFileData>) => {
            const { files } = action.payload;

            const { dispatch } = store;

            filesToArray(files).forEach((file) => {
                handleFileChange(file, {
                    path: '/',
                    uploadUrl: routes.uploadFile,
                    ajaxProcess: processFileInfoResponse,
                    onFileChange: (fileMeta: Store.IFileMeta) => {
                        dispatch(updateFileInfo(fileMeta));
                    },
                    onProgress: (
                        name: string,
                        { loaded, total }: IProgress
                    ) => {
                        dispatch(
                            updateFileState(name, Store.FileStateEnum.Uploading)
                        );
                        dispatch(updateFileProgress(name, { loaded, total }));
                    },
                    onComplete: (name: string) => {
                        dispatch(
                            updateFileState(name, Store.FileStateEnum.Uploaded)
                        );
                    },
                    onSuccess: (fileMeta: Store.IFileMeta[]) => {
                        dispatch(updateFileInfo(fileMeta));
                    },
                    onFailed: (error: any, uploadedFile: File) => {
                        dispatch(
                            updateFileState(
                                uploadedFile.name,
                                Store.FileStateEnum.Error
                            )
                        );
                        dispatch(
                            addFileError(uploadedFile.name, {
                                errorMsg: error.toString(),
                                file: uploadedFile,
                            })
                        );
                    },
                });
            });
        })
        .ignoreElements()
        .catch((e) => {
            console.error(e);
            return Observable.empty();
        });
