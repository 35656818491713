/**
 * Created by Lkarmelo on 29.03.2018.
 */

import { connect } from 'react-redux';

import {
    IChangePasswordActionProps as IActionProps,
    IChangePasswordStateProps as IStateProps,
    IChangePasswordOwnProps as IOwnProps,
    PassFieldName,
    Passwords,
} from 'app/components/Modals/ChangePassword/interfaces/IChangePasswordProps';
import {
    initForm,
    changeField,
    removeForm,
    sendForm,
} from 'app/redux/actions/forms';
import { removeLoadingError } from 'app/redux/actions/loading';
import { FormName } from 'app/api/FormName';
import * as Store from 'app/redux/store/StoreNamespace';

import ChangePassword from './ChangePassword';

const mapStateToProps = (
    state: Store.IState,
    ownProps: IOwnProps
): IStateProps & IOwnProps => ({
    passwords:
        state.forms[FormName.ChangePass] &&
        <Passwords>state.forms[FormName.ChangePass].data,
    status:
        state.forms[FormName.ChangePass] &&
        state.forms[FormName.ChangePass].status,
    error:
        state.loading.loadingErrors[FormName.ChangePass] &&
        state.loading.loadingErrors[FormName.ChangePass].message,
    isVisible: ownProps.isVisible,
    onCancel: ownProps.onCancel,
});

const mapDispatchToProps = (dispatch): IActionProps => ({
    onShown(): void {
        dispatch(
            initForm(FormName.ChangePass, {
                [PassFieldName.oldPass]: '',
                [PassFieldName.newPass]: '',
                [PassFieldName.newPassRep]: '',
            })
        );
    },
    onHidden(): void {
        dispatch(removeForm(FormName.ChangePass));
    },
    onChangePass(fieldName: PassFieldName, value: string): void {
        dispatch(changeField(FormName.ChangePass, fieldName, value));
    },
    onSavePass(data: Passwords): void {
        if (
            data.newPass &&
            data.newPassRep &&
            data.oldPass &&
            data.newPass === data.newPassRep
        ) {
            dispatch(
                sendForm(FormName.ChangePass, {
                    oldPassword: data.oldPass,
                    newPassword: data.newPass,
                })
            );
        }
    },
    removeErrorMessage(): void {
        dispatch(removeLoadingError([FormName.ChangePass]));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
