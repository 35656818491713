import { connect } from 'react-redux';

import { IBigImageActionProps } from './interfaces/IBigImageProps';
import BigImage from './BigImage';

import { onCloseBigImage } from '../../../redux/actions/search/results';

const mapDispatchToProps = (dispatch): IBigImageActionProps => ({
    onClose() {
        dispatch(onCloseBigImage());
    },
});

export default connect(null, mapDispatchToProps)(BigImage);
