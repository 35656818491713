/**
 * Created by Lkarmelo on 26.02.2018.
 */

import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import IProps from './interface/IBreadCrumbsProps';

class BreadCrumbs extends PureComponent<IProps> {
    static defaultProps = {
        delimiter: '/',
    };

    render(): JSX.Element {
        const { paths, delimiter, shouldReversePaths } = this.props;
        let pathsToRender = paths;
        if (shouldReversePaths) {
            pathsToRender = pathsToRender.slice().reverse();
        }
        return (
            <div className="breadcrumbs">
                {pathsToRender.map((path, index) => (
                    <React.Fragment key={path.link}>
                        {index > 0 && (
                            <span className="breadcrumbs__delimiter">
                                {delimiter}
                            </span>
                        )}
                        <Link className="breadcrumbs__link" to={path.link}>
                            {path.title}
                        </Link>
                    </React.Fragment>
                ))}
            </div>
        );
    }
}

export default BreadCrumbs;
