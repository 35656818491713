import * as React from 'react';

import { UIFeaturesName } from 'app/features/UIFeaturesName';

interface IProps {
    featureName: UIFeaturesName;
}

const FeatureRenderer: React.FunctionComponent<IProps> = (props) => {
    const { featureName, children, ...rest } = props;

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {APP_FEATURES.ui[featureName]
                ? children &&
                  typeof children === 'object' &&
                  !Array.isArray(children)
                    ? React.cloneElement(
                          children as React.ReactElement<any>,
                          rest
                      )
                    : children
                : null}
        </>
    );
};

export default FeatureRenderer;
