import React, { MouseEvent, PureComponent } from 'react';
import autoBind from 'autobind-decorator';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Progress from 'antd/es/progress';
import memoizeOne from 'memoize-one';

import * as Store from 'app/redux/store/StoreNamespace';
import routes from 'app/routing/apiRoutes';
import {
    deleteFile,
    fetchFileInfo,
    redoUploadFile,
} from 'app/redux/actions/files';
import './MyDocumentPanel.scss';
import prettyBytes from 'app/utils/pretty-bytes';

import UploadBox from './UploadBoxAction';

interface IProps {
    files: Store.IFileMeta[];
    downloadLink: (file: Store.IFileMeta) => string;

    disabled?: boolean;
}

interface IOwnProps {
    path: string;
}

interface IDispatchProps {
    deleteFile: (fid: string) => void;
    redoUploadFile: (fid: string) => void;

    fetchFiles: () => void;
}

interface IProgressBarProps {
    current: number;
    total: number;
    status: Store.FileStateEnum;
}

const ProgressBar: React.FunctionComponent<IProgressBarProps> = ({
    current,
    total,
    status,
}) => {
    const percent = Math.round((current / total) * 100);
    const loadedFileSize = prettyBytes(current, { locale: 'ru' });
    const totalFileSize = prettyBytes(total, { locale: 'ru' });

    return (
        <div className="upload__info_panel__progressbar">
            <Progress
                strokeWidth={4}
                percent={percent}
                showInfo={false}
                status={
                    status === Store.FileStateEnum.Uploaded
                        ? 'success'
                        : 'active'
                }
            />
            <div className="upload__info_panel__progressbar-info">
                <span className="upload__info_panel__progressbar-status">{`Загрузка... ${percent}%`}</span>
                <span className="upload__info_panel__progressbar-size">
                    {`${loadedFileSize} из ${totalFileSize}`}
                </span>
            </div>
        </div>
    );
};

class MyDocumentPanel extends PureComponent<
    IProps & IOwnProps & IDispatchProps
> {
    static defaultProps = {
        disabled: false,
    };

    componentDidMount() {
        const { fetchFiles } = this.props;

        fetchFiles();
    }

    render(): JSX.Element {
        const { files, path } = this.props;

        const filesPanel = files.map((fileMeta: Store.IFileMeta) =>
            this.renderFileInfoPanel(fileMeta)
        );

        return (
            <div>
                <UploadBox path={path} uploadUrl={routes.uploadFile} />
                {filesPanel}
            </div>
        );
    }

    renderFileInfoPanel(file: Store.IFileMeta): JSX.Element {
        const { loaded, total } = file.progress || { loaded: 0, total: 0 };

        return (
            <div className="upload__info_panel-wrapper" key={file.fid}>
                {(!!file.error && (
                    <div className="upload__info_panel-filename-in-progress">
                        <div className="upload__info_panel-title">
                            <i className="fas fa-file" />
                            &nbsp;
                            <span className="upload__info_panel-placeholder">
                                {file.name}
                            </span>
                        </div>

                        <div className="upload__info_panel-error">{`Ошибка загрузки. ${file.error}`}</div>
                    </div>
                )) ||
                    (!!file.progress && (
                        <div className="upload__info_panel-filename-in-progress">
                            <div className="upload__info_panel-title">
                                <i className="fas fa-file" />
                                &nbsp;
                                <span className="upload__info_panel-placeholder">
                                    {file.name}
                                </span>
                            </div>

                            <ProgressBar
                                current={loaded}
                                total={total}
                                status={file.status}
                            />
                        </div>
                    )) || (
                        <div className="upload__info_panel-filename">
                            <i className="fas fa-file" />
                            &nbsp;
                            <span className="upload__info_panel-placeholder">
                                {file.name}
                            </span>
                        </div>
                    )}
                {/* <div className="upload__info_panel-status"> */}
                {/* {statusTitle(file.status)} */}
                {/* </div> */}
                {/* <div className="upload__info_panel-splitter"/> */}
                {/* <div className="upload__info_panel-type"> */}
                {/* {(mime.extension(file.mimeType) || '').toUpperCase()} */}
                {/* </div> */}
                {/* <div className="upload__info_panel-splitter"/> */}
                {/* <div className="upload__info_panel-size"> */}
                {/* {prettyBytes(file.size, { locale: 'ru' })} */}
                {/* </div> */}
                {/* <div className="upload__info_panel-splitter"/> */}
                <div className="upload__info_panel-actions">
                    {/* <DownloadButton link={downloadLink(file)}/> */}
                    {(file.status === Store.FileStateEnum.Error ||
                        file.status === Store.FileStateEnum.Stored) && (
                        <i
                            className="nkc-icon-redo"
                            onClick={(e) => this.redoUploadFile(e, file.fid)}
                        />
                    )}
                    <i
                        className="nkc-icon-times"
                        onClick={(e) => this.deleteFile(e, file.fid)}
                    />
                </div>
            </div>
        );
    }

    @autoBind
    deleteFile(e: MouseEvent<HTMLElement>, fid: string) {
        e && e.stopPropagation();
        e && e.preventDefault();

        this.props.deleteFile(fid);
    }

    @autoBind
    redoUploadFile(e: MouseEvent<HTMLElement>, fid: string) {
        e && e.stopPropagation();
        e && e.preventDefault();

        this.props.redoUploadFile(fid);
    }
}

const mapStateToProps = () => {
    const getFiles = memoizeOne((fileStore: Store.IFileStore) => {
        const { files, keys } = fileStore;

        return keys
            .map((key) => files[key])
            .filter((file) => file.status !== Store.FileStateEnum.Deleted);
    });

    const downloadLink = (file: Store.IFileMeta) =>
        routes.downloadFile.getUrl({ fid: file.fid });

    return (state: Store.IState): IProps => ({
        files: getFiles(state.fileStore),
        downloadLink,
    });
};

const mapDispatchToProps = (dispatch: Dispatch<any>): IDispatchProps => ({
    fetchFiles: () => {
        dispatch(fetchFileInfo(''));
    },

    deleteFile: (fid: string) => {
        dispatch(deleteFile(fid));
    },

    redoUploadFile: (fid: string) => {
        dispatch(redoUploadFile(fid));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(MyDocumentPanel);
