import { normalize, schema } from 'normalizr';

import * as Store from 'app/redux/store/StoreNamespace';
import Api from 'app/api/Api';

import {
    setCatalogueCategories,
    fetchCatalogueCategories,
} from '../../actions/catalogue/catalogueCategories';
import {
    catalogueCategoriesReject,
    catalogueCategoriesRequest,
    catalogueCategoriesResolve,
} from '../../actions/loading';
import { createSimpleLoadingEpic } from '../utils';

export const loadCatalogueCategories = createSimpleLoadingEpic({
    triggers: [fetchCatalogueCategories.toString()],
    apiCallName: 'catalogueCategories',
    actions: {
        requestAction: catalogueCategoriesRequest,
        resolveAction: catalogueCategoriesResolve,
        rejectAction: catalogueCategoriesReject,
        setAction: setCatalogueCategories,
    },
    retryOnAfterAuth: true,
    fetchAction: fetchCatalogueCategories,
    transformResponse(
        response: Api.ICatalogueCategoriesResponse[]
    ): Store.INormalizedHierarchicalCategories {
        const cats = new schema.Entity('items', undefined, {
            idAttribute: (value: Api.ICatalogueCategoriesResponse) => value.id,
            processStrategy: (value, parent) => {
                const { id, title, subCategories, ...rest } = value;
                return {
                    ...rest,
                    subItems: subCategories,
                    item: {
                        id,
                        title,
                    },
                    parent: parent && parent.item && parent.item.id,
                };
            },
        });
        const subItems = new schema.Array(cats);

        cats.define({ subItems });

        const norm = normalize(response, [cats]);

        return {
            topCategories: norm.result,
            categories: norm.entities.items,
        };
    },
});
