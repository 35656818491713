/**
 * Created by Lkarmelo on 28.12.2017.
 */

import { createSelector } from 'reselect';

import * as Store from '../store/StoreNamespace';

const competenciesSelector = (state: Store.IState): Store.IUserCompetence[] =>
    state.currentUserCompetencies;

export const competenciesSortedByGroups = createSelector<
    Store.IState,
    Store.IUserCompetence[],
    Store.IUserCompetence[]
>(competenciesSelector, (userCompetencies) => {
    return userCompetencies
        .reduce(
            (
                groups: Store.IUserCompetence[][],
                comp: Store.IUserCompetence
            ) => {
                if (comp.requiredGrade && comp.targetGrade) {
                    groups[0] ? groups[0].push(comp) : (groups[0] = [comp]);
                } else if (comp.requiredGrade && !comp.targetGrade) {
                    groups[1] ? groups[1].push(comp) : (groups[1] = [comp]);
                } else if (comp.targetGrade && !comp.requiredGrade) {
                    groups[2] ? groups[2].push(comp) : (groups[2] = [comp]);
                } else {
                    groups[3] ? groups[3].push(comp) : (groups[3] = [comp]);
                }
                return groups;
            },
            []
        )
        .map((group: Store.IUserCompetence[]) =>
            group.sort(
                (comA: Store.IUserCompetence, comB: Store.IUserCompetence) => {
                    return comA.competenceItem.title < comB.competenceItem.title
                        ? -1
                        : 1;
                }
            )
        )
        .reduce(
            (
                competencies: Store.IUserCompetence[],
                group: Store.IUserCompetence[]
            ) => {
                group.forEach((gr) => competencies.push(gr));
                return competencies;
            },
            []
        );
});
