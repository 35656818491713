/**
 * Created by Lkarmelo on 28.03.2018.
 */

import React, { PureComponent } from 'react';
import autoBind from 'autobind-decorator';
import classNames from 'classnames';
import Tooltip from 'antd/es/tooltip';

import IProps from './interfaces/IHiddenInputFieldProps';
import IState from './interfaces/IHiddenInputFieldState';
import * as styles from './HiddenInputField.scss';

@autoBind
export default class HiddenInputField extends PureComponent<IProps, IState> {
    static defaultProps = {
        className: '',
        errorMessages: [],
    };

    state = {
        showInput: false,
    };

    render(): JSX.Element {
        const {
            title,
            value,
            className,
            errorMessages,
            isDisabled,
            placeholder,
            autoComplete,
            isRequired,
        } = this.props;
        return (
            <div
                className={classNames(styles.hiddenInputField, className, {
                    [styles.hiddenInputFieldHasErrors]:
                        errorMessages.length > 0,
                    [styles.hiddenInputFieldRequired]: isRequired,
                })}
            >
                <label className={styles.hiddenInputFieldTitle}>{title}</label>
                <div className={styles.hiddenInputFieldInputWrapper}>
                    <input
                        placeholder={placeholder}
                        disabled={isDisabled}
                        className={styles.hiddenInputFieldInput}
                        type={this.state.showInput ? 'text' : 'password'}
                        value={value}
                        onChange={this.onInputChange}
                        autoComplete={autoComplete}
                        onFocus={this.onInputFocus}
                    />
                    <Tooltip
                        placement="topRight"
                        title="Показать/скрыть пароль"
                        mouseEnterDelay={0.3}
                    >
                        <button
                            type="button"
                            className={`${styles.hiddenInputFieldToggleVisibility} btn`}
                            onClick={this.toggleInputVisibility}
                        />
                    </Tooltip>
                </div>
                {errorMessages.length > 0 && (
                    <div className={styles.hiddenInputFieldErrorMessages}>
                        {errorMessages.map((msg) => (
                            <div
                                key={msg}
                                className={styles.hiddenInputFieldErrorMessage}
                            >
                                {msg}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    onInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const { onChange, meta } = this.props;
        onChange && onChange(e.currentTarget.value, meta);
    }

    toggleInputVisibility() {
        this.setState((state) => ({ showInput: !state.showInput }));
    }

    onInputFocus(e: React.FocusEvent<HTMLInputElement>): void {
        const { onFocus, meta } = this.props;
        onFocus && onFocus(e, meta);
    }
}
