import { connect } from 'react-redux';

import { IPersonAvatarActionProps } from './interfaces/IPersonAvatarProps';
import PersonAvatar from './PersonAvatar';

import { onShowBigAvatar } from '../../../redux/actions/search/results';

const mapDispatchToProps = (dispatch): IPersonAvatarActionProps => ({
    onShowBigAvatar(userId: string, fullTitle: string) {
        dispatch(
            onShowBigAvatar({
                userId,
                resolution: '1200',
                needCache: false,
                fullTitle,
            })
        );
    },
});

export default connect(null, mapDispatchToProps)(PersonAvatar);
