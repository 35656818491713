/**
 * Created by Lkarmelo on 29.05.2018.
 */

import { handleActions } from 'redux-actions';

import { setEducationMaterialTypes } from '../actions/educationMaterialTypes';
import * as Store from '../store/StoreNamespace';

export default handleActions(
    {
        [setEducationMaterialTypes.toString()](
            state: Store.ISimpleCatalogItem[],
            { payload }: { payload: Store.ISimpleCatalogItem[]; type: string }
        ) {
            return payload;
        },
    },
    [] // - default value for education material types
);
