/**
 * Created by lkarmelo on 18.11.2019.
 */

import memoizeOne from 'memoize-one';

import * as Store from '../../redux/store/StoreNamespace';

export type FilterValueWithCount = Store.IFilterMetaValue & { count?: number };

export type FilterMetaWithCount = Omit<Store.IFilterMeta, 'values'> & {
    values?: FilterValueWithCount[];
};

export const getFiltersMetasWithCount = memoizeOne(
    (
        metas: Store.IFiltersMeta,
        filterStats: Store.IFilterStats
    ): Record<string, FilterMetaWithCount> => {
        if (!filterStats) {
            return metas;
        }

        return Object.entries(metas).reduce(
            (nextMetas, [filterName, filterMeta]) => {
                nextMetas[filterName] = { ...filterMeta };

                const filterStat = filterStats[filterName];

                if (!filterStat || !Array.isArray(filterMeta.values)) {
                    return nextMetas;
                }

                nextMetas[filterName].values = filterMeta.values.map(
                    (value) => {
                        const valueStat = filterStat[value.value];
                        return {
                            ...value,
                            count:
                                typeof valueStat === 'number'
                                    ? valueStat
                                    : undefined,
                        };
                    }
                );

                return nextMetas;
            },
            {}
        );
    }
);
