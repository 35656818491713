import { ISelectOption } from './TreeSelectWithSelectAllBtn';
import { SortOptionsFunc } from './TreeSelectWithSelectAllBtn.props';

export const sortOptionsByCount = (
    optionA: ISelectOption,
    optionB: ISelectOption
) => {
    if (
        (optionA.count !== undefined && optionB.count === undefined) ||
        optionA.count > optionB.count
    ) {
        return -1;
    }
    if (
        (optionA.count === undefined && optionB.count !== undefined) ||
        optionA.count < optionB.count
    ) {
        return 1;
    }
    return 0;
};

export const sortOptionsByActiveState = (
    optionA: ISelectOption,
    optionB: ISelectOption,
    activeMap: Record<string, boolean>
) => {
    const isOptAActive = activeMap[optionA.value];
    const isOptBActive = activeMap[optionB.value];

    if (isOptAActive && !isOptBActive) {
        return -1;
    }
    if (!isOptAActive && isOptBActive) {
        return 1;
    }
    return 0;
};

export const sortOptionsWithSubOptions = (
    options: ISelectOption[],
    sorter: SortOptionsFunc,
    activeMap?: Record<string, boolean>
): ISelectOption[] =>
    options
        .slice()
        .sort((a, b) => sorter(a, b, activeMap))
        .map((option) => {
            if (!option.options) {
                return option;
            }
            const nextOption = { ...option };
            nextOption.options = sortOptionsWithSubOptions(
                nextOption.options,
                sorter,
                activeMap
            );
            return nextOption;
        });

export const LIST_ACTION_DELAY = 1000;

export const defaultGetFilteredOptions = (
    searchQuery: string,
    options: ISelectOption[]
) => {
    if (!searchQuery) {
        return options;
    }

    const searchQueryLowerCase = searchQuery.toLowerCase();

    const optionFilterCb = (result: ISelectOption[], option: ISelectOption) => {
        const { label, options: subOptions } = option;

        const searchQueryInLabel = label
            .toLowerCase()
            .includes(searchQueryLowerCase);

        if (Array.isArray(subOptions)) {
            const filteredSubOptions = subOptions.reduce(optionFilterCb, []);

            if (searchQueryInLabel) {
                const optionToPush = {
                    ...option,
                    options:
                        filteredSubOptions.length > 0
                            ? filteredSubOptions
                            : null,
                };
                result.push(optionToPush);
            } else if (filteredSubOptions.length > 0) {
                result.push(...filteredSubOptions);
            }

            return result;
        }

        if (searchQueryInLabel) result.push(option);
        return result;
    };

    return options.reduce(optionFilterCb, []);
};

export const collapsedOptionsCount = 4;
