import {
    getDepthFirstSearcher,
    Traverser,
} from 'app/modules/utils/depthFirstSearcher';

import * as Store from '../redux/store/StoreNamespace';

export const traverseConceptTree: Traverser<'tree', Store.IConceptTree> =
    getDepthFirstSearcher('tree');

export const getTitleFromLabels = (labels: Store.ISKOSLabel[]): string => {
    if (!labels) {
        return undefined;
    }
    const labelsByLanguages = labels.reduce(
        (res: Record<string, string>, label) => {
            res[label.lang || 'default'] = label.value;
            return res;
        },
        {}
    );

    return (
        labelsByLanguages.ru ||
        labelsByLanguages.en ||
        labelsByLanguages.default
    );
};
