import React, { useRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import scrollIntoView from 'scroll-into-view';

import { useTypedSelector } from 'app/hooks/useTypedSelector';
import { IPerson } from 'app/redux/store/StoreNamespace';

import IProps from './interfaces/ISearchResultUsersProps';
import * as styles from './SearchResult.scss';
import useSearchResults from './hooks/useSearchResults';
import PersonCard from './components/PersonCard';
import SearchResults from './SearchResult';
import SearchResultMessageRow from './components/SearchResultMessageRow';

import Feedback from '../Feedback';

const SearchResultUsers: React.FunctionComponent<IProps> = (props) => {
    const { scrollTo, onPersonClick, focusSearchQueryInput, resetScroll } =
        props;
    const { personResults } = useTypedSelector((store) => store.search);
    const { items: users, totalCount: count, askFeedback } = personResults;
    const [customScroll, setCustomScroll] = useState('');

    const {
        onLoadMoreResults,
        listWithFeedback,
        hasListWithFeedback,
        isButtonShown,
    } = useSearchResults<IPerson>({ items: users, askFeedback, count });

    const elementToScrollRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (personResults.items.length === 1) {
            setCustomScroll(personResults.items[0].id);
        }
    }, []);

    useEffect(() => {
        if ((customScroll || scrollTo) && elementToScrollRef.current) {
            scrollIntoView(elementToScrollRef.current, function (type: string) {
                if (type === 'complete') {
                    elementToScrollRef.current.classList.add(
                        styles.searchResultHighlighted
                    );
                    resetScroll();
                }
            });
        }
    }, [scrollTo, resetScroll, customScroll]);

    useEffect(() => {
        if (!scrollTo && elementToScrollRef.current) {
            elementToScrollRef.current.classList.remove(
                styles.searchResultHighlighted
            );
        }
    }, [users]);

    return (
        <SearchResults>
            <SearchResultMessageRow
                focusSearchQueryInput={focusSearchQueryInput}
                list={users}
            />
            {hasListWithFeedback && (
                <div
                    className={classNames(
                        styles.searchResultList,
                        styles.searchResultListWrap,
                        styles.searchResultListUsers
                    )}
                >
                    {listWithFeedback.map((user) =>
                        typeof user === 'string' ? (
                            <Feedback
                                className={styles.searchResultFeedbackPerson}
                                key={user}
                            />
                        ) : (
                            <PersonCard
                                ref={(component) => {
                                    if (
                                        component &&
                                        (user.id === scrollTo ||
                                            user.id === customScroll)
                                    ) {
                                        elementToScrollRef.current = component;
                                    }
                                }}
                                className={classNames(
                                    styles.searchResultPersonCard
                                )}
                                key={user.id}
                                user={user}
                                onPersonClick={onPersonClick}
                            />
                        )
                    )}
                </div>
            )}
            {isButtonShown && (
                <div>
                    <button
                        onClick={onLoadMoreResults}
                        className={classNames(
                            'btn',
                            styles.searchResultMoreBtn
                        )}
                    >
                        Показать ещё
                    </button>
                </div>
            )}
        </SearchResults>
    );
};

export default SearchResultUsers;
