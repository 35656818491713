/**
 * Created by lkarmelo on 02.12.2019.
 */

import { connect } from 'react-redux';

import { fetchCatalogueCategoryRules } from 'app/redux/actions/catalogue/catalogueCategories';
import { initForm, removeForm, sendForm } from 'app/redux/actions/forms';
import * as Store from 'app/redux/store/StoreNamespace';

import CategoryRulesForm from './CategoryRulesForm';

import { FormName } from '../../../api/FormName';

import {
    ICategoryRulesFormStateProps,
    ICategoryRulesFormOwnProps,
    ICategoryRulesFormActionProps,
} from '.';

const mapStateToProps = (
    state: Store.IState,
    ownProps: ICategoryRulesFormOwnProps
): ICategoryRulesFormStateProps => {
    const category =
        state.catalogueCategories.categories &&
        state.catalogueCategories.categories[ownProps.categoryId];
    return {
        rules: category && category.item.rules,
        formStatus:
            state.forms[FormName.CatalogueCategoryRules] &&
            state.forms[FormName.CatalogueCategoryRules].status,
    };
};

const mapDispatchToProps = (dispatch): ICategoryRulesFormActionProps => ({
    initForm(): void {
        dispatch(initForm(FormName.CatalogueCategoryRules, null));
    },
    removeForm(): void {
        dispatch(removeForm(FormName.CatalogueCategoryRules));
    },
    loadRules(categoryId: string): void {
        dispatch(fetchCatalogueCategoryRules(categoryId));
    },
    sendRules(categoryId: string, rules: Store.ICategorizationRule[]): void {
        dispatch(
            sendForm(FormName.CatalogueCategoryRules, {
                categoryCode: categoryId,
                rules,
            })
        );
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryRulesForm);
