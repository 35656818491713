import { MiddlewareAPI } from 'redux';
import { Action } from 'redux-actions';
import { Observable } from 'rxjs';
import { concat } from 'rxjs/observable/concat';

import { createSimpleLoadingEpic } from 'app/redux/epics/utils';
import {
    searchTabsRequest,
    searchTabsResolve,
    searchTabsReject,
    searchTabsSimpleRequest,
    searchTabsSimpleResolve,
    searchTabsSimpleReject,
    searchTabRequest,
    searchTabResolve,
    searchTabReject,
} from 'app/redux/actions/loading';
import {
    fetchSearchTabs,
    setSearchTabsMeta,
    fetchSearchTabsSimple,
    fetchSingleSearchTabFull,
    setSingleSearchTabMeta,
    setSearchActiveTab,
    ISetSearchActiveTabPayload,
    IFetchSingleSearchTabFullPayload,
    ISetSingleSearchTabMetaPayload,
} from 'app/redux/actions/search/searchTabs';

import { setPersonalization } from '../actions/search/personalization';
import { ISearchTab } from '../store/StoreNamespace';
import { withIgnoreSearchTriggerMeta } from '../utils/withIgnoreSearchTriggerMeta';
import { resetSorting } from '../actions/sorting';
import * as Store from '../store/StoreNamespace';

export const loadSearchTabsMeta = createSimpleLoadingEpic({
    triggers: [fetchSearchTabs.toString()],
    apiCallName: 'searchTabs',
    actions: {
        requestAction: searchTabsRequest,
        resolveAction: searchTabsResolve,
        rejectAction: searchTabsReject,
        setAction: setSearchTabsMeta,
        spawnOnSuccess: [
            (response: ISearchTab[]) => {
                const defaultTab = response[0];
                const personalization = defaultTab?.personalization;
                if (typeof personalization === 'boolean') {
                    return withIgnoreSearchTriggerMeta(
                        setPersonalization(personalization)
                    );
                }
            },
        ],
    },
});

export const loadSearchTabsMetaSimple = createSimpleLoadingEpic({
    triggers: [fetchSearchTabsSimple.toString()],
    apiCallName: 'searchTabsSimple',
    actions: {
        requestAction: searchTabsSimpleRequest,
        resolveAction: searchTabsSimpleResolve,
        rejectAction: searchTabsSimpleReject,
        setAction: setSearchTabsMeta,
    },
});

export const loadSingleTabFullMeta = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }
) =>
    action$
        .ofType(fetchSingleSearchTabFull.toString())
        .mergeMap(({ payload }: Action<IFetchSingleSearchTabFullPayload>) =>
            concat(
                apiCall
                    .searchTab(payload.tabCode)
                    .mergeMap(({ response }) => {
                        return Observable.of(
                            setSingleSearchTabMeta({
                                tab: response,
                                setPersonalization: payload.setPersonalization,
                            })
                        );
                    })
                    .catch((e) => {
                        console.error(e);
                        return Observable.of(searchTabReject(e));
                    })
                    .takeUntil(
                        action$.ofType(fetchSingleSearchTabFull.toString())
                    ),
                Observable.of(searchTabResolve())
            )
                .takeUntil(action$.ofType(searchTabReject.toString()))
                .startWith(searchTabRequest())
        );

export const setSearchTabFullMetaEpic = (
    action$,
    store: MiddlewareAPI<Store.IState>
) =>
    action$
        .ofType(setSingleSearchTabMeta.toString())
        .switchMap(({ payload }: Action<ISetSingleSearchTabMetaPayload>) => {
            const state = store.getState();
            const newSearchTab = state.searchTabs.tabs?.[payload.tab.code];
            const newSortingOptions = newSearchTab?.sortingOptions;
            const currentSortingKey = state.search.sorting?.key;

            const actions = [];

            if (
                newSortingOptions.every(
                    (option) => option.key !== currentSortingKey
                )
            ) {
                actions.push(withIgnoreSearchTriggerMeta(resetSorting()));
            }

            payload.setPersonalization &&
                actions.push(
                    withIgnoreSearchTriggerMeta(
                        setPersonalization(
                            newSearchTab.personalization ?? false
                        )
                    )
                );

            return Observable.of(...actions);
        });

export const setSearchActiveTabEpic = (action$) =>
    action$
        .ofType(setSearchActiveTab.toString())
        .map(({ payload }: Action<ISetSearchActiveTabPayload>) => {
            return fetchSingleSearchTabFull({
                tabCode: payload.tabCode,
                setPersonalization: payload.setPersonalization ?? true,
            });
        });
