/**
 * Created by lkarmelo on 03.09.2018.
 */

import { connect } from 'react-redux';

import { ApproveAction } from 'app/components/Modals/index';
import { initForm, removeForm, sendForm } from 'app/redux/actions/forms';
import {
    IApproveActionActionProps as IActionProps,
    IApproveActionOwnProps as IOwnProps,
    IApproveActionStateProps as IStateProps,
} from 'app/components/Modals/ApproveAction/interfaces/IApproveActionProps';
import * as Store from 'app/redux/store/StoreNamespace';
import { FormName } from 'app/api/FormName';

import IFormData from './interfaces/IFormData';

interface IAdditionalProps {
    documentId: string;
    onDeleteSuccess?(
        props: IOwnProps<IAdditionalProps> & IAdditionalProps
    ): any;
}

const mapStateToProps = (
    state: Store.IState,
    ownProps: IOwnProps<IAdditionalProps> & IAdditionalProps
): IOwnProps<IAdditionalProps> & IStateProps & IAdditionalProps => {
    return {
        status:
            state.forms[FormName.DeleteDocument] &&
            state.forms[FormName.DeleteDocument].status,
        isVisible: ownProps.isVisible,
        onCancel: ownProps.onCancel,
        successMsgText: 'Документ успешно удален',
        documentId: ownProps.documentId,
        approveBtnText: 'Удалить',
    };
};

const mapDispatchToProps = () => {
    const defaultOnSentSuccessAfterHidden = () => {
        // TODO: заменить на просто удаление документа из листов?
        document.location.reload();
    };
    return (
        dispatch,
        ownProps: IOwnProps<IAdditionalProps> & IAdditionalProps
    ): IActionProps<IAdditionalProps> => ({
        onShown() {
            dispatch(initForm(FormName.DeleteDocument, null));
        },
        onHidden() {
            dispatch(removeForm(FormName.DeleteDocument));
        },
        onSend({ documentId }: IAdditionalProps) {
            dispatch(
                sendForm(FormName.DeleteDocument, <IFormData>{ documentId })
            );
        },
        onSentSuccessAfterHidden: ownProps.onDeleteSuccess
            ? ownProps.onDeleteSuccess
            : defaultOnSentSuccessAfterHidden,
    });
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApproveAction as any);
