import * as React from 'react';

import { getSafetyInnerHtml } from './safetyInnerHtml';

const startTag = `<span class="highlight-search">`;
const endTag = `</span>`;

interface IHighlightedContentModel {
    value: string;
    highlighted?: boolean;
}

const checkIfHasTag = (str: string, tag: string) => str.includes(tag);

const clearifyContent = (str: string): IHighlightedContentModel[] => {
    const convertedContent: IHighlightedContentModel[] = [];

    if (checkIfHasTag(str, startTag)) {
        const strConverted = str.split(startTag);
        strConverted.forEach((item) => {
            if (checkIfHasTag(item, endTag)) {
                convertedContent.push({
                    value: item.slice(0, item.indexOf(endTag)),
                    highlighted: true,
                });
                convertedContent.push({
                    value: item.slice(item.indexOf(endTag) + endTag.length),
                });
            } else {
                convertedContent.push({ value: item });
            }
        });
    } else {
        convertedContent.push({ value: str });
    }

    return convertedContent;
};

export const getClearedDangerousHtml = (str: string) =>
    clearifyContent(getSafetyInnerHtml(str)).map((item) =>
        item.highlighted ? (
            <span className="highlight-search">{item.value}</span>
        ) : (
            item.value
        )
    );

export const getFormattedByBrText = (str: string) =>
    str
        .split('<br>')
        .map((strWithoutBr) => (
            <div key={strWithoutBr}>
                {getClearedDangerousHtml(strWithoutBr)}
            </div>
        ));
