/**
 * Created by Lkarmelo on 28.11.2017.
 */

import { handleActions, Action } from 'redux-actions';

import { FormName } from 'app/api/FormName';

import {
    INIT_FORM,
    REMOVE_FORM,
    CHANGE_FIELD,
    ADD_SUB_FIELD,
    IAddSubFieldPayload,
    CHANGE_SUB_FIELD,
    IEditSubFieldPayload,
    REMOVE_SUB_FIELD,
    IRemoveSubField,
    SET_FORM_STATUS,
    ISetFormStatusPayload,
    SET_FORM_RESPONSE,
    ISetFormResponsePayload,
} from '../actions/forms';
import * as Store from '../store/StoreNamespace';

const forms = handleActions<Store.IForms, any>(
    {
        [INIT_FORM](
            state: Store.IForms,
            { payload }: Action<{ name: FormName; data: any }>
        ) {
            const nextState: Store.IForms = { ...state };
            nextState[payload.name] = {
                data: payload.data,
                status: Store.FormStatus.Editing,
            };
            return nextState;
        },
        [REMOVE_FORM](state: Store.IForms, { payload }: Action<string>) {
            const nextState: Store.IForms = { ...state };
            delete nextState[payload];
            return nextState;
        },
        [SET_FORM_RESPONSE](
            state: Store.IForms,
            { payload }: Action<ISetFormResponsePayload>
        ) {
            if (!state[payload.formName]) {
                return state;
            }
            const nextState = { ...state };
            nextState[payload.formName].response = payload.response;
            return nextState;
        },
        [CHANGE_FIELD](
            state: Store.IForms,
            {
                payload,
            }: Action<{ formName: FormName; fieldName: string; value: any }>
        ) {
            if (!state[payload.formName]) {
                return state;
            }
            const nextState = { ...state };
            nextState[payload.formName].data = {
                ...nextState[payload.formName].data,
            };
            nextState[payload.formName].data[payload.fieldName] = payload.value;
            return nextState;
        },
        [ADD_SUB_FIELD](
            state: Store.IForms,
            { payload }: Action<IAddSubFieldPayload>
        ) {
            if (!state[payload.formName]) {
                return state;
            }
            const nextState = { ...state };
            const formData = { ...nextState[payload.formName].data };
            nextState[payload.formName].data = formData;
            if (payload.key) {
                formData[payload.fieldName] = {
                    ...formData[payload.fieldName],
                };
                formData[payload.fieldName][payload.key] = payload.value;
            } else {
                formData[payload.fieldName] =
                    formData[payload.fieldName].slice();
                formData[payload.fieldName].push(payload.value);
            }
            return nextState;
        },
        [REMOVE_SUB_FIELD](
            state: Store.IForms,
            { payload }: Action<IRemoveSubField>
        ) {
            if (!state[payload.formName]) {
                return state;
            }
            const nextState = { ...state };
            nextState[payload.formName].data = {
                ...nextState[payload.formName].data,
            };
            const field = nextState[payload.formName].data[payload.fieldName];
            if (Array.isArray(field)) {
                let nextField;
                if (payload.key) {
                    nextField = field.slice(0, +payload.key);
                    nextField = nextField.concat(field.slice(+payload.key + 1));
                } else {
                    nextField = [];
                    field.forEach(
                        (f) => f !== payload.value && nextField.push(f)
                    );
                }
                nextState[payload.formName].data[payload.fieldName] = nextField;
            } else {
                delete field[payload.key];
            }
            return nextState;
        },
        [CHANGE_SUB_FIELD](
            state: Store.IForms,
            { payload }: Action<IEditSubFieldPayload>
        ) {
            if (!state[payload.formName]) {
                return state;
            }
            const nextState = { ...state };
            const formData = { ...nextState[payload.formName].data };
            nextState[payload.formName].data = formData;
            if (Array.isArray(formData[payload.fieldName])) {
                formData[payload.fieldName] =
                    formData[payload.fieldName].slice();
            } else {
                formData[payload.fieldName] = {
                    ...formData[payload.fieldName],
                };
            }
            formData[payload.fieldName][payload.key] = payload.value;
            return nextState;
        },
        [SET_FORM_STATUS](
            state: Store.IForms,
            { payload }: Action<ISetFormStatusPayload>
        ) {
            const nextState: Store.IForms = { ...state };
            if (!state[payload.formName]) {
                nextState[payload.formName] = {
                    data: undefined,
                    status: undefined,
                };
            }
            nextState[payload.formName].status = payload.status;
            return nextState;
        },
    },
    {} // - default value for forms
);

export default forms;
