/**
 * Created by lkarmelo on 07.10.2019.
 */

import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useNormalizedOrgs } from 'app/hooks/useNormalizedOrgs';
import { useHighlighted } from 'app/hooks/useHighlighted';
import { getPersonsLoginsByProfiles } from 'app/utils/document/login';
import clientRoutes from 'app/routing/clientRoutes';
import * as Store from 'app/redux/store/StoreNamespace';

import IProps from './interfaces/IOrgsAuthorsProps';
import * as styles from './OrgsAuthors.scss';

const OrgsAuthors: React.FC<IProps> = (props) => {
    const { document, ignoreHighlight, delimiterClassName } = props;

    const { meta, profiles, highlights } =
        document || ({} as Store.IDocumentSnippet);
    const { persons, orgs } = meta || ({} as Store.IDocumentDescription);

    const personWithLink = useMemo(
        () => getPersonsLoginsByProfiles(persons, profiles),
        [persons, profiles]
    );
    const normalizedOrgs = useNormalizedOrgs(orgs);

    const [_, highlightedOrgs, highlightedPersons] = useHighlighted({
        highlights,
        orgs: normalizedOrgs,
        personWithLink,
        ignoreHighlight,
    });
    return (
        <div className={styles.orgsAuthors}>
            {highlightedOrgs &&
                highlightedOrgs.ids.slice(0, 1).map((orgId, index) => {
                    const org = highlightedOrgs.orgs[orgId];
                    if (!org) {
                        return null;
                    }
                    const delimiter =
                        index > 0 ? (
                            <div className={delimiterClassName} />
                        ) : null;
                    return (
                        <React.Fragment key={`${org.id}-${org.title}`}>
                            {delimiter}
                            <div
                                dangerouslySetInnerHTML={{ __html: org.title }}
                            />
                            {highlightedOrgs.ids.length > 1 && (
                                <>
                                    <div className={delimiterClassName} />
                                    <div>
                                        и ещё {highlightedOrgs.ids.length - 1}
                                    </div>
                                </>
                            )}
                        </React.Fragment>
                    );
                })}
            {highlightedOrgs &&
                highlightedOrgs.ids.length > 0 &&
                highlightedPersons &&
                highlightedPersons.ids.length > 0 && (
                    <div className={delimiterClassName} />
                )}
            {highlightedPersons &&
                highlightedPersons.ids.slice(0, 2).map((personId, index) => {
                    const person = highlightedPersons.persons[personId];
                    if (!person) {
                        return null;
                    }

                    const delimiter =
                        index > 0 ? (
                            <div className={delimiterClassName} />
                        ) : null;

                    return (
                        <React.Fragment key={`${personId}-${person.fullName}`}>
                            {delimiter}
                            {person.link ? (
                                <div>
                                    <Link
                                        target="_blank"
                                        to={clientRoutes.account.subRoutes.profile.getUrl(
                                            null,
                                            {
                                                user: person.link,
                                                source: person.source,
                                            }
                                        )}
                                        dangerouslySetInnerHTML={{
                                            __html: person.fullName,
                                        }}
                                    />
                                </div>
                            ) : (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: person.fullName,
                                    }}
                                />
                            )}
                            {index === 1 && highlightedPersons.ids.length > 2 && (
                                <>
                                    {delimiter}
                                    <div>
                                        и ещё{' '}
                                        {highlightedPersons.ids.length - 2}
                                    </div>
                                </>
                            )}
                        </React.Fragment>
                    );
                })}
        </div>
    );
};

export default OrgsAuthors;
