/**
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 10.05.2018
 * -----
 * Last Modified: 07.06.2018 18:13:02
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import { handleActions, handleAction, Action } from 'redux-actions';

import { traversTags } from 'app/utils/expansionUtils';

import * as Store from '../../store/StoreNamespace';
import initialState from '../../store/initialState';
import {
    toggleSelectedQueryTag,
    setQueryExpansion,
    setQueryExpansionMode,
    setSelectedQueryTags,
} from '../../actions/search/searchQuery';

export const isQueryExpansion = handleAction<boolean, boolean>(
    setQueryExpansionMode.toString(),
    (currentMode: boolean, { payload }: { payload: boolean; type: string }) =>
        payload,
    false
);

export const queryTags = handleActions<
    Store.IQueryTags | null,
    Store.IQueryTags | string | null
>(
    {
        [setQueryExpansion.toString()](
            state: Store.IQueryTags,
            { payload }: { payload: Store.IQueryTags | null; type: string }
        ) {
            return traversTags(payload, (term) => ({
                term: term.term,
                expansion: (term.expansion || []).map((item) => {
                    return { ...item };
                }),
            }));
        },
    },
    initialState.search.queryTags // -- default results value
);

export const selectedQueryTags = handleActions<string[], any>(
    {
        [toggleSelectedQueryTag.toString()](
            state: string[],
            { payload }: Action<string>
        ): string[] {
            if (!Array.isArray(state)) {
                return [payload];
            }
            const idIndex = state.indexOf(payload);

            if (idIndex < 0) {
                const nextState = state.slice();
                nextState.push(payload);
                return nextState;
            }
            const nextState = state.slice(0, idIndex);
            nextState.push(...state.slice(idIndex + 1));
            return nextState;
        },
        [setSelectedQueryTags.toString()](
            _: string[],
            { payload }: Action<string[]>
        ): string[] {
            return payload;
        },
    },
    []
);
