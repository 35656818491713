import { createAction } from 'redux-actions';

import * as Store from '../../store/StoreNamespace';

export const fetchCatalogueCategories = createAction(
    'FETCH_CATALOGUE_CATEGORIES'
);

export const setCatalogueCategories =
    createAction<Store.INormalizedHierarchicalCategories>(
        'SET_CATALOGUE_CATEGORIES'
    );

export interface ISetCatalogueCategoryPayload {
    categoryId: string;
    category: Store.ICatalogueCategoryItem;
}
export const setCatalogueCategory = createAction<
    ISetCatalogueCategoryPayload,
    string,
    Store.ICatalogueCategoryItem
>(
    'SET_CATALOGUE_CATEGORY',
    (categoryId: string, category: Store.ICatalogueCategoryItem) => ({
        categoryId,
        category,
    })
);

export const loadCatalogueCategoryKeywords = createAction<string>(
    'LOAD_CATALOGUE_CATEGORY_KEYWORDS'
);

export interface ISetCatalogueCategoryKeywordsPayload {
    categoryId: string;
    keywords: string[];
}
export const setCatalogueCategoryKeywords = createAction<
    ISetCatalogueCategoryKeywordsPayload,
    string,
    string[]
>(
    'SET_CATALOGUE_CATEGORY_KEYWORDS',
    (categoryId: string, keywords: string[]) => ({ categoryId, keywords })
);

export const fetchCatalogueCategoryRules = createAction<string>(
    'LOAD_CATALOGUE_CATEGORY_RULES'
);

export interface ISetCatalogueCategoryRulesPayload {
    categoryId: string;
    rules: Store.ICategorizationRule[];
}
export const setCatalogueCategoryRules =
    createAction<ISetCatalogueCategoryRulesPayload>(
        'SET_CATALOGUE_CATEGORY_RULES'
    );

export interface IChangeCategoryCountPayload {
    categoryId: string;
    delta: number;
}
export const categoryChangeCount = createAction<IChangeCategoryCountPayload>(
    'CATEGORY_CHANGE_COUNT'
);
export const categoryChangeUnverifiedCount =
    createAction<IChangeCategoryCountPayload>(
        'CATEGORY_CHANGE_UNVERIFIED_COUNT'
    );
