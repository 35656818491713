/**
 * Created by Lkarmelo on 21.11.2017.
 */

import { handleActions } from 'redux-actions';

import { SET_CURRENT_USER_COMPETENCIES } from '../actions/currentUserCompetencies';
import * as Store from '../store/StoreNamespace';

export default handleActions(
    {
        [SET_CURRENT_USER_COMPETENCIES](
            state: Store.IUserCompetence[],
            { payload }: { payload: Store.IUserCompetence[]; type: string }
        ) {
            return payload;
        },
    },
    [] // - default value for userCompetencies
);
