import { Action, handleActions } from 'redux-actions';

import Api from 'app/api/Api';

import * as Store from '../store/StoreNamespace';
import initialState from '../store/initialState';
import { fetchGuideStatsList, setGuideStats } from '../actions/tour';
import { IGuide } from '../../types/tour';

const statsReducer = handleActions<Partial<Store.IGuideResults>, any>(
    {
        [fetchGuideStatsList.toString()](
            state: Store.IGuideResults,
            {
                payload,
            }: { payload: Action<Api.IGuideListResponse>; type: string }
        ) {
            return { ...state, ...payload };
        },
        [setGuideStats.toString()](
            state: Store.IGuideResults,
            { payload }: { payload: IGuide; type: string }
        ) {
            return { ...state, ...payload };
        },
    },
    initialState.guideResults
);

export default statsReducer;
