/**
 * Created by Lkarmelo on 29.05.2018.
 */

import { createAction } from 'redux-actions';

import * as Store from '../store/StoreNamespace';

export const fetchEducationMaterialTypes = createAction(
    'FETCH_EDUCATION_MATERIAL_TYPES'
);

export const setEducationMaterialTypes = createAction<Store.IOrganizationsInfo>(
    'SET_EDUCATION_MATERIAL_TYPES'
);
