/*
 * Created by Lkarmelo on 23.11.2017.
 * -----
 * Last Modified: 11.07.2018 10:41:32
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import queryString from 'qs';
import autoBind from 'autobind-decorator';
import memoizeOne from 'memoize-one';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';

import {
    FeatureRenderer,
    UIFeaturesName,
} from 'app/components/common/utils/FeatureRenderer';
import scrollToTopOnMount from 'app/components/high-order/scrollToTopOnMount';
import clientRoutes, { ProfileQueryParams } from 'app/routing/clientRoutes';
import { FunctionalityName, getFunctionalityAllowed } from 'app/roles';
import {
    fetchCurrentUserData,
    IFetchCurrentUserDataPayload,
} from 'app/redux/actions/currentUser';
import { fetchCurrentUserWorkInfo } from 'app/redux/actions/currentUserWorkInfo';
import * as Store from 'app/redux/store/StoreNamespace';
import { parseOptions, stringifyOptions } from 'app/utils/queryStringOptions';
import { getUserNameByUserObject } from 'app/utils/getUserNameByUserObject';

import IProps, {
    IAccountStateProps as IStateProps,
} from './interfaces/IAccountProps';
import * as styles from './Account.scss';

import { fetchFavoritesFiltersMeta } from '../../../redux/actions/search/filtersMeta';

@scrollToTopOnMount
@autoBind
class Account extends Component<IProps> {
    static defaultProps = {
        firstName: '',
        lastName: '',
        middleName: '',
        email: [],
    };

    initialPathName: string = null;

    location$: BehaviorSubject<string> = null;

    unListenHistory: () => void = null;

    locationSubscription: Subscription = null;

    getSubRoutesLocationSearch = memoizeOne((search: string): string => {
        // нужно, чтобы убрать лишние параметры из адресной строки
        // например, после того, как перешли по ссылке в связанные документы с выставленным фильтром
        const { id, user, source } = queryString.parse(
            search,
            parseOptions
        ) as Record<ProfileQueryParams, string>;
        return queryString.stringify({ id, user, source }, stringifyOptions);
    });

    render(): JSX.Element {
        const {
            children,
            firstName,
            lastName,
            middleName,
            post,
            isFavoriteViewable,
            isSubscribeViewable,
        } = this.props;
        const { search } = this.props.location;

        const profileUrl =
            clientRoutes.account.subRoutes.profile.getUrl() +
            this.getSubRoutesLocationSearch(search);
        const favoriteUrl =
            clientRoutes.account.subRoutes.favorite.getUrl() +
            this.getSubRoutesLocationSearch(search);
        const subscribeUrl =
            clientRoutes.account.subRoutes.subscribe.getUrl() +
            this.getSubRoutesLocationSearch(search);

        return (
            <section className={styles.account}>
                <div className={styles.accountHeader}>
                    <div className={styles.accountHeaderContent}>
                        <div className={styles.accountAbout}>
                            <div className={styles.accountUserInfo}>
                                <div className={styles.accountName}>
                                    {getUserNameByUserObject(
                                        { firstName, lastName, middleName },
                                        true
                                    )}
                                </div>
                                {post && (
                                    <div className={styles.accountPost}>
                                        {post.title}
                                    </div>
                                )}
                            </div>
                        </div>
                        <nav className={`${styles.accountNav} clearfix`}>
                            <FeatureRenderer
                                featureName={UIFeaturesName.AccountProfile}
                            >
                                <NavLink
                                    to={profileUrl}
                                    isActive={(match, location) =>
                                        location.pathname + location.search ===
                                        profileUrl
                                    }
                                >
                                    Профиль
                                </NavLink>
                            </FeatureRenderer>
                            {isFavoriteViewable && (
                                <FeatureRenderer
                                    featureName={UIFeaturesName.AccountFavorite}
                                >
                                    <NavLink
                                        to={favoriteUrl}
                                        isActive={(match, location) =>
                                            location.pathname +
                                                location.search ===
                                            favoriteUrl
                                        }
                                    >
                                        Избранное
                                    </NavLink>
                                </FeatureRenderer>
                            )}
                           {/* {isSubscribeViewable && (
                                <FeatureRenderer
                                    featureName={
                                        UIFeaturesName.AccountSubscribe
                                    }
                                >
                                    <NavLink
                                        to={subscribeUrl}
                                        isActive={(match, location) =>
                                            location.pathname +
                                                location.search ===
                                            subscribeUrl
                                        }
                                    >
                                        Отслеживаемое
                                    </NavLink>
                                </FeatureRenderer>
                            )}*/}
                        </nav>
                    </div>
                </div>
                <div className={styles.accountContent}>{children}</div>
            </section>
        );
    }

    componentWillMount(): void {
        this.createObservableLocation();
        this.initialPathName = this.props.location.pathname;
    }

    componentDidMount(): void {
        const { dispatch } = this.props;
        dispatch(fetchFavoritesFiltersMeta());
    }

    componentDidUpdate(prevProps: IProps): void {
        if (prevProps.userId === undefined && this.props.userId !== undefined) {
            this.location$.next(this.props.location.search);
        }
    }

    createObservableLocation(): void {
        const { dispatch } = this.props;
        this.location$ = new BehaviorSubject(this.props.location.search);
        this.locationSubscription = this.location$
            .map((x) => {
                const {
                    id,
                    user: login,
                    source,
                } = queryString.parse(x, parseOptions) as Record<
                    ProfileQueryParams,
                    string
                >;
                return {
                    id: id || (login ? undefined : this.props.userId),
                    login,
                    source,
                } as IFetchCurrentUserDataPayload;
            })
            .distinctUntilChanged(
                (
                    x: IFetchCurrentUserDataPayload,
                    y: IFetchCurrentUserDataPayload
                ): boolean =>
                    x.id === y.id &&
                    x.login === y.login &&
                    x.source === y.source
            )
            .filter(
                (x: IFetchCurrentUserDataPayload) =>
                    x.id !== undefined ||
                    x.login !== undefined ||
                    x.source !== undefined
            )
            .subscribe((x: IFetchCurrentUserDataPayload) => {
                dispatch(fetchCurrentUserData(x));
                dispatch(fetchCurrentUserWorkInfo());
            });

        this.unListenHistory = this.props.history.listen((location) => {
            location.pathname.indexOf(this.props.match.path) === 0 &&
                this.location$.next(location.search);
        });
    }

    componentWillUnmount(): void {
        this.unListenHistory();
        this.locationSubscription.unsubscribe();
    }
}

const mapStateToProps = (state: Store.IState): IStateProps => {
    const isOwnAccount = state.user.id === state.currentUser.id;
    const workInfo = state.currentUserWorkInfo && state.currentUserWorkInfo[0];

    return {
        firstName: state.currentUser.firstName,
        lastName: state.currentUser.lastName,
        middleName: state.currentUser.middleName,
        post: workInfo && workInfo.post,

        userId: state.user.id,

        isFavoriteViewable: getFunctionalityAllowed(
            state.user,
            isOwnAccount
                ? FunctionalityName.OwnFavoriteView
                : FunctionalityName.ForeignFavoriteView
        ),
        isSubscribeViewable: getFunctionalityAllowed(
            state.user,
            isOwnAccount
                ? FunctionalityName.OwnSubscribeView
                : FunctionalityName.ForeignSubscribeView
        ),
    };
};

export default connect(mapStateToProps)(Account);
