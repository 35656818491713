/**
 * Created by Lkarmelo on 02.10.2017.
 */

import { createAction, ActionMeta, Action } from 'redux-actions';

export interface ISetSkipMeta {
    ignorePagingBlock: boolean;
}
interface ISetSkip {
    (skip: number, ignorePagingBlock?: boolean): ActionMeta<
        number,
        ISetSkipMeta
    >;
}
export const setSkip: ISetSkip = createAction<
    number,
    ISetSkipMeta,
    number,
    boolean
>(
    'SET_SKIP',
    (skip) => skip,
    (_, ignorePagingBlock = false) => ({ ignorePagingBlock })
);

export interface ISetLimitPayload {
    limit: number;
    resetSkip?: boolean;
}
export const setLimit = createAction<ISetLimitPayload>('SET_LIMIT');

export const blockPaging = createAction('BLOCK_PAGING');
export const unblockPaging = createAction('UNBLOCK_PAGING');

export interface IResetPagingPayload {
    resetLimit?: boolean;
}
interface IResetPaging {
    (resetLimit?: boolean): Action<IResetPagingPayload>;
}
export const resetPaging: IResetPaging = createAction<
    IResetPagingPayload,
    boolean
>('RESET_PAGING', (resetLimit = false) => ({ resetLimit }));
