import Badge from 'antd/es/badge';
import { Link } from 'react-router-dom';
import React from 'react';

import NotificationPreview from 'app/components/common/Notification/NotificationPreview';
import clientRoutes from 'app/routing/clientRoutes';
import { useTypedSelector } from 'app/hooks/useTypedSelector';
import * as Store from 'app/redux/store/StoreNamespace';
import { useActions } from 'app/hooks/useActions';

import * as styles from './DropdownContent.scss';

const DropdownContent = ({ notificationsToRender, onMenuClick }) => {
    const { newCount: newNotificationCount } = useTypedSelector(
        (state) => state[Store.NotificationContext]
    );

    const { requestNotificationsRead: markAsRead } = useActions();

    return (
        <div>
            <div className={styles.dropdownContentTitle}>
                Уведомления
                <Badge
                    count={newNotificationCount}
                    offset={[0, 5]}
                    className={styles.dropdownContentBadge}
                />
            </div>
            <div
                className={styles.dropdownContentNotifications}
                onClick={onMenuClick}
            >
                {notificationsToRender.map((notification) => (
                    <div
                        key={notification.id}
                        onMouseEnter={() =>
                            !notification.isRead &&
                            markAsRead([notification.id])
                        }
                    >
                        <NotificationPreview notification={notification} />
                    </div>
                ))}
            </div>
            <div className={styles.dropdownContentLinkContainer}>
                <Link
                    to={clientRoutes.notifications.getUrl()}
                    className={styles.dropdownContentLink}
                >
                    Все уведомления
                </Link>
            </div>
        </div>
    );
};

export default DropdownContent;
