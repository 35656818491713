import { MiddlewareAPI } from 'redux';
import { Observable } from 'rxjs/Observable';
import { concat } from 'rxjs/observable/concat';
import { empty } from 'rxjs/observable/empty';
import { Action } from 'redux-actions';

import { createRetryOnIEAuthProblem } from './utils/createRetryOnIEAuthProblem';

import {
    SET_SUBSCRIBE,
    SEND_SUBSCRIBE,
    setSubscribe,
} from '../actions/subscribes';
import {
    sendSubscribeRequest,
    sendSubscribeResolve,
    sendSubscribeReject,
} from '../actions/loading';
import { updateSearchResult } from '../actions/search/results';
import * as Store from '../store/StoreNamespace';
import { withContext } from '../context/connectWithContext';
import { searchResultDocumentList } from '../actions/interfaces/DocumentEnum';

export const sendSubscribeMark = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }
) =>
    action$
        .ofType(SEND_SUBSCRIBE)
        .mergeMap((action: Action<Store.IMarkDocument>) =>
            concat(
                (action.payload.mark
                    ? apiCall.markSubscribes(action.payload.documentId)
                    : apiCall.unmarkSubscribes(action.payload.documentId)
                )
                    .retryWhen(createRetryOnIEAuthProblem())
                    .map(() => setSubscribe(action.payload))
                    .catch((e) => {
                        console.error(e);
                        return (!action.payload.mark && e.status === 400) ||
                            (action.payload.mark && e.status === 500)
                            ? concat(
                                  Observable.of(setSubscribe(action.payload)),
                                  Observable.of(sendSubscribeReject(e))
                              )
                            : Observable.of(sendSubscribeReject(e));
                    })
                    .takeUntil(action$.ofType(SEND_SUBSCRIBE)),
                Observable.of(sendSubscribeResolve())
            )
                .takeUntil(action$.ofType(sendSubscribeReject.toString()))
                .startWith(sendSubscribeRequest())
        );

export const updateSubscribeMark = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }
) =>
    action$
        .ofType(SET_SUBSCRIBE)
        .mergeMap((action: Action<Store.IMarkDocument>) => {
            const updateAtom: Store.IUpdateDocumentSnippet = {
                id: action.payload.documentId,
                subscribed: action.payload.mark,
            };

            const updateAction = updateSearchResult(updateAtom);
            const updateDocumentActions = searchResultDocumentList.map((key) =>
                Observable.of(withContext(updateAction, key))
            );

            return concat(Observable.of(updateAction), ...updateDocumentActions)
                .catch((e) => {
                    console.error(e);
                    return empty();
                })
                .takeUntil(action$.ofType(SET_SUBSCRIBE));
        });
