export const findClosest = (
    element: HTMLElement,
    comparator: (el: HTMLElement) => boolean
): HTMLElement | null | undefined => {
    let parent = element;
    while (!!parent && !comparator(parent)) {
        parent = parent.parentElement;
    }
    return parent;
};
