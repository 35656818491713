/**
 * Created by Lkarmelo on 25.08.2017.
 */

import React, { ReactNode } from 'react';
import classNames from 'classnames';

import BigImage from 'app/components/common/BigImage/BigImageConnected';
import { useTypedSelector } from 'app/hooks/useTypedSelector';

import * as styles from './SearchResult.scss';

const SearchResults: React.FunctionComponent<{ children?: ReactNode }> = (
    props
) => {
    const { children } = props;
    const { personResults } = useTypedSelector((store) => store.search);
    const { search: isLoading } = useTypedSelector(
        (store) => store.loading.pendingRequests
    );
    const {
        bigAvatar,
        isConfirmShownBigAvatar,
        userId,
        fullTitle,
        resolution,
    } = personResults;

    return (
        <div
            className={classNames(styles.searchResult, {
                [styles.searchResultLoading]: isLoading,
            })}
        >
            {children}
            <BigImage
                img={bigAvatar}
                visible={isConfirmShownBigAvatar}
                userId={userId}
                fullTitle={fullTitle}
                resolution={resolution}
            />
        </div>
    );
};

export default SearchResults;
