/**
 * Created by lkarmelo on 08.07.2019.
 */

import React, { useCallback } from 'react';

import IProps from './interfaces/IQueryCorrectionProps';
import * as styles from './QueryCorrection.scss';

const QueryCorrection: React.FunctionComponent<IProps> = (props) => {
    const {
        correctedSearchQuery,
        originalSearchQuery,
        lastIgnoreMistakes,
        searchOriginalQuery,
        correctSearchQuery,
    } = props;

    const onSearchOriginalQueryClick = useCallback(() => {
        searchOriginalQuery(originalSearchQuery);
    }, [originalSearchQuery]);

    const onCorrectedQueryClick = useCallback(() => {
        correctSearchQuery(correctedSearchQuery);
    }, [correctedSearchQuery]);

    return correctedSearchQuery && !lastIgnoreMistakes ? (
        <div>
            <div className={styles.queryCorrectionCorrectedBlock}>
                Показаны результаты по запросу&nbsp;
                <button
                    className={`btn ${styles.queryCorrectionCorrectedQuery}`}
                    onClick={onCorrectedQueryClick}
                >
                    {correctedSearchQuery}
                </button>
            </div>
            <div className={styles.queryCorrectionOriginalBlock}>
                Искать&nbsp;
                <button
                    className={`btn ${styles.queryCorrectionCorrectedQuery}`}
                    onClick={onSearchOriginalQueryClick}
                >
                    {originalSearchQuery}
                </button>
            </div>
        </div>
    ) : null;
};

export default QueryCorrection;
