/**
 * Created by lkarmelo on 26.06.2019.
 */

import { useMemo } from 'react';

import * as Store from 'app/redux/store/StoreNamespace';

type HighlightSource = {
    // эта переменная нужна, чтобы игнорировать подсветку, потому что по правилам хуков их нельзя вызывать с условиями, чтобы у них всегда
    // был одинаковый порядок вызова
    ignoreHighlight?: boolean;
    highlights: Store.IHighlights;
    title?: string;
    orgs?: {
        ids: string[];
        orgs: {
            [id: string]: Store.IOrg;
        };
    };
    personWithLink?: {
        persons: {
            [id: string]: {
                link?: string;
                source?: string;
            } & Store.IDocumentPerson;
        };
        ids: string[];
    };
    optionalAttrs?: Store.IDocumentAttr[];
};

const useHighlightedTitle = (
    highlights: Store.IHighlights,
    title: string,
    ignoreHighlight = false
): string =>
    useMemo<string>(() => {
        if (
            ignoreHighlight ||
            !title ||
            !highlights ||
            !Array.isArray(highlights.title) ||
            highlights.title.length === 0
        ) {
            return title;
        }
        return highlights.title.join('. ');
    }, [highlights, title, ignoreHighlight]);

const getOrderedHighlights = (
    highlights: Store.IInnerHit[],
    process: Function,
    ids: string[]
): string[] => {
    const orderedIds = [];

    highlights.forEach(({ highlight, source }) => {
        const id = source.id || source.title;
        orderedIds.push(id);

        process(highlight, id);
    });

    ids.forEach((id) => {
        if (!orderedIds.includes(id)) {
            orderedIds.push(id);
        }
    });

    return orderedIds;
};

const useHighlightedOrgs = (
    highlights: Store.IHighlights,
    orgs: HighlightSource['orgs'],
    ignoreHighlight = false
): HighlightSource['orgs'] =>
    useMemo<HighlightSource['orgs']>(() => {
        if (
            ignoreHighlight ||
            !orgs ||
            !highlights ||
            !Array.isArray(highlights.organizations) ||
            highlights.organizations.length === 0
        ) {
            return orgs;
        }
        const hlOrgs = { ...orgs };

        hlOrgs.ids = getOrderedHighlights(
            highlights.organizations,
            (highlight, id) => {
                hlOrgs.orgs[id] = {
                    ...orgs.orgs[id],
                    title: highlight.join(' '),
                };
            },
            orgs.ids
        );

        return hlOrgs;
    }, [highlights, orgs, ignoreHighlight]);

const useHighlightedPersons = (
    highlights: Store.IHighlights,
    personWithLink: HighlightSource['personWithLink'],
    ignoreHighlight = false
): HighlightSource['personWithLink'] =>
    useMemo<HighlightSource['personWithLink']>(() => {
        if (
            ignoreHighlight ||
            !personWithLink ||
            !highlights ||
            !Array.isArray(highlights.persons) ||
            highlights.persons.length === 0
        ) {
            return personWithLink;
        }
        const hlPersons = { ...personWithLink };

        hlPersons.ids = getOrderedHighlights(
            highlights.persons,
            (highlight, id) => {
                hlPersons.persons[id] = {
                    ...personWithLink.persons[id],
                    fullName: highlight.join(' '),
                };
            },
            personWithLink.ids
        );

        return hlPersons;
    }, [highlights, personWithLink, ignoreHighlight]);

const useHighlightedAttrs = (
    highlights: Store.IHighlights,
    optionalAttrs: Store.IDocumentAttr[],
    ignoreHighlight = false
): Store.IDocumentAttr[] =>
    useMemo<HighlightSource['optionalAttrs']>(() => {
        if (
            ignoreHighlight ||
            !Array.isArray(optionalAttrs) ||
            !highlights ||
            Object.keys(highlights.optionalAttrs).length === 0
        ) {
            return optionalAttrs;
        }

        return optionalAttrs.map((attr) => {
            if (!highlights.optionalAttrs[attr.code]) {
                return attr;
            }
            return {
                ...attr,
                value: highlights.optionalAttrs[attr.code].join('. '),
            };
        });
    }, [highlights, optionalAttrs, ignoreHighlight]);

export const useHighlighted = ({
    highlights,
    title,
    orgs,
    personWithLink,
    optionalAttrs,
    ignoreHighlight,
}: HighlightSource): [
    string,
    HighlightSource['orgs'],
    HighlightSource['personWithLink'],
    HighlightSource['optionalAttrs']
] => {
    const highlightedTitle = useHighlightedTitle(
        highlights,
        title,
        ignoreHighlight
    );

    const highlightedOrgs = useHighlightedOrgs(
        highlights,
        orgs,
        ignoreHighlight
    );

    const highlightedPersons = useHighlightedPersons(
        highlights,
        personWithLink,
        ignoreHighlight
    );

    const highlightedAttrs = useHighlightedAttrs(
        highlights,
        optionalAttrs,
        ignoreHighlight
    );

    return [
        highlightedTitle,
        highlightedOrgs,
        highlightedPersons,
        highlightedAttrs,
    ];
};
