/**
 * Created by lkarmelo on 31.01.2020.
 */

import { Action } from 'redux-actions';
import { Observable } from 'rxjs/Observable';

import Api from 'app/api/Api';

import { logEvent, ILogEventPayload, LogEvent } from '../actions/logEvents';

export const logEventEpic = <Event extends LogEvent>(
    action$,
    _,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(logEvent.toString())
        .mergeMap(({ payload }: Action<ILogEventPayload<Event>>) =>
            (apiCall.log[payload.event] as any)(...payload.params).catch(
                (e) => {
                    console.error(e);
                    return Observable.empty();
                }
            )
        )
        .ignoreElements();
