import React, { useCallback } from 'react';
import classNames from 'classnames';

import { IOptionGroupProps } from 'app/modules/components/TreeSelect';

import * as styles from './ConceptsPanel.scss';

const ConceptOptionGroup: React.FC<IOptionGroupProps> = (props) => {
    const onLabelWrapperClick = useCallback(() => {
        props.onSelect(props.option);
    }, [props.onSelect]);

    return (
        <div
            className={classNames(
                props.className,
                styles.cardConceptsPanelConceptOptionGroup,
                {
                    [styles.cardConceptsPanelConceptOptionGroupSelected]:
                        props.active,
                }
            )}
        >
            <div
                className={styles.cardConceptsPanelConceptOptionLabelWrapper}
                onClick={onLabelWrapperClick}
            >
                <div className={styles.cardConceptsPanelConceptOptionLabel}>
                    {props.option.label}
                </div>
            </div>
            <div className={styles.cardConceptsPanelConceptOptionGroupChildren}>
                {typeof props.children === 'function'
                    ? props.children(props)
                    : props.children}
            </div>
        </div>
    );
};

export default ConceptOptionGroup;
