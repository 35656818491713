import { setPersonalization } from './search/personalization';
import { setSkip } from './search/searchPaging';
import * as SearchResultActions from './search/results';
import { fetchSearchResults } from './search/results';
import { logEvent } from './logEvents';
import { fetchSearchHints, setSearchHints } from './search/searchHints';
import { filtersToDefaults } from './search/defaultFilters';
import * as searchQueryActions from './search/searchQuery';
import { setQuery } from './search/searchQuery';
import * as searchFilterActions from './search/filters';
import { fetchDoodle } from './doodle';
import * as notificationActions from './notification';
import * as ignoreMistakesActions from './search/ignoreMistakes';
import * as userMenuActions from './userMenu';
import { setModalVisible } from './modals';

import { withIgnoreSearchTriggerMeta } from '../utils/withIgnoreSearchTriggerMeta';

export const ActionCreators = {
    setPersonalization,
    setSkip,
    logEvent,
    fetchSearchHints,
    setSearchHints,
    fetchDoodle,
    filtersToDefaults,
    setModalVisible,
    setQuery,
    fetchSearchResults,
    withIgnoreSearchTriggerMeta,
    ...SearchResultActions,
    ...searchQueryActions,
    ...searchFilterActions,
    ...ignoreMistakesActions,
    ...notificationActions,
    ...userMenuActions,
};
