/**
 * Created by lkarmelo on 30.10.2019.
 */

import React, { useCallback, useMemo } from 'react';
import { useLatest } from 'react-use';

import {
    SelectWithSelectionDelay,
    ISelectionDelayProps,
    ISelectOption,
    HierarchicalList,
} from 'app/modules/components/TreeSelect';
import Sidebox, { useSideboxHelper } from 'app/components/common/Sidebox';
import 'app/modules/components/Sidebox/Sidebox.scss';
import { Filter } from 'app/utils/filters/createNormalizedFiltersSelector';
import { compareSelectOptionsAlphabetically } from 'app/utils/compareSelectOptionsAlphabetically';
import getMapFromArray from 'app/utils/getMapFromArray';
import { noop } from 'app/utils/noop';

import * as styles from './TreeSelectWithSidePanel.scss';

import TreeSelect from '../TreeSelect/TreeSelect';

interface IProps
    extends Omit<
        ISelectionDelayProps,
        | 'onApply'
        | 'headerComponentProps'
        | 'listComponentProps'
        | 'searchBarComponentProps'
    > {
    filterName: string;
    title: string;
    valueFilterMap: Record<string, Filter & { count?: number }>;
    /**
     * включает возможность ставить active родительской опции, если все её дочерние опции выбраны, а так же наоборот
     * убирать active, если хотя бы один потомок не выбран
     */
    enableHierarchicalSelection?: boolean;
    /**
     * Включает виртуальный рендеринг опций в TreeSelect
     */
    enableVirtualRendering?: boolean;
    onApply(filterName: string, options: string[]): void;
    onPreviewDeselect(option: ISelectOption): void;
}

// опции фильтра сортируются в следующем порядке:
// 1. По количечеству найденных документов (по убыванию)
// 2. Если количества нет, то сортируются лексикографически
// 2.1 Сперва названия на кириллице
// 2.2 Названия на латинице
// 2.3 Названия, начинающиеся со спецсимволов
// 2.4 Числа в порядке возрастания
// Если количество документов одинаковое, то такие опции сортируются также лексикографически
const sortOptions = (option1: ISelectOption, option2: ISelectOption) => {
    const optionCount1 = option1.count;
    const optionCount2 = option2.count;
    if (optionCount1 && optionCount2) {
        return (
            optionCount2 - optionCount1 ||
            compareSelectOptionsAlphabetically(option1, option2)
        );
    }

    if (!optionCount1 && optionCount2) {
        return 1;
    }

    if (optionCount1 && !optionCount2) {
        return -1;
    }

    return compareSelectOptionsAlphabetically(option1, option2);
};

const TreeSelectWithSidePanel: React.FC<IProps> = (props) => {
    const {
        active,
        onApply,
        filterName,
        title,
        valueFilterMap,
        onPreviewDeselect,
        enableHierarchicalSelection,
        options,
        enableVirtualRendering,
    } = props;

    const [openSidebox, closeSidebox, sideboxOpened] = useSideboxHelper();

    const headerComponentProps = useMemo(() => ({ title }), [title]);

    // мемоизация отсортированных дочерних опций, чтобы не сортировать больше 1 раза. сбрасывается при обновлении options
    const optionToSortedChildrenMap = useMemo(() => ({}), [options]);
    const optionToSortedChildrenMapRef = useLatest(optionToSortedChildrenMap);

    const previewOptions = useMemo(() => {
        if (!Array.isArray(active) || active.length === 0) {
            return [];
        }
        const activeAsOptions = active.map((activeValId) => ({
            value: activeValId,
            label: valueFilterMap[activeValId].label,
            count: valueFilterMap[activeValId].count,
            filterName,
        }));

        return activeAsOptions;
    }, [active, valueFilterMap, filterName]);

    const traverseFilterParent = useCallback(
        (filter: Filter, processParent: (parent?: string) => void) => {
            if (!filter?.parent) {
                return;
            }
            processParent(filter.parent);
            return traverseFilterParent(
                valueFilterMap[filter.parent],
                processParent
            );
        },
        [valueFilterMap]
    );

    const _onApply = useCallback(
        (nextActive: string[]) => {
            onApply(filterName, nextActive);
        },
        [onApply, filterName]
    );

    const _onPreviewDeselect = useCallback(
        (option: ISelectOption) => {
            // если у нас иерархичный фильтр, то нужно убрать всех родителей
            if (enableHierarchicalSelection) {
                const nextActiveMap = getMapFromArray(active.slice(), true);

                nextActiveMap[option.value] = false;

                traverseFilterParent(valueFilterMap[option.value], (parent) => {
                    nextActiveMap[parent] = false;
                });

                const nextActive = Object.keys(nextActiveMap).filter(
                    (val) => nextActiveMap[val]
                );
                onApply(filterName, nextActive);
            } else {
                onPreviewDeselect(option);
            }
        },
        [
            active,
            onApply,
            valueFilterMap,
            filterName,
            enableHierarchicalSelection,
            onPreviewDeselect,
            traverseFilterParent,
        ]
    );

    const sortChildOptions = useCallback(
        (
            optionsToSort: ISelectOption[],
            parentOption: ISelectOption | undefined
        ): ISelectOption[] => {
            const parentValue = parentOption?.value ?? '__ROOT';
            const sortedMap = optionToSortedChildrenMapRef.current;

            const sortedOptions = sortedMap[parentValue];
            if (
                !sortedOptions ||
                sortedOptions.originalOptions !== optionsToSort
            ) {
                sortedMap[parentValue] = {
                    sorted: optionsToSort.slice().sort(sortOptions),
                    // сохраняем оригинальный массив, чтобы проверять, что он не изменился. если изменился, сортируем заново
                    originalOptions: optionsToSort,
                };
            }

            return sortedMap[parentValue].sorted;
        },
        []
    );

    return (
        <div className={styles.treeSelectWithSidePanel}>
            <div className={styles.treeSelectWithSidePanelActiveList}>
                <TreeSelect
                    collapsible={previewOptions.length > 4}
                    showSearchBar={false}
                    title={title}
                    active={active}
                    options={previewOptions}
                    onSelect={noop}
                    onDeselect={_onPreviewDeselect}
                    enableVirtualRendering
                    itemSize={26}
                    hierarchical
                />
            </div>
            <button
                className={`btn ${styles.treeSelectWithSidePanelOpenBtn}`}
                onClick={openSidebox}
            >
                Выбрать
            </button>
            <Sidebox
                opened={sideboxOpened}
                onOutsideContentClick={closeSidebox}
                className={styles.treeSelectWithSidePanelSidebox}
                showCloseButton
                onCloseButtonClick={closeSidebox}
            >
                <SelectWithSelectionDelay
                    {...props}
                    options={options}
                    optionFilteringThrottleWait={800}
                    listComponentProps={{
                        noFoundPlaceholder: 'Нет подходящих элементов',
                        sortChildOptions,
                    }}
                    headerComponentProps={headerComponentProps}
                    onApply={_onApply}
                    enableVirtualRendering={enableVirtualRendering}
                    itemSize={26}
                    ListComponent={
                        enableHierarchicalSelection
                            ? HierarchicalList
                            : undefined
                    }
                >
                    {(treeSelectElement, apply) => (
                        <div>
                            <div>{treeSelectElement}</div>
                            <button
                                className={`btn-primary ${styles.treeSelectWithSidePanelApplyBtn}`}
                                onClick={() => {
                                    apply();
                                    closeSidebox();
                                }}
                            >
                                Применить
                            </button>
                        </div>
                    )}
                </SelectWithSelectionDelay>
            </Sidebox>
        </div>
    );
};

TreeSelectWithSidePanel.defaultProps = {
    active: [],
};

export default TreeSelectWithSidePanel;
