import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import * as Store from 'app/redux/store/StoreNamespace';

import Definitions from './Definitions';

const getStorageSourceValueToTitleMap = createSelector(
    (state: Store.IState): Store.IFilterMeta => state.filtersMeta.storageSource,
    (storageSourceMeta): Record<string, string> => {
        if (!storageSourceMeta || !Array.isArray(storageSourceMeta.values)) {
            return {};
        }

        const map = {};
        storageSourceMeta.values.reduce((res, { value, label }) => {
            res[value] = label;
            return res;
        }, map);
        return map;
    }
);

const mapStateToProps = (state: Store.IState) => ({
    terms:
        state.search.results &&
        state.search.results.extractedTerms &&
        state.search.results.extractedTerms.items,
    storageSourceValueToTitleMap: getStorageSourceValueToTitleMap(state),
});

export default connect(mapStateToProps)(Definitions);
