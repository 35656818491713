import * as React from 'react';
import { connect } from 'react-redux';

import LoadingScreen from 'app/components/common/utils/LoadingScreen';
import * as Store from 'app/redux/store/StoreNamespace';

export const withLoadingScreen = (
    WrappedComponent
): React.FunctionComponent<any> =>
    function ({ isLoading, ...props }) {
        return isLoading ? <LoadingScreen /> : <WrappedComponent {...props} />;
    };

export const withSearchTabsLoadingScreen = (
    WrappedComponent: React.ComponentType
) => {
    const mapStateToProps = (state: Store.IState) => {
        const searchTabsLoaded = Object.keys(state.searchTabs).length > 0;

        return {
            isLoading: !searchTabsLoaded,
        };
    };

    return connect(mapStateToProps)(withLoadingScreen(WrappedComponent));
};
