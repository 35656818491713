import React, { forwardRef } from 'react';
import classNames from 'classnames';

import * as styles from './DocumentSearchTabsPanel.scss';

interface ITabProps {
    name: string;
    isActive?: boolean;
    hiddenInDropdown?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    style?: React.CSSProperties;
    title?: string;
    isWideTab?: boolean;
}

export const Tab = forwardRef<HTMLButtonElement, ITabProps>(
    (
        { name, isActive, hiddenInDropdown, onClick, style, title, isWideTab },
        ref
    ) => {
        return (
            <button
                style={style}
                ref={ref}
                className={classNames(
                    'btn',
                    hiddenInDropdown
                        ? styles.documentSearchTabsPanelShowMoreListItem
                        : styles.documentSearchTabsPanelTab,
                    { [styles.documentSearchTabsPanelTabActive]: isActive },
                    { [styles.documentSearchTabsPanelWideTab]: isWideTab }
                )}
                onClick={onClick}
                title={title}
            >
                {name}
            </button>
        );
    }
);
