import React, { useMemo, useCallback, useState } from 'react';
import classNames from 'classnames';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';

import Collapse from 'app/components/common/utils/Collapse';
import Pagination from 'app/components/common/tables/Pagination';
import { getCurrentPage } from 'app/utils/getCurrentPage';
import { DEFAULT_PAGING_ITEMS_LIMIT } from 'app/utils/constants';
import * as Store from 'app/redux/store/StoreNamespace';
import clientRoutes from 'app/routing/clientRoutes';

import * as styles from './CardTable.scss';

const LoadingComponent = () => {
    return null;
};

const TrComponent = ({ className, children, row }) => {
    return !row ? (
        <div className={classNames('rt-tr', className)}>{children}</div>
    ) : (
        <Link
            to={clientRoutes.objectCard.getUrl({ id: row._original.docId })}
            className={classNames('rt-tr', className)}
            target="_blank"
        >
            {children}
        </Link>
    );
};

interface ICardTableProps {
    data: any;
    columns: any;
    tableName: 'links' | 'grouped';
    totalCount: number;
    paging: Store.IPaging;
    title: string;
    sortable: boolean;
    defaultSort?: Store.IObjectCardTableSorting;
    onChangeSkip(tableName: string, skip: number): void;
    onChangeLimit(tableName: string, limit: number): void;
    onChangeSorting?(
        tableName: string,
        field: string | string[],
        order: string
    ): void;
}

const CardTable: React.FC<ICardTableProps> = (props) => {
    const {
        paging,
        data,
        onChangeSkip,
        onChangeLimit,
        columns,
        totalCount,
        title,
        tableName,
        onChangeSorting,
        sortable,
        defaultSort,
    } = props;

    const [isOpened, setIsOpened] = useState(true);

    const getTrProps = useCallback((_, { row }) => ({ row }), []);

    const currentPage = paging ? getCurrentPage(paging) : 1;

    const changePage = useCallback(
        (page: number) => {
            paging && onChangeSkip(tableName, (page - 1) * paging.limit);
        },
        [onChangeSkip, paging && paging.limit, tableName]
    );

    const changeLimit = useCallback(
        (limit: number) => {
            onChangeLimit(tableName, limit);
        },
        [onChangeLimit, tableName]
    );

    const changeSorting = useCallback(
        (sorting, column) => {
            column.sortingFieldName &&
                onChangeSorting?.(
                    tableName,
                    column.sortingFieldName,
                    sorting[0].desc ? 'desc' : 'asc'
                );
        },
        [onChangeSorting, tableName]
    );

    const _defaultSort = useMemo(
        () => {
            const [sortField, sortOrder] = defaultSort
                ? Object.entries(defaultSort)[0]
                : [];
            if (!sortField) {
                return undefined;
            }
            return [
                {
                    id: columns.find(
                        ({ sortingFieldName }) => sortField === sortingFieldName
                    )?.accessor,
                    desc: sortOrder === 'desc',
                },
            ];
        },
        [] // не обновляем, потому что не надо обновлять дефолтную сортировку
    );

    return (
        <div className={styles.cardTable}>
            <div
                onClick={() => setIsOpened((s) => !s)}
                className={styles.cardTableTitle}
            >
                {title}
            </div>
            <Collapse isOpen={isOpened}>
                <ReactTable
                    loading={false}
                    showPagination={false}
                    sortable={sortable}
                    multiSort={false}
                    defaultSorted={_defaultSort}
                    columns={columns}
                    data={data}
                    minRows={0}
                    manual
                    LoadingComponent={LoadingComponent}
                    TrComponent={TrComponent}
                    getTrProps={getTrProps}
                    onSortedChange={changeSorting}
                />
                {totalCount > DEFAULT_PAGING_ITEMS_LIMIT && (
                    <Pagination
                        current={currentPage}
                        count={totalCount || 1}
                        limit={paging ? paging.limit : 10}
                        onPageChange={changePage}
                        onLimitChange={changeLimit}
                    />
                )}
            </Collapse>
        </div>
    );
};

export default CardTable;
