import * as React from 'react';

import {
    FeatureRenderer,
    UIFeaturesName,
} from 'app/components/common/utils/FeatureRenderer';
import clientRoutes from 'app/routing/clientRoutes';
import './Footer.scss';
import { ModalName } from 'app/redux/store/StoreNamespace';
import { MOBILE_NEWS_LINK } from 'app/utils/constants';
import { useActions } from 'app/hooks/useActions';

import { MobileNewsIcon } from '../svg-components/MobileNewsIcon';

const Footer: React.FC = () => {
    const isDVA = process.env.INTERFACE_TYPE === 'dva';

    const { setModalVisible } = useActions();

    const setFeedbackModalVisible = () => {
        setModalVisible(ModalName.Report, true);
    };

    return (
        <FeatureRenderer featureName={UIFeaturesName.FooterContent}>
            <footer className="footer">
                <div className="footer__content clearfix">
                    <div>
                        <div
                            className="footer__icon"
                            title="Корпоративный поиск в Мобильной ленте"
                        >
                            <a
                                href={MOBILE_NEWS_LINK}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <MobileNewsIcon />
                            </a>
                        </div>
                        <div className="footer__text">
                            <span className="footer__pipe">|</span>
                            <a
                                className="footer__button footer__link"
                                href={`${
                                    isDVA
                                        ? 'https://search.gazprom-neft.local'
                                        : ''
                                }${clientRoutes.documentation.getUrl()}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Документация
                            </a>
                        </div>
                    </div>
                    <div className="footer__text">
                        <button
                            type="button"
                            className="btn footer__button footer__link btn_feedback"
                            onClick={setFeedbackModalVisible}
                        >
                            Обратная связь
                        </button>
                        <span className="footer__pipe">|</span>
                        <a
                            className="footer__link footer__button"
                            href="mailto:search@gazprom-neft.ru"
                        >
                            search@gazprom-neft.ru
                        </a>
                    </div>
                </div>
            </footer>
        </FeatureRenderer>
    );
};

export default Footer;
