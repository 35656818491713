/**
 * Created by lkarmelo on 16.05.2019.
 */

import { ActionMeta } from 'redux-actions';
import { AnyAction } from 'redux';

import { IgnoreSearchActionMeta } from 'app/redux/epics/operators/ignoreSearchActionsByMeta';

type ActionWithIgnoreSearchMeta<P, M> = ActionMeta<
    P,
    M & IgnoreSearchActionMeta
>;

export const withIgnoreSearchTriggerMeta = <P, M>(
    action: AnyAction
): AnyAction | ActionWithIgnoreSearchMeta<P, M> => {
    if (
        (action.meta && typeof action.meta !== 'object') ||
        Array.isArray(action.meta)
    ) {
        console.error(
            `Can't add ignoreSearchTrigger to meta. Conflicting meta property on action ${action.type}`
        );
        return action;
    }
    const nextAction: ActionWithIgnoreSearchMeta<P, M> = <
        ActionWithIgnoreSearchMeta<P, M>
    >{ ...action };
    if (!nextAction.meta) {
        nextAction.meta = <M & IgnoreSearchActionMeta>{};
    }
    nextAction.meta.ignoreSearchTrigger = true;
    return nextAction;
};
