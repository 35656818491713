import stateToSearchRequest from 'app/redux/selectors/api/search';
import { SearchDocumentName } from 'app/redux/actions/interfaces/DocumentEnum';
import { THESAURUS_SPECIAL_SYMBOL } from 'app/utils/constants';
import * as Store from 'app/redux/store/StoreNamespace';
import Api from 'app/api/Api';

const getRequestQueryWithFilters = (
    originalQuery: string,
    filters: Store.IFilters
): string => {
    if (
        filters[Store.StaticFilterName.Thesaurus] &&
        filters[Store.StaticFilterName.Thesaurus].value
    ) {
        return `${THESAURUS_SPECIAL_SYMBOL}${originalQuery}`;
    }

    return originalQuery;
};

export const getRequestQuery = (
    contextState: Store.ISearch,
    isNewSearch: boolean
) => {
    return isNewSearch
        ? contextState.searchQuery
        : contextState.executingSearchQuery !== null
        ? contextState.executingSearchQuery
        : contextState.lastExecutedSearchQuery;
};

export const getRequestBody = (
    state: Store.IState,
    contextState: Store.ISearch,
    originalQuery: string
) => {
    const searchRequestPayload: Omit<Api.IGlobalSearchRequestBody, 'query'> =
        stateToSearchRequest({
            state,
            actionState: contextState,
            searchDocumentName: SearchDocumentName.search,
        });
    const query = getRequestQueryWithFilters(
        originalQuery,
        contextState.filters
    );

    return { ...searchRequestPayload, query };
};
