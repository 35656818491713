/**
 * Created by lkarmelo on 20.03.2019.
 */

import * as Store from 'app/redux/store/StoreNamespace';

import { isNumber } from '../isNumber';

const queryValueToMultiSelect = (queryValue: any): string[] => {
    if (Array.isArray(queryValue)) {
        return queryValue;
    }
    if (typeof queryValue === 'string') {
        return [queryValue];
    }
    return [JSON.stringify(queryValue)];
};

const queryValueToSelect = (queryValue: any): string => {
    if (typeof queryValue === 'string') {
        return queryValue;
    }
    if (Array.isArray(queryValue)) {
        return queryValue.length > 0 ? queryValue[0] : '';
    }
    return JSON.stringify(queryValue);
};

const queryValueToDate = (queryValue: any): Store.IDateFilterValue => {
    if (
        typeof queryValue === 'object' &&
        typeof queryValue.from === 'string' &&
        typeof queryValue.to === 'string'
    ) {
        return {
            from: isNumber ? +queryValue.from : undefined,
            to: isNumber ? +queryValue.to : undefined,
        };
    }
    return {};
};

const queryValueToCheckbox = (queryValue: any): boolean =>
    queryValue === 'true';

export const queryValueToFilterValue = (
    queryValue: any,
    filterType: Store.FilterType
): Store.IFilterValue => {
    switch (filterType) {
        case Store.FilterType.Tags:
        case Store.FilterType.MultiSelect: {
            return queryValueToMultiSelect(queryValue);
        }
        case Store.FilterType.Select: {
            return queryValueToSelect(queryValue);
        }
        case Store.FilterType.DateRange:
        case Store.FilterType.YearRange: {
            return queryValueToDate(queryValue);
        }
        case Store.FilterType.CheckBox: {
            return queryValueToCheckbox(queryValue);
        }
        default:
            return queryValue;
    }
};
