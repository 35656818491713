/**
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 18.01.2018
 * -----
 * Last Modified: 26.06.2018 11:51:28
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import { filtersReducer } from './filters';
import paging from './searchPaging';
import results from './documentResults';
import sorting from './sorting';

import { contextMixinReducers } from '../../context/reducer';

export default contextMixinReducers(
    {
        filters: filtersReducer,
        sorting,
    },
    {
        results,
        paging,
        filters: (state = {}) => state,
        sorting: (state = {}) => state,
    }
);
