/**
 * Created by Lkarmelo on 12.02.2018.
 */

import { createAction } from 'redux-actions';

import * as Store from '../store/StoreNamespace';

export const FETCH_SKILLS = 'FETCH_SKILLS';
export const fetchSkills = createAction(FETCH_SKILLS);

export const SET_SKILLS = 'SET_SKILLS';
export const setSkills = createAction<Store.IOrganizationsInfo>(SET_SKILLS);

export const CREATE_SKILL = 'CREATE_SKILL';
export const createSkill = createAction<string>(CREATE_SKILL);

export const ADD_SKILL = 'ADD_SKILL';
export const addSkill = createAction<Store.ISimpleCatalogItem>(ADD_SKILL);
