/**
 * Created by Lkarmelo on 21.02.2018.
 */

import React, { PureComponent } from 'react';
import { findDOMNode } from 'react-dom';
import autoBind from 'autobind-decorator';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { NavLink } from 'react-router-dom';
import PerfectScrollbar from 'perfect-scrollbar';

import CollapsibleMenu from 'app/components/common/CollapsibleMenu';
import AddEditCategory from 'app/components/catalogue/AddEditCategory';
import clientRoutes from 'app/routing/clientRoutes';
import { ADD_CATEGORY_FORM_TITLE } from 'app/utils/constants';

import IState from './interfaces/ICategoriesMenuState';
import IProps from './interfaces/ICategoriesMenuProps';
import * as styles from './CategoriesMenu.scss';

import * as Store from '../../../redux/store/StoreNamespace';

@autoBind
export default class CategoriesMenu extends PureComponent<IProps, IState> {
    state: IState = {
        isAddCategoryVisible: false,
    };

    categoriesListRef: React.RefObject<HTMLUListElement> = React.createRef();

    activeLinkBackgroundEl: HTMLDivElement = null;

    prevListScrollLeft: number;

    categoriesListScrollSubscription: Subscription = null;

    resizeSubscription: Subscription = null;

    activeMenuRefSequence: {
        categoryId: string;
        collapsible: CollapsibleMenu;
    }[] = [];

    currentActiveMenuRef: CollapsibleMenu | HTMLLIElement = null;

    scrollAnimationData: {
        rafId?: number;
        stepSize?: number;
        framesTotal?: number;
        framesDone?: number;
    } = {
        // 60 кадров в секунду, пусть анимация выполняется чуть меньше полсекунды
        framesTotal: 20,
    };

    scroll: PerfectScrollbar = null;

    static findActiveElement(): HTMLElement {
        return document.querySelector(
            `.${styles.categoriesMenu} .${styles.categoriesMenuLinkActive}`
        ) as HTMLElement;
    }

    renderMenuItems(id: string, isOpened?: boolean): JSX.Element {
        const { categories, activeCategoriesSequence, active } = this.props;
        const category = categories.categories[id];
        const idPosInActiveSeq = activeCategoriesSequence.indexOf(id);
        const isCategoryActive = idPosInActiveSeq >= 0;

        if (category.subItems.length > 0) {
            return (
                <li className={styles.categoriesMenuListItem} key={id}>
                    <CollapsibleMenu
                        onCollapseChange={
                            this.updateScrollBarOnMenuCollapseToggle
                        }
                        isInitiallyOpened={
                            isOpened ||
                            (isCategoryActive && idPosInActiveSeq !== 0)
                        }
                        ref={(r) => {
                            isCategoryActive &&
                                this.activeMenuRefSequence.push({
                                    categoryId: id,
                                    collapsible: r,
                                });
                            id === active && (this.currentActiveMenuRef = r);
                        }}
                        toggleElement={this.renderCategoryLink(id, category)}
                    >
                        {category.subItems.map((subId) =>
                            this.renderMenuItems(subId)
                        )}
                    </CollapsibleMenu>
                </li>
            );
        }
        return (
            <li
                className={styles.categoriesMenuListItem}
                key={id}
                ref={(r) => {
                    id === active && (this.currentActiveMenuRef = r);
                }}
            >
                {this.renderCategoryLink(id, category)}
            </li>
        );
    }

    renderCategoryLink(
        id: string,
        category: Store.INormalizedHierarchicalCatalogItem
    ): JSX.Element {
        const { active, showNewDocumentsCount } = this.props;
        const url = clientRoutes.catalogue.getUrl({ id });
        return (
            <NavLink
                className={styles.categoriesMenuLink}
                activeClassName={styles.categoriesMenuLinkActive}
                isActive={() => id === active}
                to={url}
                onClick={this.onCategoryLinkClick}
                title={category.item.title}
            >
                <span className={styles.categoriesMenuLinkName}>
                    {category.item.title}
                </span>
                <span className={styles.categoriesMenuLinkCount}>
                    {showNewDocumentsCount &&
                    typeof category.countUnverified === 'number' ? (
                        <>
                            <span className={styles.categoriesMenuLinkNewCount}>
                                {category.countUnverified}
                            </span>{' '}
                            / <span>{category.countCat}</span>
                        </>
                    ) : (
                        category.countCat
                    )}
                </span>
            </NavLink>
        );
    }

    componentDidMount(): void {
        this.categoriesListScrollSubscription = Observable.fromEvent(
            this.categoriesListRef.current,
            'scroll'
        )
            .filter(
                () =>
                    this.categoriesListRef.current.scrollLeft !==
                    this.prevListScrollLeft
            )
            .subscribe(() => {
                const activeLink = CategoriesMenu.findActiveElement();
                this.adjustActiveLinkBackgroundPos(activeLink);
            });

        this.scroll = new PerfectScrollbar(this.categoriesListRef.current);
        this.resizeSubscription = Observable.fromEvent(window, 'resize')
            .debounceTime(50)
            .subscribe(() => this.scroll.update());
    }

    componentDidUpdate(prevProps: IProps): void {
        const { categories, active } = this.props;
        if (prevProps.active !== active) {
            this.openActiveMenus();
        }
        if (
            prevProps.active !== active ||
            (Object.keys(prevProps.categories).length === 0 &&
                Object.keys(categories).length > 0)
        ) {
            const activeLink = CategoriesMenu.findActiveElement();
            this.appendActiveLinkBackground(activeLink);

            // нужно подождать анимацию открытия меню, что правильно считались расстояния
            window.setTimeout(this.scrollToActiveMenu, 420);
        }
    }

    componentWillUnmount(): void {
        this.categoriesListScrollSubscription.unsubscribe();
    }

    adjustActiveLinkBackgroundPos(activeLink: HTMLElement): void {
        if (this.activeLinkBackgroundEl) {
            this.activeLinkBackgroundEl.style.left = `${-activeLink.getBoundingClientRect()
                .left}px`;
        }
    }

    openActiveMenus(): void {
        this.activeMenuRefSequence.forEach(
            (m) =>
                m.collapsible &&
                m.categoryId !== this.props.active &&
                m.collapsible.open()
        );
    }

    scrollToActiveMenu(): void {
        const el = findDOMNode(this.currentActiveMenuRef) as HTMLElement;
        if (!el) {
            return;
        }

        const listBoundRect =
            this.categoriesListRef.current.getBoundingClientRect();
        const elBoundRect = el.getBoundingClientRect();

        if (
            elBoundRect.top > listBoundRect.top &&
            elBoundRect.top < listBoundRect.top + listBoundRect.height
        ) {
            return;
        }

        const targetTopPos =
            elBoundRect.top -
            listBoundRect.top +
            this.categoriesListRef.current.scrollTop -
            listBoundRect.height / 2;
        const diff = targetTopPos - this.categoriesListRef.current.scrollTop;

        this.scrollAnimationData.stepSize =
            diff / this.scrollAnimationData.framesTotal;
        this.scrollAnimationData.framesDone = 0;
        this.scrollAnimationData.rafId = window.requestAnimationFrame(
            this.scrollStep
        );
    }

    scrollStep(): void {
        const catList = this.categoriesListRef.current;
        const { stepSize, rafId, framesDone, framesTotal } =
            this.scrollAnimationData;
        // считаем по количеству кадров, чтобы анимация не проскочила нужное место, вместо определения позиции на экране
        if (
            framesDone === framesTotal ||
            catList.scrollTop + stepSize < 0 ||
            catList.scrollTop + stepSize > catList.scrollHeight
        ) {
            window.cancelAnimationFrame(rafId);
        } else {
            catList.scrollTop += stepSize;
            this.scrollAnimationData.framesDone++;
            this.scrollAnimationData.rafId = window.requestAnimationFrame(
                this.scrollStep
            );
        }
    }

    updateScrollBarOnMenuCollapseToggle(): void {
        // нужно подождать анимацию открытия меню
        window.setTimeout(() => {
            this.scroll.update();
        }, 420);
    }

    onCategoryLinkClick(e: React.MouseEvent<HTMLAnchorElement>): void {
        e.stopPropagation();
        this.appendActiveLinkBackground(e.currentTarget);
    }

    appendActiveLinkBackground(activeLink: HTMLElement): void {
        if (!activeLink) {
            return;
        }
        if (!this.activeLinkBackgroundEl) {
            this.activeLinkBackgroundEl = document.createElement('div');
            this.activeLinkBackgroundEl.className =
                styles.categoriesMenuLinkBackground;
        }
        if (this.activeLinkBackgroundEl.parentElement) {
            this.activeLinkBackgroundEl.parentElement.removeChild(
                this.activeLinkBackgroundEl
            );
        }
        const targetParent: HTMLElement = activeLink.parentElement;
        targetParent.style.position = 'relative';
        this.adjustActiveLinkBackgroundPos(activeLink);
        targetParent.appendChild(this.activeLinkBackgroundEl);
    }

    showAddCategoryPanel(): void {
        this.setState({ isAddCategoryVisible: true });
    }

    closeAddCategoryPanel(): void {
        this.setState({ isAddCategoryVisible: false });
    }

    render(): JSX.Element {
        const { showAddCategory, categories, categoriesAsOptions } = this.props;
        const { topCategories } = categories;
        this.activeMenuRefSequence = [];

        return (
            <div className={styles.categoriesMenu}>
                <div className={styles.categoriesMenuHeader}>
                    <div className={styles.categoriesMenuTitle}>Каталог</div>
                    {showAddCategory && (
                        <button
                            className={`btn ${styles.categoriesMenuAddCategoryBtn}`}
                            onClick={this.showAddCategoryPanel}
                            title="Создать категорию"
                        >
                            <span />
                        </button>
                    )}
                </div>
                <ul
                    ref={this.categoriesListRef}
                    className={styles.categoriesMenuList}
                >
                    {topCategories &&
                        topCategories.map((id) =>
                            this.renderMenuItems(id, true)
                        )}
                </ul>
                {showAddCategory && (
                    <AddEditCategory
                        formTitle={ADD_CATEGORY_FORM_TITLE}
                        isVisible={this.state.isAddCategoryVisible}
                        categories={categories}
                        categoriesAsOptions={categoriesAsOptions}
                        close={this.closeAddCategoryPanel}
                    />
                )}
            </div>
        );
    }
}
