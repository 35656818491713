import { createAction } from 'redux-actions';

import Api from 'app/api/Api';

export interface ISetSearchActiveTabPayload {
    tabCode: string | null;
    preserveIgnoreMistakesFromLastSearch?: boolean;
    preservePersonStrict?: boolean;
    isNewSearch?: boolean;
    /**
     * по умолчанию true
     */
    setPersonalization?: boolean;
}
export interface IFetchSingleSearchTabFullPayload {
    tabCode: string;
    setPersonalization: boolean;
}

export interface ISetSingleSearchTabMetaPayload {
    tab: Api.ISearchTab;
    setPersonalization: boolean;
}

export const fetchSearchTabs = createAction('LOAD_SEARCH_TABS_META');

export const fetchSearchTabsSimple = createAction(
    'LOAD_SEARCH_TABS_META_SIMPLE'
);

export const fetchSingleSearchTabFull =
    createAction<IFetchSingleSearchTabFullPayload>('LOAD_SINGLE_FULL_TAB_META');

export const setSearchTabsMeta = createAction<Api.ISearchTabSimple[]>(
    'SET_SEARCH_TABS_META'
);

export const setSingleSearchTabMeta =
    createAction<ISetSingleSearchTabMetaPayload>('SET_SINGLE_TAB_META');

export const setSearchActiveTab = createAction<ISetSearchActiveTabPayload>(
    'SET_SEARCH_ACTIVE_TAB'
);

export const setSearchActiveTabForResults = createAction<string | null>(
    'SET_SEARCH_ACTIVE_TAB_FOR_RESULTS'
);
