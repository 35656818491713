/**
 * Created by lkarmelo on 11.12.2019.
 */

import { connect } from 'react-redux';

import {
    fetchNotificationListAndCount,
    requestNotificationsRead,
} from 'app/redux/actions/notification';
import * as Store from 'app/redux/store/StoreNamespace';

import {
    INotificationsStateProps,
    INotificationsActionProps,
} from './interfaces/INotificationsProps';
import Notifications from './Notifications';

const mapStateToProps = (state: Store.IState): INotificationsStateProps => ({
    notifications: state[Store.NotificationContext].notifications,
    total: state[Store.NotificationContext].total,
});

const mapDispatchToProps = (dispatch): INotificationsActionProps => ({
    markAsRead(id: string[]): void {
        dispatch(requestNotificationsRead(id));
    },
    loadNotifications(limit: number): void {
        dispatch(fetchNotificationListAndCount(limit));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
