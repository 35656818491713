/**
 * Created by lkarmelo on 28.06.2019.
 */

import * as React from 'react';

const eventsSubscriptions: { [eventName: string]: Function[] } = {};

// используется для апдейтов view между компонентами реакта, которые не связаны между собой
// Если можно использовать для этого redux, лучше использовать его
export const pubSubImpl = {
    subscribe(eventName: string, cb: Function) {
        !eventsSubscriptions[eventName] &&
            (eventsSubscriptions[eventName] = []);
        eventsSubscriptions[eventName].push(cb);
    },
    unSubscribe(eventName: string, cb: Function) {
        if (!eventsSubscriptions[eventName]) {
            return;
        }
        const subscriptionIndex = eventsSubscriptions[eventName].indexOf(cb);
        if (subscriptionIndex < 0) {
            return;
        }
        eventsSubscriptions[eventName].splice(subscriptionIndex, 1);
    },
    emitEvent(eventName: string, ...rest: any) {
        if (!eventsSubscriptions[eventName]) {
            return;
        }
        eventsSubscriptions[eventName].forEach((sub) => sub(...rest));
    },
};

export const PubSubContext = React.createContext(pubSubImpl);
