import EducationMaterialStatusFilter from 'app/components/account/DocumentPanels/Education/EducationMaterialStatusFilter';
import connectWithContext from 'app/redux/context/connectWithContext';
import {
    fetchNumberOfEducationMaterials,
    setStatusFilter,
} from 'app/redux/actions/education';
import { fetchSearchResults } from 'app/redux/actions/search/results';
import {
    IEducationMaterialStatusFilterDispatchProps as IDispatchProps,
    IEducationMaterialStatusFilterStateProps as IStateProps,
} from 'app/components/account/DocumentPanels/Education/interfaces/IEducationMaterialStatusFilterProps';
import * as Store from 'app/redux/store/StoreNamespace';
import { SearchDocumentName } from 'app/redux/actions/interfaces/DocumentEnum';

const mapStateToProps = (state): IStateProps => {
    const educationState = state[
        SearchDocumentName.education
    ] as Store.IEducationMaterials;

    return {
        rootContext: SearchDocumentName.education,
        status: educationState.statusFilter,
        numberOfAll: educationState.numberOfMaterials.numberOfAll,
        numberOfRecommended:
            educationState.numberOfMaterials.numberOfRecommended,
        numberOfNecessaryRecommended:
            educationState.numberOfMaterials.numberOfMandatoryRecommended,
        numberOfPlanning: educationState.numberOfMaterials.numberOfPlanning,
        numberOfActive: educationState.numberOfMaterials.numberOfActive,
        numberOfDone: educationState.numberOfMaterials.numberOfDone,
    };
};

const mapDispatchToProps = (dispatch): IDispatchProps => ({
    onMount() {
        dispatch(fetchNumberOfEducationMaterials());
    },
    onFilterSelect(val: Store.EducationMaterialStatusValue) {
        dispatch(setStatusFilter(val));
        dispatch(fetchSearchResults());
    },
});

export default connectWithContext(
    mapStateToProps,
    mapDispatchToProps
)(EducationMaterialStatusFilter);
