/**
 * Created by Lkarmelo on 14.03.2018.
 */

import { SET_AUTHORIZED } from 'app/redux/actions/authorization';

export const retryOnAuthorizedEpic = (
    action$,
    typeOrTypes,
    fetchActionCreator
) => {
    const triggers = Array.isArray(typeOrTypes) ? typeOrTypes : [typeOrTypes];

    return action$
        .ofType(...triggers)
        .filter(
            (action) =>
                action.payload &&
                action.payload.status &&
                action.payload.status === 401
        )
        .switchMap(() => {
            return action$
                .ofType(SET_AUTHORIZED)
                .filter((action) => {
                    return action.payload;
                })
                .take(1);
        })
        .mapTo(fetchActionCreator());
};
