import { ajax } from 'rxjs/observable/dom/ajax';
import { Subscriber } from 'rxjs/Subscriber';

import { IUpdateGuide } from 'app/types/tour';
/**
 * Created by Lkarmelo on 05.09.2017
 * -----
 * Last Modified: 29.06.2018 11:08:28
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import { ParamMap } from 'app/routing/Route/Route';
import * as Store from 'app/redux/store/StoreNamespace';
import { SearchDocumentName } from 'app/redux/actions/interfaces/DocumentEnum';

import Api from './Api';
import { FormName } from './FormName';

import apiRoutes from '../routing/apiRoutes';

const headersJson = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
};

const headersUrlEncoded = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
};

const headersOctetStream = {
    'Content-Type': 'image/jpeg',
    Accept: 'application/octet-stream',
};

export default {
    search: (payload: Api.IGlobalSearchRequestBody) =>
        ajax.post(apiRoutes.globalSearch.getUrl(), payload || {}, headersJson),
    personSearch: (payload: Api.IGlobalSearchRequestBody) =>
        ajax.post(apiRoutes.personSearch.getUrl(), payload, headersJson),

    getAvatar: (userId: string, resolution: string, needCache = false) =>
        ajax({
            url: apiRoutes.getUserAvatar.getUrl({
                userId,
                resolution,
                needCache,
            }),
            method: 'GET',
            headers: headersOctetStream,
            responseType: 'blob',
        }),

    getSystemAvatar: (userId: string, resolution: string) =>
        ajax({
            url: apiRoutes.getSystemUserAvatar.getUrl({ userId, resolution }),
            method: 'GET',
            headers: headersOctetStream,
            responseType: 'blob',
        }),

    queryExpansion: (query) =>
        ajax.get(apiRoutes.queryExpansion.getUrl({ query }), headersJson),

    objectCard: ({
        id,
        query = '',
        expansion = [],
    }: {
        id: string;
        query?: string;
        expansion?: string[];
    }) =>
        ajax.post(
            apiRoutes.objectCard.getUrl({ id }),
            { query, expansion },
            headersJson
        ),
    objectCardLinked: (id: string, body: Api.ILinkedRequestBody) =>
        ajax.post(apiRoutes.objectCardLinked.getUrl({ id }), body, headersJson),
    [SearchDocumentName.objectCardSimilar]: (
        id: string,
        skip: number,
        limit: number
    ) =>
        ajax.get(
            apiRoutes.objectCardSimilar.getUrl({ id, skip, limit }),
            headersJson
        ),

    filters: () => ajax.get(apiRoutes.filtersMeta.getUrl(), headersJson),

    favoritesFilters: () =>
        ajax.get(apiRoutes.favoritesFiltersMeta.getUrl(), headersJson),
    params: () => ajax.get(apiRoutes.paramsMeta.getUrl(), headersJson),
    initialFilterStats: () =>
        ajax.post(
            apiRoutes.documentSearch.getUrl(),
            { query: '', limit: 0, skip: 0 },
            headersJson
        ),
    // TODO: убрать захардкоженный лимит, если нужно
    searchHints: (
        query: string,
        searchTab: string,
        suggestionTypes?: Api.SuggestionType[],
        isDocTypeTab = false
    ) =>
        ajax.post(
            apiRoutes.searchHints.getUrl(),
            {
                query,
                limit: isDocTypeTab ? 20 : 10,
                suggestionTypes,
                searchTab,
            },
            headersJson
        ),

    searchTabs: () => ajax.get(apiRoutes.tabsMeta.getUrl(), headersJson),
    searchTabsSimple: () =>
        ajax.get(apiRoutes.tabsMetaSimple.getUrl(), headersJson),
    searchTab: (tabCode: string) =>
        ajax.get(apiRoutes.tab.getUrl({ tabCode }), headersJson),

    compressSearchRequest: (
        searchRequest: Api.IGlobalSearchRequestBody,
        meta: Api.ISearchAliasMeta = {}
    ) =>
        ajax.post(
            apiRoutes.compressSearchRequest.getUrl(),
            { searchRequest, meta },
            headersJson
        ),
    searchRequestFromAlias: (alias: string) =>
        ajax.get(
            apiRoutes.searchRequestFromAlias.getUrl({ alias }),
            headersJson
        ),

    forgotPassword: (login) =>
        ajax.post(
            apiRoutes.forgotPassword.getUrl({
                location: window.location.origin,
            }),
            JSON.stringify({ email: login }),
            headersJson
        ),

    resetPassword: (token, password) =>
        ajax.post(
            apiRoutes.resetPassword.getUrl({ token }),
            JSON.stringify({ password1: password, password2: password }),
            headersJson
        ),

    logIn: (identifier, password, rememberMe) =>
        ajax.post(
            apiRoutes.logIn.getUrl(),
            JSON.stringify({ identifier, password, rememberMe }),
            headersJson
        ),
    logOut: () => ajax.get(apiRoutes.logOut.getUrl(), headersJson),

    [SearchDocumentName.related]: (
        id,
        payload: Api.IDocumentSearchRequestBody
    ) =>
        ajax.post(
            apiRoutes.relatedDocuments.getUrl({ id }),
            payload || {},
            headersJson
        ),
    [SearchDocumentName.favorites]: (payload: Api.IDocumentSearchRequestBody) =>
        ajax.post(apiRoutes.favorites.getUrl(), payload || {}, headersJson),
    [SearchDocumentName.readLater]: (payload: Api.IDocumentSearchRequestBody) =>
        ajax.post(apiRoutes.readLater.getUrl(), payload || {}, headersJson),
    [SearchDocumentName.subscribes]: (
        payload: Api.IDocumentSearchRequestBody
    ) => ajax.post(apiRoutes.subscribes.getUrl(), payload || {}, headersJson),

    searchByCategory: (categoryCode, payload, newDocuments) =>
        ajax.post(
            // undefined, чтобы не передавать false на бекенд
            apiRoutes.searchByCategory.getUrl({
                categoryCode,
                new: newDocuments || undefined,
            }),
            payload || {},
            headersJson
        ),

    markFavorites: (documentId) =>
        ajax.post(apiRoutes.markFavorite.getUrl(), { documentId }, headersJson),
    unmarkFavorites: (documentId) =>
        ajax.delete(
            apiRoutes.unmarkFavorite.getUrl({ documentId }),
            headersJson
        ),
    markReadLater: (documentId) =>
        ajax.post(
            apiRoutes.markReadLater.getUrl(),
            { documentId },
            headersJson
        ),
    unmarkReadLater: (documentId) =>
        ajax.delete(
            apiRoutes.unmarkReadLater.getUrl({ documentId }),
            headersJson
        ),
    markSubscribes: (documentId) =>
        ajax.post(
            apiRoutes.markSubscribes.getUrl(),
            { documentId },
            headersJson
        ),
    unmarkSubscribes: (documentId) =>
        ajax.delete(
            apiRoutes.unmarkSubscribes.getUrl({ documentId }),
            headersJson
        ),

    userData: () => ajax.get(apiRoutes.userData.getUrl(), headersJson),
    userPermissions: () =>
        ajax.get(apiRoutes.userPermissions.getUrl(), headersJson),

    currentUserData: (id) =>
        ajax.get(apiRoutes.currentUserData.getUrl({ id }), headersJson),
    currentUserWorkInfo: (id) =>
        ajax.get(apiRoutes.currentUserWorkInfo.getUrl({ id }), headersJson),
    currentUserCompetencies: (id) =>
        ajax.get(apiRoutes.currentUserCompetencies.getUrl({ id }), headersJson),
    currentUserCategories: (id) =>
        ajax.get(apiRoutes.currentUserCategories.getUrl({ id }), headersJson),
    currentUserSkills: (id) =>
        ajax.get(apiRoutes.currentUserSkills.getUrl({ id }), headersJson),

    userNotificationCount: () =>
        ajax.get(apiRoutes.userNotificationCount.getUrl(), headersJson),

    userNotification: (limit?: number) =>
        ajax.get(apiRoutes.userNotification.getUrl({ limit }), headersJson),

    userGuideStats: () =>
        ajax.get(apiRoutes.userGuideStats.getUrl(), headersJson),
    userGuideSpecificStats: (version: string) =>
        ajax.get(
            apiRoutes.userGuideSpecificStats.getUrl({ version }),
            headersJson
        ),
    userGuideSpecificUpdateStats: (version: string, body?: IUpdateGuide) =>
        ajax.put(
            apiRoutes.userGuideSpecificUpdateStats.getUrl({ version }),
            body,
            headersJson
        ),

    markNotificationReaded: (messageIds) =>
        ajax.get(
            apiRoutes.markNotificationReaded.getUrl({}, { messageIds }),
            headersJson
        ),
    markNotificationUnreaded: (messageIds) =>
        ajax.get(
            apiRoutes.markNotificationUnreaded.getUrl({}, { messageIds }),
            headersJson
        ),

    competencies: () => ajax.get(apiRoutes.competencies.getUrl(), headersJson),

    organizations: () =>
        ajax.get(apiRoutes.organizations.getUrl(), headersJson),
    addresses: () => ajax.get(apiRoutes.addresses.getUrl(), headersJson),
    posts: () => ajax.get(apiRoutes.posts.getUrl(), headersJson),
    subdivisions: () => ajax.get(apiRoutes.subdivisions.getUrl(), headersJson),

    skills: () => ajax.get(apiRoutes.skills.getUrl(), headersJson),
    createSkill: (title: string) =>
        ajax.post(apiRoutes.createSkill.getUrl(), `"${title}"`, headersJson),

    catalogueCategories: () =>
        ajax.get(apiRoutes.catalogueCategories.getUrl(), headersJson),
    catalogueCategory: (code: string) =>
        ajax.get(apiRoutes.catalogueCategory.getUrl({ code }), headersJson),
    catalogueCategorizationRules: (categoryCode: string) =>
        ajax.get(
            apiRoutes.categorizationRules.getUrl({ categoryCode }),
            headersJson
        ),
    deleteCatalogueCategorizationRule: (
        categoryCode: string,
        rulesIds: string[]
    ) =>
        ajax.delete(
            apiRoutes.deleteCategorizationRules.getUrl({
                categoryCode,
                ruleId: rulesIds,
            }),
            headersJson
        ),

    [SearchDocumentName.documentManagementAll]: (
        payload: Api.IDocumentSearchRequestBody
    ) =>
        ajax.post(
            apiRoutes.educationMaterials.getUrl(),
            payload || {},
            headersJson
        ),
    [SearchDocumentName.documentManagementOwn]: (
        payload: Api.IDocumentSearchRequestBody
    ) =>
        ajax.post(
            apiRoutes.ownEducationMaterials.getUrl(),
            payload || {},
            headersJson
        ),

    educationMaterialTypes: () =>
        ajax.get(apiRoutes.educationMaterialTypes.getUrl(), headersJson),
    numberOfEducationMaterials: () =>
        ajax.get(apiRoutes.numberOfEducationMaterials.getUrl(), headersJson),

    [SearchDocumentName.education]: (
        payload: Api.IDocumentSearchRequestBody,
        recommendationsOnly?: boolean,
        documentStatus?: string
    ) =>
        ajax.post(
            apiRoutes.educationMaterialsByStatus.getUrl({
                recommendationsOnly: recommendationsOnly
                    ? recommendationsOnly.toString()
                    : undefined,
                documentStatus,
            }),
            payload,
            headersJson
        ),

    orgStructureWithEmployees: () =>
        ajax.get(apiRoutes.orgStructureWithEmployees.getUrl(), headersJson),

    changeEducationMaterialStatus: (
        documentId: string,
        payload: Api.IChangeEducationMaterialStatus
    ) =>
        ajax.post(
            apiRoutes.changeEducationMaterialStatus.getUrl({ documentId }),
            payload,
            headersJson
        ),
    resetEducationMaterialStatus: (documentId: string) =>
        ajax.delete(
            apiRoutes.resetEducationMaterialStatus.getUrl({ documentId }),
            headersJson
        ),

    recommendations: (params: Api.IRecommendationsRequestParameters) =>
        ajax.get(
            apiRoutes.recommendations.getUrl({}, <ParamMap>params),
            headersJson
        ),

    userIdByLogin: ({
        login,
        source: sourceId,
    }: {
        login: string;
        source: string;
    }) =>
        ajax.get(
            apiRoutes.userIdByLogin.getUrl({ login, sourceId }),
            headersJson
        ),

    documentOriginalLink: (documentId: string) =>
        ajax.get(
            apiRoutes.documentOriginalLink.getUrl({ id: documentId }),
            headersJson
        ),

    documentConfirmCategory: ({ docId, categoryId }) =>
        ajax.post(
            apiRoutes.documentVerifyCategory.getUrl({
                docId,
                categoryCode: categoryId,
            }),
            {},
            headersJson
        ),
    documentRevertCategory: ({ docId, categoryId }) =>
        ajax.delete(
            apiRoutes.documentDeclineCategory.getUrl({
                docId,
                categoryCode: categoryId,
            }),
            headersJson
        ),

    broaderConcepts: (conceptUri: string, schemeUri: string) =>
        ajax.post(
            apiRoutes.broaderConcepts.getUrl(),
            { conceptSchemes: [schemeUri], resources: [conceptUri] },
            headersJson
        ),
    narrowerConcepts: (conceptUri: string, schemeUri: string) =>
        ajax.post(
            apiRoutes.narrowerConcepts.getUrl(),
            { conceptSchemes: [schemeUri], resources: [conceptUri] },
            headersJson
        ),
    resourceView: (conceptUri: string, schemeUri: string) =>
        ajax.post(
            apiRoutes.skosResourceView.getUrl(),
            { conceptSchemes: [schemeUri], resources: [conceptUri] },
            headersJson
        ),

    forms: {
        [FormName.PersonalInfo]: (
            profileId: string,
            data: Api.FormBody[FormName.PersonalInfo]
        ) =>
            ajax.post(
                apiRoutes.updatePersonalInfo.getUrl({ profileId }),
                { ...data },
                headersJson
            ),
        [FormName.UserCompetencies]: (profileId, data) =>
            ajax.post(
                apiRoutes.updateUserCompetencies.getUrl({ profileId }),
                data,
                headersJson
            ),
        [FormName.WorkInfo]: (profileId, employeeId, data) =>
            ajax.post(
                apiRoutes.updateUserWorkInfo.getUrl({ profileId, employeeId }),
                data,
                headersJson
            ),
        [FormName.Skills]: (profileId, data) =>
            ajax.post(
                apiRoutes.updateUserSkills.getUrl({ profileId }),
                data,
                headersJson
            ),
        [FormName.ChangePass]: (data) =>
            ajax.post(apiRoutes.changePassword.getUrl(), data, headersJson),

        [FormName.CatalogueCategoryRules]: ({ categoryCode, rules }) =>
            ajax.post(
                apiRoutes.categorizationCategoryRule.getUrl({ categoryCode }),
                rules,
                headersJson
            ),

        [FormName.AddNewDocument]: (
            data: Api.FormBody[FormName.AddNewDocument]
        ) => ajax.post(apiRoutes.addNewDocument.getUrl(), data, headersJson),
        [FormName.EditDocument]: (
            documentId: string,
            data: Api.FormBody[FormName.AddNewDocument]
        ) =>
            ajax.post(
                apiRoutes.editDocument.getUrl({ documentId }),
                data,
                headersJson
            ),
        [FormName.DeleteDocument]: (documentId: string) =>
            ajax.delete(
                apiRoutes.editDocument.getUrl({ documentId }),
                headersUrlEncoded
            ),
        [FormName.RecommendDocument]: (
            isByProfiles: boolean,
            data:
                | Api.FormBody['recommendByProfiles']
                | Api.FormBody['recommendBySubdivisions']
        ) => {
            if (isByProfiles) {
                return ajax.post(
                    apiRoutes.recommendDocumentByProfiles.getUrl(),
                    data,
                    headersJson
                );
            }
            return ajax.post(
                apiRoutes.recommendDocumentBySubdivisions.getUrl(),
                data,
                headersJson
            );
        },
        [FormName.DenyRecommendation]: (documentId: string) =>
            ajax.post(
                apiRoutes.refuseRecommendation.getUrl({ documentId }),
                undefined,
                headersJson
            ),
        [FormName.EditRecommendation]: (
            recommendationId: string,
            forUser: string,
            payload: {}
        ) =>
            ajax.post(
                apiRoutes.editRecommendation.getUrl({
                    id: recommendationId,
                    forUser,
                }),
                payload,
                headersJson
            ),

        [FormName.DeleteRecommendation]: (
            recommendationId: string,
            forUser: string
        ) =>
            ajax.delete(
                apiRoutes.deleteRecommendation.getUrl({
                    id: recommendationId,
                    forUser,
                }),
                headersUrlEncoded
            ),

        [FormName.ErrorReport]: (message = '') =>
            ajax.post(apiRoutes.errorReport.getUrl(), { message }, headersJson),
        [FormName.FeedbackMessage]: ({
            message,
            found,
        }: Api.FormBody[FormName.FeedbackMessage]) =>
            ajax.post(
                apiRoutes.feedbackMessage.getUrl(),
                { message, found },
                headersJson
            ),

        [FormName.CatalogueAddCategory]: (
            values: Api.FormBody[FormName.CatalogueAddCategory]
        ) =>
            ajax.post(
                apiRoutes.catalogueCategory.getUrl({}),
                { ...values },
                headersJson
            ),
        [FormName.CatalogueEditCategory]: (
            code: string,
            values: Api.FormBody[FormName.CatalogueEditCategory]
        ) =>
            ajax.post(
                apiRoutes.catalogueCategory.getUrl({ code }),
                { ...values },
                headersJson
            ),
        [FormName.CatalogueDeleteCategory]: (code: string) =>
            ajax.delete(
                apiRoutes.catalogueCategory.getUrl({ code }),
                headersJson
            ),
        [FormName.CatalogueMoveSubCategories]: ({
            categoryFromId,
            categoryToId,
        }) =>
            ajax.post(
                apiRoutes.moveCategory.getUrl({
                    from: categoryFromId,
                    to: categoryToId,
                }),
                {},
                headersJson
            ),

        [FormName.DocumentChangeCategory]: ({
            docId,
            categoryId,
            newCategoryId,
        }) =>
            ajax.post(
                apiRoutes.documentChangeCategory.getUrl({
                    docId,
                    categoryCode: categoryId,
                    newCategoryCode: newCategoryId,
                }),
                {},
                headersJson
            ),
    },

    uploadFile: (
        path: string,
        files: File[],
        progressSubscriber: Subscriber<any>
    ) => {
        const fd = new FormData();
        fd.append('location', path);
        files.forEach((file, idx) => {
            fd.append(`file${idx}`, file);
        });

        const conf = {
            Accept: 'application/json',
            // cancelToken: CancelCallback.token,
            // onUploadProgress: onProgress
        };

        return ajax({
            url: apiRoutes.uploadFile.getUrl({ path }),
            method: 'POST',
            body: fd,
            async: true,
            headers: conf,
            progressSubscriber,
        });
    },
    processFile: (file: string) =>
        ajax.post(apiRoutes.processFile.getUrl({ fid: file })),
    deleteFile: (file: string) =>
        ajax.delete(apiRoutes.deleteFile.getUrl({ fid: file })),
    downloadFile: (file: string) =>
        ajax.get(apiRoutes.downloadFile.getUrl({ fid: file })),
    listFile: (path?: string) =>
        ajax.get(apiRoutes.listFile.getUrl({ location: path })),

    log: {
        documentSourceViewEvent: (documentId: string) =>
            ajax.post(
                apiRoutes.logMetrics.getUrl(),
                {
                    eventType: 'ObjectView',
                    eventSubType: 'DocumentView',
                    objectId: documentId,
                },
                headersJson
            ),
        userSipClickEvent: (person: Store.IPerson) =>
            ajax.post(apiRoutes.logUserSipClick.getUrl(), person, headersJson),
        userMailToClickEvent: (person: Store.IPerson) =>
            ajax.post(
                apiRoutes.logUserMailToClick.getUrl(),
                person,
                headersJson
            ),
        userDetailsClickEvent: (person: Store.IPerson) =>
            ajax.post(
                apiRoutes.logUserDetailsClick.getUrl(),
                person,
                headersJson
            ),
        employeeTabFromWidgetClickEvent: (person: Store.IPerson | null) =>
            ajax.post(
                apiRoutes.logEmployeeTabFromWidgetClick.getUrl(),
                person,
                headersJson
            ),
    },

    doodle: () => ajax.get(apiRoutes.getDoodle.getUrl(), headersJson),
};
