/**
 * Created by Lkarmelo on 16.11.2017.
 */

import * as React from 'react';
import ReactScrollUp from 'react-scroll-up';

import * as styles from './ScrollToTop.scss';

const ScrollToTop: React.FC<{ showUnder?: number; bottom?: number }> = (
    props
) => (
    <>
        {/*
            в хроме проявляется странный баг: если не двигать мышь, и скроллить страницу вниз то когда заканчивается
            анимация появления кнопки скролла вверх, скролл перестаёт работать, пока не сдвинуть мышь. если убрать
            анимацию, то баг не появляется. если вставить в дом перед дивом другой див с position: fixed,
            баг тоже не проявляется (понятия не имею почему)
        */}
        <div
            style={{
                position: 'fixed',
            }}
        />
        <ReactScrollUp
            showUnder={props.showUnder}
            style={{
                bottom: props.bottom,
                left: 10,
                zIndex: 1000,
                right: 'auto',
            }}
            className=""
        >
            <button
                title="В начало страницы"
                className={`${styles.scrollToTop} btn`}
            >
                Вверх
            </button>
        </ReactScrollUp>
    </>
);

ScrollToTop.defaultProps = {
    showUnder: 300,
    bottom: 88,
};

export default ScrollToTop;
