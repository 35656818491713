import { handleActions, Action } from 'redux-actions';

import * as Store from 'app/redux/store/StoreNamespace';

import documentSearch from './search/documentSearch';

import {
    setStatusFilter,
    setNumberOfEducationMaterials,
    refuseEducationMaterialRecommendations,
} from '../actions/education';

const educationHandler = handleActions<Partial<Store.IEducationMaterials>, any>(
    {
        [setStatusFilter.toString()](
            state: Store.IEducationMaterials,
            { payload }: Action<Store.EducationMaterialStatusValue>
        ) {
            const nextState = { ...state };
            nextState.statusFilter = payload;
            return nextState;
        },
        [setNumberOfEducationMaterials.toString()](
            state: Store.IEducationMaterials,
            { payload }: Action<Store.INumberOfEducationMaterials>
        ) {
            const nextState = { ...state };
            nextState.numberOfMaterials = payload;
            return nextState;
        },
        [refuseEducationMaterialRecommendations.toString()](
            state: Store.IEducationMaterials,
            { payload: documentId }: Action<string>
        ) {
            if (!state.results.list) {
                return state;
            }

            const nextState = { ...state, results: { ...state.results } };
            const nextResultsList = nextState.results.list.slice();
            const docToChange = nextResultsList.find(
                (res) => res.id === documentId
            );

            if (!docToChange) {
                return state;
            }

            docToChange.meta = { ...docToChange.meta };
            docToChange.meta.recommendationsInfo =
                docToChange.meta.recommendationsInfo.map((rec) => ({
                    ...rec,
                    refused: true,
                }));

            nextState.results.list = nextResultsList;

            return nextState;
        },
    },
    {}
);

export default (state = {}, action) => {
    // Так как в bookmark использован combineReducers, он затирает в состоянии все поля, которые не указаны в combineReducers
    const nextState = { ...state, ...documentSearch(state, action) };
    return educationHandler(nextState, action);
};
