/**
 * Created by Lkarmelo on 25.01.2018.
 */

import { MiddlewareAPI } from 'redux';
import { Observable } from 'rxjs/Observable';
import { concat } from 'rxjs/observable/concat';
import { merge } from 'rxjs/observable/merge';
import { Action } from 'redux-actions';

import * as Store from 'app/redux/store/StoreNamespace';
import Api from 'app/api/Api';

import { retryOnAuthorizedEpic } from './retryOnAuthorizedEpic';
import { createRetryOnIEAuthProblem } from './createRetryOnIEAuthProblem';

interface ITransformResponse<TResult = any, TResponse = any> {
    (response: TResponse): TResult;
}

type options = {
    triggers: string[];
    apiCallName: keyof Api.ApiCalls;
    actions: {
        requestAction: Function;
        resolveAction: Function;
        rejectAction: Function;
        setAction: Function;
        spawnOnSuccess?: ((
            response: any,
            originalAction: Action<any>
        ) => Action<any>)[];
        spawnOnError?: ((
            error: any,
            originalAction: Action<any>
        ) => Action<any>)[];
    };
    retryOnAfterAuth?: boolean;
    fetchAction?: Function;
    filterTriggering?: (state: Store.IState, action: Action<any>) => boolean;
    transformRequest?: (state: Store.IState, action: Action<any>) => any;
    transformResponse?: ITransformResponse;
};

const defaultOptions = {
    filterTriggering: () => true,
    transformResponse: (resp) => resp,
};

const createSimpleLoadingEpic = (opts: options) => {
    const mergedOptions: options = { ...defaultOptions, ...opts };
    const {
        triggers,
        apiCallName,
        actions,
        filterTriggering,
        transformResponse,
        transformRequest,
        retryOnAfterAuth,
        fetchAction,
    } = mergedOptions;
    const {
        rejectAction,
        requestAction,
        resolveAction,
        setAction,
        spawnOnSuccess,
        spawnOnError,
    } = actions;

    return (action$, store: MiddlewareAPI<Store.IState>, { apiCall }) =>
        merge(
            retryOnAfterAuth
                ? retryOnAuthorizedEpic(
                      action$,
                      rejectAction.toString(),
                      fetchAction
                  )
                : Observable.empty(),
            action$
                .ofType(...triggers)
                .filter((action) => filterTriggering(store.getState(), action))
                .map((action) =>
                    transformRequest
                        ? transformRequest(store.getState(), action)
                        : action
                )
                .mergeMap((originalAction) =>
                    concat(
                        apiCall[apiCallName](originalAction.payload)
                            .retryWhen(createRetryOnIEAuthProblem())
                            .mergeMap((response) => {
                                const processedResponse = transformResponse(
                                    response.response
                                );
                                return Observable.of(
                                    setAction(
                                        processedResponse,
                                        originalAction
                                    ),
                                    ...(spawnOnSuccess?.map((a) =>
                                        a(response.response, originalAction)
                                    ) || [])
                                ).filter(Boolean);
                            })
                            .catch((e) => {
                                console.error(e);
                                return Observable.of(
                                    ...(spawnOnError?.map((f) =>
                                        f(e, originalAction)
                                    ) || []),
                                    rejectAction(e)
                                ).filter(Boolean);
                            })
                            .takeUntil(action$.ofType(...triggers)),
                        Observable.of(resolveAction())
                    )
                        .takeUntil(action$.ofType(rejectAction.toString()))
                        .startWith(requestAction())
                )
        );
};

export default createSimpleLoadingEpic;
