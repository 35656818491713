/**
 * Created by lkarmelo on 11.11.2019.
 */

import * as React from 'react';
import classNames from 'classnames';
import { useUpdateEffect, usePrevious } from 'react-use';

import * as styles from '../../../../components/common/tables/Filters/TreeSelect/TreeSelect.scss';

interface IProps {
    collapsible: boolean;
    collapsed: boolean;
    className?: string;
    toggleCollapse(nextCollapseState: boolean): void;
    onAfterToggleChange(collapseState: boolean): void;
}

const CollapseToggle: React.FC<IProps> = ({
    collapsible,
    collapsed,
    toggleCollapse,
    onAfterToggleChange,
    className,
}) => {
    const prevCollapsed = usePrevious(collapsed);
    useUpdateEffect(() => {
        if (collapsed !== prevCollapsed) {
            onAfterToggleChange && onAfterToggleChange(collapsed);
        }
    }, [collapsed, onAfterToggleChange]);

    if (!collapsible) {
        return null;
    }

    return (
        <div className={classNames(styles.nkcmSelectCollapseToggle, className)}>
            {collapsed ? (
                <button onClick={() => toggleCollapse(false)}>Ещё</button>
            ) : (
                <button onClick={() => toggleCollapse(true)}>Скрыть</button>
            )}
        </div>
    );
};

export default CollapseToggle;
