/**
 * Created by Lkarmelo on 19.06.2018.
 */
import { createSelector } from 'reselect';

import * as Store from 'app/redux/store/StoreNamespace';
import { IOption } from 'app/components/common/controls/Option';
import { getUserNameByUserObject } from 'app/utils/getUserNameByUserObject';

export const employeesToOptions = createSelector(
    (state: Store.IState): Store.IEmployeesMap => state.orgStructure.employees,
    (employees: Store.IEmployeesMap): IOption[] => {
        return employees
            ? Object.keys(employees).map((empId) => ({
                  value: employees[empId].personId,
                  label: getUserNameByUserObject(employees[empId]),
              }))
            : [];
    }
);
