/**
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 19.02.2018
 * -----
 * Last Modified: 19.02.2018 11:27:37
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import { Dispatch } from 'redux';

import IDocumentPanelOwnProps from 'app/components/document/DocumentPanel/interfaces/IDocumentPanelOwnProps';
import { IDocumentPanelActionProps } from 'app/components/document/DocumentPanel/interfaces/IDocumentPanelProps';
import {
    setSkip,
    setLimit,
    resetPaging,
} from 'app/redux/actions/search/searchPaging';
import {
    fetchMoreSearchResults,
    fetchSearchResults,
} from 'app/redux/actions/search/results';
import { withIgnoreSearchTriggerMeta } from 'app/redux/utils/withIgnoreSearchTriggerMeta';

const mapActionToProps = (
    dispatch: Dispatch<any>,
    ownProps: IDocumentPanelOwnProps
): IDocumentPanelActionProps => ({
    onSkipChange(skip: number): void {
        dispatch(setSkip(skip));
    },

    onLimitChange(limit: number): void {
        dispatch(setLimit({ limit, resetSkip: true }));
    },

    resetPaging(): void {
        dispatch(resetPaging());
    },

    onFetchResults: ownProps.fetchBookmarkedResults
        ? () => {
              dispatch(ownProps.fetchBookmarkedResults());
          }
        : () => {
              dispatch(fetchSearchResults());
          },

    onLoadMore(currentResultCount: number): void {
        dispatch(withIgnoreSearchTriggerMeta(setSkip(currentResultCount)));
        dispatch(fetchMoreSearchResults());
    },
});

export default mapActionToProps;
