import React, { useMemo } from 'react';
import uniqueId from 'lodash/uniqueId';
import classNames from 'classnames';

import IProps from './interfaces/ICheckboxProps';

const Checkbox: React.FC<IProps> = ({
    checked,
    onChange,
    intermediate,
    fixed,
    className,
    inProgress,
}) => {
    const id = useMemo(() => uniqueId('nkcm-checkbox-'), []);
    return (
        <div
            className={classNames(className, 'nkcm-checkbox', {
                'nkcm-checkbox--checked': checked,
                'nkcm-checkbox--intermediate': intermediate,
                'nkcm-checkbox--fixed': fixed,
                'nkcm-checkbox--inProgress': inProgress,
            })}
        >
            <label htmlFor={id} className="nkcm-checkbox__label">
                <input
                    id={id}
                    className="nkcm-checkbox__input"
                    type="checkbox"
                    checked={checked}
                    onChange={fixed ? undefined : onChange}
                    readOnly={fixed}
                />
                <div className="nkcm-checkbox__box" />
            </label>
        </div>
    );
};

Checkbox.defaultProps = {
    checked: false,
    intermediate: false,
};

export default Checkbox;
