/**
 * Created by Lkarmelo on 19.06.2018.
 */
import { handleActions, Action } from 'redux-actions';

import { setOrgStructure } from '../actions/orgstructure';
import * as Store from '../store/StoreNamespace';

export default handleActions<Partial<Store.INormalizedOrgStructure>, any>(
    {
        [setOrgStructure.toString()](
            state: Store.INormalizedOrgStructure,
            { payload }: Action<Store.INormalizedOrgStructure>
        ) {
            return payload;
        },
    },
    {} // - default value for employees types
);
