import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Tooltip from 'antd/es/tooltip';

import { useHighlighted } from 'app/hooks/useHighlighted';
import * as Store from 'app/redux/store/StoreNamespace';
import clientRoutes from 'app/routing/clientRoutes';
import { CURRENT_HTTP_URL } from 'app/utils/constants';

import IProps from './interfaces/ICardProps';
import * as styles from './Card.scss';

import Description from '../Description/Description';
import GroupedAndLinks from '../GroupedAndLinks/GroupedAndLinks';
import SimilarDocuments from '../SimilarDocuments/SimilarDocuments';
import ConceptsViewer from '../Concepts';
import InfoTooltip from '../../../common/InfoTooltip';

const enum Tabs {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Description = 'Description',
    LinkedGrouped = 'LinkedGrouped',
    Similar = 'Similar',
    Concepts = 'Concepts',
}

const Card: React.FunctionComponent<IProps> = (props) => {
    const {
        card,
        toggleFavoriteMark,
        onApproveDeleteDocumentClick,
        onChangeTableSkip,
        onChangeTableLimit,
        onChangeTableSorting,
        getLoadSimilarAction,
    } = props;
    const {
        favorite,
        id: documentId,
        links,
        highlights,
        meta,
        grouped,
        similarityCount,
        concepts,
        conceptsHierarchyTrees,
    } = card || ({} as Store.IObjectCard);
    const { link, title, isEditable } =
        meta || ({} as Store.IDocumentDescription);

    const [activeTab, setActiveTab] = useState(Tabs.Description);

    const [highlightedTitle] = useHighlighted({
        highlights,
        title,
    });

    const groupedCount = grouped?.totalCount ?? 0;
    const linksCount = links?.totalCount ?? 0;
    const similarCount = similarityCount ?? 0;

    return (
        <article className={styles.card}>
            <div className={styles.cardHeader}>
                <h1
                    className={styles.cardTitle}
                    dangerouslySetInnerHTML={{ __html: highlightedTitle }}
                />
                <div className={`${styles.cardActions} clearfix`}>
                    <div className={styles.cardAction}>
                        <button
                            type="button"
                            className="btn"
                            onClick={toggleFavoriteMark}
                        >
                            <span
                                className={classNames(styles.cardActionIcon, {
                                    [styles.cardActionIconFavorite]: favorite,
                                    [styles.cardActionIconFavoriteO]: !favorite,
                                })}
                            />
                            <span>В избранное</span>
                        </button>
                    </div>
                    {link && (
                        <div className={styles.cardAction}>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${CURRENT_HTTP_URL}${clientRoutes.documentRedirect.getUrl(
                                    { id: card.id }
                                )}`}
                            >
                                <span
                                    className={`${styles.cardActionIcon} ${styles.cardActionIconView}`}
                                />
                                <span>Смотреть оригинал</span>
                            </a>
                        </div>
                    )}
                    {isEditable && (
                        <>
                            <span
                                className={`${styles.cardActionDelimiter} ${styles.cardAction}`}
                            />
                            <Tooltip
                                placement="bottomRight"
                                title="Редактировать"
                                mouseEnterDelay={0.3}
                                overlayClassName={`${styles.cardEditDeleteTooltip} ${styles.cardActionTooltip}`}
                            >
                                <Link
                                    to={clientRoutes.documentManagement.subRoutes.edit.getUrl(
                                        { id: documentId }
                                    )}
                                    className={classNames(
                                        styles.cardAction,
                                        styles.cardActionIcon,
                                        styles.cardStatusIcon,
                                        styles.cardActionIconEdit
                                    )}
                                />
                            </Tooltip>
                            <Tooltip
                                placement="bottomRight"
                                title="Удалить"
                                mouseEnterDelay={0.3}
                                overlayClassName={`${styles.cardEditDeleteTooltip} ${styles.cardActionTooltip}`}
                            >
                                <button
                                    onClick={() =>
                                        onApproveDeleteDocumentClick(
                                            documentId,
                                            title
                                        )
                                    }
                                    className={classNames(
                                        'btn',
                                        styles.cardAction,
                                        styles.cardActionIcon,
                                        styles.cardStatusIcon,
                                        styles.cardActionIconDelete
                                    )}
                                />
                            </Tooltip>
                        </>
                    )}
                </div>
                <div className={styles.cardTabs}>
                    <button
                        type="button"
                        className={classNames('btn', styles.cardTab, {
                            [styles.cardTabActive]:
                                activeTab === Tabs.Description,
                        })}
                        onClick={() => setActiveTab(Tabs.Description)}
                    >
                        О документе
                    </button>
                    {groupedCount + linksCount > 0 && (
                        <button
                            type="button"
                            className={classNames('btn', styles.cardTab, {
                                [styles.cardTabActive]:
                                    activeTab === Tabs.LinkedGrouped,
                            })}
                            onClick={() => setActiveTab(Tabs.LinkedGrouped)}
                        >
                            Версии и связи
                            <span className={styles.cardTabCount}>
                                {groupedCount + linksCount}
                            </span>
                        </button>
                    )}
                    {similarCount > 0 && (
                        <button
                            type="button"
                            className={classNames('btn', styles.cardTab, {
                                [styles.cardTabActive]:
                                    activeTab === Tabs.Similar,
                            })}
                            onClick={() => setActiveTab(Tabs.Similar)}
                        >
                            Похожие документы
                            <span className={styles.cardTabCount}>
                                {similarCount}
                            </span>
                        </button>
                    )}
                    {Array.isArray(concepts) && concepts.length > 0 && (
                        <div className={styles.cardConceptTabTooltipWrapper}>
                            <button
                                type="button"
                                className={classNames('btn', styles.cardTab, {
                                    [styles.cardTabActive]:
                                        activeTab === Tabs.Concepts,
                                })}
                                onClick={() => setActiveTab(Tabs.Concepts)}
                            >
                                Концепты
                                <span className={styles.cardTabCount}>
                                    {concepts.length}
                                </span>
                            </button>
                            <InfoTooltip
                                className={styles.cardConceptInfoTooltip}
                                message="Концепты, выделенные из документа, а также его синонимы и близкие термины. На вкладке вы можете пройти по связям и ознакомиться со связанными концептами."
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.cardBody}>
                {activeTab === Tabs.Description && <Description card={card} />}
                {activeTab === Tabs.LinkedGrouped && (
                    <GroupedAndLinks
                        card={card}
                        onChangeTableLimit={onChangeTableLimit}
                        onChangeTableSkip={onChangeTableSkip}
                        onChangeTableSorting={onChangeTableSorting}
                    />
                )}
                {activeTab === Tabs.Similar && (
                    <SimilarDocuments
                        fetchBookmarkedResults={getLoadSimilarAction}
                        count={similarCount}
                    />
                )}
                {activeTab === Tabs.Concepts && (
                    <ConceptsViewer
                        concepts={concepts}
                        conceptsTrees={conceptsHierarchyTrees}
                    />
                )}
            </div>
        </article>
    );
};

export default Card;
