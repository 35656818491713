/**
 * Created by lkarmelo on 08.07.2019.
 */
import { handleActions, Action } from 'redux-actions';

import {
    setIgnoreMistakes,
    setLastExecutedIgnoreMistakes,
} from '../../actions/search/ignoreMistakes';

export const ignoreMistakesReducer = handleActions(
    {
        [setIgnoreMistakes.toString()]: (
            state: boolean,
            { payload }: Action<boolean>
        ): boolean => payload,
    },
    false
);

export const lastExecutedIgnoreMistakesReducer = handleActions(
    {
        [setLastExecutedIgnoreMistakes.toString()]: (
            state: boolean,
            { payload }: Action<boolean>
        ): boolean => payload,
    },
    false
);
