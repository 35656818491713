import history from 'app/history';
import clientRoutes from 'app/routing/clientRoutes';

export const redirectToUrl = (url: string) => {
    history.push(url);
};

export const redirectToSearchPage = () =>
    redirectToUrl(clientRoutes.search.getUrl());

export const reloadPage = () => {
    window.location.reload();
};

export const redirectToSearchPageAndReload = () => {
    redirectToSearchPage();
    reloadPage();
};

export const getErrorAction = (
    requestName: string
): (() => void | undefined) => {
    switch (requestName) {
        case 'searchFromAlias':
            return redirectToSearchPageAndReload;
        case 'objectCard':
            return redirectToSearchPage;
        case 'moduleLoading':
            return reloadPage;
        default:
            return undefined;
    }
};
