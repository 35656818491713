import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'autobind-decorator';
import queryString from 'qs';
import deepEqual from 'deep-equal';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';
import { Action } from 'redux-actions';

import ScrollToTop from 'app/components/common/utils/ScrollToTop';
import withSetDocumentTitle from 'app/components/high-order/setDocumentTitle';
import withApproveDeleteDocumentModal from 'app/components/high-order/withApproveDeleteDocumentModal';
import setBodyClassOnMount from 'app/components/high-order/setBodyClassOnMount';
import {
    fetchObjectCard,
    fetchObjectCardSimilar,
    objectCardTablesActions,
    resetObjectCard,
} from 'app/redux/actions/objectCard';
import { objectCardReject } from 'app/redux/actions/loading';
import { sendFavorite } from 'app/redux/actions/favorites';
import {
    requestEducationMaterialStatusChange,
    requestEducationMaterialStatusReset,
} from 'app/redux/actions/education';
import { setSearchResults } from 'app/redux/actions/search/results';
import { SearchDocumentName } from 'app/redux/actions/interfaces/DocumentEnum';
import { withContext } from 'app/redux/context/connectWithContext';
import * as Store from 'app/redux/store/StoreNamespace';
import clientRoutes from 'app/routing/clientRoutes';
import { parseOptions } from 'app/utils/queryStringOptions';

import IProps, { IObjectCardStateProps } from './interfaces/IObjectCardProps';
import IState from './interfaces/IObjectCardState';
import * as styles from './ObjectCard.scss';

import Card from '../Card';

@autoBind
class ObjectCard extends Component<IProps, IState> {
    state: IState = {
        isRecommendationModalVisible: false,
        recommendationDocumentId: undefined,
    };

    fetch$: BehaviorSubject<{ id: string; locationSearch: string }> = null;

    fetchSubscription: Subscription = null;

    render() {
        const { card, onApproveDeleteDocumentClick } = this.props;
        return (
            <section className={styles.objectCard}>
                {!!card && card.id && (
                    <div className={styles.objectCardContent}>
                        <Card
                            card={card}
                            toggleFavoriteMark={this.handleFavoriteToggle}
                            onApproveDeleteDocumentClick={
                                onApproveDeleteDocumentClick
                            }
                            onChangeTableSkip={this.changeCardTableSkip}
                            onChangeTableLimit={this.changeCardTableLimit}
                            onChangeTableSorting={this.changeCardTableSorting}
                            getLoadSimilarAction={this.getLoadSimilarAction}
                        />
                    </div>
                )}
                <ScrollToTop showUnder={120} />
            </section>
        );
    }

    componentDidMount(): void {
        this.setDocumentTitle(this.props);
        this.createFetchSubscription();
    }

    componentDidUpdate(prevProps: IProps): void {
        const { card, match, location } = this.props;
        this.fetch$.next({
            id: match.params.id,
            locationSearch: location.search,
        });
        if (card && (!prevProps.card || card.id !== prevProps.card.id)) {
            this.setDocumentTitle(this.props);
        }
    }

    componentWillUnmount(): void {
        this.fetchSubscription.unsubscribe();
        this.props.dispatch(objectCardReject());
        this.props.dispatch(resetObjectCard());
        this.props.dispatch(
            withContext(
                setSearchResults({ results: {} }),
                SearchDocumentName.objectCardSimilar
            )
        );
    }

    setDocumentTitle(props: IProps): void {
        if (props.card && props.card.meta && props.card.meta.title) {
            this.props.setDocumentTitle(props.card.meta.title);
        }
    }

    handleFavoriteToggle(): void {
        const { card, dispatch } = this.props;
        const { id, favorite } = card;

        dispatch(
            sendFavorite({
                documentId: id,
                mark: !favorite,
            })
        );
    }

    createFetchSubscription(): void {
        this.fetch$ = new BehaviorSubject({
            id: this.props.match.params.id,
            locationSearch: this.props.location.search,
        });
        this.fetchSubscription = this.fetch$
            .map((x) => {
                const parsedParams = queryString.parse(
                    x.locationSearch,
                    parseOptions
                );
                return {
                    id: x.id,
                    parsedParams,
                };
            })
            .distinctUntilChanged(
                (a, b) =>
                    a.id === b.id && deepEqual(a.parsedParams, b.parsedParams)
            )
            .subscribe((x) => {
                this.props.dispatch(objectCardReject());
                let { expansion }: typeof x.parsedParams = x.parsedParams || {};
                const { query }: typeof x.parsedParams = x.parsedParams || {};
                if (expansion !== undefined && !Array.isArray(expansion)) {
                    expansion = [expansion] as [string];
                }
                this.props.dispatch(
                    fetchObjectCard(x.id, query as string, expansion as string)
                );
            });
    }

    showRecommendationModal(docId: string) {
        this.setState({
            isRecommendationModalVisible: true,
            recommendationDocumentId: docId,
        });
    }

    hideRecommendationModal() {
        this.setState({
            isRecommendationModalVisible: false,
            recommendationDocumentId: undefined,
        });
    }

    changeDocumentStatus(status: Store.DocumentEducationStatus) {
        const { dispatch, card } = this.props;
        dispatch(requestEducationMaterialStatusChange(card.id, status));
    }

    resetDocumentStatus() {
        const { dispatch, card } = this.props;
        dispatch(requestEducationMaterialStatusReset(card.id));
    }

    changeCardTableSkip(tableName: string, skip: number) {
        this.props.dispatch(objectCardTablesActions[tableName].setSkip(skip));
    }

    changeCardTableLimit(tableName: string, skip: number) {
        this.props.dispatch(objectCardTablesActions[tableName].setSkip(0));
        this.props.dispatch(objectCardTablesActions[tableName].setLimit(skip));
    }

    changeCardTableSorting(
        tableName: string,
        field: string | string[],
        order: string
    ) {
        this.props.dispatch(objectCardTablesActions[tableName].setSkip(0));
        this.props.dispatch(
            objectCardTablesActions[tableName].setSorting(field, order)
        );
    }

    getLoadSimilarAction(): Action<any> {
        return fetchObjectCardSimilar({ id: this.props.match.params.id });
    }
}

const decoratedObjectCard = setBodyClassOnMount(styles.objectCardPage)(
    withSetDocumentTitle(
        withApproveDeleteDocumentModal(ObjectCard, (props) => {
            props.history.push(
                clientRoutes.documentManagement.subRoutes.all.getUrl()
            );
        })
    )
);

const mapStateToProps = (state: Store.IState): IObjectCardStateProps => ({
    card: state.objectCard,
});

export default connect(mapStateToProps)(decoratedObjectCard);
