import { PureComponent } from 'react';
import autoBind from 'autobind-decorator';

export interface IState {
    isEditable: boolean;
}

export default class AbstractEditablePanelContainer<
    T = {},
    S extends IState = IState
> extends PureComponent<T, S> {
    state = { isEditable: false } as S;

    @autoBind
    startEdit(): void {
        this.onStartEdit();
        this.setState({ isEditable: true });
    }

    @autoBind
    cancelEdit(): void {
        this.onCancelEdit();
        this.setState({ isEditable: false });
    }

    @autoBind
    save(): void {
        this.onSave();
    }

    onStartEdit(): void {}

    onCancelEdit(): void {}

    onSave(): void {}
}
