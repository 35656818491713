/**
 * Created by lkarmelo on 13.11.2019.
 */

import React, { useMemo, useLayoutEffect, useState } from 'react';
import classNames from 'classnames';
import { usePrevious } from 'react-use';
import { useTruncate } from '@nkc-frontend/nkc-react-hooks';

import clientRoutes from 'app/routing/clientRoutes';
import CarouselWidget from 'app/components/common/Wigets/CarouselWidget';
import { CURRENT_HTTP_URL } from 'app/utils/constants';

import IProps from './interfaces/IDefinitionsProps';
import * as styles from './Definitions.scss';

const Definitions: React.FC<IProps> = (props) => {
    const { terms, storageSourceValueToTitleMap } = props;
    const prevTerms = usePrevious(terms);

    const [currentTermIndex, setCurrentTermIndex] = useState(0);

    if (prevTerms && prevTerms !== terms && currentTermIndex !== 0) {
        setCurrentTermIndex(0);
    }

    const [
        truncateContainerRef,
        isToggleDefinitionTruncateBtnShown,
        ,
        isDefinitionManuallyOpened,
        setIsDefinitionManuallyOpened,
        showHideTruncContextBtnIfOverflown,
    ] = useTruncate<HTMLDivElement>(
        styles.definitionsDefinitionOpened,
        'height'
    );

    useLayoutEffect(() => {
        setIsDefinitionManuallyOpened(false);
        showHideTruncContextBtnIfOverflown();
    }, [currentTermIndex, terms, showHideTruncContextBtnIfOverflown]);

    const termsSlides = useMemo(
        () =>
            terms.map((term) => ({
                renderSlide: () => (
                    <React.Fragment key={term.id}>
                        <div className={styles.definitionsStorage}>
                            {storageSourceValueToTitleMap[term.storageSource]}
                        </div>
                        <div
                            ref={truncateContainerRef}
                            className={classNames(
                                styles.definitionsDefinition,
                                {
                                    [styles.definitionsDefinitionOpened]:
                                        isDefinitionManuallyOpened,
                                }
                            )}
                        >
                            <a
                                className={styles.definitionsTerm}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${CURRENT_HTTP_URL}${clientRoutes.documentRedirect.getUrl(
                                    { id: term.id }
                                )}`}
                            >
                                {term.title} —&#32;
                            </a>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: term.definition,
                                }}
                            />
                        </div>
                        {isToggleDefinitionTruncateBtnShown &&
                            !isDefinitionManuallyOpened && (
                                <button
                                    className={`btn ${styles.definitionsToggleTruncate}`}
                                    onClick={() =>
                                        setIsDefinitionManuallyOpened(true)
                                    }
                                >
                                    Подробнее
                                </button>
                            )}
                        {isToggleDefinitionTruncateBtnShown &&
                            isDefinitionManuallyOpened && (
                                <button
                                    className={`btn ${styles.definitionsToggleTruncate}`}
                                    onClick={() =>
                                        setIsDefinitionManuallyOpened(false)
                                    }
                                >
                                    Свернуть
                                </button>
                            )}
                    </React.Fragment>
                ),
            })),
        [
            terms,
            storageSourceValueToTitleMap,
            isToggleDefinitionTruncateBtnShown,
            isDefinitionManuallyOpened,
            setIsDefinitionManuallyOpened,
        ]
    );

    if (!terms || terms.length === 0) {
        return null;
    }

    return (
        <CarouselWidget
            className={styles.definitions}
            slides={termsSlides}
            currentSlideIndex={currentTermIndex}
            setCurrentSlideIndex={setCurrentTermIndex}
        />
    );
};

Definitions.defaultProps = {
    terms: [],
};

export default Definitions;
