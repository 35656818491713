import React, { useState } from 'react';
import classNames from 'classnames';

import styles from './PersonAvatar.scss';
import IProps from './interfaces/IPersonAvatarProps';

const avatarStubPath = require('images/person-avatar.svg');

const PersonAvatar: React.FC<IProps> = ({
    avatar,
    userId,
    fullTitle = '',
    className,
    onShowBigAvatar,
}) => {
    const [isLoading, setIsLoading] = useState(true);

    return avatar ? (
        <img
            alt="Аватар пользователя"
            src={avatar}
            onError={(e) => {
                e.currentTarget.src = avatarStubPath;
                setIsLoading(false);
            }}
            onLoad={() => {
                setIsLoading(false);
            }}
            onClick={() => {
                userId && onShowBigAvatar(userId, fullTitle);
            }}
            className={classNames(
                { [styles.personAvatarLoading]: isLoading },
                className
            )}
        />
    ) : (
        <div
            className={classNames(
                { [styles.personAvatarLoading]: isLoading },
                styles.personAvatarDefault,
                className
            )}
        />
    );
};

export default PersonAvatar;
