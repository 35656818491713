/**
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 09.06.2018
 * -----
 * Last Modified: 09.06.2018 15:48:52
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import * as React from 'react';

import { SlideTransition } from '../CSSTransitions';

interface IWithOpenStatus {
    className?: string;

    isOpened: boolean;

    onCloseClick: (e: MouseEvent) => void;
    onBlur: () => void;
}

const DropdownContainer = <P extends object>(
    ContentComponent: React.ComponentType<P & { className?: string }>
) =>
    class WithPopup extends React.Component<P & IWithOpenStatus> {
        node: HTMLDivElement = null;

        constructor(props: P & IWithOpenStatus) {
            super(props);

            this.onWrapperBlur = this.onWrapperBlur.bind(this);
            this.handleOutsideClick = this.handleOutsideClick.bind(this);
        }

        render() {
            const { isOpened, onBlur, onCloseClick, ...props } = this.props;
            return (
                <div
                    ref={(node) => {
                        this.node = node;
                    }}
                    className={`${props.className}__dropdown`}
                >
                    <SlideTransition in={isOpened}>
                        <div
                            className={`${props.className}__dropdown-container`}
                            onBlur={this.onWrapperBlur}
                        >
                            <ContentComponent
                                {...(props as P & { className?: string })}
                            />
                        </div>
                    </SlideTransition>
                </div>
            );
        }

        onWrapperBlur(): void {
            const { onBlur } = this.props;

            onBlur && onBlur();
        }

        handleOutsideClick(e: MouseEvent): void {
            const { isOpened, onCloseClick } = this.props;

            const target = e.target as HTMLElement;
            if (!!target.offsetParent && !this.node.contains(target)) {
                isOpened && onCloseClick && onCloseClick(e);
            }
        }

        updateEventListener(isOpened: boolean): void {
            if (isOpened) {
                // attach/remove event handler
                document.addEventListener(
                    'click',
                    this.handleOutsideClick,
                    false
                );
            } else {
                document.removeEventListener(
                    'click',
                    this.handleOutsideClick,
                    false
                );
            }
        }

        componentDidUpdate(prevProps: P & IWithOpenStatus) {
            const { isOpened } = this.props;
            const { isOpened: isPrevOpened } = prevProps;

            isOpened !== isPrevOpened && this.updateEventListener(isOpened);
        }

        componentDidMount(): void {
            const { isOpened } = this.props;

            isOpened && this.updateEventListener(isOpened);
        }

        componentWillUnmount(): void {
            const { isOpened } = this.props;

            // remove listener
            isOpened && this.updateEventListener(false);
        }
    };

export default DropdownContainer;
