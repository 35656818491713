import * as React from 'react';
import { ReactourStep } from 'reactour';

import { fetchSearchResults } from 'app/redux/actions/search/results';
import store from 'app/redux/store/store';
import { setQuery } from 'app/redux/actions/search/searchQuery';
import { setNavMenuDropdownVisibility } from 'app/redux/actions/navMenuDropdownVisibility';

const firework = require('images/themes/gpn/firework.png');

const onInsertSearchInput = (query: string) => {
    store.dispatch(setQuery(query));
};

const onSearch = (query: string) => {
    store.dispatch(setQuery(query));
    store.dispatch(fetchSearchResults());
};

const onNavMenuDropdownOpen = (isOpen: boolean) => {
    store.dispatch(setNavMenuDropdownVisibility(isOpen));
};

export const MAIN_TOUR_STEPS_GPN: ReactourStep[] = [
    {
        content: (
            <>
                Корпоративный поиск работает как привычная вам поисковая
                система: вводите запрос, нажимаете “найти” и переходите к
                выдаче.
                <br />
                Давайте посмотрим, как это выглядит.
            </>
        ),
    },
    {
        selector: '.search-panel__input-wrapper',
        content: 'В поисковую строку вводится запрос, например «ГПН» 🔎',
        mutationObservables: ['.search-panel__input-wrapper'],
        action: () => {
            onInsertSearchInput('ГПН');
        },
    },
    {
        selector: '',
        content: (
            <>
                Перед вами результаты поиска по запросу «ГПН».
                <br />
                Поисковая выдача формируется из всех найденных объектов.
            </>
        ),
        action: () => {
            onSearch('ГПН');
        },
    },
    {
        selector: '.search__services',
        position: 'bottom',
        content: `Это виджет сервисов, с помощью которых вы быстро получите нужную информацию.`,
        mutationObservables: ['.search__services'],
    },
    {
        selector: '.search__persons-widget',
        position: 'top',
        content: `Здесь представлены карточки сотрудников, связанных с вашим запросом.`,
        mutationObservables: ['.search__persons-widget'],
    },
    {
        selector: '.definitions',
        content: `Это глоссарий с расшифровками терминов и аббревиатур.`,
        mutationObservables: ['.definitions'],
    },
    {
        selector: '.document-snippet:nth-of-type(1)',
        position: 'top',
        content: `А здесь расположены релевантные запросу документы и статьи.`,
        mutationObservables: ['.document-snippet'],
        highlightedSelectors: [
            '.document-snippet:nth-of-type(2)',
            '.document-snippet:nth-of-type(3)',
        ],
    },
    {
        selector: '.filters-side-panel__header',
        content: `Это динамические фильтры. С их помощью можно настроить поисковую выдачу, выбрав нужные критерии 🔧`,
        stepInteraction: false,
        position: 'left',
        mutationObservables: ['.filters-side-panel__header'],
        highlightedSelectors: [
            '.filters-side-panel__filter:nth-of-type(3)',
            '.filters-side-panel__filter:nth-of-type(4)',
            '.filters-side-panel__filter:nth-of-type(5)',
        ],
    },
    {
        selector: '.search-result__sorting-and-personalization',
        content: `Инструменты сортировки перестроят выдачу по релевантности, дате или с учетом вашего профиля.`,
        mutationObservables: ['.search-result__sorting-and-personalization'],
    },
    {
        selector: '.btn_feedback',
        content: `С помощью обратной связи вы можете написать нам об
                    ошибке, поделиться впечатлениями или предложить идеи ✉️`,
    },
    {
        selector: '.notification-menu__toggle',
        content: `Здесь отображаются новости системы 📢`,
        action: () => {
            onNavMenuDropdownOpen(false);
        },
    },
    {
        selector: '.nav-menu__tour',
        content: `Здесь вы сможете посмотреть гид заново. Мы будем
                    добавлять обучающие обзоры для новых функций.`,
        action: () => {
            onNavMenuDropdownOpen(true);
        },
    },
    {
        selector: '.user-menu__placeholder',
        content: (
            <>
                <span>
                    A это личный кабинет с вашим профилем и списком избранного.
                </span>
                <img
                    src={firework}
                    alt="firework"
                    style={{ width: 130, margin: '10px auto' }}
                />
                <span>
                    Вот и всё! Теперь вы знакомы с основными функциями системы
                    Корпоративного поиска и можете находить информацию еще
                    быстрее.
                </span>
            </>
        ),
        action: () => {
            onNavMenuDropdownOpen(false);
        },
    },
];

export const MAIN_TOUR_STEPS_DVA: ReactourStep[] = [
    {
        content: (
            <>
                Корпоративный поиск работает как привычная вам поисковая
                система: вводите запрос, нажимаете “найти” и переходите к
                выдаче.
                <br />
                Давайте посмотрим, как это выглядит.
            </>
        ),
    },
    {
        selector: '.search-panel__input-wrapper',
        content: 'В поисковую строку вводится запрос, например «ГПН» 🔎',
        mutationObservables: ['.search-panel__input-wrapper'],
        action: () => {
            onInsertSearchInput('ГПН');
        },
    },
    {
        selector: '',
        content: (
            <>
                Перед вами результаты поиска по запросу «ГПН».
                <br />
                Поисковая выдача формируется из всех найденных объектов.
            </>
        ),
        action: () => {
            onSearch('ГПН');
        },
    },
    {
        selector: '.search__services',
        position: 'bottom',
        content: `Это виджет сервисов, с помощью которых вы быстро получите нужную информацию.`,
        mutationObservables: ['.search__services'],
    },
    {
        selector: '.definitions',
        content: `Это глоссарий с расшифровками терминов и аббревиатур.`,
        mutationObservables: ['.definitions'],
    },
    {
        selector: '.document-snippet:nth-of-type(1)',
        content: `А здесь расположены релевантные запросу документы и файлы.`,
        position: 'top',
        mutationObservables: ['.document-snippet'],
        highlightedSelectors: [
            '.document-snippet:nth-of-type(2)',
            '.document-snippet:nth-of-type(3)',
        ],
    },
    {
        selector: '.filters-side-panel__header',
        content: `Это динамические фильтры. С их помощью можно настроить поисковую выдачу, выбрав нужные критерии 🔧`,
        stepInteraction: false,
        position: 'left',
        mutationObservables: ['.filters-side-panel__header'],
        highlightedSelectors: [
            '.filters-side-panel__filter:nth-of-type(3)',
            '.filters-side-panel__filter:nth-of-type(4)',
            '.filters-side-panel__filter:nth-of-type(5)',
        ],
    },
    {
        selector: '.search-result__sorting-and-personalization',
        content: `Инструменты сортировки перестроят выдачу по релевантности, дате или с учетом вашего профиля.`,
        mutationObservables: ['.search-result__sorting-and-personalization'],
    },
    {
        selector: '.btn_feedback',
        content: `С помощью обратной связи вы можете написать нам об
                    ошибке, поделиться впечатлениями или предложить идеи ✉️`,
        action: () => {
            onNavMenuDropdownOpen(false);
        },
    },
    {
        selector: '.notification-menu__toggle',
        content: `Здесь отображаются новости системы 📢`,
    },
    {
        selector: '.nav-menu__tour',
        content: `Здесь вы сможете посмотреть гид заново. Мы будем
                    добавлять обучающие обзоры для новых функций.`,
        action: () => {
            onNavMenuDropdownOpen(true);
        },
    },
    {
        selector: '.user-menu__placeholder',
        content: (
            <>
                <span>
                    A это личный кабинет с вашим профилем и списком избранного.
                </span>
                <img
                    src={firework}
                    alt="firework"
                    style={{ width: 130, margin: '10px auto' }}
                />
                <span>
                    Вот и всё! Теперь вы знакомы с основными функциями системы
                    Корпоративного поиска и можете находить информацию еще
                    быстрее.
                </span>
            </>
        ),
    },
];

const isDVA = process.env.INTERFACE_TYPE === 'dva';

export const MAIN_TOUR_STEPS_VERSION = isDVA
    ? 'main_guide_dva_1'
    : 'main_guide_1';

export const MAIN_TOUR_STEPS: ReactourStep[] = isDVA
    ? MAIN_TOUR_STEPS_DVA
    : MAIN_TOUR_STEPS_GPN;
