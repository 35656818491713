/**
 * Created by lkarmelo on 17.09.2019.
 */
import { Action, handleActions } from 'redux-actions';
import deepEqual from 'deep-equal';

import { TAG_REG_EXP } from 'app/utils/constants';
import { caseInsensitiveHasOwnProperty } from 'app/utils/caseInsensitiveHasOwnProperty';

import {
    addTag,
    removeTag,
    removeTags,
    setTagsFromQuery,
} from '../../actions/search/tagsFromQuery';

export default handleActions(
    {
        [setTagsFromQuery.toString()](
            state: Record<string, boolean>,
            { payload: query }: Action<string | null>
        ): Record<string, boolean> {
            const tagsMatches =
                typeof query === 'string' && query.match(TAG_REG_EXP);

            if (!tagsMatches) {
                return state && Object.keys(state).length === 0 ? state : {};
            }

            const nextTags = {};
            tagsMatches.reduce((res, match) => {
                if (!caseInsensitiveHasOwnProperty(res, match)) {
                    res[match] = true;
                }
                return res;
            }, nextTags);

            return deepEqual(state, nextTags) ? state : nextTags;
        },
        [addTag.toString()](
            state: Record<string, boolean>,
            { payload }: Action<string | null>
        ): Record<string, boolean> {
            if (state && state[payload]) {
                return state;
            }
            return { ...state, [payload]: true };
        },
        [removeTag.toString()](
            state: Record<string, boolean>,
            { payload }: Action<string | null>
        ): Record<string, boolean> {
            if (state && !state[payload]) {
                return state;
            }
            const nextState = { ...state };
            delete nextState[payload];

            return nextState;
        },
        [removeTags.toString()]() {
            return {};
        },
    },
    {}
);
