import React, { useEffect } from 'react';
import classNames from 'classnames';

import * as styles from './PersonView.scss';

import PersonTelephones from '../../PersonTelephones/PersonTelephones';
import PersonAvatar from '../../PersonAvatar/PersonAvatarConnected';
import IProps from '../interfaces/IPersonViewProps';

const telephonesCount = 3;

const PersonView: React.FC<IProps> = (props) => {
    const { className, person, persons } = props;
    const { onPersonClick, onMailToClick, onLoad } = props;
    const { id, content } = person;

    useEffect(() => {
        onLoad(id);
    }, [id, person]);

    if (content) {
        const {
            fullTitle,
            positionName,
            department,
            telInternal,
            telMobile,
            telOffice,
            email,
        } = content;
        const avatar = persons.find((pers) => pers.id === id).content.avatar;
        return (
            <div className={className}>
                <div className={styles.personViewMainInfo}>
                    <PersonAvatar
                        avatar={avatar && avatar.avatar}
                        userId={id}
                        className={styles.personViewAvatar}
                        fullTitle={content.fullTitle}
                    />
                    <button
                        className={classNames(
                            styles.personViewMainInfoBtn,
                            'btn'
                        )}
                        onClick={() => onPersonClick(person)}
                    >
                        <div className={styles.personViewNameAndPosition}>
                            <div className={styles.personViewFullTitle}>
                                {fullTitle}
                            </div>
                            <div
                                className={styles.personViewPositionName}
                                title={positionName}
                            >
                                {positionName}
                            </div>
                        </div>
                    </button>
                </div>
                <div
                    className={styles.personViewUserAddress}
                    title={department?.name}
                >
                    {department?.name}
                </div>
                <div className={styles.personViewUserTelephones}>
                    <PersonTelephones
                        personId={id}
                        telInternal={telInternal}
                        telMobile={telMobile}
                        telOffice={telOffice}
                        count={telephonesCount}
                    />
                </div>
                <div className={styles.personViewUserEmail}>
                    {email && (
                        <>
                            <i
                                className={classNames(
                                    'nkc-icon-user-mail',
                                    styles.personViewUserEmailIcon
                                )}
                            />
                            <a
                                onClick={() => onMailToClick(person)}
                                href={`mailto:${email}`}
                                className={styles.personViewUserEmailInfo}
                            >
                                {email}
                            </a>
                        </>
                    )}
                </div>
            </div>
        );
    }
    return null;
};

export default PersonView;
