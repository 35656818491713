/**
 * Created by Lkarmelo on 21.11.2017.
 */

import { handleActions } from 'redux-actions';

import { setCurrentUserData, setCurrentUserId } from '../actions/currentUser';
import * as Store from '../store/StoreNamespace';

export default handleActions<Partial<Store.IUser>, any>(
    {
        [setCurrentUserData.toString()](
            state: Store.IUser,
            { payload }: { payload: Store.IUser; type: string }
        ) {
            return payload;
        },
        [setCurrentUserId.toString()](
            state: Store.IUser,
            { payload }: { payload: string; type: string }
        ) {
            const nextState: Store.IUser = { ...state };
            nextState.id = payload;
            return nextState;
        },
    },
    {} // - default value for current user
);
