/**
 * Created by Lkarmelo on 21.02.2018.
 */

import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';

import { setSkip } from 'app/redux/actions/search/searchPaging';
import {
    fetchCatalogueCategories,
    loadCatalogueCategoryKeywords,
} from 'app/redux/actions/catalogue/catalogueCategories';
import { getFunctionalityAllowed, FunctionalityName } from 'app/roles';
import { SearchDocumentName } from 'app/redux/actions/interfaces/DocumentEnum';
import {
    ICatalogueActionProps,
    ICatalogueStateProps,
} from 'app/components/catalogue/Catalogue/interfaces/ICatalogueProps';
import * as Store from 'app/redux/store/StoreNamespace';
import { withContext } from 'app/redux/context/connectWithContext';
import { knowledgeCategoriesToSelectOption } from 'app/utils/knowledgeCategoriesToSelectOption';

import Catalogue from './Catalogue';

const getCategoriesAsOptions = memoizeOne(
    (categories: Store.INormalizedHierarchicalCategories) =>
        categories ? knowledgeCategoriesToSelectOption(categories) : []
);

const mapStateToProps = (state: Store.IState): ICatalogueStateProps => ({
    categories: state.catalogueCategories,
    categoriesAsOptions: getCategoriesAsOptions(state.catalogueCategories),
    canEditCategory: getFunctionalityAllowed(
        state.user,
        FunctionalityName.CatalogueEditCategory
    ),
    canAddCategory: getFunctionalityAllowed(
        state.user,
        FunctionalityName.CatalogueAddCategory
    ),
    canAcceptOrChangeDocumentCategory: getFunctionalityAllowed(
        state.user,
        FunctionalityName.CatalogueAcceptOrChangeDocuments
    ),
    documentsCount:
        state.catalogueDocuments.results &&
        state.catalogueDocuments.results.count,
});

const mapDispatchToProps = (dispatch): ICatalogueActionProps => ({
    fetchCategoryKeywords(categoryId: string): void {
        dispatch(loadCatalogueCategoryKeywords(categoryId));
    },
    skipToFirstPage() {
        const action = withContext(
            setSkip(0),
            SearchDocumentName.catalogueDocuments
        );
        dispatch(action);
    },
    fetchCategories() {
        dispatch(fetchCatalogueCategories());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Catalogue);
