/**
 * Created by lkarmelo on 29.08.2018.
 */

import { createAction } from 'redux-actions';

import * as Store from 'app/redux/store/StoreNamespace';

export const setSorting = createAction<
    Store.ISorting,
    string,
    Store.SortingOrder
>(
    'SET_SORTING',
    (key: string, order: Store.SortingOrder): Store.ISorting => ({ key, order })
);

export const resetSorting = createAction('RESET_SORTING');
