/**
 * Created by lkarmelo on 25.11.2019.
 */

import React, { useCallback, useMemo } from 'react';
import createDecorator from 'final-form-focus';
import { Form, Field } from 'react-final-form';

import Sidebox from 'app/modules/components/Sidebox';
import { ISelectOption } from 'app/modules/components/TreeSelect';
import { HierarchicalSelectAdapter } from 'app/components/common/controls/HierarchicalSelect';
import { TagsInputAdapter } from 'app/components/common/controls/TagsInput';
import { useFormSenderHelper } from 'app/hooks/formHelpers';
import { requiredStringValidation } from 'app/utils/validation';
import { TOP_LEVEL_CATALOGUE_CATEGORY_LABEL } from 'app/utils/constants';

import IProps from './interfaces/IAddEditCategoryProps';
import * as styles from './AddEditCategory.scss';

import {
    EMPTY_OPTION,
    isRemovingCategoryDescendant,
} from '../utils/catalogueUtils';

const focusOnErrors = createDecorator();

const AddEditCategory: React.FC<IProps> = (props) => {
    const {
        initialParentCategoryId,
        isVisible,
        categories,
        close,
        initialKeywords,
        initialTitle,
        formTitle,
        resetForm,
        formStatus,
        sendForm,
        categoriesAsOptions,
        editingCategoryId,
    } = props;

    const initialParentCategory =
        categories &&
        categories.categories &&
        initialParentCategoryId &&
        categories.categories[initialParentCategoryId];

    const categoriesAsOptionsWithNullOption = useMemo(() => {
        const nextCats = categoriesAsOptions.slice();
        nextCats.unshift(EMPTY_OPTION);
        return nextCats;
    }, [categoriesAsOptions]);

    const initialParentCategoryOption = useMemo<ISelectOption>(() => {
        if (!initialParentCategory) {
            return undefined;
        }
        return {
            value: initialParentCategory.item.id,
            label: initialParentCategory.item.title,
        };
    }, [initialParentCategory]);

    // к каждой опции в селекте применяется колбек. Если опция - потомок удаляемой категории, в селекте не должно
    // быть возможности её выбрать
    const isOptionChildOfRemovingCategory = useCallback(
        (categoryId: string) => {
            if (categoryId === EMPTY_OPTION.value) {
                return false;
            }
            return isRemovingCategoryDescendant(
                categoryId,
                editingCategoryId,
                categories
            );
        },
        [editingCategoryId, categories]
    );

    const getIsOptionDisabled = useCallback(
        ({ value }: ISelectOption) => isOptionChildOfRemovingCategory(value),
        [isOptionChildOfRemovingCategory]
    );

    const onSuccess = useCallback(() => {
        // TODO: улучшить UX
        window.location.reload();
    }, []);

    const cbMap = useMemo(() => ({ onSuccess }), [onSuccess]);
    const cbRef = useFormSenderHelper(formStatus, resetForm, cbMap);

    const onSubmit = useCallback(
        (values, _, cb) => {
            cbRef.current = cb;
            const valuesToSend =
                values.parentCode && values.parentCode.value
                    ? { ...values, parentCode: values.parentCode.value }
                    : { title: values.title, keywords: values.keywords };
            sendForm(valuesToSend);
        },
        [sendForm]
    );

    return (
        <Sidebox opened={isVisible} className={styles.addEditCategory}>
            <Form decorators={[focusOnErrors]} onSubmit={onSubmit}>
                {({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className={styles.addEditCategoryContent}>
                            <div className={styles.addEditCategoryTitle}>
                                {formTitle}
                            </div>
                            <div
                                className={`${styles.addEditCategoryField} input-field input-field--required`}
                            >
                                <div className="input-field__title">
                                    Название категории
                                </div>
                                <Field
                                    name="title"
                                    initialValue={initialTitle}
                                    validate={requiredStringValidation}
                                >
                                    {({ input, meta }) => (
                                        <>
                                            <input
                                                {...input}
                                                className="input-field__input"
                                                type="text"
                                                placeholder="Например, Газ"
                                            />
                                            <div className="input-field__error-message">
                                                {meta.touched && meta.error}
                                            </div>
                                        </>
                                    )}
                                </Field>
                            </div>
                            <div className={styles.addEditCategoryField}>
                                <div className="input-field__title">
                                    Категория верхнего уровня
                                </div>
                                <Field
                                    name="parentCode"
                                    initialValue={
                                        initialParentCategoryOption ||
                                        EMPTY_OPTION
                                    }
                                >
                                    {({ input }) => (
                                        <HierarchicalSelectAdapter
                                            {...input}
                                            options={
                                                categoriesAsOptionsWithNullOption
                                            }
                                            placeholder={
                                                TOP_LEVEL_CATALOGUE_CATEGORY_LABEL
                                            }
                                            valueOnDeselected={EMPTY_OPTION}
                                            getIsOptionDisabled={
                                                editingCategoryId
                                                    ? getIsOptionDisabled
                                                    : undefined
                                            }
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={styles.addEditCategoryField}>
                                <div className="input-field__title">
                                    Ключевые слова
                                </div>
                                <Field
                                    name="keywords"
                                    initialValue={initialKeywords}
                                >
                                    {({ input }) => (
                                        <TagsInputAdapter {...input} />
                                    )}
                                </Field>
                            </div>
                            <div className={styles.addEditCategoryControls}>
                                <button
                                    className={`btn ${styles.addEditCategorySaveBtn}`}
                                    disabled={submitting}
                                >
                                    Сохранить
                                </button>
                                <button
                                    type="button"
                                    className={`btn ${styles.addEditCategoryCloseBtn}`}
                                    disabled={submitting}
                                    onClick={close}
                                >
                                    Отмена
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </Form>
        </Sidebox>
    );
};

AddEditCategory.defaultProps = {
    initialKeywords: [],
};

export default AddEditCategory;
