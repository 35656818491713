/**
 * Created by lkarmelo on 03.03.2020.
 */

import * as React from 'react';
import { useMemo } from 'react';

import * as Store from 'app/redux/store/StoreNamespace';
import { searchTargetToTabConfig } from 'app/redux/selectors/searchTabConfig';
import getSearchTarget from 'app/utils/getSearchTarget';
import { useTypedSelector } from 'app/hooks/useTypedSelector';

import { createSortingPanel } from '../../../../common/SortingPanel/SortingPanel';

const SortingPanel = createSortingPanel();

const defaultSorting: Store.ISorting = {
    key: Store.sortingOptions.RelevancySortingOption.key,
    order: 'desc',
};

const SearchSortingPanel: React.FC = () => {
    const { mainSearchPageActiveTabForResults } = useTypedSelector(
        (state) => state
    );
    const store = useTypedSelector((state) => state);

    const searchTarget = useMemo(
        () => getSearchTarget(mainSearchPageActiveTabForResults, store),
        [mainSearchPageActiveTabForResults, store]
    );

    const options = useMemo(
        () => searchTargetToTabConfig[searchTarget].sortingOptions,
        [searchTarget]
    );

    return <SortingPanel options={options} defaultSort={defaultSorting} />;
};

export default SearchSortingPanel;
