/**
 * Created by Lkarmelo on 21.11.2017.
 */

import { createAction } from 'redux-actions';

import * as Store from '../store/StoreNamespace';

export const FETCH_CURRENT_USER_SKILLS = 'FETCH_CURRENT_USER_SKILLS';
export const fetchCurrentUserSkills = createAction(FETCH_CURRENT_USER_SKILLS);

export const SET_CURRENT_USER_SKILLS = 'SET_CURRENT_USER_SKILLS';
export const setCurrentUserSkills = createAction<Store.IUserSkill[]>(
    SET_CURRENT_USER_SKILLS
);
