import * as React from 'react';
import classNames from 'classnames';

import * as styles from './Concept.scss';

interface IConceptProps {
    title: string;
    schemeTitle: string;
    uri?: string;
    className?: string;
    bodyClassName?: string;
    titleClassName?: string;
    bodyPrepend?: React.ReactElement;
    onClick?(props: IConceptProps): void;
}

const Concept: React.FC<IConceptProps> = (props) => {
    const {
        title,
        schemeTitle,
        className,
        onClick,
        bodyClassName,
        titleClassName,
        bodyPrepend,
    } = props;

    return (
        <div
            className={classNames(styles.concept, className, {
                [styles.conceptClickable]: !!onClick,
            })}
            onClick={() => onClick(props)}
        >
            {bodyPrepend}
            <div className={classNames(styles.conceptBody, bodyClassName)}>
                <div className={styles.conceptTitleArrow}>
                    <div
                        className={classNames(
                            styles.conceptTitle,
                            titleClassName
                        )}
                    >
                        {title}
                    </div>
                    <div className={styles.conceptArrow} />
                </div>
                <div className={styles.conceptSchemeTitle}>{schemeTitle}</div>
            </div>
        </div>
    );
};

export default Concept;
