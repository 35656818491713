import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import queryString from 'qs';
import memoizeOne from 'memoize-one';

import {
    FeatureRenderer,
    UIFeaturesName,
} from 'app/components/common/utils/FeatureRenderer';
import clientRoutes, { ProfileQueryParams } from 'app/routing/clientRoutes';
import { parseOptions } from 'app/utils/queryStringOptions';
import * as Store from 'app/redux/store/StoreNamespace';
import { useTypedSelector } from 'app/hooks/useTypedSelector';

import * as styles from '../Header/Header.scss';

const getSystemUserLogins = memoizeOne((user: Store.IUser) =>
    user.userLogins
        ? user.userLogins.map((loginSource) => loginSource.login)
        : []
);

const SectionTitle: React.FC<RouteComponentProps<{}>> = ({ location }) => {
    const {
        user,
        user: { id: systemUserId },
    } = useTypedSelector((state) => state);

    const systemUserLogins = getSystemUserLogins(user);

    const catalogueUrl = clientRoutes.catalogue.getUrl({});

    const documentManagementUrl = clientRoutes.documentManagement.getUrl();

    const objectCardUrl = clientRoutes.objectCard.getUrl({});

    const notificationsUrl = clientRoutes.notifications.getUrl();

    const renderProfileTitle = (): string => {
        const params = queryString.parse(
            location.search,
            parseOptions
        ) as Record<ProfileQueryParams, string>;

        if (
            (params.id !== undefined && params.id !== systemUserId) ||
            (params.user !== undefined &&
                systemUserLogins.indexOf(params.user) <= 0)
        ) {
            return 'Карточка пользователя';
        }
        return 'Личный кабинет';
    };

    const getShouldRender = (): boolean => {
        return location.pathname !== clientRoutes.search.getUrl();
    };

    return getShouldRender() ? (
        <div className={styles.headerPageTitle}>
            {/* TODO: должно быть привязано к роутингу, а не к ссылкам */}
            <FeatureRenderer featureName={UIFeaturesName.ProfileLink}>
                {location.pathname.indexOf(clientRoutes.account.getUrl()) ===
                    0 && <span>{renderProfileTitle()}</span>}
            </FeatureRenderer>
            <FeatureRenderer featureName={UIFeaturesName.CatalogueLink}>
                {location.pathname.indexOf(catalogueUrl) === 0 && (
                    <span>Каталог</span>
                )}
            </FeatureRenderer>
            <FeatureRenderer
                featureName={UIFeaturesName.DocumentManagementLink}
            >
                {location.pathname.indexOf(documentManagementUrl) === 0 && (
                    <span>Обучающие материалы</span>
                )}
            </FeatureRenderer>
            {location.pathname.indexOf(objectCardUrl) === 0 && (
                <span>Документ</span>
            )}
            {location.pathname.indexOf(notificationsUrl) === 0 && (
                <span>Уведомления</span>
            )}
        </div>
    ) : null;
};

export default withRouter(SectionTitle);
