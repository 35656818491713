/**
 * Created by Lkarmelo on 08.09.2017.
 */

const common = {
    allowDots: true,
};

export const stringifyOptions = {
    ...common,
    arrayFormat: 'repeat',
    addQueryPrefix: true,
} as const;

export const parseOptions = {
    ...common,
    ignoreQueryPrefix: true,
} as const;
