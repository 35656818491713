/**
 * Created by Lkarmelo on 21.11.2017.
 */

import { createAction } from 'redux-actions';

import * as Store from '../store/StoreNamespace';

export const FETCH_CURRENT_USER_WORK_INFO = 'FETCH_CURRENT_USER_WORK_INFO';
export const fetchCurrentUserWorkInfo = createAction(
    FETCH_CURRENT_USER_WORK_INFO
);

export const SET_CURRENT_USER_WORK_INFO = 'SET_CURRENT_USER_WORK_INFO';
export const setCurrentUserWorkInfo = createAction<Store.IEmployeeInfo[]>(
    SET_CURRENT_USER_WORK_INFO
);
