/**
 * Created by Lkarmelo on 29.12.2017.
 */

import { createAction } from 'redux-actions';

import * as Store from '../store/StoreNamespace';

export const FETCH_CURRENT_USER_KNOWLEDGE_CATEGORIES =
    'FETCH_CURRENT_USER_KNOWLEDGE_CATEGORIES';
export const fetchCurrentUserKnowledgeCategories = createAction(
    FETCH_CURRENT_USER_KNOWLEDGE_CATEGORIES
);

export const SET_CURRENT_USER_KNOWLEDGE_CATEGORIES =
    'SET_CURRENT_USER_KNOWLEDGE_CATEGORIES';
export const setCurrentUserKnowledgeCategories = createAction<
    Store.IEmployeeInfo[]
>(SET_CURRENT_USER_KNOWLEDGE_CATEGORIES);
