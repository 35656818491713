/**
 * Created by Lkarmelo on 05.10.2017
 * -----
 * Last Modified: 07.02.2018 19:08:52
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux-actions';
import { Dispatch } from 'redux';

import * as Store from 'app/redux/store/StoreNamespace';
import { sendFavorite } from 'app/redux/actions/favorites';
import {
    requestEducationMaterialStatusChange,
    requestEducationMaterialStatusReset,
} from 'app/redux/actions/education';
import { sendSubscribe } from 'app/redux/actions/subscribes';

import {
    IDocumentSnippetActionProps,
    IDocumentSnippetOwnProps,
} from './interfaces/IDocumentSnippetProps';
import DocumentSnippet from './DocumentSnippet';

export const DocumentSnippetCategoryLinkTargetContext =
    React.createContext<string>('_blank');
export const DocumentSnippetIsMinimizableContext =
    React.createContext<boolean>(false);
export const DocumentSnippetIsMinimizedByDefaultContext =
    React.createContext<boolean>(false);

const mapDispatchToProps = (
    dispatch: Dispatch<Action<any>>,
    snippet: IDocumentSnippetOwnProps
): IDocumentSnippetActionProps => ({
    onFavoriteClick() {
        snippet.document &&
            dispatch(
                sendFavorite({
                    documentId: snippet.document.id,
                    mark: !snippet.document.favorite,
                })
            );
    },

    onSubscribeClick() {
        snippet.document &&
            dispatch(
                sendSubscribe({
                    documentId: snippet.document.id,
                    mark: !snippet.document.subscribed,
                })
            );
    },

    onChangeStatusClick(status: Store.DocumentEducationStatus) {
        snippet.document &&
            dispatch(
                requestEducationMaterialStatusChange(
                    snippet.document.id,
                    status
                )
            );
    },

    onResetStatusClick() {
        snippet.document &&
            dispatch(requestEducationMaterialStatusReset(snippet.document.id));
    },
});

const ConnectedDocumentSnippet = connect(
    undefined,
    mapDispatchToProps
)(DocumentSnippet);

export default function (props: IDocumentSnippetOwnProps) {
    return (
        <DocumentSnippetCategoryLinkTargetContext.Consumer>
            {(targetAttr) => (
                <DocumentSnippetIsMinimizedByDefaultContext.Consumer>
                    {(isInitiallyMinimized) => (
                        <DocumentSnippetIsMinimizableContext.Consumer>
                            {(isMinimizable) => (
                                <ConnectedDocumentSnippet
                                    {...props}
                                    categoryLinkTargetAttr={targetAttr}
                                    isMinimizable={isMinimizable}
                                    isInitiallyMinimized={isInitiallyMinimized}
                                />
                            )}
                        </DocumentSnippetIsMinimizableContext.Consumer>
                    )}
                </DocumentSnippetIsMinimizedByDefaultContext.Consumer>
            )}
        </DocumentSnippetCategoryLinkTargetContext.Consumer>
    );
}
