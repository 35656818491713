/**
 * Created by lkarmelo on 16.09.2019.
 */

import React, { useMemo } from 'react';
import moment from 'moment';
import classNames from 'classnames';

import * as Store from 'app/redux/store/StoreNamespace';
import { DATE_DOTS_FORMAT } from 'app/utils/constants';

import ActiveIndicator from '../../ActiveIndicator/ActiveIndicator';
import CardTable from '../Table/CardTable';
import * as cardTableStyles from '../Table/CardTable.scss';

interface ICardLinksProps {
    links: Store.IDocumentLinks;
    paging: Store.IPaging;
    sorting: Store.IObjectCardTableSorting;
    onChangeSkip(tableName: string, skip: number): void;
    onChangeLimit(tableName: string, limit: number): void;
    onChangeSorting(
        tableName: string,
        field: string | string[],
        order: string
    ): void;
}

type RawRow = {
    docId: string;
    title: string;
    date: string;
    activeStatus: Store.IActivityStatus;
};

type TableRow = Omit<RawRow, 'docId'>;

const sortArrowClass = classNames(
    'nkc-icon',
    'nkc-icon-arrow-down',
    cardTableStyles.cardTableSortArrow
);

const columns = [
    {
        Header: () => (
            <span>
                Документ
                <span className={sortArrowClass} />
            </span>
        ),
        accessor: 'title',
        sortingFieldName: 'title',
        className: cardTableStyles.cardTablePreWrap,
    },
    {
        Header: (
            <span>
                Дата документа
                <span className={sortArrowClass} />
            </span>
        ),
        accessor: 'date',
        sortingFieldName: 'optionalAttrs.dateStart',
    },
    {
        Header: (
            <span>
                Степень сходства
                <span className={sortArrowClass} />
            </span>
        ),
        accessor: 'similarity',
        sortingFieldName: 'similarity',
    },
    {
        Header: (
            <span>
                Состояние
                <span className={sortArrowClass} />
            </span>
        ),
        accessor: 'activeStatus',
        Cell: ({ row }: { row: TableRow }) => (
            <ActiveIndicator activeStatus={row.activeStatus} />
        ),
        sortingFieldName: 'activeStatus.ordering',
    },
];

const CardLinks: React.FC<ICardLinksProps> = ({
    links,
    paging,
    onChangeSkip,
    onChangeLimit,
    onChangeSorting,
    sorting,
}) => {
    const data = useMemo((): RawRow[] => {
        return Array.isArray(links.items)
            ? links.items.map(({ item: l, similarity }) => {
                  const activeStatus = l.document.meta?.activeStatus;
                  const dateAttr = l.document.optionalAttrs.find(
                      ({ code }) => code === 'dateStart'
                  );
                  const date = (dateAttr?.value as Store.IDocumentAttrDateValue)
                      ?.$date;

                  return {
                      docId: l.document.id,
                      title: l.document.meta.title,
                      activeStatus,
                      similarity:
                          typeof similarity === 'number' && similarity > 0
                              ? `${Math.floor(similarity * 100)} %`
                              : '< 60 %',
                      date:
                          typeof date === 'number'
                              ? moment(date).format(DATE_DOTS_FORMAT)
                              : '',
                  };
              })
            : [];
    }, [links]);

    return (
        <CardTable
            tableName="links"
            title="Версии документа"
            sortable
            defaultSort={sorting}
            data={data}
            columns={columns}
            totalCount={links && links.totalCount}
            onChangeSkip={onChangeSkip}
            onChangeLimit={onChangeLimit}
            onChangeSorting={onChangeSorting}
            paging={paging}
        />
    );
};

export default CardLinks;
