import React, { useMemo } from 'react';
import classNames from 'classnames';

import styles from './NotificationPreview.scss';

import Notification from '../Notification';
import IProps from '../interfaces/INotificationProps';

const NotificationPreview: React.FC<IProps> = ({ notification }) => {
    // перед тем, как отобразить превью, нужно убрать из html все картинки, кроме первой
    // для вырезания картинок создаётся dom-элемент
    // после нужных манипуляций получаем обновленный html
    const [contentPreview, hasNestedImage] = useMemo(() => {
        const element = document.createElement('div');
        element.innerHTML = notification.content;

        let hasImage = false;
        Array.from(element.querySelectorAll('img')).forEach((image, index) => {
            if (index === 0) {
                hasImage = true;
            } else {
                image.parentNode.removeChild(image);
            }
        });

        return [element.innerHTML, hasImage];
    }, [notification]);

    return (
        <Notification
            className={classNames(styles.notificationPreview, {
                [styles.notificationPreviewHasNestedImage]: hasNestedImage,
            })}
            notification={{ ...notification, content: contentPreview }}
        />
    );
};

export default NotificationPreview;
