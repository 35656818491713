/**
 * Created by Lkarmelo on 20.03.2018.
 */

import { RoutingFeaturesName } from 'app/features/RoutingFeaturesName';

import ClientRoute, { TSubRoutesConfig } from './ClientRoute';

export default class FeatureDependantClientRoute<
    SubRoutesNames extends TSubRoutesConfig = {},
    TParams extends string = any,
    TQuery extends string = any
> extends ClientRoute<SubRoutesNames> {
    featureName: RoutingFeaturesName;

    constructor(
        path: string,
        subRoutesConf?: { [subRouteName: string]: string | ClientRoute },
        featureName?: RoutingFeaturesName
    ) {
        super(path, subRoutesConf);
        this.featureName = featureName;
    }

    getUrl(...args: any[]): string {
        return APP_FEATURES.routing[this.featureName]
            ? super.getUrl.apply(this, arguments)
            : '#';
    }
}
