/**
 * Created by Lkarmelo on 25.09.2017.
 */

import * as Store from 'app/redux/store/StoreNamespace';
import { SearchTarget } from 'app/redux/store/StoreNamespace';

import { FormName } from './FormName';
import { RequestName } from './RequestName';
import apiCalls from './apiCalls';

import { IGuide } from '../types/tour';

namespace Api {
    export type ApiCalls = typeof apiCalls;
    export interface IObjectCardResponse extends Store.IDocumentSnippet {
        annotation?: Store.IAnnotation[];
        similarityCount?: number;
        modifed?: number;
        linksPaging?: Store.IPaging;
        groupedPaging?: Store.IPaging;
        linksSorting?: Store.IObjectCardTableSorting;
        groupedSorting?: Store.IObjectCardTableSorting;
        relatedConcepts?: Store.ISchemeConcept[];
    }

    export interface IFilterValueList {
        values: {
            DisplayValue: {
                code: string;
                parent: string | null;
                displayValue: string;
            };
        }[];
    }

    export interface IFilterValueRange {
        from: string;
        to: string;
    }

    export interface IFilterDescription {
        autoOrdering: boolean;
        code: string;
        hidden: boolean;
        hierarchical: boolean | null;
        isMultiSelect: boolean;
        name: string;
        type: Store.ServerFilterType;
        value: IFilterValueList | IFilterValueRange;
        alwaysVisible: boolean;
        order: number;
        searchTargets?: SearchTarget[];
    }

    export interface ISearchTabSimple {
        id: string;
        code: string;
        name: string;
        description?: string;
        order?: number;
        showPersonalizationSwitch?: boolean;
        searchTarget: Store.SearchTarget;
    }

    export interface ISearchTab extends ISearchTabSimple {
        filters: IFilterDescription[];
        personalization?: boolean;
    }

    export interface ILinkedRequestBody {
        links: ILinkedTableInfo;
        grouped: ILinkedTableInfo;
    }

    interface ILinkedTableInfo {
        skip: number;
        limit: number;
        sortBy?: {
            [field: string]: Store.SortingOrder;
        };
    }

    export interface ILinkedResponseBody {
        links?: Store.IDocumentLinks;
        grouped?: Store.IGroupedSnippets;
    }

    export interface ICategoryWithCatalogItem {
        title: string;
        keywords: string[];
        description: string;
    }

    export interface IDocumentSearchRequestBody {
        query: string;
        limit: number;
        skip: number;
        personalization?: boolean;
        ignoreMistakes?: boolean;
        queryParams?: ISearchRequestFilter[];
        userFilters?: ISearchRequestFilter[];
        expansion?: Store.IQueryNode | Store.IQueryTerm;
        sortBy?: {
            [field: string]: SearchSorting;
        };
        concepts?: ISearchRequestConcept[];
        strict?: boolean | null;
    }

    export interface ISearchRequestConcept {
        uri: string;
        selected: boolean;
    }

    export type SearchSorting = 'ASC' | 'DESC';

    export interface ISearchRequestFilter {
        type: 'BaseFilter';
        code: string;
        value: RangeFilterValue | SelectFilterValue;
    }

    export type RangeFilterValue = {
        RangeFilterValue: {
            from: number;
            to: number;
        };
    };

    export type SelectFilterValue = {
        SelectFilterValue: {
            values: {
                StringFilterValue: {
                    value: string;
                };
            }[];
        };
    };

    export interface IPersonSearchRequestBody {
        query: string;
        limit: number;
        skip: number;
        userFilters?: ISearchRequestFilter[];
        sortBy?: {
            [field: string]: SearchSorting;
        };
    }

    export interface IGlobalSearchRequestBody {
        query: string;
        searchTab?: string;
        doc: GlobalSearchDocumentRequestBody;
    }

    export interface ICompressedSearch {
        searchRequest: IGlobalSearchRequestBody;
        meta: ISearchAliasMeta;
    }

    export interface ISearchAliasMeta {
        savedFiltersMetaValues?: {
            [filterName: string]: Store.IFilterMetaValue[];
        };
    }

    export const enum SuggestionType {
        employee = 'employee',
    }

    export type GlobalSearchDocumentRequestBody = Omit<
        IDocumentSearchRequestBody,
        'query'
    >;
    export type GlobalSearchPersonRequestBody = Omit<
        IPersonSearchRequestBody,
        'query'
    >;

    export interface IGlobalSearchResponseBody {
        documents: IDocumentSearchResponseBody;
        persons?: IPersonSearchResponseBody;
        askFeedback?: boolean;
    }

    export interface IDocumentSearchResponseBody {
        count: number;
        aggregations: Store.IAggregation[];
        list: Store.IDocumentSnippet[];
        categories?: any[];
        extractedTerms?: {
            items: Store.IExtractedTerm[];
            total: number;
        };
        extractedServices?: {
            items: Store.IExtractedService[];
            total: number;
        };
        extractedConcepts?: IExtractedConcept[];
        dynamicFilterVisible?: Store.IFilterVisibility[];
    }

    export interface IExtractedConcept {
        item: Store.ISelectableSchemeConcept;
        tree?: Omit<IExtractedConcept, 'related'>[];
        related?: Store.ISelectableSchemeConcept[];
    }

    export interface IPersonSearchResponseBody {
        totalCount: number;
        items: Store.IPerson[];
        aggregations: Store.IAggregation[];
        askFeedback?: boolean;
        strict?: boolean;
        dynamicFilterVisible?: Store.IFilterVisibility[];
    }

    export interface IHierarchicalOrgStructure {
        employees: Store.INamedEmployee[];
        childSubdivision: IHierarchicalOrgStructure[];
        division?: Store.ISimpleCatalogItem;
    }

    export interface INumberOfEducationMaterials {
        total: number;
        recommendations: {
            total: number;
            mandatory: number;
        };
        documentStatus: {
            [Store.EducationMaterialStatusValue.Planning]: number;
            [Store.EducationMaterialStatusValue.Done]: number;
            [Store.EducationMaterialStatusValue.Active]: number;
        };
    }

    export interface IChangeEducationMaterialStatus {
        documentStatus: Store.DocumentEducationStatus;
    }

    export interface ICatalogueCategoriesResponse {
        id: string;
        title: string;
        subCategories: ICatalogueCategoriesResponse[];
        countCat: number;
        countWithSubCat: number;
        countUnverified?: number;
    }

    export interface IRecommendationsRequestParameters {
        document?: string;
        author?: string;
        createdDateFrom?: number;
        createdDateTo?: number;
        planDateFrom?: number;
        planDateTo?: number;
        learnedDateTo?: number;
        learnedDateFrom?: number;
        necessity?: boolean;
        status?: Store.DocumentEducationStatus[];
        recommendationStatus?: boolean;
        receiver?: string;
        orderBy?: string;
        orderAsc?: boolean;
        page?: number;
        count?: number;
    }

    export type UserPermissionsResponse = IUserPermission[];

    export interface IUserPermission {
        permission: Store.PermissionRole;
        userUuid: string;
    }

    export interface ICreateRecommendationResponse {
        recommendation: Store.IRecommendationDetail;
        receivers: Store.IUserRecommendationDetail[];
        declinedAssignments?: {
            declinedByCollisions: Store.IUserRecommendationDetail[];
            declinedByDocumentBeenStudied: Store.IUser[];
            declinedByRecommendedToSelf: boolean;
        };
    }

    export interface ICategorizationRulesResponse {
        categoryCodeToRules: {
            [category: string]: Store.ICategorizationRule[];
        };
    }

    export interface ICatalogueCategoryOperation {
        title: string;
        keywords: string[];
        description?: string;
        parentCode?: string;
    }

    export interface INotificationsResponse {
        notifications: Store.IUserNotification[];
        total: number;
        newCount: number;
    }

    export interface IGuideListResponse {
        stats: IGuide[];
    }

    export interface IObjectCardSimilar {
        similarity: number;
        document: Omit<Store.IDocumentSnippet, 'similarity'>;
    }

    export type FormBody = {
        [FormName.PersonalInfo]: {
            birthDate: DateTimeString;
            phone: string[];
            email: string[];
        };

        [FormName.AddNewDocument]: {
            title: string;
            objectSubType: string;
            storageUrl?: string;
            summary?: string;
            year?: number;
            orgs?: Store.IOrganization[];
            persons?: { title: string; id: string }[];
            categories?: string[];
            competenciesCodeToScore?: {
                competenceCode: string;
                grade: number;
            }[];
            optionalAttributes?: { [attrName: string]: string };
        };

        recommendBySubdivisions: {
            documentId: string;
            receiversData: {
                subdivisionIds: string[];
                postIds: string[];
                planDate?: string;
            };
        };

        recommendByProfiles: {
            documentId: string;
            receiversData: {
                profilePlanDate: { profileId: string; planDate?: string }[];
            };
        };

        [FormName.EditRecommendation]: {
            profilePlanDate: { profileId: string; planDate?: string }[];
        };

        [FormName.ErrorReport]: {
            message: string;
        };

        [FormName.FeedbackMessage]: {
            message: string;
            found: boolean;
        };

        [FormName.CatalogueAddCategory]: {
            id: string;
        } & ICatalogueCategoryOperation;

        [FormName.CatalogueEditCategory]: ICatalogueCategoryOperation;
    };

    export const errorCodes = new Map([
        [RequestName.CreateSkill, { SKILL_ARCHIVED: 'SKILL_ARCHIVED' }],
    ]);
}

export default Api;
