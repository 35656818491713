/**
 * Created by Lkarmelo on 29.09.2017.
 */

import React, { PureComponent, MouseEvent } from 'react';
import autoBind from 'autobind-decorator';
import moment, { Moment } from 'moment';
import DatePicker from 'react-datepicker';

import { SlideTransition } from 'app/components/common/utils/CSSTransitions';
import { DATE_DOTS_FORMAT } from 'app/utils/constants';

import IProps from './interfaces/ITimePeriodProps';
import IState from './interfaces/ITimePeriodState';
import * as styles from './TimePeriod.scss';

import DropDown from '../Select/DropDown/DropDown';

@autoBind
export default class TimePeriod extends PureComponent<IProps, IState> {
    static options = [
        { label: 'За вcё время', value: 'all' },
        { label: 'За сегодня', value: 'day' },
        { label: 'За неделю', value: 'week' },
        { label: 'За месяц', value: 'month' },
        { label: 'За период...', value: 'period' },
    ];

    currentValue: string = undefined;

    valueBeforeDatePickerShow: string = undefined;

    isDatePickerClicked = false;

    state = {
        isDatePickerShown: false,
    };

    get hasStartDate(): boolean {
        return (
            this.props.startDate !== undefined && this.props.startDate !== null
        );
    }

    get hasEndDate(): boolean {
        return this.props.endDate !== undefined && this.props.endDate !== null;
    }

    get startDate(): Moment {
        return this.hasStartDate && this.currentValue === 'period'
            ? this.fromUnit(this.props.startDate)
            : undefined;
    }

    get endDate(): Moment {
        return this.hasEndDate && this.currentValue === 'period'
            ? this.fromUnit(this.props.endDate)
            : undefined;
    }

    get minDate(): Moment {
        if (this.valueBeforeDatePickerShow === 'period' && this.hasStartDate) {
            return this.fromUnit(this.props.startDate);
        }
    }

    get maxDate(): Moment {
        if (this.valueBeforeDatePickerShow === 'period' && this.hasEndDate) {
            return this.fromUnit(this.props.endDate);
        }
    }

    get textTitle(): string {
        const { startDate, endDate } = this.props;

        if (this.currentValue === 'period') {
            const fmt = DATE_DOTS_FORMAT;

            return `${
                this.hasStartDate ? `С ${moment(startDate).format(fmt)}` : ''
            } ${this.hasEndDate ? `По ${moment(endDate).format(fmt)}` : ''}`;
        }

        return TimePeriod.options.find((opt) => opt.value === this.currentValue)
            .label;
    }

    get title(): string | JSX.Element {
        const { startDate, endDate } = this.props;

        if (this.currentValue === 'period') {
            const fmt = DATE_DOTS_FORMAT;

            return (
                <span className={styles.timePeriodDateDisplayWrapper}>
                    <span
                        className={styles.timePeriodDateDisplay}
                        onClick={this.onDateDisplayClick}
                        onMouseDown={this.setDatePickerClicked}
                        onMouseUp={this.setDatePickerNotClicked}
                    >
                        {this.hasStartDate && (
                            <span>С {moment(startDate).format(fmt)}</span>
                        )}
                        {'\u00A0'}
                        {this.hasEndDate && (
                            <span>По {moment(endDate).format(fmt)}</span>
                        )}
                    </span>
                </span>
            );
        }
        return TimePeriod.options.find((opt) => opt.value === this.currentValue)
            .label;
    }

    render(): JSX.Element {
        this.setCurrentValueByDate();
        return (
            <div className={styles.timePeriod}>
                <div>
                    <DropDown
                        options={TimePeriod.options}
                        prompt={this.textTitle}
                        title={this.title}
                        active={this.currentValue}
                        filterName={this.props.filterName}
                        onSelectOption={this.onSelectOption}
                        focusable={this.props.focusable}
                    />
                </div>
                <SlideTransition in={this.state.isDatePickerShown}>
                    <div
                        onMouseDown={this.setDatePickerClicked}
                        onMouseUp={this.setDatePickerNotClicked}
                        className={`${styles.timePeriodDatepickersContainer} clearfix`}
                    >
                        <div className={styles.timePeriodDatepicker}>
                            <DatePicker
                                onChange={undefined}
                                inline
                                fixedHeight
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                selected={this.startDate}
                                onSelect={this.onStartDateChange}
                                maxDate={this.maxDate}
                            />
                        </div>
                        <div className={styles.timePeriodDatepicker}>
                            <DatePicker
                                onChange={undefined}
                                inline
                                fixedHeight
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                selected={this.endDate}
                                onSelect={this.onEndDateChange}
                                minDate={this.minDate}
                            />
                        </div>
                    </div>
                </SlideTransition>
            </div>
        );
    }

    componentDidMount(): void {
        document.addEventListener(
            'mousedown',
            this.closeDatePickerOnOutsideClick
        );
    }

    componentWillUnmount(): void {
        document.removeEventListener(
            'mousedown',
            this.closeDatePickerOnOutsideClick
        );
    }

    setCurrentValueByDate(): void {
        let { startDate, endDate } = this.props;

        // convert
        startDate = startDate && this.fromUnit(startDate).valueOf();
        endDate = endDate && this.fromUnit(endDate).valueOf();

        const todayEnd = moment().endOf('day').valueOf();
        const todayStart = moment().startOf('day').valueOf();
        const weekStart = moment().subtract(7, 'days').startOf('day').valueOf();
        const monthStart = moment()
            .subtract(1, 'months')
            .startOf('day')
            .valueOf();
        const yearStart = moment()
            .subtract(1, 'years')
            .startOf('day')
            .valueOf();
        const currentYear = moment().startOf('years').valueOf();
        const previousYear = moment()
            .startOf('years')
            .subtract(1, 'years')
            .valueOf();
        const previous2Year = moment()
            .startOf('years')
            .subtract(2, 'years')
            .valueOf();

        if (!this.hasEndDate && !this.hasStartDate) {
            this.currentValue = 'all';
        } else if (startDate === todayStart && endDate === todayEnd) {
            this.currentValue = 'day';
        } else if (startDate === weekStart && endDate === todayEnd) {
            this.currentValue = 'week';
        } else if (startDate === monthStart && endDate === todayEnd) {
            this.currentValue = 'month';
        } else if (startDate === yearStart && endDate === todayEnd) {
            this.currentValue = 'year';
        } else if (startDate === previousYear && endDate === currentYear) {
            this.currentValue = 'currentYear';
        } else if (startDate === previous2Year && endDate === previousYear) {
            this.currentValue = 'previousYear';
        } else {
            this.currentValue = 'period';
        }
    }

    closeDatePickerOnOutsideClick(): void {
        if (!this.isDatePickerClicked) {
            this.state.isDatePickerShown &&
                this.setState({ isDatePickerShown: false });
        }
    }

    onSelectOption(value: string): void {
        switch (value) {
            case 'all':
                this.onSelectAll();
                break;
            case 'day':
                this.onSelectDay();
                break;
            case 'week':
                this.onSelectWeek();
                break;
            case 'month':
                this.onSelectMonth();
                break;
            case 'year':
                this.onSelectYear();
                break;
            case 'currentYear':
                this.onSelectCurrentYear();
                break;
            case 'previousYear':
                this.onSelectPreviousYear();
                break;
            case 'period':
                this.onSelectPeriod();
                break;
            default:
        }
    }

    toUnit(from: Moment, to: Moment) {
        return { from: from && from.valueOf(), to: to && to.valueOf() };
    }

    fromUnit(date: number): Moment {
        return moment(date);
    }

    onSelectAll(): void {
        this.props.onPeriodChange(this.props.filterName, {
            from: undefined,
            to: undefined,
        });
    }

    onSelectDay(): void {
        const todayEnd = moment().endOf('day');
        const todayStart = moment().startOf('day');
        this.props.onPeriodChange(
            this.props.filterName,
            this.toUnit(todayStart, todayEnd)
        );
    }

    onSelectWeek(): void {
        const todayEnd = moment().endOf('day');
        const weekStart = moment().subtract(7, 'days').startOf('day');
        this.props.onPeriodChange(
            this.props.filterName,
            this.toUnit(weekStart, todayEnd)
        );
    }

    onSelectMonth(): void {
        const todayEnd = moment().endOf('day');
        const monthStart = moment().subtract(1, 'months').startOf('day');
        this.props.onPeriodChange(
            this.props.filterName,
            this.toUnit(monthStart, todayEnd)
        );
    }

    onSelectYear(): void {
        const todayEnd = moment().endOf('day');
        const yearStart = moment().subtract(1, 'years').startOf('day');
        this.props.onPeriodChange(
            this.props.filterName,
            this.toUnit(yearStart, todayEnd)
        );
    }

    onSelectCurrentYear(): void {
        const todayEnd = moment().endOf('day');
        const yearStart = moment().startOf('year');
        this.props.onPeriodChange(
            this.props.filterName,
            this.toUnit(yearStart, todayEnd)
        );
    }

    onSelectPreviousYear(): void {
        const todayEnd = moment().startOf('year').subtract(1, 'seconds');
        const yearStart = moment().startOf('year').subtract(1, 'years');
        this.props.onPeriodChange(
            this.props.filterName,
            this.toUnit(yearStart, todayEnd)
        );
    }

    onSelectPeriod(): void {
        this.onBeforeShowDatePicker();
        this.setState({ isDatePickerShown: true });
    }

    setDatePickerClicked(e: MouseEvent<HTMLElement>): void {
        e.stopPropagation();
        this.isDatePickerClicked = true;
    }

    setDatePickerNotClicked(e: MouseEvent<HTMLElement>): void {
        e.stopPropagation();
        this.isDatePickerClicked = false;
    }

    onStartDateChange(date: Moment): void {
        const { onStartDateChange, startDate, filterName, onPeriodChange } =
            this.props;

        if (this.valueBeforeDatePickerShow !== 'period') {
            onPeriodChange(filterName, this.toUnit(date, undefined));
            this.valueBeforeDatePickerShow = 'period';
        } else if (Math.abs(date.diff(moment(startDate), 'days')) < 1) {
            onStartDateChange(filterName, undefined);
        } else {
            onStartDateChange(filterName, this.toUnit(date, undefined).from);
        }
    }

    onEndDateChange(date: Moment): void {
        const { onEndDateChange, endDate, filterName, onPeriodChange } =
            this.props;

        if (this.valueBeforeDatePickerShow !== 'period') {
            onPeriodChange(filterName, this.toUnit(undefined, date));
            this.valueBeforeDatePickerShow = 'period';
        } else if (Math.abs(date.diff(moment(endDate), 'days')) < 1) {
            onEndDateChange(filterName, undefined);
        } else {
            onEndDateChange(filterName, this.toUnit(undefined, date).to);
        }
    }

    onDateDisplayClick(e: MouseEvent<HTMLSpanElement>): void {
        e.stopPropagation();
        this.onBeforeShowDatePicker();
        this.setState((state) => ({
            isDatePickerShown: !state.isDatePickerShown,
        }));
    }

    /*
        Чтобы когда пользователь открыл календарь после выбора периода "за сегодня/неделю...",
        у него не были заблокированы даты вне предыдущего периода
    */
    onBeforeShowDatePicker(): void {
        this.valueBeforeDatePickerShow = this.currentValue;
    }
}
