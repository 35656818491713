import * as React from 'react';

import connectWithContext, {
    IInjectedProps,
} from 'app/redux/context/connectWithContext';
import * as Store from 'app/redux/store/StoreNamespace';
import { IDocumentPanelStateProps } from 'app/components/document/DocumentPanel/interfaces/IDocumentPanelProps';
import {
    documentState,
    SearchDocumentName,
} from 'app/redux/actions/interfaces/DocumentEnum';

import StatusFilter from './EducationMaterialStatusFilterConnected';
import * as styles from './Education.scss';

import mapActionToProps from '../../../document/DocumentPanelActionDefault';
import DocumentPanel from '../../../document/DocumentPanel/DocumentPanelConnected';

const educationState = documentState(SearchDocumentName.education);

const DocumentFilterPanel = () => {
    return null;
};

let isDocRemoved = (doc: Store.IDocumentSnippet): boolean => false;

const mapStateToFactoryProps = () => {
    const constProps = {
        renderFilterPanel: (): JSX.Element => {
            return (
                <div className={styles.educationFilters}>
                    <div className={styles.educationTitle}>
                        Обучение и развитие
                    </div>
                    <StatusFilter />
                    <DocumentFilterPanel />
                </div>
            );
        },
        checkRemoved: (doc: Store.IDocumentSnippet) => isDocRemoved(doc),
    };

    return (
        state: Store.IState
    ): Store.IDocumentResults & IInjectedProps & IDocumentPanelStateProps => {
        const actionState: Store.IEducationMaterials = educationState(
            state
        ) as Store.IEducationMaterials;

        isDocRemoved = (doc?: Store.IDocumentSnippet): boolean => {
            const isRecommendedOpened =
                actionState.statusFilter ===
                Store.EducationMaterialStatusValue.Recommended;
            const isAllOpened =
                actionState.statusFilter ===
                Store.EducationMaterialStatusValue.All;
            const hasNoAcceptedRecommendations =
                !doc.meta.recommendationsInfo ||
                doc.meta.recommendationsInfo.filter((rec) => !rec.refused)
                    .length === 0;

            return (
                (!isAllOpened &&
                    !(isRecommendedOpened && !doc.meta.status) &&
                    doc.meta.status !== actionState.statusFilter) ||
                (isRecommendedOpened && hasNoAcceptedRecommendations) ||
                (hasNoAcceptedRecommendations && !doc.meta.status)
            );
        };

        return {
            rootContext: SearchDocumentName.education,
            currentUserId: state.currentUser.id,
            isLoading: state.loading.pendingRequests.search,
            limit: actionState.paging.limit,
            skip: actionState.paging.skip,
            ...actionState.results,
            ...constProps,
        };
    };
};

const ConnectedDocumentPanel = connectWithContext(
    mapStateToFactoryProps,
    mapActionToProps
)(DocumentPanel);

// eslint-disable-next-line react/function-component-definition
export default function () {
    return <ConnectedDocumentPanel />;
}
