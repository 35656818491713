/**
 * Created by Lkarmelo on 23.08.2017.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'autobind-decorator';
import classNames from 'classnames';
import Col from 'antd/es/col';
import Row from 'antd/es/row';

import ScrollToTop from 'app/components/common/utils/ScrollToTop';
import QueryCorrection from 'app/components/search/QueryCorrection';
import { searchInputClassName } from 'app/components/search/SearchPanel';
import {
    setQuery,
    setQueryExpansion,
    setQueryExpansionMode,
    setLastExecutedSearchQuery,
} from 'app/redux/actions/search/searchQuery';
import {
    fetchSearchResults,
    setSearchResults,
} from 'app/redux/actions/search/results';
import {
    searchReject,
    expandQueryReject,
    compressSearchReject,
    searchFromAliasReject,
} from 'app/redux/actions/loading';
import {
    setFilters,
    setLastExecutedFilters,
} from 'app/redux/actions/search/filters';
import { setTagsFromQuery } from 'app/redux/actions/search/tagsFromQuery';
import { setLastExecutedIgnoreMistakes } from 'app/redux/actions/search/ignoreMistakes';
import { setLastExecutedSearchAlias } from 'app/redux/actions/search/searchAlias';
import {
    clearConceptsSelectState,
    setExtractedConcepts,
} from 'app/redux/actions/search/concepts';
import * as Store from 'app/redux/store/StoreNamespace';
import windowSizeContext from 'app/components/GridSizeProvider/windowSizeContext';
import {
    SEARCH_QUERY_PARAM_NAME,
    SEARCH_ALIAS_PARAM_NAME,
    GridSize,
    EMPLOYEES_TAB_CODE,
} from 'app/utils/constants';
import getSearchTarget from 'app/utils/getSearchTarget';
import { setSearchActiveTab } from 'app/redux/actions/search/searchTabs';

import IProps, { ISearchStateProps } from './interfaces/ISearchProps';
import * as styles from './Search.scss';
import IState from './interfaces/ISearchState';

import SearchLocationObserver from '../SearchLocationObserver';
import { withIgnoreSearchTriggerMeta } from '../../../redux/utils/withIgnoreSearchTriggerMeta';
import Services from '../Services';
import PersonsWidget from '../PersonsWidget/PersonsWidgetConnected';
import ConceptsPanel from '../SearchConcepts';
import Definitions from '../Definitions';
import FiltersSidePanel from '../FiltersPanel/FiltersPanel';
import QueryTooLong from '../QueryTooLong';
import { SearchResultDocuments, SearchResultUsers } from '../SearchResult';
import { fetchFiltersMeta } from '../../../redux/actions/search/filtersMeta';

@autoBind
class Search extends Component<IProps, IState> {
    static contextType = windowSizeContext;

    state: IState = {};

    clarifySuggestionBeenShown = false;

    initialPathName: string = null;

    constructor(props: IProps) {
        super(props);
        const { searchQuery, location } = props;
        // нужно чтобы проиграть анимацию уезжающей вверх панели поиска при первой поиске после захода на страницу
        if (
            !searchQuery &&
            !location.search.includes(SEARCH_QUERY_PARAM_NAME) &&
            !location.search.includes(SEARCH_ALIAS_PARAM_NAME)
        ) {
            document.body.classList.add(styles.searchNoSearchDone);
        }
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(fetchFiltersMeta());
    }

    componentWillMount() {
        this.initialPathName = this.props.location.pathname;
    }

    componentDidUpdate(prevProps: IProps): void {
        const {
            location: { search },
            lastSearchQuery,
            lastSearchFilters,
            currentActiveTab,
        } = this.props;
        const {
            lastSearchQuery: prevLastSearchQuery,
            lastSearchFilters: prevLastSearchFilters,
            currentActiveTab: prevCurrentActiveTab,
            location: { search: prevSearch },
        } = prevProps;

        if (
            (typeof prevLastSearchQuery !== 'string' &&
                typeof lastSearchQuery === 'string') ||
            (!prevSearch.includes(SEARCH_QUERY_PARAM_NAME) &&
                search.includes(SEARCH_QUERY_PARAM_NAME)) ||
            (!prevSearch.includes(SEARCH_ALIAS_PARAM_NAME) &&
                search.includes(SEARCH_ALIAS_PARAM_NAME))
        ) {
            document.body.classList.remove(styles.searchNoSearchDone);
        }

        if (
            prevLastSearchQuery !== lastSearchQuery ||
            prevLastSearchFilters !== lastSearchFilters
        ) {
            this.onNewSearchExecuted();
        }
        if (
            prevLastSearchQuery !== lastSearchQuery ||
            prevLastSearchFilters !== lastSearchFilters ||
            prevCurrentActiveTab !== currentActiveTab
        ) {
            this.setState({ scrollToPerson: this.state.personId });
        }
    }

    componentWillUnmount(): void {
        const { dispatch } = this.props;

        document.body.classList.remove(styles.searchNoSearchDone);

        dispatch(compressSearchReject());
        dispatch(searchFromAliasReject());
        dispatch(searchReject());
        dispatch(expandQueryReject());

        dispatch(setQuery(null));
        dispatch(setLastExecutedSearchQuery(null));
        dispatch(setSearchResults({ results: {} }));
        dispatch(setFilters({}));
        dispatch(setLastExecutedFilters({}));
        dispatch(setLastExecutedIgnoreMistakes(false));
        dispatch(setLastExecutedSearchAlias(null));
        dispatch(setTagsFromQuery(''));
        dispatch(withIgnoreSearchTriggerMeta(setQueryExpansionMode(false)));
        dispatch(setQueryExpansion(null));
        dispatch(setExtractedConcepts({}));
        dispatch(withIgnoreSearchTriggerMeta(clearConceptsSelectState()));
    }

    onNewSearchExecuted(): void {
        this.clarifySuggestionBeenShown = false;
    }

    getSearchInputHtmlElement(): HTMLElement {
        return document.querySelector(
            `.${searchInputClassName}`
        ) as HTMLElement;
    }

    focusSearchFieldInput(): void {
        const searchInputElement = this.getSearchInputHtmlElement();
        searchInputElement && searchInputElement.focus();
    }

    blurSearchFieldInput(): void {
        const searchInputElement = this.getSearchInputHtmlElement();
        searchInputElement && searchInputElement.blur();
    }

    onPersonClick(person: Store.IPerson, isManager?: boolean): void {
        const { dispatch } = this.props;

        dispatch(
            setSearchActiveTab({
                tabCode: EMPLOYEES_TAB_CODE,
                preserveIgnoreMistakesFromLastSearch: true,
                preservePersonStrict: true,
            })
        );
        if (isManager) {
            dispatch(setQuery(person.content.fullTitle));
            dispatch(fetchSearchResults());
        }

        this.setState({ personId: person.id });
    }

    resetScroll(): void {
        this.setState({ scrollToPerson: null, personId: null });
    }

    render(): JSX.Element {
        const { searchTarget, currentActiveTab } = this.props;
        const { scrollToPerson } = this.state;

        const isGridSizeMedium =
            this.context === GridSize.Medium || this.context === GridSize.Small;

        const isEmployeeSearchTargetActive =
            searchTarget === Store.SearchTarget.employee;
        const isEmployeeCurrentActiveTab =
            currentActiveTab === Store.SearchTarget.employee;

        return (
            <div className={classNames(styles.search)}>
                <SearchLocationObserver />
                <div className={styles.searchQueryHelpers}>
                    <QueryTooLong />
                    <QueryCorrection />
                </div>
                <div className={styles.searchDocumentsWidgets}>
                    <Row gutter={30}>
                        <Col
                            className={styles.searchFiltersCol}
                            lg={{ span: 6, push: 18 }}
                            md={{ span: 24, push: 0 }}
                        >
                            {!isEmployeeSearchTargetActive &&
                                !isEmployeeCurrentActiveTab && (
                                    <>
                                        <Definitions />
                                        {isGridSizeMedium && (
                                            <>
                                                <Services
                                                    className={
                                                        styles.searchServices
                                                    }
                                                />
                                                <PersonsWidget
                                                    className={
                                                        styles.searchPersonsWidget
                                                    }
                                                    onPersonClick={
                                                        this.onPersonClick
                                                    }
                                                />
                                            </>
                                        )}
                                        <ConceptsPanel />
                                    </>
                                )}
                            <FiltersSidePanel />
                        </Col>
                        <Col
                            lg={{ span: 18, pull: 6 }}
                            md={{ span: 24, pull: 0 }}
                        >
                            {!isEmployeeSearchTargetActive &&
                                !isGridSizeMedium &&
                                !isEmployeeCurrentActiveTab && (
                                    <>
                                        <Services
                                            className={styles.searchServices}
                                        />
                                        <PersonsWidget
                                            className={
                                                styles.searchPersonsWidget
                                            }
                                            onPersonClick={this.onPersonClick}
                                        />
                                    </>
                                )}
                            {isEmployeeSearchTargetActive &&
                            isEmployeeCurrentActiveTab ? (
                                <SearchResultUsers
                                    focusSearchQueryInput={
                                        this.focusSearchFieldInput
                                    }
                                    scrollTo={scrollToPerson}
                                    resetScroll={this.resetScroll}
                                    onPersonClick={this.onPersonClick}
                                />
                            ) : (
                                <SearchResultDocuments
                                    focusSearchQueryInput={
                                        this.focusSearchFieldInput
                                    }
                                />
                            )}
                        </Col>
                    </Row>
                </div>
                <ScrollToTop />
            </div>
        );
    }
}

const mapStateToProps = (state: Store.IState): ISearchStateProps => ({
    searchTarget: getSearchTarget(
        state.mainSearchPageActiveTabForResults,
        state
    ),
    searchQuery: state.search.searchQuery,
    lastSearchQuery: state.search.lastExecutedSearchQuery,
    lastSearchFilters: state.search.lastExecutedFilters,
    globalSearchPage:
        Math.floor(state.search.paging.skip / state.search.paging.limit) + 1,
    currentActiveTab: getSearchTarget(state.mainSearchPageActiveTab, state),
});

export default connect(mapStateToProps)(Search);
