/**
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 15.01.2018
 * -----
 * Last Modified: 20.02.2018 12:50:19
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import * as React from 'react';

import connectWithContext, {
    IInjectedProps,
} from 'app/redux/context/connectWithContext';
import {
    SearchDocumentName,
    documentState,
} from 'app/redux/actions/interfaces/DocumentEnum';
import DocumentPanel from 'app/components/document/DocumentPanel';
import MyDocumentPanel from 'app/components/common/UploadPanel/MyDocumentPanel';
import * as Store from 'app/redux/store/StoreNamespace';
import DocumentSnippet from 'app/components/search-snippets/DocumentSnippet';
import IDocumentPanelOwnProps from 'app/components/document/DocumentPanel/interfaces/IDocumentPanelOwnProps';
import { IDocumentPanelStateProps } from 'app/components/document/DocumentPanel/interfaces/IDocumentPanelProps';

import mapActionToProps from '../../../document/DocumentPanelActionDefault';

const myDocumentState = documentState(SearchDocumentName.uploadDocument);

const DocumentFilterPanel = () => {
    return null;
};

const mapStateToFactoryProps = () => {
    const constProps = {
        renderFilterPanel: (): JSX.Element => {
            return (
                <>
                    <MyDocumentPanel path="/" />
                    <br />
                    <DocumentFilterPanel />
                </>
            );
        },
        checkRemoved: (doc) => !doc.favorite,
        renderSearchResult: (doc): JSX.Element => {
            return <DocumentSnippet document={doc} />;
        },
    };

    return (
        state: Store.IState
    ): Store.IDocumentResults &
        IInjectedProps &
        IDocumentPanelStateProps &
        IDocumentPanelOwnProps => {
        const { paging, results }: Store.ISearch = myDocumentState(state);
        const { currentUser, loading } = state;

        return {
            rootContext: SearchDocumentName.uploadDocument,
            currentUserId: currentUser.id,
            isLoading: loading.pendingRequests.search,
            limit: paging.limit,
            skip: paging.skip,
            ...results,
            ...constProps,
        };
    };
};

export default connectWithContext(
    mapStateToFactoryProps,
    mapActionToProps
)(DocumentPanel);
