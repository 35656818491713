/**
 * Created by Lkarmelo on 31.01.2018.
 */

const getPlaceHolderByUserNames = (
    firstName: string,
    lastName: string
): string => {
    let res = '';
    if (typeof firstName === 'string') {
        res += firstName.charAt(0);
    }
    if (typeof lastName === 'string') {
        res += lastName.charAt(0);
    }
    return res;
};

export default getPlaceHolderByUserNames;
