import React, { useState, useEffect } from 'react';

import { GridSizeMin, GridSize } from 'app/utils/constants';

import windowSizeContext from './windowSizeContext';

const GridSizeProvider: React.FC = ({ children }) => {
    const [currentWindowSize, setCurrentWindowSize] = useState<GridSize>(null);

    useEffect(() => {
        const eventListenerRemovers = [];

        const mediaQueries = new Map([
            [GridSize.Small, `(max-width: ${GridSizeMin.MediumMin - 1}px)`],
            [
                GridSize.Medium,
                `(min-width: ${GridSizeMin.MediumMin}px) and (max-width: ${
                    GridSizeMin.LargeMin - 1
                }px)`,
            ],
            [
                GridSize.Large,
                `(min-width: ${GridSizeMin.LargeMin}px) and (max-width: ${
                    GridSizeMin.ExtraLargeMin - 1
                }px)`,
            ],
            [
                GridSize.ExtraLarge,
                `(min-width: ${GridSizeMin.ExtraLargeMin}px)`,
            ],
        ]);

        Array.from(mediaQueries.entries()).forEach(([gridSize, mediaQuery]) => {
            const windowWidthMatch = window.matchMedia(mediaQuery);
            const onWindowWidthChange = (
                event: MediaQueryList | MediaQueryListEvent
            ) => event.matches && setCurrentWindowSize(gridSize);

            onWindowWidthChange(windowWidthMatch);

            windowWidthMatch.addListener(onWindowWidthChange);
            eventListenerRemovers.push(() =>
                windowWidthMatch.removeListener(onWindowWidthChange)
            );
        });

        return () =>
            eventListenerRemovers.forEach((removeListener) => removeListener());
    }, []);

    return (
        <windowSizeContext.Provider value={currentWindowSize}>
            {children}
        </windowSizeContext.Provider>
    );
};

export default GridSizeProvider;
