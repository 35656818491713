import React, { PureComponent, ChangeEvent } from 'react';
import classNames from 'classnames';
import '../Login.scss';
import queryString from 'qs';
import { Link } from 'react-router-dom';
import { connect, DispatchProp } from 'react-redux';
import { Action } from 'redux';
import autoBind from 'autobind-decorator';

import { passwordRecovery } from 'app/redux/actions/authorization';
import clientRoutes from 'app/routing/clientRoutes';
import { parseOptions } from 'app/utils/queryStringOptions';
import * as Store from 'app/redux/store/StoreNamespace';

interface IForgetModalProps extends DispatchProp<Action> {
    error: string;
}

interface IForgetModalState {
    email?: string;
}

@autoBind
class RecoveryPassword extends PureComponent<
    IForgetModalProps,
    IForgetModalState
> {
    state: IForgetModalState = {
        email: '',
    };

    constructor(props: IForgetModalProps) {
        super(props);

        const { email } = queryString.parse(location.search, parseOptions);

        if (email) this.state = { email: email as string };
    }

    onPasswordRecovery(): void {
        const { email } = this.state;

        this.props.dispatch(passwordRecovery(email));
    }

    onLoginChange(e: ChangeEvent<HTMLInputElement>): void {
        this.setState({ email: e.currentTarget.value });
    }

    render(): JSX.Element {
        const { email } = this.state;
        const { error } = this.props;

        return (
            <div
                className={classNames('login', {
                    'recovery_password--failed': error,
                })}
            >
                <div className="recovery_password__content">
                    <div className="login__logo" />
                    <div className="recovery_password__header">
                        Забыли пароль?
                    </div>
                    <div className="recovery_password__header_description">
                        Мы отправим вам инструкцию, как его восстановить.
                    </div>

                    {error && (
                        <div className="recovery_password__fail-message">
                            {error}
                        </div>
                    )}

                    <div className="recovery_password__name">
                        <input
                            autoFocus
                            placeholder="Эл. почта"
                            value={email}
                            onChange={this.onLoginChange}
                        />
                    </div>

                    <button
                        onClick={this.onPasswordRecovery}
                        className="recovery_password__submit btn-primary"
                    >
                        Восстановить пароль
                    </button>

                    <Link
                        className="recovery_password__login-url"
                        to={clientRoutes.login.getUrl()}
                    >
                        Вернуться на страницу входа
                    </Link>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: Store.IState) => ({
    error: state.passwordRecovery.error,
});

export default connect(mapStateToProps)(RecoveryPassword);
