import * as React from 'react';
import { connect } from 'react-redux';
import autoBind from 'autobind-decorator';
import moment from 'moment';

import AbstractEditablePanelContainer from 'app/components/common/forms/AbstractEditablePanelContainer';
import Form from 'app/components/common/forms/Form';
import {
    initForm,
    removeForm,
    setFormStatus,
    sendForm,
} from 'app/redux/actions/forms';
import { FormName } from 'app/api/FormName';
import * as Store from 'app/redux/store/StoreNamespace';
import { FULL_DATE_TIME_FORMAT } from 'app/utils/constants';

import IFormData from './interfaces/IFormData';
import IProps, {
    IPersonalInfoOwnProps as IOwnProps,
} from './interfaces/IPersonalInfoProps';
import IState from './interfaces/IPersonalInfoState';

import PersonalInfoPresentational from '../presentational/PersonalInfo';

@autoBind
class PersonalInfo extends AbstractEditablePanelContainer<IProps, IState> {
    static defaultProps = {
        email: [],
        phone: [],
    };

    state = {
        isEditable: false,
        isFormValidationFailedOnSave: false,
    };

    formRef: React.RefObject<typeof Form.prototype> = React.createRef();

    render(): JSX.Element {
        const { email, birthDate, login, phone } = this.props;
        return (
            <PersonalInfoPresentational
                email={email}
                birthDate={birthDate}
                login={login}
                phone={phone}
            />
        );
    }

    componentDidMount() {
        this.props.onUpdate && this.props.onUpdate(this.state.isEditable);
    }

    componentDidUpdate() {
        this.props.onUpdate && this.props.onUpdate(this.state.isEditable);
    }

    onStartEdit() {
        const { formName, email, dispatch, birthDate, phone, onEditStart } =
            this.props;
        const formData = {
            birthDate: birthDate
                ? moment(birthDate, FULL_DATE_TIME_FORMAT).valueOf()
                : undefined,
            phone,
            email,
        };

        onEditStart && onEditStart();

        dispatch(initForm(formName, formData));
    }

    onSave() {
        const { formData } = this.props;
        const { isFormValidationFailedOnSave } = this.state;
        if (!this.formRef.current.getIsFormValid(true)) {
            !isFormValidationFailedOnSave &&
                this.setState({ isFormValidationFailedOnSave: true });
            return;
        }
        isFormValidationFailedOnSave &&
            this.setState({ isFormValidationFailedOnSave: false });
        const data = {
            birthDate: formData.birthDate,
            phone: formData.phone
                ? formData.phone.map((p) => p.trim())
                : undefined,
            email: formData.email
                ? formData.email.map((e) => e.trim())
                : undefined,
        };
        this.props.dispatch(sendForm(this.props.formName, data));
    }

    onCancelEdit() {
        this.state.isFormValidationFailedOnSave &&
            this.setState({ isFormValidationFailedOnSave: false });
        this.props.dispatch(removeForm(this.props.formName));
    }

    onValidationFail() {
        const { formStatus, dispatch, formName } = this.props;
        if (formStatus !== Store.FormStatus.Invalid) {
            dispatch(setFormStatus(formName, Store.FormStatus.Invalid));
        }
    }

    onValidationSuccess() {
        const { formStatus, dispatch, formName } = this.props;
        if (formStatus !== Store.FormStatus.Editing) {
            dispatch(setFormStatus(formName, Store.FormStatus.Editing));
        }
    }
}

const mapStateToProps = (state: Store.IState, props: IOwnProps): IProps => {
    const formName = FormName.PersonalInfo;
    // @ts-ignore
    return {
        birthDate: state.currentUser.birthDate,
        email: state.currentUser.email,
        phone: state.currentUser.phone,
        login: state.currentUser.login,
        formData:
            state.forms[formName] && (state.forms[formName].data as IFormData),
        formStatus: state.forms[formName] && state.forms[formName].status,
        formResponse: state.forms[formName] && state.forms[formName].response,
        formName,
        onUpdate: props.onUpdate,
        onEditStart: props.onEditStart,
        isEditable: props.isEditable,
        hideChangePassword: props.hideChangePassword,
    };
};

export default connect(mapStateToProps)(PersonalInfo);
