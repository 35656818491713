/**
 * Created by lkarmelo on 24.08.2018.
 */

import * as Store from 'app/redux/store/StoreNamespace';

const documentStatusToString: Map<Store.DocumentEducationStatus, string> =
    new Map<Store.DocumentEducationStatus, string>([
        [Store.EducationMaterialStatusValue.Done, 'Пройдено'],
        [Store.EducationMaterialStatusValue.Planning, 'В плане'],
        [Store.EducationMaterialStatusValue.Active, 'В процессе'],
    ]);

export const getReadableStringFromDocumentStatus = (
    status: Store.DocumentEducationStatus
): string => documentStatusToString.get(status);
