/**
 * Created by Lkarmelo on 08.08.2018.
 */

import React, { PureComponent } from 'react';

export interface IBodyClassSetterProps {
    setBodyClass?(cls: string): void;
    removeBodyClass?(cls: string): void;
    toggleBodyClass?(cls: string): void;
    getBodyContainsClass?(cls: string): boolean;
}

export default <T extends IBodyClassSetterProps>(
    WrappedComponent: React.ComponentType<T>
): React.ComponentType<T> =>
    class BodyClassController extends PureComponent<T> {
        static setClass(cls: string) {
            document.body.classList.add(cls);
        }

        static removeClass(cls: string): void {
            document.body.classList.remove(cls);
        }

        static toggleClass(cls: string): void {
            document.body.classList.toggle(cls);
        }

        static containsClass(cls: string): boolean {
            return document.body.classList.contains(cls);
        }

        render(): JSX.Element {
            return (
                <WrappedComponent
                    {...this.props}
                    setBodyClass={BodyClassController.setClass}
                    removeBodyClass={BodyClassController.removeClass}
                    toggleBodyClass={BodyClassController.toggleClass}
                    getBodyContainsClass={BodyClassController.containsClass}
                />
            );
        }
    };
