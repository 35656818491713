/**
 * Created by Lkarmelo on 24.11.2017.
 */

import React, { PureComponent } from 'react';

import IProps from './interfaces/IPersonalInfoProps';

import './WorkInfo.scss';

export default class WorkInfo extends PureComponent<IProps> {
    render(): JSX.Element {
        const {
            organization,
            department,
            workPhone,
            workAddress,
            room,
            workFunction,
        } = this.props;
        return (
            <article className="work-info">
                {organization && (
                    <div className="work-info__field clearfix">
                        <div className="work-info__title">Организация</div>
                        <div className="work-info__value">{organization}</div>
                    </div>
                )}
                {department && (
                    <div className="work-info__field clearfix">
                        <div className="work-info__title">Подразделение</div>
                        <div className="work-info__value">
                            <div>{department}</div>
                        </div>
                    </div>
                )}
                {workFunction && (
                    <div className="work-info__field clearfix">
                        <div className="work-info__title">Функция</div>
                        <div className="work-info__value">
                            <div>{workFunction}</div>
                        </div>
                    </div>
                )}
                {Array.isArray(workPhone) && workPhone.length > 0 && (
                    <div className="work-info__field clearfix">
                        <div className="work-info__title">Рабочий телефон</div>
                        {workPhone.map((ph) => (
                            <div key={ph} className="work-info__value">
                                <a href={`tel:${ph}`}>{ph}</a>
                            </div>
                        ))}
                    </div>
                )}
                {(workAddress || room) && (
                    <div className="work-info__field clearfix">
                        <div className="work-info__title">Адрес работы</div>
                        <div className="work-info__value">
                            {workAddress && workAddress}
                            {room && `${workAddress ? ', ' : ''}${room}`}
                        </div>
                    </div>
                )}
            </article>
        );
    }
}
