/**
 * Created by Lkarmelo on 21.11.2017.
 */

import { createAction } from 'redux-actions';

import * as Store from '../store/StoreNamespace';

export const FETCH_COMPETENCIES = 'FETCH_COMPETENCIES';
export const fetchCompetencies = createAction(FETCH_COMPETENCIES);

export const SET_COMPETENCIES = 'SET_COMPETENCIES';
export const setCompetencies =
    createAction<Store.ISimpleCatalogItem[]>(SET_COMPETENCIES);
