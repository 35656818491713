import { createAction } from 'redux-actions';

import * as Store from 'app/redux/store/StoreNamespace';

export const setStatusFilter =
    createAction<Store.EducationMaterialStatusValue>('SET_STATUS_FILTER');

export const fetchNumberOfEducationMaterials = createAction(
    'FETCH_NUMBER_OF_EDUCATION_MATERIALS'
);

export const setNumberOfEducationMaterials =
    createAction<Store.INumberOfEducationMaterials>(
        'SET_NUMBER_OF_EDUCATION_MATERIALS'
    );

export interface IRequestEducationMaterialStatusChange {
    id: string;
    status: Store.DocumentEducationStatus;
}
export const requestEducationMaterialStatusChange = createAction<
    IRequestEducationMaterialStatusChange,
    string,
    Store.DocumentEducationStatus
>(
    'REQUEST_STATUS_CHANGE',
    (id: string, status: Store.DocumentEducationStatus) => ({
        id,
        status,
    })
);

export interface ISetEducationMaterialStatusPayload {
    id: string;
    status: Store.DocumentEducationStatus;
}
export const setEducationMaterialStatus = createAction<
    ISetEducationMaterialStatusPayload,
    string,
    Store.DocumentEducationStatus
>(
    'SET_EDUCATION_MATERIAL_STATUS',
    (id: string, status: Store.DocumentEducationStatus) => ({
        id,
        status,
    })
);

export const requestEducationMaterialStatusReset = createAction<string>(
    'REQUEST_EDUCATION_MATERIAL_STATUS_RESET'
);

export interface IUpdateEducationMaterialStatusPayload {
    id: string;
    status: Store.DocumentEducationStatus;
}
export const updateEducationStatus = createAction<
    IUpdateEducationMaterialStatusPayload,
    string,
    Store.DocumentEducationStatus
>(
    'UPDATE_EDUCATION_MATERIAL_STATUS',
    (id: string, status: Store.DocumentEducationStatus) => ({
        id,
        status,
    })
);

export const refuseEducationMaterialRecommendations = createAction<string>(
    'REFUSE_EDUCATION_MATERIAL_RECOMMENDATIONS'
);

export const REDIRECT_TO_DOCUMENT = 'REDIRECT_TO_DOCUMENT';
export const redirectToDocument =
    createAction<Store.ISimpleCatalogItem>(REDIRECT_TO_DOCUMENT);
