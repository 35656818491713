/**
 * Created by Lkarmelo on 04.12.2017.
 */

import React, { PureComponent } from 'react';
import autoBind from 'autobind-decorator';
import classNames from 'classnames';
import { createSelector } from 'reselect';

import * as Store from 'app/redux/store/StoreNamespace';
import { IOption } from 'app/components/common/controls/Option';
import { COMPETENCIES_GRADE_SCALE } from 'app/utils/constants';

import IState from './interfaces/ICompetenciesState';
import IProps from './interfaces/ICompetenciesProps';

import CompetenceRow from '../CompetenceRow/CompetenceRow';

import './Competencies.scss';

const competenciesToOptions = createSelector(
    (props: IProps): Store.ISimpleCatalogItem[] => props.allCompetencies,
    (props: IProps): Store.IUserCompetence[] => props.competencies,
    (
        allCompetencies: Store.ISimpleCatalogItem[],
        competencies: Store.IUserCompetence[]
    ): IOption[] =>
        allCompetencies
            ? allCompetencies
                  .map((com, index) => ({
                      value: com.id,
                      label: com.title,
                      meta: { competenceIndex: index },
                  }))
                  .filter(
                      (opt: IOption) =>
                          !(
                              competencies.findIndex(
                                  (c) => opt.value === c.competenceItem.id
                              ) >= 0
                          )
                  )
                  .sort((optA: IOption, optB: IOption) =>
                      optA.label > optB.label ? 1 : -1
                  )
            : []
);

@autoBind
export default class Competencies extends PureComponent<IProps, IState> {
    static defaultProps = {
        gradeScale: COMPETENCIES_GRADE_SCALE,
    };

    state: IState = {
        maxTableRows: 5,
    };

    render(): JSX.Element {
        const { targetPost, gradeScale, isEditable, competencies } = this.props;
        if ((!competencies || competencies.length === 0) && !isEditable) {
            return null;
        }
        return (
            <div
                className={classNames('competencies', {
                    'competencies--editable': isEditable,
                })}
            >
                {targetPost && (
                    <div className="competencies__target-post">
                        <span className="competencies__target-post-title">
                            Целевая должность
                        </span>
                        <span className="competencies__target-post-name">
                            {targetPost}
                        </span>
                    </div>
                )}
                <div className="competencies__table">
                    <table>
                        <thead>
                            <tr>
                                <th
                                    className="competencies__table-header-title"
                                    rowSpan={2}
                                >
                                    Компетенция
                                </th>
                                <th
                                    className="competencies__table-header-scale-title"
                                    colSpan={gradeScale}
                                >
                                    <div>
                                        Уровень компетенций сотрудника, 0 –{' '}
                                        {gradeScale} баллов
                                    </div>
                                </th>
                                <th className="competencies__table-header-delete-btn-space" />
                            </tr>
                            <tr className="competencies__table-scale-grades">
                                {this.renderScaleHeader()}
                            </tr>
                        </thead>
                        <tbody>{this.renderCompetencies()}</tbody>
                    </table>
                </div>
                {this.state.maxTableRows === Number.MAX_VALUE && !isEditable && (
                    <button
                        onClick={this.showPartOfCompetencies}
                        className="competencies__hide-btn btn"
                    >
                        Показать часть
                    </button>
                )}
                {this.state.maxTableRows !== Number.MAX_VALUE && !isEditable && (
                    <button
                        onClick={this.showAllCompetencies}
                        className="competencies__show-btn btn"
                    >
                        Показать всё
                    </button>
                )}
                {isEditable && (
                    <button
                        onClick={this.addCompetence}
                        className="competencies__add-btn btn"
                    >
                        Добавить компетенцию
                    </button>
                )}
            </div>
        );
    }

    renderScaleHeader(): JSX.Element[] {
        const { gradeScale } = this.props;
        const ths = [];

        for (let i = 0; i <= gradeScale; i++) {
            ths.push(
                <th key={i}>
                    <span className="competencies">{i}</span>
                </th>
            );
        }

        return ths;
    }

    renderCompetencies(): JSX.Element[] {
        const { competencies, gradeScale, isEditable } = this.props;
        const trs = [];
        let maxRowsCount = competencies.length;

        if (this.state.maxTableRows < maxRowsCount && !isEditable) {
            maxRowsCount = this.state.maxTableRows;
        }

        for (let i = 0; i < maxRowsCount; i++) {
            const isCompetenceEditable =
                isEditable &&
                !competencies[i].requiredGrade &&
                !competencies[i].targetGrade;
            competencies[i] &&
                trs.push(
                    <CompetenceRow
                        key={i}
                        index={i}
                        competence={competencies[i]}
                        gradeScale={gradeScale}
                        onCompetenceClick={this.clickCompetence}
                        onRemove={this.removeCompetence}
                        onChangeGrade={this.changeCompetenceGrade}
                        onChangeCompetence={this.changeCompetence}
                        isEditable={isCompetenceEditable}
                        isGradeChangAble={isEditable}
                        isDeletable={isEditable}
                        isDeleteButtonDisabled={!isCompetenceEditable}
                        availableCompetencies={
                            isCompetenceEditable &&
                            competenciesToOptions(this.props)
                        }
                    />
                );
        }
        return trs;
    }

    showAllCompetencies(): void {
        this.setState({ maxTableRows: Number.MAX_VALUE });
    }

    showPartOfCompetencies(): void {
        this.setState({ maxTableRows: 5 });
    }

    clickCompetence(index: number): void {
        this.props.onClickCompetence(
            this.props.competencies[index].competenceItem
        );
    }

    removeCompetence(index: number): void {
        this.props.onRemoveCompetence(index);
    }

    changeCompetenceGrade(index: number, grade: number): void {
        this.props.onChangeGrade(index, grade);
    }

    changeCompetence(index: number, meta: { competenceIndex: number }): void {
        this.props.onChangeCompetence(
            index,
            this.props.allCompetencies[meta.competenceIndex]
        );
    }

    addCompetence(): void {
        const { onAddCompetence, allCompetencies } = this.props;
        if (allCompetencies && allCompetencies.length > 0)
            onAddCompetence(allCompetencies[0]);
    }
}
