/**
 * Created by Lkarmelo on 22.05.2018.
 */

import React, { PureComponent } from 'react';
import { NavLink, Link } from 'react-router-dom';

import clientRoutes from 'app/routing/clientRoutes';

import * as styles from './AddedDocuments.scss';

export default class AddedDocuments extends PureComponent {
    render(): JSX.Element {
        const allDocumentsUrl =
            clientRoutes.documentManagement.subRoutes.all.getUrl();
        const recommendationsUrl =
            clientRoutes.documentManagement.subRoutes.recommendations.getUrl();
        return (
            <section className={styles.addedDocuments}>
                <div className={styles.addedDocumentsHeader}>
                    <nav className={`${styles.addedDocumentsNav} clearfix`}>
                        <NavLink
                            to={clientRoutes.documentManagement.subRoutes.own.getUrl()}
                        >
                            Добавленные мной
                        </NavLink>
                        <NavLink
                            to={allDocumentsUrl}
                            isActive={(match, location) =>
                                location.pathname === allDocumentsUrl
                            }
                        >
                            Все материалы
                        </NavLink>
                        <NavLink to={recommendationsUrl}>
                            Исходящие рекомендации
                        </NavLink>
                        <Link
                            to={clientRoutes.documentManagement.subRoutes.add.getUrl()}
                            className={`${styles.addedDocumentsAddDocument}`}
                        >
                            <span />
                            Добавить документ
                        </Link>
                    </nav>
                </div>
                <article className={styles.addedDocumentsContent}>
                    {this.props.children}
                </article>
            </section>
        );
    }
}
