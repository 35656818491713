/**
 * Created by lkarmelo on 05.12.2019.
 */

import { connect } from 'react-redux';

import { sendForm, setFormStatus } from 'app/redux/actions/forms';
import * as Store from 'app/redux/store/StoreNamespace';
import { FormName } from 'app/api/FormName';

import AddEditCategory from './AddEditCategory';

import Api from '../../../api/Api';

import {
    IAddEditCategoryActionProps,
    IAddEditCategoryOwnProps,
    IAddEditCategoryStateProps,
    EditCategoryFormBody,
} from '.';

const mapStateToProps = (
    state: Store.IState,
    own: IAddEditCategoryOwnProps
): IAddEditCategoryStateProps => {
    const form = own.editingCategoryId
        ? state.forms[FormName.CatalogueEditCategory]
        : state.forms[FormName.CatalogueAddCategory];
    return {
        formStatus: form && form.status,
    };
};

const mapDispatchToProps = (
    dispatch,
    { editingCategoryId }: IAddEditCategoryOwnProps
): IAddEditCategoryActionProps => ({
    resetForm() {
        if (editingCategoryId) {
            dispatch(setFormStatus(FormName.CatalogueEditCategory, null));
        } else {
            dispatch(setFormStatus(FormName.CatalogueAddCategory, null));
        }
    },
    sendForm(values: Api.ICatalogueCategoryOperation): void {
        if (editingCategoryId) {
            dispatch(
                sendForm(FormName.CatalogueEditCategory, {
                    ...values,
                    editingCategoryId,
                } as EditCategoryFormBody)
            );
        } else {
            dispatch(
                sendForm(FormName.CatalogueAddCategory, {
                    ...values,
                    id: Date.now().toString(),
                })
            );
        }
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCategory);
