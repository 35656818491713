/**
 * Created by Lkarmelo on 01.02.2018.
 */

import React, { PureComponent } from 'react';
import autoBind from 'autobind-decorator';

import IProps from './interfaces/IDropDownMenuProps';
import IState from './interfaces/IDropDownMenuState';

import { SlideTransition } from '../utils/CSSTransitions';

@autoBind
export default class DropDownMenu extends PureComponent<IProps, IState> {
    state: IState = {
        isMenuOpened: false,
    };

    isDropDownClicked = false;

    componentDidUpdate(prevProps: Readonly<IProps>): void {
        if (prevProps.isDropdownVisible !== this.props.isDropdownVisible) {
            this.toggleDropDown(this.props.isDropdownVisible);
        }
    }

    render(): JSX.Element {
        const { className, dropDownContentRenderer, toggleElement } =
            this.props;
        return (
            <div
                className={className}
                onClick={() => this.toggleDropDown()}
                onMouseDown={this.setDropDownClicked}
                onMouseUp={this.setDropDownNotClicked}
            >
                {toggleElement}
                <SlideTransition in={this.state.isMenuOpened}>
                    <div
                        className={`${className}__drop-down`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {dropDownContentRenderer(this.toggleDropDown)}
                    </div>
                </SlideTransition>
            </div>
        );
    }

    componentDidMount(): void {
        document.addEventListener('mousedown', this.closeIfNotClicked);
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.closeIfNotClicked);
    }

    close(): void {
        this.setState({ isMenuOpened: false });
        this.props.onClose && this.props.onClose();
    }

    closeIfNotClicked(): void {
        if (!this.isDropDownClicked && this.state.isMenuOpened) {
            this.close();
        }
    }

    toggleDropDown(isMenuOpened = !this.state.isMenuOpened): void {
        this.setState({ isMenuOpened });
        if (!this.state.isMenuOpened) {
            this.props.onOpen && this.props.onOpen();
        } else {
            this.props.onClose && this.props.onClose();
        }
    }

    setDropDownClicked(): void {
        this.isDropDownClicked = true;
    }

    setDropDownNotClicked(): void {
        this.isDropDownClicked = false;
    }
}
