import { MiddlewareAPI } from 'redux';
import { Observable } from 'rxjs/Observable';
import { concat } from 'rxjs/observable/concat';
import notification from 'antd/es/notification';
import queryString from 'qs';
import { Action } from 'redux-actions';

import clientRoutes from 'app/routing/clientRoutes';
import history from 'app/history';
import { parseOptions } from 'app/utils/queryStringOptions';
import { clearUserPermissions } from 'app/redux/actions/user';

import { createRetryOnIEAuthProblem } from './utils/createRetryOnIEAuthProblem';

import {
    logOutOnUnAuthActionCreators,
    logInRequest,
    logInResolve,
    logInReject,
} from '../actions/loading';
import {
    setAuthorized,
    setLoginURL,
    LOG_IN,
    LOG_OUT,
    LOG_IN_SUCCESS,
    logInSuccess,
    logInFail,
    PASSWORD_RECOVERY,
    RESET_PASSWORD,
    resetPasswordFail,
    recoveryPasswordFail,
} from '../actions/authorization';
import * as Store from '../store/StoreNamespace';

export const logoutOnUnauthorizedError = (
    action$,
    store: MiddlewareAPI<Store.IState>
) =>
    action$
        .ofType(...logOutOnUnAuthActionCreators.map((a) => a.toString()))
        .filter((action) => action.error && action.payload.status === 401)
        .mergeMap((action) => {
            let loginUrl = action.payload.response.loginUrl || clientRoutes.login.getUrl();
            return concat(
                Observable.of(setLoginURL(loginUrl)),
                Observable.of(setAuthorized(false), clearUserPermissions())
            )
        });

export const logIn = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }
) =>
    action$.ofType(LOG_IN).mergeMap((action) =>
        concat(
            apiCall
                .logIn(
                    action.payload.identifier,
                    action.payload.password,
                    action.payload.rememberMe
                )
                .map((response) => {
                    if (response.response.token) {
                        return setAuthorized(true);
                    }
                    throw new Error('No auth token');
                })
                .catch((e) => {
                    console.error(e);
                    if (e.status === undefined || e.status === 401) {
                        return Observable.of(logInFail(), logInReject());
                    }
                    return Observable.of(logInReject(e));
                }),
            Observable.of(logInSuccess(), logInResolve())
        )
            .takeUntil(action$.ofType(logInReject.toString()))
            .startWith(logInRequest())
    );

const openNotificationWithIcon = ({ type, message, description }) => {
    notification[type]({ message, description });
};

export const passwordRecovery = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }
) =>
    action$.ofType(PASSWORD_RECOVERY).mergeMap((action: Action<string>) =>
        apiCall
            .forgotPassword(action.payload)
            .retryWhen(createRetryOnIEAuthProblem())
            .do((response) => {
                history.push(clientRoutes.login.getUrl());

                // TODO Уведомления должны быть выделены в отдельный блок и привязаны к интерфейсу
                // Необходимо заменить на вызовы redux и перенести текст уведомлений в state
                openNotificationWithIcon({
                    type: 'success',
                    message: 'Восстановление пароля',
                    description: `Уведомление с инструкциями отправлено на адрес электронной почты ${action.payload}`,
                });
            })
            .ignoreElements()
            .catch((e) => {
                console.error(e);
                const { response } = e;

                return Observable.of(
                    recoveryPasswordFail(response && response.msg)
                );
            })
    );

export const resetPassword = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }
) =>
    action$.ofType(RESET_PASSWORD).mergeMap((action) =>
        apiCall
            .resetPassword(action.payload.token, action.payload.password)
            .retryWhen(createRetryOnIEAuthProblem())
            .do((response) => {
                history.push(clientRoutes.login.getUrl());
            })
            .ignoreElements()
            .catch((e) => {
                console.error(e);
                return Observable.of(resetPasswordFail(e.response.status));
            })
    );

export const redirectToPreviousPageOnLoginSuccess = (
    action$,
    store: MiddlewareAPI<Store.IState>
) =>
    action$
        .ofType(LOG_IN_SUCCESS)
        .do(() => {
            const params = queryString.parse(location.search, parseOptions);

            const newUrl = params.backUrl
                ? decodeURIComponent(params.backUrl as string)
                : clientRoutes.main.getUrl();

            if (newUrl.startsWith('/admin')) {
                // use native redirect from React to external link
                window.location.href = newUrl;
            } else {
                history.push(newUrl);
            }
        })
        .ignoreElements();

export const logOut = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }
) =>
    action$
        .ofType(LOG_OUT)
        .filter(() => store.getState().authorization.isAuthorized)
        .mergeMap(() =>
            apiCall
                .logOut()
                .do(() => history.push(clientRoutes.login.getUrl()))
                .mergeMap(() =>
                    Observable.of(setAuthorized(false), clearUserPermissions())
                )
                .catch((e) => {
                    console.error(e);
                    return Observable.of({}).ignoreElements();
                })
                .takeUntil(action$.ofType(LOG_OUT))
        );
