/**
 * Created by lkarmelo on 28.09.2018.
 */

import * as Store from 'app/redux/store/StoreNamespace';

type PersonWithLink = {
    link?: string;
    source?: string;
} & Store.IDocumentPerson;

export const getLoginWithSourceFromLogins = (
    logins: Store.ISourceToLogin[]
): Store.ISourceToLogin => {
    if (!logins || logins.length === 0) {
        return undefined;
    }
    const loginWithoutSource = logins.find(
        (lS) => lS.login !== undefined && lS.source === undefined
    );

    return loginWithoutSource || logins[0];
};

export const getPersonsLoginsByProfiles = (
    persons: Store.IDocumentPerson[],
    profiles: Store.IProfileLink[]
): { persons: { [id: string]: PersonWithLink }; ids: string[] } => {
    const res = {
        persons: {},
        ids: [],
    };

    if (Array.isArray(persons)) {
        persons.forEach((person) => {
            const profile =
                Array.isArray(profiles) &&
                profiles.find((prof) => prof.login === person.id);

            const personId = person.id || person.fullName;

            res.ids.push(personId);

            if (
                !profile ||
                !profile.loginSource ||
                profile.loginSource.length === 0
            ) {
                res.persons[personId] = person;
                return;
            }
            const loginWithoutSource = getLoginWithSourceFromLogins(
                profile.loginSource
            );
            res.persons[personId] = {
                ...person,
                link: loginWithoutSource
                    ? loginWithoutSource.login
                    : profile.loginSource[0].login,
                source: loginWithoutSource
                    ? loginWithoutSource.source
                    : profile.loginSource[0].source,
            };
        });
    }
    return res;
};

export const getParametersForLinkToProfileFromIdAndLogins = (
    id: string,
    logins: Store.ISourceToLogin[]
): { id: string; user: string; source: string } => {
    const loginSource = getLoginWithSourceFromLogins(logins);
    return {
        user: loginSource ? loginSource.login : undefined,
        source: loginSource ? loginSource.source : undefined,
        id: loginSource ? undefined : id,
    };
};
