/**
 * Created by Lkarmelo on 29.05.2018.
 */

import {
    fetchEducationMaterialTypes,
    setEducationMaterialTypes,
} from 'app/redux/actions/educationMaterialTypes';
import {
    educationMaterialTypesRequest,
    educationMaterialTypesResolve,
    educationMaterialTypesReject,
} from 'app/redux/actions/loading';

import { createSimpleLoadingEpic } from './utils';

export const loadEducationMaterialTypes = createSimpleLoadingEpic({
    triggers: [fetchEducationMaterialTypes.toString()],
    apiCallName: 'educationMaterialTypes',
    actions: {
        requestAction: educationMaterialTypesRequest,
        resolveAction: educationMaterialTypesResolve,
        rejectAction: educationMaterialTypesReject,
        setAction: setEducationMaterialTypes,
    },
});
