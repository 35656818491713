/**
 * Created by lkarmelo on 11.12.2019.
 */

import React, { useEffect, useReducer } from 'react';

import IProps from './interfaces/INotificationsProps';
import * as styles from './Notifications.scss';

import Notification from '../../common/Notification';
import ScrollToTop from '../../common/utils/ScrollToTop';

const Notifications: React.FC<IProps> = (props) => {
    const { notifications, markAsRead, loadNotifications, loadLimit, total } =
        props;
    const [limit, increaseLimit] = useReducer(
        (_limit: number, add: number): number => _limit + add,
        loadLimit
    );

    useEffect(() => loadNotifications(limit), [limit]);

    return (
        <div className={styles.notifications}>
            {Array.isArray(notifications) &&
                notifications.map((notification) => (
                    <div
                        key={notification.id}
                        onMouseEnter={() =>
                            !notification.isRead &&
                            markAsRead([notification.id])
                        }
                        onClick={() =>
                            !notification.isRead &&
                            markAsRead([notification.id])
                        }
                    >
                        <Notification notification={notification} />
                    </div>
                ))}
            {total &&
                Array.isArray(notifications) &&
                notifications.length < total && (
                    <div className={styles.notificationsLoadMore}>
                        <button
                            className="btn"
                            onClick={() => increaseLimit(loadLimit)}
                        >
                            Показать еще
                        </button>
                    </div>
                )}
            <ScrollToTop />
        </div>
    );
};

Notifications.defaultProps = {
    loadLimit: 10,
};

export default Notifications;
