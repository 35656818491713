import * as React from 'react';

import * as Store from 'app/redux/store/StoreNamespace';

import { MessageType, IGetMessageTypeOptions } from '../types';
import * as styles from '../SearchResultsMessage.scss';

const EMPLOYEE_MESSAGES = {
    found: (
        <>
            Результаты поиска{' '}
            <span className={styles.searchResultsMessageBold}>сотрудников</span>
        </>
    ),
    notFoundBecauseFilters: 'Сотрудников с выбранными фильтрами не нашлось',
    notFoundBecauseQuery: 'Сотрудников по вашему запросу не нашлось',
    notFoundBecauseQueryAndFilters:
        'Сотрудников по вашему запросу с выбранными фильтрами не нашлось',
};

const DOCUMENT_MESSAGES = {
    found: (
        <>
            Результаты поиска
        </>
    ),
    notFoundBecauseFilters:
        'Результаты поиска и выбранных фильтров: 0',
    notFoundBecauseQuery:
        'Результаты поиска и поискового запроса: 0',
    notFoundBecauseQueryAndFilters:
        'Результаты поиска поискового запроса и фильтров: 0',
};

export const getMessageType = ({
    hasResults,
    hasFilters,
    hasSearchQuery,
}: IGetMessageTypeOptions): MessageType => {
    if (hasResults) {
        return 'found';
    }
    if (!hasFilters) {
        return 'notFoundBecauseQuery';
    }
    if (!hasSearchQuery && hasFilters) {
        return 'notFoundBecauseFilters';
    }
    if (hasSearchQuery && hasFilters) {
        return 'notFoundBecauseQueryAndFilters';
    }
};

export const getResultMessagesBySearchTarget = (target: Store.SearchTarget) =>
    target === Store.SearchTarget.employee
        ? EMPLOYEE_MESSAGES
        : DOCUMENT_MESSAGES;
