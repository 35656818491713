/**
 * Created by Lkarmelo on 02.10.2017.
 */

import { createAction } from 'redux-actions';

import * as Store from '../../store/StoreNamespace';

export const setQuery = createAction<string | null>('SET_QUERY');

export const setQueryExpansion = createAction<Store.IQueryTags | void>(
    'SET_QUERY_EXPANSION'
);
export const fetchQueryExpansion = createAction('FETCH_QUERY_EXPANSION');

export const toggleSelectedQueryTag = createAction<string>(
    'TOGGLE_SELECTED_QUERY_TAG'
);
export const setSelectedQueryTags = createAction<string[]>(
    'SET_SELECTED_QUERY_TAG'
);

export const setQueryExpansionMode = createAction<boolean>(
    'SET_QUERY_EXPANSION_MODE'
);

export const setLastExecutedSearchQuery = createAction<string | null>(
    'SET_LAST_EXECUTED_SEARCH_QUERY'
);

export const setExecutingSearchQuery = createAction<string | null>(
    'SET_EXECUTING_SEARCH_QUERY'
);
