import React from 'react';

import { HintsMoreDetailsLink } from 'app/utils/constants';

const HintText = () => (
    <p>Поддерживаемые операторы языка запросов:<br />"
        <span />
        ", -, +, AND / И, OR / ИЛИ, NOT / НЕ, ^, *, ().
        <span>
            &#160;
        </span>
        {/*<a href={HintsMoreDetailsLink} target='_blank' rel='noopener noreferrer'>
            Подробнее.
        </a>*/}
    </p>
);

export default HintText;
