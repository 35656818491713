export const getInputInnerWidth = (inputRef) => {
    const inputStyles = window.getComputedStyle(inputRef.current, null);

    const inputPaddingRight = parseFloat(inputStyles.getPropertyValue('padding-right'));
    const inputPaddingLeft = parseFloat(inputStyles.getPropertyValue('padding-left'));
    const inputBorderRight = parseFloat(inputStyles.getPropertyValue('border-right-width'));
    const inputBorderLeft = parseFloat(inputStyles.getPropertyValue('border-left-width'));

    return inputRef.current.clientWidth - (inputPaddingRight + inputPaddingLeft + inputBorderLeft + inputBorderRight);
};
