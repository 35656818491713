/**
 * Created by lkarmelo on 23.08.2018.
 */

export const getUserNameByUserObject = <
    T extends { firstName?: string; lastName?: string; middleName?: string }
>(
    user: T,
    addMiddleName = false
): string => {
    const { middleName, firstName, lastName } = user;
    return addMiddleName && typeof middleName === 'string'
        ? `${firstName} ${middleName} ${lastName}`
        : `${firstName} ${lastName}`;
};
