import { Action, handleAction } from 'redux-actions';

import { setStrictForPersons } from '../../actions/search/strict';
import initialState from '../../store/initialState';

export const personStrict = handleAction(
    setStrictForPersons.toString(),
    (_, { payload }: Action<boolean | null>) => payload,
    initialState.search.personStrict
);
