import React, { useCallback, useMemo, useRef } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { createSelector } from 'reselect';
import { RouteComponentProps, withRouter } from 'react-router';
import memoizeOne from 'memoize-one';

import {
    removeMultiSelectFilterValue,
    setDateRangeFrom,
    setDateRangeTo,
    setFilterValue,
} from 'app/redux/actions/search/filters';
import * as Store from 'app/redux/store/StoreNamespace';
import YearPeriod from 'app/components/common/tables/Filters/YearPeriod/YearPeriod';
import { Period } from 'app/components/common/tables/Filters/YearPeriod/interfaces/IYearPeriodState';
import { IDateFilterValue } from 'app/redux/store/StoreNamespace';

import * as styles from './ServerOnlyFiltersOptions.scss';

export const HIGHLIGHT_CHOSEN_FILTERS_EVENT = 'HIGHLIGHT_CHOSEN_FILTERS_EVENT';
export const UNHIGHLIGHT_CHOSEN_FILTERS_EVENT =
    'UNHIGHLIGHT_CHOSEN_FILTERS_EVENT';

type FilterProps = {
    filterName: string;
    filterLabel: string;
    value?: Store.IFilterValue;
    label: string;
    onDeselect(filterName: string, filterValue: Store.IFilterValue): void;
};

const Filter: React.FunctionComponent<FilterProps> = ({
    filterName,
    value,
    label,
    onDeselect,
    filterLabel,
}) => (
    <span className={styles.tagsPanelFilter} title={`${filterLabel}: ${label}`}>
        {`${filterLabel}: ${label}`}
        <button
            className={`${styles.tagsPanelFilterRemove} btn`}
            onClick={() => onDeselect(filterName, value)}
        />
    </span>
);

interface ITagsPanelStateProps {
    filters: Store.IFilters;
    filtersMeta: Store.IFiltersMeta;
    filterNamesToValuesToLabels: {
        [filterName: string]: { [code: string]: string };
    };
}

type TagsPanelProps = ITagsPanelStateProps &
    DispatchProp<any> &
    RouteComponentProps<{}>;

type YearFilterValue = {
    fromValue?: number;
    toValue?: number;
    isFromUndefined: boolean;
    isToUndefined: boolean;
};

const isYearFilterUndefinedOrValue = memoizeOne(
    (value: any): boolean | YearFilterValue => {
        const dateRangeValue = value as Store.IDateFilterValue;
        const fromValue = dateRangeValue.from;
        const toValue = dateRangeValue.to;

        const isFromUndefined = fromValue === undefined || fromValue === null;
        const isToUndefined = toValue === undefined || toValue === null;

        if (isFromUndefined && isToUndefined) {
            return false;
        }
        return {
            fromValue,
            toValue,
            isFromUndefined,
            isToUndefined,
        };
    }
);

const ServerOnlyFiltersOptions: React.FunctionComponent<TagsPanelProps> = (
    props
) => {
    const { filters, dispatch, filterNamesToValuesToLabels, filtersMeta } =
        props;
    const ref = useRef<HTMLDivElement>(null);

    const onRemoveTag = useCallback(
        (filterName: string, value?: Store.IFilterValue): void => {
            dispatch(setFilterValue(filterName, value));
        },
        []
    );

    const onRemoveMultiSelectTag = useCallback(
        (filterName: string, value: string) => {
            dispatch(removeMultiSelectFilterValue(filterName, value));
        },
        []
    );

    const onRemoveFromTag = useCallback(
        (filterName: string, value?: number): void => {
            dispatch(setDateRangeFrom(filterName, value));
        },
        []
    );

    const onRemoveToTag = useCallback(
        (filterName: string, value?: number): void => {
            dispatch(setDateRangeTo(filterName, value));
        },
        []
    );

    const onRemoveYearRangeTag = useCallback(
        (filterName: string, value: Store.IDateFilterValue): void => {
            dispatch(setFilterValue(filterName, value));
        },
        []
    );

    const hasFilters =
        filters &&
        Object.entries(filters).some(([filterName, { value }]) => {
            const filterMeta = filtersMeta[filterName];
            if (filterMeta) {
                const filterType = filterMeta.type;
                return (
                    filterType === Store.FilterType.Text ||
                    filterType === Store.FilterType.Search ||
                    filterType === Store.FilterType.Select ||
                    (filterType === Store.FilterType.CheckBox && !!value) ||
                    (filterType === Store.FilterType.MultiSelect &&
                        Array.isArray(value) &&
                        value.length > 0) ||
                    (filterType === Store.FilterType.YearRange &&
                        isYearFilterUndefinedOrValue(value) !== false)
                );
            }
            return false;
        });

    const orderedFiltersValues = useMemo<
        {
            value: Store.IFilterValue;
            filterName: string;
            valueSetTime: number;
        }[]
    >(() => {
        const valuesToOrder = [];
        const valuesWithoutTime = [];

        hasFilters &&
            Object.entries(filters).forEach(
                ([filterName, { value, valueSetTime }]) => {
                    if (Array.isArray(value)) {
                        value.forEach((v) => {
                            const valueToPush = {
                                value: v,
                                filterName,
                                valueSetTime:
                                    valueSetTime && valueSetTime[v]
                                        ? valueSetTime[v]
                                        : valueSetTime,
                            };
                            valueToPush.valueSetTime !== undefined
                                ? valuesToOrder.push(valueToPush)
                                : valuesWithoutTime.push(valueToPush);
                        });
                    } else {
                        const valueToPush = {
                            value,
                            filterName,
                            valueSetTime,
                        };
                        valueToPush.valueSetTime !== undefined
                            ? valuesToOrder.push(valueToPush)
                            : valuesWithoutTime.push(valueToPush);
                    }
                }
            );

        valuesToOrder.sort(
            (
                { valueSetTime: valueSetTimeA },
                { valueSetTime: valueSetTimeB }
            ) => {
                if (valueSetTimeA > valueSetTimeB) {
                    return 1;
                }
                if (valueSetTimeA < valueSetTimeB) {
                    return -1;
                }
                return 0;
            }
        );

        valuesWithoutTime.push(...valuesToOrder);
        return valuesWithoutTime;
    }, [filters]);

    if (!hasFilters) {
        return null;
    }

    return (
        <div ref={ref} className={styles.tagsPanel}>
            {orderedFiltersValues.map(({ filterName, value }) => {
                const filterMeta = filtersMeta[filterName];
                if (filterMeta) {
                    switch (filterMeta.type) {
                        // эти 2 не проверяла, т.к. на данный момент нет фильтров этих типов
                        case Store.FilterType.Text:
                        case Store.FilterType.Search: {
                            return (
                                <Filter
                                    key={filterName}
                                    filterName={filterName}
                                    value={filterMeta.default}
                                    label={value.toString()}
                                    filterLabel={filterMeta.title}
                                    onDeselect={onRemoveTag}
                                />
                            );
                        }
                        case Store.FilterType.MultiSelect: {
                            const filterValueLabels =
                                filterNamesToValuesToLabels[filterName];
                            if (Array.isArray(value)) {
                                return value.map((v) => (
                                    <Filter
                                        key={filterName + v}
                                        filterName={filterName}
                                        value={v}
                                        label={
                                            (filterValueLabels &&
                                                filterValueLabels[v]) ||
                                            v
                                        }
                                        filterLabel={filterMeta.title}
                                        onDeselect={onRemoveMultiSelectTag}
                                    />
                                ));
                                // в orderedFilters все значения мультиселекта будут отдельно, а не в массиве
                            }
                            if (typeof value === 'string') {
                                return (
                                    <Filter
                                        key={filterName + value}
                                        filterName={filterName}
                                        value={value}
                                        label={
                                            (filterValueLabels &&
                                                filterValueLabels[value]) ||
                                            value
                                        }
                                        filterLabel={filterMeta.title}
                                        onDeselect={onRemoveMultiSelectTag}
                                    />
                                );
                            }
                            return null;
                        }
                        // тоже не проверяла
                        case Store.FilterType.Select: {
                            const valueStr = value as string;
                            return (
                                <Filter
                                    key={filterName}
                                    filterName={filterName}
                                    value={filterMeta.default}
                                    label={
                                        filterNamesToValuesToLabels[
                                            filterName
                                        ] &&
                                        filterNamesToValuesToLabels[filterName][
                                            valueStr
                                        ]
                                    }
                                    filterLabel={filterMeta.title}
                                    onDeselect={onRemoveTag}
                                />
                            );
                        }
                        case Store.FilterType.YearRange: {
                            const yearValueOrFalse: boolean | YearFilterValue =
                                isYearFilterUndefinedOrValue(value);
                            // не выводим тэг, если не задан фильтр
                            if (yearValueOrFalse === false) {
                                return null;
                            }

                            const {
                                fromValue,
                                toValue,
                                isFromUndefined,
                                isToUndefined,
                            } = yearValueOrFalse as YearFilterValue;

                            // todo: передавать isFromUndefined, isToUndefined в YearPeriod.getPeriodByYears
                            const period = YearPeriod.getPeriodByYears(
                                fromValue,
                                toValue
                            );
                            // точный период - 2 тэга (с - по)
                            if (period === Period.Custom) {
                                const fromLabel = `c ${fromValue}`;
                                const toLabel = `по ${toValue}`;
                                let defaultFrom;
                                let defaultTo;
                                if (typeof filterMeta.default === 'object') {
                                    defaultFrom = (
                                        filterMeta.default as IDateFilterValue
                                    ).from;
                                    defaultTo = (
                                        filterMeta.default as IDateFilterValue
                                    ).to;
                                }
                                return [
                                    !isFromUndefined && (
                                        <Filter
                                            key={filterName + fromLabel}
                                            filterName={filterName}
                                            value={defaultFrom}
                                            label={fromLabel}
                                            filterLabel={filterMeta.title}
                                            onDeselect={onRemoveFromTag}
                                        />
                                    ),
                                    !isToUndefined && (
                                        <Filter
                                            key={filterName + toLabel}
                                            filterName={filterName}
                                            value={defaultTo}
                                            label={toLabel}
                                            filterLabel={filterMeta.title}
                                            onDeselect={onRemoveToTag}
                                        />
                                    ),
                                ];
                            }
                            const periodLabel = YearPeriod.options.find(
                                (opt) => opt.value === period
                            ).label;
                            return (
                                <Filter
                                    key={filterName + value.toString()}
                                    filterName={filterName}
                                    value={filterMeta.default}
                                    label={periodLabel}
                                    filterLabel={filterMeta.title}
                                    onDeselect={onRemoveYearRangeTag}
                                />
                            );
                        }
                        case Store.FilterType.CheckBox: {
                            // Выводим тэг, только если отмечен чекбокс
                            return value ? (
                                <Filter
                                    key={filterName}
                                    filterName={filterName}
                                    value={filterMeta.default}
                                    label="да"
                                    filterLabel={filterMeta.title}
                                    onDeselect={onRemoveTag}
                                />
                            ) : null;
                        }
                        // todo: реализовать, если появится
                        // case Store.FilterType.DateRange: {
                        // }
                        default: {
                            return null;
                        }
                    }
                }
                return null;
            })}
        </div>
    );
};

const filterCodesValues = createSelector(
    (state: Store.IState): Store.IFiltersMeta => state.filtersMeta,
    (
        filtersMeta: Store.IFiltersMeta
    ): { [filterName: string]: { [code: string]: string } } => {
        const namesToValuesToLabels = {};

        filtersMeta &&
            Object.entries(filtersMeta).forEach(([filterName, filterMeta]) => {
                namesToValuesToLabels[filterName] = {};
                Array.isArray(filterMeta.values) &&
                    filterMeta.values.forEach(({ value, label }) => {
                        namesToValuesToLabels[filterName][value] =
                            label || value;
                    });
            });

        return namesToValuesToLabels;
    }
);

const getHiddenFilters = memoizeOne(
    (filters: Store.IFilters, meta: Store.IFiltersMeta): Store.IFilters => {
        if (!filters || !meta) {
            return {};
        }

        return Object.entries(filters).reduce(
            (hiddenFilters, [filterName, filter]) => {
                if (meta[filterName] && meta[filterName].hidden) {
                    hiddenFilters[filterName] = filter;
                }
                return hiddenFilters;
            },
            {}
        );
    }
);

export default withRouter(
    connect((state: Store.IState) => ({
        filters: getHiddenFilters(state.search.filters, state.filtersMeta),
        filtersMeta: state.filtersMeta,
        filterNamesToValuesToLabels: filterCodesValues(state),
    }))(ServerOnlyFiltersOptions)
);
