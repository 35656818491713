/**
 * Created by lkarmelo on 23.07.2019.
 */

import { MiddlewareAPI } from 'redux';
import { merge } from 'rxjs/observable/merge';

import { setLimit, setSkip } from 'app/redux/actions/search/searchPaging';
import { compressRequestUpdateLocationAndSearch } from 'app/redux/actions/search/results';
import { setQuery } from 'app/redux/actions/search/searchQuery';
import { setPersonalization } from 'app/redux/actions/search/personalization';
import * as Store from 'app/redux/store/StoreNamespace';
import { setSearchActiveTab } from 'app/redux/actions/search/searchTabs';
import { ContextOrNonContextAction } from 'app/types/ContextOrNonContextAction';

import { getSearchState } from './utils/getSearchState';
import { filtersChangeActions } from './userSearchTriggerEpics';

export const resetSearchQueryToLastExecutedQuery = (
    action$,
    store: MiddlewareAPI<Store.IState>
) =>
    merge<ContextOrNonContextAction>(
        action$.ofType(
            ...filtersChangeActions,
            setSkip.toString(),
            setLimit.toString(),
            setPersonalization.toString()
        ),
        action$
            .ofType(setSearchActiveTab.toString())
            .filter(({ isNewSearch }) => !isNewSearch)
    )
        .hasContext(false)
        .ignoreSearchActionsByMeta()
        .filter(() => {
            const { lastExecutedSearchQuery, executingSearchQuery } =
                getSearchState(store);
            return (
                executingSearchQuery !== null ||
                lastExecutedSearchQuery !== null
            );
        })
        .switchMap(() =>
            // ждём все debounce'ы и прочее, меняем только когда начнётся загрузка запроса
            action$
                .ofType(compressRequestUpdateLocationAndSearch.toString())
                .hasContext(false)
                .take(1)
        )
        .map(() => {
            const { lastExecutedSearchQuery, executingSearchQuery } =
                getSearchState(store);
            return setQuery(
                executingSearchQuery !== null
                    ? executingSearchQuery
                    : lastExecutedSearchQuery
            );
        });
