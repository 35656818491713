/**
 * Created by lkarmelo on 22.11.2019.
 */

import React, { useCallback, useState } from 'react';

import DropDownMenu from 'app/components/common/DropDownMenu';
import CategoryRulesForm from 'app/components/catalogue/CategoryRulesForm';
import AddEditCategory, {
    IAddEditCategoryOwnProps,
} from 'app/components/catalogue/AddEditCategory';
import DeleteCategory from 'app/components/catalogue/DeleteCategory';
import { ISelectOption } from 'app/modules/components/TreeSelect';
import { ADD_CATEGORY_FORM_TITLE } from 'app/utils/constants';

import * as styles from './CatalogueCategoryControls.scss';

import * as Store from '../../../redux/store/StoreNamespace';

interface IProps {
    activeCategoryId: string;
    activeCategoriesSequence: string[];
    categories: Store.INormalizedHierarchicalCategories;
    categoriesAsOptions: ISelectOption[];
}

type AddEditCategoryProps = Pick<
    IAddEditCategoryOwnProps,
    | 'initialKeywords'
    | 'initialParentCategoryId'
    | 'initialTitle'
    | 'formTitle'
    | 'editingCategoryId'
>;

const editFormTitle = 'Редактирование категории';

const CatalogueCategoryControls: React.FC<IProps> = (props) => {
    const {
        activeCategoryId,
        categories,
        activeCategoriesSequence,
        categoriesAsOptions,
    } = props;

    const [rulesModalShown, setRulesModalShown] = useState(false);
    const closeRulesModal = useCallback(() => setRulesModalShown(false), []);

    const [addEditCategoryModalShown, setAddEditCategoryModalShown] =
        useState(false);
    const closeAddEditCategoryModalShown = useCallback(
        () => setAddEditCategoryModalShown(false),
        []
    );
    const [addEditCategoryProps, setAddEditCategoryProps] =
        useState<AddEditCategoryProps>();

    const [deleteCategoryModalShown, setDeleteCategoryModalShown] =
        useState(false);
    const closeDeleteCategoryModal = useCallback(
        () => setDeleteCategoryModalShown(false),
        []
    );

    const openAddSubCategory = useCallback(
        (close) => {
            close();
            setAddEditCategoryProps({
                initialParentCategoryId: activeCategoryId,
                formTitle: ADD_CATEGORY_FORM_TITLE,
            });
            setAddEditCategoryModalShown(true);
        },
        [activeCategoryId]
    );
    const openEditCategory = useCallback(
        (close) => {
            const parentCategoryId = activeCategoriesSequence[1]; // 0 - текущая категория, 1 всегда будет родительской
            const activeCategory =
                categories &&
                categories.categories &&
                activeCategoryId &&
                categories.categories[activeCategoryId];

            if (activeCategory && !activeCategory.item.keywords) {
                // если ключевые слова ещё не загрузились, не даём редактировать категорию
                // TODO: улчшить UX
                return;
            }

            close();
            setAddEditCategoryProps({
                formTitle: editFormTitle,
                initialParentCategoryId: parentCategoryId,
                initialKeywords:
                    activeCategory && activeCategory.item.keywords.slice(),
                initialTitle: activeCategory.item.title,
                editingCategoryId: activeCategoryId,
            });
            setAddEditCategoryModalShown(true);
        },
        [categories, activeCategoriesSequence, activeCategoryId]
    );

    return (
        <>
            <CategoryRulesForm
                isVisible={rulesModalShown}
                close={closeRulesModal}
                categoryId={activeCategoryId}
            />
            <AddEditCategory
                isVisible={addEditCategoryModalShown}
                close={closeAddEditCategoryModalShown}
                categories={categories}
                categoriesAsOptions={categoriesAsOptions}
                {...addEditCategoryProps}
            />
            <DeleteCategory
                isVisible={deleteCategoryModalShown}
                categories={categories}
                categoriesAsOptions={categoriesAsOptions}
                close={closeDeleteCategoryModal}
                categoryToRemoveId={activeCategoryId}
                activeCategoriesSequence={activeCategoriesSequence}
            />
            <DropDownMenu
                className={styles.catalogueCategoryControls}
                toggleElement={
                    <button
                        className={`btn nkc-icon-meatball ${styles.catalogueCategoryControlsToggle}`}
                    />
                }
                dropDownContentRenderer={(close) => (
                    <div className={styles.catalogueCategoryControlsList}>
                        <button
                            className={`btn ${styles.catalogueCategoryControlsListItem}`}
                            onClick={() => openEditCategory(close)}
                        >
                            Редактировать
                        </button>
                        <button
                            className={`btn ${styles.catalogueCategoryControlsListItem}`}
                            onClick={() => openAddSubCategory(close)}
                        >
                            Добавить подкатегорию
                        </button>
                        <button
                            className={`btn ${styles.catalogueCategoryControlsListItem}`}
                            onClick={() => {
                                close();
                                setRulesModalShown(true);
                            }}
                        >
                            Правила категории
                        </button>
                        <div
                            className={
                                styles.catalogueCategoryControlsDelimeter
                            }
                        />
                        <button
                            className={`btn ${styles.catalogueCategoryControlsListItem} ${styles.catalogueCategoryControlsDelete}`}
                            onClick={() => {
                                close();
                                setDeleteCategoryModalShown(true);
                            }}
                        >
                            Удалить категорию
                        </button>
                    </div>
                )}
            />
        </>
    );
};

export default React.memo(CatalogueCategoryControls);
