import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { usePrevious } from 'react-use';

import { EMPLOYEES_TAB_CODE } from 'app/utils/constants';
import CarouselWidget from 'app/components/common/Wigets/CarouselWidget';

import IProps from './interfaces/IPersonsWidgetProps';
import * as styles from './PersonsWidget.scss';
import PersonView from './PersonView/PersonViewConnected';

const PersonsWidget: React.FC<IProps> = (props) => {
    const { persons, className, setActiveTab, onPersonClick, onMailToClick } =
        props;
    const prevPersons = usePrevious(persons);

    const [currentPersonIndex, setCurrentPersonIndex] = useState(0);

    if (
        prevPersons &&
        prevPersons.length !== persons.length &&
        currentPersonIndex !== 0
    ) {
        setCurrentPersonIndex(0);
    }

    const usersSlides = useMemo(
        () =>
            Array.isArray(persons) &&
            persons
                .map((_, idx) => [persons[idx * 2], persons[idx * 2 + 1]])
                .filter((personPair) => personPair[0] || personPair[1])
                .map((personPair) => {
                    const person1 = personPair[0];
                    const person2 = personPair[1];
                    return {
                        renderSlide: () => (
                            <React.Fragment key={person1.id}>
                                <div
                                    className={
                                        styles.personsWidgetPersonsWrapper
                                    }
                                >
                                    {person1 ? (
                                        <PersonView
                                            person={person1}
                                            className={
                                                styles.personsWidgetPerson
                                            }
                                            onPersonClick={onPersonClick}
                                            onMailToClick={onMailToClick}
                                        />
                                    ) : (
                                        ''
                                    )}
                                    {person2 ? (
                                        <PersonView
                                            person={person2}
                                            className={
                                                styles.personsWidgetPerson
                                            }
                                            onPersonClick={onPersonClick}
                                            onMailToClick={onMailToClick}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </React.Fragment>
                        ),
                    };
                }),
        [persons, onPersonClick, onMailToClick]
    );

    if (!persons || persons.length === 0) {
        return null;
    }

    return (
        <CarouselWidget
            className={className}
            slides={usersSlides}
            showPaging={persons.length > 2}
            currentSlideIndex={currentPersonIndex}
            setCurrentSlideIndex={setCurrentPersonIndex}
            renderSlidesCount={(index, count, _) => (
                <div className={styles.personsWidgetFooter}>
                    <button
                        className={classNames(
                            'btn',
                            styles.personsWidgetAllPersons,
                            styles.personsWidgetToggleTruncate,
                            {
                                [styles.personsWidgetAllPersonsSingleSlide]:
                                    count < 2,
                            }
                        )}
                        onClick={() => setActiveTab(EMPLOYEES_TAB_CODE)}
                    >
                        {count > 1
                            ? 'Показать всех сотрудников'
                            : 'Перейти на вкладку "сотрудники"'}
                    </button>
                    {count > 1 && (
                        <span className={styles.personsWidgetCount}>
                            {index + 1} из {count}
                        </span>
                    )}
                </div>
            )}
        />
    );
};

PersonsWidget.defaultProps = {
    persons: [],
};

export default PersonsWidget;
