/**
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 27.06.2018
 * -----
 * Last Modified: 11.07.2018 10:44:11
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import { Action, handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';

import Api from 'app/api/Api';

import {
    setNotificationListAndCount,
    markNotificationsRead,
    setNotificationsCount,
    setLatestNotificationsAndCount,
    setNotificationsIntervalId,
} from '../actions/notification';
import * as Store from '../store/StoreNamespace';
import initialState from '../store/initialState';
import { clampNumber } from '../../modules/utils/clampNumber';

const totalReducer = handleActions<any>(
    {
        [combineActions(
            setNotificationListAndCount,
            setLatestNotificationsAndCount
        ).toString()](
            _: number,
            { payload }: Action<Api.INotificationsResponse>
        ): number {
            return payload.total;
        },
    },
    initialState[Store.NotificationContext].newCount
);

const newCountReducer = handleActions<any>(
    {
        [combineActions(
            setNotificationListAndCount,
            setLatestNotificationsAndCount
        ).toString()](
            _: number,
            { payload }: Action<Api.INotificationsResponse>
        ): number {
            return payload.newCount;
        },
        [markNotificationsRead.toString()](
            state: number,
            { payload }: Action<string[]>
        ): number {
            return clampNumber(state - payload.length, 0, state);
        },
        [setNotificationsCount.toString()]: (
            _,
            { payload }: Action<number>
        ): number => payload,
    },
    initialState[Store.NotificationContext].newCount
);

const newNotificationsIntervalId = handleActions<any>(
    {
        [setNotificationsIntervalId.toString()]: (
            _,
            { payload }: Action<number>
        ): number => payload,
    },
    initialState[Store.NotificationContext].notificationsIntervalId
);

const getNotificationsReducer = (setAction: string) =>
    handleActions<any>(
        {
            [setAction](
                state: Store.IUserNotification[],
                { payload }: Action<Api.INotificationsResponse>
            ): Store.IUserNotification[] {
                if (!payload || !Array.isArray(payload.notifications)) {
                    return state;
                }
                return payload.notifications;
            },
            [markNotificationsRead.toString()](
                state: Store.IUserNotification[],
                { payload }: Action<string[]>
            ): Store.IUserNotification[] {
                if (!Array.isArray(state) || payload.length === 0) {
                    return state;
                }
                return state.map((notification) => {
                    const shouldMarkAsRead = payload.includes(notification.id);
                    return shouldMarkAsRead
                        ? { ...notification, isRead: true }
                        : notification;
                });
            },
        },
        []
    );

export default combineReducers({
    newCount: newCountReducer,
    notifications: getNotificationsReducer(
        setNotificationListAndCount.toString()
    ),
    latestNotifications: getNotificationsReducer(
        setLatestNotificationsAndCount.toString()
    ),
    total: totalReducer,
    notificationsIntervalId: newNotificationsIntervalId,
});
