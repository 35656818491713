/**
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 08.11.2017
 * -----
 * Last Modified: 07.02.2018 18:42:03
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import { Component } from 'react';
import * as React from 'react';
import { Provider } from 'react-redux';
import ru from 'antd/es/locale-provider/ru_RU';
import LocaleProvider from 'antd/es/locale-provider';

import { PubSubContext, pubSubImpl } from 'app/react-context-pubsub';
import store from 'app/redux/store/store';

import WindowSizeProvider from '../GridSizeProvider';
import Root from '../Root/Root';
import { ConstantFuturesName } from '../../features/ConstantFuturesName';

class App extends Component {
    componentDidMount() {
        document.title = APP_FEATURES.constant[
            ConstantFuturesName.PageTitle
        ] as string;
    }

    render(): JSX.Element {
        return (
            <Provider store={store as any}>
                <LocaleProvider locale={ru}>
                    <PubSubContext.Provider value={pubSubImpl}>
                        <WindowSizeProvider>
                            <Root />
                        </WindowSizeProvider>
                    </PubSubContext.Provider>
                </LocaleProvider>
            </Provider>
        );
    }
}

export default App;
