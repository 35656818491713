import React, {FC, useLayoutEffect, useMemo, useState} from 'react';
import { Subject } from 'rxjs/Subject';

import * as Store from 'app/redux/store/StoreNamespace';
import { useTypedSelector } from 'app/hooks/useTypedSelector';
import { getParametersForLinkToProfileFromIdAndLogins } from 'app/utils/document/login';
import history from 'app/history';
import clientRoutes from 'app/routing/clientRoutes';
import DropDownList from 'app/components/common/controls/DropDownList/DropDownList';
import { IDropDownListRef } from 'app/components/common/controls/DropDownList';
import { IOption } from 'app/components/common/controls/Option';

import { RenderHintContent } from './RenderHintContent';

import { HintOptionMeta, isHintFilter, isHintPerson, isHintRegular } from '../../types';
import { getOptionsFromHints, getQueryHints } from '../../helpers';
import { MAX_DROPDOWN_HEIGHT, DROPDOWN_PADDING } from '../../constants';

interface IRenderHintList {
    hintListRef: React.RefObject<IDropDownListRef>;
    showHints: boolean;
    onHintUnHighlight: (option: IOption<HintOptionMeta>) => void;
    onClickPersonList: () => void;
    updateQuery: (query: string, isOriginalSearchQuery?: boolean) => void;
    handleSearchClick: () => void;
    onSelectFilterHint: (
        value: string,
        label: string,
        filterName: string,
        filtersMeta: Store.IFiltersMeta) => void;
    setFocusedHint: (value: {} | null) => void;
    highlightQueryFromFilterHint: (position: Store.IQueryPosition | null) => void;
    isTextAreaWrapperClicked: boolean;
    closeHints: () => void;
    subjectEnterPressed$: Subject<any>;
    fetchSearchHints: (reset: boolean) => void;
}

export const RenderHintList: FC<IRenderHintList> = (
    {
        hintListRef,
        showHints,
        isTextAreaWrapperClicked,
        onHintUnHighlight,
        onClickPersonList,
        updateQuery,
        handleSearchClick,
        onSelectFilterHint,
        setFocusedHint,
        highlightQueryFromFilterHint,
        closeHints,
        subjectEnterPressed$,
        fetchSearchHints
    }) => {
    const { search: { searchQuery = '', hints }, filtersMeta } = useTypedSelector(store => store);
    const personHints = useMemo(() => hints?.persons ?? [], [hints?.persons]);
    const { queryHints, filterHints } = getQueryHints(hints?.queries);

    const [options, setOptions] = useState<IOption[]>(() => getOptionsFromHints(
        queryHints,
        filterHints,
        personHints,
        searchQuery,
        filtersMeta))

    const onHintHighlight = (option: IOption<HintOptionMeta>) => {
        if (!option) {
            return;
        }
        const { meta } = option;

        setFocusedHint(isHintRegular(meta)
            ? { query: meta.originalHintQuery, position: meta.position }
            : null);

        if (isHintFilter(meta)) {
            highlightQueryFromFilterHint(meta.position);
        } else {
            // сбрасываем предыдущее выделение в поисковой строке
            highlightQueryFromFilterHint(null);
        }
    };

    const closeHintsOnOutsideClick = () => {
        if (!isTextAreaWrapperClicked && showHints) {
            closeHints();
        }
    };

    const onSelectHint = (value: string, label: string, meta: HintOptionMeta) => {
        if (isHintPerson(meta)) {
            if (meta.overallPersonsCount === undefined) {
                const profileLinkParams = getParametersForLinkToProfileFromIdAndLogins(value, meta.userLogins);
                history.push(clientRoutes.account.subRoutes.profile.getUrl(null, profileLinkParams));
            }
        }
        if (isHintRegular(meta)) {
            updateQuery(value);
            handleSearchClick();
        }
        if (isHintFilter(meta)) {
            updateQuery(meta.resultSearchQuery);
            onSelectFilterHint(value, meta.displayValue, meta.filterName, filtersMeta);
            handleSearchClick();
        }

        highlightQueryFromFilterHint(null);
        setFocusedHint(null);
        fetchSearchHints(true);
    };

    useLayoutEffect(() => {
        if (hintListRef.current) {
            const optionsList = hintListRef.current.optionListRef.current;

            const initialOptions = getOptionsFromHints(
                queryHints,
                filterHints,
                personHints,
                searchQuery,
                filtersMeta);

            let totalOptionsHeight = DROPDOWN_PADDING;
            let lastHintIndex = 0;

            for (let i = 0; i < optionsList.children.length; i++) {
                totalOptionsHeight += optionsList.children[i].clientHeight;

                if (totalOptionsHeight > MAX_DROPDOWN_HEIGHT) {
                    break;
                }

                lastHintIndex = i;
            }

            setOptions(initialOptions.slice(0, lastHintIndex + 1))
        };

        }, [filterHints, filtersMeta, hintListRef, personHints, queryHints, searchQuery])

    return (
            options ? <DropDownList
                ref={hintListRef}
                options={options}
                isOpened={showHints}
                onSelectOption={onSelectHint}
                classPrefix="search-panel"
                onDocumentMouseDown={closeHintsOnOutsideClick}
                isOptionActive={() => false}
                optionRenderer={RenderHintContent}
                onOptionHighlighted={onHintHighlight}
                onOptionUnHighlighted={onHintUnHighlight}
                onClickPersonList={onClickPersonList}
                subjectEnterPressed$={subjectEnterPressed$}
            /> : null
    );
};
