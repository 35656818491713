import React, { Component, ChangeEvent, FormEvent } from 'react';
import { Action } from 'redux';
import { connect, DispatchProp } from 'react-redux';
import autoBind from 'autobind-decorator';
import classNames from 'classnames';
import Tooltip from 'antd/es/tooltip';

import { resetPassword } from 'app/redux/actions/authorization';

import '../Login.scss';

import * as Store from '../../../redux/store/StoreNamespace';

interface IResetPasswordState {
    password: string;
    showPassword: boolean;
}

interface IResetPasswordProps extends DispatchProp<Action> {
    error: string;
}

@autoBind
class ResetPassword extends Component<
    IResetPasswordProps,
    IResetPasswordState
> {
    state: IResetPasswordState = {
        password: '',
        showPassword: false,
    };

    onSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
    }

    onSubmitPassword(): void {
        const query = location.pathname;
        const token = query.substr(query.lastIndexOf('/') + 1);

        this.props.dispatch(resetPassword(token, this.state.password));
    }

    onPasswordChange(e: ChangeEvent<HTMLInputElement>): void {
        this.setState({ password: e.currentTarget.value });
    }

    togglePasswordVisibility() {
        this.setState((state) => ({ showPassword: !state.showPassword }));
    }

    render(): JSX.Element {
        const { error } = this.props;
        return (
            <div className={classNames('login', { 'login--failed': !!error })}>
                <div className="login__content">
                    <div className="login__logo" />
                    <div className="login__header">Восстановление пароля</div>
                    <form onSubmit={this.onSubmit} className="login__form">
                        {!!error && (
                            <div className="login__fail-message">{error}</div>
                        )}
                        <div className="login__password">
                            <input
                                type={
                                    this.state.showPassword
                                        ? 'text'
                                        : 'password'
                                }
                                placeholder="Пароль"
                                value={this.state.password}
                                onChange={this.onPasswordChange}
                            />
                            <Tooltip
                                placement="topRight"
                                title="Показать/скрыть пароль"
                                mouseEnterDelay={0.3}
                            >
                                <button
                                    type="button"
                                    className="login__toggle-password-visibility btn"
                                    onClick={this.togglePasswordVisibility}
                                />
                            </Tooltip>
                        </div>
                        <button
                            onClick={this.onSubmitPassword}
                            className="login__submit btn-primary"
                        >
                            Установить пароль
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: Store.IState) => ({
    error: state.authorization.error,
});

export default connect(mapStateToProps)(ResetPassword);
