import moment from 'moment';

import cssVars from 'stylessheets/vars.scss';
import {
    IDocumentFileMeta,
    IDocumentFilePath,
} from 'app/redux/store/StoreNamespace';

export const MAX_SEARCH_QUERY_LENGTH = 300;

export const DEFAULT_PAGING_ITEMS_LIMIT = 10;

export const COMPETENCIES_GRADE_SCALE = 5;

export const YEARS_WIDGET_BARS_SHOWN_NUMBER = 15;
export const YEARS_WIDGET_MIN_YEAR = 1900;
export const YEARS_WIDGET_MAX_YEAR = new Date().getFullYear() + 100;

export const DATE_INPUT_DEFAULT_MIN_VALUE = moment()
    .subtract(100, 'years')
    .startOf('year')
    .valueOf();
export const DATE_INPUT_DEFAULT_MAX_VALUE = moment()
    .add(100, 'years')
    .endOf('year')
    .valueOf();

export const SEARCH_QUERY_PARAM_NAME = 'query';
export const SEARCH_ALIAS_PARAM_NAME = 'h';
export const SEARCH_FILTERS_PARAM_NAME = 'f';
export const SEARCH_TAB_PARAM_NAME = 't';

export const NOTIFICATIONS_LOAD_INTERVAL = 1000 * 60 * 5;

export const DOCUMENT_TITLE_CHANGE_DEBOUNCE_TIME = 150;

export const DEPARTMENT_PATH = 'department-path';

export const BREAD_CRUMBS_MAX_LENGTH = 130;
export const BREAD_CRUMBS_DIVIDER = ' > ';

export const CURRENT_HTTP_URL = `https://${document.location.host}`;

export const enum GridSizeMin {
    SmallMin = 768,
    MediumMin = 992,
    LargeMin = 1200,
    ExtraLargeMin = 1600,
}

export const enum GridSize {
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
    ExtraLarge = 'extraLarge',
}

export const YEAR_PERIOD_FILTER_MIN_YEAR = 1900;

export const FULL_DATE_TIME_FORMAT = 'YYYY-MM-DDT00:00:00';
export const DATE_DOTS_FORMAT = 'DD.MM.YYYY';
export const DATE_FULL_MONTH_FORMAT = 'DD MMMM YYYY';
export const DATE_DAY_AND_MONTH_FORMAT = 'DD MMMM';
export const DATE_INPUT_FORMAT_HINT = 'ДД.ММ.ГГГГ';

export const TAG_REG_EXP = /#[^\s#]+/g;

// tslint:disable-next-line
export const EMAIL_REG_EXP =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const PASSWORD_REG_EXP = /^\S+$/;
export const PASSWORD_MIN_LENGTH = 5;

export const DEFAULT_LOADING_ERROR_MESSAGE = 'Произошла ошибка при загрузке';

export const LOADING_ERROR_MESSAGE_REFRESH =
    'Что-то пошло не так, мы уже разбираемся с этим!/nПопробуйте обновить страницу через несколько минут.';
export const LOADING_ERROR_BTN_TITLE_REFRESH = 'Обновить страницу';

export const LOADING_ERROR_MESSAGE_DELETED_DOCUMENT =
    'К сожалению, не могу открыть документ./nВозможно, он уже удалён или у вас нет к нему доступа.';
export const LOADING_ERROR_BTN_TITLE_DELETED_DOCUMENT = 'Вернуться к поиску';

export const LOADING_ERROR_MESSAGE_SEARCH =
    'Ссылка устарела и больше никуда не ведёт';
export const LOADING_ERROR_BTN_TITLE_SEARCH = 'Хорошо, поищу самостоятельно';

export const MAX_UPLOAD_FILE_SIZE = 200 * 1024 * 1024;

export const MAX_USERS_SHOWN_IN_SEARCH_HINTS = 2;
export const MAX_USERS_SHOWN_IN_SEARCH_PAGE_PREVIEW = 4;

export const NON_COLLAPSED_FILTERS_COUNT = 4;

export const THESAURUS_SPECIAL_SYMBOL = '$';

export const ADD_CATEGORY_FORM_TITLE = 'Создание категории';
export const TOP_LEVEL_CATALOGUE_CATEGORY_LABEL = '(категория верхнего уровня)';

export const DOCUMENT_EMPTY_CONTENT_TEXT =
    'Описание отсутствует. См. атрибуты на карточке документа.';
export const DOCUMENT_EMPTY_TEXT = 'Описание отсутствует';

export const FEEDBACK_BLOCK_INDEX_IN_SEARCH_RESULTS = 3;
// tslint:disable-next-line
export const CONCEPTS_HINT_TEXT = `Концепты (термины), найденные в составе поискового запроса. Каждый такой концепт лежит в иерархии концептов, где может иметь связи с более широкими, узкими или похожими понятиями. Они также дописываются в неявном виде к запросу. Вы можете изменить их набор, нажав кнопку "Выбрать".`;

export const LOAD_AVATAR_SERVER_ERROR_TEXT =
    'Что-то пошло не так, мы уже разбираемся с этим!/nПопробуйте обновить страницу через несколько минут.';
export const LOAD_AVATAR_SERVER_ERROR_BTN = 'Обновить страницу';

export const LOAD_AVATAR_NOT_FOUND_TEXT =
    'К сожалению, не могу открыть изображение./nВозможно, оно уже удалено или файл изображения поврежден.';
export const LOAD_AVATAR_NOT_FOUND_BTN = 'Вернуться к поиску';

export const EMPLOYEES_TAB_CODE = 'employees';

export const MAX_HREF_LENGTH = 2000;

export const documentFileMetaTitle: Record<keyof IDocumentFileMeta, string> = {
    checksum: 'Контрольная сумма',
    contentType: 'Тип контента',
    created: 'Дата создания',
    extension: 'Расширение',
    filename: 'Имя файла',
    filesize: 'Размер файла',
    indexedChars: 'Извлечено символов',
    indexingDate: 'Дата индексации',
    lastAccessed: 'Последнее открытие',
    lastModified: 'Последнее изменение',
};

export const documentFilePathTitle: Record<keyof IDocumentFilePath, string> = {
    relative: 'Относительный путь',
    real: 'Полный путь',
};

export const enum RedirectLevel {
    redirect,
    redirectLocalError,
    redirectGlobalError,
    redirectDFSError,
}

export const enum StatusGuide {
    start = 'START',
    skip = 'SKIP',
    finish = 'FINISH',
}

export const headerHeight = parseInt(cssVars.headerheight, 10);
export const footerHeight = parseInt(cssVars.footerheight, 10);

export const HintsMoreDetailsLink =
    'https://kb.gazprom-neft.local/pages/viewpage.action?pageId=85164656';

export const MOBILE_NEWS_LINK =
    'https://lenta.gazprom-neft.ru/channel/korporativnyy_poisk/?utm_source=search';
