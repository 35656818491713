/**
 * Created by Lkarmelo on 05.09.2017.
 */
import { MiddlewareAPI } from 'redux';
import { Observable } from 'rxjs/Observable';
import { Action } from 'redux-actions';

import { getTitleFromLabels } from 'app/utils/concepts';

import { createSimpleLoadingEpic } from './utils';
import { createRetryOnIEAuthProblem } from './utils/createRetryOnIEAuthProblem';

import {
    fetchObjectCard,
    setObjectCard,
    objectCardTablesActions,
    setObjectCardLinksGrouped,
    fetchObjectCardSimilar,
    ILoadMoreParentConcepts,
    ILoadParentConceptsPayload,
    fetchMoreParentConcepts,
    fetchParentConcepts,
    addParentsToConceptHierarchyTree,
    fetchChildrenConcepts,
    addChildrenToConceptHierarchyTree,
} from '../actions/objectCard';
import {
    objectCardReject,
    objectCardRequest,
    objectCardResolve,
    searchReject,
    searchRequest,
    searchResolve,
} from '../actions/loading';
import * as Store from '../store/StoreNamespace';
import Api from '../../api/Api';
import {
    addToSearchResults,
    fetchMoreSearchResults,
    setSearchResults,
} from '../actions/search/results';
import { SearchDocumentName } from '../actions/interfaces/DocumentEnum';
import { withContext } from '../context/connectWithContext';

export const loadObjectCard = createSimpleLoadingEpic({
    triggers: [fetchObjectCard.toString()],
    apiCallName: 'objectCard',
    actions: {
        requestAction: objectCardRequest,
        resolveAction: objectCardResolve,
        rejectAction: objectCardReject,
        setAction: setObjectCard,
    },
});

export const loadObjectCardLinked = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(
            objectCardTablesActions.grouped.setSkip.toString(),
            objectCardTablesActions.grouped.setLimit.toString(),
            objectCardTablesActions.grouped.setSorting.toString(),
            objectCardTablesActions.links.setSkip.toString(),
            objectCardTablesActions.links.setLimit.toString(),
            objectCardTablesActions.links.setSorting.toString()
        )
        .debounceTime(200)
        .switchMap(() => {
            const { objectCard } = store.getState();
            const requestBody: Api.ILinkedRequestBody = {
                links: {
                    ...objectCard.linksPaging,
                    sortBy: objectCard.linksSorting,
                },
                grouped: {
                    ...objectCard.groupedPaging,
                    sortBy: objectCard.groupedSorting,
                },
            };

            return apiCall
                .objectCardLinked(objectCard.id, requestBody)
                .mergeMap(({ response }) =>
                    Observable.of(
                        setObjectCardLinksGrouped(response),
                        objectCardResolve()
                    )
                )
                .catch((e) => {
                    console.error(e);
                    return Observable.of(objectCardReject(e));
                })
                .startWith(objectCardRequest());
        });

export const loadObjectCardSimilar = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall, context }
) =>
    action$
        .ofType(
            fetchObjectCardSimilar.toString(),
            fetchMoreSearchResults.toString()
        )
        .contextStartsWith(SearchDocumentName.objectCardSimilar)
        .scan((accumulated, action) => {
            if (action.type === fetchObjectCardSimilar.toString()) {
                return { lastCardId: action.payload.id, action };
            }
            return { ...accumulated, action };
        }, {})
        .switchMap(({ lastCardId, action }) => {
            const shouldAddToResults =
                action.type === fetchMoreSearchResults.toString();
            const actionState = context(store.getState(), action);

            return apiCall[SearchDocumentName.objectCardSimilar](
                lastCardId,
                actionState.paging.skip,
                actionState.paging.limit
            )
                .retryWhen(createRetryOnIEAuthProblem())
                .mergeMap(
                    ({ response }: { response: Api.IObjectCardSimilar[] }) => {
                        const setOrAddAction = shouldAddToResults
                            ? addToSearchResults
                            : setSearchResults;

                        const responseAsSearchList = {
                            list: Array.isArray(response)
                                ? response.map(({ document, similarity }) => ({
                                      ...document,
                                      similarity,
                                  }))
                                : [],
                        };

                        return Observable.of(
                            withContext(
                                setOrAddAction({
                                    results: responseAsSearchList,
                                }),
                                action.context
                            ),
                            searchResolve()
                        );
                    }
                )
                .catch((e) => {
                    console.error(e);
                    return Observable.of(searchReject(e));
                })
                .startWith(searchRequest());
        });

interface IAccumulated {
    concepts: Record<string, Store.IConceptWithChildren>;
    schemeUri: string;
    payload: ILoadParentConceptsPayload | ILoadMoreParentConcepts;
    originalConcept: Store.ISchemeConcept;
}
export const loadParentConceptsEpic = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall, context }
) =>
    action$
        .ofType(
            fetchParentConcepts.toString(),
            fetchMoreParentConcepts.toString()
        )
        .groupBy(
            ({
                type,
                payload,
            }: Action<
                ILoadParentConceptsPayload | ILoadMoreParentConcepts
            >) => {
                if (type === fetchParentConcepts.toString()) {
                    return payload.concept.uri;
                }
                if (type === fetchMoreParentConcepts.toString()) {
                    return (<ILoadMoreParentConcepts>payload).originalConcept
                        .uri;
                }
            },
            undefined,
            () => Observable.timer(15000)
        )
        .mergeMap((group) =>
            group
                .scan(
                    (
                        accumulated: IAccumulated,
                        action: Action<
                            ILoadParentConceptsPayload | ILoadMoreParentConcepts
                        >
                    ) => {
                        if (action.type === fetchParentConcepts.toString()) {
                            accumulated.concepts = {};
                            accumulated.schemeUri = (<
                                ILoadParentConceptsPayload
                            >action.payload).concept.inScheme.uri;
                            accumulated.originalConcept = (<
                                ILoadParentConceptsPayload
                            >action.payload).concept;
                        } else if (
                            action.type === fetchMoreParentConcepts.toString()
                        ) {
                            const { payload } =
                                action as Action<ILoadMoreParentConcepts>;
                            accumulated.concepts = {
                                ...accumulated.concepts,
                                [payload.concept.uri]: {
                                    ...payload.concept,
                                    children: [payload.childrenConceptUri],
                                },
                            };
                        }
                        accumulated.payload = action.payload;
                        return accumulated;
                    },
                    <IAccumulated>{}
                )
                .mergeMap(
                    ({
                        payload,
                        schemeUri,
                        concepts,
                        originalConcept,
                    }: IAccumulated) =>
                        apiCall
                            .broaderConcepts(payload.concept.uri, schemeUri)
                            .retryWhen(createRetryOnIEAuthProblem())
                            .mergeMap(({ response }) => {
                                const broaderConcept =
                                    response[payload.concept.uri]?.[
                                        Store.SKOSRelationshipClasses.Broader
                                    ]?.[0];
                                if (broaderConcept) {
                                    return Observable.of(
                                        fetchMoreParentConcepts({
                                            concept: {
                                                uri: broaderConcept.value,
                                                label: getTitleFromLabels(
                                                    broaderConcept.attr
                                                        .prefLabel
                                                ),
                                            },
                                            childrenConceptUri:
                                                payload.concept.uri,
                                            originalConcept,
                                        })
                                    );
                                }
                                return Observable.of(
                                    addParentsToConceptHierarchyTree({
                                        originalConcept,
                                        concepts,
                                        topConceptUri: payload.concept.uri,
                                    })
                                );
                            })
                            .catch((e) => {
                                console.error(e);
                                return Observable.empty();
                            })
                )
        );

export const loadChildrenConceptsEpic = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }
) =>
    action$
        .ofType(fetchChildrenConcepts.toString())
        .groupBy(
            ({
                type,
                payload,
            }: Action<
                ILoadParentConceptsPayload | ILoadMoreParentConcepts
            >) => {
                if (type === fetchParentConcepts.toString()) {
                    return payload.concept.uri;
                }
                if (type === fetchMoreParentConcepts.toString()) {
                    return (<ILoadMoreParentConcepts>payload).originalConcept
                        .uri;
                }
            },
            undefined,
            () => Observable.timer(15000)
        )
        .mergeMap((group) =>
            group.mergeMap(
                ({ payload }: Action<ILoadParentConceptsPayload>) => {
                    return apiCall
                        .narrowerConcepts(
                            payload.concept.uri,
                            payload.concept.inScheme.uri
                        )
                        .retryWhen(createRetryOnIEAuthProblem())
                        .mergeMap(({ response }) => {
                            const narrowerConceptList =
                                response[payload.concept.uri]?.[
                                    'http://www.w3.org/2004/02/skos/core#narrower'
                                ];

                            const concepts: Record<
                                string,
                                Store.IConceptWithChildren
                            > = {};

                            if (
                                Array.isArray(narrowerConceptList) &&
                                narrowerConceptList.length > 0
                            ) {
                                narrowerConceptList.forEach((conc) => {
                                    concepts[conc.value] = {
                                        uri: conc.value,
                                        label: getTitleFromLabels(
                                            conc.attr.prefLabel
                                        ),
                                        children: [],
                                    };
                                });
                            }

                            return Observable.of(
                                addChildrenToConceptHierarchyTree({
                                    originalConcept: payload.concept,
                                    concepts,
                                })
                            );
                        })
                        .catch((e) => {
                            console.error(e);
                            return Observable.empty();
                        });
                }
            )
        );
