/**
 * Created by Lkarmelo on 03.09.2018.
 */

import React, { Component } from 'react';
import autoBind from 'autobind-decorator';
import classNames from 'classnames';
import Modal from 'antd/es/modal/Modal';

import Form from 'app/components/common/forms/Form';

import IProps from './interfaces/IApproveActionProps';
import IState from './interfaces/IApproveActionState';
import * as styles from './ApproveAction.scss';

@autoBind
class ApproveAction extends Component<IProps, IState> {
    static defaultProps = {
        approveBtnText: 'Да, уверен',
        denyBtnText: 'Отмена',
        bodyText: 'Вы уверены, что хотите совершить это действие?',
        successMsgText: 'Действие успешно выполнено',
    };

    state = {
        isSuccessfullySent: false,
    };

    render(): JSX.Element {
        const {
            isVisible,
            status,
            approveBtnText,
            denyBtnText,
            bodyText,
            successMsgText,
            renderBody,
        } = this.props;
        return (
            <Modal
                footer={null}
                visible={isVisible}
                onCancel={this.cancel}
                afterClose={this.afterModalClose}
                className={classNames(`modal`, styles.approveAction, {
                    [styles.approveActionSuccess]:
                        this.state.isSuccessfullySent,
                })}
            >
                <div className={styles.approveActionBody}>
                    {typeof renderBody === 'function' ? (
                        <>{renderBody(this.props)}</>
                    ) : (
                        <div className={styles.approveActionText}>
                            {bodyText}
                        </div>
                    )}
                    <Form status={status} onFormSaved={this.formSaved} />
                    <div className={styles.approveActionControls}>
                        <button className="btn btn-ok" onClick={this.send}>
                            <span />
                            {approveBtnText}
                        </button>
                        <button
                            className="btn btn-cancel"
                            onClick={this.cancel}
                        >
                            {denyBtnText}
                        </button>
                    </div>
                </div>
                <div className={styles.approveActionSuccessMessage}>
                    {successMsgText}
                </div>
            </Modal>
        );
    }

    componentDidMount(): void {
        if (this.props.isVisible) {
            this.props.onShown();
        }
    }

    componentDidUpdate(prevProps: IProps): void {
        if (this.props.isVisible && !prevProps.isVisible) {
            this.props.onShown();
        }
    }

    componentWillUnmount(): void {
        this.props.onHidden();
    }

    cancel(): void {
        this.props.onCancel();
    }

    send(): void {
        this.props.onSend(this.props);
    }

    formSaved(): void {
        this.props.onSentSuccess && this.props.onSentSuccess(this.props);
        this.setState({ isSuccessfullySent: true });
        setTimeout(() => {
            this.props.isVisible && this.cancel();
        }, 1500);
    }

    afterModalClose(): void {
        const { onHidden, onSentSuccessAfterHidden } = this.props;
        onHidden();
        this.state.isSuccessfullySent &&
            onSentSuccessAfterHidden &&
            onSentSuccessAfterHidden(this.props);
        this.setState({
            isSuccessfullySent: false,
        });
    }
}

export default ApproveAction;
