import { createAction } from 'redux-actions';

import * as Store from 'app/redux/store/StoreNamespace';

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const uploadFile = createAction<IUploadFileData>(UPLOAD_FILE);

export const FETCH_FILE_INFO = 'FETCH_FILE';
export const fetchFileInfo = createAction<string>(FETCH_FILE_INFO);

export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const downloadFile = createAction<string>(DOWNLOAD_FILE);

export const CANCEL_FILE_UPLOAD = 'CANCEL_FILE_UPLOAD';
export const cancelFileUpload = createAction<string>(CANCEL_FILE_UPLOAD);

export const DELETE_FILE = 'DELETE_FILE';
export const deleteFile = createAction<string>(DELETE_FILE);

export const REDO_UPLOAD_FILE = 'REDO_UPLOAD_FILE';
export const redoUploadFile = createAction<string>(REDO_UPLOAD_FILE);

export interface IUploadFileData {
    path?: string;
    fid?: string;
    files: FileList | File;
}

export interface IUpdateFileMeta {
    [key: string]: Store.IFileMeta;
}

export interface IProgress {
    loaded: number;
    total: number;
}

export interface IUploadError {
    errorMsg: string;
    file?: File;
}

export interface IUpdateFileState {
    fileKey: string;
    state: Store.FileStateEnum;
}

export interface IUpdateFileProgress {
    fileKey: string;
    progress: IProgress;
}

export interface IAddFileError {
    fileKey: string;
    error: IUploadError;
}

export const UPDATE_FILE_STATE = 'UPDATE_FILE_STATE';
export const updateFileState = createAction<
    IUpdateFileState,
    string,
    Store.FileStateEnum
>(UPDATE_FILE_STATE, (fileKey: string, state: Store.FileStateEnum) => ({
    fileKey,
    state,
}));

export const UPDATE_FILE_PROGRESS = 'UPDATE_FILE_PROGRESS';
export const updateFileProgress = createAction<
    IUpdateFileProgress,
    string,
    IProgress
>(UPDATE_FILE_PROGRESS, (fileKey: string, progress: IProgress) => ({
    fileKey,
    progress,
}));

export const ADD_FILE_ERROR = 'ADD_FILE_ERROR';
export const addFileError = createAction<IAddFileError, string, IUploadError>(
    ADD_FILE_ERROR,
    (fileKey: string, error: IUploadError) => ({ fileKey, error })
);

export const UPDATE_FILE_INFO = 'UPDATE_FILE_INFO';
export const updateFileInfo = createAction<
    IUpdateFileMeta,
    Store.IFileMeta | Store.IFileMeta[]
>(UPDATE_FILE_INFO, (files: Store.IFileMeta | Store.IFileMeta[]) => {
    if (!Array.isArray(files)) {
        return { [files.fid || files.name]: files };
    }

    const payload = {};
    (files as Store.IFileMeta[]).forEach((file) => {
        payload[file.fid || file.name] = file;
    });

    return payload;
});
