import React, { Component, ChangeEvent, FormEvent } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Tooltip from 'antd/es/tooltip';
import autoBind from 'autobind-decorator';

import { logIn, logInSuccess } from 'app/redux/actions/authorization';
import * as Store from 'app/redux/store/StoreNamespace';

import IState from './interfaces/ILoginState';
import IProps from './interfaces/ILoginProps';

import history from '../../history';
import clientRoutes from '../../routing/clientRoutes';

@autoBind
class Login extends Component<IProps, IState> {
    state: IState = {
        login: '',
        password: '',
        showPassword: false,
    };

    componentDidMount(): void {
        const { dispatch, alreadyAuthorized } = this.props;
        if (alreadyAuthorized) {
            dispatch(logInSuccess());
        }
    }

    componentDidUpdate(prevProps: IProps): void {
        const { dispatch, alreadyAuthorized } = this.props;
        if (prevProps.alreadyAuthorized === null && alreadyAuthorized) {
            dispatch(logInSuccess());
        }
    }

    onSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
    }

    onLogin(): void {
        this.props.dispatch(logIn(this.state.login, this.state.password));
    }

    onPasswordRecovery(): void {
        const { login } = this.state;

        history.push(
            clientRoutes.recoveryPassword.getUrl(null, { email: login })
        );
    }

    onLoginChange(e: ChangeEvent<HTMLInputElement>): void {
        this.setState({ login: e.currentTarget.value });
    }

    onPasswordChange(e: ChangeEvent<HTMLInputElement>): void {
        this.setState({ password: e.currentTarget.value });
    }

    togglePasswordVisibility() {
        this.setState((state) => ({ showPassword: !state.showPassword }));
    }

    render(): JSX.Element {
        const { authorizationFailed } = this.props;
        return (
            <div
                className={classNames('login', {
                    'login--failed': authorizationFailed,
                })}
            >
                <div className="login__content">
                    <div className="login__logo" />
                    <form onSubmit={this.onSubmit} className="login__form">
                        <div className="login__name">
                            <input
                                autoFocus
                                placeholder="Эл. почта"
                                value={this.state.login}
                                onChange={this.onLoginChange}
                            />
                        </div>
                        <div className="login__password">
                            <input
                                type={
                                    this.state.showPassword
                                        ? 'text'
                                        : 'password'
                                }
                                placeholder="Пароль"
                                value={this.state.password}
                                onChange={this.onPasswordChange}
                            />
                            <Tooltip
                                placement="topRight"
                                title="Показать/скрыть пароль"
                                mouseEnterDelay={0.3}
                            >
                                <button
                                    type="button"
                                    className="login__toggle-password-visibility btn"
                                    onClick={this.togglePasswordVisibility}
                                />
                            </Tooltip>
                        </div>
                        {authorizationFailed && (
                            <div className="login__fail-message">
                                Логин или пароль указаны неверно.
                            </div>
                        )}
                        <a
                            href="#"
                            className="login__pass-recovery"
                            onClick={this.onPasswordRecovery}
                        >
                            Восстановить пароль
                        </a>
                        <button
                            onClick={this.onLogin}
                            className="login__submit btn-primary"
                        >
                            Войти
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: Store.IState) => ({
    authorizationFailed: state.authorization.authorizationFailed,
    alreadyAuthorized: state.authorization.isAuthorized,
});

export default connect(mapStateToProps)(Login);
