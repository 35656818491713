// https://github.com/sindresorhus/pretty-bytes/tree/10fbba2871d977e1a78dbf1b4a734ce93a5ecea9
// Взят чтобы не ломалась минификация

const UNITS: string[] = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

/*
Formats the given number using `Number#toLocaleString`.
- If locale is a string, the value is expected to be a locale-key (for example: `de`).
- If locale is true, the system default locale is used for translation.
- If no value for locale is specified, the number is returned unmodified.
*/
const toLocaleString = (fileSize: number, locale: string): string | number => {
    let result: string | number = fileSize;
    if (typeof locale === 'string') {
        result = fileSize.toLocaleString(locale);
    } else if (locale === true) {
        result = fileSize.toLocaleString();
    }

    return result;
};

const prettyBytes = (fileSize: number, options: any): string => {
    // IE11: does not support Number.isFinite
    if (!isFinite(fileSize)) {
        throw new TypeError(
            `Expected a finite number, got ${typeof fileSize}: ${fileSize}`
        );
    }

    options = { ...options };

    if (options.signed && fileSize === 0) {
        return ' 0 B';
    }

    const isNegative = fileSize < 0;
    const prefix = isNegative ? '-' : options.signed ? '+' : '';

    if (isNegative) {
        fileSize = -fileSize;
    }

    if (fileSize < 1) {
        const fileSizeString = toLocaleString(fileSize, options.locale);
        return `${prefix + fileSizeString} B`;
    }

    const exponent = Math.min(
        Math.floor(Math.log10(fileSize) / 3),
        UNITS.length - 1
    );
    const roundFileSize = Number((fileSize / 1024 ** exponent).toPrecision(3));

    const roundFileSizeString = toLocaleString(roundFileSize, options.locale);
    const unit = UNITS[exponent];

    return `${prefix + roundFileSizeString} ${unit}`;
};

export { prettyBytes as default };
