/**
 * Created by lkarmelo on 15.11.2019.
 */

import memoizeOne from 'memoize-one';

import * as Store from 'app/redux/store/StoreNamespace';
import {
    FilterWithChildren,
    MetaWithNormalizedValues,
} from 'app/utils/filters/createNormalizedFiltersSelector';
import { ISelectOption } from 'app/modules/components/TreeSelect/Select/TreeSelect';

export type NormalizedMetaWithCount = MetaWithNormalizedValues<
    Store.IFilterMeta,
    { count?: number }
>;

type FilterValue = FilterWithChildren<{ count?: number }>;

export const createFiltersToOptions = () =>
    memoizeOne(
        (
            filtersMeta: NormalizedMetaWithCount[]
        ): { [filterName: string]: ISelectOption[] } => {
            const getMetaReducer =
                (
                    values: { [valId: string]: FilterValue },
                    filterName: string
                ) =>
                (result, valueId) => {
                    const value = values[valueId];
                    const option: ISelectOption = {
                        value: value.value,
                        label: value.label,
                        filterName,
                        count: value.count,
                    };
                    if (value.children) {
                        option.options = value.children.reduce(
                            getMetaReducer(values, filterName),
                            []
                        );
                    }
                    result.push(option);
                    return result;
                };

            return filtersMeta.reduce((res, filterMeta) => {
                res[filterMeta.filterName] = filterMeta.topValues.reduce(
                    getMetaReducer(filterMeta.values, filterMeta.filterName),
                    []
                );
                return res;
            }, {});
        }
    );
