import { Action, handleActions } from 'redux-actions';

import initialState from '../store/initialState';
import {
    setResourceView,
    ISetResourceViewPayload,
} from '../actions/conceptDescriptions';
import * as Store from '../store/StoreNamespace';

export default handleActions(
    {
        [setResourceView.toString()](
            state: Record<string, Store.IConceptDescription>,
            { payload }: Action<ISetResourceViewPayload>
        ): Record<string, Store.IConceptDescription> {
            return {
                ...state,
                [payload.conceptUri]: {
                    prefLabels: payload.prefLabels,
                    altLabels: payload.altLabels,
                    definition: payload.definition,
                    related: payload.related,
                },
            };
        },
    },
    initialState.conceptDescriptions
);
