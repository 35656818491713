import React, { PureComponent, MouseEvent, KeyboardEvent } from 'react';
import classNames from 'classnames';
import autoBind from 'autobind-decorator';
import { createSelector } from 'reselect';

import DropDownList, {
    IDropDownListRef,
} from 'app/components/common/controls/DropDownList';
import { isElementInParentChain } from 'app/utils/portalDOMUtils';
import { getRelatedTarget } from 'app/utils/getRelatedTargetFromFocusEvent';
import { highlightFunction } from 'app/redux/epics/highlight';
import { IOption } from 'app/components/common/controls/Option';

import IProps, { TitleType } from './interfaces/IDropDownProps';
import IState from './interfaces/IDropDownState';

import './DropDown.scss';

const findTitle = createSelector<
    IProps,
    IOption[],
    string | string[],
    TitleType,
    string,
    boolean,
    string,
    TitleType
>(
    (props: IProps) => props.options,
    (props: IProps) => props.active,
    (props: IProps) => props.title,
    (props: IProps) => props.placeholder,
    (props: IProps) => props.isMultiSelect,
    (props: IProps) => props.titlePrefix,
    (
        options: IOption[],
        active: string | string[],
        title: TitleType,
        placeholder: string,
        isMultiSelect: boolean,
        titlePrefix: string
    ): TitleType => {
        if (title) {
            return title;
        }

        if (!active || active.length < 1) {
            return placeholder;
        }
        if (isMultiSelect) {
            const labels = [];
            active &&
                (active as string[]).forEach((val) => {
                    const foundOption = options.find(
                        (opt) => val === opt.value
                    );
                    foundOption && labels.push(foundOption.label);
                });
            return `${titlePrefix}${labels.join(', ')}`;
        }
        const foundOption = options.find((opt) => opt.value === active);
        return `${titlePrefix}${foundOption ? foundOption.label : ''}`;
    }
);

const filterOptions = createSelector<
    { state: IState; props: IProps },
    IOption[],
    string,
    IOption[]
>(
    ({ props }: { state: IState; props: IProps }) => props.options,
    ({ state }: { state: IState; props: IProps }) => state.searchQuery,
    (options: IOption[], searchQuery: string): IOption[] => {
        if (!searchQuery) {
            return options;
        }
        return options
            .filter(
                (opt) =>
                    opt.label
                        .toLowerCase()
                        .indexOf(searchQuery.toLowerCase()) >= 0
            )
            .map((opt) => ({
                ...opt,
                label: highlightFunction(
                    searchQuery.toLowerCase(),
                    '<span class="highlight-search">',
                    '</span>'
                )(opt.label),
            }))
            .sort((optA, optB) => {
                const queryIndexA = optA.label.indexOf(searchQuery);
                const queryIndexB = optB.label.indexOf(searchQuery);
                if (queryIndexA < queryIndexB) {
                    return -1;
                }
                if (queryIndexA > queryIndexB) {
                    return 1;
                }
                return 0;
            });
    }
);

@autoBind
export default class DropDown extends PureComponent<IProps, IState> {
    static defaultProps = {
        options: [],
        titlePrefix: '',
        shouldCloseOnSelect: true,
        optionRenderer: (value: string, label: string, meta?: any) => (
            <div className="select__field-option">
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: label }} />
                {!!meta.description && (
                    <div className="select__field-option-description">
                        {meta.description}
                    </div>
                )}
            </div>
        ),
    };

    state: IState = {
        isOpened: false,
        isUserTyping: false,
        searchQuery: '',
    };

    isClicked = false;

    dropDownListRef: React.RefObject<IDropDownListRef> = React.createRef();

    inputRef: React.RefObject<HTMLInputElement> = React.createRef();

    selfRef: React.RefObject<HTMLDivElement> = React.createRef();

    render(): JSX.Element {
        const {
            options,
            active,
            disabled,
            focusable,
            prompt,
            isFilterable,
            optionRenderer,
        } = this.props;
        const { searchQuery, isUserTyping } = this.state;

        const title = findTitle(this.props);
        return (
            <div
                ref={this.selfRef}
                onClick={isFilterable ? undefined : this.toggleOpenState}
                className={classNames('select', {
                    'select--opened': this.state.isOpened,
                    'select--disabled': disabled,
                    'select--filterable': isFilterable,
                })}
                onMouseDown={this.setClickedTrue}
                onMouseUp={this.setClickedFalse}
                tabIndex={isFilterable && !disabled ? 1 : undefined}
                onBlur={this.onBlur}
                title={prompt}
            >
                {isFilterable ? (
                    <div
                        className="select__input-title-wrapper"
                        onClick={this.onInputTitleWrapperClick}
                    >
                        {searchQuery === '' && (
                            <span
                                className={classNames('select__input-title', {
                                    'select__input-title--typing': isUserTyping,
                                })}
                                title={title as string}
                            >
                                {title}
                            </span>
                        )}
                        <input
                            className="select__input"
                            ref={this.inputRef}
                            type="text"
                            value={searchQuery}
                            onChange={this.onInputChange}
                            onFocus={this.onInputFocus}
                            disabled={disabled}
                            onKeyDown={this.onInputKeyDown}
                        />
                        <span className="select__arrow" />
                    </div>
                ) : (
                    <div
                        className="select__field"
                        tabIndex={focusable ? 0 : null}
                        onKeyDown={this.onFieldKeyDown}
                    >
                        <span
                            className={classNames('select__field-title', {
                                select__placeholder:
                                    !active || active.length < 1,
                            })}
                        >
                            {title}
                        </span>
                        <span className="select__arrow" />
                    </div>
                )}
                <DropDownList
                    ref={this.dropDownListRef}
                    options={
                        isFilterable
                            ? filterOptions({
                                  props: this.props,
                                  state: this.state,
                              })
                            : options
                    }
                    isOpened={this.state.isOpened}
                    onDocumentMouseDown={this.closeOnOutsideClick}
                    onSelectOption={this.onSelectOption}
                    onDeselectOption={this.onDeselectOption}
                    classPrefix="select"
                    isOptionActive={this.isOptionActive}
                    active={active}
                    optionRenderer={optionRenderer}
                />
            </div>
        );
    }

    closeOnOutsideClick(): void {
        if (!this.isClicked && this.state.isOpened) {
            this.setState({ isOpened: false });
        }
    }

    toggleOpenState(e: MouseEvent<HTMLDivElement>): void {
        e.stopPropagation();
        !this.props.disabled &&
            this.setState((state) => ({ isOpened: !state.isOpened }));
    }

    setClickedTrue(): void {
        this.isClicked = true;
    }

    setClickedFalse(): void {
        this.isClicked = false;
    }

    onBlur(e: React.FocusEvent<HTMLDivElement>): void {
        getRelatedTarget(e).then((relatedTarget) => {
            if (
                !relatedTarget ||
                (!isElementInParentChain(
                    this.dropDownListRef.current.optionListRef.current,
                    relatedTarget
                ) &&
                    relatedTarget !== this.inputRef.current &&
                    relatedTarget !== this.selfRef.current)
            ) {
                !this.isClicked &&
                    this.setState({
                        isUserTyping: false,
                        searchQuery: '',
                        isOpened: false,
                    });
            }
        });
    }

    onInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const { onInputValueChange } = this.props;
        const inputValue = e.currentTarget.value;

        onInputValueChange && onInputValueChange(inputValue);
        this.setState({ searchQuery: inputValue, isOpened: true });
    }

    onInputFocus(): void {
        this.setState({ isUserTyping: true });
    }

    onInputTitleWrapperClick(): void {
        if (this.props.disabled) {
            return;
        }
        !this.state.isOpened && this.setState({ isOpened: true });
        this.inputRef.current.focus();
    }

    onInputKeyDown(e: KeyboardEvent<HTMLInputElement>): void {
        const input = e.currentTarget;
        if (e.keyCode === 40) {
            e.preventDefault();
            if (input.selectionStart === input.value.length) {
                this.openAndFocusFirstOption();
            } else {
                input.selectionStart = input.selectionEnd = input.value.length;
            }
        }
    }

    onFieldKeyDown(e: KeyboardEvent<HTMLDivElement>): void {
        if (e.keyCode === 40 || e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();
            this.openAndFocusFirstOption();
        }
    }

    openAndFocusFirstOption() {
        if (!this.state.isOpened) {
            this.setState(
                { isOpened: true },
                () =>
                    this.dropDownListRef &&
                    this.dropDownListRef.current.highlightOption(0)
            );
        } else {
            this.dropDownListRef &&
                this.dropDownListRef.current.highlightOption(0);
        }
    }

    onSelectOption(value: string, label: string, meta: any): void {
        const {
            isMultiSelect,
            filterName,
            onSelectOption,
            shouldCloseOnSelect,
        } = this.props;
        const shouldClose =
            !isMultiSelect &&
            (typeof shouldCloseOnSelect === 'function'
                ? shouldCloseOnSelect(value)
                : shouldCloseOnSelect);

        if (shouldClose) {
            this.setState({
                isOpened: false,
                isUserTyping: false,
                searchQuery: '',
            });
        }
        onSelectOption(value, filterName, meta, label);
    }

    onDeselectOption(value: string, label: string, meta: any): void {
        const { isMultiSelect, isClearable, onDeselectOption, filterName } =
            this.props;
        if (isMultiSelect || isClearable) {
            onDeselectOption &&
                onDeselectOption(value, filterName, meta, label);
        } else if (!isMultiSelect && !isClearable) {
            this.setState({
                isOpened: false,
                isUserTyping: false,
                searchQuery: '',
            });
        }
    }

    isOptionActive(opt: IOption): boolean {
        const { isMultiSelect, active } = this.props;
        return isMultiSelect
            ? active && active.includes(opt.value)
            : active === opt.value;
    }
}
