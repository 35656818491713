import React, { useCallback } from 'react';
import { FixedSizeTree } from 'react-vtree';
import AutoSizer from 'react-virtualized-auto-sizer';

import { GetPropsFunc, ListSelectDeselectFunc } from './List';

import { ISelectOption } from '..';

interface IVirtualOptionsRendererProps {
    /**
     * высота элемента списка, см. документацию react-vtree и react-window
     */
    itemSize: number;

    activeMap: Record<string, boolean>;
    intermediateMap: Record<string, boolean>;
    options: ISelectOption[];

    onSelect: ListSelectDeselectFunc;
    onDeselect: ListSelectDeselectFunc;

    initiallyOpenedNestedLevel?: number;
    OptionGroupComponent?: React.ComponentType<any>;
    OptionComponent?: React.ComponentType<any>;
    optionComponentProps?: Record<string, any> | GetPropsFunc;
    optionGroupComponentProps?: Record<string, any> | GetPropsFunc;

    getSortedOptionChildren?(
        options: ISelectOption[],
        parentOption?: ISelectOption
    ): ISelectOption[];
}

export const VirtualOptionsRenderer: React.FC<IVirtualOptionsRendererProps> = (
    props
) => {
    const {
        options,
        initiallyOpenedNestedLevel,
        activeMap,
        OptionGroupComponent,
        OptionComponent,
        optionComponentProps,
        optionGroupComponentProps,
        onSelect,
        onDeselect,
        intermediateMap,
        itemSize,
        getSortedOptionChildren,
    } = props;

    const treeWalker = useCallback(
        function* _treeWalker(refresh: boolean) {
            const stack: { nestedLevel: number; option: ISelectOption }[] = [];

            const sortedOptions =
                getSortedOptionChildren?.(options, undefined) ?? options;

            for (let i = sortedOptions.length - 1; i >= 0; i--) {
                stack.push({
                    nestedLevel: 1,
                    option: sortedOptions[i],
                });
            }

            // Walk through the tree until we have no nodes available.
            while (stack.length !== 0) {
                const { option, nestedLevel } = stack.pop();

                // Here we are sending the information about the node to the Tree component
                // and receive an information about the openness state from it. The
                // `refresh` parameter tells us if the full update of the tree is requested;
                // basing on it we decide to return the full node data or only the node
                // id to update the nodes order.
                const isOpened = yield refresh
                    ? {
                          id: option.value,
                          isOpenByDefault:
                              nestedLevel <= initiallyOpenedNestedLevel,
                          nestedLevel,
                          ...option,
                      }
                    : option.value;

                // Basing on the node openness state we are deciding if we need to render
                // the child nodes (if they exist).
                if (
                    Array.isArray(option.options) &&
                    option.options.length !== 0 &&
                    isOpened
                ) {
                    // Since it is a stack structure, we need to put nodes we want to render
                    // first to the end of the stack.
                    const sortedSubOptions =
                        getSortedOptionChildren?.(option.options, option) ??
                        option.options;
                    for (let i = sortedSubOptions.length - 1; i >= 0; i--) {
                        stack.push({
                            nestedLevel: nestedLevel + 1,
                            option: sortedSubOptions[i],
                        });
                    }
                }
            }
        },
        [options, initiallyOpenedNestedLevel, getSortedOptionChildren]
    );

    const Node = useCallback(
        ({ data: node, isOpen, style, toggle }) => {
            const hasSubOptions =
                Array.isArray(node.options) && node.options.length > 0;

            const Component = hasSubOptions
                ? OptionGroupComponent
                : OptionComponent;

            let additionalProps;

            if (hasSubOptions) {
                additionalProps =
                    typeof optionGroupComponentProps === 'function'
                        ? optionGroupComponentProps(node)
                        : optionGroupComponentProps;
            } else {
                additionalProps =
                    typeof optionComponentProps === 'function'
                        ? optionComponentProps(node)
                        : optionComponentProps;
            }

            const marginLeft = 24 * (node.nestedLevel - 1);
            return (
                <div
                    style={{
                        ...style,
                        marginLeft,
                        width: `calc(100% - ${marginLeft}px)`,
                    }}
                    className="nkcm-select-vwrapper"
                >
                    <Component
                        key={node.value}
                        active={!!activeMap[node.value]}
                        onSelect={onSelect}
                        onDeselect={onDeselect}
                        option={node}
                        opened={isOpen}
                        toggleOpened={toggle}
                        intermediate={!!intermediateMap?.[node.value]}
                        options={node.options}
                        {...additionalProps}
                    />
                </div>
            );
        },
        [
            onSelect,
            onDeselect,
            activeMap,
            OptionGroupComponent,
            OptionComponent,
            optionGroupComponentProps,
            optionComponentProps,
            onDeselect,
            onSelect,
        ]
    );

    return (
        <AutoSizer>
            {({ height, width }) => (
                <FixedSizeTree
                    height={height}
                    treeWalker={treeWalker}
                    itemSize={itemSize}
                    width={width}
                    overscanCount={10}
                >
                    {Node}
                </FixedSizeTree>
            )}
        </AutoSizer>
    );
};
