/**
 * Created by Lkarmelo on 07.03.2018.
 */

import { createAction } from 'redux-actions';

export const fetchCatalogueDocuments = createAction<{ id: string }>(
    'FETCH_CATALOGUE_DOCUMENTS'
);

export interface IConfirmDocumentCategoryPayload {
    docId: string;
    categoryId: string;
}
export const confirmDocumentCategory =
    createAction<IConfirmDocumentCategoryPayload>('DOCUMENT_CONFIRM_CATEGORY');

export const revertDocumentCategory =
    createAction<IConfirmDocumentCategoryPayload>('DOCUMENT_REVERT_CATEGORY');
