/**
 * Created by lkarmelo on 13.11.2019.
 */
import { createAction } from 'redux-actions';

import * as Store from 'app/redux/store/StoreNamespace';

export const fetchRedirectInfo = createAction<string>('REDIRECT_INFO_FETCH');

export const setRedirectInfo =
    createAction<Store.IRedirectInfo>('REDIRECT_INFO_SET');
