import React, { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';

import DropDownMenu from 'app/components/common/DropDownMenu';
import { useTypedSelector } from 'app/hooks/useTypedSelector';

import * as styles from './NavigationMenu.scss';
import Content from './components';

const NavigationMenu: React.FC = () => {
    const { isOpen: isDropdownVisible } = useTypedSelector(
        (store) => store.navMenuDropdownVisibility
    );

    const dropDownMenuRef: React.MutableRefObject<DropDownMenu> = useRef();
    const [isNavMenuEnabled, setIsNavMenuEnabled] = useState(false);

    const onClose = useCallback((): void => {
        dropDownMenuRef.current && dropDownMenuRef.current.close();
    }, []);

    const renderContent = (close: (e: React.MouseEvent<any>) => void) => {
        return <Content onClose={onClose} />;
    };

    return (
        <DropDownMenu
            ref={dropDownMenuRef}
            className={styles.navMenu}
            onOpen={() => setIsNavMenuEnabled(true)}
            onClose={() => setIsNavMenuEnabled(false)}
            dropDownContentRenderer={renderContent}
            toggleElement={
                <div
                    className={classNames(styles.navMenuToggle, {
                        [styles.navMenuToggleEnabled]: isNavMenuEnabled,
                    })}
                />
            }
            isDropdownVisible={isDropdownVisible}
        />
    );
};

export default NavigationMenu;
