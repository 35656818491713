import React, { FC } from 'react';

import * as styles from '../../SearchPanel.scss';
import { FilterHintOptionMeta } from '../../types';

interface IRenderFilterHintContent {
    label: string;
    meta: FilterHintOptionMeta;
}

export const RenderFilterHintContent: FC<IRenderFilterHintContent> = (
    {
        label,
        meta: { sectionTitle },
    }) => {
    return (
        <div className={`${styles.searchPanelFilterHint}`}>
            {sectionTitle && <div className={styles.searchPanelFilterHintSectionTitle}>
                {sectionTitle}
            </div>}
            <div className={styles.searchPanelFilterHintLabel} dangerouslySetInnerHTML={{ __html: label }} />
        </div>
    );
};
