/**
 * Created by lkarmelo on 23.07.2019.
 */

import { MiddlewareAPI } from 'redux';

import * as Store from 'app/redux/store/StoreNamespace';

export const getSearchState = (
    store: MiddlewareAPI<Store.IState>
): Store.ISearch => store.getState().search;
