type GetValue<Item, Value> = (item: Item) => Value;

const getMapFromArray = <Item, Value, Key extends string | number | symbol>(
    array: Item[],
    value: Value | GetValue<Item, Value>,
    getKey: (item: Item) => Key = (item: unknown) => item as Key
) => {
    return array.reduce<Record<Key, Value>>((map, item) => {
        if (typeof value === 'function') {
            map[getKey(item)] = (value as GetValue<Item, Value>)(item);
        } else {
            map[getKey(item)] = value;
        }
        return map;
    }, {} as Record<Key, Value>);
};

export default getMapFromArray;
