/**
 * Created by Lkarmelo on 31.10.2017.
 */

import Option from './Option';
import IOption from './interfaces/IOption';

export default Option;

export { IOption };
