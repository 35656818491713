/**
 * Created by lkarmelo on 25.04.2019.
 */
import { handleActions, Action } from 'redux-actions';

import * as Store from 'app/redux/store/StoreNamespace';
import { setInitialFilterStats } from 'app/redux/actions/search/initialFilterStats';

export default handleActions(
    {
        [setInitialFilterStats.toString()]: (
            _,
            { payload }: Action<Store.IFilterStats>
        ) => payload,
    },
    {}
);
