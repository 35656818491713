import React, {
    useState,
    useCallback,
    useRef,
    useLayoutEffect,
    useMemo,
    useEffect,
} from 'react';
import classNames from 'classnames';
import throttle from 'lodash/throttle';

import DropDownMenu from 'app/components/common/DropDownMenu';
import { swapArrayElements } from 'app/utils/swapArrayElements';
import * as Store from 'app/redux/store/StoreNamespace';

import { Tab } from './Tab';
import cssVars, * as styles from './DocumentSearchTabsPanel.scss';

const tabMargin = parseInt((cssVars as any).tabmargin, 10);
const showMoreBtnWidth = parseInt((cssVars as any).showmorebtnwidth, 10);

export interface IDocumentSearchTabsPanelStateProps {
    tabs?: Record<string, Store.ISearchTab>;
    tabCodes?: string[];
    activeTab?: string | null;
    lastExecutedSearchQuery: string;
}

export interface IDocumentSearchTabsPanelActionProps {
    setActiveTab(tabCode: string): void;
}

export interface IDocumentSearchTabsPanelProps
    extends IDocumentSearchTabsPanelStateProps,
        IDocumentSearchTabsPanelActionProps {}

/**
 * Выносит выбранную вкладку из дропдауна в панель вкладок.
 * Последнюю вкладку на панели выносим в дропдаун на позицию согласно порядковому номеру.
 */
export const swapLastVisibleTabWithActiveTab = (
    tabs: string[],
    lastVisibleTabIndex: number,
    activeTabIndex: number,
    baseLastVisibleTabIndex: number,
    tabCodes: string[]
) => {
    swapArrayElements(
        tabs,
        lastVisibleTabIndex,
        activeTabIndex,
        baseLastVisibleTabIndex,
        tabCodes
    );
};

const DocumentSearchTabsPanel: React.FC<IDocumentSearchTabsPanelProps> = (
    props
) => {
    const { tabs, tabCodes, activeTab, setActiveTab, lastExecutedSearchQuery } =
        props;

    const panelRef = useRef<HTMLDivElement>();
    const tabsForMeasurementRefs = useRef<HTMLButtonElement[]>([]);

    const [tabCodesWithCustomOrder, setTabCodesWithCustomOrder] =
        useState<string[]>(tabCodes);
    const [visibleTabsCount, setVisibleTabsCount] = useState<number>(0);
    const [isClickHiddenTab, setIsClickHiddenTab] = useState<boolean>(false);
    const [isWideTab, setIsWideTab] = useState<boolean>(false);

    const tabsForMeasurement = useMemo(() => {
        return tabCodes.map((code, i) => (
            <Tab
                ref={(el) => {
                    tabsForMeasurementRefs.current[i] = el;
                }}
                key={tabs[code]?.id}
                isActive={code === activeTab}
                name={tabs[code]?.name}
            />
        ));
    }, [tabCodes, tabs]);

    const onResize = useCallback(() => {
        setIsWideTab(false);
        const panel = panelRef.current;
        const tabsForMeasurementElements = tabsForMeasurementRefs.current;
        if (!panel || tabsForMeasurementElements.length === 0) {
            return;
        }

        const tabPanelWidth = panel.offsetWidth;

        let tabsWidth = 0;
        let tabsCount = 0;

        for (let i = 0; i < tabsForMeasurementElements.length; i++) {
            const tabElement = tabsForMeasurementElements.find(
                (item) =>
                    Object.values(tabs).find(
                        (tab) => tabCodesWithCustomOrder[i] === tab.code
                    ).name === item.innerHTML
            );
            tabsWidth += tabElement.offsetWidth + tabMargin;

            if (tabsWidth >= tabPanelWidth - showMoreBtnWidth) {
                if (tabsCount === 0 && tabsWidth > 0) {
                    tabsCount = 1;
                    setIsWideTab(true);
                }
                break;
            }

            tabsCount++;
        }

        const activeTabIndex = tabCodesWithCustomOrder.findIndex(
            (tab) => tab === activeTab
        );
        const lastVisibleTabIndex = tabsCount === 0 ? 0 : tabsCount - 1;
        const baseLastVisibleTabIndex = tabCodes.findIndex(
            (tab) => tab === tabCodesWithCustomOrder[lastVisibleTabIndex]
        );

        if (
            activeTabIndex > lastVisibleTabIndex ||
            (activeTabIndex < lastVisibleTabIndex &&
                baseLastVisibleTabIndex !== lastVisibleTabIndex)
        ) {
            const newTabCodes = [...tabCodesWithCustomOrder];
            swapLastVisibleTabWithActiveTab(
                newTabCodes,
                lastVisibleTabIndex,
                activeTabIndex,
                baseLastVisibleTabIndex,
                tabCodes
            );

            setTabCodesWithCustomOrder(newTabCodes);
        }

        setVisibleTabsCount(tabsCount);
    }, [tabCodes, tabCodesWithCustomOrder, activeTab, visibleTabsCount]);

    const searchExecuted = typeof lastExecutedSearchQuery === 'string';

    useLayoutEffect(() => {
        if (searchExecuted) {
            onResize();
        }
    }, [searchExecuted, tabCodesWithCustomOrder, isWideTab]);

    /**
     * Обрабатываем событие клика по скрытой вкладке. В зависимости от выбранной вкладки
     * будет меняться количество видимых вкладок и нужно запускать эту обработку несколько
     * раз пока выбранная вкладка не встанет на своё место или последней в списке видимых.
     */
    useEffect(() => {
        const activeTabIndex = tabCodesWithCustomOrder.findIndex(
            (tab) => tab === activeTab
        );
        const lastVisibleTabIndex = visibleTabsCount - 1;
        const baseActiveTabIndex = tabCodes.findIndex(
            (tab) => tab === tabCodesWithCustomOrder[activeTabIndex]
        );

        if (baseActiveTabIndex === activeTabIndex) {
            setIsClickHiddenTab(false);
            return;
        }

        if (isClickHiddenTab) {
            if (activeTabIndex < lastVisibleTabIndex) {
                const newTabCodes = [...tabCodesWithCustomOrder];
                const activeElement = newTabCodes.splice(activeTabIndex, 1);
                newTabCodes.splice(
                    baseActiveTabIndex < lastVisibleTabIndex
                        ? baseActiveTabIndex
                        : lastVisibleTabIndex,
                    0,
                    activeElement[0]
                );
                setTabCodesWithCustomOrder(newTabCodes);
            } else if (activeTabIndex > lastVisibleTabIndex) {
                onResize();
            }
        }
        if (activeTabIndex === lastVisibleTabIndex) {
            setIsClickHiddenTab(false);
        }
    }, [visibleTabsCount]);

    const onResizeThrottle = useCallback(
        throttle(() => onResize(), 100),
        [onResize, tabCodesWithCustomOrder]
    );

    useEffect(() => {
        if (searchExecuted) {
            window.addEventListener('resize', onResizeThrottle);
        }

        return () => window.removeEventListener('resize', onResizeThrottle);
    }, [searchExecuted, tabCodesWithCustomOrder, visibleTabsCount]);

    const tabCodesVisible = useMemo(
        () => tabCodesWithCustomOrder.slice(0, visibleTabsCount),
        [tabCodesWithCustomOrder, visibleTabsCount]
    );
    const tabCodesHidden = useMemo(
        () => tabCodesWithCustomOrder.slice(visibleTabsCount),
        [tabCodesWithCustomOrder, visibleTabsCount]
    );

    const titleTab = (code) =>
        tabs[code]?.description ? tabs[code]?.description : tabs[code]?.name;

    return (
        <div
            ref={panelRef}
            className={styles.documentSearchTabsPanel}
            id="search-tabs-panel"
        >
            {tabCodesVisible.map((code) => (
                <Tab
                    key={tabs[code]?.id}
                    isActive={code === activeTab}
                    name={tabs[code]?.name}
                    onClick={() => setActiveTab(code)}
                    title={titleTab(code)}
                    isWideTab={isWideTab}
                />
            ))}
            {tabCodesHidden.length > 0 && (
                <DropDownMenu
                    className={classNames(
                        'btn',
                        styles.documentSearchTabsPanelShowMore
                    )}
                    dropDownContentRenderer={(close) => (
                        <div
                            className={
                                styles.documentSearchTabsPanelShowMoreList
                            }
                        >
                            {tabCodesHidden.map((code) => (
                                <Tab
                                    hiddenInDropdown
                                    key={tabs[code]?.id}
                                    isActive={code === activeTab}
                                    name={tabs[code]?.name}
                                    title={titleTab(code)}
                                    onClick={() => {
                                        setIsClickHiddenTab(true);
                                        const newTabCodes = [
                                            ...tabCodesWithCustomOrder,
                                        ];

                                        const lastVisibleTabIndex =
                                            visibleTabsCount - 1;
                                        const baseLastVisibleTabIndex =
                                            tabCodes.findIndex(
                                                (tab) =>
                                                    tab ===
                                                    tabCodesWithCustomOrder[
                                                        lastVisibleTabIndex
                                                    ]
                                            );
                                        const newActiveTabIndex =
                                            newTabCodes.findIndex(
                                                (tab) => tab === code
                                            );

                                        swapLastVisibleTabWithActiveTab(
                                            newTabCodes,
                                            lastVisibleTabIndex,
                                            newActiveTabIndex,
                                            baseLastVisibleTabIndex,
                                            tabCodes
                                        );

                                        setTabCodesWithCustomOrder(newTabCodes);
                                        setActiveTab(code);
                                        close();
                                    }}
                                />
                            ))}
                        </div>
                    )}
                    toggleElement={
                        searchExecuted && (
                            <button
                                key="show-more-btn"
                                className={classNames(
                                    'btn',
                                    styles.documentSearchTabsPanelShowMoreBtn
                                )}
                            />
                        )
                    }
                />
            )}
            {/* рендерим все вкладки со стилями как на панели вкладок,
                для того, чтобы замерить их ширину
                Приходится скрывать их так криво от пользователя,
                потому что иначе не получается узнать ширину этих вкладок
            */}
            <div className={styles.tabsForMeasurement}>
                {tabsForMeasurement}
            </div>
        </div>
    );
};

DocumentSearchTabsPanel.defaultProps = {
    tabCodes: [],
};

export default DocumentSearchTabsPanel;
