/**
 * Created by Lkarmelo on 23.05.2018.
 */

import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import autoBind from 'autobind-decorator';
import moment from 'moment';
import classNames from 'classnames';
import Col from 'antd/es/col';
import Row from 'antd/es/row';

import Form from 'app/components/common/forms/Form';
import Competencies from 'app/components/account/ProfilePanels/Competencies/presentational/Competencies';
import clientRoutes from 'app/routing/clientRoutes';
import {
    DATE_DOTS_FORMAT,
    DATE_INPUT_DEFAULT_MIN_VALUE,
    DATE_INPUT_DEFAULT_MAX_VALUE,
    DATE_INPUT_FORMAT_HINT,
} from 'app/utils/constants';
import * as Store from 'app/redux/store/StoreNamespace';

import IProps from './interfaces/IAddDocumentProps';
import IState from './interfaces/IAddDocumentState';
import * as styles from './AddDocument.scss';

@autoBind
export default class AddDocument extends PureComponent<IProps, IState> {
    static defaultProps: Pick<
        IProps,
        | 'competencies'
        | 'category'
        | 'formTitle'
        | 'isDisabled'
        | 'cancelBtnLink'
    > = {
        competencies: [],
        category: [],
        formTitle: 'Добавление документа',
        isDisabled: false,
        cancelBtnLink: clientRoutes.documentManagement.subRoutes.all.getUrl(),
    };

    state = {
        isFormValidationFailedOnSave: false,
    };

    formRef: React.RefObject<typeof Form.prototype> = React.createRef();

    componentDidMount(): void {
        this.props.onMount();
    }

    componentWillUnmount(): void {
        this.props.onUnmount();
    }

    changeField(value: string, { fieldName }: { fieldName: string }): void {
        this.props.onChangeField(fieldName, value);
    }

    changeSelection(
        value: string,
        optMeta: any,
        { fieldName }: { fieldName: string }
    ): void {
        this.props.onChangeField(fieldName, value);
    }

    select(
        value: string,
        optMeta: any,
        { fieldName }: { fieldName: string }
    ): void {
        this.props.onSelect(fieldName, value);
    }

    deSelect(
        value: string,
        optMeta: any,
        { fieldName }: { fieldName: string }
    ): void {
        this.props.onDeSelect(fieldName, value);
    }

    addCompetence(competence: Store.ISimpleCatalogItem): void {
        this.props.onAddCompetence('competencies', competence);
    }

    changeCompetenceGrade(index: number, grade: number): void {
        this.props.onChangeCompetenceGrade(
            'competencies',
            index,
            this.props.competencies[index],
            grade
        );
    }

    changeCompetence(
        index: number,
        competence: Store.ISimpleCatalogItem
    ): void {
        this.props.onChangeCompetence('competencies', index, competence);
    }

    removeCompetence(index: number): void {
        this.props.onRemoveCompetence('competencies', index);
    }

    send(): void {
        const { onSend } = this.props;
        const { isFormValidationFailedOnSave } = this.state;
        if (!this.formRef.current.getIsFormValid(true)) {
            !isFormValidationFailedOnSave &&
                this.setState({ isFormValidationFailedOnSave: true });
            return;
        }
        isFormValidationFailedOnSave &&
            this.setState({ isFormValidationFailedOnSave: false });
        onSend();
    }

    onFormSavedSuccess(): void {
        this.props.onFormSaved(this.props);
    }

    render(): JSX.Element {
        const {
            status,
            documentClass,
            type,
            title,
            org,
            source,
            description,
            date,
            category,
            competencies,
            author,
            allDocumentClasses,
            allDocumentTypes,
            allCategories,
            allCompetencies,
            onValidationFail,
            onValidationSuccess,
            formTitle,
            isDisabled,
            cancelBtnLink,
        } = this.props;

        return (
            <section
                className={classNames(styles.addDocument, {
                    [styles.addDocumentDisabled]: isDisabled,
                })}
            >
                {isDisabled && (
                    <div className={styles.addDocumentDisableOverlay} />
                )}
                <header className={styles.addDocumentHeader}>
                    <h1>{formTitle}</h1>
                </header>
                <article className={styles.addDocumentContent}>
                    <Row>
                        <Col lg={13}>
                            <Form
                                ref={this.formRef}
                                status={status}
                                activateValidationForAllFields={
                                    this.state.isFormValidationFailedOnSave
                                }
                                validation={{
                                    title: [
                                        (value: string) => ({
                                            isValid: !!value && !!value.trim(),
                                            message:
                                                'Поле должно быть не пустым и содержать не только пробелы',
                                        }),
                                    ],
                                    source: [
                                        (value: string) => ({
                                            isValid: !!value && !!value.trim(),
                                            message:
                                                'Поле должно быть не пустым и содержать не только пробелы',
                                        }),
                                    ],
                                    description: [
                                        (value: string) => ({
                                            isValid: !!value && !!value.trim(),
                                            message:
                                                'Поле должно быть не пустым и содержать не только пробелы',
                                        }),
                                    ],
                                    category: [
                                        (value: string[]) => ({
                                            isValid:
                                                !!value && value.length > 0,
                                        }),
                                    ],
                                    type: [
                                        (value: string) => ({
                                            isValid: !!value,
                                        }),
                                    ],

                                    author: [
                                        (value: string) => ({
                                            isValid:
                                                !value ||
                                                (!!value && !!value.trim()),
                                            message:
                                                'Поле не должно содержать только пробелы',
                                        }),
                                    ],
                                    org: [
                                        (value: string) => ({
                                            isValid:
                                                !value ||
                                                (!!value && !!value.trim()),
                                            message:
                                                'Поле не должно содержать только пробелы',
                                        }),
                                    ],
                                    date: [
                                        (val: string | number) => ({
                                            isValid: typeof val !== 'string',
                                            message: `Дата должна быть указана в формате ${DATE_INPUT_FORMAT_HINT}`,
                                        }),
                                        (val: string | number) => {
                                            const isValNotNumber =
                                                typeof val === 'string';
                                            return {
                                                isValid:
                                                    isValNotNumber ||
                                                    (val <=
                                                        DATE_INPUT_DEFAULT_MAX_VALUE &&
                                                        val >=
                                                            DATE_INPUT_DEFAULT_MIN_VALUE),
                                                message:
                                                    `Дата должна быть указана от ` +
                                                    `${moment(
                                                        DATE_INPUT_DEFAULT_MIN_VALUE
                                                    ).format(
                                                        DATE_DOTS_FORMAT
                                                    )} ` +
                                                    `до ${moment(
                                                        DATE_INPUT_DEFAULT_MAX_VALUE
                                                    ).format(
                                                        DATE_DOTS_FORMAT
                                                    )}`,
                                            };
                                        },
                                    ],
                                }}
                                onValidationFail={onValidationFail}
                                onValidationSuccess={onValidationSuccess}
                                fields={{
                                    documentClass: {
                                        value: documentClass,
                                        title: 'Документ',
                                        type: 'select',
                                        options: allDocumentClasses,
                                        onSelectOption: this.changeSelection,
                                        meta: { fieldName: 'documentClass' },
                                        isDisabled: true,
                                        isRequired: true,
                                    },
                                    type: {
                                        value: type,
                                        title: 'Тип обучающего материала',
                                        type: 'select',
                                        options: allDocumentTypes,
                                        onSelectOption: this.changeSelection,
                                        meta: { fieldName: 'type' },
                                        isRequired: true,
                                    },
                                    title: {
                                        value: title,
                                        title: 'Название',
                                        type: 'text',
                                        onTextChange: this.changeField,
                                        meta: { fieldName: 'title' },
                                        isRequired: true,
                                    },
                                    author: {
                                        value: author,
                                        title: 'Автор / Преподаватель',
                                        type: 'text',
                                        onTextChange: this.changeField,
                                        meta: { fieldName: 'author' },
                                    },
                                    org: {
                                        value: org,
                                        title: 'Организация',
                                        type: 'text',
                                        onTextChange: this.changeField,
                                        meta: { fieldName: 'org' },
                                    },
                                    source: {
                                        value: source,
                                        title: 'Источник',
                                        type: 'text',
                                        placeholder:
                                            'Введите URL адрес документа',
                                        onTextChange: this.changeField,
                                        meta: { fieldName: 'source' },
                                        isRequired: true,
                                    },
                                    description: {
                                        value: description,
                                        title: 'Описание',
                                        type: 'text',
                                        isMultiLine: true,
                                        onTextChange: this.changeField,
                                        meta: { fieldName: 'description' },
                                        isRequired: true,
                                    },
                                    date: {
                                        value: date,
                                        type: 'date',
                                        title: 'Дата материала',
                                        onDateChange: this.changeField,
                                        meta: { fieldName: 'date' },
                                        isRequired: true,
                                    },
                                    category: {
                                        value: category,
                                        title: 'Категории',
                                        type: 'multiselect',
                                        options: allCategories,
                                        onSelectOption: this.select,
                                        onDeselectOption: this.deSelect,
                                        meta: { fieldName: 'category' },
                                        isRequired: true,
                                    },
                                }}
                                onFormSaved={this.onFormSavedSuccess}
                            />
                        </Col>
                    </Row>
                    <div className={styles.addDocumentProfiling}>
                        Профилирование
                    </div>
                    <div className={styles.addDocumentCompetencies}>
                        <Competencies
                            isEditable
                            allCompetencies={allCompetencies}
                            competencies={competencies}
                            onAddCompetence={this.addCompetence}
                            onChangeGrade={this.changeCompetenceGrade}
                            onChangeCompetence={this.changeCompetence}
                            onRemoveCompetence={this.removeCompetence}
                        />
                    </div>
                    {this.state.isFormValidationFailedOnSave && (
                        <div className={styles.addDocumentErrorMessage}>
                            Не все обязательные поля формы заполнены или
                            некоторые поля заполнены с ошибками
                        </div>
                    )}
                    <div className={styles.addDocumentControls}>
                        <button onClick={this.send} className="btn-ok">
                            <span>Сохранить</span>
                        </button>
                        <Link to={cancelBtnLink} className="btn-cancel">
                            Отменить
                        </Link>
                    </div>
                </article>
            </section>
        );
    }
}
