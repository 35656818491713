import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useTruncate } from '@nkc-frontend/nkc-react-hooks';
import { useUpdateEffect } from 'react-use';

import * as Store from 'app/redux/store/StoreNamespace';

import IProps from './interfaces/IDocumentTagsProps';
import * as styles from './DocumentTags.scss';

export { styles };

const DocumentTags: React.FunctionComponent<IProps> = (props) => {
    const { tags, renderTag } = props;
    const [
        containerRef,
        isToggleTruncateBtnShown,
        _,
        isManuallyOpened,
        setIsManuallyOpened,
        showHideTruncBtnIfOverflown,
    ] = useTruncate<HTMLDivElement>(styles.documentTagsContainerOpened);

    useUpdateEffect(showHideTruncBtnIfOverflown, [tags]);

    const showTags = useCallback(() => setIsManuallyOpened(true), []);
    const truncateTags = useCallback(() => setIsManuallyOpened(false), []);

    return (
        <div className={styles.documentTags}>
            <div
                ref={containerRef}
                className={classNames(styles.documentTagsContainer, {
                    [styles.documentTagsContainerOpened]: isManuallyOpened,
                })}
            >
                {tags.map((tag) =>
                    renderTag(tag, props, styles.documentTagsTag)
                )}
                {isToggleTruncateBtnShown && !isManuallyOpened && (
                    <button
                        className={`btn ${styles.documentTagsShowBtn}`}
                        onClick={showTags}
                    >
                        ещё
                    </button>
                )}
            </div>
            {isToggleTruncateBtnShown && isManuallyOpened && (
                <button
                    className={`btn ${styles.documentTagsTruncateBtn}`}
                    onClick={truncateTags}
                >
                    свернуть теги
                </button>
            )}
        </div>
    );
};

DocumentTags.defaultProps = {
    renderTag(
        tag: Store.ICategory,
        props: IProps,
        className: string
    ): JSX.Element {
        const { onClick } = props;
        const HtmlTag = onClick ? 'button' : 'span';
        const tagTitle = `#${tag.name.replace(/ /g, '_')}`;
        return (
            <HtmlTag
                key={tag.id}
                className={classNames(className, { btn: !!onClick })}
                onClick={
                    onClick &&
                    (() => {
                        onClick(tag);
                    })
                }
                title={tagTitle}
            >
                {tagTitle}
            </HtmlTag>
        );
    },
};

export default React.memo(DocumentTags);
