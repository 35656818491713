/**
 * Created by Lkarmelo on 21.11.2017.
 */

import { createAction } from 'redux-actions';

import * as Store from '../store/StoreNamespace';

export const FETCH_CURRENT_USER_COMPETENCIES =
    'FETCH_CURRENT_USER_COMPETENCIES';
export const fetchCurrentUserCompetencies = createAction(
    FETCH_CURRENT_USER_COMPETENCIES
);

export const SET_CURRENT_USER_COMPETENCIES = 'SET_CURRENT_USER_COMPETENCIES';
export const setCurrentUserCompetencies = createAction<Store.IUserCompetence[]>(
    SET_CURRENT_USER_COMPETENCIES
);
