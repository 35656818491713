/**
 * Created by lkarmelo on 21.08.2018.
 */

import { fetchRecommendations } from 'app/redux/actions/recommendations';
import { setLimit, setSkip } from 'app/redux/actions/search/searchPaging';
import {
    addMultiSelectFilterValue,
    removeMultiSelectFilterValue,
    setFilterValue,
    setDateRangeFrom,
    setDateRangeTo,
    setFilters,
} from 'app/redux/actions/search/filters';
import { setSorting, resetSorting } from 'app/redux/actions/sorting';
import {
    IRecommendationsActionProps,
    IRecommendationsStateProps,
} from 'app/components/document-management/Recommendations/interfaces/IRecommendationsProps';
import { RecommendationsColumnName as ColumnName } from 'app/components/document-management/Recommendations/index';
import * as Store from 'app/redux/store/StoreNamespace';
import connectWithContext, {
    IInjectedProps,
} from 'app/redux/context/connectWithContext';

import Recommendations from './Recommendations';

const mapStateToProps = (
    state: Store.IState
): IRecommendationsStateProps & IInjectedProps => {
    const { filters } = state.recommendations;
    return {
        recommendations:
            state.recommendations && state.recommendations.list.recommendations,
        limit: state.recommendations.paging.limit,
        skip: state.recommendations.paging.skip,
        count:
            state.recommendations.list && state.recommendations.list.totalCount,
        rootContext: Store.RecommendationsContext,
        sortColumn: <ColumnName>state.recommendations.sorting.field,
        sortOrder: state.recommendations.sorting.order,
        [ColumnName.createdDate]:
            filters[ColumnName.createdDate] &&
            <Store.IDateFilterValue>filters[ColumnName.createdDate].value,
        [ColumnName.planDate]:
            filters[ColumnName.planDate] &&
            <Store.IDateFilterValue>filters[ColumnName.planDate].value,
        [ColumnName.finishDate]:
            filters[ColumnName.finishDate] &&
            <Store.IDateFilterValue>filters[ColumnName.finishDate].value,
        [ColumnName.title]:
            filters[ColumnName.title] &&
            <string>filters[ColumnName.title].value,
        [ColumnName.author]:
            filters[ColumnName.author] &&
            <string>filters[ColumnName.author].value,
        [ColumnName.receiver]:
            filters[ColumnName.receiver] &&
            <string>filters[ColumnName.receiver].value,
        [ColumnName.isNecessary]:
            filters[ColumnName.isNecessary] &&
            <string>filters[ColumnName.isNecessary].value,
        [ColumnName.status]:
            filters[ColumnName.status] &&
            <string[]>filters[ColumnName.status].value,
    };
};

const mapDispatchToProps = (dispatch): IRecommendationsActionProps => ({
    fetchRecommendations(): void {
        dispatch(fetchRecommendations());
    },
    setPageSize(size: number): void {
        dispatch(setLimit({ limit: size, resetSkip: true }));
    },
    setSkip(skip: number): void {
        dispatch(setSkip(skip));
    },
    setDateRangeFrom(filterName: string, date: number): void {
        dispatch(setDateRangeFrom(filterName, date));
    },
    setDateRangeTo(filterName: string, date: number): void {
        dispatch(setDateRangeTo(filterName, date));
    },
    setDateRangePeriod(filterName: string, date: Store.IDateFilterValue): void {
        dispatch(setFilterValue(filterName, date));
    },
    setTextFilter(filterName: string, query: string): void {
        dispatch(setFilterValue(filterName, query));
    },
    selectFilter(value: string, filterName: string): void {
        dispatch(setFilterValue(filterName, value));
    },
    deselectFilter(value: string, filterName: string): void {
        dispatch(setFilterValue(filterName, undefined));
    },
    selectMultiValueFilter(filterName: string, value: string): void {
        dispatch(addMultiSelectFilterValue(filterName, value));
    },
    deselectMultiValueFilter(filterName: string, value: string): void {
        dispatch(removeMultiSelectFilterValue(filterName, value));
    },
    deselectAll(filterName: string): void {
        dispatch(setFilterValue(filterName, []));
    },
    setSorting(col: string, order: Store.SortingOrder): void {
        dispatch(setSorting(col, order));
    },
    resetSorting(): void {
        dispatch(resetSorting());
    },
    resetFilters(): void {
        dispatch(setFilters({}));
    },
});

export default connectWithContext(
    mapStateToProps,
    mapDispatchToProps
)(Recommendations);
