/**
 * Created by Lkarmelo on 21.11.2017.
 */

import { createAction } from 'redux-actions';

import * as Store from '../store/StoreNamespace';

export const FETCH_ORGANIZATIONS_INFO = 'FETCH_ORGANIZATIONS_INFO';
export const fetchOrganizationsInfo = createAction(FETCH_ORGANIZATIONS_INFO);

export const SET_ORGANIZATIONS_INFO = 'SET_ORGANIZATIONS_INFO';
export const setOrganizationsInfo = createAction<Store.IOrganizationsInfo>(
    SET_ORGANIZATIONS_INFO
);
