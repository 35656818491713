/**
 * Created by Lkarmelo on 21.11.2017.
 */

import { createAction } from 'redux-actions';

import * as Store from '../store/StoreNamespace';

export interface IFetchCurrentUserDataPayload {
    login?: string;
    id?: string;
    source?: string;
}
export const fetchCurrentUserData = createAction<IFetchCurrentUserDataPayload>(
    'FETCH_CURRENT_USER_DATA'
);

export const setCurrentUserData = createAction<Store.IUser>(
    'SET_CURRENT_USER_DATA'
);

export const setCurrentUserId = createAction<string>('SET_CURRENT_USER_ID');

export const fetchUserIdByLogin = createAction<{
    login: string;
    source: string;
}>('FETCH_USER_ID_BY_LOGIN');
