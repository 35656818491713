/**
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 23.01.2018
 * -----
 * Last Modified: 07.02.2018 15:47:42
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import React, { PureComponent } from 'react';
import autoBind from 'autobind-decorator';
import moment from 'moment';

import NumberInput from 'app/components/common/controls/NumberInput';
import {
    List as SelectList,
    Option,
    ISelectOption,
    IOptionProps,
} from 'app/modules/components/TreeSelect';
import { IOption } from 'app/components/common/controls/Option';
import { PubSubContext } from 'app/react-context-pubsub';
import { YEAR_PERIOD_FILTER_MIN_YEAR } from 'app/utils/constants';
import { noop } from 'app/utils/noop';

import IState, { Period } from './interfaces/IYearPeriodState';
import IProps from './interfaces/IYearPeriodProps';

import './YearPeriod.scss';

@autoBind
export default class YearPeriod extends PureComponent<IProps, IState> {
    static contextType = PubSubContext;

    static options: IOption[] = [
        { label: 'За вcё время', value: Period.All },
        { label: 'За последний год', value: Period.Year },
        { label: 'За последние пять лет', value: Period.FiveYears },
        { label: 'Задать точный период', value: Period.Custom },
    ];

    state: IState = {
        isModalPanelShown: false,
        period: Period.All,
        wasLastChangeManual: false,
        lastEndYear: undefined,
        lastStartYear: undefined,
    };

    static getDerivedStateFromProps(
        props: IProps,
        state: IState
    ): Partial<IState> {
        let nextState: Partial<IState> = null;
        const hasYearChanged =
            props.endYear !== state.lastEndYear ||
            props.startYear !== state.lastStartYear;
        if (hasYearChanged) {
            nextState = {
                lastEndYear: props.endYear,
                lastStartYear: props.startYear,
                wasLastChangeManual: false,
            };
        }
        if (!state.wasLastChangeManual && hasYearChanged) {
            const nextPeriod = YearPeriod.getPeriodByYears(
                props.startYear,
                props.endYear
            );
            if (nextPeriod !== state.period) {
                nextState = { ...nextState, period: nextPeriod };
            }
        }
        return nextState;
    }

    static getPeriodByYears(startYear: number, endYear: number): Period {
        const currentYear = new Date().getFullYear();
        const yearsDiff = endYear - startYear;
        const isEndYearCurrent = currentYear === endYear;
        const isStartYearDefined =
            startYear !== undefined && startYear !== null;
        const isEndYearDefined = endYear !== undefined && endYear !== null;

        if (
            (!isStartYearDefined && !isEndYearDefined) ||
            (startYear === YEAR_PERIOD_FILTER_MIN_YEAR &&
                endYear === currentYear)
        ) {
            return Period.All;
        }
        if (!isStartYearDefined || !isEndYearDefined || !isEndYearCurrent) {
            return Period.Custom;
        }
        if (yearsDiff === 0) {
            return Period.Year;
        }
        if (yearsDiff === 4) {
            return Period.FiveYears;
        }
        return Period.Custom;
    }

    get title(): string | JSX.Element {
        if (this.state.period === Period.Custom) {
            let str = '';
            this.props.startYear !== undefined &&
                this.props.startYear !== null &&
                (str += `с ${this.props.startYear} `);
            this.props.endYear !== undefined &&
                this.props.endYear !== null &&
                (str += `по ${this.props.endYear}`);
            return str;
        }
        return YearPeriod.options.find((opt) => opt.value === this.state.period)
            .label;
    }

    render(): JSX.Element {
        return (
            <div className="year-period">
                <div>
                    <SelectList
                        options={YearPeriod.options}
                        active={[this.state.period]}
                        OptionComponent={this.dropDownOptionsRenderer}
                        onSelect={this.onSelectOption}
                        onDeselect={noop}
                    />
                </div>
            </div>
        );
    }

    componentDidMount(): void {
        this.subscribeToContext();
    }

    componentDidUpdate(prevProps: IProps): void {
        if (this.props.periodToAllEvent !== prevProps.periodToAllEvent) {
            this.unSubscribeFromContext(prevProps.periodToAllEvent);
            this.subscribeToContext();
        }
    }

    componentWillUnmount(): void {
        this.unSubscribeFromContext(this.props.periodToAllEvent);
    }

    subscribeToContext(): void {
        if (typeof this.props.periodToAllEvent === 'string') {
            this.context.subscribe(
                this.props.periodToAllEvent,
                this.setPeriodToAll
            );
        }
    }

    unSubscribeFromContext(event: string): void {
        if (typeof event === 'string') {
            this.context.unSubscribe(event, this.setPeriodToAll);
        }
    }

    setPeriodToAll(): void {
        this.setState({ period: Period.All });
    }

    dropDownOptionsRenderer(optionComponentProps: IOptionProps): JSX.Element {
        const { option } = optionComponentProps;
        if (option.value === Period.Custom) {
            return this.renderYearsRange(optionComponentProps);
        }
        return <Option {...optionComponentProps} />;
    }

    renderYearsRange(optionComponentProps: IOptionProps): JSX.Element {
        return (
            <div className="year-period__option-custom">
                <Option {...optionComponentProps} />
                {this.state.period === Period.Custom && (
                    <div onClick={(e) => e.stopPropagation()}>
                        <NumberInput
                            dropDownClassName="year-period__number-input-drop-down"
                            value={this.props.startYear}
                            minValue={YEAR_PERIOD_FILTER_MIN_YEAR}
                            maxValue={
                                this.props.endYear || new Date().getFullYear()
                            }
                            label="с"
                            showDropDown
                            reverseDropDownOptionsOrder
                            filterDropDownOptions
                            maxInputStrLength={4}
                            onValueChange={this.onStartYearInputChange}
                            showStepBtns={false}
                            showClearBtn={false}
                        />
                        <NumberInput
                            dropDownClassName="year-period__number-input-drop-down"
                            value={this.props.endYear}
                            minValue={
                                this.props.startYear ||
                                YEAR_PERIOD_FILTER_MIN_YEAR
                            }
                            maxValue={new Date().getFullYear()}
                            label="по"
                            showDropDown
                            reverseDropDownOptionsOrder
                            filterDropDownOptions
                            maxInputStrLength={4}
                            onValueChange={this.onEndYearInputChange}
                            showStepBtns={false}
                            showClearBtn={false}
                        />
                    </div>
                )}
            </div>
        );
    }

    onSelectOption({ value }: ISelectOption): void {
        this.setState({
            period: value as Period,
        });
        switch (value) {
            case Period.All:
                this.onSelectAll();
                break;
            case Period.Year:
                this.onSelectYears(0);
                break;
            case Period.FiveYears:
                this.onSelectYears(4);
                break;
            default:
        }
    }

    onSelectAll(): void {
        const { onStartYearChange, onEndYearChange, filterName } = this.props;

        onStartYearChange(filterName, undefined);
        onEndYearChange(filterName, undefined);
    }

    onSelectYears(yearsDiff: number): void {
        const { onStartYearChange, onEndYearChange, filterName } = this.props;

        onStartYearChange(filterName, moment().year() - yearsDiff);
        onEndYearChange(filterName, moment().year());
    }

    shouldCloseDropDownOnSelect(val: Period): boolean {
        return val !== Period.Custom;
    }

    onStartYearInputChange(nextStartYear: number): void {
        const {
            onStartYearChange,
            endYear,
            filterName,
            onEndYearChange,
            startYear,
        } = this.props;
        if (this.state.period === Period.Custom) {
            this.setState({ wasLastChangeManual: true });
        }

        if (nextStartYear > endYear) {
            onEndYearChange(filterName, undefined);
        }

        nextStartYear !== startYear &&
            onStartYearChange(filterName, nextStartYear);
    }

    onEndYearInputChange(nextEndYear: number): void {
        const {
            onEndYearChange,
            startYear,
            filterName,
            onStartYearChange,
            endYear,
        } = this.props;
        if (this.state.period === Period.Custom) {
            this.setState({ wasLastChangeManual: true });
        }

        if (nextEndYear < startYear) {
            onStartYearChange(filterName, nextEndYear);
        }

        nextEndYear !== endYear && onEndYearChange(filterName, nextEndYear);
    }
}
