import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { ActionFunctionAny, createActions } from 'redux-actions';

import {
    DEFAULT_LOADING_ERROR_MESSAGE,
    LOADING_ERROR_BTN_TITLE_REFRESH,
    LOADING_ERROR_MESSAGE_REFRESH,
} from 'app/utils/constants';
import { noop } from 'app/utils/noop';

import { camelCaseToCapSnakeCase } from './camelCaseToCapSnakeCase';
import { getErrorAction } from './historyUtils';

type LoadingActions = {
    [actionName: string]: ActionFunctionAny<any>;
};

type PayloadProviderCreator = (opt: Option) => (...args: any[]) => any;

type PayloadProviderCreators = {
    request?: PayloadProviderCreator;
    resolve?: PayloadProviderCreator;
    reject?: PayloadProviderCreator;
};

type MetaProviderCreator = (
    opt: Option
) => (...args: any[]) => { requestName: string; [key: string]: any };

type MetaProviderCreators = {
    request?: MetaProviderCreator;
    resolve?: MetaProviderCreator;
    reject?: MetaProviderCreator;
};

type Option = {
    name: string;
    errors?: {
        [status: number]:
            | string
            | LoadingErrorInfo
            | ((er: Error) => LoadingErrorInfo);
    };
    payloadCreatorsFactory?: PayloadProviderCreators;
    metaCreatorsFactory?: MetaProviderCreators;
    showLoadingBar?: boolean;
    logOutOnUnAuth?: boolean;
    logInOnAuth?: boolean;
    ignoreErrors?: boolean;
    additionalInfo?: any;
};

export type LoadingErrorInfo = {
    errorMessage?: string;
    btnTitle?: string;
    onCloseAction?: () => void;
    closeByBtnClickOnly?: boolean;
    showErrorInMainErrorComponent: boolean;
};

type MetaWithLoadingError = { requestName: string } & LoadingErrorInfo;

export function createLoadingActions(
    options: (Option | string)[],
    loadingShowActionCreators: ActionFunctionAny<any>[],
    loadingHideActionCreators: ActionFunctionAny<any>[],
    onErrorTriggers: ActionFunctionAny<any>[],
    logOutOnUnAuthActionCreators: ActionFunctionAny<any>[],
    logInOnAuthActionCreators: ActionFunctionAny<any>[]
): LoadingActions {
    const defaultOption = {
        errors: {},
        payloadCreatorsFactory: {},
        metaCreatorsFactory: {},
        showLoadingBar: true,
        logOutOnUnAuth: true,
        logInOnAuth: true,
        ignoreErrors: false,
    };

    let actionCreators = {};

    options.forEach((option: Option | string) => {
        let optionWithDefaults: Option = null;
        if (typeof option === 'string') {
            optionWithDefaults = { ...defaultOption, name: option };
        } else {
            optionWithDefaults = { ...defaultOption, ...option };
        }
        const {
            name,
            payloadCreatorsFactory,
            metaCreatorsFactory,
            showLoadingBar,
            logOutOnUnAuth,
            ignoreErrors,
            errors,
            logInOnAuth,
        } = optionWithDefaults;

        const defaultMetaCreator = () => ({ requestName: name });
        const defaultRejectMetaCreator = (e: AjaxError) => {
            const meta: MetaWithLoadingError = {
                requestName: name,
                errorMessage: undefined,
                showErrorInMainErrorComponent: undefined,
                btnTitle: undefined,
                closeByBtnClickOnly: false,
                onCloseAction: undefined,
            };
            if (!e) {
                return meta;
            }

            const errorOptions = errors[e.status];
            if (errorOptions === undefined) {
                const statuses = [500, 502, 504];
                if (statuses.indexOf(e.status) >= 0) {
                    meta.errorMessage = LOADING_ERROR_MESSAGE_REFRESH;
                    meta.btnTitle = LOADING_ERROR_BTN_TITLE_REFRESH;
                    meta.onCloseAction = getErrorAction('moduleLoading');
                    meta.closeByBtnClickOnly = true;
                } else {
                    meta.errorMessage = DEFAULT_LOADING_ERROR_MESSAGE;
                }
                meta.showErrorInMainErrorComponent = true;
            } else if (typeof errorOptions === 'string') {
                meta.errorMessage = errorOptions;
                meta.showErrorInMainErrorComponent = true;
            } else if (typeof errorOptions === 'object') {
                meta.errorMessage = (<LoadingErrorInfo>(
                    errorOptions
                )).errorMessage;
                meta.btnTitle = (<LoadingErrorInfo>errorOptions).btnTitle;
                meta.showErrorInMainErrorComponent = (<LoadingErrorInfo>(
                    errorOptions
                )).showErrorInMainErrorComponent;
                meta.closeByBtnClickOnly = (<LoadingErrorInfo>(
                    errorOptions
                )).closeByBtnClickOnly;
                meta.onCloseAction = (<LoadingErrorInfo>(
                    errorOptions
                )).onCloseAction;
            } else if (typeof errorOptions === 'function') {
                meta.errorMessage = errorOptions(e).errorMessage;
                meta.btnTitle = errorOptions(e).btnTitle;
                meta.closeByBtnClickOnly = errorOptions(e).closeByBtnClickOnly;
                meta.showErrorInMainErrorComponent =
                    errorOptions(e).showErrorInMainErrorComponent;
                meta.onCloseAction = errorOptions(e).onCloseAction;
            }

            meta.errorMessage =
                meta.errorMessage ?? DEFAULT_LOADING_ERROR_MESSAGE;
            meta.btnTitle = meta.btnTitle ?? 'Ok';
            meta.onCloseAction = meta.onCloseAction ?? null;
            return meta;
        };

        const creators = createActions({
            [`${camelCaseToCapSnakeCase(name)}_REQUEST`]: [
                payloadCreatorsFactory.request
                    ? payloadCreatorsFactory.request(optionWithDefaults)
                    : noop,
                metaCreatorsFactory.request
                    ? metaCreatorsFactory.request(optionWithDefaults)
                    : defaultMetaCreator,
            ],
            [`${camelCaseToCapSnakeCase(name)}_RESOLVE`]: [
                payloadCreatorsFactory.resolve
                    ? payloadCreatorsFactory.resolve(optionWithDefaults)
                    : noop,
                metaCreatorsFactory.resolve
                    ? metaCreatorsFactory.resolve(optionWithDefaults)
                    : defaultMetaCreator,
            ],
            [`${camelCaseToCapSnakeCase(name)}_REJECT`]: [
                payloadCreatorsFactory.reject
                    ? payloadCreatorsFactory.reject(optionWithDefaults)
                    : noop,
                metaCreatorsFactory.reject
                    ? metaCreatorsFactory.reject(optionWithDefaults)
                    : defaultRejectMetaCreator,
            ],
        });

        const requestActionName = `${name}Request`;
        const resolveActionName = `${name}Resolve`;
        const rejectActionName = `${name}Reject`;

        if (showLoadingBar) {
            loadingShowActionCreators.push(creators[requestActionName]);
            loadingHideActionCreators.push(creators[resolveActionName]);
            loadingHideActionCreators.push(creators[rejectActionName]);
        }

        if (logOutOnUnAuth)
            logOutOnUnAuthActionCreators.push(creators[rejectActionName]);
        if (logInOnAuth)
            logInOnAuthActionCreators.push(creators[resolveActionName]);

        if (!ignoreErrors) onErrorTriggers.push(creators[rejectActionName]);

        actionCreators = { ...actionCreators, ...creators };
    });
    return actionCreators;
}
