import React, { useCallback, useMemo } from 'react';

import { IPerson } from 'app/redux/store/StoreNamespace';
import { useActions } from 'app/hooks/useActions';

import ContactItem from '../ContactItem';
import styles from '../../PersonCard.scss';
import { getTelephones } from '../../helpers';

const renderPhones = (telephones, id) => {
    return telephones.map((tel, index) => {
        const delimiter = index > 0 && (
            <div className={styles.personCardPhoneDelimiter} />
        );
        return (
            <React.Fragment key={`${id}-${tel}`}>
                {delimiter}
                <span className={styles.personCardPhone}>{tel}</span>
            </React.Fragment>
        );
    });
};

const ContactInfo = ({ user }: { user: IPerson }) => {
    const { content, id } = user;
    const { email, city, usrSip, telInternal, telMobile, telOffice } = content;

    const { logEvent } = useActions();

    const telephones = useMemo(
        () => getTelephones([telInternal, telMobile, telOffice]),
        [telOffice, telInternal, telMobile]
    );

    const logSipClick = (person: IPerson) => {
        logEvent('userSipClickEvent', [person]);
    };

    const logMailToClick = (person: IPerson) => {
        logEvent('userMailToClickEvent', [person]);
    };

    const onEmailClick = useCallback(() => logMailToClick(user), [user]);
    const onUsrSipClick = useCallback(() => logSipClick(user), [user]);

    return (
        <ul className={styles.personCardContactInfo}>
            <ContactItem icon="nkc-icon-location">{city}</ContactItem>
            <ContactItem icon="nkc-icon-phone">
                {telephones.length > 0 && renderPhones(telephones, id)}
            </ContactItem>
            <ContactItem onClick={onEmailClick} icon="nkc-icon-user-mail">
                {email && <a href={`mailto:${email}`}>{email}</a>}
            </ContactItem>
            <ContactItem onClick={onUsrSipClick} icon="nkc-icon-skype">
                {usrSip && <a href={usrSip}>Написать в Skype for business</a>}
            </ContactItem>
        </ul>
    );
};

export default ContactInfo;
