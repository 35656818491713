import React, { useCallback, useEffect, useRef } from 'react';

import DropDownMenu from 'app/components/common/DropDownMenu';
import { useTypedSelector } from 'app/hooks/useTypedSelector';
import { useActions } from 'app/hooks/useActions';

import * as styles from './UserMenu.scss';
import Toggle from './components/Toggle';
import Content from './components/Content';

const UserMenu: React.FunctionComponent = () => {
    const dropDownMenuRef: React.MutableRefObject<DropDownMenu> = useRef();

    const { userId: currentUserId } = useTypedSelector((state) => state.user);
    const { fetchLittleUserMenuAvatar, fetchDropDownUserMenuAvatar } =
        useActions();

    const onLoad = useCallback((userUUID: string) => {
        fetchLittleUserMenuAvatar({ userId: userUUID, resolution: '36' });
        fetchDropDownUserMenuAvatar({ userId: userUUID, resolution: '64' });
    }, []);

    const onClose = useCallback((): void => {
        dropDownMenuRef.current && dropDownMenuRef.current.close();
    }, []);

    const menuRenderer = useCallback(() => <Content onClose={onClose} />, []);

    useEffect(() => onLoad(currentUserId), [currentUserId, onLoad]);

    return (
        <DropDownMenu
            ref={dropDownMenuRef}
            className={styles.userMenu}
            dropDownContentRenderer={menuRenderer}
            toggleElement={<Toggle />}
        />
    );
};

export default UserMenu;
