/**
 * Created by lkarmelo on 13.11.2019.
 */

import { connect } from 'react-redux';

import { fetchRedirectInfo } from 'app/redux/actions/outsideRedirectInfo';
import { logEvent } from 'app/redux/actions/logEvents';
import * as Store from 'app/redux/store/StoreNamespace';

import DocumentRedirect from './DocumentRedirect';
import {
    IDocumentRedirectStateProps,
    IDocumentRedirectActionProps,
} from './interfaces/IDocumentRedirectProps';

const mapStateToProps = (state: Store.IState): IDocumentRedirectStateProps => ({
    link: state.outsideRedirectInfo && state.outsideRedirectInfo.link,
    checkLinks: state.outsideRedirectInfo.checkLinks,
});

const mapDispatchToProps = (dispatch): IDocumentRedirectActionProps => ({
    fetchLink(documentId: string): void {
        dispatch(fetchRedirectInfo(documentId));
    },
    logDocumentSourceViewEvent(documentId: string): void {
        dispatch(logEvent('documentSourceViewEvent', [documentId]));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentRedirect);
