/**
 * Created by lkarmelo on 15.07.2019.
 */

import { createAction } from 'redux-actions';

import * as Store from 'app/redux/store/StoreNamespace';

export interface ISetStateFromLocationPayload {
    query?: string;
    filters?: Store.IFilters;
    alias?: string;
}
export const setStateFromLocation = createAction<ISetStateFromLocationPayload>(
    'SET_STATE_FROM_LOCATION'
);
