/**
 * Created by Lkarmelo on 05.09.2017.
 */
import { createSimpleLoadingEpic } from './utils';

import { FETCH_COMPETENCIES, setCompetencies } from '../actions/competencies';
import {
    competenciesRequest,
    competenciesReject,
    competenciesResolve,
} from '../actions/loading';

export const loadCompetencies = createSimpleLoadingEpic({
    triggers: [FETCH_COMPETENCIES],
    apiCallName: 'competencies',
    actions: {
        requestAction: competenciesRequest,
        resolveAction: competenciesResolve,
        rejectAction: competenciesReject,
        setAction: setCompetencies,
    },
    filterTriggering: (storeState) =>
        !storeState.competencies || storeState.competencies.length === 0,
});
