/*
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 13.03.2018
 * -----
 * Last Modified: 13.03.2018 10:48:59
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import * as React from 'react';
import classNames from 'classnames';

import * as Store from 'app/redux/store/StoreNamespace';

import * as styles from './ExpansionTagsPanel.scss';

export interface IExpansionTagsPanelStateProps {
    tags: Store.IQueryKeyword[] | null;
    selectedTagIds: string[];
}

export interface IExpansionTagsPanelActionProps {
    onSelectExpansionTag: (id: string) => void;
}

const ExpansionTagsPanel: React.FC<
    IExpansionTagsPanelStateProps & IExpansionTagsPanelActionProps
> = (props) => {
    const { tags, onSelectExpansionTag, selectedTagIds } = props;

    if (!tags) {
        return null;
    }

    const tagsBag = tags.map((node) => (
        <button
            key={node.lemmaId}
            className={classNames('btn', styles.expansionTagsPanelExpansion, {
                [styles.expansionTagsPanelExpansionSelected]:
                    selectedTagIds.includes(node.lemmaId),
            })}
            onClick={() => onSelectExpansionTag(node.lemmaId)}
        >
            {node.raw}
        </button>
    ));

    return (
        <div className={styles.expansionTagsPanel}>
            {Object.keys(tags).length === 0 ? (
                <span className={styles.expansionTagsPanelText}>
                    Аналоги не найдены
                </span>
            ) : (
                tagsBag
            )}
        </div>
    );
};

export default ExpansionTagsPanel;
