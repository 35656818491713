export const swapArrayElements = <T>(
    arr: T[],
    lastVisibleTabIndex: number,
    activeTabIndex: number,
    baseLastVisibleTabIndex: number,
    tabCodes: T[]
) => {
    const baseActiveTabIndex = tabCodes.findIndex(
        (tab) => tab === arr[activeTabIndex]
    );
    const activeElement = arr.splice(activeTabIndex, 1);
    const lastElement = arr.splice(lastVisibleTabIndex, 1, activeElement[0]);
    arr.splice(
        baseLastVisibleTabIndex > lastVisibleTabIndex
            ? baseActiveTabIndex < baseLastVisibleTabIndex
                ? baseLastVisibleTabIndex
                : baseLastVisibleTabIndex + 1
            : lastVisibleTabIndex + 1,
        0,
        lastElement[0]
    );
};
