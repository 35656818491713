/**
 * Created by Lkarmelo on 27.09.2017.
 */

import React, { FocusEvent, KeyboardEvent, PureComponent } from 'react';
import autoBind from 'autobind-decorator';
import classNames from 'classnames';
import { filter, first } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';

import { logEvent } from 'app/redux/actions/logEvents';
import OptionPersonCard from 'app/components/search/OptionPersonCard/OptionPersonCard';
import store from 'app/redux/store/store';
import { setSearchActiveTab } from 'app/redux/actions/search/searchTabs';
import { EMPLOYEES_TAB_CODE } from 'app/utils/constants';

import IProps from './interfaces/IOptionProps';

@autoBind
export default class Option extends PureComponent<IProps> {
    static defaultProps = {
        defaultClick: true,
        mouseEnterTimeoutAfterMount: 200,
    };

    isMouseEnterTimeoutFinished = false;

    // onMouseLeave не вызовется, если элемент был удалён из-под курсора, поэтому приходится ставить флаг и по нему вызывать
    // this.props.onMouseLeave при unMount'е
    isEntered = false;

    ref: React.RefObject<HTMLLIElement> = React.createRef();

    // todo: debounceTime onMouseEnter & onMouseLeave
    subjectEnterPressed$!: Subscription;

    render(): JSX.Element {
        const { baseClassName, isActive, onBlur, isDisabled, className } =
            this.props;
        return (
            <li
                ref={this.ref}
                tabIndex={-1}
                className={classNames(
                    `${baseClassName}-item`,
                    { [`${baseClassName}-item--active`]: isActive },
                    className
                )}
                onMouseEnter={this.onOptionMouseEnter}
                onMouseLeave={this.onOptionMouseLeave}
                {...(!isDisabled && {
                    onKeyDown: this.onOptionKeyDown,
                    onClick: this.onClick,
                })}
                {...(!isDisabled &&
                    onBlur && {
                        onFocus: this.onOptionFocus,
                        onBlur: this.onOptionBlur,
                    })}
            >
                {this.props.option.meta.person ? (
                    <OptionPersonCard
                        meta={this.props.option}
                        personId={this.props.option.meta.person.userId}
                    />
                ) : (
                    this.renderOptionContent()
                )}
            </li>
        );
    }

    componentWillUnmount(): void {
        this.isEntered && this.props.onMouseLeave(undefined, this.props.option);
        if (this.subjectEnterPressed$) {
            this.subjectEnterPressed$.unsubscribe();
        }
    }

    componentDidMount(): void {
        const { mouseEnterTimeoutAfterMount } = this.props;
        if (
            typeof mouseEnterTimeoutAfterMount === 'number' &&
            mouseEnterTimeoutAfterMount > 0
        ) {
            window.setTimeout(() => {
                this.isMouseEnterTimeoutFinished = true;
            }, mouseEnterTimeoutAfterMount);
        } else {
            this.isMouseEnterTimeoutFinished = true;
        }
        if (this.props.subjectEnterPressed$) {
            this.subjectEnterPressed$ = this.props.subjectEnterPressed$
                .pipe(
                    filter((_) => this.props.isActive),
                    first()
                )
                .subscribe((_) => {
                    this.onClick();
                });
        }
    }

    renderOptionContent(): JSX.Element | string {
        const { optionRenderer, option } = this.props;
        return optionRenderer
            ? optionRenderer(
                  option.value,
                  option.label,
                  option.meta,
                  this.props.onClickPersonList
              )
            : option.label;
    }

    onClick(): void {
        const { onSelect, option } = this.props;
        if (this.props.option.meta.person) {
            const data = {
                id: this.props.option.meta.person.userId,
            };
            store.dispatch(
                setSearchActiveTab({
                    tabCode: EMPLOYEES_TAB_CODE,
                    preserveIgnoreMistakesFromLastSearch: true,
                    preservePersonStrict: true,
                })
            );
            store.dispatch(logEvent('employeeTabFromWidgetClickEvent', [data]));
        }
        onSelect(option.value, option.label, option.meta);
    }

    onOptionKeyDown(e: KeyboardEvent<HTMLLIElement>): void {
        if (e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();
            this.onClick();
        }
    }

    onOptionFocus(e: FocusEvent<HTMLLIElement>): void {
        const { onFocus, option } = this.props;
        onFocus && onFocus(e, option);
    }

    onOptionBlur(e: FocusEvent<HTMLLIElement>): void {
        const { onBlur, option } = this.props;
        onBlur && onBlur(e, option);
    }

    onOptionMouseEnter(e: React.MouseEvent): void {
        if (!this.isMouseEnterTimeoutFinished) {
            return;
        }
        const { onMouseEnter, option } = this.props;
        this.isEntered = true;
        onMouseEnter && onMouseEnter(e.nativeEvent, option);
    }

    onOptionMouseLeave(e: React.MouseEvent): void {
        const { onMouseLeave, option } = this.props;
        this.isEntered = false;
        onMouseLeave && onMouseLeave(e.nativeEvent, option);
    }

    focus(): void {
        // в IE11 приходится использовать setActive, потому что focus() в IE может двигать родительские элементы
        if ((this.ref.current as any)?.setActive) {
            (this.ref.current as any)?.setActive?.();
        } else {
            this.ref.current?.focus?.();
        }
    }
}
