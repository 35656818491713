import React, { useCallback, useMemo } from 'react';
import { usePrevious } from 'react-use';

import * as Store from 'app/redux/store/StoreNamespace';
import { compareFilterValues } from 'app/utils/filters/compareFilterValues';
import getSearchTarget from 'app/utils/getSearchTarget';
import { useTypedSelector } from 'app/hooks/useTypedSelector';
import { useActions } from 'app/hooks/useActions';

import * as styles from './SearchResultsMessage.scss';
import IProps from './types';
import { ResultMessage } from './components/ResultMessage';
import { ResultMessageActions } from './components/ResultMessageActions';
import { getMessageType } from './helpers';

const SearchResultsMessage: React.FC<IProps> = (props) => {
    const { focusSearchQueryInput } = props;

    const {
        lastExecutedSearchQuery: searchQuery,
        lastExecutedFilters: filters,
        lastExecutedIgnoreMistakes: lastIgnoreMistakes,
        results,
        personResults,
    } = useTypedSelector((state) => state.search);
    const activeTab = useTypedSelector(
        (state) => state.mainSearchPageActiveTabForResults
    );
    const { filtersMeta } = useTypedSelector((state) => state);
    const store = useTypedSelector((state) => state);

    const searchTarget = getSearchTarget(activeTab, store);
    const correctedSearchQuery = useMemo(
        () =>
            searchTarget === Store.SearchTarget.employee
                ? personResults?.correctedQuery?.replacement.value
                : results?.correctedQuery?.replacement.value,
        [searchTarget]
    );

    const {
        setQuery,
        setLastExecutedSearchQuery,
        clearCorrectedQuery,
        compressRequestUpdateLocationAndSearch,
        setIgnoreMistakes,
        filtersToDefaults,
    } = useActions();

    const resetFilters = (): void => {
        filtersToDefaults();
    };

    const onCorrectedQueryClick = useCallback(() => {
        setQuery(correctedSearchQuery);
        setLastExecutedSearchQuery(correctedSearchQuery);
        clearCorrectedQuery();
        setIgnoreMistakes(false);
        compressRequestUpdateLocationAndSearch({ isNewSearch: true });
    }, [correctedSearchQuery]);

    const count =
        searchTarget === Store.SearchTarget.employee
            ? personResults?.totalCount
            : results?.count;
    const hasResults = typeof count === 'number' && count > 0;

    const hasFilters = useMemo(() => {
        const filtersEntries = Object.entries(filters);
        if (!filters || filtersEntries.length === 0) {
            return false;
        }

        const hasNonDefaultFilter = filtersEntries.some(
            ([filterName, filter]) => {
                const meta = filtersMeta[filterName];
                const defaultFilterValue =
                    meta && filtersMeta[filterName].default;

                return (
                    meta &&
                    !compareFilterValues(
                        filter.value,
                        defaultFilterValue,
                        meta.type
                    )
                );
            }
        );

        return hasNonDefaultFilter;
    }, [filters, filtersMeta]);

    const messageType = getMessageType({
        hasResults,
        hasFilters,
        hasSearchQuery: !!searchQuery,
    });

    // прошлое значение correctedQuery запоминается только для того, чтобы надпись "Возможно, вы искали"
    // не исчезала в момент выполнения поиска, где оригинальное значение correctedQuery стирается
    const previousCorrectedQuery = usePrevious(correctedSearchQuery);

    return (
        <div className={styles.searchResultsMessage}>
            <ResultMessage
                count={count}
                searchTarget={searchTarget}
                type={messageType}
            />
            {searchTarget === Store.SearchTarget.employee &&
            lastIgnoreMistakes &&
            (correctedSearchQuery || previousCorrectedQuery) ? (
                <div className={styles.searchResultsMessageMessage}>
                    Возможно, вы искали&nbsp;
                    <button className="btn" onClick={onCorrectedQueryClick}>
                        {correctedSearchQuery}
                    </button>
                </div>
            ) : (
                <ResultMessageActions
                    type={messageType}
                    resetFilters={resetFilters}
                    focusSearchQueryInput={focusSearchQueryInput}
                />
            )}
        </div>
    );
};

export default SearchResultsMessage;
