/**
 * Created by lkarmelo on 23.10.2019.
 */

import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import ServerOnlyFiltersOptions from 'app/components/search/ServerOnlyFiltersOptions/ServerOnlyFiltersOptions';
import {
    FeatureRenderer,
    UIFeaturesName,
} from 'app/components/common/utils/FeatureRenderer';
import {
    TreeSelect,
    TreeSelectWithSidePanel,
    YearPeriod,
} from 'app/components/common/tables/Filters';
import Tags from 'app/components/common/tables/Filters/Tags/Tags';
import { ISelectOption } from 'app/modules/components/TreeSelect';
import { NormalizedMetaWithCount } from 'app/utils/filters/filtersToOptions';
import { compareFilterValues } from 'app/utils/filters/compareFilterValues';
import { CLEAR_FILTERS_EVENT } from 'app/redux/epics/filtersToDefaultsEpic';
import * as Store from 'app/redux/store/StoreNamespace';

import IProps from './interfaces/IFiltersSidePanelProps';
import * as styles from './FiltersSidePanel.scss';

const FilterWrapper: React.FC = ({ children }) => (
    <div className={styles.filtersSidePanelFilter}>{children}</div>
);

const defaultFilterRenderer = (
    filterMeta: NormalizedMetaWithCount,
    activeValues: Store.IFilterValue,
    props: IProps,
    options?: ISelectOption[]
): JSX.Element | null => {
    if (!filterMeta || !props) {
        return null;
    }
    const { filterName, type, title, hierarchical } = filterMeta;
    const {
        onSelectMultiSelectFilter,
        onDeselectMultiSelectFilter,
        onSelect,
        onEndRangeChange,
        onStartRangeChange,
    } = props;

    if (hierarchical) {
        return (
            <FilterWrapper key={filterName}>
                <TreeSelectWithSidePanel
                    enableHierarchicalSelection
                    enableVirtualRendering
                    filterName={filterName}
                    title={title}
                    valueFilterMap={filterMeta.values}
                    options={options}
                    active={activeValues as string[]}
                    onPreviewDeselect={onDeselectMultiSelectFilter}
                    onApply={onSelect}
                />
            </FilterWrapper>
        );
    }

    switch (type) {
        case Store.FilterType.YearRange: {
            const rangeFilterValue =
                (activeValues as Store.IRangeFilterValue) || {};
            return (
                <FilterWrapper key={filterName}>
                    <YearPeriod
                        filterName={filterName}
                        startYear={rangeFilterValue.from}
                        endYear={rangeFilterValue.to}
                        onStartYearChange={onStartRangeChange}
                        onEndYearChange={onEndRangeChange}
                        periodToAllEvent={CLEAR_FILTERS_EVENT}
                    />
                </FilterWrapper>
            );
        }
        case Store.FilterType.MultiSelect:
            return (
                <FilterWrapper key={filterName}>
                    <TreeSelect
                        options={options}
                        active={activeValues as string[]}
                        collapsible={options?.length > 4}
                        showSearchBar={options?.length > 4}
                        onSelect={onSelectMultiSelectFilter}
                        onDeselect={onDeselectMultiSelectFilter}
                        title={title}
                        clearSearchQueryEvent={CLEAR_FILTERS_EVENT}
                    />
                </FilterWrapper>
            );
        default:
            return null;
    }
};

const FiltersSidePanel: React.FC<IProps> = (props) => {
    const {
        activeFilterValues,
        orderedNormalizedFiltersMeta,
        filtersOptions,
        filterRenderer,
        showServerOnlyFiltersOptions,
        onFiltersToDefaultsClick,
        activeTags,
        tagsOptions,
        onSelectTag,
        onDeSelectTag,
        defaultFilters,
        filtersMeta,
    } = props;

    const someActiveFiltersNotEqualToDefault = useMemo(
        () =>
            Object.entries(activeFilterValues).some(
                ([filterName, activeFilterVal]) => {
                    const meta = filtersMeta[filterName];
                    return (
                        meta &&
                        defaultFilters?.[filterName] &&
                        !compareFilterValues(
                            activeFilterVal,
                            defaultFilters[filterName].value,
                            meta.type
                        )
                    );
                }
            ),
        [activeFilterValues, filtersMeta, defaultFilters]
    );

    const hasActiveFiltersOrTags = useMemo(
        () =>
            (Array.isArray(activeTags) && activeTags.length > 0) ||
            (activeFilterValues && someActiveFiltersNotEqualToDefault),
        [activeTags, activeFilterValues, someActiveFiltersNotEqualToDefault]
    );

    return (
        <div className={styles.filtersSidePanel}>
            <div className={styles.filtersSidePanelHeader}>
                {!isEmpty(orderedNormalizedFiltersMeta) && (
                    <div className={styles.filtersSidePanelTitle}>Фильтры</div>
                )}
                {hasActiveFiltersOrTags && (
                    <button
                        onClick={onFiltersToDefaultsClick}
                        className={`btn ${styles.filtersSidePanelClear}`}
                    >
                        <span className={styles.filtersSidePanelClearCross} />
                        Очистить
                    </button>
                )}
            </div>
            {showServerOnlyFiltersOptions && (
                <div className={styles.filtersSidePanelServerOnlyFilters}>
                    <ServerOnlyFiltersOptions />
                </div>
            )}
            {Array.isArray(orderedNormalizedFiltersMeta) &&
                orderedNormalizedFiltersMeta.map((filterMeta) => {
                    const { filterName } = filterMeta;

                    const renderedFilter = filterRenderer(
                        filterMeta,
                        activeFilterValues[filterName],
                        props,
                        filtersOptions[filterName]
                    );

                    return APP_FEATURES.ui.hasOwnProperty(filterName) ? (
                        <FeatureRenderer
                            key={filterName}
                            featureName={filterName as UIFeaturesName}
                        >
                            {renderedFilter}
                        </FeatureRenderer>
                    ) : (
                        renderedFilter
                    );
                })}
            {tagsOptions && tagsOptions.length > 0 && activeTags && (
                <FilterWrapper>
                    <Tags
                        title="Популярные теги"
                        options={tagsOptions}
                        active={activeTags}
                        onSelect={onSelectTag}
                        onDeselect={onDeSelectTag}
                    />
                </FilterWrapper>
            )}
        </div>
    );
};

FiltersSidePanel.defaultProps = {
    filterRenderer: defaultFilterRenderer,
};

export default FiltersSidePanel;
