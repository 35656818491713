// https://github.com/sindresorhus/prepend-http/tree/040579fb1271df6232dd96a7600c9efb1510eb47
// чуть-чуть отрефакторен и типизирован

export const prependHttp = (
    url: string,
    opts: { https: boolean } = { https: false }
) => {
    if (typeof url !== 'string') {
        throw new TypeError(
            `Expected \`url\` to be of type \`string\`, got \`${typeof url}\``
        );
    }

    url = url.trim();

    if (/^\.*\/|^(?!localhost)\w+:/.test(url)) {
        return url;
    }

    return url.replace(
        /^(?!(?:\w+:)?\/\/)/,
        opts.https ? 'https://' : 'http://'
    );
};
