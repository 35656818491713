/**
 * Created by lkarmelo on 29.10.2018.
 */
import { Observable } from 'rxjs/Observable';

import { ContextAction } from 'app/redux/context/connectWithContext';

/**
 * Фильтрует экшены по совпадению первого элемента в массиве контекста с указанным
 * @param {string} context: контекст
 */
function contextStartsWith(
    this: Observable<ContextAction<any>>,
    context: string
): Observable<ContextAction<any>> {
    return this.filter(
        (action) =>
            Array.isArray(action.context) &&
            action.context.length > 0 &&
            action.context[0] === context
    );
}

Observable.prototype.contextStartsWith = contextStartsWith;

declare module 'rxjs/Observable' {
    /* tslint:disable-next-line: interface-name */
    interface Observable<T> {
        contextStartsWith: typeof contextStartsWith;
    }
}
