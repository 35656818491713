/*
 * Created by: Pavel Borisov (pborisov@naumen.ru>) on 18.06.2018
 * -----
 * Last Modified: 19.06.2018 19:27:45
 * Modified By: Pavel Borisov (pborisov@naumen.ru>)
 */

import React, { PureComponent, KeyboardEvent, FocusEvent } from 'react';
import autoBind from 'autobind-decorator';
import classNames from 'classnames';

import IProps from './interfaces/ITreeOptionLeafProps';

@autoBind
export default class TreeOptionLeaf extends PureComponent<IProps> {
    ref: React.RefObject<HTMLLIElement> = React.createRef();

    render(): JSX.Element {
        const { option, baseClassName, isActive, onBlur, onExpand } =
            this.props;
        const { level, expand } = option.meta || { level: 0, expand: null };

        return (
            <li
                ref={this.ref}
                className={classNames(`${baseClassName}-item`, 'check-box', {
                    [`${baseClassName}-item--active`]: isActive,
                })}
                onClick={(e) => {
                    e.stopPropagation();

                    this.onActiveToggle(!isActive);
                }}
                onFocus={onBlur ? this.onOptionFocus : null}
                onBlur={onBlur ? this.onOptionBlur : null}
            >
                <div className="check-box">
                    <span
                        className={classNames('ant-checkbox', {
                            'ant-checkbox-checked': isActive,
                        })}
                    >
                        <span className="ant-checkbox-inner" />
                    </span>
                </div>

                {level
                    ? [...Array.from(Array(level).keys())].map((n) => (
                          <span
                              className={`${baseClassName}__switcher`}
                              key={`level-${n}`}
                          />
                      ))
                    : null}
                <span
                    className={classNames(
                        `${baseClassName}__switcher`,
                        { [`${baseClassName}__switcher_open`]: expand },
                        {
                            [`${baseClassName}__switcher_close`]:
                                expand === false,
                        }
                    )}
                    onClick={onExpand}
                />
                {this.renderOptionContent()}
            </li>
        );
    }

    renderOptionContent(): JSX.Element | string {
        const { optionRenderer, option } = this.props;
        return optionRenderer
            ? optionRenderer(option.value, option.label, option.meta)
            : option.label;
    }

    onActiveToggle(isChecked: boolean): void {
        const { onSelect, onDeselect, option } = this.props;

        if (isChecked) {
            onSelect(option.value, option.label, option.meta);
        } else {
            onDeselect && onDeselect(option.value, option.label, option.meta);
        }
    }

    onOptionKeyDown(e: KeyboardEvent<HTMLLIElement>): void {
        const { isActive } = this.props;

        if (e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();

            this.onActiveToggle(!isActive);
        }
    }

    onOptionFocus(e: FocusEvent<HTMLLIElement>): void {
        const { onFocus, option } = this.props;
        onFocus && onFocus(e, option);
    }

    onOptionBlur(e: FocusEvent<HTMLLIElement>): void {
        const { onBlur, option } = this.props;
        onBlur && onBlur(e, option);
    }

    focus(): void {
        this.ref.current && this.ref.current.focus();
    }
}
