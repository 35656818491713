import { Action } from 'redux-actions';
import { MiddlewareAPI } from 'redux';
import { Observable } from 'rxjs/Observable';

import { IUpdateGuide, IGuide } from 'app/types/tour';
import Api from 'app/api/Api';

import * as Store from '../store/StoreNamespace';
import {
    fetchGuide,
    fetchGuideStatsList,
    updateGuideStatsAction,
    setGuideStats,
} from '../actions/tour';
import { MAIN_TOUR_STEPS_VERSION } from '../../components/common/SiteTour/MainTourSteps';

export const loadListGuideStats = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$.ofType(fetchGuide.toString()).mergeMap(() =>
        apiCall
            .userGuideStats()
            .map(({ response }: { response: Api.IGuideListResponse }) =>
                fetchGuideStatsList(response)
            )
            .catch((e) => {
                console.error(e);
                const statusCode = e.status;
                switch (statusCode) {
                    case 401:
                        console.error('Пользователь не авторизован');
                        break;
                    case 404:
                        console.error('Пользователь не найден');
                        break;
                    default:
                        break;
                }
                return Observable.empty();
            })
    );

export const loadGuideStats = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$.ofType(fetchGuide.toString()).mergeMap(() =>
        apiCall
            .userGuideSpecificStats(MAIN_TOUR_STEPS_VERSION)
            .map(({ response }: { response: IGuide }) =>
                setGuideStats(response)
            )
            .catch((e) => {
                console.error(e);
                const statusCode = e.status;
                switch (statusCode) {
                    case 400:
                        console.error('Некорректный формат версии гида');
                        break;
                    case 401:
                        console.error('Пользователь не авторизован');
                        break;
                    case 404:
                        const emptyResult: IGuide = {
                            version: MAIN_TOUR_STEPS_VERSION,
                            view: false,
                        };
                        console.error('Пользователь не найден');
                        return Observable.of(setGuideStats(emptyResult));
                    default:
                        break;
                }
                return Observable.empty();
            })
    );

export const updateGuideStats = (
    action$,
    store: MiddlewareAPI<Store.IState>,
    { apiCall }: { apiCall: Api.ApiCalls }
) =>
    action$
        .ofType(updateGuideStatsAction.toString())
        .mergeMap((action: Action<IUpdateGuide>) =>
            apiCall
                .userGuideSpecificUpdateStats(
                    action.payload.version,
                    action.payload
                )
                .map(({ response }: { response: IGuide }) =>
                    setGuideStats(response)
                )
                .catch((e) => {
                    console.error(e);
                    const statusCode = e.status;
                    switch (statusCode) {
                        case 400:
                            console.error('Некорректный формат версии гида');
                            break;
                        case 401:
                            console.error('Пользователь не авторизован');
                            break;
                        case 404:
                            console.error('Пользователь не найден');
                            break;
                        default:
                            break;
                    }
                    return Observable.empty();
                })
        );
