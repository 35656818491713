/**
 * Created by Lkarmelo on 21.11.2017.
 */

import { Action, handleActions } from 'redux-actions';

import Api from 'app/api/Api';

import {
    clearUserPermissions,
    SET_USER_DATA,
    setUserPermissions,
} from '../actions/user';
import * as Store from '../store/StoreNamespace';
import { fetchProfileAvatar, setProfileAvatar } from '../actions/profile';
import {
    fetchDropDownUserMenuAvatar,
    fetchLittleUserMenuAvatar,
    setDropDownUserMenuAvatar,
    setLittleUserMenuAvatar,
} from '../actions/userMenu';

export default handleActions<Partial<Store.ISystemUser>, any>(
    {
        [SET_USER_DATA](
            state: Store.ISystemUser,
            { payload }: { payload: Store.IUser; type: string }
        ) {
            return payload;
        },
        [setUserPermissions.toString()](
            state: Store.ISystemUser,
            { payload }: { payload: Api.UserPermissionsResponse; type: string }
        ) {
            const nextState = { ...state };
            nextState.permissions = payload.reduce(
                (
                    result: Store.EnabledUserRoles,
                    element: Api.IUserPermission
                ): Store.EnabledUserRoles => {
                    result[element.permission] = true;
                    return result;
                },
                <Store.EnabledUserRoles>{}
            );
            return nextState;
        },
        [clearUserPermissions.toString()](state: Store.ISystemUser) {
            return {
                ...state,
                permissions: undefined,
            };
        },
        [fetchProfileAvatar.toString()](state: Store.ISystemUser) {
            return {
                ...state,
            };
        },
        [setProfileAvatar.toString()](
            state: Store.ISystemUser,
            { payload }: Action<Store.IPersonAvatar>
        ) {
            const nextState = { ...state };
            nextState.avatar = payload;
            return nextState;
        },
        [fetchLittleUserMenuAvatar.toString()](state: Store.ISystemUser) {
            return {
                ...state,
            };
        },
        [setLittleUserMenuAvatar.toString()](
            state: Store.ISystemUser,
            { payload }: Action<Store.IPersonAvatar>
        ) {
            const nextState = { ...state };
            nextState.littleUserMenuAvatar = payload;
            return nextState;
        },
        [fetchDropDownUserMenuAvatar.toString()](state: Store.ISystemUser) {
            return {
                ...state,
            };
        },
        [setDropDownUserMenuAvatar.toString()](
            state: Store.ISystemUser,
            { payload }: Action<Store.IPersonAvatar>
        ) {
            const nextState = { ...state };
            nextState.dropdownUserMenuAvatar = payload;
            return nextState;
        },
    },
    {} // - default value for user
);
