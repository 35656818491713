/**
 * Created by Lkarmelo on 23.08.2017.
 */

import { Action, createAction } from 'redux-actions';

import Api from 'app/api/Api';

import * as Store from '../../store/StoreNamespace';

export interface IExecuteSearchPayload {
    alias?: string;
    requestBody: Api.IGlobalSearchRequestBody;
    /* из-за того, что в запросе в query могут быть добавлены специальные символы, query, которая выводится в интерфейсе нужно передавать
     * отдельно, она поставится как lastExecutedSearchQuery
     */
    clearQuery: string;
    filters: Store.IFilters;
    // true - полученный ответ будет добавлен к результатом, falsy - результаты будут перезаписаны ответом
    shouldAddToSearchResults?: boolean;
    // если в ответе придут флаги strict, то меняем стейт по их значениям
    setPersonStrictFromResponse?: boolean;
    // если пришли extracted concepts, то вызываем экшн, чтобы положить их в стейт
    setExtractedConcepts?: boolean;
    // если setExtractedConcepts = true, то в редюсере будут выставлены initialSelectedConcepts
    setInitialSelectedFromExtractedConcepts?: boolean;
}
export const executeSearch =
    createAction<IExecuteSearchPayload>('EXECUTE_SEARCH');

export interface ICompressRequestAndSearchPayload {
    isNewSearch?: boolean;
    skipSearchRequest?: boolean;
    setPersonStrictFromResponse?: boolean;
    setExtractedConcepts?: boolean;
    setInitialSelectedFromExtractedConcepts?: boolean;
    tabHasChanged?: boolean;
}
export const compressRequestUpdateLocationAndSearch =
    createAction<ICompressRequestAndSearchPayload>(
        'COMPRESS_REQUEST_UPDATE_LOCATION_AND_SEARCH'
    );

export interface IFetchSearchResultsPayload {
    ignoreMistakes: boolean;
}
interface IFetchSearchResults {
    (ignoreMistakes?: boolean): Action<IFetchSearchResultsPayload>;
}
export const fetchSearchResults: IFetchSearchResults = createAction<
    IFetchSearchResultsPayload,
    boolean
>(
    'FETCH_SEARCH_RESULTS',
    (ignoreMistakes = false): IFetchSearchResultsPayload => ({ ignoreMistakes })
);

type SearchResults = (
    | Api.IGlobalSearchResponseBody
    | { list?: Array<Store.IDocumentSnippet> }
) & { askFeedback?: boolean };
export interface ISetSearchResultsPayload<Results = SearchResults> {
    askFeedback?: boolean;
    dynamicFilterVisible?: Store.IFilterVisibility[];
    searchTarget?: Store.SearchTarget;
    results?: Results;
    personResults?: Api.IPersonSearchResponseBody;
}

export const setSearchResults =
    createAction<ISetSearchResultsPayload>('SET_SEARCH_RESULTS');

export const updateSearchResult = createAction<
    Store.IUpdateDocumentSnippet | Store.IPersonResults | {}
>('UPDATE_SEARCH_RESULT');

export const clearCorrectedQuery = createAction('CLEAR_CORRECTED_QUERY');

export const fetchMoreSearchResults = createAction('FETCH_MORE_SEARCH_RESULTS');
export const addToSearchResults = createAction<ISetSearchResultsPayload>(
    'ADD_TO_SEARCH_RESULTS'
);

export const fetchAvatar = createAction('FETCH_AVATAR');
export const setAvatars = createAction<Store.IPersonAvatar>('SET_AVATARS');

export const onShowBigAvatar = createAction('ON_SHOW_BIG_AVATAR');
export const getBigAvatar = createAction<Store.IPersonAvatar>('GET_BIG_AVATAR');

export const confirmShowImage = createAction('CONFIRM_SHOW_IMAGE');

export const onCloseBigImage = createAction('ON_CLOSE_BIG_IMAGE');
