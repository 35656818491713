import { createAction } from 'redux-actions';

import Api from 'app/api/Api';

import { IGuide, IUpdateGuide } from '../../types/tour';

export const fetchGuide = createAction('GUIDE_FETCH');

export const fetchGuideStatsList = createAction<Api.IGuideListResponse>(
    'GUIDE_SET_STATUS_LIST'
);

export const setGuideStats = createAction<IGuide>('GUIDE_SET_STATUS');

export const updateGuideStatsAction = createAction<IUpdateGuide>(
    'GUIDE_UPDATE_STATUS'
);
