import { connect } from 'react-redux';

import PersonView from './PersonView';

import * as Store from '../../../../redux/store/StoreNamespace';
import {
    IPersonViewWidgetActionProps,
    IPersonViewWidgetStateProps,
} from '../interfaces/IPersonViewProps';
import { fetchPersonViewWidgetAvatar } from '../../../../redux/actions/personWidget';
import { SearchDocumentName } from '../../../../redux/actions/interfaces/DocumentEnum';

const mapStateToProps = (state: Store.IState): IPersonViewWidgetStateProps => ({
    persons: state[SearchDocumentName.search].personResults.items,
});

const mapDispatchToProps = (dispatch): IPersonViewWidgetActionProps => ({
    onLoad(id: string): void {
        dispatch(
            fetchPersonViewWidgetAvatar({
                userId: id,
                resolution: '40',
                needCache: true,
            })
        );
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonView);
