/**
 * Created by Lkarmelo on 12.02.2018.
 */

import React, { PureComponent } from 'react';
import autoBind from 'autobind-decorator';
import classNames from 'classnames';
import memoizeOne from 'memoize-one';

import { DropDown } from 'app/components/common/tables/Filters';
import { IOption } from 'app/components/common/controls/Option';

import IProps from './interfaces/IDropDownWithTagsProps';

import './DropDownWithTags.scss';

@autoBind
export default class DropDownWithTags extends PureComponent<IProps> {
    inputValue = '';

    activeToStringArray = memoizeOne(
        (options: Array<string | IOption>): string[] =>
            options.map((opt) =>
                typeof opt === 'string' ? opt : (opt as IOption).value
            )
    );

    getTitle = memoizeOne(
        (active: Array<string | IOption>, options: IOption[]): string => {
            const activeLabels: string[] = [];
            active.forEach((act) => {
                if (typeof act === 'string') {
                    const option = options.find((opt) => opt.value === act);
                    option && activeLabels.push(option.label);
                } else {
                    activeLabels.push((act as IOption).label);
                }
            });
            return activeLabels.join(', ');
        }
    );

    render(): JSX.Element {
        const { active, options, onSelectOption, onDeselectOption, className } =
            this.props;
        return (
            <div className={classNames('drop-down-with-tags', className)}>
                <div className="drop-down-with-tags__inputs">
                    <div className="drop-down-with-tags__select">
                        <DropDown
                            onSelectOption={onSelectOption}
                            onDeselectOption={onDeselectOption}
                            onInputValueChange={this.onInputValueChange}
                            active={this.activeToStringArray(active)}
                            options={options}
                            title={this.getTitle(active, options)}
                            isMultiSelect
                            isFilterable
                        />
                    </div>
                    <button
                        className="btn drop-down-with-tags__add-option"
                        onClick={this.createOption}
                    >
                        <span>Добавить</span>
                    </button>
                </div>
                <div className="drop-down-with-tags__tags">
                    {active.map((optOrOptValue) => {
                        const option =
                            typeof optOrOptValue === 'string'
                                ? options.find(
                                      (opt) => opt.value === optOrOptValue
                                  )
                                : (optOrOptValue as IOption);
                        const optionVal = option && option.value;

                        return !option ? null : (
                            <div
                                className="drop-down-with-tags__tag"
                                key={optionVal}
                            >
                                <span className="drop-down-with-tags__tag-name">
                                    {option.label}
                                </span>
                                <button
                                    className="btn drop-down-with-tags__delete-tag"
                                    onClick={() =>
                                        onDeselectOption(
                                            option.value,
                                            '',
                                            option.meta,
                                            option.label
                                        )
                                    }
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    onInputValueChange(value: string) {
        this.inputValue = value;
    }

    createOption() {
        const { options, onCreateOption } = this.props;
        if (
            this.inputValue.trim() === '' ||
            options.findIndex(
                (opt) =>
                    opt.label.toLowerCase() === this.inputValue.toLowerCase()
            ) >= 0
        ) {
            return;
        }
        onCreateOption(this.inputValue);
    }
}
