import * as React from 'react';

import * as Store from 'app/redux/store/StoreNamespace';

import CardLinks from '../Tables/Links/CardLinks';
import CardGrouped from '../Tables/Grouped/CardGrouped';
import * as styles from '../Card/Card.scss';

interface IProps {
    card: Store.IObjectCard;
    onChangeTableSkip(tableName: string, skip: number): void;
    onChangeTableLimit(tableName: string, skip: number): void;
    onChangeTableSorting(
        tableName: string,
        field: string | string[],
        order: string
    ): void;
}

const GroupedAndLinks: React.FC<IProps> = ({
    card,
    onChangeTableSkip,
    onChangeTableLimit,
    onChangeTableSorting,
}) => {
    const {
        links,
        grouped,
        linksPaging,
        groupedPaging,
        linksSorting,
        groupedSorting,
    } = card || ({} as Store.IObjectCard);

    return (
        <div>
            {links && Array.isArray(links.items) && links.items.length > 0 && (
                <div className={styles.cardSection}>
                    <CardLinks
                        links={links}
                        paging={linksPaging}
                        sorting={linksSorting}
                        onChangeSkip={onChangeTableSkip}
                        onChangeLimit={onChangeTableLimit}
                        onChangeSorting={onChangeTableSorting}
                    />
                </div>
            )}
            {grouped &&
                Array.isArray(grouped.items) &&
                grouped.items.length > 0 && (
                    <div className={styles.cardSection}>
                        <CardGrouped
                            grouped={grouped}
                            paging={groupedPaging}
                            sorting={groupedSorting}
                            onChangeSkip={onChangeTableSkip}
                            onChangeLimit={onChangeTableLimit}
                            onChangeSorting={onChangeTableSorting}
                        />
                    </div>
                )}
        </div>
    );
};

export default GroupedAndLinks;
