/**
 * Created by lkarmelo on 03.09.2019.
 */

import React, { useCallback } from 'react';
import classNames from 'classnames';
import Tooltip from 'antd/es/tooltip';

import { MAX_SEARCH_QUERY_LENGTH } from 'app/utils/constants';

import IProps from './interfaces/IQueryTooLongProps';
import * as styles from './QueryTooLong.scss';

const QueryTooLong: React.FC<IProps> = ({
    lastExecutedSearchQuery,
    onShortenQuery,
}) => {
    const shortenQuery = useCallback(() => {
        onShortenQuery(
            typeof lastExecutedSearchQuery === 'string'
                ? lastExecutedSearchQuery.slice(0, MAX_SEARCH_QUERY_LENGTH)
                : ''
        );
    }, [lastExecutedSearchQuery]);

    return lastExecutedSearchQuery &&
        lastExecutedSearchQuery.length > MAX_SEARCH_QUERY_LENGTH ? (
        <div className={styles.queryTooLong}>
            <div>
                Запрос слишком длинный. Показаны результаты поиска по&nbsp;
                <Tooltip
                    title="Скопировать в поисковую строку"
                    placement="bottomLeft"
                >
                    <button
                        className={classNames('btn', styles.queryTooLongBtn)}
                        onClick={shortenQuery}
                    >
                        сокращенному запросу.
                    </button>
                </Tooltip>
            </div>
        </div>
    ) : null;
};

export default QueryTooLong;
